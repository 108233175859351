import styled from 'styled-components';

export const ModalProfile = styled.div`
  position: absolute;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
`;

export const ModalWrapper = styled.div`
  position: absolute;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
`;

export const ModalBackdrop = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
`;
