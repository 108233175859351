import { number, bool, string } from 'prop-types';

import { UserPicContainer, LiveIconContainer, AvatarContainer, VerifiedIcon } from './components';

import DefaultUserAvatar from 'Assets/img/DefaultUserAvatar.png';
import { ReactComponent as LiveIcon } from 'Assets/icons/Live.svg';
import { useTranslation } from 'react-i18next';

export const UserPic = ({ src, size, active, live, verified }) => {
  const { t } = useTranslation();
  return (
    <UserPicContainer size={size}>
      <AvatarContainer size={size} active={active} live={live}>
        <img src={src || DefaultUserAvatar} width={size} height={size} alt={t('user_profile')} />
      </AvatarContainer>
      {verified ? <VerifiedIcon /> : null}
      {live ? (
        <LiveIconContainer>
          <LiveIcon />
        </LiveIconContainer>
      ) : null}
    </UserPicContainer>
  );
};

UserPic.propTypes = {
  src: string,
  size: number,
  active: bool,
  live: bool,
  verified: bool
};

UserPic.defaultProps = {
  src: '',
  size: 40,
  active: false,
  live: false,
  verified: false
};
