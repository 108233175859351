import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { MobileMenu } from './MobileMenu';
import { ActionsBar } from 'Components/Navigation/NavBar/ActionsBar';
import { PostModal } from 'Components/PostModal';
import { MainLogo } from 'Components/shared/Logos/MainLogo';

import LogoSecondary from 'Assets/img/LogoSecondary.png';
import { ThemeContext } from 'Context/themeContext';

export const Header = () => {
  const { theme } = useContext(ThemeContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);

  const closeModal = () => setModalIsOpen(false);

  return (
    <>
      <header className="header-secondary">
        <div className="header-secondary-content" style={{ background: theme.background, padding: '0 0.75rem' }}>
          <MainLogo />
          <ActionsBar openModal={openModal} />
        </div>
        <div className="header-mobile" style={{ background: theme.background, padding: '0 0.75rem' }}>
          <Link to="/">
            <img className="mobile-logo" src={LogoSecondary} alt="Spoxio" />
          </Link>
          <MobileMenu />
        </div>
      </header>
      <PostModal closeModal={closeModal} isOpen={modalIsOpen} />
    </>
  );
};
