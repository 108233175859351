import { forwardRef } from 'react';
import { string, shape, bool, elementType } from 'prop-types';

import {
  CheckboxContainer,
  CheckboxIconContainer,
  CheckboxLabel,
  HiddenCheckbox
} from './components';

import { ReactComponent as CheckboxCheckedIcon } from 'Assets/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'Assets/icons/CheckboxUnchecked.svg';

export const FormCheckbox = forwardRef(
  ({ name, label, CustomLabel, checked, className, styles, ...props }, ref) => {
    return (
      <CheckboxContainer className={className}>
        <HiddenCheckbox ref={ref} name={name} {...props} />
        <CheckboxIconContainer>
          {checked ? (
            <CheckboxCheckedIcon width={26} height={26} />
          ) : (
            <CheckboxUncheckedIcon width={26} height={26} />
          )}
        </CheckboxIconContainer>
        {label ? <CheckboxLabel>{label}</CheckboxLabel> : CustomLabel ? <CustomLabel /> : null}
      </CheckboxContainer>
    );
  }
);

FormCheckbox.propTypes = {
  name: string.isRequired,
  label: string,
  CustomLabel: elementType,
  checked: bool,
  styles: shape({}),
  className: string
};

FormCheckbox.defaultProps = {
  checked: false,
  styles: {},
  CustomLabel: null,
  className: ''
};
