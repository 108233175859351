import { useDispatch, useSelector } from 'react-redux';
import { fetchPostCommentReplies } from 'Store/commentSlice';
import { getRepliesSelector } from 'Store/selectors/commentSelector';
import { PostCommentListProp, PostCommentProp } from 'Utils/propTypes';

import { Comment } from './Comment';
import { ReplyContainer } from './components';

export const PostComment = ({ comments, comment, ...props }) => {
  const dispatch = useDispatch();
  const replies = useSelector(getRepliesSelector);

  const onViewReply = async () => {
    await dispatch(fetchPostCommentReplies(comment._id));
  };

  return (
    <>
      <Comment comment={comment} onViewReply={onViewReply} {...props} />
      <br />
      {replies
        .filter((value) => value.parentComment === comment._id)
        .map((currentComment) => (
          <ReplyContainer key={currentComment._id}>
            <PostComment comments={comments} comment={currentComment} {...props} />
          </ReplyContainer>
        ))}
    </>
  );
};

PostComment.propTypes = {
  comments: PostCommentListProp,
  comment: PostCommentProp.isRequired
};

PostComment.defaultProps = {
  comments: []
};
