import styled from 'styled-components';

import { CenteredContainer } from 'Components/shared/styledComponents';

export const AppLink = styled.a.attrs({
  rel: 'noreferrer',
  target: '_blank'
})`
  display: block;
  margin-top: 30px;
  margin-right: 10px;
  margin: ${({ margin }) => margin};
`;

export const ContentContainer = styled(CenteredContainer)`
  margin: 0 auto;
  margin-top: 140px;
  max-width: 200px;
`;

export const AppLinkNav = styled.a.attrs({
  rel: 'noreferrer',
  target: '_blank'
})`
  display: block;
  margin-right: ${({ marginRight }) => marginRight || '10px'};
`;
