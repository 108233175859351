import { AboutTags } from 'Components/OtherProfile/components';
import { ProgressBar } from 'Components/Scout/components';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import TypeEvent from './TypeEvent';
import { CreateEventButton, TypeEventContainer } from './components';
import EventForm from './EventForm';
import Tickets from './Tickets';
import { ContentContainer, SpoxioBlueBtn } from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { useForm } from 'react-hook-form';
import isEmpty from 'Utils/isEmpty';
import { getFormData, setFormData } from 'Store/selectors/dataSelector';
import { useDispatch, useSelector } from 'react-redux';

const CreatEventStep = ({ setStep }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  // const [active, setActive] = useState('');
  const [active, setActive] = useState('virtual');
  const [page, setPage] = useState(1);
  const formData = useSelector(getFormData);
  const { register, watch, errors, control, handleSubmit } = useForm();

  const preSubmit = (data) => {
    dispatch(
      setFormData({
        ...data,
        category: data?.sportCategory?.label,
        is_paid: false,
        is_virtual: false,
        photos: [''],
        schedules: []
      })
    );

    // console.log(data, 'presubmit formData \n\n\r');

    setTimeout(() => {
      setPage(3);
    }, 500);
  };

  // console.log(formData, 'formData presubmit 2');

  return (
    <ContentContainer gap="20px" width="100%" height="100%">
      <AboutTags
        size="1.45rem"
        weight="700"
        style={{ letterSpacing: '0.01em', fontFamily: 'SF Pro Bold' }}
      >
        Create Event
      </AboutTags>
      <ProgressBar trailColor={theme?.spoxioIcon2} type="line" percent={page * 25} />

      {page === 1 && (
        <>
          <ContentContainer row flexWrap="nowrap" width="100%" gap="10px">
            <TypeEvent virtual setActive={setActive} active={active} />
            {/* <TypeEvent setActive={setActive} active={active} /> */}
          </ContentContainer>
        </>
      )}

      {page === 2 && (
        <EventForm
          register={register}
          watch={watch}
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
          handleGetData={preSubmit}
          handlePrev={() => setPage(1)}
        />
      )}

      {page === 3 && <Tickets handlePrev={() => setPage(2)} setStep={setStep} />}

      {/* Ending Button */}
      {page !== 2 && page !== 3 && (
        <ContentContainer column width="100%" gap="0px" margin="auto 0 10%">
          <SpoxioBlueBtn
            onClick={() => {
              page < 3 ? setPage((prev) => prev + 1) : setStep((prev) => prev + 1);
            }}
          >
            Next
          </SpoxioBlueBtn>

          <SpoxioBlueBtn
            border={theme?.spoxioText}
            background="transparent"
            color={theme?.spoxioText}
            onClick={() => {
              if (page === 1) {
                return;
              }
              page === 2 ? setPage(1) : setStep((prev) => prev - 1);
            }}
          >
            Cancel
          </SpoxioBlueBtn>
        </ContentContainer>
      )}

      {page === 2 && (
        <SpoxioBlueBtn
          border={theme?.spoxioText}
          background="transparent"
          marginTop="0"
          color={theme?.spoxioText}
          onClick={() => {
            if (page === 1) {
              return;
            }
            setStep((prev) => prev - 1);
          }}
        >
          Cancel
        </SpoxioBlueBtn>
      )}
    </ContentContainer>
  );
};

CreatEventStep.propTypes = {
  setStep: PropTypes.func
};
export default CreatEventStep;
