import { shape } from 'prop-types';
import React, { useState } from 'react';
import { ButtonsTag, DetailsPage, DetailsTag } from '../components';
import Records from '../Player/Records';

import ClubTeam from './ClubTeam';
import Events from './Events';
import OverView from './OverView';
import Recent from './Recent';

const Club = ({ about }) => {
  const [tag, setTag] = useState(1);
  const { clubProfile } = about;

  return (
    <DetailsPage maxWidth="220px">
      <ButtonsTag m={'10px'}>
        <DetailsTag
          active={tag === 1}
          onClick={() => {
            setTag(1);
          }}
        >
          Overview
        </DetailsTag>
        <DetailsTag
          active={tag === 2}
          onClick={() => {
            setTag(2);
          }}
        >
          Squad
        </DetailsTag>
        <DetailsTag
          active={tag === 3}
          onClick={() => {
            setTag(3);
          }}
        >
          Stats
        </DetailsTag>
      </ButtonsTag>
      {/* Players Info page */}
      {tag === 1 && (
        <>
          <OverView clubProfile={clubProfile} about={about} />
          {/* <Recent /> */}
          {/* <Events /> */}
        </>
      )}
      {tag === 2 && (
        <>
          <ClubTeam />
        </>
      )}
      {tag === 3 && (
        <>
          <Records about={about} />
        </>
      )}
    </DetailsPage>
  );
};

Club.propTypes = {
  about: shape({})
};

export default Club;
