import { BASE_URL, EVENT_BASE_URL } from 'Utils/constants';
import axiosApiInstance from './AxiosInstance';
const ApiHandler = {
  post: async (url, data) => axiosApiInstance.post(BASE_URL + url, data),
  put: async (url, data) => axiosApiInstance.put(BASE_URL + url, data),
  delete: async (url) => axiosApiInstance.delete(BASE_URL + url),
  get: async (url) => axiosApiInstance.get(BASE_URL + url)
};

export const EventApiHandler = {
  post: async (url, data) => axiosApiInstance.post(EVENT_BASE_URL + url, data),
  put: async (url, data) => axiosApiInstance.put(EVENT_BASE_URL + url, data),
  delete: async (url) => axiosApiInstance.delete(EVENT_BASE_URL + url),
  get: async (url) => axiosApiInstance.get(EVENT_BASE_URL + url)
};

export default ApiHandler;
