import styled from 'styled-components';

import {
  BaseText,
  BlackText,
  CenteredContainer,
  ContentContainer,
  SubTitle
} from 'Components/shared/styledComponents';

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* gap: 20px; */
  width: 100%;
  height: 100%;
  max-height: 75vh;
  margin: 10px 0;
  border: 0.875px solid ${({ theme }) => theme.lightGray2};
  border-radius: 20px;
`;

export const UserList = styled.div`
  width: 300px;
  border-right: 1px solid ${({ theme }) => theme.lightGray2};

  @media (max-width: 1024px) {
    width: auto;
  }
`;

export const UnreadPop = styled(ContentContainer)`
  width: 26px;
  height: 24px;
  background: ${({ theme }) => theme.spoxioAltBlue};
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  font-size: 0.65rem;
  font-weight: bold;
`;

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NotSelectedChatContainer = styled(CenteredContainer)`
  justify-content: center;
  margin: auto;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

export const UserItem = styled(ContentContainer)`
  padding: 6px 8px 6px 15px;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  gap: 6px;
  cursor: pointer;
  background: ${({ theme, selected, themeMode }) =>
    selected && themeMode === 'light'
      ? themeMode.spoxioIcon
      : selected && themeMode === 'dark'
      ? theme.borderShade
      : 'transparent'};
  box-shadow: 0px 2.5px 5px rgba(12, 21, 35, 0.1);
  border-color: ${({ theme, selected }) => (selected ? theme.spoxioAltBlue : theme.borderShade)};

  &:hover {
    opacity: 0.75;
  }
  @media (max-width: 1024px) {
    padding: 24px 10px;
  }
`;

export const CenterListItem = styled.div`
  padding: 6px 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const DateLabel = styled(BaseText)`
  min-width: 50px;
  margin-left: auto;
  font-size: ${({ theme }) => theme.small}px;
  text-align: right;
  color: ${({ theme }) => theme.opacityGray};

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ListName = styled(BlackText)`
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 130px;
  color: ${({ theme }) => theme.spoxioText};
`;

export const ListMessage = styled(SubTitle)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.7rem;
  line-height: 14px;
  /* min-width: 150px; */
  opacity: 0.65;
`;

export const TextArea = styled.textarea`
  width: 100% !important;
  min-height: 50px;
  padding: 12px 32px 12px 12px;
  resize: none;
  outline: none !important;
  border-color: ${({ theme }) => theme.lightGray};
  background: ${({ theme }) => theme.spoxioLayer1};
  /* color: ${({ theme }) => theme.spoxioText}; */
  border-style: solid;
  border-radius: 12px;
  font-family: SF Pro;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.gray};
  }

  :disabled {
    background-color: rgba(222, 222, 222, 0.42);
    border-color: transparent;
    color: ${({ theme }) => theme.black};
  }
`;

export const TextareaContainer = styled.div`
  position: relative;
  width: 100%;

  & svg {
    position: absolute;
    bottom: 40%;
    right: 8px;
    cursor: pointer;
  }
`;

export const UserInfo = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MessagesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0px;
  min-height: 55vh;
  overflow-y: auto;
  gap: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MessageInputContainer = styled.div`
  margin-top: auto;
  padding: 10px 0px 4px;
`;

export const Message = styled(BlackText)`
  display: inline-block;
  border-radius: 10px;
  padding: 10px 15px;
  width: auto;
  max-width: 80%;
  margin-bottom: 2px;
  /* margin-right: 25%; */
  position: relative;
  align-self: flex-start;
  background-color: ${({ theme }) => theme.spoxioIcon2};
  line-height: 22px;

  /* &:before {
    display: ${({ isLast }) => (isLast ? 'block' : 'none')};
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: ${({ theme }) => theme.lightGray1};
    border-bottom-right-radius: 15px;
  }

  &:after {
    display: ${({ isLast }) => (isLast ? 'block' : 'none')};
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
  } */
`;

export const SentMessage = styled.div`
  border-radius: 10px;
  padding: 10px 15px;
  width: auto;
  max-width: 80%;
  margin-bottom: 2px;
  display: inline-block;
  color: white;
  /* margin-left: 25%; */
  background: ${({ theme }) => theme.blue};
  background-attachment: fixed;
  position: relative;
  align-items: flex-end;
  align-self: flex-end;

  /* &:before {
    display: ${({ isLast }) => (isLast ? 'block' : 'none')};
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: ${({ theme }) => theme.blue};
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }

  &:after {
    display: ${({ isLast }) => (isLast ? 'block' : 'none')};
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: ${({ theme }) => theme.background};
    border-bottom-left-radius: 10px;
  } */
`;

export const TitleName = styled(BlackText)`
  font-size: ${({ theme }) => theme.xLarge}px;
`;

export const TitleRole = styled(BaseText)`
  font-size: 0.7rem;
  text-transform: capitalize;
  color: ${({ theme }) => theme.opacityGray};
`;

export const MessageTime = styled(BaseText)`
  margin-bottom: 5px;
  font-size: 0.65rem;
  color: ${({ theme }) => theme.opacityGray};
`;

export const SentMessageTime = styled(MessageTime)`
  display: flex;
  justify-content: flex-end;
`;
