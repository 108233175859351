import styled from 'styled-components';

import { BaseText } from '../styledComponents';

import { bool } from 'prop-types';

export const BarItem = styled.div`
  display: flex;
  margin-top: ${({ mt }) => mt || '20px'};
  flex-wrap: nowrap;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => width || 'auto'};
`;

export const UserName = styled(BaseText)`
  margin-left: ${({ marginLeft }) => marginLeft || '10px'};
  max-width: 200px;
`;

export const FollowIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: auto;
  width: 40px;
  cursor: pointer;

  & svg {
    width: 25px;
    transform: ${({ isFollowed }) => (isFollowed ? 'rotate(45deg)' : null)};
  }
`;

FollowIconContainer.propTypes = {
  isFollowed: bool
};

FollowIconContainer.defaultProps = {
  isFollowed: false
};
