import styled from 'styled-components';

export const PlayerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const PlayerInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PlayerInfoTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0px;
  column-gap: 0px;
`;
export const PlayerInfoTag = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.lightGray3};
  opacity: 0.5;
  font-size: 15px;
  margin: 0;
`;

export const PlayerInfoAns = styled.p`
  display: inline-block;
  font-weight: 700;

  font-size: 16px;
`;

export const PlayerAboutContainer = styled.div``;

export const PlayerAboutText = styled.div`
  color: ${({ theme }) => theme.lightGray3};
  font-size: 20px;
`;

export const PlayerKitContainer = styled.div`
  border: 0.3px solid ${({ theme }) => theme.borderShade};
  padding: 20px;
  border-radius: 20px;
`;
export const PlayerKitBackground = styled.div`
  background: ${({ theme }) => theme.spoxioIcon2};
  border-radius: 15px;
  display: flex;
  justify-content: center;
  max-width: ${({ maxWidth }) => maxWidth || 'fit-content'};
  max-height: ${({ maxHeight }) => maxHeight || '195px'};
`;
export const PlayerKitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
export const PlayerKitButton = styled.button`
  background: ${({ theme }) => theme.opacityPrimaryBlue};
  cursor: pointer;
  color: ${({ theme }) => theme.spoxioBlue};
  margin: 0 auto;
  font-size: 0.75rem;
  width: 100%;
  min-height: 50px;
  border: 1px solid ${({ theme }) => theme.opacityPrimaryBlue};
  font-weight: 600;
  border-radius: 10px;
`;
export const RecordsContainer = styled.div`
  /* max-width: 120px; */
  width: 100%;
  background: ${({ theme }) => theme.spoxioIcon2};
  border-radius: 12px;
  min-height: ${({ h }) => (h ? h : '120px')};
  min-width: ${({ w }) => (w ? w : '105px')};
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
`;
export const RecordsTextTag = styled.h6`
  color: ${({ theme }) => theme.spoxioText};
  font-size: ${({ fontSize }) => fontSize || '0.75rem'};
  display: block;
  text-align: center;
  letter-spacing: 1.25911px;
  width: 100%;
  /* height: 15px; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  margin: 0;
`;
export const RecordsTextAns = styled.p`
  display: block;
  color: #898a8d; //${({ theme }) => theme.spoxioGray1};
  font-weight: 700;
  font-size: 2.25rem;
  margin: 0;
`;
