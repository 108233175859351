import { ContentContainer, Title } from 'Components/shared/styledComponents';
import styled from 'styled-components';

export const ClubInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const ClubInfoText = styled.div`
  display: flex;
  column-gap: 15px;
  margin: 0;
`;

export const ClubText = styled.p`
  width: ${({ w }) => (w ? w : 'max-content')};
  margin: 0;
`;
export const SocialLinksContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;
export const SocialLink = styled.a.attrs({
  rel: 'noreferrer',
  target: '_blank'
})`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.borderShade};
  height: 40px;
`;

export const AppLinkNav = styled.a.attrs({
  rel: 'noreferrer',
  target: '_blank'
})`
  display: block;
  margin-right: ${({ margin }) => margin};
`;

export const Social = styled.a``;
export const ListContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  column-gap: 10px;
  &::-webkit-scrollbar {
    display: none;
    border: none;
  }
`;
export const RecentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RecentText = styled.p`
  margin: 0;
`;

export const ViewsText = styled.p``;

export const ClubEventsMain = styled(ContentContainer)`
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 12px;
  gap: 0;
  cursor: pointer;
  border-radius: ${({ borderRadius }) => borderRadius || '12px'};
`;
export const ClubLeftSide = styled.div`
  display: flex;
  padding: 20px 8px;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  height: ${({ height }) => height};
  margin-right: ${({ marginRight }) => marginRight || '-2px'};
  background: ${({ theme, themeMode }) =>
    themeMode === 'light' ? theme.lightGray1 : theme.spoxioIcon2};
  border-radius: 12px 0px 0px 12px;
  width: ${({ width }) => width};
`;

export const EventsTime = styled.h4`
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.spoxioText};
  width: 65%;
  display: flex;
  margin: 0;
  justify-content: center;
  border-radius: 8px;
  padding: 10px 8px;
  min-height: ${({ minHeight }) => minHeight};
`;

export const CLubEvents = styled.div`
  border: 1px solid ${({ theme }) => theme.borderShade};
  background: ${({ theme }) => theme.spoxioBg};
`;
export const ClubEventsTitle = styled.h4``;

export const ClubImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClubEventsLocation = styled.p``;

export const ClubEventTime = styled(Title)`
  background: ${({ theme, live }) => (live ? theme.primaryRed : theme.spoxioBg)};
  padding: 4px 7px;
  color: ${({ theme }) => theme.defaultWhite};
  font-size: 0.6rem;
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  border: 0.848016px solid #414141;
`;
