import { useEffect, useState } from 'react';
import { func, shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { UserPic } from 'Components/shared/UserPic';
import { ClickableContainer, ContentContainer } from 'Components/shared/styledComponents';
import { BarItem, UserName } from './components';
import { followOnUser, getProfileById, unfollowOnUser } from 'Store/profileSlice';
import { useTranslation } from 'react-i18next';
import { FollowButtonRevamp } from '../FollowButtonRevamp';
import isEmpty from 'Utils/isEmpty';
import Api from 'middleware/api';

export const FollowUser = ({ user }) => {
  const [isFollow, setIsFollow] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onUserClick = () => dispatch(push(`${paths.PROFILE}/${user?._id}`));

  const getUserById = () => {
    Api.user
      .userDetailById(user?._id)
      .then((res) => {
        const { isFollowedByMe } = res.data.data;
        setIsFollow(isFollowedByMe);
        return res.data.data;
      })
      .catch((errors) => {
        return {};
      });
  };

  useEffect(async () => {
    getUserById();
  }, [user]);

  // console.log('\n <===== the followUserser user =====>\n', user, userData);

  const onPlusClick = () => {
    if (isFollow) {
      dispatch(unfollowOnUser(user._id));
      setIsFollow(!isFollow);
    } else {
      dispatch(followOnUser(user._id));
      setIsFollow(!isFollow);
    }
  };

  return (
    <BarItem width="100%" mt="0">
      <ClickableContainer onClick={onUserClick}>
        <ContentContainer row gap="10px" alignItems="center">
          <UserPic src={user?.picture} />
          <UserName width="100%">
            {!isEmpty(user) ? `${user?.firstName} ${user?.lastName}` : ''}
          </UserName>
        </ContentContainer>
      </ClickableContainer>
      {!isFollow && <FollowButtonRevamp isFollowed={isFollow} onClick={onPlusClick} />}
    </BarItem>
  );
};

FollowUser.propTypes = {
  user: shape({}).isRequired
};
