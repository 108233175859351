import { NavContainer } from 'Components/Navigation/NavBar/components';
import { BaseTitle, BlackText } from 'Components/shared/styledComponents';
import { MainLogo } from 'Components/shared/Logos/MainLogo';
import { AppLink, ContentContainer } from './components';

import AppStoreLogo from 'Assets/img/AppStore.png';
import GooglePlayLogo from 'Assets/img/GooglePlay.png';

import { fontSizes } from 'Theme';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

export const MobileDetectRoute = () => {
  const { t } = useTranslation();
  return (
    <>
      <NavContainer border="none">
        <MainLogo />
      </NavContainer>
      <ContentContainer>
        <BaseTitle className="margin-bottom_large">{t('all_set')}</BaseTitle>
        <BlackText textAlign="center" fontSize={fontSizes.xLarge}>
          {t('to_start_using')}
        </BlackText>
        <AppLink href="https://apps.apple.com/ng/app/spoxio/id1525715823">
        <QRCode value="https://apps.apple.com/ng/app/spoxio/id1525715823" />
          <img src={AppStoreLogo} width={200} height={70} alt="App Store Link" />
        </AppLink>
        <AppLink href="https://play.google.com/store/apps/details?id=com.spoxio.app">
        <QRCode value="https://play.google.com/store/apps/details?id=com.spoxio.app" />
          <img src={GooglePlayLogo} width={200} height={70} alt="Play Market Link" />
        </AppLink>
      </ContentContainer>
    </>
  );
};
