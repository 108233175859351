import styled from 'styled-components';
import { number } from 'prop-types';

import { BlackText, CenteredContainer, RowContainer } from 'Components/shared/styledComponents';

import { ReactComponent as BeforeIcon } from 'Assets/icons/Before.svg';

export const HeaderContainer = styled(RowContainer)`
  justify-content: center;
  width: 100%;
  padding: 40px 0;
`;

export const SecondHeader = styled(BlackText)`
  width: 100%;
  padding: 20px 0;
  font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.large2)}px;
`;

SecondHeader.propTypes = {
  fontSize: number
};

SecondHeader.defaultProps = {
  fontSize: null
};

export const BackButton = styled(BeforeIcon)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  cursor: pointer;
`;

export const MainContainer = styled(CenteredContainer)`
  max-width: 540px;
  margin: 0 auto;
  padding-top: 80px;
`;

export const FormContainer = styled.form`
  width: 100%;
`;

export const ReportButton = styled.div`
  max-width: 335px;
  margin: 30px auto;
`;

export const Margin = styled.div`
  margin: 30px 0;
`;

export const SuccessContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 330px;
  align-self: center;
  & svg {
    width: 120px;
    height: 80px;
    align-self: center;
  }
`;
