import { useState } from 'react';

import { TextArea, TextareaContainer } from './components';

import { ReactComponent as Send } from 'Assets/icons/Send.svg';
import { func, string } from 'prop-types';
import { colors } from 'Theme';
// import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export const MessageInput = ({ defaultMessage, onSendClick }) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const onChange = (e) => setValue(e.target.value);

  const onClick = async () => {
    if (defaultMessage) {
      await onSendClick();
      setValue('');
    }

    const text = value.trim();

    if (text) {
      await onSendClick(text);
      setValue('');
    }
  };

  return (
    <TextareaContainer>
      <TextArea
        value={defaultMessage ? defaultMessage : value}
        disabled={!!defaultMessage}
        placeholder={t('type_a_message')}
        onChange={onChange}
      />
      <Send
        color={value.trim() || defaultMessage ? colors.primaryBlue : colors.opacityGray}
        onClick={onClick}
      />
    </TextareaContainer>
  );
};

MessageInput.propTypes = {
  defaultMessage: string,
  onSendClick: func.isRequired
};

MessageInput.defaultProps = {
  defaultMessage: ''
};
