import styled from 'styled-components';

import { ReactComponent as Image } from 'Assets/icons/Image.svg';
import { ReactComponent as CameraSvg } from 'Assets/icons/CameraIcon.svg';
import { bool } from 'prop-types';

export const InputContainer = styled.div`
  position: relative;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ border, theme }) => border || theme.secondaryGray};
  border-radius: 20px;
  width: 100%;
  height: 100%;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  cursor: ${({ loading }) => (loading ? 'none' : 'pointer')};
`;

export const FileInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
`;

export const ImageIcon = styled(Image)`
  margin-bottom: 5px;
  & path {
    fill: ${({ theme }) => theme.primaryBlue};
  }
`;

export const CameraIcon = styled(CameraSvg)`
  margin-bottom: 5px;
  & path {
    fill: ${({ theme, color }) => color || theme.spoxioIcon};
  }
`;
