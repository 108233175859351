import styled from 'styled-components';

import { BaseText } from 'Components/shared/styledComponents';

export const LeftBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
  width: 100%;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ border, theme }) => (border ? border : theme.borderShade2)};
  border-radius: 30px;
  background-color: ${({ background, theme }) => (background ? background : theme.spoxioBg)};
`;

export const ListItem = styled(BaseText)`
  padding: 3px 0;
  color: ${({ theme }) => theme.opacityGray};
  cursor: pointer;
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  & > a {
    color: ${({ theme }) => theme.opacityGray};
    padding: 5px 0;
    font-family: SF Pro;
    font-size: ${({ theme }) => theme.large}px;
  }
  & > span {
    color: ${({ theme }) => theme.opacityGray};
    padding: 5px 0;
    font-family: SF Pro;
    font-size: ${({ theme }) => theme.large}px;
  }
`;

export const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;

  & > a {
    margin-bottom: 5px;
  }
`;

export const StoreImage = styled.img`
  width: 90px;
  height: 28px;
  opacity: 0.5;
  cursor: pointer;
`;
