import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { any, node, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { getFollowingData } from 'Store/profileSlice';
import NavBarUser from 'Components/OtherProfile/NavBarUser';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import ScoutDetails from 'Components/Scout/ScoutDetails';
import { DetailsPage } from 'Components/OtherProfile/components';
import { LeftBar } from '../MainNavigation/LeftBar';
import { NavBar } from '../MainNavigation/NavBar';
import {
  CenterContainer,
  InnerCenterContainer,
  LeftContainer,
  MainContainer,
  RightContainer
} from '../MainNavigation/components';
import { RightBarContainer } from '../MainNavigation/RightBar/components';
import NavBarProfile from 'Components/OtherProfile/NavBarProfile';
import Api from 'middleware/api';
import { ThemeContext } from 'Context/themeContext';
import TalentDetails from 'Components/Scout/TalentDetails';

const ScoutLayout = ({ children, profile, navTitle }) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFollowingData());
  }, []);
  const { data, isCurrentUser } = profile;

  const [clubData, setClubData] = useState({});

  const getClubData = async () => {
    try {
      const res = await Api.user.userDetailById(data?.clubMemberProfile?.clubProfile);
      setClubData(res.data.data);
      console.log(res.data, '<---------------------------- get club  data \n\n\r');
    } catch (e) {
      // console.log(e, e?.response, 'club data error');
    }
  };

  useEffect(() => {
    getClubData();
  }, [data]);

  return (
    <MainContainer>
      <LeftContainer>
        <LeftBar showSuggestion={true} showSports={true} showFooter={true} />
      </LeftContainer>
      <CenterContainer>
        <NavBar title={navTitle || 'Scout Activities'} />
        <InnerCenterContainer>{children}</InnerCenterContainer>
      </CenterContainer>
      <RightContainer>
        <RightBarContainer border="transparent" background="transparent" padding="20px 20px 0px">
          {/* Top aligned with the search bar i */}
          <NavBarProfile isCurrentUser={isCurrentUser} data={data} showProfileButton />

          {/* Main content with about and all )*/}
          <DetailsPage background={theme.background}>
            {data?.userType === 'talent' || data?.userType === 'club_member' ? (
              <TalentDetails profile={profile} clubData={clubData} />
            ) : (
              <ScoutDetails profile={profile} clubData={clubData} />
            )}
          </DetailsPage>
        </RightBarContainer>
      </RightContainer>
    </MainContainer>
  );
};

ScoutLayout.propTypes = {
  children: node.isRequired,
  profile: shape({}),
  navTitle: any
};

export default ScoutLayout;
