import { Header } from 'Components/Landing/Header';
import { Footer } from 'Components/Landing/Footer';
import { useTranslation } from 'react-i18next';

import FifthScreenshot from 'Assets/img/Screen5.png';

import 'Styles/main.scss';
import 'Styles/about.scss';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  color: ${({ theme }) => theme.spoxioText};
  opacity: 0.8;
`;
const HeaderText = styled.h1`
  color: ${({ theme }) => theme.black};
`;

export const About = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Header />
      <main
        className="main container nullable-vertical-padding"
        style={{ background: theme.background }}
      >
        <div className="about-content">
          <HeaderText className="about-title">{t('about')} Spoxio</HeaderText>
          <div className="about-body">
            <div className="about-images-set">
              <img className="about-screenshot" src={FifthScreenshot} alt="Screenshot" />
              <div className="blue-block"></div>
            </div>
            <div className="about-description">
              <Paragraph className="about-text">Spoxio {t('about_text')}</Paragraph>
              <Paragraph className="about-text">{t('about_text_body')}</Paragraph>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
