import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { any, node, shape } from 'prop-types';
import { getFollowingData } from 'Store/profileSlice';
import { DetailsPage } from 'Components/OtherProfile/components';
import { NavBar } from '../MainNavigation/NavBar';
import {
  CenterContainer,
  InnerCenterContainer,
  LeftContainer,
  MainContainer,
  RightContainer
} from '../MainNavigation/components';
import { RightBarContainer } from '../MainNavigation/RightBar/components';
import { ThemeContext } from 'Context/themeContext';
import { LeftBarContainer } from '../MainNavigation/LeftBar/components';
import { MainLogo } from 'Components/shared/Logos/MainLogo';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { AuthLink } from 'Routes/Auth/components';
import { paths } from 'Utils/constants';
import { Image } from 'Components/PostModal/components';
import spoxioApp from 'Assets/img/spoxioAppImage.png';
import AppStoreBtn from 'Assets/img/appstorebtn.png';
import GooglePlayBtn from 'Assets/img/googleplaybtn.png';
import { AppLink } from 'Routes/MobileDetectRoute/components';

const ClubInviteLayout = ({ children, profile, navTitle }) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFollowingData());
  }, []);
  // const { data, isCurrentUser } = profile;

  const [clubData, setClubData] = useState({});

  // const getClubData = async () => {
  //   try {
  //     const res = await Api.user.userDetailById(data?.clubMemberProfile?.clubProfile);
  //     setClubData(res.data.data);
  //     console.log(res.data, '<---------------------------- get club  data \n\n\r');
  //   } catch (e) {
  //     // console.log(e, e?.response, 'club data error');
  //   }
  // };

  // useEffect(() => {
  //   getClubData();
  // }, [data]);

  return (
    <MainContainer>
      <LeftContainer>
        <LeftBarContainer style={{ justifyContent: 'space-between' }}>
          <ContentContainer width="150px" height="100px">
            <MainLogo />
          </ContentContainer>

          <ContentContainer
            width="100%"
            column
            justifyContent="center"
            alignItems="center"
            gap="20px">
            <SubTitle color={theme?.SpoxioBg} fontSize="0.875rem" hover opacity="1">
              Are you new here?
            </SubTitle>

            <AuthLink
              style={{
                fontSize: '0.875rem',
                fontWeight: '700',
                width: '70%',
                textAlign: 'center',
                padding: '1rem 1.45rem',
                background: theme?.borderShade,
                color: theme?.spoxioAltBlue,
                borderRadius: '0.875rem'
              }}
              to={paths.SIGN_UP}>
              Create an account
            </AuthLink>

            <ContentContainer
              row
              gap="0.75rem"
              justifyContent="center"
              alignItems="center"
              margin="0 auto 8vh">
              {' '}
              <SubTitle hover color={theme?.SpoxioBg} fontSize="0.875rem" opacity="1">
                Already have an account?
              </SubTitle>
              <AuthLink style={{ fontSize: '0.875rem', fontWeight: '600' }} to={paths.SIGN_IN}>
                Login
              </AuthLink>
            </ContentContainer>

            <SubTitle fontSize="0.875rem">Spoxio v2.0</SubTitle>
          </ContentContainer>
        </LeftBarContainer>
      </LeftContainer>
      <CenterContainer>
        <NavBar title={navTitle || 'Invitation'} />
        <InnerCenterContainer>{children}</InnerCenterContainer>
      </CenterContainer>
      <RightContainer>
        <RightBarContainer
          border={theme?.borderShade}
          background={theme.background}
          padding="20px 0px">
          <DetailsPage
            border="transparent"
            padding="0"
            background={theme.background}
            style={{ height: '100%', width: '100%' }}>
            <ContentContainer
              minHeight="40vh"
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
              gap="20px">
              <Image src={spoxioApp} effect='opacity' alt="" width="100%" style={{ objectFit: 'contain' }} />

              <Title
                textAlign="center"
                padding="0px 4px"
                fontSize="1.5rem"
                fontWeight="bold"
                lineHeight="1.875rem">
                Download the <br />
                Spoxio mobile app
              </Title>

              <ContentContainer
                row
                flexWrap="wrap"
                gap="10px"
                width="100%"
                padding="0 20px"
                justifyContent="center">
                <ContentContainer width="47.5%">
                  <AppLink href="https://apps.apple.com/ng/app/spoxio/id1525715823" margin="0px">
                    <Image src={AppStoreBtn} alt="App Store Link" style={{ objectFit: 'cover' }} />
                  </AppLink>
                </ContentContainer>
                <ContentContainer width="47.5%">
                  <AppLink
                    href="https://play.google.com/store/apps/details?id=com.spoxio.app"
                    margin="0px">
                    <Image
                      src={GooglePlayBtn}
                      alt="Play Market Link"
                      style={{ objectFit: 'cover' }}
                    />
                  </AppLink>
                </ContentContainer>
              </ContentContainer>
            </ContentContainer>
          </DetailsPage>
        </RightBarContainer>
      </RightContainer>
    </MainContainer>
  );
};

ClubInviteLayout.propTypes = {
  children: node.isRequired,
  profile: shape({}),
  navTitle: any
};

export default ClubInviteLayout;
