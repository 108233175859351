import React, { useContext, useEffect, useState } from 'react';
// import EventCover from 'Assets/img/event-photo.png';
// import EventCover from 'Assets/img/event-placeholder3.jpeg';
import EventCover from 'Assets/img/event-placeholder4.jpeg';
import { ReactComponent as ShareIcon } from 'Assets/icons/ShareIcon.svg';
import { ReactComponent as Calendar } from 'Assets/icons/event-calendar.svg';
import { ReactComponent as Location } from 'Assets/icons/event-location.svg';
import { ReactComponent as Time } from 'Assets/icons/event-time.svg';
import { ReactComponent as TicketIcon } from 'Assets/icons/TicketIcon.svg';
import { ReactComponent as ArrowRight } from 'Assets/icons/blue-arrow-right.svg';

import {
  DetailsText,
  EventShare,
  EventDetailsImage,
  EventText,
  EventTiming,
  EventTimingBox,
  NameHolder
} from './components';
import { UserPic } from 'Components/shared/UserPic';
import { JoinEventBtn } from '../components';
import { AboutTags } from 'Components/OtherProfile/components';
import EventsLayout from 'Components/Layouts/EventsLayout/EventsLayout';
import { useLocation, useParams } from 'react-router';
import {
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { dateMeridiem, formatDate } from 'Utils/dates';
import isEmpty from 'Utils/isEmpty';
import { Tabs } from 'Components/shared/Tabs';
import copy from 'copy-to-clipboard';
import { paths } from 'Utils/constants';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Api2 from 'middleware/api';
import { Loader } from 'Components/shared/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserId } from 'Store/authSlice';
import { fetchEventDetailsById } from 'Store/eventSlice';
import { getEventDetails } from 'Store/selectors/eventSelector';
import { BottomOverlay } from 'Components/Layouts/AuthLayout/components';
import { PostImage } from 'Components/shared/Post/components';
import { AppLinkNav } from 'Routes/MobileDetectRoute/components';

const EventDetails = (props) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const [step, setStep] = useState(1);
  const { id } = useParams();
  const { t } = useTranslation();
  const currentUserId = useSelector(getCurrentUserId);
  const eventDetails = useSelector(getEventDetails);
  const [tabName, setTabName] = useState('details');
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [event, setEvent] = useState(state?.event || {});
  const isAttended = event?.participants?.filter((item) => item?.user?.includes(currentUserId));

  const onTabClick = (tabId) => {
    setTabName(tabId);
  };

  useEffect(() => {
    dispatch(fetchEventDetailsById(id));
  }, []);

  useEffect(() => {
    if (isEmpty(eventDetails)) {
      return;
    }
    setEvent(eventDetails);
  }, [eventDetails]);

  const onShareClick = () => {
    if (isEmpty(event?._id)) {
      toast.warn('Fetching event details, please wait...');
      return;
    }
    copy(`${window.location.origin}${paths.EVENTS}/${event?._id}`);
    toast.success(`${t('link_copied')}`);
  };

  console.log('event details location data', event, currentUserId, isAttended);

  const joinEvent = async () => {
    if (isEmpty(event?._id)) {
      toast.warn('Fetching event details, please wait...');
      return;
    }
    try {
      setLoading(true);
      const res = await Api2.event.joinEvent({
        event: event?._id,
        user: currentUserId
      });
      toast.success(res?.data?.message, 'success');
      dispatch(fetchEventDetailsById(id));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.title);
      console.log('\n\n\rjoin event error', e, e.response);
    }
  };

  return (
    <EventsLayout
      setStep={setStep}
      step={step}
      showBackButton
      navTitle={event?.title || 'Event'}
      hideCreateImage={true}
    >
      <ContentContainer>
        <ContentContainer
        // maxHeight="90vh" hideScroll height="100%" overflowY="hidden"
        >
          <EventDetailsImage
            cover={!isEmpty(event?.photos) && event?.photos[0] ? event?.photos[0] : null}
            fallBack={EventCover}
          >
            <EventShare>
              <Title fontSize="2rem" color={theme?.defaultWhite}>
                {event?.title}
              </Title>

              <IconContainer
                opacity="0.8"
                padding="12px"
                background={theme?.spoxioBg2}
                fill={theme?.spoxioIcon}
                width="20px"
                height="20px"
                onClick={onShareClick}
              >
                <ShareIcon />
              </IconContainer>
            </EventShare>
            <BottomOverlay borderRadius="0" />
          </EventDetailsImage>
          {/* Event Scheduling Time */}
          <EventTiming>
            <EventTimingBox>
              <IconContainer width="24px" height="24px" margin="0" fill={theme?.spoxioAltBlue}>
                <Calendar />
              </IconContainer>
              <EventText>{formatDate(event?.event_date, 'DD MMMM, YYYY')}</EventText>
            </EventTimingBox>
          </EventTiming>
          <EventTiming>
            <EventTimingBox>
              <IconContainer width="24px" height="24px" margin="0" fill={theme?.spoxioAltBlue}>
                <Time />
              </IconContainer>
              <EventText>
                {!isEmpty(event?.start_time) ? (
                  <>
                    {event?.start_time} {dateMeridiem(+event?.start_time[0] + event?.start_time[1])}{' '}
                    - {event?.end_time} {dateMeridiem(+event?.end_time[0] + event?.end_time[1])}
                  </>
                ) : null}
              </EventText>
            </EventTimingBox>
            <EventTimingBox>
              <IconContainer
                width="24px"
                height="24px"
                margin="0"
                opacity="0.75"
                fill={theme?.spoxioAltBlue}
              >
                <Location />
              </IconContainer>
              <EventText>{event?.venue}</EventText>
            </EventTimingBox>
          </EventTiming>

          {/* Event Profile */}
          <ContentContainer row justifyContent="space-between" alignItems="center" margin="12px 0">
            <ContentContainer row alignItems="center" gap="12px">
              <UserPic size={65} src={event?.created_by?.picture} />
              <NameHolder>
                <Title fontSize="1.325rem" color={theme?.spoxioText} opacity="0.875">
                  {event?.created_by?.firstName} {event?.created_by?.lastName}
                </Title>
                <SubTitle color={theme?.mediumGray}>Organizer</SubTitle>
              </NameHolder>
            </ContentContainer>

            {loading ? (
              <ContentContainer maxWidth="105px" height="40px" width="100%">
                <Loader />
              </ContentContainer>
            ) : event?.created_by?.user !== currentUserId ? (
              isEmpty(isAttended) ? (
                <JoinEventBtn
                  width={'105px'}
                  height={'40px'}
                  // background="#122126"
                  color="#0094C9"
                  onClick={joinEvent}
                >
                  Attend{' '}
                </JoinEventBtn>
              ) : null // <FollowButtonRevamp isFollowed={false} onClick={onFollow} />
            ) : null}
          </ContentContainer>

          <ContentContainer width="100%" margin="10px 0">
            <Tabs
              activeBorderColor="#0094C9"
              inactiveBorderColor={theme.lightGrayShade}
              defaultTab={tabName}
              tabGap="20px"
              tabColorOpacity="0.55"
              background={theme?.spoxioIcon2}
              handleChangeTab={onTabClick}
              tabs={[
                {
                  id: 'details',
                  label: `Details`,
                  component: (
                    <ContentContainer margin="20px 0">
                      {' '}
                      <AboutTags fontFamily="SF Pro Bold" color={theme?.black} size={'1.25rem'}>
                        Event Details
                      </AboutTags>
                      <DetailsText color={theme?.gray}>{event?.about}</DetailsText>
                      <ContentContainer margin="6vh 0 0">
                        {/* Buy Tickets Button */}
                        {/* <AboutTags color={theme?.black} size={'1.5rem'}>
                          Participants
                        </AboutTags> */}
                        {
                          // !isEmpty(event?.payment_link) ? (
                          //   <AppLinkNav href={event?.payment_link}>
                          //     <SpoxioBlueBtn
                          //       style={{
                          //         display: 'flex',
                          //         flexDirection: 'row',
                          //         alignItems: 'center',
                          //         justifyContent: 'center',
                          //         gap: '20px'
                          //       }}
                          //     >
                          //       <IconContainer
                          //         width="24px"
                          //         height="24px"
                          //         margin="0"
                          //         fill={theme?.defaultWhite}
                          //       >
                          //         <TicketIcon />
                          //       </IconContainer>

                          //       <Title
                          //         textAlign="center"
                          //         fontSize="1.2rem"
                          //         fontWeight="400"
                          //         color={theme?.defaultWhite}
                          //       >
                          //         Buy Tickets
                          //       </Title>
                          //       <IconContainer
                          //         width="15px"
                          //         height="15px"
                          //         margin="0"
                          //         padding="8px"
                          //         background={theme?.defaultWhite}
                          //       >
                          //         <ArrowRight />
                          //       </IconContainer>
                          //     </SpoxioBlueBtn>
                          //   </AppLinkNav>
                          // ) :
                          !isEmpty(event?.event_link) ? (
                            <AppLinkNav href={event?.event_link}>
                              <SpoxioBlueBtn hover width="100%">
                                <Title
                                  textAlign="center"
                                  fontSize="1rem"
                                  fontWeight="400"
                                  fontFamily="SF Pro"
                                  // color="#0094C9"
                                  color={theme.defaultWhite}
                                >
                                  Learn More...
                                </Title>
                              </SpoxioBlueBtn>
                            </AppLinkNav>
                          ) : null
                        }
                      </ContentContainer>
                    </ContentContainer>
                  )
                },
                {
                  id: 'photos',
                  label: `Photos`,
                  component: (
                    <ContentContainer row margin="20px 0" gap="20px" flexWrap="wrap">
                      {!isEmpty(event?.photos) && event?.photos[0]
                        ? event?.photos?.map((photo, i) => {
                            return (
                              <ContentContainer key={i} height="200px" width="200px">
                                <PostImage
                                  src={photo}
                                  alt={t('')}
                                  borderRadius="8px"
                                  backgroundColor={theme.spoxioBg2}
                                />
                              </ContentContainer>
                            );
                          })
                        : null}
                    </ContentContainer>
                  )
                },

                {
                  id: 'schedule',
                  label: `Schedule`,
                  component: (
                    <ContentContainer column margin="20px 0" hideScroll gap="25px" width="100%">
                      {!isEmpty(event?.schedules) && event?.schedules[0]
                        ? event?.schedules?.map((schedule, i) => {
                            const startTime =
                              schedule?.start_time +
                              dateMeridiem(+schedule?.start_time[0] + schedule?.start_time[1]);
                            const endTime =
                              schedule?.start_time +
                              dateMeridiem(+schedule?.end_time[0] + schedule?.end_time[1]);

                            return (
                              <ContentContainer
                                key={i}
                                row
                                flexWrap="nowrap"
                                gap="6px"
                                width="100%"
                                minHeight="7vh"
                              >
                                <ContentContainer width="10%" padding="12px 5px">
                                  <SubTitle width="100%" fontSize="0.875rem">
                                    {formatDate(schedule?.date, 'DD MMM')}
                                  </SubTitle>
                                </ContentContainer>
                                <ContentContainer
                                  borderRadius="14px"
                                  width="90%"
                                  gap="8px"
                                  padding="12px 10px"
                                  background={theme.spoxioLinear2}
                                >
                                  <Title
                                    fontSize="1.25rem"
                                    fontFamily="SF Pro"
                                    color={theme.defaultWhite}
                                  >
                                    {schedule?.title}
                                  </Title>
                                  <Title
                                    fontSize="0.875rem"
                                    opacity="0.75"
                                    color={theme.defaultWhite}
                                  >
                                    {startTime} - {endTime}
                                  </Title>
                                </ContentContainer>
                              </ContentContainer>
                            );
                          })
                        : null}
                    </ContentContainer>
                  )
                },
                {
                  id: 'participants',
                  label: `Participants`,
                  component: (
                    <ContentContainer
                      column
                      margin="20px 0"
                      height="100%"
                      maxHeight="35vh"
                      hideScroll
                      gap="20px"
                      overflowY="auto"
                    >
                      {!isEmpty(event?.participants) && event?.participants[0]
                        ? event?.participants?.map((participant, i) => {
                            return (
                              <ContentContainer key={i} row alignItems="center" gap="12px">
                                <UserPic size={65} src={participant?.picture} />
                                <NameHolder>
                                  <Title
                                    fontSize="1rem"
                                    color={theme?.spoxioText}
                                    opacity="0.875"
                                    fontFamily="SF Pro"
                                  >
                                    {participant?.firstName} {participant?.lastName}
                                  </Title>
                                </NameHolder>
                              </ContentContainer>
                            );
                          })
                        : null}
                    </ContentContainer>
                  )
                }
              ]}
            />
          </ContentContainer>

          {/* Buy Tickets Button */}
          {/* <EventButton>
          Buy Tickets
          <CircleContainer size={'30px'} color={theme?.black}>
            <IconContainer width="24px" height="24px" margin="0">
              <ArrowRight />
            </IconContainer>
          </CircleContainer>
        </EventButton> */}
        </ContentContainer>
      </ContentContainer>
    </EventsLayout>
  );
};

export default EventDetails;
