import { Title } from 'Components/shared/styledComponents';
import styled from 'styled-components';

export const EventsContainer = styled.div`
  display: inherit;
  flex-flow: column nowrap;
  top: 20px; // 100px;
  position: relative;
`;

export const BarContainer = styled.div`
  width: 100%;
  min-height: 80px;
  padding: 10px;
  box-sizing: border-box;
  background: ${({ theme }) => theme?.spoxioBg};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.borderShade};
  border-radius: 20px;

  .other_icons {
    display: flex;
    flex-flow: row nowrap;
    height: 80%;
    align-items: center;
  }
`;

export const CreateEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CreateEventIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || '65px'};
  height: ${({ height }) => height || '65px'};
  z-index: ${({ zIndex }) => zIndex};
  border-radius: ${({ type }) => (type === 'sub' ? '10px' : '50%')};
  background: ${({ background }) =>
    background ? background : 'linear-gradient(98.25deg, #0094C9 5.01%, #0076DE 90.43%)'};
`;

export const BarDivider = styled.span`
  height: 10vh;
  width: 1px;
  background: ${({ theme }) => theme?.borderShade};
  margin-right: 5px;
  /* padding: 1px; */
`;

export const TrendingCardContainer = styled.div`
  height: 350px;
  min-width: 350px;
  margin-left: 24px;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.borderShade};
  border-radius: 20px;
`;

export const TrendingCardHeader = styled.div`
  ${({ theme }) => {
    return `
                .trending_event_details {
                    width: 50%;
                    height: 100%;
                    padding-top: 32px; 
                    display: inline-block;

                    .trending_event_date {
                        background: ${theme?.white};
                        width: 110px;
                        height: 45px;
                        border-radius: 10px;
                        display: block;
                        font-size: 18px;
                        color: ${theme?.spoxioText};
                        line-height: 45px;
                        text-align: center;
                        font-weight: 500;
                    }

                    .trending_event_title {
                        font-size: 18px;
                        font-weight: 510;
                        color: ${theme?.spoxioText};
                        margin: 24px 0px 8px 0px;
                    }

                    .trending_event_location {
                        color: ${theme?.spoxioText}
                    }

                    .location_pin {
                        display: inline-block;
                        margin-right: 8px;
                        width: 100%:
                        overflow: hidden;
                        text-wrap: nowrap;

                        img {       
                            width: 16px;
                            height: 16px;
                            margin-right: 8px;
                        }
                    }

                    .trending_event_live {
                        display: inline-block;
                        padding: 8px;
                        border-radius: 8px;
                        background: ${theme?.pink};
                        font-size: 10px;
                        font-weight: 590px;
                        margin-top: 24px;
                    }
                }
            `;
  }}
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  background: ${({ theme }) => theme?.spoxioBg};
  width: 100%;
  height: 80%;
  border-radius: 16px;
  box-sizing: border-box;
  padding-left: 16px;

  .trending_event_image {
    width: 50%;
    height: 100%;
    border-radius: 0px 16px 16px 0px;
    display: inline-block;
  }
`;

export const TrendingCardFooter = styled.div`
  margin-top: 2 ${({ mt }) => (mt ? mt : '0px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TrendingBarContainer = styled.div`
  width: 100%;
  /* max-width: 710px; */
  /* overflow-x: scroll;

  .overflow_buffer {
    display: flex;
    flex-flow: row nowrap;
  } */
`;

export const NextButton = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: none;
  background: ${({ theme }) => theme?.darkGray1};
  position: absolute;
  right: 0;
  top: 20%;
`;

// Events Container screen
export const EventsNavContainer = styled.div`
  display: flex;
`;

export const EventSpace = styled.div``;
export const Attending = styled.p`
  color: ${({ theme }) => theme.spoxioText};
`;

export const MyEventsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.spoxioBg};
  border: 1px solid ${({ theme }) => theme.borderShade};
  border-radius: 15px;
  align-items: center;
  padding: 5px;
`;
export const MyEventsLeft = styled.div`
  display: flex;
  padding: 5px;
  width: 70%;
  align-items: center;
  column-gap: 10px;
`;
export const EventsImgHolder = styled.div`
  border-radius: 14px;
  overflow: hidden;
  /* width: 100%;
  height: 100%; */
  width: 88px;
  height: 88px;
`;
export const EventsDetailsHolder = styled.div`
  display: flex;
  flex-direction: column;
`;
export const EventsDetailsTitle = styled(Title)`
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: SF Pro;
  font-size: 0.875rem;
  line-height: normal;
`;

export const EventsDetailsTime = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.spoxioBodyText};
`;
export const EventsDetailsDate = styled.p`
  margin: 0;
  display: flex;
  align-items: center;

  column-gap: 5px;
`;
export const JoinEventBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => (width ? width : '78px')};
  height: ${({ height }) => (height ? height : '35px')};
  background: ${({ theme, background }) => background || theme.opacityPrimaryBlue};
  color: ${({ theme, color }) => color || theme.spoxioAltBlue};
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.spoxioAltBlue};
`;
