import { forwardRef } from 'react';
import { bool, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { getTextByCount } from 'Utils/strings';
import { paths } from 'Utils/constants';
import { UserPic } from 'Components/shared/UserPic';
import { StatsItemContainer, Statistic, UserName, UserData, Stat, Checkbox } from './components';
import { ClickableContainer } from 'Components/shared/styledComponents';

export const StatsItem = forwardRef(({ item, name, checked, showCheckbox }, ref) => {
  const dispatch = useDispatch();

  const onUserClick = () => {
    dispatch(push(`${paths.PROFILE}/${item._id}`));
  };

  return (
    <StatsItemContainer>
      <ClickableContainer onClick={onUserClick}>
        <UserPic src={item?.picture} />
      </ClickableContainer>
      <UserData>
        <UserName fontWeight="bold" onClick={onUserClick}>
          {`${item?.firstName || ''} ${item?.lastName || ''}`}
        </UserName>
        <Statistic>
          <Stat fontWeight="bold">
            {item?.likeCount || 0} <span>{getTextByCount(item?.likeCount, 'star')}</span>
          </Stat>
          <Stat fontWeight="bold">
            {item?.viewCount || 0} <span>{getTextByCount(item?.viewCount, 'view')}</span>
          </Stat>
          <Stat fontWeight="bold">
            {item?.commentCount || 0} <span>{getTextByCount(item?.commentCount, 'comment')}</span>
          </Stat>
        </Statistic>
      </UserData>
      {showCheckbox ? <Checkbox ref={ref} name={name} checked={checked || false} /> : null}
    </StatsItemContainer>
  );
});

StatsItem.propTypes = {
  item: shape({}).isRequired,
  name: string.isRequired,
  checked: bool,
  showCheckbox: bool
};

StatsItem.defaultProps = {
  checked: false,
  showCheckbox: false
};
