import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  purchases: [],
  activePurchases: [],
  purchaseIntent: {}
};

// reducer
export const purchaseSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    setPurchases: (state, action) => {
      state.purchases = action.payload;
    },
    setActivePurchases: (state, action) => {
      state.activePurchases = action.payload;
    },
    setPurchasesIntent: (state, action) => {
      state.purchaseIntent = action.payload;
    }
  }
});

export const { setPurchases, setActivePurchases, setPurchasesIntent } = purchaseSlice.actions;
export const purchaseReducer = purchaseSlice.reducer;

// selectors
export const getStripePurchase = (state) => state.purchases.purchases;
export const getActiveStripePurchase = (state) => state.purchases.activePurchases;
export const getStripePurchaseIntent = (state) => state.purchases.purchaseIntent;
