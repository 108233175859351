import React, { createContext, useEffect, useState } from 'react';
// import { light, dark, globalColors } from '../utils/theme';
import { node } from 'prop-types';
import { darkTheme, globalColors, lightTheme } from 'Theme';
import useMedia from 'Hooks/useMedia';
import { ThemeProvider } from 'styled-components';

export const ThemeContext = createContext();

export default function ThemeContextProvider(props) {
  const [themeMode, setThemeMode] = useState('light');
  const [theme, setTheme] = useState(lightTheme);
  const prefersDarkMode = usePrefersDarkMode();

  useEffect(() => {
    getSavedTheme();
  }, []);

  const getSavedTheme = async () => {
    const localTheme = window.localStorage.getItem('themeMode');

    // console.log('theme list from context\n', themeMode, '\n', theme);

    if (localTheme === 'light') {
      setTheme(lightTheme);
    } else {
      setTheme(darkTheme);
    }
    setThemeMode(localTheme);
  };

  const toggleTheme = async () => {
    // console.log('context helloeooooo toggle');
    // console.log('here on context', themeMode, theme);
    
    if (themeMode === 'light') {
      setTheme(darkTheme);
      setThemeMode('dark');
      window.localStorage.setItem('themeMode', 'dark');
    } else {
      setTheme(lightTheme);
      setThemeMode('light');
      window.localStorage.setItem('themeMode', 'light');
    }
  };

  useEffect(() => {}, [themeMode, prefersDarkMode]);
  return (
    <ThemeContext.Provider value={{ Colors: theme, toggleTheme, themeMode, globalColors, theme }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}

ThemeContextProvider.propTypes = {
  children: node
};

ThemeContextProvider.defaultProps = {
  children: null
};

function usePrefersDarkMode() {
  return useMedia(['(prefers-color-scheme: dark)'], [true], false);
}
