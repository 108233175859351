import { forwardRef, useContext, useState } from 'react';
import { any, string } from 'prop-types';

import { BaseInput, IconContainer } from '../styledComponents';
import {
  FormInputContainer,
  ShowPasswordButton,
  HelperText,
  InputLabel,
  ErrorText
} from './components';

import { ReactComponent as EyeIcon } from 'Assets/icons/Eye.svg';
import { ReactComponent as EyeOffIcon } from 'Assets/icons/EyeOff.svg';
import { ThemeContext } from 'Context/themeContext';

export const FormInput = forwardRef(
  (
    {
      name,
      type,
      placeholder,
      helperText,
      className,
      label,
      error,
      defaultValue,
      marginBottom,
      ...props
    },
    ref
  ) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const { theme } = useContext(ThemeContext);

    const togglePassword = () => {
      setIsShowPassword(!isShowPassword);
    };

    return (
      <FormInputContainer mb={marginBottom} className={className}>
        {label ? <InputLabel>{label}</InputLabel> : null}
        <BaseInput
          ref={ref}
          type={type === 'password' && isShowPassword ? 'text' : type}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...props}
        />
        {type === 'password' ? (
          <>
            <ShowPasswordButton onClick={togglePassword}>
              {isShowPassword ? (
                <IconContainer margin="0" height="15px">
                  <EyeIcon color={theme?.spoxioIcon} />
                </IconContainer>
              ) : (
                <IconContainer margin="0" height="15px">
                  <EyeOffIcon color={theme?.spoxioIcon} />
                </IconContainer>
              )}
            </ShowPasswordButton>
          </>
        ) : null}
        {helperText && <HelperText>{helperText}</HelperText>}
        <ErrorText isShow={!!error}>{error}</ErrorText>
      </FormInputContainer>
    );
  }
);

FormInput.propTypes = {
  name: string.isRequired,
  type: string,
  placeholder: string,
  helperText: string,
  className: string,
  label: string,
  error: string,
  marginBottom: string,
  defaultValue: string,
  props: any
};

FormInput.defaultProps = {
  type: 'text',
  placeholder: 'Placeholder',
  helperText: '',
  className: '',
  label: '',
  error: ''
};
