import React from 'react';
import PropTypes from 'prop-types';
import { AboutTags, AboutText } from './components';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';

const ScoutProfileInfo = ({ about }) => {
  const { scoutProfile } = about;

  return (
    <ContentContainer column gap="4px" width="100%" margin="20px 0">
      {/* About the person self */}
      <ContentContainer row gap="12px" alignItems="center" flexWrap="wrap">
        <SubTitle fontWeight="600" fontSize="0.875rem">
          Company or club name:{' '}
        </SubTitle>
        <Title fontSize="0.875rem">{scoutProfile.club}</Title>
      </ContentContainer>
      <ContentContainer row gap="12px" alignItems="center" flexWrap="wrap">
        <SubTitle fontWeight="600" fontSize="0.875rem">
          Organisation:{' '}
        </SubTitle>
        <Title fontSize="0.875rem">{scoutProfile.organisation}</Title>
      </ContentContainer>
      <ContentContainer row gap="12px" alignItems="center" flexWrap="wrap">
        <SubTitle fontWeight="600" fontSize="0.875rem">
          Country:{' '}
        </SubTitle>
        <Title fontSize="0.875rem">{about.country.name}</Title>
      </ContentContainer>
    </ContentContainer>
  );
};

ScoutProfileInfo.propTypes = {
  about: PropTypes.object
};

export default ScoutProfileInfo;
