import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { isScout } from 'Utils';
import { paths } from 'Utils/constants';
import { getCurrentUser, getCurrentUserId } from 'Store/authSlice';
import { PageNavigation } from 'Components/Navigation';
import { BaseTitle, CenterContent } from 'Components/shared/styledComponents';
import { Stats } from 'Components/Stats';

import { fontSizes } from 'Theme';
import { useTranslation } from 'react-i18next';
import { MainNavigation } from 'Components/Layouts/MainNavigation';
import ScoutLayout from 'Components/Layouts/ScoutLayout/ScoutLayout';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { getProfileById } from 'Store/profileSlice';
import { useEffect } from 'react';

export const StatsPage = () => {
  const currentUser = useSelector(getCurrentUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(getCurrentUserId);

  const profile = useSelector(getProfileSelector);

  useEffect(async () => {
    dispatch(getProfileById(userId));
  }, [userId]);

  return isScout(currentUser.userType) ? (
    <ScoutLayout profile={profile}>
      <CenterContent>
        <BaseTitle fontSize={fontSizes.xLarge2} className="margin-top_small">
          {t('stats_for_scout')}
        </BaseTitle>
        <Stats />
      </CenterContent>
    </ScoutLayout>
  ) : (
    <Redirect to={paths.TIMELINE} />
  );
};
