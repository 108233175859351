import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import roleBg from 'Assets/img/role-bg-overlay.png';

import {
  BaseButton,
  BaseText,
  BlackText,
  CenteredContainer,
  hideScrollbar,
  RowContainer
} from 'Components/shared/styledComponents';

import { ReactComponent as VerificationBadgeIcon } from 'Assets/icons/BadgeVerified.svg';

export const BottomLinkContainer = styled.div`
  margin-top: 80px;
`;

export const LinkDescription = styled(BaseText)`
  /* color: ${({ theme }) => theme.neutralGray}; */
  color: ${({ theme }) => theme.black};
`;

export const AuthLink = styled(Link)`
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  color: ${({ theme, color }) => color || theme.spoxioAltBlue};
  font-family: SF Pro; //SF Pro Bold;
  width: ${({ width }) => width || 'auto'};
  text-align: ${({ textAlign }) => textAlign};
  padding: ${({ padding }) => padding};
  text-decoration: ${({ textDecoration }) => textDecoration};
`;

AuthLink.propTypes = {
  color: string,
  marginLeft: string
};

AuthLink.defaultProps = {
  color: '',
  marginLeft: undefined
};

export const AuthFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : '100%')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '2rem')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0.875rem')};
  /* height: 65vh; */
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding-bottom: 5px;
  ${hideScrollbar}
`;

export const AuthFormDescription = styled(BlackText)`
  width: 100%;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
`;

export const AuthFormButton = styled(BaseButton)`
  width: auto;
`;

export const AuthContent = styled(CenteredContainer)`
  /* width: 335px; */
  width: 100%;
  margin: 0 auto;
`;

export const VerificationContainer = styled(RowContainer)`
  width: 350px;
  align-items: flex-start;
`;

export const VerificationBadgeContainer = styled.div`
  position: relative;
  width: 40px;
  margin-right: 20px;
`;

export const VerificationGrayCircle = styled.div`
  width: 29px;
  height: 29px;
  background-color: ${({ theme }) => theme.lightGray};
  border-radius: 50%;
`;

export const VerificationIcon = styled(VerificationBadgeIcon)`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: -11px;
  right: -18px;
  color: #b7db90;
`;

export const ForgotPasswordLink = styled(Link)`
  width: auto;
  text-align: right;
  color: ${({ theme }) => theme.spoxioText};
  font-size: ${({ theme }) => theme.small};
`;

export const SocialInfoContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  width: 100%;
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => (gap ? gap : '4px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '50px')};
`;

SocialInfoContainer.propTypes = {
  direction: string
};

SocialInfoContainer.defaultProps = {
  direction: 'column'
};

export const SocialInfoUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DocumentationLink = styled(Link)`
  margin-left: 5px;
  color: ${({ theme, color }) => (color ? color : theme.black)};
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;

export const CapabilityItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 280px;
`;

export const CapabilityDescription = styled(BlackText)`
  max-width: 230px;
  font-size: ${({ theme }) => theme.xLarge}px;
  color: ${({ color }) => color};
`;

CapabilityDescription.propTypes = {
  color: string
};

CapabilityDescription.defaultProps = {
  color: 'inherit'
};

export const PaymentButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.primaryRed};
  border-color: ${({ theme }) => theme.primaryRed};
  max-width: 280px;
  font-family: SF Pro Bold;
`;

export const TrialPeriodButton = styled(BaseButton)`
  max-width: 280px;
`;

export const ButtonDescription = styled(BaseText)`
  width: 100%;
  max-width: 300px;
  margin-top: 5px;
  text-align: center;
  color: ${({ theme }) => theme.gray};
`;

export const LogoutButton = styled(BaseButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 70px;
  padding: 5px 0;
`;

export const RoleContainer = styled(RowContainer)`
  ${hideScrollbar}
  flex-wrap: wrap;
`;

export const RoleBgOverlay = styled.div`
  background-image: url(${roleBg});
  background-clip: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: -30%;
`;
