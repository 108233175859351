import { userRoles } from 'Utils/constants';

export const getProfileDefaultValues = (data = {}) =>
  data.userType === userRoles.SCOUT
    ? {
        picture: data?.picture || '',
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        email: data?.email || '',
        country: data?.country ? { label: data.country?.name, value: data?.country?._id } : '',
        club: data?.scoutProfile.club || '',
        organisation: data?.scoutProfile.organisation || '',
        scoutId: data?.scoutProfile.scoutId || ''
        // about: data?.about || ''
      }
    : data.userType === userRoles.CLUB
    ? {
        picture: data?.picture || '',
        sportCategory: data?.clubProfile
          ? { label: data.clubProfile?.sportCategory?.name, value: data.clubProfile?.sportCategory?._id }
          : { label: '', value: '' },
        name: data?.clubProfile.name,
        address: data?.clubProfile?.address,
        phoneNumber: data?.clubProfile?.phoneNumber,
        website: data?.clubProfile?.website
      }
    : {
        picture: data?.picture || '',
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        email: data?.email || '',
        dateOfBirth: data?.talentProfile?.dateOfBirth
          ? new Date(data.talentProfile?.dateOfBirth)
          : '',
        gender: data?.talentProfile?.gender
          ? { label: data.talentProfile?.gender, value: data?.talentProfile?.gender }
          : '',
        country: data?.country ? { label: data.country?.name, value: data?.country?._id } : '',
        weight: data?.talentProfile?.weight || '',
        height: data?.talentProfile?.height || '',
        bio: data?.talentProfile?.bio || ''
      };
