/* eslint-disable eqeqeq */
import { useContext, useEffect, useMemo, useState } from 'react';
import { shape } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Stories from 'react-insta-stories';

import { RouteProps } from 'Utils/propTypes';
import { paths } from 'Utils/constants';
import { getFormattedStories } from './helpers';
import { getStories } from 'Store/storiesSlice';
import {
  CloseStoriesButton,
  StoriesContainer,
  LogoImage,
  HeaderContainer,
  NavigationButtonsContainer,
  PrevButtonContainer,
  NextButtonContainer
} from './components';
import {
  ClickableContainer,
  ContentContainer,
  SpoxioBlueBtn
} from 'Components/shared/styledComponents';
import Logo from 'Assets/img/Logo.png';
import LogoSecondary from 'Assets/img/LogoSecondary.png';
import { ReactComponent as CloseIcon } from 'Assets/icons/Close.svg';
import { ReactComponent as PreviousIcon } from 'Assets/icons/CircleArrowLeft.svg';
import { ReactComponent as NextIcon } from 'Assets/icons/CircleArrowRight.svg';
import { ReactComponent as Info } from 'Assets/icons/More.svg';
import { getStateForStoriesRouting } from 'Utils';
import { getStoriesSelector } from 'Store/selectors/storiesSelector';
import { ThemeContext } from 'Context/themeContext';
import { getCurrentUserId } from 'Store/authSlice';
import { useTranslation } from 'react-i18next';
import SpoxioModal from 'Components/Modals/SpoxioModal';
import Api from 'middleware/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export const StoriesPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [togglePause, setTogglePause] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stories, setStories] = useState([]);
  const currentUserId = useSelector(getCurrentUserId);
  const allStories = useSelector(getStoriesSelector);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (!allStories?.length) {
      dispatch(getStories());
    } else {
      const { userStoryId } = location?.state;
      const currentStory = allStories.find((item) => item._id === userStoryId);
      console.log(
        allStories,
        '\n\r All storys\n\r',
        currentStory,
        '\n\rcurrent story\n\r',
        stories?.length,
        currentIndex
      );
      const formattedStories = getFormattedStories(currentStory);

      setStories(formattedStories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, allStories]);

  useEffect(() => {
    dispatch(getStories());

    return () => {
      getStories();
    };
  }, [dispatch]);

  const onClose = () => {
    history.replace(paths.TIMELINE);
  };

  // console.log('\n\r All stories\n\r', allStories, stories);

  const onUserClick = () => {
    const { userStoryId } = location?.state;
    history.push(`${paths.PROFILE}/${userStoryId}`);
  };

  const onStoryStart = (index) => {
    setTogglePause(true);
    setCurrentIndex(index);
    setTogglePause(false);
  };
  
  // console.log('\n\r then state', location);

  const onNext = () => {
    if (currentIndex + 1 < stories.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      const { next } = location?.state;
      console.log('\n\r then state', location);
      if (next) {
        setCurrentIndex(0);
        const state = getStateForStoriesRouting(allStories, next);
        console.log('\n\r then state', state);
        history.replace(`${paths.STORIES}/${state.userStoryId}`, state);
      } else {
        history.replace(paths.TIMELINE);
      }
    }
  };

  const onPrevious = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      const { prev } = location?.state;

      if (prev >= 0) {
        setCurrentIndex(0);
        const state = getStateForStoriesRouting(allStories, undefined, prev);
        history.replace(`${paths.STORIES}/${state.userStoryId}`, state);
      }
    }
  };

  const currentPostID = useMemo(() => {
    return stories[currentIndex]?._id;
  }, [currentIndex]);

  console.log("🚀 ~ file: index.jsx:135 ~ currentPostID ~ currentPostID:", currentPostID)

  const onAllStoriesEnd = (storyIndex) => {
    if (storyIndex + 1 < stories.length) {
      setCurrentIndex(storyIndex + 1);
    } else {
      const { next } = location?.state;
      if (next) {
        setCurrentIndex(0);
        const state = getStateForStoriesRouting(allStories, next);
        history.replace(`${paths.STORIES}/${state.userStoryId}`, state);
      } else {
        history.replace(paths.TIMELINE);
      }
    }
  };

  const onDeleteStory = async (data) => {
    console.log('Post will be deleted');
    try {
      const res = await Api.story.deleteStory(data);
      toast.warn(res?.data?.message.charAt(0).toUpperCase() + res?.data?.message.slice(1));
      setTimeout(() => {
        setShowDeleteDialog(!showDeleteDialog);
        onClose();
      }, 1500);
    } catch (error) {
      console.log('onDeletePost::', error);
      toast.error('Error');
    }
  };

  const confirmDelete = (data) => {
    Swal.fire({
      title: `Delete Story`,
      text: `You are about to delete current story`,
      showCancelButton: true,
      confirmButtonColor: theme?.spoxioAltBlue,
      cancelButtonColor: theme?.spoxioRed,
      confirmButtonText: 'Proceed',
      reverseButtons: true,
      imageUrl: data?.url,
      imageWidth: 200,
      imageHeight: 150,
      imageAlt: ''
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteStory(currentPostID);
      }
    });
  };

  return (
    <StoriesContainer>
      <HeaderContainer>
        <LogoImage src={Logo} />

        <CloseStoriesButton onClick={onClose}>
          <CloseIcon width={20} height={20} color={theme?.spoxioIcon} />
        </CloseStoriesButton>
      </HeaderContainer>
      {/* <UserHeader onClick={onUserClick} /> */}
      <ClickableContainer
        justifyContent="center"
        alignItems="center"
        style={{
          position: 'absolute',
          right: '21vw',
          top: '25px',
          background: theme?.spoxioIcon,
          borderRadius: '50%',
          padding: '5px',
          width: '24px',
          height: '24px',
          zIndex: 10000
        }}
      >
        <Info
          title={t('More')}
          color={theme?.spoxioIcon}
          onClick={() => {
            currentUserId === allStories[0]?.createdBy?._id
              ? setShowDeleteDialog(true)
              : setModalVisible(true);
          }}
        />
      </ClickableContainer>

      {stories?.length > currentIndex ? (
        <Stories
          stories={stories}
          currentIndex={currentIndex}
          width="60vw"
          height="100%"
          isPaused={showDeleteDialog || modalVisible}
          storyStyles={{ height: '100%', objectFit: 'contain' }}
          onStoryStart={onStoryStart}
          onAllStoriesEnd={onAllStoriesEnd}
        />
      ) : null}
      <NavigationButtonsContainer>
        <PrevButtonContainer
          onClick={onPrevious}
          isVisible={currentIndex || location?.state?.prev >= 0}
        >
          <PreviousIcon width={22} height={22} color={theme?.spoxioIcon} />
        </PrevButtonContainer>

        <NextButtonContainer onClick={onNext}>
          <NextIcon width={22} height={22} color={theme?.spoxioIcon} />
        </NextButtonContainer>
      </NavigationButtonsContainer>

      <SpoxioModal
        showCloseButton={true}
        visible={modalVisible}
        setVisible={() => setModalVisible(!modalVisible)}
        background={theme.defaultWhite}
        minHeight="240px"
        maxWidth="24vw"
      >
        <ClickableContainer>
          <img className="mobile-logo" src={LogoSecondary} alt="Spoxio" />
        </ClickableContainer>
        <ContentContainer gap="0px" justifyContent="center" alignItems="center" padding="20px">
          <SpoxioBlueBtn
            hover
            border={theme.mediumGray}
            background="transparent"
            color={theme.mediumGray}
            fontSize="1.1rem"
            // onClick={onReport}
          >
            {' '}
            Report Content
          </SpoxioBlueBtn>
          <SpoxioBlueBtn
            hover
            border={theme.spoxioRed}
            background="transparent"
            color={theme.spoxioRed}
            fontSize="1.1rem"
            onClick={() => {
              // onBlock();
              setTimeout(() => {
                setModalVisible(!modalVisible);
              }, 1500);
            }}
          >
            {t('block_user')}
          </SpoxioBlueBtn>
        </ContentContainer>
      </SpoxioModal>

      <SpoxioModal
        showCloseButton={true}
        visible={showDeleteDialog}
        setVisible={() => setShowDeleteDialog(!showDeleteDialog)}
        background={theme.defaultWhite}
        minHeight="240px"
        maxWidth="24vw"
      >
        <ClickableContainer>
          <img className="mobile-logo" src={LogoSecondary} alt="Spoxio" />
        </ClickableContainer>
        <ContentContainer justifyContent="center" alignItems="center" padding="20px">
          <SpoxioBlueBtn
            hover
            border={theme.mediumGray}
            background="transparent"
            color={theme.mediumGray}
            fontSize="1.1rem"
            onClick={() => {
              setShowDeleteDialog(!showDeleteDialog);
            }}
          >
            Cancel
          </SpoxioBlueBtn>
          <SpoxioBlueBtn
            hover
            border={theme.defaultWhite}
            background={theme.spoxioRed}
            color={theme.defaultWhite}
            fontSize="1.1rem"
            onClick={() => {
              confirmDelete();
            }}
          >
            {t('Delete')}
          </SpoxioBlueBtn>
        </ContentContainer>
      </SpoxioModal>
    </StoriesContainer>
  );
};

StoriesPage.propTypes = {
  user: shape({}),
  ...RouteProps
};

StoriesPage.defaultProps = {
  user: {}
};
