import styled, { css } from 'styled-components';
import { bool, number, string } from 'prop-types';
import { ToastContainer } from 'react-toastify';

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : '20px')};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const hideScrollbar = (props) => css`
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: center;
  gap: ${({ gap }) => gap || '35px'};
  height: auto;
  flex-wrap: wrap;
  width: ${({ width }) => (width ? width : '100%')};
`;

export const CenteredRowContainer = styled(RowContainer)`
  flex-direction: 'column' !important;
  gap: 10px;
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
  align-items: ${({ alignItems }) => alignItems};
`;

CenteredRowContainer.propTypes = {
  flexWrap: string
};

CenteredRowContainer.defaultProps = {
  flexWrap: 'wrap'
};

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 85vh;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const ContentContainer = styled.div`
  ${(props) => props.hideScroll && hideScrollbar}
  position: ${({ position }) => position || 'relative'};
  display: flex;
  flex: ${({ flex }) => flex};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-direction: ${({ column, row, flexDirection }) =>
    row ? 'row' : column ? 'column' : flexDirection ? flexDirection : 'column'};
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  min-height: ${({ minHeight }) => minHeight};
  height: ${({ height }) => height};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
  align-items: ${({ alignItems }) => alignItems};
  background: ${({ background }) => background};
  border-radius: ${({ borderRadius }) => borderRadius};
  gap: ${({ gap }) => (gap ? gap : '4px')};
  cursor: ${({ cursor }) => cursor};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ border }) => border || 'transparent'};
  overflow: ${({ overflow }) => overflow};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};

  &:hover {
    opacity: ${({ hover, opacity }) => (opacity && hover ? opacity : hover ? '0.85' : '1')};
  }
`;

export const BaseText = styled.span`
  font-family: ${({ fontWeight, fontFamily }) =>
    fontFamily ? fontFamily : fontWeight === 'bold' ? 'SF Pro Bold' : 'SF Pro'};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
  color: ${({ color, theme }) => (color ? color : theme.spoxioText)};
  text-align: ${({ textAlign }) => textAlign};
  width: ${({ width }) => (width ? width : 'auto')};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  text-overflow: ${({ textOverflow }) => textOverflow};
`;

BaseText.propTypes = {
  fontWeight: string,
  textAlign: string
};

BaseText.defaultProps = {
  fontWeight: 'normal',
  textAlign: 'left'
};

export const BaseTitle = styled.h2`
  margin: 0;
  text-align: ${({ textAlign }) => textAlign};
  font-family: SF Pro Bold;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.2rem')};
  color: ${({ theme }) => theme.gray};
`;
export const BaseSubtitle = styled.h4`
  margin: 0;
  text-align: ${({ textAlign }) => textAlign};
  font-weight: 'normal';
  font-family: SF Pro Bold;
  font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.xLarge3)}px;
  color: ${({ theme }) => theme.gray};
  opacity: 0.7;
`;

export const Title = styled(BaseText)`
  width: ${({ width }) => (width ? width : 'auto')};
  max-width: ${({ maxWidth }) => maxWidth};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '28px')};
  color: ${({ theme, color }) => color || theme.spoxioText};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.5rem')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '600')};
  text-transform: ${({ textTransform }) => textTransform};
  opacity: ${({ opacity }) => opacity};
  margin: ${({ margin }) => margin};

  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  text-overflow: ${({ textOverflow }) => textOverflow};
  font-family: ${({ fontFamily }) => fontFamily || 'SF Pro Bold'};
`;

export const SubTitle = styled(BaseText)`
  color: ${({ color, theme }) => color || theme.spoxioText};
  font-size: ${({ fontSize }) => fontSize || '1.15rem'};
  opacity: ${({ opacity }) => (opacity ? opacity : '0.6')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '600')};
  text-transform: ${({ textTransform }) => textTransform || 'none'};
  margin: ${({ margin }) => margin};
  cursor: ${({ cursor }) => cursor};
  font-family: ${({ fontFamily, fontWeight }) =>
    fontWeight === 'bold' ? 'SF Pro Bold' : fontFamily ? fontFamily : 'SF Pro'};

  &:hover {
    opacity: ${({ hover, opacity }) => (hover && opacity ? opacity : hover ? '0.875' : '0.6')};
  }
`;

BaseTitle.propTypes = {
  fontSize: string,
  textAlign: string
};

BaseTitle.defaultProps = {
  fontSize: '2rem',
  textAlign: 'center'
};

export const BaseInput = styled.input`
  width: 100%;
  /* min-height: ${({ minHeight }) => (minHeight ? minHeight : '50px')}; */
  padding: 14px 10px !important;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, border }) => (border ? border : theme.mediumGray)};
  font-family: SF Pro;
  font-size: ${({ theme }) => theme.medium}px;
  color: ${({ theme }) => theme.black};
  background-color: ${({ theme }) => theme.spoxioBg};
  border-radius: 8px;
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;

  ::placeholder {
    color: ${({ theme }) => theme.spoxioBodyText2};
    font-size: 0.85rem;
  }
`;

export const BaseTextArea = styled.textarea`
  width: 100%;
  min-height: 57px;
  padding: 10px 8px;
  /* border: none; */
  border-radius: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.mediumGray};
  font-family: SF Pro;
  font-size: ${({ theme }) => theme.large}px;
  color: ${({ theme }) => theme.black};
  background: ${({ theme }) => theme.background};

  ::placeholder {
    color: ${({ theme }) => theme.spoxioBodyText2};
    font-size: ${({ theme }) => theme.small}px;
  }
`;

export const BaseButton = styled.button`
  width: 100%;
  padding: 11px 4px;
  background-color: ${({ theme, variant }) =>
    variant === 'transparent' ? 'transparent' : theme.spoxioAltBlue};
  border: 1px solid
    ${({ theme, variant }) => (variant === 'transparent' ? 'transparent' : theme.spoxioAltBlue)};
  border-radius: 30px;
  font-family: SF Pro;
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  font-weight: ${({ variant, fontWeight }) => (variant === 'transparent' ? fontWeight : 700)};
  /* color: ${({ theme, variant }) =>
    variant === 'transparent' ? theme?.primaryBlue : theme.white}; */
  color: ${({ theme, variant }) =>
    variant === 'transparent' ? theme?.spoxioAltBlue : theme.defaultWhite};

  cursor: pointer;
`;

export const ButtonWrapper = styled.button`
  font-family: ${({ fontWeight }) => (fontWeight === 'bold' ? 'SF Pro Bold' : 'SF Pro')};
  font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.large)}px;
  color: ${({ theme, color }) => (color ? color : theme.defaultWhite)};
  width: ${({ width }) => (width ? width : '100%')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '10rem')};
  text-align: 'center';
  text-transform: capitalize;
  padding: ${({ padding }) => (padding ? padding : '10px')};
  margin: ${({ margin }) => (margin ? margin : '4px')};
  background: ${({ background }) =>
    background ? background : 'linear-gradient(98.25deg, #00a5c9 5.01%, #007aff 90.43%)'};
  border: ${({ border }) => (border ? border : `1px solid rgba(0, 148, 201, 0.2)`)};
  box-sizing: border-box;
  border-radius: 12px;
`;

export const ButtonOutline = styled(ButtonWrapper)`
  border-color: ${({ borderColor, theme }) => (borderColor ? borderColor : theme.spoxioAltBlue)};
  background: ${({ background, theme }) => (background ? background : theme.spoxioBg)};
  color: ${({ color, theme }) => (color ? color : theme.spoxioText)};
`;

BaseButton.propTypes = {
  fontWeight: number,
  variant: string
};

BaseButton.defaultProps = {
  variant: 'filled',
  fontWeight: 400
};

export const ActionButton = styled.button`
  /* display: flex;
  align-items: center; */
  height: ${({ height }) => height || 'fit-content'};
  min-height: ${({ minHeight }) => minHeight || '4vh'};
  min-width: ${({ minWidth }) => minWidth};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding || '10px 20px'};
  background: ${({ theme, background }) => background || theme.black};
  color: ${({ color, theme }) => (color ? color : theme.white)};
  border-radius: ${({ borderRadius }) => borderRadius || '12px'};
  font-size: ${({ fontSize }) => fontSize || '0.75rem'};
  cursor: pointer;
  border-width: 0.875px;
  border-style: solid;
  border-color: ${({ borderColor, theme }) => borderColor || theme.borderShade};

  &:hover {
    background: ${({ theme }) => theme.spoxioAltBlue};
    color: ${({ theme }) => theme.defaultWhite};
  }

  & > svg {
    margin-right: 10px;
  }

  @media (max-width: 810px) {
    padding: 8px 10px;
  }
`;

ActionButton.propTypes = {
  color: string
};

ActionButton.defaultProps = {
  // color: ''
};

export const ModalContent = styled.div`
  margin: auto auto;
  backdrop-filter: blur(4px);
  border-radius: ${({ borderRadius }) => borderRadius || '30px'};
  padding: ${({ padding }) => padding || '40px 25px'};
  width: ${({ width }) => width || '540px'};
  max-width: ${({ maxWidth }) => maxWidth || '80vw'};
  min-height: ${({ minHeight }) => minHeight || '320px'};
  min-width: ${({ minWidth }) => minWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  height: ${({ height }) => height};
  background: ${({ background, theme }) => background || theme.background};
`;

export const LandingButton = styled(BaseButton)`
  height: 48px;
  width: 290px;
  margin-top: 40px;
  border: none;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.primaryRed};
  color: ${({ theme }) => theme.defaultWhite};
  cursor: pointer;
`;

export const BlackText = styled(BaseText)`
  color: ${({ theme, color }) => color || theme.black};
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  width: ${({ width }) => (width ? width : '100%')};
`;

export const BarTitle = styled(BaseTitle)`
  margin: ${({ margin }) => margin || '12% 0 10px'};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
  text-align: ${({ textAlign }) => textAlign};
`;

BarTitle.propTypes = {
  textAlign: string
};

BarTitle.defaultProps = {
  textAlign: 'left'
};

export const ClickableContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap};

  & svg {
    fill: ${({ fill }) => fill};
    stroke: ${({ stroke }) => stroke};

    path {
      fill: ${({ fill }) => fill};
      stroke: ${({ stroke }) => stroke};
    }
  }
`;

export const CenterContent = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
  position: relative;
  margin: ${({ m }) => m};
  margin-top: ${({ mt }) => mt};
  border-radius: ${({ borderRadius }) => borderRadius || '30px'};
  padding: ${({ padding }) => padding || '20px'};
  border-width: 0.8px;
  border-style: solid;
  border-color: ${({ border, theme }) => (border ? theme.borderShade : 'transparent')};
  min-height: ${({ mh }) => mh};
`;

export const NotificationContainer = styled(ToastContainer)`
  .Toastify__toast {
    font-family: SF Pro;
    font-size: 1rem; //${({ theme }) => theme.large}px;
    border-radius: 8px;
  }

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.red};
  }

  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.yellow};
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.lightGreen};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || '1rem'};
`;

export const IconContainer = styled.span`
  display: flex;
  cursor: pointer;
  border-radius: ${({ borderRadius }) => borderRadius || '50%'};
  margin: ${({ margin }) => margin || '0 10px'};
  padding: ${({ padding }) => padding};
  border-width: ${({ borderWidth }) => borderWidth || '1px'};
  border-style: solid;
  border-color: ${({ border }) => border || 'transparent'};
  background: ${({ background }) => background};
  z-index: ${({ zIndex }) => zIndex};
  /* justify-content: center; */

  &:hover {
    opacity: ${({ opacity }) => opacity};
  }

  & svg {
    width: ${({ width }) => (width ? width : '27px')};
    height: ${({ height }) => (height ? height : '27px')};
    stroke: ${({ stroke }) => stroke};
    fill: ${({ fill, color, theme }) => fill || theme[color]};

    path {
      stroke: ${({ stroke }) => stroke};
      fill: ${({ fill, color, theme }) => fill || theme[color]};
    }
  }
`;

export const Divider = styled.div`
  width: ${({ horizontal, vertical, width }) =>
    horizontal ? '100%' : vertical ? '0.7px' : width ? width : 'auto'};
  height: ${({ horizontal, vertical, height }) =>
    vertical ? '8vh' : horizontal ? '0.7px' : height ? height : '100%'};
  padding: 1px;
  margin: ${({ margin }) => margin};
  background: ${({ theme }) => theme.borderShade};
`;

export const ReadMoreText = styled(BaseText)`
  font-family: SF Pro Bold;
  font-size: ${({ theme }) => theme.small}px;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
`;

export const Back = styled(BaseText)`
  display: flex;
  align-items: center;
  position: ${({ position }) => (position ? position : 'absolute')};
  top: 0;
  bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 6px;
  left: 0;
  color: ${({ theme }) => theme.opacityGray};
  font-size: ${({ theme }) => theme.small}px;
  line-height: 12px;
  cursor: pointer;

  & svg {
    width: 13px;
    height: 13px;
    margin-right: 7px;
  }
`;

export const NewNotificationBadge = styled.div`
  align-self: center;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ theme }) => theme.spoxioBlue};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

NewNotificationBadge.propTypes = {
  isVisible: bool
};

NewNotificationBadge.defaultProps = {
  isVisible: false
};

export const SpoxioBlueBtn = styled.button`
  background: ${({ theme, background }) => background || theme.spoxioLinear2};
  color: ${({ color }) => color || '#fff'};
  padding: ${({ padding }) => (padding ? padding : '0.875rem 2rem')};
  margin: 0 auto;
  font-family: SF Pro;
  min-height: ${({ minHeight }) => minHeight || '40px'};
  max-height: ${({ maxHeight }) => maxHeight};
  font-size: ${({ fontSize }) => fontSize || '0.75rem'};
  font-weight: ${({ fontWeight }) => fontWeight};
  width: ${({ width }) => (width ? width : '100%')};
  max-width: ${({ maxWidth }) => maxWidth};
  border-radius: ${({ borderRadius }) => borderRadius || '10px'};
  border-width: ${({ borderWidth }) => borderWidth || '1px'};
  border-style: solid;
  border-color: ${({ border }) => border || 'transparent'};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '20px')};
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }

  &:hover {
    opacity: ${({ hover, opacity }) => (hover ? '0.85' : opacity)};
  }
`;

export const AppStoreBtn = styled.img`
  width: 140px;
  height: 45px;
`;

export const GooglePlayBtn = styled.img`
  width: 140px;
  height: 40px;
`;

export const AppstoreBtnContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
