import { Close, FormContainer, Image, ImageContainer } from 'Components/PostModal/components';
import { DragDropFileInput } from 'Components/shared/Inputs/DragDropFilesInput';
import { CameraIcon } from 'Components/shared/Inputs/DragDropFilesInput/components';
import { FormDatePicker } from 'Components/shared/Inputs/FormDatePicker';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
import { FormTextArea } from 'Components/shared/Inputs/FormTextArea';
import { Loader } from 'Components/shared/Loader';
import {
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { Row, TwoRow } from 'Routes/EditProfile/components';
import { editClubMember, inviteClubMember } from 'Store/clubSlice';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { genderOptions } from 'Utils/constants';
import isEmpty from 'Utils/isEmpty';
import { emailRules, requiredRule } from 'Utils/validations';
import firebase from 'firebase/app';
import 'firebase/storage';
import moment from 'moment';
import PropTypes, { func, object } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const EditTeamMember = ({ clubRoles, selectedClubMember, clubInfo, closeModal }) => {
  console.log(
    '\n 🚀 ~ file: EditTeamMember.js:34 ~ EditTeamMember ~ selectedClubMember:',
    selectedClubMember
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { data } = useSelector(getProfileSelector);
  const [isLoading, setIsloading] = useState(false);

  const { register, reset, control, errors, handleSubmit, watch } = useForm();

  const watchValues = watch(['event_date']);

  const [files, setFiles] = useState([]);
  const [coverFiles, setCoverFiles] = useState([]);

  const defaultValues = {
    ...selectedClubMember
  };

  const selectFile = (base64Name, file) => {
    setFiles([...files, { base64Name, file }]);
  };

  const selectCoverFile = (base64Name, file) => {
    setCoverFiles([...coverFiles, { base64Name, file }]);
  };

  const removeFile = (index) => {
    const newListOfFiles = files.filter((value, currentIndex) => currentIndex !== index);
    setFiles(newListOfFiles);
  };

  const removeCoverFile = (index) => {
    const newListOfFiles = coverFiles.filter((value, currentIndex) => currentIndex !== index);
    setCoverFiles(newListOfFiles);
  };

  useEffect(() => reset(defaultValues), [reset, data]);

  useEffect(() => {}, [files, coverFiles]);

  const handleChange = (data) => {
    // if (isEmpty(coverFiles) || isEmpty(files)) {
    //   toast.warn('No profile photo or cover image uploaded');
    //   return;
    // }
    // if (data.age < 5) {
    //   toast.warn('Please review talent age');
    //   return;
    // }

    const payload = {
      ...data,
      picture: files[0],
      coverPicture: coverFiles[0],
      role: data.role.value,
      gender: data.gender.value
    };

    handleSubmitProfile(payload);
  };

  const handleSubmitProfile = async (payload) => {
    setIsloading(true);
    const { picture, coverPicture } = payload;

    if (
      picture !== 'https://www.freeiconspng.com/thumbs/profile-icon-png/profile-icon-9.png' &&
      picture !== null
    ) {
      const filename = `${clubInfo._id}-profilePhoto.jpg`;
      const storageRef = await firebase.storage().ref(`users/${filename}`);
      await storageRef
        .put(picture.file)
        .then(() => storageRef.getDownloadURL())
        .then(async (pictureUrl) => {
          console.log('🚀 ~ file: EditTeamMember.js:117 ~ .then ~ pictureUrl:', pictureUrl);
          payload.picture = pictureUrl;
        })
        .catch((error) => console.error(error));
    }

    if (
      coverPicture !==
        'http://beingcovers.com/media/facebook-cover/Netherlands-FootBall-facebook-covers-3551.jpg' &&
      coverPicture !== null
    ) {
      const filenamee = `${clubInfo._id}-coverImage.jpg`;
      const storageReff = await firebase.storage().ref(`users/${filenamee}`);
      await storageReff
        .put(coverPicture.file)
        .then(() => storageReff.getDownloadURL())
        .then(async (pictureUrl) => {
          console.log('🚀 ~ file: EditTeamMember.js:134 ~ .then ~ pictureUrl:', pictureUrl);
          payload.coverPicture = pictureUrl;
        })
        .catch((error) => console.error(error));
    }

    payload.age = +payload.age;
    payload.medals = payload.medals > 0 ? +payload.medals : payload.medals;
    payload.height = +payload.height;
    // payload.dateSigned = moment(payload.dateSigned).format();

    const data = { ...payload };
    const response = await dispatch(editClubMember(clubInfo._id, selectedClubMember?._id, data));

    if (response) {
      closeModal();
      setIsloading(false);
      return;
    }
    setIsloading(false);
  };

  return (
    <>
      {isLoading && <Loader size={24} background="rgba(0, 0, 0, 0.5)" />}
      <ContentContainer column width="100%" height="100%" padding="10px 0 0">
        <Title margin="20px 0 0">Edit player profile</Title>

        <ContentContainer maxHeight="90vh" height="100%" width="100%" overflowY="auto" hideScroll>
          <FormContainer onSubmit={handleSubmit(handleChange)}>
            <TwoRow width="100%" margin="20px 0 0">
              <Row width="100%">
                <FormInput
                  ref={register(requiredRule)}
                  name="name"
                  placeholder={t('Type a name')}
                  className="margin-bottom_medium"
                  error={errors?.name?.message}
                />
              </Row>
            </TwoRow>

            <TwoRow width="100%" margin="0">
              <Row width="48%" style={{ position: 'relative' }}>
                <FormInput
                  ref={register(requiredRule)}
                  name="age"
                  type="number"
                  placeholder={t('Age')}
                  error={errors?.age?.message}
                  className="margin-bottom_medium"
                />
              </Row>
              <Row width="48%" style={{ position: 'relative' }}>
                <FormInput
                  ref={register(requiredRule)}
                  name="height"
                  type="number"
                  placeholder={t('height')}
                  error={errors?.height?.message}
                  className="margin-bottom_medium"
                />
              </Row>
            </TwoRow>

            <TwoRow width="100%" margin="0">
              <Row width="48%" style={{ position: 'relative' }}>
                <FormDatePicker
                  ref={register(requiredRule)}
                  control={control}
                  name="dateSigned"
                  placeholder={t('Date Signed')}
                  defaultValue={moment(watchValues.dateSigned).format('YYYY-MM-DD')}
                  rules={requiredRule}
                  error={errors?.dateSigned?.message}
                  // className="margin-bottom_medium"
                  isFixed={false}
                />
              </Row>
              <Row width="48%" style={{ position: 'relative' }}>
                <FormInput
                  ref={register(requiredRule)}
                  name="medals"
                  type="number"
                  placeholder={t('Medals/ Awards')}
                  error={errors?.medals?.message}
                  // className="margin-bottom_medium"
                />
              </Row>
            </TwoRow>

            <TwoRow width="100%" margin="0 0 20px 0">
              <Row width="48%" style={{ position: 'relative' }}>
                <FormSelect
                  control={control}
                  rules={{
                    required: 'Please, assign a role'
                  }}
                  ref={register(requiredRule)}
                  name="role"
                  options={clubRoles || []}
                  placeholder={defaultValues?.role?.name}
                  isSearchable
                  error={errors?.role?.message}
                  isFixed={false}
                  // disabled={true}
                />
              </Row>
              <Row width="48%" style={{ position: 'relative' }}>
                <FormSelect
                  ref={register(requiredRule)}
                  control={control}
                  rules={requiredRule}
                  name="gender"
                  options={genderOptions}
                  placeholder={t('gender')}
                  error={errors?.gender?.message}
                  isFixed={false}
                  // className="margin-bottom_medium"
                />
              </Row>
            </TwoRow>

            <FormTextArea
              ref={register(requiredRule)}
              name="bio"
              placeholder={t('About player')}
              error={errors?.bio?.message}
            />

            <ContentContainer column width="100%" height="100%">
              <SubTitle fontSize="1rem" margin="10px 0">
                Upload profile photo and cover image
              </SubTitle>

              <ContentContainer row overflowX="auto" gap="10px" padding="0" hideScroll>
                {files.length
                  ? files.map((file, index) => (
                      <ImageContainer key={index} height="125px" minWidth="10vw" width="145px">
                        <Image
                          src={file.base64Name}
                          alt={t('Profile Photo')}
                          height="125px"
                          width="145px"
                          style={{ inlineSize: '-webkit-fill-available' }}
                        />
                        <IconContainer
                          width="20px"
                          height="20px"
                          fill={theme?.spoxioIcon}
                          margin="0">
                          <Close title="Remove" onClick={() => removeFile(index)} />
                        </IconContainer>
                      </ImageContainer>
                    ))
                  : null}

                {files.length === 0 ? (
                  <ContentContainer
                    background={theme?.tabActiveBg}
                    padding="8px"
                    borderRadius="20px"
                    height="fit-content">
                    <DragDropFileInput
                      onChange={selectFile}
                      acceptedFormat="image/*"
                      customIcon={
                        <ContentContainer
                          height="125px"
                          minWidth="10vw"
                          width="125px"
                          justifyContent="center"
                          alignItems="center">
                          <Image
                            src={defaultValues?.picture}
                            alt={t('Profile Photo')}
                            height="125px"
                            style={{ inlineSize: '-webkit-fill-available', opacity: '0.45' }}
                          />
                          <ContentContainer column position="absolute" alignItems="center">
                            <CameraIcon color={theme?.spoxioText} />
                            <SubTitle fontSize="0.75rem" fontWeight="bold">
                              Profile photo
                            </SubTitle>
                          </ContentContainer>
                        </ContentContainer>
                      }
                    />
                  </ContentContainer>
                ) : null}

                {coverFiles.length
                  ? coverFiles.map((file, index) => (
                      <ImageContainer key={index} height="125px" minWidth="10vw" width="145px">
                        <Image
                          src={file.base64Name}
                          alt={t('Cover Image')}
                          height="125px"
                          width="145px"
                          style={{ inlineSize: '-webkit-fill-available' }}
                        />
                        <IconContainer
                          width="20px"
                          height="20px"
                          fill={theme?.spoxioIcon}
                          margin="0">
                          <Close title="Remove" onClick={() => removeCoverFile(index)} />
                        </IconContainer>
                      </ImageContainer>
                    ))
                  : null}

                {coverFiles.length === 0 ? (
                  <ContentContainer
                    background={theme?.tabActiveBg}
                    padding="8px"
                    borderRadius="20px"
                    height="fit-content">
                    <DragDropFileInput
                      onChange={selectCoverFile}
                      acceptedFormat="image/*"
                      customIcon={
                        <ContentContainer
                          height="125px"
                          minWidth="10vw"
                          width="125px"
                          justifyContent="center"
                          alignItems="center">
                          <Image
                            src={defaultValues?.coverPicture}
                            alt={t('Cover Image')}
                            height="125px"
                            style={{ inlineSize: '-webkit-fill-available', opacity: '0.35' }}
                          />
                          <ContentContainer column position="absolute" alignItems="center">
                            <CameraIcon color={theme?.spoxioText} />
                            <SubTitle fontSize="0.75rem" fontWeight="bold">
                              Cover Image
                            </SubTitle>
                          </ContentContainer>
                        </ContentContainer>
                      }
                    />
                  </ContentContainer>
                ) : null}
              </ContentContainer>
            </ContentContainer>

            <ContentContainer width="100%">
              <SpoxioBlueBtn type="submit" maxWidth="320px">
                Save and update player profile
              </SpoxioBlueBtn>
            </ContentContainer>
          </FormContainer>
        </ContentContainer>
      </ContentContainer>
    </>
  );
};

EditTeamMember.propTypes = {
  clubRoles: PropTypes.arrayOf(PropTypes.object),
  selectedClubMember: object,
  handleChange: func,
  closeModal: func,
  clubInfo: object
};

EditTeamMember.defaultPropTypes = {
  handleChange: () => {},
  closeModal: () => {}
};

export default EditTeamMember;
