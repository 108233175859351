import React, { useContext } from 'react';
import { EventItemContainer } from './components';
import { ReactComponent as LocationSvg } from 'Assets/icons/Location.svg';
import { object } from 'prop-types';
import {
  ClubEventsMain,
  ClubEventTime,
  ClubLeftSide,
  EventsTime
} from 'Components/OtherProfile/Club/components';
import { ThemeContext } from 'Context/themeContext';
import { formatDate } from 'Utils/dates';
import {
  ContentContainer,
  IconContainer,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import isEmpty from 'Utils/isEmpty';
import EventsPics from 'Assets/img/event-calendar.png';
import fallbackSrc from 'Assets/img/event-placeholder4.jpeg';
const EventItem = ({ event }) => {
  const { theme, themeMode } = useContext(ThemeContext);

  return (
    <EventItemContainer>
      <ContentContainer row flexWrap="nowrap" padding="0" gap="0" cursor="pointer" maxWidth="400px">
        <ClubLeftSide
          marginRight="0"
          width="50%"
          themeMode={themeMode}
          height="280px"
          justifyContent="space-around"
        >
          <EventsTime minHeight="2vh">{formatDate(event?.event_date, 'DD MMM')}</EventsTime>
          <ContentContainer column width="100%" margin="10px 0" gap="40px 0">
            <Title width="100%" fontSize="1rem" fontWeight="bold" lineHeight="1.25rem">
              {event?.title || 'Title'}
            </Title>
            <ContentContainer row width="100%" alignItems="center">
              <IconContainer width="12px" height="10px" fill={theme?.mediumGray} margin="0">
                <LocationSvg />
              </IconContainer>

              <SubTitle width="auto" fontSize="0.75rem">
                {event.venue || 'Venue'}
              </SubTitle>
            </ContentContainer>
          </ContentContainer>
          {/* <ClubEventTime live width="55%" lineHeight="normal">
            LIVE NOW
          </ClubEventTime> */}
        </ClubLeftSide>
        <ContentContainer width="50%" padding="0" height="280px">
          {!isEmpty(event?.photos) ? (
            <img
              src={event?.photos[0]}
              alt={''}
              width="100%"
              height="100%"
              style={{
                // background: theme.black,
                objectFit: 'cover',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px'
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = fallbackSrc;
                currentTarget.style.objectFit = 'cover';
              }}
            />
          ) : (
            <img
              src={fallbackSrc}
              alt={''}
              width="100%"
              height="100%"
              style={{
                // background: theme.black,
                objectFit: 'cover',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px'
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = fallbackSrc;
                currentTarget.style.objectFit = 'fill';
              }}
            />
          )}
        </ContentContainer>
      </ContentContainer>
    </EventItemContainer>
  );
};

EventItem.propTypes = {
  event: object
};

export default EventItem;
