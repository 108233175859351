// import { AboutTags, IconContainer, ModalContent } from 'Components/OtherProfile/components'
import React from 'react';
import PropTypes, { any, array, arrayOf, func, object, shape } from 'prop-types';
import { ReactComponent as CloseModal } from '../../../Assets/icons/circle-back.svg';
import { CreateEventButton, InputForm } from './components';
import { ContentContainer, IconContainer, SubTitle } from 'Components/shared/styledComponents';
import { useTranslation } from 'react-i18next';
import { FormContainer } from 'Components/PostModal/components';
import { requiredRule } from 'Utils/validations';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { getFormData, setFormData } from 'Store/selectors/dataSelector';
import { useForm } from 'react-hook-form';
import { formatDate } from 'Utils/dates';

const SchedueModalDetails = ({ setActive, setSchedule, schedule, formData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, watch, errors, handleSubmit } = useForm();
  const watchValues = watch(['date']);

  const handleScheduleData = (data) => {
    console.log(data, 'schedule');
    const sched = [];
    sched.push({ ...data, date: new Date(data.date) });

    setSchedule([...schedule, ...sched]);
    dispatch(setFormData({ ...formData, schedules: [...schedule, ...sched] }));
    setActive(false);
  };

  return (
    <>
      <IconContainer
        onClick={() => {
          setActive(false);
        }}
      >
        <CloseModal />
      </IconContainer>

      <ContentContainer row justifyContent="center" margin="10px 0 8%">
        <SubTitle fontSize="2.25rem" fontWeight="bold">
          Add a schedule
        </SubTitle>
      </ContentContainer>

      {/* Form Input  */}

      <FormContainer onSubmit={handleSubmit(handleScheduleData)}>
        <FormInput
          ref={register(requiredRule)}
          name="title"
          type="text"
          placeholder={t('Schedule Title')}
          error={errors?.title?.message}
          className="margin-bottom_small"
        />

        <FormInput
          ref={register(requiredRule)}
          name="description"
          type="text"
          placeholder={t('Description')}
          error={errors?.description?.message}
          className="margin-bottom_small"
        />

        <FormInput
          ref={register(requiredRule)}
          rules={requiredRule}
          name="date"
          type="date"
          value={watchValues.date}
          placeholder={t('Date')}
          error={errors?.date?.message}
          className="margin-bottom_small"
          min={formatDate(new Date(), 'YYYY/MM/DD')}
          // max={formatDate(new Date())}
        />

        <ContentContainer
          row
          flexWrap="wrap"
          width="100%"
          alignItems="center"
          gap="12px"
          justifyContent="space-between"
        >
          <ContentContainer width="45%">
            <FormInput
              ref={register(requiredRule)}
              name="start_time"
              type="time"
              placeholder={t('Start Time')}
              error={errors?.start_time?.message}
              className="margin-bottom_small"
            />
          </ContentContainer>
          <ContentContainer width="45%">
            <FormInput
              ref={register(requiredRule)}
              name="end_time"
              type="time"
              placeholder={t('End Time')}
              error={errors?.end_time?.message}
              className="margin-bottom_small"
            />
          </ContentContainer>
        </ContentContainer>

        <ContentContainer column width="100%" gap="0px" margin="auto 0 0">
          <CreateEventButton
            onClick={() => {
              // setActive(false);
            }}
          >
            Save Schedule
          </CreateEventButton>
        </ContentContainer>
      </FormContainer>
    </>
  );
};
SchedueModalDetails.propTypes = {
  setActive: func,
  setSchedule: func,
  formData: any,
  schedule: arrayOf(shape({}))
};
export default SchedueModalDetails;
