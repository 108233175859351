import styled from 'styled-components';
import {
  BaseButton,
  BaseText,
  BaseTitle,
  BlackText,
  IconContainer
} from 'Components/shared/styledComponents';
import { FormInput } from 'Components/shared/Inputs/FormInput';

import { ReactComponent as Edit } from 'Assets/icons/Edit.svg';
import { ReactComponent as Comment } from 'Assets/icons/Comment.svg';
import ProfileImg from 'Assets/img/profile_background2.png';

export const ProfileContainer = styled.div`
  width: 100%;
  max-width: 700px;
  min-height: calc(90vh - 110px);
  margin: 40px auto;
  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ProfileHeadContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: end;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 10px 0 0px;
  /* margin-top: 20px; //100px; */
  min-height: 240px;
  color: ${({ theme }) => theme.lightGray1};
  border-radius: 20px;
  background-image: url(${ProfileImg ? ProfileImg : 'none'});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const UserImage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 14px;
  padding: 10px;
  background: ${({ mode }) =>
    mode === 'light'
      ? 'transparent'
      : `linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 80%)`};
`;

export const UserName = styled(BaseTitle)`
  font-size: ${({ fontSize }) => fontSize || '24px'};
  font-weight: 700;
  text-transform: capitalize;
  color: ${({ theme }) => theme.defaultWhite};
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const Sport = styled(BaseText)`
  margin: 3px 0;
  color: ${({ theme }) => theme.lightGray};
`;

export const Statistic = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin: 15px 0;
`;

export const StatisticItem = styled(BaseText)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  color: ${({ theme }) => theme.defaultLightGray};
  opacity: 0.8;
  font-size: 0.875rem;
  text-transform: capitalize;
  cursor: pointer;

  & span {
    font-size: ${({ theme }) => theme.xLarge}px;
    font-family: SF Pro Bold;
  }
`;

export const Description = styled(BaseText)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 20px;
  line-height: 22px;
  color: ${({ theme }) => theme.lightGray};
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 23px;
  margin: 0 10px;
  position: relative;
  border-radius: 20px;
  background: ${({ theme, isFollowed }) => (isFollowed ? theme.primaryBlue : theme.black)};
  color: ${({ theme }) => theme.white};
  font-family: SF Pro;
  font-size: ${({ theme }) => theme.medium}px;
  cursor: pointer;

  @media (max-width: 810px) {
    padding: 8px 10px;
  }
`;

export const EditBtn = styled(Button)`
  position: absolute;
  bottom: -18px;
`;

export const BtnContainer = styled.div`
  position: absolute;
  bottom: -18px;
  display: flex;
`;

export const TabContainer = styled.div`
  margin-top: 80px;
`;

export const FollowSportsContainer = styled.div`
  width: 60%;
  margin: 40px auto;
  position: relative;
`;

export const Title = styled.div`
  margin: 20px 0;
  text-align: center;
  font-size: 20px;
`;

export const Popular = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
`;

export const EditIcon = styled(Edit)`
  margin-right: 10px;

  & path {
    fill: ${({ theme }) => theme.white};
  }
`;

export const CommentIcon = styled(Comment)`
  margin-right: 10px;

  & path {
    fill: ${({ theme }) => theme.white};
  }
`;

export const SubmitBtn = styled(BaseButton)`
  margin-top: 40px;
`;

export const Input = styled(FormInput)`
  margin: 20px 0;
`;

export const Head = styled.div`
  margin-top: 100px;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
`;

export const HeadUserName = styled(BaseTitle)`
  font-size: ${({ theme }) => theme.xLarge}px;
`;

export const FollowContainer = styled.div`
  width: 100%;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 210px;
  margin: auto;
  font-family: SF Pro;
  font-size: ${({ theme }) => theme.large}px;
  text-align: center;

  & svg {
    margin-bottom: 20px;
    width: 70px;
    height: 70px;
    & path {
      fill: ${({ theme }) => theme.opacityGray};
    }
  }
`;

export const LabelPosts = styled(BlackText)`
  margin-bottom: 35px;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: capitalize;
  color: ${({ theme }) => theme.spoxioBodyText};
`;

// profile details such has the username and verified scout

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

// below the cover page
export const StatisticsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;
export const HamburgerHolder = styled(IconContainer)`
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
`;
