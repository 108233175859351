import { bool, func, string } from 'prop-types';
import { Back } from '../styledComponents';

import { ReactComponent as BeforeIcon } from 'Assets/icons/Before.svg';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

export const BackButton = ({
  label,
  onClick,
  substep,
  customGoBack = () => {
    return null;
  }
}) => {
  const dispatch = useDispatch();
  const onGoBack = async () => {
    dispatch(goBack());
  };
  return (
    <Back onClick={onClick}>
      <BeforeIcon />
      {label}
    </Back>

    // <BackButton
    //   onClick={() => {
    //     substep ? customGoBack() : onGoBack();
    //   }}
    // />
  );
};

BackButton.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
  substep: bool,
  customGoBack: func
};
