import { ReactComponent as Football } from 'Assets/icons/FootballIcon.svg';
import { ReactComponent as Golf } from 'Assets/icons/Golf.svg';
import { ReactComponent as AmericanFootball } from 'Assets/icons/AmericanFootball.svg';
import { ReactComponent as Baseball } from 'Assets/icons/Baseball.svg';
import { ReactComponent as Basketball } from 'Assets/icons/Basketball.svg';
import { ReactComponent as Boxing } from 'Assets/icons/boxing-icon.svg';
import { ReactComponent as Flag } from 'Assets/icons/Flag.svg';
import { ReactComponent as CreateEventVector } from 'Assets/icons/VectorCreateEvent.svg';
import { ReactComponent as CreateFootballVector } from 'Assets/icons/FootballIcon.svg';
import { ReactComponent as CreateASVector } from 'Assets/icons/AmericanFootball.svg';
import { ReactComponent as CreateBasketballVector } from 'Assets/icons/Basketball.svg';
import { ReactComponent as CreateBadmintonVector } from 'Assets/icons/Badminton.svg';
import { ReactComponent as CreateGolfVector } from 'Assets/icons/NewGolf.svg';
import { ReactComponent as CreateTennisVector } from 'Assets/icons/tennis.svg';

const getSportIcon = (name) => {
  const icons = {
    Football: {
      event: 'Football',
      icon: CreateFootballVector,
      background: 'linear-gradient(144.52deg, #2B1C12 22.1%, #4D280F 92.24%)',
      color: '#FA6400'
    },
    Basketball: {
      event: 'Basketball',
      icon: CreateBasketballVector,
      background: 'linear-gradient(141.26deg, #2B2412 26.28%, #423D17 96.6%)',
      color: '#F7B500'
    },
    Boxing: {
      event: 'Boxing',
      icon: Boxing,
      background: 'linear-gradient(140.32deg, #12202B 28.88%, #1D3761 97.69%)',
      color: '#AAFA00'
    },
    Golf: {
      event: 'Golf',
      icon: CreateGolfVector,
      background: 'linear-gradient(140.32deg, #122B22 28.88%, #1D614D 97.69%)',
      color: '#C0F6FF'
    },
    'American Football': {
      event: 'American Football',
      icon: CreateASVector,
      background: 'linear-gradient(140.32deg, #12202B 28.88%, #1D3761 97.69%)',
      color: '#007AFF'
    },
    Baseball: {
      event: 'Baseball',
      icon: Basketball,
      background: 'linear-gradient(140.32deg, #122B22 28.88%, #1D614D 97.69%)',
      color: '#C0F6FF'
    },
    Tennis: {
        event: 'Tennis',
        icon: CreateTennisVector,
        background: 'linear-gradient(140.32deg, #122B22 28.88%, #1D614D 97.69%)',
        color: '#C0F6FF'
      }
  };

  return icons[name];
};

export default getSportIcon;
