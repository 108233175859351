import React, { useEffect, useState } from 'react';
import ScoutPic from '../../../Assets/img/scoutprofile.png';
import { AboutTags, ParagraphText } from '../components';
import { ClubEventsMain, ClubImageContainer } from './components';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { UserPic } from 'Components/shared/UserPicCustom';
import { useDispatch, useSelector } from 'react-redux';
import { paths } from 'Utils/constants';
import { push } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import { fetchClubMembers } from 'Store/clubSlice';
import { transformArr } from 'Utils';
import { getProfileSelector } from 'Store/selectors/profileSelector';

const ClubTeam = () => {
  const dispatch = useDispatch();
  const [membersLoading, setMemberLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const profile = useSelector(getProfileSelector);
  const { data } = profile;
  // console.log('🚀 ~ file: ClubTeam.js:20 ~ ClubTeam ~ profile:', profile);
  const onUserClick = (id) => dispatch(push(`${paths.PROFILE}/${id}`));
  const clubInfo = data?.clubProfile;

  const fetchAndSaveMembers = async () => {
    setMemberLoading(true);
    const result = await dispatch(fetchClubMembers(clubInfo?._id));
    // console.log('🚀 ~ file: ClubTeam.js:27 ~ fetchAndSaveMembers ~ result:', result);
    let members = transformArr(result);
    setMembers(members);
    setMemberLoading(false);
  };

  useEffect(() => {
    fetchAndSaveMembers();
    return () => {
      fetchAndSaveMembers();
    };
  }, [clubInfo?._id, dispatch]);

  return (
    <ContentContainer column gap="16px" flexWrap="wrap" margin="20px 0" width="100%">
      {members.map((category) => {
        return (
          <ContentContainer
            key={uuidv4()}
            column
            cursor="pointer"
            gap="4px"
            width="100%"
            maxWidth="650px"
            hideScroll
            // onClick={() => onUserClick(data?.id)}
          >
            <Title textTransform="uppercase" fontSize="1.25rem" lineHeight="normal" opacity="0.5">
              {category.name}
            </Title>

            <ContentContainer
              row
              width="100%"
              alignItems="center"
              flexWrap="nowrap"
              style={{ overflowX: 'auto' }}
            >
              {category?.data?.map((player, i) => {
                return (
                  <ContentContainer
                    key={uuidv4()}
                    column
                    cursor="pointer"
                    gap="4px"
                    width="100%"
                    minWidth="150px"
                    maxWidth="150px"
                    hideScroll
                    // onClick={() => onUserClick(data?.id)}
                  >
                    <UserPic src={player?.picture} size="100%" />
                    <Title fontSize="0.875rem" lineHeight="normal" textAlign="center">
                      {player?.user.firstName} {player?.user.lastName}
                    </Title>
                    {/* <Title fontSize="0.75rem" lineHeight="normal" textAlign="center">
                                  <SubTitle fontSize="inherit" lineHeight="normal">
                                    Origin:{' '}
                                  </SubTitle>{' '}
                                  Nigeria
                                </Title> */}
                  </ContentContainer>
                );
              })}
            </ContentContainer>
          </ContentContainer>
        );
      })}
    </ContentContainer>
  );
};

export default ClubTeam;
