import { MainNavigation } from 'Components/Layouts/MainNavigation';
import { NotificationItem } from 'Components/Layouts/MainNavigation/NavBar/NotificationItem';
import { AboutTags, DetailsPage } from 'Components/OtherProfile/components';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { Loader } from 'Components/shared/Loader';
import { ContentContainer } from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getCurrentUserId } from 'Store/authSlice';
import { fetchNotifications } from 'Store/dataSlice';
import { getIsNotificationLoading } from 'Store/loaderSlice';
import { getNotificationSelector } from 'Store/selectors/dataSelector';
import isEmpty from 'Utils/isEmpty';

const Notification = () => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const loading = useSelector(getIsNotificationLoading);
  const currentUserId = useSelector(getCurrentUserId);
  const notifications = useSelector(getNotificationSelector);
  const getNotifications = async () => {
    await dispatch(fetchNotifications());
  };

  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <MainNavigation navTitle={'Notification'} showSports={false}>
      <DetailsPage mh="80vh">
        <ContentContainer width="100%" gap="12px" height="100%">
          {loading ? (
            <ContentContainer
              column
              height="100%"
              alignItems="center"
              justifyContent="center"
              minHeight="80vh"
            >
              <Loader size={24} height="100%" />
            </ContentContainer>
          ) : isEmpty(notifications) ? (
            <ContentContainer column width="100%" alignItems="center" justifyContent="center">
              <EmptyContent description={t('no_notification')} />
            </ContentContainer>
          ) : (
            notifications?.map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                currentUserId={currentUserId}
                getNotifications={getNotifications}
                turnedOffList={currentUser?.turnedOffNotifications}
              />
            ))
          )}
        </ContentContainer>
      </DetailsPage>
    </MainNavigation>
  );
};

export default Notification;
