import { number, bool, string, object } from 'prop-types';

import { UserPicContainer, LiveIconContainer, AvatarContainer, VerifiedIcon } from './components';

import DefaultUserAvatar from 'Assets/img/DefaultUserAvatar.png';
import { ReactComponent as LiveIcon } from 'Assets/icons/Live.svg';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const UserPic = ({ src, size, active, live, verified, borderRadius, containerStyle}) => {
  const { t } = useTranslation();
  return (
    <UserPicContainer size={size} style={containerStyle}>
      <AvatarContainer size={size} active={active} live={live} borderRadius={borderRadius}>
        <LazyLoadImage effect='blur' src={src || DefaultUserAvatar} width={size} height={size} alt={t('user_profile')} />
      </AvatarContainer>
      {verified ? <VerifiedIcon /> : null}
      {live ? (
        <LiveIconContainer>
          <LiveIcon />
        </LiveIconContainer>
      ) : null}
    </UserPicContainer>
  );
};

UserPic.propTypes = {
  src: string,
  borderRadius: string,
  size: string,
  containerStyle: object,
  active: bool,
  live: bool,
  verified: bool
};

UserPic.defaultProps = {
  src: '',
  size: '40px',
  borderRadius: '10px',
  active: false,
  live: false,
  verified: false
};
