import { any, func, arrayOf, shape, string, number } from 'prop-types';

export const HistoryProp = shape({
  push: func.isRequired,
  goBack: func,
  replace: func
}).isRequired;

export const LocationProp = shape({
  pathname: string.isRequired,
  state: any
}).isRequired;

export const RouteProps = {
  history: HistoryProp,
  location: LocationProp
};

export const PostItemProp = shape({
  type: string,
  url: string
});

export const PostItemListProps = arrayOf(PostItemProp);

export const PostCommentProp = shape({
  id: number,
  author: string,
  likes: arrayOf(string),
  parent: number,
  text: string
});

export const PostCommentListProp = arrayOf(PostCommentProp);

export const PostProp = shape({
  id: string,
  uid: string,
  address: string,
  createdAt: string,
  hashtags: arrayOf(string),
  likes: arrayOf(string),
  posts: PostItemListProps,
  sport: shape({
    _id: string,
    name: string
  }),
  text: string
});

export const PostListProps = arrayOf(PostProp);
