import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  comments: [],
  replies: []
};

export const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    setCommentReplies: (state, action) => {
      state.replies = action.payload;
    }
  }
});

// actions
export const { setComments, setCommentReplies } = commentSlice.actions;

// reducer
export const commentsReducer = commentSlice.reducer;

// selectors
export const getCommentsSelector = (state) => state?.comments?.comments;
export const getRepliesSelector = (state) => state?.comments?.replies;
