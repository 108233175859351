import { bool, string } from 'prop-types';

import { PostListProps } from 'Utils/propTypes';
import { Stories } from 'Components/Stories';
import { Post } from 'Components/shared/Post';
import { PostListContainer } from './components';

export const PostsList = ({ postList, className, showStories }) => (
  <>
    {showStories ? <Stories /> : null}
    <PostListContainer className={className} maxWidth="640px">
      {postList?.map((post) => (
        <Post key={post._id} data={post} />
      ))}
    </PostListContainer>
  </>
);

PostsList.propTypes = {
  postList: PostListProps,
  className: string,
  showStories: bool
};

PostsList.defaultProps = {
  postList: [],
  className: '',
  showStories: true
};
