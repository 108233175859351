import { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { RouteProps } from 'Utils/propTypes';
import { fetchSinglePost, reportPost } from 'Store/postSlice';
import {
  BaseButton,
  BaseTitle,
  ContentContainer,
  SpoxioBlueBtn
} from 'Components/shared/styledComponents';
// import { NavBar } from 'Components/Navigation/NavBar';
import { Post } from 'Components/shared/Post';
import { FormCheckbox } from 'Components/shared/Inputs/FormCheckbox';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import {
  // MainContainer,
  HeaderContainer,
  SecondHeader,
  Margin,
  FormContainer,
  ReportButton,
  BackButton
} from './components';
import { Success } from './Success';
import { getPostSelector } from 'Store/selectors/postSelector';
import { useTranslation } from 'react-i18next';
import { MainContainer } from 'Components/Layouts/MainNavigation/components';
import { NavBar } from 'Components/Layouts/MainNavigation/NavBar';
import { ThemeContext } from 'Context/themeContext';
import { PostMedia } from 'Components/shared/Post/PostMedia';
import { PostListContainer } from 'Components/PostsList/components';
import isEmpty from 'Utils/isEmpty';
import { toast } from 'react-toastify';

export const ReportPost = ({ history, location }) => {
  const path = location.pathname.split('/');
  const dataId = path[path.length - 2];
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [showSuccess, setSuccess] = useState(false);

  const post = useSelector(getPostSelector);
  const query = useSelector((state) => state?.router?.location?.query);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSinglePost(dataId));
  }, []);

  const { register, watch, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log(data, 'submit report data');

    data = {
      ...data,
      contentType: 'post',
      contentId: dataId
    };

    if (data?.isOffensive) {
      data = {
        ...data,
        message: `${t('its_offensive')}`
      };
    }

    if (data?.isFalse) {
      data = {
        ...data,
        message: `${t('its_false')}`
      };
    }

    if (data?.isOther) {
      data = {
        ...data,
        message: data?.details
      };
    }

    if (!data?.isOffensive && !data?.isFalse && isEmpty(data?.details)) {
      toast.error('Please select options to proceed');
      return;
    }

    dispatch(reportPost(data, query?.livestreamId)).then(() => setSuccess(true));
  };

  const onBack = () => {
    history.goBack();
  };

  const checkboxes = watch(['isOffensive', 'isFalse', 'other']);
  return (
    <ContentContainer
      width="100%"
      height="100vh"
      overflowY="hidden"
      gap="0"
      background={theme?.white}
    >
      <NavBar showActionNav title="Report Content" showBackButton />
      <MainContainer style={{ flexDirection: 'column', alignItems: 'center' }}>
        {showSuccess ? (
          <Success />
        ) : (
          <ContentContainer hideScroll height="95vh" overflowY="auto" alignItems="center">
            {!query?.livestreamId ? (
              <ContentContainer width="70vw">
                <PostListContainer maxWidth="500px">
                  <Post data={post} onSelect={() => {}} />
                </PostListContainer>
              </ContentContainer>
            ) : null}

            <ContentContainer
              gap="10px"
              margin="20px 0 0"
              maxWidth="45vw"
              alignItems="center"
              justifyContent="center"
            >
              <SecondHeader fontWeight="bold">{t('report_question')}</SecondHeader>
              <FormContainer onSubmit={handleSubmit(onSubmit)}>
                <FormCheckbox
                  ref={register}
                  name="isOffensive"
                  label={t('its_offensive')}
                  checked={checkboxes.isOffensive}
                />
                <FormCheckbox
                  ref={register}
                  name="isFalse"
                  label={t('its_false')}
                  checked={checkboxes.isFalse}
                />
                <FormCheckbox
                  ref={register}
                  name="other"
                  label={t('other')}
                  checked={checkboxes.other}
                />
                <Margin>
                  <FormInput
                    ref={register}
                    name="details"
                    placeholder={t('provide_more_details')}
                  />
                </Margin>
                <ReportButton>
                  <SpoxioBlueBtn>{t('report_content')}</SpoxioBlueBtn>
                </ReportButton>
              </FormContainer>
            </ContentContainer>
          </ContentContainer>
        )}
      </MainContainer>
    </ContentContainer>
  );
};

ReportPost.propTypes = RouteProps;
