import { TRIM_LIMIT } from './constants';

export const isWebUrl = (url) => url.substr(0, 4) === 'http';

export const getAddressForPost = (address) => {
  let formattedAddress = typeof address !== 'string' ? '' : address;

  if (formattedAddress.length > TRIM_LIMIT) {
    formattedAddress = `${formattedAddress.substr(0, TRIM_LIMIT)}...`;
  }

  return formattedAddress;
};

export const getNumberWithText = (number = 0, text) => {
  const formattedString = `${number} ${text[0].toUpperCase()}${text.slice(1)}`;

  if (number === 1) {
    return formattedString;
  }

  return `${formattedString}s`;
};

export const getTextByCount = (count = 0, text) => (count === 1 ? text : `${text}s`);

export const trimText = (text, countOfCharacters) => {
  if (!text) {
    return '';
  }

  if (text.length > countOfCharacters) {
    const trimmedText = `${text.slice(0, countOfCharacters)}... `;

    return trimmedText;
  }

  return text;
};

export const getNotificationText = (notificationType) => {
  switch (notificationType) {
    case 'like':
      return 'liked your post';
    case 'create':
      return 'commented your post';
    case 'follow':
      return 'followed you';
    case 'live':
      return 'is live streaming now';
    default:
      return '';
  }
};
