import Api from 'middleware/api';
import Auth from 'middleware/storage';
import { toast } from 'react-toastify';
import { setLoading } from './loaderSlice';
import { setActivePurchases, setPurchases, setPurchasesIntent } from './selectors/purchaseSelector';

export const fetchPurchases = () => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const response = await Api.user.purchases();
    const { data } = response.data;
    dispatch(setPurchases(data));
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.data) {
      toast.warn(error.response.data.title);
    } else {
      toast.warn('Network error', 'error');
    }

    return false;
  }
};

export const fetchActivePurchases = () => async (dispatch) => {
  try {
    const res = await Api.user.purchasesActive();
    console.log(res, 'fetch active purchases');
    dispatch(setActivePurchases(res.data.data));
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchPurchasesIntent = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await Api.user.purchaseIntent();
    dispatch(setPurchasesIntent(res.data));
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    console.log(error.response, 'fetched purchase intent');
    return false;
  }
};

export const cancelSubscription = (itemId) => async (dispatch) => {
  try {
    setLoading(true);
    await Api.user.cancelSub(itemId);
    setLoading(false);
    await Auth.fetchUser();
    await Auth.getUser();
    toast.success('Subscription cancelled successfully', 'success');
    await dispatch(fetchActivePurchases());
  } catch (error) {
    console.log(
      '🚀 ~ file: purchasesSlice.js:62 ~ cancelSubscription ~ error:',
      error,
      error.response
    );
    setLoading(false);
    toast.error('Request Failed');
  }
};
