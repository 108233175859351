import { useDispatch, useSelector } from 'react-redux';
import { number, shape, string } from 'prop-types';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { getNumberWithText } from 'Utils/strings';
import { getCurrentUserSports, updateUser } from 'Store/authSlice';
import { HeaderContainer, PostsCount, ResultTitle } from './components';
import { FollowButton } from 'Components/shared/FollowButton';
import { CenteredRowContainer } from 'Components/shared/styledComponents';
import { BackButton } from 'Components/shared/BackButton';

export const ResultHeader = ({ search, searchString }) => {
  const userSports = useSelector(getCurrentUserSports) || [];
  const dispatch = useDispatch();

  const onBackClick = () => dispatch(push(paths.DISCOVERY));

  const onFollowClick = () => dispatch(updateUser({ sports: [search?.data?._id] }, null));

  const isFollowed = userSports.some((sport) => sport._id === search?.data?._id);

  return (
    <HeaderContainer>
      {searchString ? (
        <>
          <ResultTitle>"{searchString}"</ResultTitle>
        </>
      ) : (
        <>
          <CenteredRowContainer>
            <ResultTitle>{search?.data.name}</ResultTitle>
            <BackButton
              label={search?.type === 'sport' ? 'All sports' : 'Discovery'}
              onClick={onBackClick}
            />
          </CenteredRowContainer>
          <PostsCount>{getNumberWithText(search?.data.postCount, 'post')}</PostsCount>
          {search?.type === 'sport' ? (
            <FollowButton isFollowed={isFollowed} onClick={onFollowClick} />
          ) : null}
        </>
      )}
    </HeaderContainer>
  );
};

ResultHeader.propTypes = {
  search: shape({
    type: string,
    data: shape({
      id: string,
      name: string,
      totalPosts: number
    })
  }),
  searchString: string
};

ResultHeader.defaultProps = {
  search: null,
  searchString: ''
};
