import { bool, node, string } from 'prop-types';

import { Empty } from './components';

import { ReactComponent as SearchResult } from 'Assets/icons/SearchResult.svg';
import { Title } from 'Components/Layouts/AuthLayout/components';

export const EmptyContent = ({ description, title, maxWidth, showIcon, margin }) => (
  <Empty maxWidth={maxWidth}>
    {showIcon && <SearchResult />}
    <Title textAlign="center">{description}</Title>
    {title ? (
      <Title textAlign="center" margin={margin}>
        "{title}".
      </Title>
    ) : null}
  </Empty>
);

EmptyContent.propTypes = {
  // description: string.isRequired,
  description: node.isRequired,
  title: string,
  maxWidth: string,
  showIcon: bool,
  margin: string
};

EmptyContent.defaultProps = {
  showIcon: true,
  title: ''
};
