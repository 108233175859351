import styled from 'styled-components';

import { BlackText } from '../shared/styledComponents';

export const SliderContainer = styled.div`
  width: 95%;
  min-height: 10vh;
  /* max-width: 700px; */
  margin: 20px 0;
  position: relative;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  align-items: center;
  padding: 8px;
  border-width: 0.7px;
  border-style: solid;
  border-color: ${({ theme }) => theme.borderShade};
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  padding: 5px;
  justify-content: center;
  align-items: center;
`;

export const UserName = styled(BlackText)`
  margin-top: 2px;
  text-align: center;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
  line-height: 12px;
`;

export const NextBtn = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.borderShade};
  height: 30px;
  width: 30px;
  padding: 2px;
  right: -20px;
  z-index: 10;

  & svg {
    height: 10px;
    width: 14px;
    stroke: ${({ theme }) => theme.black};
  }
  &:hover {
    opacity: 0.75;
  }
`;

export const BeforeBtn = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  left: 5px;
  background-color: ${({ theme }) => theme.borderShade};
  height: 30px;
  width: 30px;
  padding: 2px;
  z-index: 10;

  & svg {
    height: 10px;
    width: 14px;
    stroke: ${({ theme }) => theme.black};
  }
  &:hover {
    opacity: 0.75;
  }
`;
