import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUser, getCurrentUserId } from 'Store/authSlice';
import { useModal } from 'Hooks/useModal';
import { NotificationItem } from './NotificationItem';
import {
  MenuContainer,
  ArrowUp,
  NotificationsHead,
  Close,
  IconContainer,
  NotificationItemsContainer
} from './components';
// import { ReactComponent as Bell } from 'Assets/icons/Bell.svg';
import { ReactComponent as Bell } from 'Assets/icons/NotificationIcon.svg';

import { colors } from 'Theme';
import { fetchNotifications } from 'Store/dataSlice';
import { getNotificationSelector } from 'Store/selectors/dataSelector';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';

export const Notifications = () => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const currentUserId = useSelector(getCurrentUserId);
  const notifications = useSelector(getNotificationSelector);

  const { isModalOpen, openModal, closeModal } = useModal();

  const getNotifications = async () => {
    await dispatch(fetchNotifications());
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <IconContainer fill={theme.mediumGray}>
        <Bell title="Notifications" color={theme.mediumGray} onClick={openModal} />
      </IconContainer>
      <MenuContainer isOpen={isModalOpen}>
        <ArrowUp />
        <NotificationsHead>
          {t('notification')} <Close onClick={closeModal} />
        </NotificationsHead>
        <NotificationItemsContainer>
          {notifications?.map((notification) => (
            <NotificationItem
              key={notification._id}
              notification={notification}
              currentUserId={currentUserId}
              getNotifications={getNotifications}
              turnedOffList={currentUser?.turnedOffNotifications}
            />
          ))}
        </NotificationItemsContainer>
        <div id="notifications-overlay"></div>
      </MenuContainer>
    </>
  );
};
