import React from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('profile-modal');

const ModalPortal = ({ children }) => {
  return ReactDOM.createPortal(children, modalRoot);
};

export default ModalPortal;
