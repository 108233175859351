import OverView from 'Components/OtherProfile/Club/OverView';
import { AboutTags } from 'Components/OtherProfile/components';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LinearStats,
  OverviewHead,
  ProgressBar,
  ProgressBarCircle,
  ProgressContainer,
  ProgressStats,
  StatsProgress,
  TextInfo,
  ViewProfile
} from './components';
import { ThemeContext } from 'Context/themeContext';
import {
  ContentContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { useHistory } from 'react-router';
import { paths } from 'Utils/constants';
import Api from 'middleware/api';
import isEmpty from 'Utils/isEmpty';
import { getCountryFlag } from 'Utils';
import SpoxioModal from 'Components/Modals/SpoxioModal';

const TalentStatOverview = ({ profile, clubData }) => {
  const { user } = profile;
  const [showCv, setShowCv] = useState(false);
  const { theme } = useContext(ThemeContext);
  const history = useHistory();
  const openProfile = () => {
    history.push(`${paths.PROFILE}/${user?._id}`);
  };

  console.log('--------Talent Overview data -----------', profile, '---club data----', clubData);

  const pushLink = (link) => {
    History.push(link);
  };

  const Statistics = () => {
    user?.clubMemberProfile?.statistics ||
      [].map((stat, i) => {
        return (
          <>
            <ProgressStats key={i.toString()}>
              <OverviewHead>
                <AboutTags size={'16px'}>{stat.label}</AboutTags>
                <AboutTags size={'20px'}>{stat.value}</AboutTags>
              </OverviewHead>
              <ProgressBar trailColor={theme?.spoxioIcon2} percent={10}></ProgressBar>
            </ProgressStats>
          </>
        );
      });
  };
  console.log(
    '\n 🚀 ~ file: TalentStatOverview.js:74 ~ TalentStatOverview ~ user?.clubMemberProfile:',
    user?.clubMemberProfile
  );

  return (
    <ContentContainer column gap="10px" padding="4%">
      <ContentContainer row gap="10px" justifyContent="space-between" alignItems="center">
        <AboutTags size="1.35rem" fontWeight="bold">
          Overview
        </AboutTags>
        <ViewProfile onClick={openProfile}>View Profile</ViewProfile>
      </ContentContainer>
      <ContentContainer row alignItems="center" justifyContent="space-between" gap="100px">
        <ContentContainer column justifyContent="center">
          <SubTitle fontSize="0.8rem">Team</SubTitle>
          <TextInfo color={theme.spoxioText} style={{ opacity: 0.8 }}>
            {isEmpty(user?.clubMemberProfile) ? '-' : user?.clubProfile?.name}
          </TextInfo>
        </ContentContainer>

        <ContentContainer column justifyContent="center">
          <SubTitle fontSize="0.8rem">Origin</SubTitle>
          <TextInfo color={theme.spoxioText}>
            {
              <>
                <Title
                  fontSize="0.875rem"
                  opacity="0.8"
                  lineHeight="normal"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px'
                  }}>
                  {user?.country?.name}
                </Title>{' '}
                <span>{!isEmpty(user?.country?.iso2) && getCountryFlag(user?.country?.iso2)}</span>
              </>
            }
          </TextInfo>
        </ContentContainer>

        <ContentContainer column justifyContent="center">
          <SubTitle fontSize="0.8rem">Talent CV</SubTitle>
          <TextInfo color={theme.spoxioText}>
            {
              <>
                {!isEmpty(user?.talentProfile?.cv) ? (
                  <SpoxioBlueBtn
                    background={theme.spoxioIcon2}
                    marginTop="0"
                    padding="10px"
                    onClick={() => setShowCv(!showCv)}>
                    <Title
                      fontSize="0.875rem"
                      opacity="0.8"
                      lineHeight="normal"
                      textAlign="center"
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px'
                      }}>
                      View CV
                    </Title>
                  </SpoxioBlueBtn>
                ) : (
                  <Title
                    fontSize="0.875rem"
                    opacity="0.8"
                    lineHeight="normal"
                    textAlign="center"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '200px'
                    }}>
                    N/A
                  </Title>
                )}
              </>
            }
          </TextInfo>
        </ContentContainer>
      </ContentContainer>

      {/* stats  */}
      {!isEmpty(user?.clubMemberProfile?.statistics) ? (
        <StatsProgress>
          <LinearStats>
            <Statistics />{' '}
            {/* <ProgressStats>
            <OverviewHead>
              <AboutTags size={'16px'}>Appearance</AboutTags>
              <AboutTags size={'20px'}>0</AboutTags>
            </OverviewHead>
            <ProgressBar trailColor={theme?.spoxioIcon2} percent={10}></ProgressBar>
          </ProgressStats>
          <ProgressStats>
            <OverviewHead>
              <AboutTags size={'16px'}>Wins</AboutTags>
              <AboutTags size={'20px'}>0</AboutTags>
            </OverviewHead>
            <ProgressBar trailColor={theme?.spoxioIcon2} percent={10}></ProgressBar>
          </ProgressStats>
          <ProgressStats>
            <OverviewHead>
              <AboutTags size={'16px'}>Losses</AboutTags>
              <AboutTags size={'20px'}>0</AboutTags>
            </OverviewHead>
            <ProgressBar trailColor={theme?.spoxioIcon2} percent={10}></ProgressBar>
          </ProgressStats> */}
            {/* <ContentContainer column gap="10px">
          <TextInfo color={theme.gray}>Athlete Accuracy</TextInfo>
          <ProgressBarCircle
            gapDegree={5}
            percent={10}
            trailColor={theme?.spoxioIcon2}
          ></ProgressBarCircle>
        </ContentContainer> */}
          </LinearStats>{' '}
        </StatsProgress>
      ) : (
        <ContentContainer margin="20px auto 0">
          <SubTitle textAlign="center" fontWeight="bold">
            No Current Stats
          </SubTitle>
        </ContentContainer>
      )}

      <SpoxioModal
        showCloseButton
        visible={showCv}
        width="100%"
        height="100%"
        minWidth="85vw"
        maxWidth="85vw"
        borderRadius="0px"
        setVisible={() => setShowCv(false)}
        minHeight="100vh"
        maxHeight="100vh">
        <ContentContainer column width="100%" height="100%" margin="auto" padding="10px">
          <object
            data={user?.talentProfile?.cv}
            type="application/pdf"
            width="100%"
            height="100%"
            aria-label="Talent CV"></object>
        </ContentContainer>
      </SpoxioModal>
    </ContentContainer>
  );
};

TalentStatOverview.propTypes = {
  profile: PropTypes.object,
  clubData: PropTypes.object
};
export default TalentStatOverview;
