import { PageNavigation } from 'Components/Navigation';
import { Discovery } from 'Components/Discovery';
import { MainNavigation } from 'Components/Layouts/MainNavigation';
import { useEffect, useState } from 'react';
import DiscoverButtons from 'Components/Discovery/DiscoverButtons';
import { Topics } from 'Components/Discovery/Topics';
import SportTrending from 'Components/SportTrending';
import { ContentContainer } from 'Components/shared/styledComponents';
import { useLocation } from 'react-router';
import isEmpty from 'Utils/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchInterests, getSearchTopics, getSearchUsers } from 'Store/discoverySlice';
import { DiscoveryContainer } from 'Components/Discovery/components';
import { Results } from 'Components/Results';
import { ClubImageContainer } from 'Components/OtherProfile/Club/components';

export const DiscoveryPage = () => {
  const [type, setType] = useState(1);
  const dispatch = useDispatch();
  const location = useLocation();
  const { query, search } = useSelector((state) => state?.router.location);

  const searchTopic = new URLSearchParams(location.search).get('topic');
  const searchSport = new URLSearchParams(location.search).get('sport');

  useEffect(() => {
    !isEmpty(searchSport) && setType(2);
    !isEmpty(searchTopic) && setType(3);
  }, [searchTopic, searchSport]);

  useEffect(() => {
    if (query.search) dispatch(getSearchUsers(query.search));
  }, [query.search]);

  return (
    <MainNavigation navTitle="Discover">
      <ContentContainer column width="100%" maxWidth="600px" margin="20px auto">
        <DiscoverButtons type={type} setTypeNumber={setType} />
        {type === 1 && (
          <>{query?.search ? <Results searchString={query?.search} /> : <SportTrending />}</>
        )}
        {type === 2 && <Discovery />}
        {type === 3 && <Topics />}
      </ContentContainer>
    </MainNavigation>
  );
};
