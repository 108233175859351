import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  eventData: {
    is_virtual: null,
    title: '',
    category: '',
    event_date: '',
    start_time: '',
    end_time: '',
    venue: '',
    venue_address: '',
    timezone: '',
    about: '',
    is_paid: false,
    payment_link: '',
    ticket_amount: '',
    event_link: ''
  },
  eventDetails: {},
  trendingEvents: [],
  participatingEvent: [],
  myEvents: [],
  eventCategories: [],
  schedules: [],
  photos: [],
  participants: []
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, action) => {
      state.eventData = action.payload;
    },
    setMyEvent: (state, action) => {
      state.myEvents = action.payload;
    },
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload;
    },
    setTrendingEvent: (state, action) => {
      state.trendingEvents = action.payload;
    },
    setParticipatingEvent: (state, action) => {
      state.participatingEvent = action.payload;
    },
    setEventCategories: (state, action) => {
      state.eventCategories = action.payload;
    },
    setEventSchedules: (state, action) => {
      state.schedules = action.payload;
    },
    addEventPhoto: (state, action) => {
      state.photos = action.payload;
    },
    removeEventPhoto: (state, action) => {
      let id = action.payload;
      let newPhotos = state.photos.filter((image) => image.id !== id);
      return {
        ...state,
        photos: newPhotos
      };
    },
    setEventParticipants: (state, action) => {
      state.participants = action.payload;
    }
  }
});

// actions
export const {
  setEvent,
  setMyEvent,
  setEventDetails,
  setTrendingEvent,
  setParticipatingEvent,
  setEventCategories,
  setEventSchedules,
  addEventPhoto,
  removeEventPhoto,
  setEventParticipants
} = eventSlice.actions;

// reducer
export const eventReducer = eventSlice.reducer;

export const getEventData = (state) => state?.event?.eventData;
export const getEventDetails = (state) => state?.event?.eventDetails;
export const getTrendingEvents = (state) => state?.event?.trendingEvents;
export const getParticipatingEvent = (state) => state?.event?.participatingEvent;
export const getEventCategories = (state) => state?.event?.eventCategories;
export const getEventParticipants = (state) => state?.event?.participants;
export const getMyEvent = (state) => state?.event?.myEvents;
export const getEventSchedules = (state) => state?.event?.schedules;
export const getEventPhotos = (state) => state?.event?.photos;
