import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { setSearch } from 'Store/selectors/discoverySelector';
import { MostPopular } from './MostPopularItem';
import { SearchItem } from './SearchItem';
import { DiscoveryContainer, Popular, SecondTitle, Title } from './components';
import { Results } from 'Components/Results';
import { getSportPopularSelector } from 'Store/selectors/dataSelector';
import { fetchPopularSports } from 'Store/dataSlice';
import { getSearchInterests, getSearchTopics, getSearchUsers } from 'Store/discoverySlice';
import { useTranslation } from 'react-i18next';
import { ContentContainer } from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import Skeleton from 'react-loading-skeleton';

export const Discovery = (props) => {
  const [loading, setLoading] = useState(false);
  const { themeMode, theme } = useContext(ThemeContext);
  const sportsPopular = useSelector(getSportPopularSelector);
  const { query, search } = useSelector((state) => state?.router.location);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchPopularSports());
    setLoading(false);
  }, []);

  useEffect(() => {
    getSearchResult();
  }, [search]);

  const differentColors = [
    {
      color: themeMode === 'light' ? 'rgba(55, 33, 17, 0.85)' : '#372111'
    },
    {
      color: themeMode === 'light' ? 'rgba(50, 44, 19, 0.85)' : '#322c13'
    },
    {
      color: themeMode === 'light' ? 'rgba(22, 39, 59, 0.85)' : '#16273b'
    },
    {
      color: themeMode === 'light' ? 'rgba(38, 25, 55, 0.85)' : '#261937'
    }
  ];

  const getSearchResult = () => {
    if (query.sport) dispatch(getSearchInterests(query.sport));
    if (query.topic) dispatch(getSearchTopics(query.topic));
    if (query.search) dispatch(getSearchUsers(query.search));
  };

  const onChooseSport = (sport) => {
    const search = { type: 'sport', data: sport };

    dispatch(setSearch(search));
    dispatch(push(`${paths.DISCOVERY}?sport=${sport.name}`));
  };

  return query?.sport || query?.topic || query?.search ? (
    <Results searchString={query?.search} />
  ) : (
    <DiscoveryContainer>
      <ContentContainer column width="100%" minHeight="15vh">
        <SecondTitle>{t('most_popular')}</SecondTitle>

        <Popular>
          {loading ? (
            <Skeleton
              containerClassName="skeleton-wrap"
              wrapper={({ children }) => {
                return (
                  <div
                    style={{
                      width: `170px`,
                      border: `1px solid ${({ theme }) => theme.lightBlue}`,
                      marginBottom: `10px`,
                      borderRadius: `20px`,
                      flexDirection: 'row',
                      flexShrink: 0
                    }}>
                    {children}
                  </div>
                );
              }}
              height={100}
              borderRadius={10}
              baseColor={theme?.darkGray2}
              count={4}
            />
          ) : null}

          {!loading
            ? sportsPopular
                .slice(0, 4)
                .map((sport, index) => (
                  <MostPopular
                    key={`${sport._id}-${index}`}
                    index={index}
                    sport={sport}
                    color={differentColors[index]}
                    onClick={() => onChooseSport(sport)}
                  />
                ))
            : null}
        </Popular>
      </ContentContainer>
      <ContentContainer column width="100%" minHeight="15vh">
        <SecondTitle>{t('all_sports')}</SecondTitle>
        {loading ? (
          <Skeleton
            height={45}
            borderRadius={10}
            style={{ marginBottom: '10px' }}
            baseColor={theme?.darkGray2}
            count={6}
          />
        ) : null}
        {!loading
          ? sportsPopular.map((sport) => (
              <SearchItem
                key={sport._id}
                name={sport.name}
                count={sport.postCount}
                onClick={() => onChooseSport(sport)}
              />
            ))
          : null}
      </ContentContainer>
    </DiscoveryContainer>
  );
};
