import { any, arrayOf, bool, number, object, oneOfType, shape, string } from 'prop-types';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
// import Select from 'react-dropdown-select';

import { ErrorText, FormSelectContainer, InputLabel } from '../components';

import { styles } from './styles';

export const FormSelect = ({
  control,
  rules,
  options,
  name,
  defaultValue,
  placeholder,
  error,
  isSearchable,
  className,
  label,
  disabled,
  background,
  isFixed
}) => {
  return (
    <FormSelectContainer className={className} background={background}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        as={Select}
        options={options}
        menuPosition={isFixed ? 'fixed' : 'absolute'}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isClearable={false}
        closeMenuOnScroll
        styles={styles}
        dropdownPosition="auto"
        isDisabled={disabled}
      />
      <ErrorText isShow={!!error}>{error}</ErrorText>
    </FormSelectContainer>
  );
};

FormSelect.propTypes = {
  options: arrayOf(
    shape({
      value: oneOfType([string, number]),
      label: string
    })
  ).isRequired,
  name: string.isRequired,
  control: any,
  rules: any,
  defaultValue: string,
  placeholder: string,
  error: string,
  isSearchable: bool,
  className: string,
  label: string,
  background: string,
  disabled: bool,
  isFixed: bool
};

FormSelect.defaultProps = {
  control: {},
  rules: null,
  defaultValue: '',
  placeholder: 'Select option',
  error: '',
  isSearchable: false,
  className: '',
  label: '',
  isFixed: true
};
