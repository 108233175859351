import OverView from 'Components/OtherProfile/Club/OverView';
import { AboutTags } from 'Components/OtherProfile/components';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LinearStats,
  OverviewHead,
  ProgressBar,
  ProgressBarCircle,
  ProgressContainer,
  ProgressStats,
  StatsProgress,
  TextInfo,
  ViewProfile
} from './components';
import { ThemeContext } from 'Context/themeContext';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { useHistory } from 'react-router';
import { paths } from 'Utils/constants';
import Api from 'middleware/api';
import isEmpty from 'Utils/isEmpty';
import { getCountryFlag } from 'Utils';

const Overview = ({ profile, clubData }) => {
  const { theme } = useContext(ThemeContext);
  const history = useHistory();
  const { data, isCurrentUser } = profile;
  const openProfile = () => {
    history.push(`${paths.PROFILE}/${data._id}`);
  };

  console.log('--------Talent Overview data -----------', profile, '---club data----', clubData);

  const pushLink = (link) => {
    History.push(link);
  };

  const Statistics = () => {
    data?.clubMemberProfile?.statistics?.map((stat, i) => {
      return (
        <ProgressStats key={i.toString()}>
          <OverviewHead>
            <AboutTags size={'16px'}>{stat.label}</AboutTags>
            <AboutTags size={'20px'}>{stat.value}</AboutTags>
          </OverviewHead>
          <ProgressBar trailColor={theme?.spoxioIcon2} percent={10}></ProgressBar>
        </ProgressStats>
      );
    });
  };
  return (
    <ContentContainer column gap="10px" padding="4%">
      <ContentContainer row gap="10px" justifyContent="space-between" alignItems="center">
        <AboutTags size="1.35rem" fontWeight="bold">
          Overview
        </AboutTags>
        <ViewProfile onClick={openProfile}>View Profile</ViewProfile>
      </ContentContainer>

      {!isEmpty(clubData) ? (
        <ContentContainer row alignItems="center" gap="100px">
          <ContentContainer column justifyContent="center">
            <SubTitle fontSize="0.8rem">Team</SubTitle>
            <TextInfo color={theme.spoxioText} style={{ opacity: 0.8 }}>
              {isEmpty(data.clubMemberProfile) ? '-' : clubData?.clubProfile?.name}
            </TextInfo>
          </ContentContainer>
          <ContentContainer column justifyContent="center">
            <SubTitle fontSize="0.8rem">Origin</SubTitle>
            <TextInfo color={theme.spoxioText}>
              <Title
                fontSize="0.875rem"
                opacity="0.8"
                lineHeight="normal"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '200px'
                }}>
                {data?.country?.name}
              </Title>{' '}
              <span>{!isEmpty(data?.country?.iso2) && getCountryFlag(data?.country?.iso2)}</span>
            </TextInfo>
          </ContentContainer>
        </ContentContainer>
      ) : null}

      {/* stats  */}
      {!isEmpty(data?.clubMemberProfile?.statistics) ? (
        <StatsProgress>
          <LinearStats>
            <Statistics />{' '}
            {/* <ProgressStats>
            <OverviewHead>
              <AboutTags size={'16px'}>Appearance</AboutTags>
              <AboutTags size={'20px'}>0</AboutTags>
            </OverviewHead>
            <ProgressBar trailColor={theme?.spoxioIcon2} percent={10}></ProgressBar>
          </ProgressStats>
          <ProgressStats>
            <OverviewHead>
              <AboutTags size={'16px'}>Wins</AboutTags>
              <AboutTags size={'20px'}>0</AboutTags>
            </OverviewHead>
            <ProgressBar trailColor={theme?.spoxioIcon2} percent={10}></ProgressBar>
          </ProgressStats>
          <ProgressStats>
            <OverviewHead>
              <AboutTags size={'16px'}>Losses</AboutTags>
              <AboutTags size={'20px'}>0</AboutTags>
            </OverviewHead>
            <ProgressBar trailColor={theme?.spoxioIcon2} percent={10}></ProgressBar>
          </ProgressStats> */}
            {/* <ContentContainer column gap="10px">
          <TextInfo color={theme.gray}>Athlete Accuracy</TextInfo>
          <ProgressBarCircle
            gapDegree={5}
            percent={10}
            trailColor={theme?.spoxioIcon2}
          ></ProgressBarCircle>
        </ContentContainer> */}
          </LinearStats>{' '}
        </StatsProgress>
      ) : (
        <ContentContainer marign="auto">
          <SubTitle textAlign="center" fontSize='0.875rem'>No stats available</SubTitle>
        </ContentContainer>
      )}
    </ContentContainer>
  );
};

Overview.propTypes = {
  profile: PropTypes.object,
  clubData: PropTypes.object
};
export default Overview;
