import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: '',
  role: '',
  user: {},
  isAuthorized: false,
  following: []
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
    setFollowing: (state, action) => {
      state.following = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    clearState: (state) => {
      state.user = {};
      state.userId = '';
      state.role = '';
      state.isAuthorized = false;
    }
  }
});

// actions
export const { setUser, setUserId, setIsAuthorized, setFollowing, setRole, clearState } =
  authSlice.actions;

// reducer
export const authReducer = authSlice.reducer;

// auth selectors
export const getIsAuthorizedSelector = (state) => state?.auth?.isAuthorized;
export const getCurrentUser = (state) => state?.auth?.user;
export const getCurrentUserSports = (state) => state?.auth?.user?.sports;
export const getCurrentUserId = (state) => state?.auth?.userId;
export const getCurrentUserRole = (state) => state?.auth?.role;
export const getCurrentUserFollowingSelector = (state) => state?.auth?.following;
