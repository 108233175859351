import styled from 'styled-components';

export const PostListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '680px'};
  position: relative;
  justify-content: center;
  margin: 0 auto;
`;

export const PostImage = styled.div`
  & img {
    max-width: 100%;
  }
`;

export const PostUserData = styled.div`
  margin-left: 15px;
`;

export const PostUserName = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;

export const PostLocation = styled.div`
  display: flex;
  font-size: 12px;
  color: ${({ theme }) => theme.opacityGray};
  & div {
    margin-right: 10px;
  }
  & svg {
    padding-right: 5px;
    position: relative;
    top: 3px;
    width: 15px;
    height: 15px;
    & path {
      fill: ${({ theme }) => theme.opacityGray};
    }
  }
`;

export const PostLocationIcon = styled.div`
  & svg {
    padding-right: 0;
  }
`;

export const PostTime = styled.div`
  display: flex;
  align-items: center;
`;

export const Actions = styled.div`
  width: 20%;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  & svg {
    height: 20px;
    width: 20px;
    & path {
      fill: ${({ theme }) => theme.opacityGray};
    }
  }
`;

export const Description = styled.div`
  padding: 20px;
`;
