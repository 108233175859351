import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  padding-top: 5px;
  flex: 1;
  justify-content: center;
  position: relative;
  gap: 20px;
  min-width: 768px;
  max-width: 1920px; // 1440px; // 1920px;
  height: 100vh;
  margin: 0 auto;
  overflow-y: hidden;
  background: ${({ theme }) => theme.white};
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 25%;
  height: 100vh;
  width: 100%;
  /* min-width: calc(15vw - 10px); */
  padding: 20px 0px 20px 10px;
  overflow-x: hidden;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  width: 100%;
  height: 100%;
  padding-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 50%;
  min-width: calc(50vw - 10px);
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (min-width: 1440px) {
    min-width: 30vw;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  padding: ${({ padding }) => (padding ? padding : '20px 10px 20px 0px')};
  flex: 25%;
  height: 100%;
  /* min-width: calc(25vw - 10px); */
  width: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || '30px'};
`;

export const InnerCenterContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  /* padding-top: 80px; */
`;

export const SidebarContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 25%;
  height: 100vh;
  width: 100%;
  min-width: 200px;
  max-width: 420px;
  padding: 20px;
`;
