import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileLayout } from 'Components/Layouts/ProfileLayout';
import { ContentContainer, Title } from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { ModalAdminItem } from 'Components/OtherProfile/components';
import { paths } from 'Utils/constants';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export const AboutSpoxio = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <ProfileLayout navTitle="About Spoxio">
      <ContentContainer width="100%" column gap="15px" maxWidth="700px" margin="40px auto">
        <Link to={paths.ABOUT}>
          <ModalAdminItem
            background={theme.borderShade}
            minHeight="40px"
            row
            alignItems="center"
            cursor="pointer"
            borderRadius="5px"
            width="100%"
            padding="8px 16px"
          >
            <Title fontSize="1rem" fontWeight="bold" opacity="0.85">
              {t('about')}
            </Title>
          </ModalAdminItem>
        </Link>

        <Link to={paths.PRIVACY}>
          <ModalAdminItem
            background={theme.borderShade}
            minHeight="40px"
            row
            alignItems="center"
            cursor="pointer"
            borderRadius="5px"
            width="100%"
            padding="8px 16px"
          >
            <Title fontSize="1rem" fontWeight="bold" opacity="0.85">
              {t('privacy_policy')}
            </Title>
          </ModalAdminItem>
        </Link>
        <Link to={paths.TERMS_OF_CONDITIONS}>
          <ModalAdminItem
            background={theme.borderShade}
            minHeight="40px"
            row
            alignItems="center"
            cursor="pointer"
            borderRadius="5px"
            width="100%"
            padding="8px 16px"
          >
            <Title fontSize="1rem" fontWeight="bold" opacity="0.85">
              {' '}
              {t('terms_of_use')}
            </Title>
          </ModalAdminItem>
        </Link>
      </ContentContainer>
    </ProfileLayout>
  );
};
