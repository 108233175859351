import styled from 'styled-components';
import { string } from 'prop-types';

import { BaseText } from '../shared/styledComponents';
import { colors } from '../../Theme';

export const RoleItemContainer = styled.div`
  /* display: flex;
  padding: 20px;
  margin-bottom: 30px;
  max-width: 290px;
  border: 1px solid ${({ theme }) => theme.lightGray};
  border-radius: 10px;
  cursor: pointer; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.spoxioCardBg};
  gap: 10px;
  width: 40%;
  /* max-width: 240px; */
  /* min-width: 240px; */
  min-height: 320px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border: 1px solid ${({ theme }) => theme.spoxioCardBg};
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 5;

  &:hover {
    /* box-shadow: 6px rgba(0, 0, 0, 0.25); */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const RoleTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 20px 20px;
  gap: 10px;
  width: 75%;
`;

export const RoleTitle = styled.span`
  font-family: SF Pro Bold;
  font-size: ${({ theme }) => theme.xLarge2}px;
  color: ${({ color }) => color};
`;

RoleTitle.propTypes = {
  color: string
};

RoleTitle.defaultProps = {
  color: colors.black
};

export const RoleDescription = styled(BaseText)`
  color: ${({ theme }) => theme.opacityGray};
  font-size: 1rem;
  font-weight: 300;
`;
