import { any, bool, instanceOf, object, oneOfType, string } from 'prop-types';
import { Controller } from 'react-hook-form';

import { DatePicker } from 'Components/shared/DatePicker';
import { BaseInput } from 'Components/shared/styledComponents';
import { Container } from '../FormImagePicker/components';
import { ErrorText, InputLabel } from '../components';


export const FormDatePicker = ({
  name,
  value,
  control,
  rules,
  error,
  maxDate,
  placeholder,
  className,
  label,
  style
}) => {
  return (
    <Container className={className} style={style}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={value}
        as={DatePicker}
        placeholder={placeholder}
        maxDate={maxDate}
        component={BaseInput}
      />
      <ErrorText isShow={!!error}>{error}</ErrorText>
    </Container>
  );
};

FormDatePicker.propTypes = {
  name: string.isRequired,
  control: any,
  rules: any,
  error: string,
  value: oneOfType([instanceOf(Date), string]),
  maxDate: instanceOf(Date),
  placeholder: string,
  className: string,
  label: string,
  style: object
};

FormDatePicker.defaultProps = {
  control: {},
  rules: null,
  error: '',
  value: null,
  maxDate: null,
  placeholder: 'Select date',
  className: '',
  label: ''
};
