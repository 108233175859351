import Api from 'Api';
import {
  CenterListItem,
  DateLabel,
  ListMessage,
  ListName,
  UnreadPop,
  UserItem,
  UserList
} from 'Components/ChatRevamp/components';
import {
  DetailsPage,
  FollowUserButton,
  NavBarUserContainer
} from 'Components/OtherProfile/components';
import UserInfo from 'Components/OtherProfile/UserInfo';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { UserName } from 'Components/shared/FollowUser/components';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { UserPic } from 'Components/shared/UserPic';
import { push, replace } from 'connected-react-router';
import { ThemeContext } from 'Context/themeContext';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { array, arrayOf, bool, node, shape, string } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'Store/authSlice';
import { getCurrentUserId } from 'Store/selectors/authSelector';
import { getChatsData, setChatsData } from 'Store/selectors/dataSelector';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { paths } from 'Utils/constants';
import { getDateDifference } from 'Utils/dates';
import isEmpty from 'Utils/isEmpty';
import {
  CenterContainer,
  InnerCenterContainer,
  LeftContainer,
  MainContainer,
  RightContainer
} from '../MainNavigation/components';
import { LeftBar } from '../MainNavigation/LeftBar';
import { NavBar } from '../MainNavigation/NavBar';
import { RightBarContainer } from '../MainNavigation/RightBar/components';

const ChatLayout = ({ children, showNav, navTitle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedChatId = useSelector((state) => state?.router?.location?.query?.chat_id);
  const profile = useSelector(getProfileSelector);
  const currentUser = useSelector(getCurrentUser);
  const chatsData = useSelector(getChatsData);
  const { theme, themeMode } = useContext(ThemeContext);
  const [chats, setChatData] = useState(chatsData);
  const [readMsg, setReadMsg] = useState([]);
  const [unreadMsg, setUnReadMsg] = useState([]);
  // const { data } = profile;
  const data = currentUser;
  const currentUserId = useSelector(getCurrentUserId);

  // console.log(currentUser, 'currentUser', profile);

  const onChatClick = (chat) => {
    const message = chat?.messages[chat?.messages?.length - 1];
    if (message && message.userId !== currentUserId) {
      Api.messages.read(chat?.id, message.id);
    }
    dispatch(replace(`${paths.CHAT}?chat_id=${chat.id}`));
  };

  useEffect(() => {
    let newChat = chats;

    // const urm = newChat?.filter((chat, i) => {
    //   let msg = chat.messages;
    //   if (msg && !msg[msg.length - 1]?.isRead) {
    //     return true;
    //   }
    //   return false;
    // });
    // setUnReadMsg(urm);

    // const rm = chats?.filter((chat) => {
    //   let msg = chat.messages;
    //   if (msg && msg[msg.length - 1]?.isRead) {
    //     return true;
    //   }
    //   return false;
    // });
    // setReadMsg(rm);
    setReadMsg(chats);
  }, [chats]);

  useEffect(() => {
    if (isEmpty(chatsData)) return;
    setChatData(chatsData);
  }, [chatsData]);

  useEffect(() => {}, [selectedChatId]);

  const setNewChatData = (func) => {
    setChatData(func);
  };

  const onMessageUpdate = async () => {
    await Api.messages.messageUpdate(setNewChatData);
  };

  return (
    <MainContainer>
      <LeftContainer>
        <LeftBar />
      </LeftContainer>
      <CenterContainer>
        {showNav && (
          <NavBar
            title={
              navTitle || (
                <SubTitle fontWeight="bold" fontSize="1rem">
                  Chat
                </SubTitle>
              )
            }
          />
        )}
        <InnerCenterContainer>{children}</InnerCenterContainer>
      </CenterContainer>
      <RightContainer>
        <RightBarContainer>
          <DetailsPage padding="0" border="transparent" borderRadius="0px" mt={'0'}>
            <NavBarUserContainer justifyContent flexWrap="wrap">
              <ContentContainer row gap="8px" alignItems="center">
                <UserPic size={50} src={data?.picture} />
                <ContentContainer column gap="0">
                  <UserName
                    title={`${data?.firstName || ''} ${data?.lastName || ''}`}
                    weight={'600'}
                    marginLeft="0"
                    fontSize="1rem"
                  >
                    {!isEmpty(data) ? `${data?.firstName} ${data?.lastName}` : ''}
                  </UserName>
                  <UserInfo data={data} maxWidth="10vw" />
                </ContentContainer>
              </ContentContainer>

              <FollowUserButton
                minWidth="70px"
                padding="10px"
                borderRadius="8px"
                onClick={() => {
                  console.log('open modal to view talent list to chat');
                  // onClickChat();
                }}
              >
                New Chat
              </FollowUserButton>
            </NavBarUserContainer>
            <ContentContainer column gap="20px" width="100%" margin="30px 0">
              {!isEmpty(unreadMsg) && (
                <ContentContainer column gap="10px" width="100%" minHeight="10vh">
                  <Title fontSize="0.875rem">New Messages</Title>

                  {unreadMsg?.map((chat, index) => {
                    let msg = chat.messages;
                    const count = msg?.filter((chat) => !chat.isRead);
                    console.log(chat.isRead, chat, '!chat.isRead the chat');
                    const { isRead, MessageGroupID, previewMessageID } = chat;
                    return (
                      <UserItem
                        themeMode={themeMode}
                        key={chat.id}
                        selected={chat.id === selectedChatId}
                        index={index}
                        onClick={() => {
                          if (isRead && MessageGroupID && previewMessageID) {
                            firebase
                              .firestore()
                              .collection('chat_groups')
                              .doc(MessageGroupID)
                              .collection('messages')
                              .doc(previewMessageID)
                              .update({
                                isRead: true
                              })
                              .finally(() => onMessageUpdate());
                          }
                          onChatClick(chat);
                        }}
                      >
                        <ContentContainer
                          row
                          gap="8px"
                          alignItems="center"
                          flexWrap="nowrap"
                          width="100%"
                        >
                          <UserPic src={chat?.avatar} verified={chat?.verified} size={55} />
                          <ContentContainer column>
                            <ContentContainer>
                              <ListName fontWeight="bold">{chat?.name}</ListName>
                              <ListMessage>
                                <ListMessage>
                                  {isEmpty(msg)
                                    ? '...'
                                    : msg.length === 1
                                    ? msg[0].text
                                    : msg[msg.length - 1]?.text}
                                </ListMessage>
                              </ListMessage>
                            </ContentContainer>
                          </ContentContainer>
                        </ContentContainer>
                        <UnreadPop>{count?.length}</UnreadPop>
                      </UserItem>
                    );
                  })}
                </ContentContainer>
              )}

              {!isEmpty(readMsg) && (
                <ContentContainer column gap="12px" width="100%" minHeight="10vh">
                  <Title fontSize="0.875rem"> {!isEmpty(unreadMsg) && 'Other'} Messages</Title>

                  {readMsg?.map((chat, index) => {
                    let msg = chat.messages;
                    return (
                      <UserItem
                        themeMode={themeMode}
                        key={chat.id}
                        selected={chat.id === selectedChatId}
                        index={index}
                        onClick={() => onChatClick(chat)}
                      >
                        <ContentContainer row gap="8px" alignItems="center">
                          <UserPic src={chat?.avatar} verified={chat?.verified} size={55} />
                          <ContentContainer column>
                            <ListName fontWeight="bold">{chat.name}</ListName>
                            <ListMessage>
                              {isEmpty(msg)
                                ? '...'
                                : msg.length === 1
                                ? msg[0].text
                                : msg[msg.length - 1]?.text}
                            </ListMessage>
                          </ContentContainer>
                        </ContentContainer>
                        {/* <DateLabel>{getDateDifference(chat?.lastMessageDate?.toDate())}</DateLabel> */}
                      </UserItem>
                    );
                  })}
                </ContentContainer>
              )}

              {isEmpty(readMsg) && isEmpty(unreadMsg) && (
                <EmptyContent
                  showIcon={false}
                  maxWidth="auto"
                  description={
                    <ContentContainer
                      column
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Title
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color={theme.black}
                        textAlign="center"
                      >
                        No messages here yet...
                      </Title>
                      <SubTitle
                        width="100%"
                        textAlign="center"
                        fontSize="0.875rem"
                        lineHeight="1.15rem"
                      >
                        Send a message from a user profile or tap on the new chat button to get
                        started.
                      </SubTitle>
                    </ContentContainer>
                  }
                />
              )}
            </ContentContainer>
          </DetailsPage>
        </RightBarContainer>
      </RightContainer>
    </MainContainer>
  );
};

ChatLayout.propTypes = {
  children: node.isRequired,
  showNav: bool,
  navTitle: string
};

ChatLayout.defaultProps = {
  showNav: true,
  navTitle: ''
};

export default ChatLayout;
