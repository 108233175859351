import styled from 'styled-components';

export const RightBarContainer = styled.div`
  /* max-width: 400px; */
  /* min-width: 250px; */
  display: flex;
  flex-direction: column;
  flex: 25%;
  width: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || '30px'};
  padding: ${({ padding }) => (padding ? padding : '20px')};
  border-width: 0.5px;
  border-style: solid;
  border-color: ${({ border, theme }) => (border ? border : theme.borderShade)};
  background-color: ${({ background, theme }) => background || theme.spoxioBg};
  overflow: hidden;
  height: ${({ height }) => height}
`;
export const ProfileButton = styled.button`
  min-width: 94px;
  min-height: 44px;
  max-width: 94px;
  max-height: 44px;
  border-radius: 12px;
  background-color: #007aff;
  cursor: pointer;
  border: none;
  color: ${({ theme }) => theme.black};
`;

// heading profile username

export const ProfileHeader = styled.p`
  font-size: 20px;
  white-space: no-wrap;
  color: #e4e6eb;
  max-width: 100%;
  max-height: 33px;
  text-overflow: ellipsis;
`;
