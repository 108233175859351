import Api2 from 'middleware/api';
import Auth from 'middleware/storage';
import { toast } from 'react-toastify';
import {
  setAllClubsList,
  setClubProfileByID,
  setClubRoles,
  setClubStatCategory,
  setClubStatistics
} from './selectors/clubSelector';
import isEmpty from 'Utils/isEmpty';

export const createClubProfile = (credentials) => async (dispatch) => {
  try {
    const response = await Api2.club.createClubProfile(credentials);
    if (response.data) {
      toast.success(response?.data?.message, 'success');
    }
    return true;
  } catch (error) {
    if (error && error?.response?.data) {
      toast.warn(error?.response?.data?.title);
    } else {
      toast.error('Network error. Try again');
    }

    return false;
  }
};

export const fetchAllClubs = () => async (dispatch) => {
  try {
    const response = await Api2?.club?.fetchAllClubs();
    dispatch(setAllClubsList(response.data.data));
    return true;
  } catch (error) {
    if (error && error?.response?.data) {
      toast.warn(error?.response?.data?.title);
    } else {
      toast.error('Network error. Try again');
    }
    return false;
  }
};

export const fetchClubByID = (id) => async (dispatch) => {
  try {
    const response = await Api2?.club?.fetchClubProfileById(id);
    const data = response.data.data && response.data?.data[0];
    return data;
  } catch (error) {
    if (error && error?.response?.data) {
      toast.warn(error?.response?.data?.title);
    } else {
      toast.error('Network error. Try again');
    }
    // throw error;
  }
};

export const acceptClubInvite = async (e) => {
  const { token, clubProfileId, ...rest } = e;
  const payload = { ...rest, clubProfileId };
  console.log('\n 🚀 ~ file: clubSlice.js:64 ~ acceptClubInvite ~ payload:', payload);
  try {
    const { data: response } = await Api2?.club?.acceptClubInvite(payload, token, clubProfileId);
    toast.success(response?.data?.message);
    console.log('\n 🚀 ~ file: clubSlice.js:67 ~ acceptClubInvite ~ data:', response);
    return true;
  } catch (error) {
    console.log('\n 🚀 ~ file: clubSlice.js:71 ~ acceptClubInvite ~ error:', error, error?.response);
    if (error && error?.response?.data) {
      toast.warn(error?.response?.data?.title);
    } else {
      toast.error('Network error. Try again');
    }
    return false;
  }
};

export const editClubProfile = (clubId, credentials) => async (dispatch) => {
  try {
    const response = await Api2?.club?.editClubProfile(clubId, credentials);
    if (response.data) {
      toast.success(response?.data?.message);
    }
    await Auth.fetchUser();
    return true;
  } catch (error) {
    if (error && error?.response?.data) {
      toast.warn(error?.response?.data?.title);
    } else {
      toast.error('Network error. Try again');
    }

    return false;
  }
};

export const addClubRole = (clubId, data) => async (dispatch) => {
  try {
    const response = await Api2?.club?.addClubRole(clubId, data);
    if (response.data) {
      toast.success(response?.data?.message);
      return true;
    }
  } catch (error) {
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};

export const fetchClubRoles = (clubId) => async (dispatch) => {
  try {
    const response = await Api2?.club?.fetchClubRoles(clubId);
    dispatch(setClubRoles(response.data.data));
    return true;
  } catch (error) {
    if (error && error?.response?.data) {
      toast.warn(error?.response?.data?.title);
    } else {
      toast.error('Network error. Try again');
    }

    return false;
  }
};

export const inviteClubMember = (clubId, data) => async (dispatch) => {
  try {
    const response = await Api2?.club?.inviteClubMember(clubId, data);
    console.log('\n 🚀 ~ file: clubSlice.js:102 ~ inviteClubMember ~ response:', response);
    if (response.data) {
      toast.success(response?.data?.message);
      return true;
    }
    // dispatch(setLoading(false));
  } catch (error) {
    // dispatch(setLoading(false));
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};

export const editClubMember = (clubId, memberId, data) => async (dispatch) => {
  console.log(data);
  try {
    // dispatch(setLoading(true));
    const response = await Api2?.club?.editClubMember(clubId, memberId, data);
    if (response.data) {
      toast.success(response?.data?.message);
      return true;
    }
    // dispatch(setLoading(false));
  } catch (error) {
    // dispatch(setLoading(false));
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};

export const deleteClubRole = (clubId, roleId) => async (dispatch) => {
  try {
    const response = await Api2?.club?.deleteClubRole(clubId, roleId);
    if (response.data) {
      toast.success('Deleted successfully');
      return true;
    }
  } catch (error) {
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};

export const addClubStatCategory = (clubId, data) => async (dispatch) => {
  try {
    const response = await Api2?.club?.addClubStatCategory(clubId, data);
    if (response.data) {
      toast.success(response?.data?.message);
      return true;
    }
  } catch (error) {
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};

export const fetchClubStatCategory = (clubId, type) => async (dispatch) => {
  try {
    const response = await Api2?.club?.fetchClubStatCategory(clubId, type);
    dispatch(setClubStatCategory(response.data.data));
    return true;
  } catch (error) {
    if (error && error?.response?.data) {
      toast.warn(error?.response?.data?.title);
    } else {
      toast.error('Network error. Try again');
    }

    return false;
  }
};

export const deleteClubStatCategory = (clubId, statId) => async (dispatch) => {
  try {
    const response = await Api2?.club?.deleteClubStatCategory(clubId, statId);
    if (response.data) {
      toast.success('Deleted successfully');
      return true;
    }
  } catch (error) {
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};

export const addClubStats = (clubId, data) => async (dispatch) => {
  try {
    const response = await Api2?.club?.addClubStats(clubId, data);
    if (response.data) {
      toast.success(response?.data?.message);
      const res = await Auth.fetchUser();
      // console.log('🚀 ~ file: clubSlice.js:229 ~ addClubStats ~ res:', res);

      return true;
    }
  } catch (error) {
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};

export const deleteClubStats = (clubId, statId) => async (dispatch) => {
  try {
    const response = await Api2?.club?.deleteClubStats(clubId, statId);
    if (response.data) {
      toast.success('Deleted successfully');
      return response.data.data;
    }
    // dispatch(setLoading(false));
  } catch (error) {
    // dispatch(setLoading(false));
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};

export const fetchClubMembers = (clubId) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));

    const response = await Api2?.club?.fetchClubMembers(clubId);
    // dispatch(setLoading(false));
    return response.data.data;
  } catch (error) {
    // dispatch(setLoading(false));
    if (error && error?.response?.data) {
      toast.warn(error?.response?.data?.title);
    } else {
      toast.error('Network error. Try again');
    }

    return false;
  }
};

export const addClubMemberStats = (clubId, memberId, data) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const response = await Api2?.club?.addClubMemberStats(clubId, memberId, data);
    if (response.data) {
      toast.success(response?.data?.message);
      return response.data.data;
    }
    // dispatch(setLoading(false));
  } catch (error) {
    // dispatch(setLoading(false));
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};

export const deleteClubMemberStats = (clubId, memberId, statId) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const response = await Api2?.club?.deleteClubMemberStats(clubId, memberId, statId);
    if (response.data) {
      toast.success('Deleted successfully');
      return true;
    }
    // dispatch(setLoading(false));
  } catch (error) {
    // dispatch(setLoading(false));
    if (error && error?.response?.data) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.warn(error?.response?.data?.title);
      }
    } else {
      toast.error('Network error. Try again');
    }
  }
};
