import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import { getIsLoading } from 'Store/loaderSlice';
import { PostsList } from 'Components/PostsList';
import { Loader } from 'Components/shared/Loader';
import { PostListContainer } from 'Components/PostsList/components';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { fetchAllPosts } from 'Store/postSlice';
import { Container, ContentContainer, Title } from 'Components/shared/styledComponents';
import { LoaderImage } from 'Components/shared/Loader/components';
import {
  getPostsSelector,
  getTimelineMetaData,
  setPosts,
  setTimelineMetaData
} from 'Store/selectors/postSelector';
import { useTranslation } from 'react-i18next';
import { MainNavigation } from 'Components/Layouts/MainNavigation';
import isEmpty from 'Utils/isEmpty';
import { getProfile } from 'Store/profileSlice';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import Auth from 'middleware/storage';
import Api from 'middleware/api';
import { ThemeContext } from 'Context/themeContext';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { resendEmail } from 'Store/authSlice';
import { paths, userRoles } from 'Utils/constants';
import { fetchActivePurchases } from 'Store/purchasesSlice';
import { getActiveStripePurchase } from 'Store/selectors/purchaseSelector';
import { push } from 'connected-react-router';
import useLocalStorage from 'Hooks/useLocalStorage';

export const Timeline = () => {
  const isLoading = useSelector(getIsLoading);
  const allposts = useSelector(getPostsSelector);
  const _metaData = useSelector(getTimelineMetaData);
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const [more, setMore] = useState(false);
  const { t } = useTranslation();
  const { data } = useSelector(getProfileSelector);
  const activeSub = useSelector(getActiveStripePurchase);
  const [checkScoutStatus, setCheckScoutStatus] = useState(true);

  const checkActiveUser = async () => {
    toast.warn(`Please kindly activate your account from your email`);

    setTimeout(() => {
      Swal.fire({
        title: `Activate your account`,
        text: `Open your email to activate your account`,
        showCancelButton: true,
        confirmButtonColor: theme?.spoxioAltBlue,
        cancelButtonColor: theme?.spoxioRed,
        confirmButtonText: 'Open',
        cancelButtonText: 'Resend',
        reverseButtons: true,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          window.open('mailto:%20?subject=%20&body=%20');
        } else {
          resendEmail(data);
        }
      });
    }, 1500);
  };

  const fetchPost = async () => {
    await dispatch(fetchAllPosts());
    setMore(true);
  };

  const loadMore = async () => {
    if (_metaData?.page >= _metaData?.page_count) {
      setMore(false);
      return;
    }
    try {
      const token = Auth.getToken();
      const response = await Api.post.timelinePost(_metaData?.page + 1, _metaData?.per_page, token);
      console.log(response, 'the resp all post inside');
      const { data } = response.data;
      dispatch(setPosts([...allposts, ...data]));
      dispatch(setTimelineMetaData(response.data?._metadata));
    } finally {
      console.log('i got hereee');
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      dispatch(getProfile());
      fetchPost();
      dispatch(fetchActivePurchases());
      const stat = localStorage.getItem('checkScoutStatus');
      setCheckScoutStatus(stat);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const gotoPayment = async () => {
    try {
      // eslint-disable-next-line eqeqeq
      if (data?.userType == userRoles.SCOUT && activeSub.length < 1) {
        Swal.fire({
          title: `Welcome Scout`,
          text: `To enjoy scout features, Kindly subscribe`,
          showCancelButton: true,
          confirmButtonColor: theme?.spoxioAltBlue,
          cancelButtonColor: theme?.spoxioRed,
          confirmButtonText: 'Subscribe',
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(push(paths.SCOUT_SUBSCRIPTION));
            localStorage.setItem('checkScoutStatus', 'true');
            setCheckScoutStatus('true');
          } else {
            localStorage.setItem('checkScoutStatus', 'true');
            setCheckScoutStatus('true');
          }
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (checkScoutStatus == null || checkScoutStatus === 'null' || checkScoutStatus !== 'false') {
      return;
    }
    gotoPayment();
    return () => {};
  }, [activeSub, data, checkScoutStatus]);

  // const onGoBackAfterDelete = (index) => {
  //   const newPosts = [...state.posts];
  //   newPosts.splice(index, 1);

  //   dispatch({
  //     type: 'posts',
  //     payload: newPosts
  //   });
  // };

  useEffect(() => {
    if (isEmpty(data)) return;
    data.email && !data.isActive && checkActiveUser(data);
  }, [data.isActive]);

  useEffect(() => {
    return () => {
      console.log('\n 🚀 ~ file: index.jsx:163 ~ return ~ allposts:', allposts);
      console.log('\n 🚀 ~ file: index.jsx:165 ~ return ~ data:', data);
    };
  }, [allposts]);

  return (
    <MainNavigation navTitle={`Hi, ${!isEmpty(data) ? data?.firstName : 'Welcome'}✌`}>
      <ContentContainer height="90vh" overflow="auto">
        <InfiniteScroll
          pageStart={0}
          loadMore={async () => await loadMore()}
          hasMore={more}
          loader={<LoaderImage key={0} />}
          element={PostListContainer}
          useWindow={false}>
          {isLoading && !allposts?.length ? (
            <Container>
              <Loader size={24} />
            </Container>
          ) : null}

          {allposts?.length ? <PostsList postList={allposts} /> : null}

          {!isLoading && !allposts?.length ? (
            <EmptyContent
              description={
                <Title fontSize="1.2rem" fontWeight="bold">
                  {t('post_empty')}
                </Title>
              }
            />
          ) : null}
        </InfiniteScroll>
      </ContentContainer>
    </MainNavigation>
  );
};
