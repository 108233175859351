import { arrayOf, func, shape, string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GroupMessage } from 'Assets/icons/groupchat.svg';

import {
  Sort,
  SortList,
  StatsHeadContainer,
  GroupMessageIcon,
  RightBar,
  BulkButton,
  NextBtn,
  Cancel,
  BtnContainer
} from './components';
import { ContentContainer, IconContainer } from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';

export const StatsHead = ({ sorts, currentSort, onSort, setShowCheckbox, onNextClick }) => {
  const { theme } = useContext(ThemeContext);
  const [showBtn, setShowBtn] = useState(false);
  const { t } = useTranslation();
  const toggleBtn = (val) => {
    setShowBtn(val);
    setShowCheckbox(val);
  };

  const showMessages = () => onNextClick(true);

  return (
    <StatsHeadContainer>
      <SortList>
        {sorts.map((item) => (
          <Sort
            key={item.value}
            selected={item.value === currentSort.value}
            onClick={() => onSort(item)}
          >
            {item.label}
          </Sort>
        ))}
      </SortList>
      <RightBar>
        {showBtn ? (
          <BtnContainer>
            <Cancel variant="transparent" onClick={() => toggleBtn(false)}>
              {t('cancel')}
            </Cancel>
            <NextBtn onClick={showMessages}>{t('next')}</NextBtn>
          </BtnContainer>
        ) : (
          <ContentContainer row alignItems="center" gap="0">
            <IconContainer fill={theme.spoxioIcon}>
              <GroupMessage />
            </IconContainer>
            <BulkButton onClick={() => toggleBtn(true)}>
              {/* <GroupMessageIcon viewBox="-6 -4 30 19" /> */}
              {t('bulk_msg')}
            </BulkButton>
          </ContentContainer>
        )}
      </RightBar>
    </StatsHeadContainer>
  );
};

StatsHead.propTypes = {
  sorts: arrayOf(
    shape({
      label: string,
      value: string
    })
  ).isRequired,
  currentSort: shape({
    label: string,
    value: string
  }).isRequired,
  onSort: func,
  setShowCheckbox: func,
  onNextClick: func
};

StatsHead.propTypes = {
  onSort: () => {},
  setShowCheckbox: () => {},
  setShowMessage: () => {}
};
