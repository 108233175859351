import { Footer } from 'Components/Landing/Footer';
import { Header } from 'Components/Landing/Header';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import 'Styles/main.scss';
import 'Styles/privacy_terms.scss';

const Paragraph = styled.p`
  color: ${({ theme }) => theme.spoxioText};
  opacity: 0.8;
`;
const Span = styled.span`
  color: ${({ theme }) => theme.black};
`;

export const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Header />
      <main
        className="main container nullable-vertical-padding"
        style={{ background: theme.background }}
      >
        <div className="main-content">
          <h1 className="main-title-secondary" style={{ color: theme.black }}>
            {t('privacy_policy')}
          </h1>
          <div className="main-body">
            <Paragraph className="main-text">{t('policy_p1')}</Paragraph>
            <Span className="main-text-header">{t('policy_h1')}</Span>
            <Paragraph className="main-text">{t('policy_p2')}</Paragraph>
            <Span className="main-text-header">{t('policy_h2')}</Span>
            <Paragraph className="main-text">{t('policy_p3')}</Paragraph>
            <Span className="main-text-header">{t('policy_h3')} </Span>
            <Paragraph className="main-text">{t('policy_p4')}</Paragraph>
            <Span className="main-text-header">{t('policy_h4')}</Span>
            <Paragraph className="main-text">{t('policy_p5')}</Paragraph>
            <Span className="main-text-header">{t('policy_h5')}</Span>
            <Paragraph className="main-text">{t('policy_p6')}</Paragraph>
            <Span className="main-text-header">{t('policy_h6')}</Span>
            <Paragraph className="main-text">{t('policy_p7')}</Paragraph>
            <Span className="main-text-header">{t('policy_h7')}</Span>
            <Paragraph className="main-text">{t('policy_p8')}</Paragraph>
            <Span className="main-text-header">{t('policy_h8')}</Span>
            <Paragraph className="main-text">{t('policy_p9')}</Paragraph>
            <Span className="main-text-header">{t('policy_h9')}</Span>
            <Paragraph className="main-text">{t('policy_p10')}</Paragraph>
            <Span className="main-text-header">{t('policy_h10')}</Span>
            <Paragraph className="main-text">
              {t('policy_p11')}
              info@spoxio.com
            </Paragraph>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
