import React, { useContext, useEffect, useState } from 'react';
import { InputForm, TicketsForm, TypeTickets } from './components';
import Unchecked from '../../../Assets/icons/CheckboxUnchecked.svg';
import Checked from '../../../Assets/icons/CheckboxChecked.svg';
import { AboutTags } from 'Components/OtherProfile/components';
import EventScheduling from './EventScheduling';
import {
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  SubTitle
} from 'Components/shared/styledComponents';
import { Close, Image, ImageContainer } from 'Components/PostModal/components';
import { DragDropFileInput } from 'Components/shared/Inputs/DragDropFilesInput';
import { isVideo } from 'Utils';
import { useTranslation } from 'react-i18next';
import { CameraIcon } from 'Components/shared/Inputs/DragDropFilesInput/components';
import { ThemeContext } from 'Context/themeContext';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFormData, setFormData } from 'Store/selectors/dataSelector';
import { getCurrentUserId } from 'Store/authSlice';
import { uploadMedia } from 'Api/services';
import { toast } from 'react-toastify';
import firebase from 'firebase/app';
import 'firebase/storage';
import { createEvent } from 'Store/eventSlice';
import { Loader } from 'Components/shared/Loader';

const Tickets = ({ handlePrev, setStep }) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(getCurrentUserId);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const formData = useSelector(getFormData);
  const [schedule, setSchedule] = useState([]);
  const [files, setFiles] = useState([]);

  const selectFile = (base64Name, file) => {
    setFiles([...files, { base64Name, file }]);
  };

  const removeFile = (index) => {
    const newListOfFiles = files.filter((value, currentIndex) => currentIndex !== index);
    setFiles(newListOfFiles);
  };

  useEffect(() => {
    console.log(files, `\n Form data event upload \n`);
  }, [files, formData]);

  // useEffect(() => {
  //   // console.log(formData, `\n Form data event upload \n`);
  // }, [formData]);

  // const handleCreateEvent = () => async (dispatch) => {
  //   setLoading(false);
  //   const posts = await uploadMedia(files, currentUserId);

  //   const data = {
  //     ...formData,
  //     schedule: schedule,
  //     photos: posts
  //   };

  //   await Api2.post.createPost(data);

  //   // await dispatch(fetchAllPosts());

  //   dispatch(setLoading(false));
  // };

  const handleCreateEvent = async () => {
    setLoading(true);
    console.log(formData, 'form data submitted', files);
    try {
      const mediaSources = [];
      await Promise.all(
        files?.map(async (attachment) => {
          const type = attachment.base64Name.includes('image') ? 'image' : 'video';
          const extension = attachment.base64Name.includes('image') ? '.jpg' : '.mp4';
          const filename = `${new Date().getTime()}${extension}`;
          const storageRef = firebase.storage().ref(`users/${filename}`);

          await storageRef.put(attachment.file);
          const dataUrl = await storageRef.getDownloadURL();

          mediaSources.push(dataUrl);
        })
      );

      console.log('mediaSources URL: ' + mediaSources);

      let payload = {
        photos: mediaSources,
        about: formData?.about,
        category: (formData?.sportCategory.label).toLowerCase(),
        end_time: formData.end_time,
        event_date: new Date(formData.event_date),
        event_link: formData.event_link,
        is_paid: false,
        is_virtual: false,
        schedules: formData.schedules,
        start_time: formData.start_time,
        title: formData.title,
        venue: formData.venue,
        venue_address: formData.venue_address
      };

      console.log('--------------Create Event Payload--------------', payload);

      let data = await dispatch(createEvent(payload));
      setLoading(false);
      if (data) {
        console.log('-----------------___-Create Event Response______----------------', data);
        setStep(3);
        dispatch(setFormData({ ...data }));
        // navigation.navigate('EventCreated', { data });
      }
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };

  return (
    <>
      {/* <TypeTickets>
        <TicketsForm
          onClick={() => {
            setPaidTickets((prev) => !prev);
          }}
        >
          <img src={paidTickets ? Checked : Unchecked} width={'20px'} alt={''} />
          <SubTitle> Paid Tickets</SubTitle>
        </TicketsForm>
        <TicketsForm
          onClick={() => {
            setPaidTickets((prev) => !prev);
          }}
        >
          <img src={!paidTickets ? Checked : Unchecked} width={'20px'} alt={''} />
          <SubTitle> Free Tickets</SubTitle>
        </TicketsForm>
      </TypeTickets> */}
      {/* {paidTickets && (
        <form
          style={{ display: 'flex', flexDirection: 'column', rowGap: '10px', marginBottom: '5px' }}
        >
          <InputForm placeholder={'Amount'} />
          <InputForm placeholder={'Payment Link'} />
        </form>
      )} */}
      <AboutTags size={'1rem'} weight={'400'}>
        Upload event photos
      </AboutTags>
      <ContentContainer row overflowX="auto" gap="10px" padding="0 20px 0" hideScroll>
        {files.length
          ? files.map((file, index) => (
              <ImageContainer key={index} height="150px" minWidth="50%">
                {isVideo(file.base64Name) ? (
                  <video src={file.base64Name} />
                ) : (
                  <Image
                    src={file.base64Name}
                    alt={t('Add Image')}
                    style={{ inlineSize: '-webkit-fill-available' }}
                  />
                )}
                <IconContainer width="20px" height="20px" fill={theme?.spoxioIcon} margin="0">
                  <Close title="Remove" onClick={() => removeFile(index)} />
                </IconContainer>
              </ImageContainer>
            ))
          : null}
        {files.length < 10 ? (
          <ContentContainer>
            <DragDropFileInput
              onChange={selectFile}
              acceptedFormat="image/*"
              customIcon={
                <ContentContainer
                  width="150px"
                  height="150px"
                  column
                  justifyContent="center"
                  alignItems="center">
                  <CameraIcon />
                  <SubTitle fontSize="0.875rem">Add Image</SubTitle>
                </ContentContainer>
              }
            />
          </ContentContainer>
        ) : null}
      </ContentContainer>
      <AboutTags size={'0.875rem'} weight={'400'}>
        Upload up to 10 image (cover image first)
      </AboutTags>

      {/* Scheduling */}
      <EventScheduling formData={formData} schedule={schedule} setSchedule={setSchedule} />

      <ContentContainer column width="100%" gap="0px" margin="auto 0 0">
        <SpoxioBlueBtn
          onClick={() => {
            handleCreateEvent();
          }}>
          {!loading ? 'Submit event for review' : <Loader />}
        </SpoxioBlueBtn>
        {!loading ? (
          <SpoxioBlueBtn
            border={theme?.spoxioText}
            background="transparent"
            color={theme?.spoxioText}
            onClick={() => {
              handlePrev();
            }}>
            Previous
          </SpoxioBlueBtn>
        ) : null}
      </ContentContainer>
    </>
  );
};

Tickets.propTypes = {
  handlePrev: func,
  setStep: func
};

export default Tickets;
