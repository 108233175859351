import React from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Api from 'Api';
import { paths, SCOUT_MESSAGE } from 'Utils/constants';
import { getCurrentUser, getCurrentUserId } from 'Store/authSlice';
import {
  MessagesContainer,
  InputContainer,
  MessageHead,
  Title,
  BackIcon,
  UsersContainer,
  UserIcon,
  MessageIcon,
  Info
} from './components';
import { MessageInput } from 'Components/Chat/MessageInput';
import { UserPic } from 'Components/shared/UserPic';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

export const Messages = ({ users, setShowMessage }) => {
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const currentUserId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();

  const onSendClick = async () => {
    await Api.messages.bulkCreate(users, currentUserId);
    dispatch(push(paths.CHAT));
  };

  return (
    <MessagesContainer>
      <MessageHead>
        <Title>
          {t('sending_bulk_msg')} {users?.length} {t('people')}
        </Title>
        <UsersContainer>
          {users?.map((user) => (
            <UserIcon key={user._id}>
              <UserPic src={user?.picture} size={32} />
            </UserIcon>
          ))}
        </UsersContainer>
        <BackIcon onClick={() => setShowMessage(false)} />
      </MessageHead>
      <Info>&#9432; {t('will_receive_separately')}</Info>
      <InputContainer>
        <MessageIcon>
          <UserPic src={currentUser.picture} size={32} />
        </MessageIcon>
        <MessageInput defaultMessage={SCOUT_MESSAGE} onSendClick={onSendClick} />
      </InputContainer>
    </MessagesContainer>
  );
};

Messages.propTypes = {
  users: arrayOf(shape({})),
  setShowMessage: func
};

Messages.defaultProps = {
  users: [],
  setShowMessage: () => {}
};
