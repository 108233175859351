import DefaultUserAvatar from 'Assets/img/DefaultUserAvatar.png';

export const getFormattedStories = (user) => {
  return user?.stories?.map((story) => ({
    ...story,
    header: {
      heading: user.username,
      profileImage: user.profile || DefaultUserAvatar
    }
  }));
};
