import { arrayOf, func, shape, string } from 'prop-types';

import { ResultHeader } from 'Components/ResultHeader';
import { Content, ResultUsersContainer } from './components';
import { BarTitle, ContentContainer } from 'Components/shared/styledComponents';
import { FollowUser } from 'Components/shared/FollowUser';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { useTranslation } from 'react-i18next';
import { FollowButtonRevamp } from 'Components/shared/FollowButtonRevamp';
import { FollowUserPage } from 'Components/shared/FollowUserPageRevamp';

export const ResultUsersTopics = ({ users, topics, searchString, onTopicClick }) => {
  const { t } = useTranslation();

  const renderContent = () => {
    if (users.length < 1) {
      return <EmptyContent description={t('no_data')} title={searchString} margin="10px 0 0" />;
    }

    return (
      <>
        <BarTitle textAlign="center" margin="0" fontSize="1.325rem">
          {t('user_profiles')}
        </BarTitle>
        <ContentContainer overflowY="auto" gap="12px" width="100%">
          {users.map((user) => {
            console.log('the user,', user);
            return <FollowUser key={user._id} user={user} />;
          })}
        </ContentContainer>
      </>
    );
  };

  return (
    <Content>
      <ResultHeader searchString={searchString} />
      {renderContent()}
    </Content>
  );
};

ResultUsersTopics.propTypes = {
  users: arrayOf(shape({})),
  topics: arrayOf(shape({})),
  searchString: string,
  onTopicClick: func
};

ResultUsersTopics.defaultProps = {
  users: [],
  topics: [],
  searchString: '',
  onTopicClick: () => {}
};
