import { Route, Switch } from 'react-router-dom';

import { paths, userRoles } from 'Utils/constants';
import { PrivateRote } from './PrivateRote';
import { Home } from './Landing/Home';
import { About } from './Landing/About';
import { PrivacyPolicy } from './Landing/PrivacyPolicy';
import { TermsOfUse } from './Landing/TermsOfUse';
// import { SignUpEmailPage } from './Auth/SignUp/SignUpEmailPage';
// import { SignInPage } from './Auth/SignIn';
// import { ForgotPassword } from './Auth/SignIn/ForgotPassword';
// import { SignUpGeneralInfoPage } from './Auth/SignUp/SignUpGeneralInfoPage';
// import { SignUpRolePage } from './Auth/SignUp/SignUpRole';
// import { EnhanceProfilePage } from './Auth/SignUp/EnhanceProfile';
// import { SignUpChoseSports } from './Auth/SignUp/SignUpChoseSports';
// import { ProfilePage } from './Profile';
import { PostDetail } from './PostDetail';
import { ReportPost } from './ReportPost';
import { ChatPage } from './Chat';
import { DiscoveryPage } from './Discovery';
import { EditProfile } from './EditProfile';
import { StatsPage } from './Stats';
import { StoriesPage } from './StoriesPage';
import { MobileDetectRoute } from './MobileDetectRoute';
import { ScrollToTop } from 'Components/ScrollToTop';
import { PublicRoute } from './PublicRoute';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProfile } from 'Store/profileSlice';
import { push } from 'connected-react-router';
import { SignInPage } from './Auth/SignInRevamp';
import { ForgotPassword } from './Auth/SignInRevamp/ForgotPassword';
import OnboardingPage from './Auth/OnboardingPage';
import { SignUpGeneralInfoPage } from './Auth/SignUpRevamp/SignUpGeneralInfoPage';
import { SignUpRolePage } from './Auth/SignUpRevamp/SignUpRole';
import { EnhanceProfilePage } from './Auth/SignUpRevamp/EnhanceProfile';
import { SignUpChoseSports } from './Auth/SignUpRevamp/SignUpChoseSports';
import { Timeline } from './TimelineRevamp';
import Notification from './Notification';
import { ProfilePage } from './ProfileRevamp';
import { Events } from './Events';
import EventDetails from 'Components/Event/EventDetails/EventDetails';
import { OtherProfile } from 'Components/OtherProfile/OtherProfile';
import Scout from 'Components/OtherProfile/Scout';
import ScoutActivities from './Scout';
import { FollowPage } from './FollowPage';
import { ChangePassword } from 'Components/ProfileRevamp/ChangePassword';
import { Personalization } from 'Components/ProfileRevamp/Personalization';
import { AboutSpoxio } from 'Components/ProfileRevamp/AboutSpoxio';
import ScoutSubscription from 'Components/Scout/ScoutSubscription/ScoutSubscription';
import { SignUpPaymentsPage } from './Auth/SignUpRevamp/SignUpPaymentsPage';
import { AllEvents } from './Events/AllEvents';
import SubscriptionDetails from './SubscriptionDetails/SubscriptionDetails';
import { Invitations } from './Invitations';

export const Router = () => {
  const dispatch = useDispatch();
  const userRole = localStorage.getItem('type');
  useEffect(() => {
    dispatch(getProfile());
    if (userRole === userRoles.DEFAULT) {
      dispatch(push(paths.SIGN_UP_ROLE));
      return;
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <Switch>
        {/* Landing routes */}
        <PublicRoute exact path={paths.HOME} component={Home} />
        <Route path={paths.ABOUT} component={About} />
        <Route path={paths.PRIVACY} component={PrivacyPolicy} />
        <Route path={paths.TERMS_OF_CONDITIONS} component={TermsOfUse} />
        <Route exact path={paths.CLUB_INVITE} component={Invitations} />

        {/* Authentication routes */}
        <PublicRoute path={paths.USERONBOARDING} component={OnboardingPage} />
        <Route exact path={paths.SIGN_UP} component={SignUpGeneralInfoPage} />
        <PrivateRote path={paths.SIGN_UP_ROLE} component={SignUpRolePage} />
        <PrivateRote path={paths.SIGN_UP_ENHANCE_INFO} component={EnhanceProfilePage} />

        <Route path={paths.SIGN_UP_PAYMENTS} component={SignUpPaymentsPage} />
        <Route path={paths.SIGN_UP_SPORTS} component={SignUpChoseSports} />
        <PublicRoute path={paths.SIGN_IN} component={SignInPage} />
        <PublicRoute path={paths.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={paths.MOBILE_SCREEN} component={MobileDetectRoute} />
        <Route exact path={`${paths.TIMELINE}/:id`} component={PostDetail} />

        {/* Private routes */}
        <PrivateRote exact path={paths.TIMELINE} component={Timeline} />
        <PrivateRote exact path={`${paths.TIMELINE}/:id`} component={PostDetail} />
        <PrivateRote path={`${paths.TIMELINE}/:id/report`} component={ReportPost} />
        <PrivateRote path={`${paths.STORIES}/:id`} component={StoriesPage} />
        <PrivateRote exact path={[paths.PROFILE, `${paths.PROFILE}/:id`]} component={ProfilePage} />
        <PrivateRote exact path={`${paths.PROFILE}/:id/sports`} component={Personalization} />
        <PrivateRote exact path={`${paths.PROFILE}/:id/about`} component={AboutSpoxio} />
        <PrivateRote
          exact
          path={`${paths.PROFILE}/:id/change_password`}
          component={ChangePassword}
        />
        <PrivateRote
          path={[
            `${paths.PROFILE}/:id/followers`,
            `${paths.PROFILE}/:id/following`,
            // `${paths.PROFILE}/:id/mutual`
          ]}
          component={FollowPage}
        />
        <PrivateRote
          path={[`${paths.PROFILE}/:id/scout`, `${paths.PROFILE}/:id/scout`]}
          component={Scout}
        />
        <PrivateRote path={`${paths.PROFILE}/:id/edit`} component={EditProfile} />
        <PrivateRote exact path={[paths.CHAT, `${paths.CHAT}/:id`]} component={ChatPage} />
        <PrivateRote exact path={paths.NOTIFICATION} component={Notification} />
        <PrivateRote path={paths.STATS} component={StatsPage} />
        <PrivateRote path={paths.DISCOVERY} component={DiscoveryPage} />
        <PrivateRote exact path={paths.EVENTS} component={Events} />
        <PrivateRote
          exact
          path={[`${paths.VIEW_TRENDING_EVENTS}`, `${paths.VIEW_PARTICIPATING_EVENTS}`]}
          component={AllEvents}
        />
        <PrivateRote exact path={`${paths.EVENTS}/:id`} component={EventDetails} />

        {/* UserProfiles routes */}
        <PublicRoute path={paths.OTHERROFILE} component={OtherProfile} />

        {/* Scout Activities */}
        <PrivateRote path={paths.SCOUT_ACTIVITIES} component={ScoutActivities} />
        <PrivateRote path={paths.SCOUT_PAYMENTS} component={SubscriptionDetails} />
        <PrivateRote path={paths.SCOUT_SUBSCRIPTION} component={ScoutSubscription} />
      </Switch>
    </>
  );
};
