import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {  SocialInfoContainer } from 'Routes/Auth/components';
import { emailRules, passwordRules, requiredRule } from 'Utils/validations';
import { paths } from 'Utils/constants';
import LogoSecondary from 'Assets/img/Logo.png';
import {
  ButtonGroup,
  ClubName,
  ClubTitle,
  InvitationContent,
  InvitationsBody,
  InvitationsHeader,
  InvitationsModal,
  InvitationsTitle,
  InvitationsWrapper,
  Paragraph,
  Subtitle,
  TitleDetails,
  TitleProfile
} from './components';
import { ContentContainer, SpoxioBlueBtn } from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { object } from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { acceptClubInvite } from 'Store/clubSlice';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router';
import Swal from 'sweetalert2';
import { LoaderImage } from 'Components/shared/Loader/components';

export const InvitationsPage = ({ clubData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  
  const { register, errors, handleSubmit } = useForm();

  const redirectToLogin = () => {
    dispatch(push(`${paths.SIGN_IN}`));
  };

  const confirmIgnoreInvite = (data) => {
    Swal.fire({
      title: `Ignore Club Invite?`,
      text: `Kindly proceed to decline club invite if you wish. The invitation to join ${clubData?.name} club can still be accepted later until invitation expires.`,
      showCancelButton: true,
      confirmButtonColor: theme?.spoxioAltBlue,
      cancelButtonColor: theme?.spoxioRed,
      confirmButtonText: 'Decline',
      reverseButtons: true,
      imageUrl: data?.url,
      imageWidth: 200,
      imageHeight: 150,
      imageAlt: ''
    }).then((result) => {
      if (result.isConfirmed) {
        redirectToLogin();
      }
    });
  };

  const submitInvitation = async (data) => {
    setLoading(true);
    const payload = {
      ...data,
      username: data.username,
      token: query.get('inviteToken'),
      clubProfileId: query.get('clubProfileId')
    };

    try {
      const response = await acceptClubInvite(payload);
      if (response) {
        setTimeout(() => {
          console.log('\n 🚀 ~ file: index.jsx:94 ~ submitInvitation ~ response:', response);
          redirectToLogin();
        }, 3000);
      }
    } catch (error) {
      console.log('\n 🚀 ~ file: index.jsx:57 ~ submitInvitation ~ error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <InvitationsWrapper>
      <InvitationsHeader>
        <InvitationsModal />
        <InvitationsTitle>
          <TitleProfile
            effect="blur"
            src={clubData?.user?.picture}
            placeholder={<Skeleton circle borderRadius="50%" />}
            placeholderSrc={LogoSecondary}
          />
          <TitleDetails>
            <ClubTitle>{clubData?.name}</ClubTitle>
            <Subtitle>{clubData.address}</Subtitle>
          </TitleDetails>
        </InvitationsTitle>
      </InvitationsHeader>
      <InvitationsBody>
        <InvitationContent>
          <Paragraph>You have been invited to join</Paragraph>
          <ClubName>{clubData?.name}</ClubName>
          {/* <Paragraph2>Enter the unique invitation code sent to your email to continue</Paragraph2> */}
        </InvitationContent>

        <form onSubmit={handleSubmit(submitInvitation)}>
          <SocialInfoContainer marginBottom="0">
            <ContentContainer
              row
              width="100%"
              gap="4px"
              flexWrap="wrap"
              justifyContent="space-between">
              <ContentContainer width="48%">
                <FormInput
                  ref={register(requiredRule)}
                  name="firstName"
                  placeholder={t('first_name')}
                  error={errors?.firstName?.message}
                  // className="margin-bottom_medium"
                />
              </ContentContainer>
              <ContentContainer width="48%">
                <FormInput
                  ref={register}
                  name="lastName"
                  placeholder={`${t('last_name')} (Optional)`}
                  // className="margin-bottom_medium"
                />
              </ContentContainer>
            </ContentContainer>

            <FormInput
              ref={register(emailRules)}
              name="username"
              placeholder={t('email')}
              error={errors?.username?.message}
              // className="margin-bottom_medium"
            />
            <FormInput
              ref={register(passwordRules)}
              name="password"
              type="password"
              placeholder={t('password')}
              // helperText={t('minimum_password')}
              helperText={t('Minimum of 6 letters and/or numbers')}
              error={errors?.password?.message}
              // className="margin-bottom_medium"
              isPassword
            />
          </SocialInfoContainer>

          <ButtonGroup>
            <SpoxioBlueBtn
              hover
              disabled={loading}
              type="button"
              background="transparent"
              border={theme?.spoxioAltBlue}
              onClick={confirmIgnoreInvite}>
              Ignore Invitation
            </SpoxioBlueBtn>
            <SpoxioBlueBtn disabled={loading} hover type="submit">
              {loading ? <LoaderImage size={16} /> : ' Complete setup'}
            </SpoxioBlueBtn>
          </ButtonGroup>
        </form>
      </InvitationsBody>
    </InvitationsWrapper>
  );
};

InvitationsPage.propTypes = {
  clubData: object
};

InvitationsPage.defaultProps = {
  clubData: {}
};
