import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { getIsLoading } from 'Store/loaderSlice';
import { passwordRules, requiredRule } from 'Utils/validations';
import { FollowSportsContainer, SubmitBtn, Input } from './components';
import { Loader } from 'Components/shared/Loader';
import { accountDeactivate, changePassword, updateUser } from 'Store/authSlice';
import { useTranslation } from 'react-i18next';
import { ProfileLayout } from 'Components/Layouts/ProfileLayout';
import {
  ContentContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';

import { ThemeContext } from 'Context/themeContext';
import { ButtonsTag, DetailsTag } from 'Components/OtherProfile/components';
import { ChoseSportsGrid } from 'Components/ChoseSports/ChoseSportsGrid';
import { fetchSports } from 'Store/dataSlice';
import { getSportSelector } from 'Store/selectors/dataSelector';
import { toast } from 'react-toastify';
import { paths } from 'Utils/constants';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { getProfileById } from 'Store/profileSlice';
import { useLocation } from 'react-router';
import {
  SportItemButton,
  SportItemContainer,
  SportList,
  SportName
} from 'Components/ChoseSports/components';
import isEmpty from 'Utils/isEmpty';
import { ReactComponent as IconMedal } from 'Assets/icons/icon-medal.svg';

export const Personalization = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const isLoading = useSelector(getIsLoading);
  const suggestedSports = useSelector(getSportSelector);
  const profile = useSelector(getProfileSelector);
  const [tag, setTag] = useState(1);

  const path = location.pathname.split('/');
  const profileId = path[2];

  useEffect(() => {
    dispatch(getProfileById(profileId));
  }, [profileId]);
  const { data } = profile;

  useEffect(() => {
    if (!data?.sports?.length) {
      dispatch(fetchSports());
    }
  }, []);

  const [selectedSports, selectSport] = useState();

  useEffect(() => {
    const selectedSportIds = data?.sports?.map((x) => x._id);
    selectSport(selectedSportIds);
  }, [profile]);

  const checkIfSelected = (id) => {
    return selectedSports?.includes(id);
  };

  const onChangeValue = (value) => {
    console.log(value, 'sport value');
    if (checkIfSelected(value) === false) {
      let newSelectedSports = [...selectedSports, value];
      selectSport(newSelectedSports);
    } else {
      const filteredSports = selectedSports?.filter((sport) => sport !== value);
      selectSport(filteredSports);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!data?.sports?.length) {
      toast.warn(t('choose_one_sport'));
    } else {
      const formattedSports = new Set(selectedSports);
      const pathToRedirect = paths.PROFILE;
      dispatch(updateUser({ sports: [...formattedSports] }, pathToRedirect));
    }
  };
  // console.log('profile personalize', profile);

  return (
    <ProfileLayout navTitle="Personalization">
      <ContentContainer width="100%" column gap="0px" maxWidth="700px" margin="20px auto">
        {isLoading && <Loader size={24} height="100%" />}
        <ButtonsTag gap="20px">
          <DetailsTag
            width="auto"
            padding="8px 20px"
            active={tag === 1}
            onClick={() => {
              setTag(1);
            }}
          >
            My Preferences
          </DetailsTag>
          <DetailsTag
            width="auto"
            padding="8px 20px"
            active={tag === 2}
            onClick={() => {
              setTag(2);
            }}
          >
            Suggestions
          </DetailsTag>
        </ButtonsTag>

        <ContentContainer column gap="0px" margin="20px 0 0">
          <SubTitle>Interested in</SubTitle>
        </ContentContainer>

        {tag === 1 && (
          <SportList flexWrap="wrap">
            {data?.sports?.map((sport) => {
              return (
                <SportItemContainer key={sport._id}>
                  <SportItemButton isSelected>
                    {!isEmpty(sport.icon) ? (
                      <img src={sport.icon} alt={sport.name} height="30px" width="30px" />
                    ) : (
                      <IconMedal width="30px" height="30px" />
                    )}
                  </SportItemButton>
                  <SportName>{sport.name}</SportName>
                </SportItemContainer>
              );
            })}
          </SportList>
        )}

        {tag === 2 && (
          <ContentContainer column gap="0px" margin="0 0 20px">
            <SportList flexWrap="wrap">
              {suggestedSports?.map((sport) => {
                const isSelected = checkIfSelected(sport._id);

                // console.log(isSelected, sports);
                return (
                  <SportItemContainer key={sport._id}>
                    <SportItemButton
                      isSelected={isSelected}
                      onClick={() => onChangeValue(sport._id)}
                    >
                      {!isEmpty(sport.icon) ? (
                        <img src={sport.icon} alt={sport.name} height="30px" width="30px" />
                      ) : (
                        <IconMedal width="30px" height="30px" />
                      )}
                    </SportItemButton>
                    <SportName>{sport.name}</SportName>
                  </SportItemContainer>
                );
              })}
            </SportList>
            <SpoxioBlueBtn marginTop="-25px" margin="0 0px" onClick={onSubmit}>
              I'm done
            </SpoxioBlueBtn>
          </ContentContainer>
        )}
      </ContentContainer>
    </ProfileLayout>
  );
};
