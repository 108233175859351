import { useContext, useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { isVideo } from 'Utils';
import { getIsLoading } from 'Store/loaderSlice';
import { createPost } from 'Store/postSlice';
import { getAddressForPost } from 'Utils/strings';
import { ModalWindow } from 'Components/shared/Modal';
import { DragDropFileInput } from 'Components/shared/Inputs/DragDropFilesInput';
import { Search } from './Search';
import {
  FormContainer,
  TextInput,
  SelectItems,
  FootballIcon,
  LocationIcon,
  Select,
  SelectLabel,
  Image,
  ImageSelectContainer,
  Close,
  ImageContainer,
  Button,
  File,
  Back,
  SearchWrapper
} from './components';
import { Loader } from 'Components/shared/Loader';

import { ReactComponent as BeforeIcon } from 'Assets/icons/Before.svg';
import { getSportSelector } from 'Store/selectors/dataSelector';
import { fetchSports } from 'Store/dataSlice';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';
import { getCurrentUser } from 'Store/authSlice';

export const PostModal = ({ isOpen, closeModal }) => {
  const { theme } = useContext(ThemeContext);
  const [address, setAddress] = useState(null);
  const [sport, setSport] = useState(null);
  const [search, setSearch] = useState(null);
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const isLoading = useSelector(getIsLoading);
  const sportCategories = useSelector(getSportSelector);
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const { register, handleSubmit } = useForm();

  const showSearch = (val) => setSearch(val);

  const onSelect = (searchResult) => {
    if (search === 'address') {
      setAddress(searchResult);
    } else {
      setSport(searchResult);
    }

    setSearch(null);
  };

  const selectFile = (base64Name, file) => setFiles([...files, { base64Name, file }]);

  const removeFile = (index) => {
    const newListOfFiles = files.filter((value, currentIndex) => currentIndex !== index);

    setFiles(newListOfFiles);
  };

  const onClose = () => {
    setAddress(null);
    setSport(null);
    setSearch(null);
    setFiles([]);
    closeModal();
  };

  const onSubmit = (data) => {
    if (!data?.text || !files.length) {
      toast.warn(t('should_add_description'));
      return;
    }

    dispatch(
      createPost({
        ...data,
        files,
        sport,
        address
      })
    ).then(() => {
      onClose();
    });
  };

  useEffect(() => {
    dispatch(fetchSports());
  }, []);

  return (
    <ModalWindow
      isOpen={isOpen}
      closeModal={onClose}
      leftComponent={
        search ? (
          <Back onClick={() => setSearch(null)}>
            <BeforeIcon />
            {t('back')}
          </Back>
        ) : null
      }
      title={t('New post - my feed')}>
      {isLoading && <Loader size={40} height="100%" />}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <TextInput ref={register} name="text" placeholder={t('what_to_share_talent')} />
        <SelectItems>
          <Select onClick={() => showSearch('address')}>
            <LocationIcon selected={address} />
            <SelectLabel>
              {address ? getAddressForPost(address.text) : t('add_location')}
            </SelectLabel>
          </Select>
          <Select onClick={() => showSearch('sport')}>
            <FootballIcon selected={sport} />
            <SelectLabel>{sport ? sport.name : t('add_sport')}</SelectLabel>
          </Select>
        </SelectItems>
        <ImageSelectContainer>
          {files.length
            ? files.map((file, index) => (
                <ImageContainer key={index} width={'350px'} minWidth="45%">
                  {isVideo(file.base64Name) ? (
                    <video src={file.base64Name} />
                  ) : (
                    <Image src={file.base64Name} alt={t('post')} height={'220px'} width={'300px'} />
                  )}
                  <Close color={theme.secondaryGray} onClick={() => removeFile(index)} />
                </ImageContainer>
              ))
            : null}
          {files.length < 2 ? (
            <File>
              <DragDropFileInput
                acceptedFormat={currentUser?.userType === 'talent' ? 'video/*' : 'image/*, video/*'}
                onChange={selectFile}
              />
            </File>
          ) : null}
        </ImageSelectContainer>
        <Button isVisible={!search}>{t('post')} </Button>
      </FormContainer>
      {search ? (
        <SearchWrapper>
          <Search
            data={search === 'address' ? '' : sportCategories}
            placeholder={search === 'address' ? t('search_location') : t('search_sport')}
            isAddress={search === 'address'}
            onSelect={onSelect}
          />
        </SearchWrapper>
      ) : null}
    </ModalWindow>
  );
};

PostModal.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired
};
