import { useTranslation } from 'react-i18next';
import 'Styles/main.scss';
import { LanguageWrapper } from './component';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <LanguageWrapper value={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)}>
        <option value="en">English</option>
        <option value="ch">Chinese</option>
        <option value="fr">French</option>
        <option value="ge">German</option>
        <option value="it">Italian</option>
        <option value="jp">Japanese</option>
        <option value="sp">Spanish</option>
      </LanguageWrapper>
    </>
  );
};

export default LanguageSwitcher;
