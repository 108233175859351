import styled from 'styled-components';

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '400px'};

  & svg {
    width: 100px;
    height: 60px;
    align-self: center;
    margin-bottom: 20px;
    & path {
      fill: ${({ theme }) => theme.spoxioText};
    }
  }
`;
