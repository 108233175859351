import styled from 'styled-components';

import { ReactComponent as Flag } from 'Assets/icons/Flag.svg';
import { BaseText, BlackText, ContentContainer } from '../styledComponents';
import { Title } from 'Components/Layouts/AuthLayout/components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import LogoSecondary from 'Assets/img/Logo.png';

export const PostContainer = styled.div`
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.borderShade};
  border-radius: 20px;
`;

export const PostContainerSmall = styled.div`
  display: inline-block;
  border-radius: 20px;
  height: 120px;
  width: 80px;
`;

export const PostHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  padding: 20px;
  gap: 4px;
`;

export const PostHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const PostNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
`;

const PostNavigationButton = styled.div`
  display: none;
  align-items: center;
  height: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
`;

export const PostNext = styled(PostNavigationButton)`
  right: 7px;
`;

export const PostPrevious = styled(PostNavigationButton)`
  left: 7px;
`;

export const PostMediaContainer = styled.div`
  width: 100%;
  max-height: 100%;
  position: relative;
  cursor: pointer;

  &:hover ${PostNext}, &:hover ${PostPrevious} {
    display: flex;
  }

  .carousel__inner-slide {
    & > .carousel__slide-focus-ring {
      display: none !important;
      visibility: hidden !important;
    }
  }
`;

export const PostMediaContainerSmall = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100px'};
  max-height: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
`;

export const PostPlayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  & svg {
    path {
      stroke: ${({ stroke }) => stroke};
    }
  }
`;

export const PostPlayContainerSmall = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  position: absolute;
  left: 6px;
  bottom: 6px;
  margin-top: auto;
  margin-bottom: auto;

  & svg {
    path {
      stroke: ${({ stroke }) => stroke};
    }
  }
`;

export const PostMuteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 30px;
  right: 7px;
`;

export const PostSlideContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius};
  position: relative;
  background-color: ${({ theme }) => theme.black};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};

  .post-image-wrapper {
    width: 100%;
  }
`;

export const PostMediaDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 0;
  position: absolute;
  bottom: 6px;
  /* background-color: ${({ theme }) => theme.black}; */
`;

export const PostDot = styled.div`
  width: ${({ isActive }) => (isActive ? '8px' : '5px')};
  height: ${({ isActive }) => (isActive ? '8px' : '5px')};
  margin-right: 2px;
  border-radius: 50%;
  background-color: ${({ theme, isActive }) => (isActive ? theme.spoxioAltBlue : '#b4b4b4')};
`;

export const PostImage = styled(LazyLoadImage).attrs((props) => ({
  effect: 'blur',
  placeholder: (
    <Skeleton
      height="100%"
      wrapper={() => {
        return (
          <ContentContainer height="100%" width="100%" justifyContent="center" alignItems="center">
            <img src={LogoSecondary} alt="" />
          </ContentContainer>
        );
      }}
    />
  )
  // placeholderSrc: LogoSecondary
}))`
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const PostVideo = styled.video`
  object-fit: contain;
  width: 100%;
  border-radius: ${({ borderRadius }) => borderRadius};
`;

export const PostUserData = styled.div`
  margin-left: 12px;
`;

export const PostUserName = styled(BlackText)`
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: ${({ theme }) => theme.small}px;
`;

export const PostSubInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.opacityGray};

  & div {
    /* margin-right: 10px; */
  }

  & svg {
    fill: ${({ fill }) => fill};
    stroke: ${({ stroke }) => stroke};

    path {
      fill: ${({ fill }) => fill};
    }
  }
`;

export const PostSubInfoText = styled(BaseText)`
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.mediumGray};
`;

export const PostTime = styled(Title)`
  font-size: 0.85rem;
  width: ${({ width }) => (width ? width : 'auto')};
  color: ${({ theme }) => theme.spoxioText};
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-top: 20px;

  & svg {
    height: 18px;
    width: 18px;
  }
`;

export const MainActions = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    margin-right: 20px;
  }
`;

export const Description = styled(BlackText)`
  display: block;
  padding: 15px 20px 0;
  font-size: 1rem;
`;

export const SliderContainer = styled.div`
  width: 100%;
  height: 120px;
  position: relative;
  padding: 0 30px;
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UserName = styled.div`
  margin-top: 10px;
  max-width: 90px;
  text-align: center;
`;

export const NextBtn = styled.div`
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 10px;
  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const BeforeBtn = styled.div`
  position: absolute;
  cursor: pointer;
  top: 30px;
  left: 10px;
  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const FlagIcon = styled(Flag)`
  margin-left: auto;
`;

export const PostStats = styled(BaseText)`
  margin-left: 20px;
  margin-top: 15px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.spoxioText};
  opacity: 0.7;
  font-weight: 500;
`;
