import React from 'react';
import PropTypes from 'prop-types';
import { ModalBackdrop, ModalProfile } from './components';
import ModalPortal from './ModalPortal';
import ProfileModalContent from 'Components/OtherProfile/ProfileModalContent';

const ProfileModal = ({ visible, profile, setVisible }) => {
  return (
    <>
      {visible && (
        <ModalPortal>
          <ModalProfile>
            <ProfileModalContent setVisible={setVisible} visible={visible} profile={profile} />
          </ModalProfile>
        </ModalPortal>
      )}
    </>
  );
};

ProfileModal.propTypes = {
  visible: PropTypes.bool,
  profile: PropTypes.shape({}),
  setVisible: PropTypes.func
};

export default ProfileModal;
