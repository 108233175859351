import React, { useContext } from 'react';
import PropTypes, { bool, string } from 'prop-types';
import { ReactComponent as World } from '../../../Assets/icons/world.svg';
import { ReactComponent as Grouped } from '../../../Assets/icons/physicalevent.svg';
import { TypeEventCard } from './components';
import { AboutTags, IconContainerBg } from 'Components/OtherProfile/components';
import { ReactComponent as CheckboxCheckedIcon } from 'Assets/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'Assets/icons/CheckboxUnchecked.svg';
import {
  ContentContainer,
  IconContainer,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';

const TypeEvent = ({ active, setActive, virtual }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      {virtual ? (
        <TypeEventCard
          onClick={() => {
            setActive('virtual');
          }}
        >
          {active === 'virtual' && (
            <IconContainer
              width="20px"
              height="20px"
              style={{ position: 'absolute', top: '10px', right: '0px' }}
            >
              <CheckboxCheckedIcon />
            </IconContainer>
          )}

          <ContentContainer column gap="20px" width="100%">
            <ContentContainer
              width="20px"
              height="20px"
              alignItems="center"
              justifyContent="center"
            >
              <IconContainer
                background={theme?.spoxioIcon3}
                padding="8px"
                width="20px"
                height="20px"
              >
                <World />
              </IconContainer>
            </ContentContainer>

            <Title fontSize="1rem" color={theme?.spoxioText}>
              Virtual Event
            </Title>
            <SubTitle fontSize="0.875rem" opacity="0.45" style={{ lineHeight: '1.2rem' }}>
              Broadcast live to your audience with spoxio-live or add an external link
            </SubTitle>
          </ContentContainer>
        </TypeEventCard>
      ) : (
        <TypeEventCard
          onClick={() => {
            setActive('physical');
          }}
        >
          {!virtual && active === 'physical' && (
            <IconContainer
              width="20px"
              height="20px"
              style={{ position: 'absolute', top: '10px', right: '0px' }}
            >
              <CheckboxCheckedIcon />
            </IconContainer>
          )}
          <ContentContainer column gap="20px" width="100%" alignItems="space-between">
            <ContentContainer
              width="20px"
              height="20px"
              alignItems="center"
              justifyContent="center"
            >
              <IconContainer
                background={theme?.spoxioIcon3}
                padding="8px"
                width="20px"
                height="20px"
              >
                <Grouped />
              </IconContainer>
            </ContentContainer>

            <Title fontSize="1rem" color={theme?.spoxioText}>
              Physical Event
            </Title>
            <SubTitle fontSize="0.875rem" opacity="0.45" style={{ lineHeight: '1.2rem' }}>
              Your event is physical and does not require a livestream
            </SubTitle>
          </ContentContainer>
        </TypeEventCard>
      )}
    </>
  );
};

TypeEvent.propTypes = {
  active: string,
  setActive: PropTypes.func,
  virtual: bool
};

export default TypeEvent;
