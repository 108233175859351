import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { CreateEventButton, CreateEventContainer, EventContainerDetail } from './components';
import CreateEvent from '../../../Assets/icons/create-event.svg';
import { AboutTags } from 'Components/OtherProfile/components';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
const EventsRight = ({ setStep, hideCreateImage }) => {
  return (
    <CreateEventContainer>
      <ContentContainer column gap="10px" width="100%" alignItems="center">
        {!hideCreateImage ? <img width={'240px'} src={CreateEvent} alt="" /> : null}
        <SubTitle fontSize="1.5rem">Create an event</SubTitle>
        <Title fontSize="0.75rem" lineHeight="normal">
          Make Moment that lasts
        </Title>
      </ContentContainer>
      {/* Event Button */}
      <CreateEventButton
        onClick={() => {
          setStep(2);
        }}
      >
        Create an Event
      </CreateEventButton>
    </CreateEventContainer>
  );
};

EventsRight.propTypes = {
  setStep: PropTypes.func,
  hideCreateImage: bool
};

EventsRight.defaultPropTypes = {
  hideCreateImage: false
};

export default EventsRight;
