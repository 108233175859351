import React, { useContext, useState } from 'react';
import PropTypes, { arrayOf, func, object } from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormContainer } from 'Components/PostModal/components';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
import { Loader } from 'Components/shared/Loader';
import { ContentContainer, SpoxioBlueBtn, Title } from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { Row, TwoRow } from 'Routes/EditProfile/components';
import { addClubStats, fetchAllClubs } from 'Store/clubSlice';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { requiredRule } from 'Utils/validations';

const AddTeamStat = ({ selectedClubStat, clubInfo, closeModal, reset }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { data } = useSelector(getProfileSelector);
  const [isLoading, setIsloading] = useState(false);

  const { register, control, errors, handleSubmit } = useForm();

  const handleChange = (data) => {
    const payload = {
      category: selectedClubStat.value,
      value: `${data.value}${data.unit.value === '%' ? '%' : ''}`
    };
    handleSubmitStat(payload);
  };

  const handleSubmitStat = async (payload) => {
    setIsloading(true);
    const response = await dispatch(addClubStats(clubInfo._id, payload));
    if (response) {
      fetchAllClubs();
      reset({ category: '' });
      closeModal();
      setIsloading(false);
      return;
    }
    setIsloading(false);
  };

  return (
    <>
      {isLoading && <Loader size={24} background="rgba(0, 0, 0, 0.5)" />}
      <ContentContainer column width="100%" height="100%" padding="10px 0 0">
        <Title margin="30px 0 10px">{selectedClubStat?.label}</Title>

        <ContentContainer maxHeight="90vh" height="100%" width="100%" overflowY="auto" hideScroll>
          <FormContainer onSubmit={handleSubmit(handleChange)}>
            <TwoRow width="100%" margin="20px 0 0">
              <Row width="55%">
                <FormInput
                  ref={register(requiredRule)}
                  name="value"
                  placeholder={t('Enter stats')}
                  className="margin-bottom_medium"
                  type="number"
                  error={errors?.value?.message}
                />
              </Row>
              <Row width="40%" style={{ position: 'relative' }}>
                <FormSelect
                  control={control}
                  rules={{
                    required: 'Please, select a unit'
                  }}
                  ref={register(requiredRule)}
                  name="unit"
                  options={[
                    { label: t('Percentage'), value: '%' },
                    { label: t('Value'), value: '' }
                  ]}
                  placeholder={'Unit'}
                  isSearchable
                  error={errors?.unit?.message}
                  isFixed={false}
                  background={theme?.spoxioCardBg}
                />
              </Row>
            </TwoRow>
            <ContentContainer width="100%" margin="auto 0 0">
              <SpoxioBlueBtn type="submit" maxWidth="320px">
                Save
              </SpoxioBlueBtn>
            </ContentContainer>
          </FormContainer>
        </ContentContainer>
      </ContentContainer>
    </>
  );
};

AddTeamStat.propTypes = {
  clubRoles: PropTypes.arrayOf(PropTypes.object),
  selectedClubStat: object,
  handleChange: func,
  closeModal: func,
  clubInfo: object,
  reset: func
};

AddTeamStat.defaultPropTypes = {
  handleChange: () => {},
  closeModal: () => {},
  reset: () => {}
};

export default AddTeamStat;
