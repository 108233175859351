// use-fetch-data.js
import { useEffect, useState } from 'react';
import { func } from 'prop-types';

const useFetch = (queryFn) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await queryFn();
        setData(data);
        setError(undefined);
      } catch (error) {
        console.log('\n 🚀 ~ file: useFetch.js:15 ~ fetchData ~ error:', error);
        setError(error);
        setData(undefined);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    data,
    loading,
    error
  };
};

export default useFetch;

useFetch.propTypes = {
  queryFn: func
};

useFetch.defaultProps = {
  queryFn: () => {}
};
