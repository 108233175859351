import { useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { paths } from 'Utils/constants';
import { ListItem } from './components';
import { getSportPopularSelector } from 'Store/selectors/dataSelector';
import { fetchPopularSports } from 'Store/dataSlice';
import { setSearch } from 'Store/selectors/discoverySelector';

export const CategoryList = () => {
  const sports = useSelector(getSportPopularSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchPopularSports());
  }, []);

  const openDiscovery = (sport) => {
    if (!sport?.name) {
      dispatch(push(paths.DISCOVERY));
    } else {
      const search = { type: 'sport', data: sport };

      dispatch(setSearch(search));
      dispatch(push(`${paths.DISCOVERY}?sport=${sport.tag}`));
    }
  };

  return (
    <>
      {sports.map((sport, index) =>
        index < 6 ? (
          <ListItem key={sport._id} onClick={() => openDiscovery(sport)}>
            {sport.name}
          </ListItem>
        ) : null
      )}
      <ListItem onClick={openDiscovery}>{t('see_all')}</ListItem>
    </>
  );
};
