import styled from 'styled-components';

import { BaseText } from '../../styledComponents';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : '100%')};
`;

export const ImageInput = styled.input.attrs(() => ({
  type: 'file',
  accept: 'image/*'
}))`
  position: absolute;
  width: 100%;
  height: 100px;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`;

export const HelperText = styled(BaseText)`
  text-align: ${({ textAlign }) => textAlign};
  width: ${({ width }) => (width ? width : 'auto')};
  max-width: ${({ maxWidth }) => maxWidth};
  color: ${({ theme }) => theme.spoxioText};
`;

export const HiddenInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
`;

export const EditIconContainer = styled.div`
  height: ${({ height }) => (height ? height : '27px')};
  width: ${({ width }) => (width ? width : '27px')};
  position: absolute;
  right: 0px;
  bottom: 0;
  display: flex;
  cursor: pointer;

  & svg path {
    fill: ${({ color, theme }) => theme[color]};
  }
`;
