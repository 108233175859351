// import styled from 'styled-components';
// import { BaseText } from 'Components/shared/styledComponents';

// export const LeftBarContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   width: 100%;
//   padding: 20px;
//   border: 1px solid rgba(196, 196, 196, 0.5);
//   border-radius: 30px;
//   background-color: ${({ theme }) => theme.spoxioBg};
// `;

// export const ListItem = styled(BaseText)`
//   padding: 3px 0;
//   color: ${({ theme }) => theme.opacityGray};
//   cursor: pointer;
// `;

// export const DotsContainer = styled.div`
//   height: auto;
//   width: auto;
//   display: flex;
//   flex-direction: row;
//   gap: 4px;
// `;

// export const Dots = styled.span`
//   height: ${({ height }) => (height ? height : 4)}px;
//   width: ${({ selected }) => (selected ? 16 : 10)}px;
//   background: ${({ selected, theme }) => (selected ? theme.spoxioText : theme.spoxioGray)};
//   border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 15 + '%')};
//   opacity: ${({ selected }) => (selected ? 1 : 0.4)};
// `;

// export const NavigationContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-top: 15px;

//   & > a {
//     color: ${({ theme }) => theme.opacityGray};
//     padding: 5px 0;
//     font-family: SF Pro;
//     font-size: ${({ theme }) => theme.large}px;
//   }
//   & > span {
//     color: ${({ theme }) => theme.opacityGray};
//     padding: 5px 0;
//     font-family: SF Pro;
//     font-size: ${({ theme }) => theme.large}px;
//   }
// `;

// export const StoreContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   margin-top: auto;

//   & > a {
//     margin-bottom: 5px;
//   }
// `;

// export const StoreImage = styled.img`
//   width: 90px;
//   height: 28px;
//   opacity: 0.5;
//   cursor: pointer;
// `;

// export const OnboardingContainer = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   height: 100%;
//   overflow: hidden;
//   position: relative;
// `;

// export const Title = styled(BaseText)`
//   color: ${({ theme }) => theme.spoxioText};
//   font-size: ${({ theme }) => (theme ? theme.xLarge2 : 22)}px;
// `;

// export const SubTitle = styled(BaseText)`
//   color: ${({ theme }) => theme.spoxioText};
//   font-size: ${({ theme }) => (theme ? theme.medium : 22)}px;
//   opacity: 0.6;
// `;

// export const ImageContainer = styled.div`
//   /* width: '100%';
//   height: '100%';
//   overflow-y: 'auto';
//   overflow-x: 'hidden'; */
//   height: 100%;
//   width: 100%;
//   overflow-x: hidden;
//   overflow-y: auto;
//   border-bottom-left-radius: 5px;
//   border-bottom-right-radius: 5px;

//   ::-webkit-scrollbar {
//     display: none;
//   }
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// `;

// export const BottomOverlay = styled.div`
//   height: 20vh;
//   width: 100%;
//   background: linear-gradient(180.44deg, rgba(16, 16, 16, 0) 66.54%, #101010 99.89%);
//   background-blend-mode: overlay;
//   mix-blend-mode: normal;
//   position: absolute;
//   bottom: 0;
//   border-radius: 0 5px 5px;
// `;

// export const SideNavContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
//   height: 100%;
//   overflow-x: hidden;
//   overflow-y: auto;
// `;

// export const NavText = styled(BaseText)`
//   color: ${({ theme }) => theme?.spoxioFaintWhite};
//   font-size: ${({ theme }) => theme?.medium2};
//   margin-left: 10px;
// `;

// export const LogoutText = styled(NavText)`
//   color: ${({ theme }) => theme?.spoxioRed};
//   font-family: 'SF Pro';
// `;

import styled from 'styled-components';
import { BaseText } from 'Components/shared/styledComponents';

export const LeftBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.borderShade}; //rgba(196, 196, 196, 0.5);
  border-radius: 30px;
  background-color: ${({ theme }) => theme.spoxioBg};
`;

export const ListItem = styled(BaseText)`
  padding: ${({ padding }) => padding || '5px 14px'};
  color: ${({ theme }) => theme.spoxioText};
  cursor: pointer;
  background-color: ${({ theme }) => theme.borderShade};
  border-radius: 8px;
  width: ${({ width }) => width || 'auto'};
`;

export const DotsContainer = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const Dots = styled.span`
  height: ${({ height }) => (height ? height : 4)}px;
  width: ${({ selected }) => (selected ? 16 : 10)}px;
  background: ${({ selected, theme }) => (selected ? theme.spoxioText : theme.spoxioGray)};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 15 + '%')};
  opacity: ${({ selected }) => (selected ? 1 : 0.4)};
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  & > a {
    color: ${({ theme }) => theme.opacityGray};
    padding: 5px 0;
    font-family: SF Pro;
    font-size: ${({ theme }) => theme.large}px;
  }
  & > span {
    color: ${({ theme }) => theme.opacityGray};
    padding: 5px 0;
    font-family: SF Pro;
    font-size: ${({ theme }) => theme.large}px;
  }
`;

export const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;

  & > a {
    margin-bottom: 5px;
  }
`;

export const StoreImage = styled.img`
  width: 90px;
  height: 28px;
  opacity: 0.5;
  cursor: pointer;
`;

export const OnboardingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const Title = styled(BaseText)`
  color: ${({ theme }) => theme.spoxioText};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.4rem')};
`;

export const SubTitle = styled(BaseText)`
  color: ${({ theme }) => theme.spoxioText};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.875rem')};
  opacity: 0.6;
`;

export const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const BottomOverlay = styled.div`
  max-height: 35vh;
  height: 100%;
  width: 100%;
  background: linear-gradient(180.44deg, rgba(16, 16, 16, 0) 66.54%, #101010 99.89%);
  background-blend-mode: overlay;
  mix-blend-mode: normal;
  position: absolute;
  bottom: 0;
  border-radius: 0 5px 5px;
`;

export const SideNavContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  gap: 0px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  .ant-menu .ant-menu-vertical {
    border: none !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SpoxioMenu = styled.div`
  background: transparent;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  row-gap: 1.325rem; //1.75rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const SpoxioMenuItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  cursor: pointer;
  justify-content: ${({ center }) => (center ? center : 'start')};
  padding: 8px 12px;
  background-color: ${({ active, theme }) => (active ? theme.darkGray2 : 'transparent')};
  border-radius: 8px;
  font-weight: 500;

  &:hover {
    opacity: 0.9;
    background-color: ${({ theme }) => theme.darkGray2};
  }
`;
// inside the menu item
export const SpoxioMenuText = styled.div`
  font-size: ${({ f }) => (f ? f : '1rem')};
  width: ${({ w }) => w};
  height: ${({ h }) => h};
  color: ${({ active, theme }) => (active ? theme?.black : theme.opacityGray)};
  /* color: ${({ c, theme }) => (c ? theme.spoxioAltBlue : '')}; */

  svg {
    fill: ${({ active, theme }) => (active ? theme.opacityGray : theme.mediumGray)} !important;
    width: 22px;
    stroke: '#fff';

    path {
      fill: ${({ active, theme }) => (active ? theme.black : theme.mediumGray)} !important;
    }
  }
`;
export const NavText = styled(BaseText)`
  color: ${({ theme }) => theme?.black};
  font-size: ${({ theme }) => theme?.medium2};
  margin-left: 10px;
`;

export const LogoutText = styled(NavText)`
  color: ${({ theme }) => theme?.spoxioRed};
  font-family: 'SF Pro';
`;
