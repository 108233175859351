import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { getIsLoading } from 'Store/loaderSlice';
import { passwordRules, requiredRule } from 'Utils/validations';
import { FollowSportsContainer, SubmitBtn, Input } from '../ProfileRevamp/components';
import { Loader } from 'Components/shared/Loader';
import { changePassword } from 'Store/authSlice';
import { useTranslation } from 'react-i18next';

export const ChangePassword = () => {
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, errors, watch, handleSubmit } = useForm();

  const onSubmit = async ({ password, newPassword }) => {
    dispatch(changePassword({ oldPassword: password, newPassword }));
  };

  const newPassword = watch('newPassword');

  return (
    <FollowSportsContainer>
      {isLoading && <Loader size={40} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          ref={register(requiredRule)}
          name="password"
          type="password"
          error={errors?.password?.message}
          placeholder={t('old_pass')}
        />
        <Input
          ref={register(passwordRules)}
          name="newPassword"
          type="password"
          error={errors?.newPassword?.message}
          placeholder={t('new_pass')}
        />
        <Input
          ref={register({
            validate: (value) => value === newPassword || `${t('password_dont_match')}`
          })}
          name="confirmPassword"
          type="password"
          error={errors?.confirmPassword?.message}
          placeholder={t('confirm_pass')}
        />
        <SubmitBtn>{t('save_changes')}</SubmitBtn>
      </form>
    </FollowSportsContainer>
  );
};
