import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as LogOut } from 'Assets/icons/logout-nav.svg';
import { ReactComponent as Home } from 'Assets/icons/Home.svg';
import { ReactComponent as Discover } from 'Assets/icons/Discover.svg';
import { ReactComponent as Event } from 'Assets/icons/Event.svg';
import { ReactComponent as Chat } from 'Assets/icons/ChatIcon.svg';
import { ReactComponent as Scout } from 'Assets/icons/ScoutIcon.svg';
import { ReactComponent as Notification } from 'Assets/icons/NotificationIcon.svg';
import { ReactComponent as More } from 'Assets/icons/MoreIcon.svg';
import { ReactComponent as Add } from 'Assets/icons/AddIcon.svg';
import { ReactComponent as Blog } from 'Assets/icons/blog.svg';
import { paths } from 'Utils/constants';

import { useHistory, useLocation } from 'react-router-dom';
import { getCurrentUser, getCurrentUserId, logout, logoutUser } from 'Store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { isScout } from 'Utils';
import { ContentContainer, SpoxioBlueBtn } from 'Components/shared/styledComponents';
import LanguageSwitcher from 'Components/LanguageSwitcher/LanguageSwitcher';
import { ToggleTheme } from 'Components/shared/ToggleTheme';
import { ThemeContext } from 'Context/themeContext';
import { AppLinkNav } from 'Routes/MobileDetectRoute/components';
import { PostModal } from 'Components/PostModal';
import {
  SideNavContainer,
  SpoxioMenu,
  SpoxioMenuItem,
  SpoxioMenuText
} from '../LeftBar/components';
import { UserMenu } from './UserMenu';
import Auth from 'middleware/storage';

const SpoxioActionBar = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { theme } = useContext(ThemeContext);
  const History = useHistory();
  const dipatch = useDispatch(logoutUser);
  const user = useSelector(getCurrentUser);
  const currentUserId = useSelector(getCurrentUserId);
  const profile = Auth?.getUser()

  // console.log(profile?.userType, 'profile user type');

  // console.log('profile\n', profile);

  // // direct to post screen
  // const postRoute = () => {
  //   History.push('/new');
  // };

  // logout user
  const logoutHandler = () => {
    // dipatch(logoutUser());
    dipatch(logout());
  };
  const location = useLocation();
  const getIcon = (name) => {
    const icons = {
      Home: Home,
      Discover: Discover,
      Event: Event,
      Chat: Chat,
      Scout: Scout,
      Notification: Notification,
      Blog: Blog,
      More: More,
      Add: Add,
      Logout: LogOut
    };

    return icons[name];
  };

  const navList = [
    {
      path: paths.TIMELINE,
      name: 'Home'
    },
    {
      path: paths.DISCOVERY,
      name: 'Discover'
    },
    {
      path: paths.EVENTS,
      name: 'Event'
    },
    {
      path: paths.CHAT,
      name: 'Chat'
    },
    {
      path: paths.SCOUT_ACTIVITIES,
      name: 'Scout'
    },
    {
      path: paths.NOTIFICATION,
      name: 'Notification'
    }
    // {
    //   path: 'http://blog.spoxio.com',
    //   name: 'Blog'
    // },
    // {
    //   path: `${paths.PROFILE}/${userId}`,
    //   name: 'More'
    // }
  ];
  // push router
  const pushLink = (link) => {
    History.push(link);
  };

  const openModal = () => setModalIsOpen(true);

  const closeModal = () => setModalIsOpen(false);

  return (
    <SideNavContainer
      // flexDirection="row"
      style={{
        overflowX: 'scroll',
        overflowY: 'hidden',
        gap: '4px',
        justifyContent: 'center',
        padding: '0 1.75rem'
      }}
    >
      <SpoxioMenu
        style={{
          border: 'none',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        {navList?.map((nav, i) => {
          const Icon = getIcon(nav.name);
          const active = nav.path?.toLowerCase()?.includes(location.pathname);

          // Disable scout activities role navigation
          if (
            (profile?.userType === 'fan' || profile?.userType === 'club') &&
            nav.name?.toLowerCase() === 'scout'
          ) {
            return null;
          }

          // if (nav.name === 'Blog') {
          //   return (
          //     <AppLinkNav href="http://blog.spoxio.com" style={{ width: '100%' }} key={nav.name}>
          //       <SpoxioMenuItem active={active}>
          //         <SpoxioMenuText active={active}>
          //           <Icon />
          //         </SpoxioMenuText>
          //         {/* <SpoxioMenuText active={active}>{nav.name}</SpoxioMenuText> */}
          //       </SpoxioMenuItem>
          //     </AppLinkNav>
          //   );
          // }

          if (nav.name === 'Scout') {
            return (
              <SpoxioMenuItem
                key={nav.name}
                onClick={() => pushLink(nav.name === 'Home' ? '/' : nav.path)}
                active={active}
              >
                <SpoxioMenuText active={active}>
                  <Icon />
                </SpoxioMenuText>
                {/* <SpoxioMenuText active={active}>{nav.name} Activities</SpoxioMenuText> */}
                {/* {!tog && <SpoxioMenuText>{nav.name}</SpoxioMenuText>} */}
              </SpoxioMenuItem>
            );
          }

          return (
            <SpoxioMenuItem
              key={nav.name}
              onClick={() => pushLink(nav.name === 'Home' ? '/' : nav.path)}
              active={active}
            >
              <SpoxioMenuText active={active}>
                <Icon />
              </SpoxioMenuText>
              {/* <SpoxioMenuText active={active}>{nav.name}</SpoxioMenuText> */}
              {/* {!tog && <SpoxioMenuText>{nav.name}</SpoxioMenuText>} */}
            </SpoxioMenuItem>
          );
        })}

        <SpoxioMenuItem center={'center'}>
          <SpoxioBlueBtn
            marginTop="0"
            padding="0.6rem 1.5rem"
            // onClick={postRoute}
            onClick={openModal}
            // width={'266px'}
          >
            Post
          </SpoxioBlueBtn>
        </SpoxioMenuItem>

        {/* <SpoxioMenuItem>
          <Add />
          <SpoxioMenuText f={'16px'} c={true}>
            Add Account
          </SpoxioMenuText>
        </SpoxioMenuItem> */}

        {/* <SpoxioMenuItem>
          <ToggleTheme />
           <LanguageSwitcher />
        </SpoxioMenuItem> */}

        {/* <ContentContainer
          row
          gap="20px"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <div
            onClick={() => logoutHandler()}
            style={{
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
              alignItems: 'center'
            }}
          >
            <LogOut width="16px" height="16px" fontWeight="700" />
            <LogoutText>Log Out</LogoutText>
          </div>{' '}
          <div style={{ color: '#828490', fontWeight: '600' }}>Spoxio v1.8.0</div>
        </ContentContainer> */}
        <UserMenu user={{ ...user, id: currentUserId }} style={{ marginLeft: '-10px' }} />
      </SpoxioMenu>
      <PostModal closeModal={closeModal} isOpen={modalIsOpen} />
    </SideNavContainer>
  );
};

export default SpoxioActionBar;
