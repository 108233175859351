import { func, string } from 'prop-types';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { paths } from 'Utils/constants';
import { getCurrentUser, getCurrentUserId, getIsAuthorizedSelector } from 'Store/authSlice';
import { PostBtn, ActionContainer, IconContainer, NotAuthorizedButton } from './components';
import { Notifications } from './Notifications';
import { UserMenu } from './UserMenu';
import { ReactComponent as Stats } from 'Assets/icons/ScoutIcon.svg';
import { ReactComponent as Message } from 'Assets/icons/ChatIcon.svg';
// import { ReactComponent as Message } from 'Assets/icons/Message.svg';
// import { ReactComponent as Stats } from 'Assets/icons/Stats.svg';
import { useTranslation } from 'react-i18next';
import { AppLinkNav } from 'Routes/MobileDetectRoute/components';
import { ToggleTheme } from 'Components/shared/ToggleTheme';
import { useContext } from 'react';
import { ThemeContext } from 'Context/themeContext';

export const ActionsBar = ({ size, openModal }) => {
  const { themeMode, toggleTheme, theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const user = useSelector(getCurrentUser);
  const currentUserId = useSelector(getCurrentUserId);
  const isAuthorized = useSelector(getIsAuthorizedSelector);
  const pathname = useSelector((state) => state?.router?.location?.pathname);
  const dispatch = useDispatch();

  const goToChat = () => dispatch(push(paths.CHAT));

  const goToSignIn = () => dispatch(push(paths.SIGN_IN));

  const goToSignUp = () => dispatch(push(paths.SIGN_UP));

  const goToStats = () => dispatch(push(paths.SCOUT_ACTIVITIES));

  return (
    <ActionContainer size={size}>
      {!isAuthorized ? (
        <>
          {' '}
          <ToggleTheme themeMode={themeMode} toggleTheme={toggleTheme} />
          <NotAuthorizedButton onClick={goToSignUp}>{t('get_started')}</NotAuthorizedButton>
          <NotAuthorizedButton variant="transparent" onClick={goToSignIn}>
            {t('sign_in')}
          </NotAuthorizedButton>
        </>
      ) : (
        <>
          <AppLinkNav href="http://blog.spoxio.com">{t('blog')}</AppLinkNav>
          {user?.userType === 'scout' ? (
            <IconContainer onClick={goToStats}>
              <Stats
                title={t('stats')}
                color={pathname === paths.STATS ? theme.black : theme.darkGray1}
              />
            </IconContainer>
          ) : (
            openModal && <PostBtn onClick={openModal}>{t('post')}</PostBtn>
          )}
          <ToggleTheme themeMode={themeMode} toggleTheme={toggleTheme} />
          <IconContainer
            onClick={goToChat}
            fill={pathname === paths.CHAT ? theme.black : theme.mediumGray}
          >
            <Message title={t('chat')} />
          </IconContainer>
          <Notifications />
          <UserMenu user={{ ...user, id: currentUserId }} />
        </>
      )}
    </ActionContainer>
  );
};

ActionsBar.propTypes = {
  size: string,
  openModal: func
};

ActionsBar.defaultProps = {
  size: '310px',
  openModal: null
};
