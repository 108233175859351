import { shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { isScout } from 'Utils';
import { followOnSport } from 'Store/authSlice';
import { getIsLoading } from 'Store/loaderSlice';
import { PostsList } from 'Components/PostsList';
import { Tabs } from 'Components/shared/Tabs';
import { ProfileHead } from './ProfileHead';
import { TabContainer, LabelPosts, ProfileContainer } from './components';
import { EmptyPage } from '../Profile/EmptyPage';
import { Loader } from 'Components/shared/Loader';
import { useTranslation } from 'react-i18next';
import ProfileModal from 'Components/Modals/ProfileModal';
import { useState } from 'react';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { theme } from 'Theme';
import { PostMediaThumbnail } from 'Components/shared/Post/PostMediaThumbnail';
import { SportThumbnail } from 'Components/SportTrending/component';
import { v4 as uuidv4 } from 'uuid';
import { fetchSinglePost } from 'Store/postSlice';
import isEmpty from 'Utils/isEmpty';

export const Profile = ({ profile, tabName }) => {
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { posts, data, isCurrentUser, isBlocked } = profile;

  const [visible, setVisible] = useState(false);
  const selectPost = (data) => {
    dispatch(fetchSinglePost(data._id));
    dispatch(push(`${paths.TIMELINE}/${data._id}`, { id: data._id }));
  };
  return (
    <ProfileContainer>
      {isLoading ? (
        <Loader size={24} />
      ) : (
        <>
          <ProfileHead setVisible={setVisible} profile={profile} />
          <ContentContainer>
            <LabelPosts>{`${t('post')}`}</LabelPosts>
          </ContentContainer>

          {!isBlocked ? (
            <>
              {posts < 1 ? (
                <EmptyContent
                  showIcon={false}
                  maxWidth="auto"
                  description={
                    <ContentContainer
                      column
                      width="100%"
                      alignItems="center"
                      justifyContent="center">
                      <Title fontWeight="bold" color={theme.black} textAlign="center">
                        Not feeling sporty
                      </Title>
                      <SubTitle textAlign="center" fontSize="1rem">
                        You don't have any post yet. Upload photos and videos of yourself to get
                        your profile up and sporty.
                      </SubTitle>
                    </ContentContainer>
                  }
                />
              ) : null}

              {!isEmpty(posts) ? (
                <ContentContainer row flexWrap="wrap" width="100%" gap="25px">
                  {posts?.map((post) => {
                    return (
                      <SportThumbnail
                        maxWidth="180px"
                        height="180px"
                        key={uuidv4()}
                        onClick={() => selectPost(post)}>
                        <PostMediaThumbnail height="100%" width="100%" mediaList={post.contents} />
                      </SportThumbnail>
                    );
                  })}
                </ContentContainer>
              ) : null}
            </>
          ) : (
            <EmptyContent
              showIcon={false}
              maxWidth="auto"
              description={
                <ContentContainer column width="100%" alignItems="center" justifyContent="center">
                  <Title fontWeight="bold" color={theme.black} textAlign="center">
                    Profile Restricted
                  </Title>
                  <SubTitle textAlign="center" fontSize="1rem">
                    You may not have the privileges to view this profile content.
                  </SubTitle>
                </ContentContainer>
              }
            />
          )}

          <ProfileModal setVisible={setVisible} visible={visible} profile={profile} />
        </>
      )}
    </ProfileContainer>
  );
};

Profile.propTypes = {
  profile: shape({}),
  tabName: string
};

Profile.defaultProps = {
  profile: {},
  tabName: ''
};
