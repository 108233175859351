import React from 'react';
import PropTypes from 'prop-types';
import { AboutTags, ButtonsTag, DetailsPage, DetailsTag } from './components';
import ScoutInfo from './ScoutInfo';
import AboutProfile from './AboutProfile';

const SectionTags = ({ role }) => {
  return (
    <>
      {role === 'scout' && (
        <>
          <ButtonsTag>
            {/* Conditional based on props */}
            <DetailsTag m={'30px'}>About</DetailsTag>
            <DetailsTag m={'30px'}>Scout</DetailsTag>
          </ButtonsTag>
          {/* About */}
          {/* Scout */}
          <ScoutInfo />
        </>
      )}
      {role === 'user' && (
        <>
          <DetailsPage>
            <DetailsTag>
              <button
                style={{
                  background: 'none',
                  border: 'none'
                }}
              >
                About
              </button>
            </DetailsTag>
            <AboutProfile />
          </DetailsPage>
        </>
      )}
    </>
  );
};

SectionTags.propTypes = {
  role: PropTypes.string
};
export default SectionTags;
