import { string } from 'prop-types';

import { ReactComponent as Posts } from 'Assets/icons/Posts.svg';
import { useTranslation } from 'react-i18next';
import { EmptyContainer } from '../ProfileRevamp/components';

export const EmptyPage = ({ userName }) => {
  const { t } = useTranslation();
  return (
    <EmptyContainer>
      <Posts viewBox="0 0 24 24" />
      {`${t('when')} ${userName} ${t('will_appear_here')}`}
    </EmptyContainer>
  );
};

EmptyPage.propTypes = {
  userName: string
};

EmptyPage.defaultProps = {
  userName: 'user'
};
