import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';

import { RouteProps } from 'Utils/propTypes';
import { paths } from 'Utils/constants';
import { getScoutAccess, isScout, stringToPrice } from 'Utils';
import { createSession, getPrices } from 'Api/services';
import { getCurrentUser, getCurrentUserId, logoutUser } from 'Store/authSlice';
import { getIsLoading, setLoading } from 'Store/loaderSlice';
import { Loader } from 'Components/shared/Loader';
import { BaseButton, BlackText, CenteredContainer } from 'Components/shared/styledComponents';
import { AuthContainer } from 'Components/AuthContainer';
import {
  AuthFormContainer,
  CapabilityDescription,
  CapabilityItem,
  PaymentButton,
  ButtonDescription,
  LogoutButton
} from '../components';

import { ReactComponent as ImageIcon } from 'Assets/icons/Image.svg';
import { ReactComponent as MessageIcon } from 'Assets/icons/Message.svg';
import { ReactComponent as StatsIcon } from 'Assets/icons/Stats.svg';
import { ReactComponent as VerifiedIcon } from 'Assets/icons/Verified.svg';

import { colors } from 'Theme';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'Components/Layouts/AuthLayout';

export const SignUpPaymentsPage = ({ history, location }) => {
  const [paymentIsDone, setPaymentIsDone] = useState(false);
  const [prices, setPrices] = useState({});
  const { t } = useTranslation();
  const currentUserId = useSelector(getCurrentUserId);
  const currentUser = useSelector(getCurrentUser);
  const { query } = useSelector((state) => state?.router.location);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();

  const stripe = useStripe();

  const isTrial = !currentUser.expiryDate;

  useEffect(() => {
    if (!isScout(currentUser?.userType)) {
      history.replace(paths.TIMELINE);
    }

    const getAvailablePrices = async () => {
      dispatch(setLoading(true));

      const availablePrices = await getPrices();

      setPrices(availablePrices);
      dispatch(setLoading(false));
    };

    getAvailablePrices();
  }, []);

  useEffect(() => {
    if (query?.payment_status === 'success' || getScoutAccess(currentUser)) {
      setPaymentIsDone(true);
    }

    if (query?.payment_status === 'failed') {
      setPaymentIsDone(false);
      toast.error(t('payment_failed'));
    }
  }, [query]);

  const onStartClick = () => {
    if (isMobile) {
      history.push(paths.MOBILE_SCREEN);
    } else {
      history.push(paths.TIMELINE);
    }
  };

  const onPaymentButtonClick = async (e, price) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const session = await createSession(currentUserId, currentUser, price, isTrial);

    dispatch(setLoading(false));

    const result = await stripe.redirectToCheckout({ sessionId: session.id });

    if (result?.error) {
      toast.error(result.error.message);
    }
  };

  const onLogoutClick = () => {
    dispatch(logoutUser());
  };

  return (
    <AuthLayout
      title={`${
        paymentIsDone ? t('scout_profile_set') : !isTrial ? t('choose_sub_plan') : t('choose_trial')
      }`}
    >
      <LogoutButton variant="transparent" fontWeight={700} onClick={onLogoutClick}>
        {t('logout')}
      </LogoutButton>
      <AuthFormContainer>
        <CenteredContainer className="margin-top_small-v2">
          <CapabilityItem>
            <ImageIcon color={colors.primaryBlue} width={30} />
            <CapabilityDescription>{t('see_videos_and_photos_talent')} </CapabilityDescription>
          </CapabilityItem>
          <CapabilityItem>
            <StatsIcon color={colors.primaryBlue} width={30} />
            <CapabilityDescription>{t('access_exclusive_stats')}</CapabilityDescription>
          </CapabilityItem>
          <CapabilityItem>
            <MessageIcon color={colors.primaryBlue} width={30} />
            <CapabilityDescription>{t('contact_directly_or_bulk')}</CapabilityDescription>
          </CapabilityItem>
        </CenteredContainer>
        <CenteredContainer className="margin-top_medium">
          {isLoading && <Loader size={24} />}
          {paymentIsDone ? (
            <>
              <CapabilityItem>
                <VerifiedIcon width={27} height={27} />
                <CapabilityDescription color={colors.green}>
                  {t('remember_to_follow')}
                  <b>{t('verified_acct_text')}!</b>
                </CapabilityDescription>
              </CapabilityItem>
              <BaseButton className="margin-top_small-v2" onClick={onStartClick}>
                {t('start_using_spoxio')}
              </BaseButton>
            </>
          ) : (
            <>
              {prices?.month ? (
                <>
                  <PaymentButton onClick={(e) => onPaymentButtonClick(e, prices?.month)}>
                    {`${stringToPrice(prices?.month?.amount)} / ${t('month')}`}
                  </PaymentButton>
                  <ButtonDescription className="margin-bottom_small">
                    {t('charged_monthly')}
                  </ButtonDescription>
                  <PaymentButton onClick={(e) => onPaymentButtonClick(e, prices?.year)}>
                    {`${stringToPrice(prices?.year?.amount)} / ${t('year')}`}
                  </PaymentButton>
                  <ButtonDescription>{t('charged_annually')}</ButtonDescription>
                </>
              ) : null}
              {isTrial && (
                <ButtonDescription className="margin-top_medium margin-bottom_small">
                  {t('dont_worry')} <BlackText>{t('you_wont_be_charged_now')}</BlackText>{' '}
                  {t('notify_you_before_trial_ends')}
                </ButtonDescription>
              )}
            </>
          )}
        </CenteredContainer>
      </AuthFormContainer>
    </AuthLayout>
  );
};

SignUpPaymentsPage.propTypes = RouteProps;
