import React from 'react';
import { InvitationsPage } from 'Components/Invitations';
import ClubInviteLayout from 'Components/Layouts/ClubInviteLayout/ClubInviteLayout';
import useFetch from 'Hooks/useFetch';
import { fetchClubByID } from 'Store/clubSlice';
import { Loader } from 'Components/shared/Loader';
import isEmpty from 'Utils/isEmpty';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { useLocation } from 'react-router';

export const Invitations = () => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const { data, loading, error } = useFetch(fetchClubByID(query.get('clubProfileId')));

  return (
    <ClubInviteLayout>
      {loading ? <Loader size={24} height="100%" /> : null}{' '}
      {!loading && isEmpty(error) ? <InvitationsPage clubData={data} /> : null}
      {!loading && !isEmpty(error) ? (
        <EmptyContent
          maxWidth="100%"
          description={error?.response?.data?.message}
          title="Something went wrong somewhere"
        />
      ) : null}
    </ClubInviteLayout>
  );
};
