import { Bin } from 'Components/Layouts/MainNavigation/NavBar/components';
import SpoxioModal from 'Components/Modals/SpoxioModal';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { Loader } from 'Components/shared/Loader';
import { PostImage } from 'Components/shared/Post/components';
import { PostMediaThumbnail } from 'Components/shared/Post/PostMediaThumbnail';
import {
  ContentContainer,
  IconContainer,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { SportThumbnail } from 'Components/SportTrending/component';
import { ThemeContext } from 'Context/themeContext';
import Api from 'middleware/api';
import { shape } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserId } from 'Store/authSlice';
import { deleteTalentPhoto, fetchTalentGallery } from 'Store/profileSlice';
import { getTalentPhotoList } from 'Store/selectors/profileSelector';
import Swal from 'sweetalert2';
import { userRoles } from 'Utils/constants';
import isEmpty from 'Utils/isEmpty';
import Club from '../Club/Index';
import { ButtonsTag, DetailsPage, DetailsTag } from '../components';
import PlayerInfo from '../Player/PlayerInfo';
import PlayerKit from '../Player/PlayerKit';
import Records from '../Player/Records';

const TalentInfoPage = ({ about }) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(getCurrentUserId);
  const talentPhotoList = useSelector(getTalentPhotoList);
  const { theme } = useContext(ThemeContext);
  const [clubData, setClubData] = useState({});
  const [loading, setLoading] = useState(false);
  const [photoVisible, setPhotoVisible] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState({});
  const [tag, setTag] = useState(1);

  const handleViewPhoto = (data) => {
    setSelectedPhoto(data);
    setPhotoVisible(!photoVisible);
  };

  // console.log(
  //   about,
  //   `\n<---- about `,
  //   talentPhotoList,
  //   `\ntalentPhotos ----->`,
  //   // talentPhotos,
  //   ` ------ Talent Club Member Data --------- \n`,
  //   clubData,
  //   `\n`,
  //   about?.clubMemberProfile?.clubProfile
  // );

  useEffect(() => {
    fetchTalentPhotos();
  }, []);

  const fetchTalentPhotos = async () => {
    setLoading(true);
    if (about?.userType === 'talent') {
      let resp = await dispatch(fetchTalentGallery(about?.talentProfile?._id));
      setLoading(false);
    } else {
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    // console.log(
    //   '\n\rurl: ',
    //   talentPhotoList?.map((d) => d.url),
    //   '\n\r',
    //   talentPhotoList,
    //   'photos'
    // );
  }, [talentPhotoList]);

  useEffect(async () => {
    if (isEmpty(about.clubMemberProfile)) return;
    getUserById();
  }, [about?.clubMemberProfile?.clubProfile]);

  const getUserById = () => {
    Api.user
      .userDetailById(about?.clubMemberProfile?.clubProfile)
      .then((res) => {
        setClubData(res.data.data);
        return res.data.data;
      })
      .catch((errors) => {
        return {};
      });
  };

  const deleteGalleryPhoto = async (id) => {
    if (about?.userType === userRoles.TALENT && about?._id === currentUserId) {
      let resp = await dispatch(deleteTalentPhoto(id));
      if (resp) {
        setPhotoVisible(false);
        await dispatch(fetchTalentGallery(currentUserId));
      }
    } else {
      return;
    }
  };

  const confirmDelete = (data) => {
    Swal.fire({
      title: `Delete Photo?`,
      text: `You are about to delete this photo`,
      showCancelButton: true,
      confirmButtonColor: theme?.spoxioAltBlue,
      cancelButtonColor: theme?.spoxioRed,
      confirmButtonText: 'Proceed',
      reverseButtons: true,
      imageUrl: data?.url,
      imageWidth: 200,
      imageHeight: 150,
      imageAlt: ''
    }).then((result) => {
      if (result.isConfirmed) {
        deleteGalleryPhoto(data?._id);
      }
    });
  };

  return (
    <>
      <DetailsPage
      // maxWidth="25vw"
      >
        <ButtonsTag>
          <DetailsTag
            active={tag === 1}
            onClick={() => {
              setTag(1);
            }}>
            About
          </DetailsTag>
          <DetailsTag
            active={tag === 2}
            onClick={() => {
              setTag(2);
            }}>
            Stats
          </DetailsTag>
          {about?.userType === userRoles.TALENT ? (
            <DetailsTag
              active={tag === 3}
              onClick={() => {
                setTag(3);
              }}>
              Photos
            </DetailsTag>
          ) : null}

          {/* {!isEmpty(about?.clubMemberProfile) && (
            <DetailsTag
              active={tag === 3}
              onClick={() => {
                setTag(3);
              }}
            >
              Club
            </DetailsTag>
          )} */}
        </ButtonsTag>

        {/* Players Info page */}
        {tag === 1 && (
          <>
            <PlayerInfo about={about} />
            {/* {!isEmpty(about?.clubMemberProfile) && <PlayerKit about={about} />} */}
          </>
        )}
        {tag === 2 && (
          <>
            {!isEmpty(about?.clubMemberProfile) ? (
              <Records about={about} />
            ) : (
              <EmptyContent
                showIcon={false}
                maxWidth="auto"
                description={
                  <ContentContainer
                    column
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    // margin="20px 0"
                  >
                    {/* <Title fontWeight="bold" color={theme.black} textAlign="center">
                      Free Agent
                    </Title> */}
                    <SubTitle width="100%" textAlign="center" fontSize="1rem">
                      No stats available
                    </SubTitle>
                  </ContentContainer>
                }
              />
            )}
          </>
        )}

        {tag === 3 && (
          <>
            {loading ? (
              <ContentContainer height="15vh" justifyContent="center" alignItems="center">
                <Loader background="transparent" size={24} />
              </ContentContainer>
            ) : !isEmpty(talentPhotoList) ? (
              <ContentContainer row flexWrap="wrap" width="100%" gap="10px" margin="10px 0 0">
                {talentPhotoList?.map((photo, index) => {
                  // console.log('the photo', photo);
                  return (
                    <SportThumbnail
                      hover
                      opacity="0.85"
                      maxWidth="95px"
                      key={index.toString()}
                      onClick={() => {
                        handleViewPhoto(photo);
                      }}>
                      <PostImage
                        src={photo.url}
                        height="95px"
                        effect="blur"
                        alt=""
                        borderRadius="8px"
                        style={{ objectFit: 'cover' }}
                        
                      />
                    </SportThumbnail>
                  );
                })}

                <SpoxioModal
                  showCloseButton
                  visible={photoVisible}
                  width="100%"
                  height="100%"
                  minWidth="85vw"
                  maxWidth="85vw"
                  borderRadius="0px"
                  setVisible={() => setPhotoVisible(false)}
                  minHeight="100vh"
                  maxHeight="100vh">
                  <ContentContainer column width="100%" height="100%" margin="auto" padding="10px">
                    {about?.userType === userRoles.TALENT && about?._id === currentUserId ? (
                      <IconContainer
                        hover
                        opacity="0.85"
                        height="20px"
                        width="20px"
                        fill={theme?.spoxioRed}
                        style={{ position: 'absolute', top: '-32px' }}
                        onClick={() => confirmDelete(selectedPhoto)}>
                        <Bin title="Delete" />
                      </IconContainer>
                    ) : null}
                    <PostImage
                      src={selectedPhoto?.url}
                      alt=""
                      borderRadius="8px"
                      style={{ height: '100%', maxHeight: '90vh' }}
                    />
                  </ContentContainer>
                </SpoxioModal>
              </ContentContainer>
            ) : (
              <EmptyContent
                showIcon={false}
                maxWidth="auto"
                description={
                  <ContentContainer column width="100%" alignItems="center" justifyContent="center">
                    <Title
                      fontWeight="bold"
                      fontSize="0.875rem"
                      color={theme.black}
                      textAlign="center">
                      No photos available
                    </Title>
                    <SubTitle width="100%" textAlign="center" fontSize="0.75rem">
                      Upload photos to gallery from your profile.
                    </SubTitle>
                  </ContentContainer>
                }
              />
            )}
          </>
        )}

        {/* {tag === 3 && (
          <>
            {!isEmpty(clubData) ? (
              <Club about={clubData} />
            ) : (
              <EmptyContent
                showIcon={false}
                maxWidth="auto"
                description={
                  <ContentContainer column width="100%" alignItems="center" justifyContent="center">
                    <Title fontWeight="bold" color={theme.black} textAlign="center">
                      Syncing
                    </Title>
                    <SubTitle width="100%" textAlign="center" fontSize="1rem">
                      Club details are currently unavailable...
                    </SubTitle>
                  </ContentContainer>
                }
              />
            )}
          </>
        )} */}
      </DetailsPage>
    </>
  );
};

TalentInfoPage.propTypes = {
  about: shape({})
};

export default TalentInfoPage;
