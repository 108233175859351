// import styled from 'styled-components';

// export const DiscoveryContainer = styled.div`
//   width: 100%;
//   max-width: 600px;
// `;

// export const MostPopularContainer = styled.div`
//   width: 48%;
//   border: 1px solid ${({ theme }) => theme.lightBlue};
//   padding: 20px;
//   margin-bottom: 10px;
//   border-radius: 20px;
// `;

// export const IconContainer = styled.div`
//   & svg path {
//     fill: ${({ color, theme }) => theme[color]};
//   }
// `;

// export const Popular = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   cursor: pointer;
// `;

// export const Title = styled.div`
//   margin-bottom: 40px;
//   margin-top: 40px;
//   text-align: center;
//   font-weight: 600;
//   font-size: 18px;
// `;

// export const SecondTitle = styled.div`
//   margin-bottom: 20px;
//   margin-top: 20px;
//   font-weight: 600;
// `;

// export const SearchItemContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   padding: 5px 0 5px;
//   border-bottom: 1px solid ${({ theme }) => theme.lightGray1};
//   cursor: pointer;
// `;

// export const PostsCount = styled.div`
//   color: ${({ theme }) => theme.opacityGray};
//   font-size: 12px;
// `;

import { SubTitle } from 'Components/Layouts/AuthLayout/components';
import styled from 'styled-components';

export const DiscoveryContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`;

export const MostPopularContainer = styled.div`
  width: 100%;
`;

export const IconContainer = styled.div`
  & svg path {
    fill: ${({ color, theme }) => theme[color]};
  }
`;
// Discover Buttons
export const Popular = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: max-content;
  max-width: 100%;
  column-gap: 5px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
    border: none;
  }

  .skeleton-wrap {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;

export const PopularSportContainer = styled.div`
  display: flex;
  flex-wrap: ${({ flexWrap }) => flexWrap || 'wrap'};
  cursor: pointer;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || ' 100%'};
  gap: 12px;
  column-gap: ${({ gap }) => gap};
  /* width: max-content; */
  /* overflow-x: scroll; */
  &::-webkit-scrollbar {
    display: none;
    border: none;
  }
`;

export const Title = styled.div`
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
`;

export const SecondTitle = styled(SubTitle)`
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 700;
  font-size: 1.75rem;
  font-family: SF Pro Bold;
`;

export const SearchItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px 5px;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.darkGray2};
  margin-bottom: 8px;
  border-radius: 10px;
  min-height: 45px;
  max-height: 50px;
`;

export const PostsCount = styled.div`
  color: ${({ theme }) => theme.opacityGray};
  font-size: 12px;
  display: flex;
  width: auto;
  column-gap: 4px;
  align-items: center;
  opacity: 0.8;
`;

export const DiscoveryButtonsContainer = styled.div`
  display: flex;
  column-gap: 30px;
  margin: 15px 0;
`;
export const DiscoverButton = styled.div`
  width: 130px;
  height: 43px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.darkGray2};
  cursor: pointer;
  text-align: center;
  border: ${({ type, index }) => (type === index + 1 ? '1px solid #007AFF' : 'none')};
  color: ${({ theme }) => theme.white};
  &:hover {
    border: 1px solid #007aff;
  }
`;
