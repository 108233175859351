import { array } from 'prop-types';
import { TrendingBarContainer } from './components';
import { ReactComponent as LocationSvg } from 'Assets/icons/Location.svg';
import {
  ContentContainer,
  IconContainer,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import isEmpty from 'Utils/isEmpty';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { ThemeContext } from 'Context/themeContext';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsEventLoading } from 'Store/loaderSlice';
import { Loader } from 'Components/shared/Loader';
import { formatDate } from 'Utils/dates';
import { v4 as uuidv4 } from 'uuid';
import {
  ClubEventsMain,
  ClubEventTime,
  ClubLeftSide,
  EventsTime
} from 'Components/OtherProfile/Club/components';
import EventsPics from 'Assets/img/event-calendar.png';
import fallbackSrc from 'Assets/img/event-placeholder4.jpeg';
// import fallbackSrc from 'Assets/img/club-event.png';
import { push } from 'connected-react-router';
import { paths } from 'Utils/constants';

const TrendingEventsBar = ({ trendingEvents }) => {
  const { theme, themeMode } = useContext(ThemeContext);
  const isLoading = useSelector(getIsEventLoading);
  const dispatch = useDispatch();

  const viewEventDetails = (event) => {
    dispatch(push({ pathname: `${paths.EVENTS}/${event?._id}`, state: { event } }));
  };
  return (
    <TrendingBarContainer>
      <div className="overflow_buffer">
        {isLoading ? (
          <ContentContainer minHeight="60vh" borderRadius="12px">
            <Loader />
          </ContentContainer>
        ) : isEmpty(trendingEvents) ? (
          <EmptyContent
            showIcon={false}
            maxWidth="auto"
            description={
              <ContentContainer column width="100%" alignItems="center" justifyContent="center">
                <Title fontWeight="bold" color={theme.black} textAlign="center">
                  No Events Available
                </Title>
                <SubTitle textAlign="center" fontSize="1rem">
                  There are no upcoming events available on this space. Please check back later...
                  Thanks.
                </SubTitle>
              </ContentContainer>
            }
          />
        ) : (
          <ContentContainer hideScroll row gap="20px" width="100%" style={{ overflowX: 'auto' }}>
            {trendingEvents?.map((_event, _idx) => {
              return (
                // <TrendingCardContainer>
                //   <TrendingCardHeader>
                //     <div className="trending_event_details">
                //       <p className="trending_event_date">{formatDate(_event?.event_date)}</p>
                //       <h4 className="trending_event_title">{_event?.title}</h4>
                //       <span className="location_pin">
                //         <img src={LocationSvg} alt="" />
                //         <span className="trending_event_location">{_event.venue}</span>
                //       </span>
                //       {_event.isLive ? <></> : <></>}
                //     </div>
                //     <img
                //       className="trending_event_image"
                //       src={ClubImg || _event?.eventImgSrc}
                //       alt={`${_event?.title} cover`}
                //     />
                //   </TrendingCardHeader>
                //   <TrendingCardFooter>
                //     <EventSpace>
                //       <img src={SpaceImg} alt={'Event Pics'} />
                //     </EventSpace>
                //     <Attending>{_event.attendeeCount} Attending</Attending>
                //   </TrendingCardFooter>
                // </TrendingCardContainer>
                <ContentContainer
                  borderRadius="12px"
                  column
                  width="100%"
                  height="fit-content"
                  border={theme?.spoxioIcon2}
                  key={uuidv4()}
                  minWidth="250px"
                  onClick={() => viewEventDetails(_event)}
                >
                  <ClubEventsMain maxWidth="400px" gap="0">
                    <ClubLeftSide
                      themeMode={themeMode}
                      height="36vh"
                      width="200px"
                      justifyContent="space-around"
                    >
                      <EventsTime>{formatDate(_event?.event_date, 'DD MMM')}</EventsTime>
                      <ContentContainer column width="100%" margin="10px 0">
                        <Title
                          width="100%"
                          fontSize="1rem"
                          fontWeight="bold"
                          lineHeight="1.25rem"
                          fontFamily="SF Pro"
                        >
                          {_event?.title}
                        </Title>
                        <ContentContainer row width="100%" alignItems="center">
                          <IconContainer
                            width="12px"
                            height="10px"
                            fill={theme?.mediumGray}
                            margin="0"
                          >
                            <LocationSvg />
                          </IconContainer>

                          <SubTitle width="auto" fontSize="0.55rem">
                            {_event.venue}
                          </SubTitle>
                        </ContentContainer>
                      </ContentContainer>
                      {/* <ClubEventTime live width="55%" lineHeight="normal">
                        LIVE NOW
                      </ClubEventTime> */}
                    </ClubLeftSide>
                    <ContentContainer width="200px" padding="0">
                      {!isEmpty(_event?.photos) ? (
                        <img
                          src={_event?.photos[0]}
                          alt={''}
                          width="100%"
                          height="100%"
                          style={{
                            // background: theme.black,
                            objectFit: 'cover',
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px'
                          }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = fallbackSrc;
                            currentTarget.style.objectFit = 'cover';
                          }}
                        />
                      ) : (
                        <img
                          src={EventsPics}
                          alt={''}
                          width="100%"
                          height="100%"
                          style={{
                            // background: theme.black,
                            objectFit: 'cover',
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px'
                          }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = fallbackSrc;
                            currentTarget.style.objectFit = 'fill';
                          }}
                        />
                      )}
                    </ContentContainer>
                  </ClubEventsMain>
                  {/* <ContentContainer
                    row
                    flexWrap="wrap"
                    justifyContent="space-between"
                    padding="0px 20px 10px"
                  >
                    <SubTitle fontSize="0.75rem">36 attending</SubTitle>
                  </ContentContainer> */}
                </ContentContainer>
              );
            })}
          </ContentContainer>
        )}
      </div>
    </TrendingBarContainer>
  );
};

TrendingEventsBar.defaultProps = {
  trendingEvents: []
};

TrendingEventsBar.propTypes = {
  trendingEvents: array.isRequired
};

export default TrendingEventsBar;
