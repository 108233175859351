import { RoleItem } from 'Components/RoleItem';
import { BlackText } from 'Components/shared/styledComponents';

import talent from 'Assets/img/talent-role.png';
import fan from 'Assets/img/fan-role.png';
import scout from 'Assets/img/scout-role.png';
import club from 'Assets/img/club-role.png';

import { paths, userRoles } from 'Utils/constants';
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import { fanProfile } from 'Store/authSlice';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'Components/Layouts/AuthLayout';
import { RoleBgOverlay, RoleContainer } from '../components';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';
import styled from 'styled-components';
import { SubTitle, Title } from 'Components/Layouts/AuthLayout/components';

const RoleWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  position: relative;
`;

export const SignUpRolePage = () => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const userRole = localStorage.getItem('type');
  const { t } = useTranslation();
  if (userRole !== userRoles.DEFAULT) {
    return <Redirect to={paths.TIMELINE} />;
  }

  const onChoseFan = async () => {
    const res = await dispatch(fanProfile());
    if (res) {
      localStorage.setItem('type', userRoles.FAN);
      dispatch(push(paths.SIGN_UP_SPORTS));
    }
  };

  const onChoseRole = (role) => {
    role === userRoles.SCOUT
      ? localStorage.setItem('type', userRoles.SCOUT)
      : role === userRoles.CLUB
      ? localStorage.setItem('type', userRoles.CLUB)
      : localStorage.setItem('type', userRoles.TALENT);
    dispatch(push(paths.SIGN_UP_ENHANCE_INFO));
  };

  return (
    <AuthLayout
      showBackButton
      title={
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Title fontSize="2rem" fontWeight="bold">
            Account created!
          </Title>
          <SubTitle fontSize="1.75rem" fontWeight="bold">
            let’s personalize it
          </SubTitle>{' '}
        </div>
      }
    >
      <BlackText className="margin-bottom_medium margin-top_medium">
        {t('option_fits_you')}
      </BlackText>
      <RoleWrapper>
        <RoleContainer>
          <RoleItem
            Icon={talent}
            role={userRoles.TALENT}
            title={t('i_talent')}
            description={t('i_talent_msg')}
            color={theme?.yellow}
            onClick={onChoseRole}
          />
          <RoleItem
            Icon={scout}
            role={userRoles.SCOUT}
            title={t('i_scout')}
            description={t('Discover and contact sport talents across different sports.')}
            color={theme?.lightBlue}
            onClick={onChoseRole}
          />
        </RoleContainer>
        <RoleContainer>
          <RoleItem
            Icon={fan}
            role={userRoles.FAN}
            title={t('i_fan')}
            description={t('Follow and support sports professionals you love.')}
            color={theme?.lightGreen}
            onClick={onChoseFan}
          />
          <RoleItem
            Icon={club}
            role={userRoles.CLUB}
            title={t('I am a club')}
            description={t('Manage and share latest update on your team.')}
            color={theme?.spoxioPurple}
            onClick={onChoseRole}
          />
        </RoleContainer>
        <RoleBgOverlay />
      </RoleWrapper>
    </AuthLayout>
  );
};
