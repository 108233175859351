/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Auth from 'middleware/storage';
import firebase from 'firebase/app';
import 'firebase/storage';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
import {
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getCurrentUser } from 'Store/authSlice';
import {
  addClubStatCategory,
  deleteClubStatCategory,
  deleteClubStats,
  fetchAllClubs,
  fetchClubStatCategory
} from 'Store/clubSlice';
import { requiredRule } from 'Utils/validations';
import { ReactComponent as PlusIcon } from 'Assets/icons/AddIcon.svg';
import delIcon from 'Assets/img/close.png';
import { Loader } from 'Components/shared/Loader';
import { getClub, getClubStatCategories } from 'Store/selectors/clubSelector';
import { CloseIconX } from 'Components/PostModal/components';
import Swal from 'sweetalert2';
import SpoxioModal from 'Components/Modals/SpoxioModal';
import isEmpty from 'Utils/isEmpty';
import {
  RecordsContainer,
  RecordsTextAns,
  RecordsTextTag
} from 'Components/OtherProfile/Player/components';
import AddTeamStat from './AddTeamStat';

const EditClubStats = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const profile = useSelector(getCurrentUser);
  const clubstat = useSelector(getClubStatCategories);
  const [addStatLoading, setAddStatLoading] = useState(false);
  const [clubStatsLoading, setClubStatsLoading] = useState(false);
  const [addTeamStatModal, setAddTeamStatModal] = useState(false);
  const [delStatLoading, setDelStatLoading] = useState(false);
  const [delTeamStatLoading, setDelTeamStatLoading] = useState(false);

  const [selectedStatId, setSelectedStatId] = useState('');
  const [selectedTeamStatId, setSelectedTeamStatId] = useState('');
  const [selectedClubStat, setSelectedClubStat] = useState({});

  const club = useSelector(getClub);
  const clubInfo = profile?.clubProfile;
  const [clubStats, setClubStats] = useState(clubInfo?.statistics || []);

  // console.log('\n\r🚀 ~ file: EditClubStats.js:82 ~ EditClubStats ~ club:');
  // console.log('\n\rCLUB--->', club);
  // console.log('\n\n\r MY CLUB INFO------>', clubInfo);
  // console.log('\n\n\r THE USER PROFILE', profile);
  // console.log('🚀 ~ file: EditClubStats.js:79 ~ EditClubStats ~ clubStats:', clubStats);

  const fetchStatCategory = async () => {
    setClubStatsLoading(true);
    await dispatch(fetchClubStatCategory(clubInfo?._id, 'club'));
    setClubStatsLoading(false);
  };

  const clubCategory = club.clubStatCategories.map((item) => ({
    label: item.name,
    value: item._id
  }));

  useEffect(() => {
    fetchStatCategory();
    return () => {
      fetchStatCategory();
    };
  }, [clubInfo?._id, dispatch]);

  const removeClubStat = async (statId) => {
    setDelStatLoading(true);
    setSelectedStatId(statId);
    const response = await dispatch(deleteClubStatCategory(clubInfo._id, statId));
    if (response) {
      dispatch(fetchClubStatCategory(clubInfo?._id, 'club'));
    }
    setSelectedStatId('');
    setDelStatLoading(false);
  };

  const confirmDelete = (data) => {
    Swal.fire({
      title: `Delete Statistics Category?`,
      text: `You are about to delete the category - ${(data?.label).toString().toUpperCase()}`,
      showCancelButton: true,
      confirmButtonColor: theme?.spoxioAltBlue,
      cancelButtonColor: theme?.spoxioRed,
      reverseButtons: true,
      imageUrl: delIcon,
      imageWidth: '5em',
      imageHeight: '5em',
      imageAlt: ''
    }).then((result) => {
      if (result.isConfirmed) {
        removeClubStat(data?.value);
      }
    });
  };

  const deleteClubStatWithValue = async (statId) => {
    setDelTeamStatLoading(true);
    setSelectedTeamStatId(statId);
    const response = await dispatch(deleteClubStats(clubInfo._id, statId));
    if (response) {
      const newStat = clubStats.filter((value) => value?._id !== statId);
      setClubStats(newStat);
      // console.log('🚀 ~ file: EditClubStats.js:136 ~ deleteClubStatWithValue ~ newStat:', newStat);
    }
    setSelectedTeamStatId('');
    setDelTeamStatLoading(true);
  };

  const confirmTeamStatDelete = (data) => {
    // console.log('🚀 ~ file: EditClubStats.js:143 ~ confirmTeamStatDelete ~ data:', data);
    Swal.fire({
      title: `Delete Club Stat?`,
      text: `You are about to delete the stat - ${data?.category?.name}`,
      showCancelButton: true,
      confirmButtonColor: theme?.spoxioAltBlue,
      cancelButtonColor: theme?.spoxioRed,
      reverseButtons: true,
      imageUrl: delIcon,
      imageWidth: '5em',
      imageHeight: '5em',
      imageAlt: ''
    }).then((result) => {
      if (result.isConfirmed) {
        deleteClubStatWithValue(data?._id);
      }
    });
  };

  useEffect(() => {
    Auth.fetchUser();
    dispatch(fetchAllClubs());

    return () => {
      Auth.fetchUser();
      fetchAllClubs();
    };
  }, [clubInfo?._id, dispatch]);

  const { register, reset, errors, handleSubmit } = useForm();

  const submitStatCategory = async (value) => {
    setAddStatLoading(true);
    const data = { name: value?.name, tag: '', type: 'club' };
    const response = await dispatch(addClubStatCategory(clubInfo?._id, data));
    if (response) {
      fetchStatCategory();
      reset({ name: '' });
    }
    setAddStatLoading(false);
  };

  const handleSubmitStatCategory = (event) => {
    event.preventDefault();
    handleSubmit(async (data) => {
      submitStatCategory(data);
    })();
  };

  const {
    register: register2,
    reset: reset2,
    control,
    errors: errors2,
    handleSubmit: handleSubmit2
  } = useForm();

  const onClickSelectStat = (event) => {
    event.preventDefault();
    handleSubmit2(async (data) => {
      if (isEmpty(data.category)) {
        return;
      }
      setSelectedClubStat(data.category);
      setAddTeamStatModal(true);
    })();
  };

  return (
    <ContentContainer width="100%" gap="1rem">
      <ContentContainer background={theme?.background} padding="0.875rem" borderRadius="12px">
        <ContentContainer row gap="20px" width="100%" alignItems="center" margin="0 0 0.875rem">
          <FormInput
            ref={register(requiredRule)}
            name="name"
            rules={{
              required: 'Add statistics category'
            }}
            placeholder={t('Add statistics category')}
            error={errors?.name?.message}
            marginBottom="0"
          />
          <SpoxioBlueBtn
            disabled={addStatLoading}
            hover
            width="10%"
            marginTop="0"
            fontSize="1.2rem"
            background={theme?.spoxioLightBlue}
            padding="14px"
            height="100%"
            onClick={handleSubmitStatCategory}>
            {addStatLoading ? (
              <Loader size={20} background="transparent" padding="0" position="relative" />
            ) : (
              <IconContainer
                fill={theme?.spoxioBlue}
                height="16px"
                width="16px"
                margin="0"
                style={{ justifyContent: 'center' }}>
                <PlusIcon />
              </IconContainer>
            )}
          </SpoxioBlueBtn>
        </ContentContainer>
        <ContentContainer row gap="20px" flexWrap="wrap" alignItems="center">
          {clubStatsLoading ? (
            <ContentContainer row justifyContent="center" width="100%" margin="2% auto">
              <Loader size={20} background="transparent" padding="0" position="relative" />
            </ContentContainer>
          ) : (
            clubCategory &&
            clubCategory.map((stat) => (
              <ContentContainer
                hover
                key={stat._id}
                row
                gap="12px"
                padding="10px 8px"
                borderRadius="8px"
                background={theme?.spoxioCardBg}
                alignItems="center"
                cursor="pointer"
                minWidth="30px">
                {delStatLoading && selectedStatId === stat._id ? (
                  <Loader size={16} background="transparent" padding="0" position="relative" />
                ) : (
                  <>
                    <IconContainer
                      width="12px"
                      height="12px"
                      margin="0"
                      style={{ justifyContent: 'center' }}
                      onClick={() => confirmDelete(stat)}>
                      <CloseIconX name="close" color="#E4E6EB" size={18} />
                    </IconContainer>
                    <SubTitle fontSize="0.875rem" textTransform="uppercase">
                      {stat.label}
                    </SubTitle>
                  </>
                )}
              </ContentContainer>
            ))
          )}
        </ContentContainer>
      </ContentContainer>

      <ContentContainer borderRadius="8px">
        <ContentContainer row gap="20px" width="100%" margin="0 0 0.875rem">
          <FormSelect
            ref={register2(requiredRule)}
            control={control}
            rules={{
              required: 'Select a category'
            }}
            name="category"
            options={clubCategory || []}
            placeholder={'Category'}
            isSearchable
            error={errors2?.category?.message}
          />

          <SpoxioBlueBtn
            hover
            width="45%"
            marginTop="0"
            background={theme?.spoxioLightBlue}
            padding="10px"
            height="100%"
            maxHeight="50px"
            onClick={onClickSelectStat}>
            <Title fontSize="0.75rem" color={theme?.spoxioBlue}>
              Add new team statistics
            </Title>
          </SpoxioBlueBtn>
        </ContentContainer>
        <ContentContainer row gap="20px" flexWrap="wrap" alignItems="center">
          {clubStatsLoading ? (
            <ContentContainer row justifyContent="center" width="100%" margin="2% auto">
              <Loader size={20} background="transparent" padding="0" position="relative" />
            </ContentContainer>
          ) : (
            <>
              {!isEmpty(clubStats)
                ? clubStats?.map((data) => (
                    <ContentContainer column width="45%" key={data._id}>
                      {delTeamStatLoading && selectedTeamStatId === data._id ? (
                        <Loader
                          size={16}
                          background="transparent"
                          padding="0"
                          position="relative"
                        />
                      ) : (
                        <>
                          <IconContainer
                            hover
                            opacity="0.85"
                            width="16px"
                            height="12px"
                            margin="0"
                            padding="6px"
                            background="rgba(252, 87, 59, 0.5)" //"#0F0F0F"
                            borderRadius="6px"
                            style={{
                              justifyContent: 'flex-end',
                              position: 'absolute',
                              top: '10px',
                              right: '10px'
                            }}
                            onClick={() => confirmTeamStatDelete(data)}>
                            <CloseIconX name="close" size={18} />
                          </IconContainer>
                          <RecordsContainer>
                            <RecordsTextTag
                              title={data.data?.category?.name}
                              aria-label={data?.category?.name}>
                              {data?.category?.name}
                            </RecordsTextTag>
                            <RecordsTextAns>{data.value}</RecordsTextAns>
                          </RecordsContainer>
                        </>
                      )}
                    </ContentContainer>
                  ))
                : null}
            </>
          )}
        </ContentContainer>
      </ContentContainer>

      <SpoxioModal
        showCloseButton
        visible={addTeamStatModal}
        width="100%"
        height="100%"
        padding="25px"
        minWidth="40vw"
        maxWidth="40vw"
        borderRadius="12px"
        setVisible={() => setAddTeamStatModal(false)}
        minHeight="45vh"
        maxHeight="45vh"
        closeIconStyle={{ left: '10px', right: '0' }}>
        <AddTeamStat
          selectedClubStat={selectedClubStat}
          clubInfo={clubInfo}
          reset={reset2}
          closeModal={() => setAddTeamStatModal(false)}
        />
      </SpoxioModal>
    </ContentContainer>
  );
};

export default EditClubStats;
