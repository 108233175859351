import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOf, func, string } from 'prop-types';

import { ChoseSportsGrid } from './ChoseSportsGrid';
import { ChoseSportsContainer, SportsGroupTitle } from './components';
import { fetchPopularSports, fetchSports } from 'Store/dataSlice';
import { getSportPopularSelector, getSportSelector } from 'Store/selectors/dataSelector';
import { useTranslation } from 'react-i18next';

export const ChoseSports = ({ selectedSports, onSelect }) => {
  const sports = useSelector(getSportSelector);
  const sportPop = useSelector(getSportPopularSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!sports?.length) {
      dispatch(fetchPopularSports());
      dispatch(fetchSports());
    }

    return () => {
      fetchPopularSports();
      fetchSports();
    };
  }, []);

  return (
    <ChoseSportsContainer>
      <SportsGroupTitle>{t('most_popular')}</SportsGroupTitle>
      <ChoseSportsGrid
        flexWrap={'nowrap'}
        data={sportPop?.slice(0, 10)}
        selectedSports={selectedSports}
        onSelect={onSelect}
      />
      <SportsGroupTitle>{t('all_sport_and_activities')}</SportsGroupTitle>
      <div>
        <ChoseSportsGrid
          direction={'wrap'}
          data={sports}
          selectedSports={selectedSports}
          onSelect={onSelect}
        />
      </div>
    </ChoseSportsContainer>
  );
};

ChoseSports.propTypes = {
  selectedSports: arrayOf(string),
  onSelect: func
};

ChoseSports.defaultProps = {
  selectedSports: [],
  onSelect: () => {}
};
