import React, { useState } from 'react';
import AboutProfile from '../AboutProfile';
import PropTypes from 'prop-types';
import { ButtonsTag, DetailsPage, DetailsTag } from '../components';
import ScoutInfo from '../ScoutInfo';
import Player from './Player';
import Club from '../Club/Index';
import { TalentInfo } from 'Components/Scout/components';
import TalentInfoPage from '../Talent/TalentInfo';
import { useSelector } from 'react-redux';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { useTranslation } from 'react-i18next';
import ScoutProfileInfo from '../ScoutProfileInfo';

const Details = ({ role }) => {
  const { t } = useTranslation();
  const [scoutTag, setScoutTag] = useState(false);
  const profile = useSelector(getProfileSelector);
  const { data } = profile;
  return (
    <>
      {/* Fan Data */}
      {role === 'fan' && (
        <DetailsPage>
          <DetailsTag active>About</DetailsTag>
          <AboutProfile about={data} />
        </DetailsPage>
      )}

      {/* Scout*/}
      {role === 'scout' && (
        <>
          <ButtonsTag>
            <DetailsTag
              onClick={() => {
                setScoutTag((prev) => !prev);
              }}
              active={!scoutTag}
              m={'30px'}
            >
              About
            </DetailsTag>
            <DetailsTag
              onClick={() => {
                setScoutTag((prev) => !prev);
              }}
              active={scoutTag}
              m={'30px'}
            >
              Scout
            </DetailsTag>
          </ButtonsTag>

          {/* Scout About */}
          {!scoutTag && <ScoutProfileInfo about={data} />}
          {scoutTag && <ScoutInfo about={data} profile={profile} />}
        </>
      )}

      {/* Talent */}
      {role === 'talent' && <TalentInfoPage about={data} />}

      {/* Signed Talent / Active club member */}
      {role === 'club_member' && <TalentInfoPage about={data} />}

      {/* Club data */}
      {role === 'club' && <Club about={data} />}
    </>
  );
};
Details.propTypes = {
  role: PropTypes.string
};
export default Details;
