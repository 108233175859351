import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { getIsAuthorizedSelector } from 'Store/authSlice';
import { ClickableContainer, LogoContainer } from 'Components/shared/styledComponents';

import LogoSecondary from 'Assets/img/LogoSecondary.png';
import darklogo from 'Assets/img/darklogo.png';
import { useContext } from 'react';
import { ThemeContext } from 'Context/themeContext';
import { bool, func, string } from 'prop-types';

export const MainLogo = ({ marginBottom, onClick }) => {
  const { themeMode } = useContext(ThemeContext);
  const isAuthorized = useSelector(getIsAuthorizedSelector);
  const dispatch = useDispatch();

  const onClickLogo = () => {
    if (isAuthorized) {
      dispatch(push(paths.TIMELINE));
    } else {
      dispatch(push(paths.HOME));
    }
  };

  return (
    <LogoContainer marginBottom={marginBottom}>
      <ClickableContainer onClick={onClickLogo}>
        <img
          className="mobile-logo"
          src={themeMode === 'light' ? LogoSecondary : darklogo}
          alt="Spoxio"
          width="100%"
          height="100%"
        />
      </ClickableContainer>
    </LogoContainer>
  );
};

MainLogo.propTypes = {
  marginBottom: string,
  onClick: func
};

MainLogo.defaultProps = {
  onClick: () => {}
};
