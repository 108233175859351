import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { shape } from 'prop-types';
import React from 'react';
import { RecordsContainer, RecordsTextAns, RecordsTextTag } from './components';
import isEmpty from 'Utils/isEmpty';

const Records = ({ about }) => {
  // console.log('🚀 ~ file: Records.js:7 ~ Records ~ about:', about);

  return (
    <ContentContainer column gap="20px" margin="20px 0" width="100%">
      {!isEmpty(about?.clubMemberProfile) && (
        <>
          <SubTitle fontWeight="600">Record</SubTitle>
          <Title fontWeight="700" fontSize="0.875rem" lineHeight="normal">
            (Player Stats)
          </Title>
        </>
      )}
      {!isEmpty(about?.clubProfile) && (
        <SubTitle fontWeight="600" fontSize="1.15rem">
          All time record
        </SubTitle>
      )}

      {/* Records for the player */}
      <ContentContainer row gap="10px" flexWrap="wrap" width="100%">
        {!isEmpty(about?.clubMemberProfile)
          ? about?.clubMemberProfile?.statistics?.map((data) => (
              <ContentContainer column width="45%" key={data?._id}>
                <RecordsContainer>
                  <RecordsTextTag title={data?.category?.name} aria-label={data.title}>
                    {data.title}
                  </RecordsTextTag>
                  <RecordsTextAns>{data.score}</RecordsTextAns>
                </RecordsContainer>
              </ContentContainer>
            ))
          : null}

        {!isEmpty(about?.clubProfile)
          ? about?.clubProfile?.statistics?.map((data) => (
              <ContentContainer column width="45%" key={data._id}>
                <RecordsContainer>
                  <RecordsTextTag
                    title={data.data?.category?.name}
                    aria-label={data?.category?.name}
                  >
                    {data?.category?.name}
                  </RecordsTextTag>
                  <RecordsTextAns>{data.value}</RecordsTextAns>
                </RecordsContainer>
              </ContentContainer>
            ))
          : null}
      </ContentContainer>
    </ContentContainer>
  );
};

Records.propTypes = {
  about: shape({})
};

export default Records;
