// import { useTranslation } from 'react-i18next';
import { MASONRY_FIXTURES } from 'Utils/fixtures';
import {
  OnboardingContainer,
  Title,
  SubTitle,
  ImageContainer,
  DotsContainer,
  Dots,
  BottomOverlay
} from './components';
import Masonry from 'react-responsive-masonry';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Onboarding = () => {
  //   const { t } = useTranslation();

  return (
    <OnboardingContainer>
      <Title>
        Connect with <br />
        sport professionals
      </Title>

      <SubTitle>
        The best way to see your favourite <br /> sport athlete live in action with
        <br /> photos and videos.
      </SubTitle>

      <DotsContainer>
        <Dots selected /> <Dots /> <Dots />
      </DotsContainer>

      <ImageContainer>
        <Masonry columnsCount={2} gutter="10px">
          <LazyLoadImage
            src={MASONRY_FIXTURES.image1}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image2}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image3}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image4}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image5}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image6}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image7}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image8}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image9}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image10}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />
        </Masonry>
        <BottomOverlay />
      </ImageContainer>
    </OnboardingContainer>
  );
};
