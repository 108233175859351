import { any, func, string } from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../Theme';

import { RoleItemContainer, RoleTitleContainer, RoleTitle, RoleDescription } from './components';

const RoleImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  max-width: 200px;
  min-width: 100px;
`;

export const RoleItem = ({ Icon, title, role, color, description, onClick }) => {
  const onItemClick = () => {
    onClick(role);
  };

  return (
    <RoleItemContainer onClick={onItemClick}>
      <RoleImageContainer>
        <img src={Icon} width={'100%'} height={'100%'} color={color} alt={title} />
      </RoleImageContainer>
      <RoleTitleContainer>
        <RoleTitle color={color}>{title}</RoleTitle>
        <RoleDescription>{description}</RoleDescription>
      </RoleTitleContainer>
    </RoleItemContainer>
  );
};

RoleItem.propTypes = {
  Icon: any.isRequired,
  title: string.isRequired,
  onClick: func.isRequired,
  role: string.isRequired,
  description: string,
  color: string
};

RoleItem.defaultProps = {
  description: '',
  color: colors.black
};
