import { Redirect } from 'react-router';

import { paths, userRoles } from 'Utils/constants';
import { EnhanceClubProfilePage } from './EnhanceClubProfilePage';
import { EnhanceScoutProfilePage } from './EnhanceScoutProfilePage';
import { EnhanceTalentProfilePage } from './EnhanceTalentProfilePage';

export const EnhanceProfilePage = (props) => {
  const userRole = localStorage.getItem('type');
  if (userRole === userRoles.FAN) {
    return <Redirect to={paths.TIMELINE} />;
  }

  return userRole === userRoles.CLUB ? (
    <EnhanceClubProfilePage {...props} />
  ) : userRole === userRoles.SCOUT ? (
    <EnhanceScoutProfilePage {...props} />
  ) : (
    <EnhanceTalentProfilePage {...props} />
  );
};
