import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  posts: [],
  post: {},
  userPosts: [],
  myPosts: [],
  trends: [],
  metaData: {}
};

export const postSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    setPost: (state, action) => {
      state.post = action.payload;
    },
    setMyPosts: (state, action) => {
      state.myPosts = action.payload;
    },
    setTrends: (state, action) => {
      state.trends = action.payload;
    },
    setTimelineMetaData: (state, action) => {
      state.metaData = action.payload;
    }
  }
});

// actions
export const {
  setPosts,
  setPost,
  setUserPosts,
  setTrends,
  setMyPosts,
  setTimelineMetaData
} = postSlice.actions;

// reducer
export const postsReducer = postSlice.reducer;

// selectors
export const getPostsSelector = (state) => state?.posts?.posts;
export const getPostSelector = (state) => state?.posts?.post;
export const getUserPostsSelector = (state) => state?.posts?.userPosts;
export const getMyPostSelector = (state) => state?.posts?.myPosts;
export const getTrendsSelector = (state) => state?.posts?.trends;
export const getTimelineMetaData = (state) => state?.posts?.metaData;
