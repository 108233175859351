import { func, instanceOf, shape } from 'prop-types';

import 'react-day-picker/lib/style.css';
import { getYears } from 'Utils/dates';

const currentYear = new Date().getFullYear();
export const toMonth = new Date(currentYear, 0);
export const fromMonth = new Date(currentYear - 100, 11);

export const YearMonthForm = ({ date, maxDate, localeUtils, onChange, onInputFocus }) => {
  const months = localeUtils?.getMonths();
  const years = getYears(fromMonth.getFullYear(), maxDate?.getFullYear() || toMonth.getFullYear());

  const handleChange = (e) => {
    const { year, month } = e.target.form;

    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption">
      <select id="month" name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select id="year" name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

YearMonthForm.propTypes = {
  date: instanceOf(Date),
  localeUtils: shape({}),
  maxDate: instanceOf(Date),
  onChange: func,
  onInputFocus: func
};

YearMonthForm.defaultProps = {
  date: new Date(),
  localeUtils: {},
  maxDate: null,
  onChange: () => {},
  onInputFocus: () => {}
};
