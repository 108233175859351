import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Api from '../../Api/index';

import { paths } from 'Utils/constants';
import { UserPic } from 'Components/shared/UserPic';
import { ActionButton, ContentContainer, Title } from 'Components/shared/styledComponents';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';
import  Api2  from 'middleware/api';
import { getCurrentUserId } from 'Store/selectors/authSelector';
import { getAgeDifference } from 'Utils/dates';
import { getCountryFlag } from 'Utils';
import isEmpty from 'Utils/isEmpty';

export const ScoutedTalents = ({ user }) => {
  const { theme, themeMode } = useContext(ThemeContext);
  const [userData, setUserData] = useState({});
  const currentUserId = useSelector(getCurrentUserId);
  const [isFollow, setIsFollow] = useState(false);
  const fieldName = user.follower ? 'follower' : 'following';
  const { t } = useTranslation();
  const dispatch = useDispatch();

  console.log(
    '\n <===== the userData profile =====>\n',
    user,
    '\n',
    user?.user
    // user[fieldName]?._id
    //  userData
  );

  const getUserById = () => {
    Api2.user
      .userDetailById(user?.user?._id)
      .then((res) => {
        const { isFollowedByMe } = res.data.data;
        setUserData(res.data.data);
        setIsFollow(isFollowedByMe);
        return res.data.data;
      })
      .catch((errors) => {
        return {};
      });
  };

  useEffect(async () => {
    getUserById();
  }, [user]);

  const onUserClick = () => dispatch(push(`${paths.PROFILE}/${user?.user?._id}`));

  const goToChat = (groupId) => dispatch(push(`${paths.CHAT}?chat_id=${groupId}`));
  const onPlusClick = () => {
    Api.messages.create([currentUserId, user?.user?._id], goToChat);
  };

  return (
    <ContentContainer
      row
      gap="12px"
      background={themeMode === 'light' ? theme.background : theme.spoxioIcon2}
      borderRadius="10px"
      padding="6px"
      width="100%"
      justifyContent="space-between"
    >
      <ContentContainer row gap="5px" cursor="pointer" onClick={onUserClick}>
        <UserPic src={user?.user?.picture} size={55} />
        <ContentContainer column gap="0">
          <Title
            fontSize="1rem"
            textOverflow="ellipsis"
            maxWidth="5vw"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            {`${user?.user?.firstName} ${user?.user?.lastName || ''}`}
          </Title>

          <ContentContainer row alignItems="center" flexWrap="wrap" minWidth="80px">
            <Title opacity="0.5" lineHeight="normal" fontSize="0.75rem">
              {getAgeDifference(user?.dateOfBirth)}| Origin:
            </Title>
            <Title
              opacity="0.8"
              lineHeight="normal"
              fontSize="0.65rem"
              maxWidth="7vw"
              textOverflow="ellipsis"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            >
              {user?.user?.country?.name}
            </Title>
            <span>
              {!isEmpty(user?.user?.country?.iso2) && getCountryFlag(user?.user?.country?.iso2)}
            </span>
          </ContentContainer>
        </ContentContainer>
      </ContentContainer>

      <ActionButton
        minWidth="5vw"
        maxWidth="5vw"
        color={theme.defaultWhite}
        background={theme.spoxioAltLinear}
        // className={className}
        borderRadius="8px"
        padding="1vh 1.5vw"
        margin="0"
        onClick={onPlusClick}
      >
        {t('Message')}
      </ActionButton>
    </ContentContainer>
  );
};

ScoutedTalents.propTypes = {
  user: shape({}).isRequired
};
