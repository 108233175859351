import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { requiredRule } from 'Utils/validations';
import { fanProfile, getCurrentUser, scoutProfile } from 'Store/authSlice';
import { AuthContainer } from 'Components/AuthContainer';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
import { BaseButton, BlackText, ContentContainer } from 'Components/shared/styledComponents';
import {
  AuthFormContainer,
  AuthFormDescription,
  VerificationBadgeContainer,
  VerificationContainer,
  VerificationGrayCircle,
  VerificationIcon
} from '../../components';
import { FormImagePicker } from 'Components/shared/Inputs/FormImagePicker';
import { fetchCountries } from 'Store/dataSlice';
import { getCountrySelector } from 'Store/selectors/dataSelector';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'Components/Layouts/AuthLayout';
import { SpoxioBlueBtn, SubTitle, Title } from 'Components/Layouts/AuthLayout/components';
import { ThemeContext } from 'Context/themeContext';
import { paths, userRoles } from 'Utils/constants';
import { push } from 'connected-react-router';

export const EnhanceScoutProfilePage = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const user = useSelector(getCurrentUser);
  const country = useSelector(getCountrySelector);

  const dispatch = useDispatch();

  const onTryLater = async () => {
    const res = await dispatch(fanProfile());
    if (res) {
      localStorage.setItem('type', userRoles.FAN);
      dispatch(push(paths.SIGN_UP_SPORTS));
    }
  };

  const defaultValues = {
    picture: user?.picture,
    club: user?.club,
    country: user?.country,
    organisation: user?.organisation,
    scoutId: user?.scoutId
  };
  const { register, control, errors, handleSubmit } = useForm({ defaultValues });

  useEffect(() => {
    const getCountries = async () => {
      await dispatch(fetchCountries());
    };

    getCountries();
  }, []);

  const onSubmit = (data) => {
    //dispatch(updateUser(formatScoutEnhanceInfo(data), paths.SIGN_UP_SPORTS));
    dispatch(scoutProfile(data));
  };

  const countryArray = country?.map((item, i) => {
    return {
      label: item.name,
      value: item._id
    };
  });

  return (
    <AuthLayout
      title={
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Title fontSize="1.75rem" fontWeight="bold">
            Complete your
            <br />
            Scout profile
          </Title>
        </div>
      }
      showBackButton
    >
      <AuthFormDescription textAlign="left" className="margin-top_small">
        <SubTitle
          fontSize="1rem"
          fontWeight="400"
          opacity="0.85"
          style={{ color: theme.black, lineHeight: '1.1rem' }}
        >
          Find extra ordinary talents by <br />
          completing your profile and
          <br /> becoming a certified member.
        </SubTitle>
      </AuthFormDescription>
      <AuthFormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormImagePicker
          ref={register(requiredRule)}
          name="picture"
          url={defaultValues.picture}
          compressSize={0.6}
          // helperText={t('add_picture')}
          error={errors?.picture?.message}
          className="margin-bottom_small"
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
        <FormInput
          ref={register(requiredRule)}
          name="club"
          placeholder={t('company_or_clubname')}
          error={errors?.club?.message}
          // className="margin-bottom_medium"
        />
        <FormSelect
          control={control}
          rules={requiredRule}
          name="country"
          options={countryArray}
          placeholder={t('country_of_reg')}
          isSearchable
          error={errors?.country?.message}
          // className="margin-bottom_medium"
        />
        <FormInput
          ref={register(requiredRule)}
          name="organisation"
          placeholder={t('organisation_of_reg')}
          error={errors?.organisation?.message}
          // className="margin-bottom_medium"
        />
        <FormInput
          ref={register(requiredRule)}
          name="scoutId"
          placeholder={t('reg_no')}
          error={errors?.registrationNo?.message}
          // className="margin-bottom_medium"
        />
        {/* <VerificationContainer>
          <VerificationBadgeContainer>
            <VerificationGrayCircle />
            <VerificationIcon />
          </VerificationBadgeContainer>
          <BlackText>
            {t('verify_help')}
            <BlackText fontWeight="bold">{t('verified_acct_text')}</BlackText>
          </BlackText>
        </VerificationContainer> */}
        {/* <BaseButton className="margin-top_medium">{t('complete_scout_profile')}</BaseButton> */}
        <SpoxioBlueBtn className="margin-top_medium">{t('complete_scout_profile')}</SpoxioBlueBtn>
        <ContentContainer
          margin="12px 0"
          width="100%"
          row
          alignItems="center"
          justifyContent="center"
        >
          <Title
            textAlign="center"
            fontSize="1rem"
            onClick={onTryLater}
            style={{ cursor: 'pointer' }}
          >
            I’ll do this later
          </Title>
        </ContentContainer>
      </AuthFormContainer>
    </AuthLayout>
  );
};
