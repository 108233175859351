import { bool, func, string } from 'prop-types';

import { ActionButton } from 'Components/shared/styledComponents';

import { ReactComponent as CheckedIcon } from 'Assets/icons/Check.svg';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';

export const FollowButton = ({ isFollowed, className, onClick }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  return isFollowed ? (
    <ActionButton className={className} onClick={onClick}>
      <CheckedIcon width={12} height={12} color={theme.white} />
      {t('following')}
    </ActionButton>
  ) : (
    <ActionButton className={className} onClick={onClick}>
      + {t('follow')}
    </ActionButton>
  );
};

FollowButton.propTypes = {
  isFollowed: bool,
  className: string,
  onClick: func
};

FollowButton.defaultProps = {
  isFollowed: false,
  className: '',
  onClick: () => {}
};
