import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import firebase from 'firebase/app';
import shortid from 'shortid';

import { getResourceUriByBroadcastID } from 'Utils';
import { getCurrentUser, getCurrentUserId } from 'Store/authSlice';

export const useLivestream = (location) => {
  const [livestream, setLivestream] = useState({});
  const [livestreamRef, setLivestreamRef] = useState(null);
  const [viewers, setViewers] = useState(0);

  const query = useSelector((state) => state?.router?.location?.query);
  const currentUser = useSelector(getCurrentUser);
  const currentUserId = useSelector(getCurrentUserId);

  useEffect(() => {
    if (query?.isLivestream) {
      const path = location.pathname.split('/');
      const dataId = path[path.length - 1];

      firebase
        .firestore()
        .collection('online_streaming')
        .doc(dataId)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            const livestreamData = snapshot.data().posts[0];
            const resourceId = getResourceUriByBroadcastID(livestreamData.url);

            setLivestream({
              id: snapshot.id,
              createdAt: snapshot.data().createdAt,
              ...livestreamData,
              resourceId
            });
            setLivestreamRef(snapshot.ref);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (livestreamRef) {
      livestreamRef.onSnapshot({
        next: (snapshot) => {
          const data = snapshot.data();

          if (data) {
            setLivestream({
              ...livestream,
              messages: data.posts[0].messages,
              likes: data.posts[0].likes
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livestreamRef]);

  const handleLivestreamChange = (e) => {
    if (e.key === 'livestreamViewers') {
      const data = JSON.parse(e.newValue);

      setViewers(data?.current || 0);
    }
  };

  useEffect(() => {
    if (query?.isLivestream) {
      window.addEventListener('storage', handleLivestreamChange);
    }

    return () => window.removeEventListener('storage', handleLivestreamChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage = (text) => {
    const prevMessages = livestream.messages;
    const message = {
      id: shortid(),
      uid: currentUserId,
      name: currentUser.name,
      avatar: currentUser.picture,
      text,
      likes: []
    };

    livestreamRef.update({
      posts: [{ ...livestream, messages: [...prevMessages, message] }]
    });
  };

  const likeLivestream = () => {
    if (currentUserId === livestream.uid) {
      toast.warn('This is your own livestream');
      return;
    }

    const likes = livestream.likes.includes(currentUserId)
      ? livestream.likes.filter((id) => id !== currentUserId)
      : [...livestream.likes, currentUserId];

    livestreamRef.update({ posts: [{ ...livestream, likes }] });
  };

  const likeMessage = (messageId) => {
    const messages = livestream.messages.map((message) => {
      if (message.id === messageId) {
        if (message.uid === currentUserId) {
          toast.warn('This is your own message');
          return message;
        }

        const likes = message.likes.includes(currentUserId)
          ? message.likes.filter((id) => id !== currentUserId)
          : [...message.likes, currentUserId];

        return { ...message, likes };
      }

      return message;
    });

    livestreamRef.update({
      posts: [{ ...livestream, messages }]
    });
  };

  return { livestream, viewers, sendMessage, likeLivestream, likeMessage };
};
