import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getIsAuthorizedSelector } from 'Store/authSlice';
import { paths } from 'Utils/constants';

import LogoSecondary from 'Assets/img/LogoSecondary.png';
import { ReactComponent as InstagramIcon } from 'Assets/icons/Instagram.svg';
import { ReactComponent as FacebookIcon } from 'Assets/icons/Facebook.svg';
import { ReactComponent as TwitterIcon } from 'Assets/icons/Twitter.svg';

import { colors } from 'Theme';
import 'Styles/main.scss';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'Components/LanguageSwitcher/LanguageSwitcher';
import { ToggleTheme } from 'Components/shared/ToggleTheme';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';

export const Footer = () => {
  const isAuthorized = useSelector(getIsAuthorizedSelector);
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return (
    <footer className="footer container">
      <div className="footer-content">
        <div className="footer-logo">
          <Link to={isAuthorized ? paths.TIMELINE : paths.HOME}>
            <img src={LogoSecondary} alt="Spoxio" />
          </Link>
        </div>
        <div className="menu">
          <ul className="menu-list">
            <li className="menu-item">
              <Link to="/about" className="footer-link">
                {t('about')}
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/privacy" className="footer-link">
                {t('privacy_policy')}
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/terms-conditions" className="footer-link">
                {t('terms_of_use')}
              </Link>
            </li>
          </ul>
          <div className="contact">
            <span className="label" style={{ color: theme.defaultBlack, opacity: '0.8' }}>
              {t('contact_us')}
            </span>
            <span className="email">support@spoxio.com</span>
            <br />
            <span className="label" style={{ color: theme.defaultBlack, opacity: '0.8' }}>
              {t('for_more_info')}
            </span>
            <a href=" https://blog.spoxio.com/" rel="noreferrer" target="_blank">
              {t('spoxio_blog')}
            </a>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-social">
            <span className="footer-label" style={{ color: theme.defaultBlack, opacity: '0.8' }}>
              {t('follow_us')}
            </span>
            <div className="footer-social-links">
              <a href="https://www.instagram.com/spoxioapp/" rel="noreferrer" target="_blank">
                <InstagramIcon className="footer-image" color={theme.spoxioAltBlue} />
              </a>
              <a href="https://www.facebook.com/Spoxioapp/" rel="noreferrer" target="_blank">
                <FacebookIcon className="footer-image" color={theme.spoxioAltBlue} />
              </a>
              <a href="https://twitter.com/spoxioapp/" rel="noreferrer" target="_blank">
                <TwitterIcon className="footer-image" color={theme.spoxioAltBlue} fill={theme.spoxioAltBlue} />
                {/* theme.primaryBlue */}
              </a>
            </div>
          </div>
          <div className="footer-address">
            <span className="footer-address-line">Spoxio LTD</span>
            <span className="footer-address-line">London, UK</span>
          </div>
          <span
            className="email"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              alignItems: 'center',
              marginBottom: '8px'
            }}
          >
            Change Theme: <ToggleTheme />
          </span>
          <span className="email">
            {t('switch_language')}: <LanguageSwitcher />
          </span>
        </div>
      </div>
    </footer>
  );
};
