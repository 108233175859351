import React, { useContext } from 'react';
import {
  EventsDetailsDate,
  EventsDetailsHolder,
  EventsDetailsTime,
  EventsDetailsTitle,
  EventsImgHolder,
  JoinEventBtn,
  MyEventsContainer,
  MyEventsLeft
} from './components';
import { ReactComponent as Calendar } from 'Assets/icons/calendar.svg';
// import EventDummy from 'Assets/img/event-dummy.png';
// import EventDummy from 'Assets/img/event-placeholder.jpeg';
import EventDummy from 'Assets/img/event-placeholder2.png';
import { ContentContainer, IconContainer, SubTitle } from 'Components/shared/styledComponents';
import { shape } from 'prop-types';
import { formatDate } from 'Utils/dates';
import isEmpty from 'Utils/isEmpty';
import { ThemeContext } from 'Context/themeContext';
import { paths } from 'Utils/constants';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

const MyEvent = ({ event }) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  // console.log(event, 'my event');
  const viewEventDetails = () => {
    dispatch(push({ pathname: `${paths.EVENTS}/${event?._id}`, state: { event } }));
  };
  return (
    <MyEventsContainer>
      <MyEventsLeft>
        <ContentContainer overflow="hidden" height="88px" width="88px" borderRadius="14px ">
          {!isEmpty(event?.photos) && event?.photos[0] ? (
            <img
              src={event?.photos[0]}
              alt=""
              width="100%"
              height="100%"
              style={{
                objectFit: 'cover'
                //  background: theme?.spoxioBg
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = EventDummy;
                currentTarget.style.objectFit = 'fill';
              }}
            />
          ) : (
            <img
              src={EventDummy}
              alt=""
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', background: theme?.spoxioBg }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = EventDummy;
                currentTarget.style.objectFit = 'fill';
              }}
            />
          )}
        </ContentContainer>

        {/* Event Details */}
        <ContentContainer gap="4px">
          <EventsDetailsTitle
            fontSize="0.875rem"
            fontFamily="SF Pro"
            fontWeight="700"
            textTransform="capitalize"
          >
            {event?.title}
          </EventsDetailsTitle>
          {!isEmpty(event?.start_time) ? (
            <SubTitle fontSize="0.75rem" fontFamily="SF Pro">
              {event?.start_time} - {event?.end_time}
            </SubTitle>
          ) : null}
          <ContentContainer row alignItems="center">
            <IconContainer width="13px" height="13px" margin="0">
              <Calendar />
            </IconContainer>
            <SubTitle fontSize="0.75rem" style={{ display: 'inline-block', margin: 0 }}>
              {formatDate(event?.event_date, 'DD MMMM YYYY')}
            </SubTitle>
          </ContentContainer>
        </ContentContainer>
      </MyEventsLeft>
      <JoinEventBtn onClick={() => viewEventDetails()}>View</JoinEventBtn>
    </MyEventsContainer>
  );
};

MyEvent.propTypes = {
  event: shape({})
};

export default MyEvent;
