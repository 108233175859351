import React from 'react';
import { ReactComponent as SavedLogo } from '../Assets/icons/modal/Saved.svg';
import { ReactComponent as Personalize } from '../Assets/icons/modal/personalize.svg';
import { ReactComponent as Scout } from '../Assets/icons/modal/scout-modal.svg';
import { ReactComponent as Notification } from '../Assets/icons/modal/notification.svg';
import { ReactComponent as Subscription } from '../Assets/icons/modal/subscription.svg';
import { ReactComponent as Security } from '../Assets/icons/modal/security.svg';
import { ReactComponent as About } from '../Assets/icons/modal/about.svg';
import { ReactComponent as Profile } from '../Assets/icons/Profile.svg';
// Social Links
import { ReactComponent as Phone } from '../Assets/icons/social/phone.svg';
import { ReactComponent as Mail } from '../Assets/icons/social/mail.svg';
import { ReactComponent as Website } from '../Assets/icons/social/website.svg';
import { ReactComponent as Facebook } from '../Assets/icons/social/facebook.svg';
import { ReactComponent as Instagram } from '../Assets/icons/social/instagram.svg';
import { ReactComponent as Twitter } from '../Assets/icons/social/twitter.svg';

export const modalData = {
  fan: [
    {
      icon: Profile,
      text: 'Edit Profile',
      name: 'editProfile'
    },
    {
      icon: Personalize,
      text: 'Personalization',
      name: 'personalize'
    },
    {
      icon: Security,
      text: 'Security',
      name: 'security'
    },
    {
      icon: About,
      text: 'About',
      name: 'about'
    },
    {
      text: 'Switch Theme Mode',
      name: 'switchTheme'
    }
  ],
  talent: [
    {
      icon: Profile,
      text: 'Edit Profile',
      name: 'editProfile'
    },
    {
      icon: Personalize,
      text: 'Personalization',
      name: 'personalize'
    },
    {
      icon: Scout,
      text: 'Scout Activities',
      name: 'scoutActivities'
    },
    {
      icon: Security,
      text: 'Security',
      name: 'security'
    },
    {
      icon: About,
      text: 'About',
      name: 'about'
    },
    {
      text: 'Switch Theme Mode',
      name: 'switchTheme'
    }
  ],
  club: [
    {
      icon: Profile,
      text: 'Edit Profile',
      name: 'editProfile'
    },
    {
      icon: Personalize,
      text: 'Personalization',
      name: 'personalize'
    },
    {
      icon: Security,
      text: 'Security',
      name: 'security'
    },
    {
      icon: About,
      text: 'About',
      name: 'about'
    },
    {
      text: 'Switch Theme Mode',
      name: 'switchTheme'
    }
  ],
  scout: [
    {
      icon: Profile,
      text: 'Edit Profile',
      name: 'editProfile'
    },
    {
      icon: Personalize,
      text: 'Personalization',
      name: 'personalize'
    },
    {
      icon: Scout,
      text: 'Scout Activities',
      name: 'scoutActivities'
    },
    {
      icon: Subscription,
      text: 'Subscription',
      name: 'subscription'
    },
    {
      icon: Security,
      text: 'Security',
      name: 'security'
    },
    {
      icon: About,
      text: 'About',
      name: 'about'
    },
    {
      text: 'Switch Theme Mode',
      name: 'switchTheme'
    }
  ],
  club_member: [
    // {
    //   icon: Profile,
    //   text: 'Edit Profile',
    //   name: 'editProfile'
    // },
    {
      icon: Personalize,
      text: 'Personalization',
      name: 'personalize'
    },
    {
      icon: Scout,
      text: 'Scout Activities',
      name: 'scoutActivities'
    },
    {
      icon: Security,
      text: 'Security',
      name: 'security'
    },
    {
      icon: About,
      text: 'About',
      name: 'about'
    },
    {
      text: 'Switch Theme Mode',
      name: 'switchTheme'
    }
  ],
  default: [
    {
      icon: Personalize,
      text: 'Personalization',
      name: 'personalize'
    },
    {
      icon: About,
      text: 'About',
      name: 'about'
    },
    {
      text: 'Switch Theme Mode',
      name: 'switchTheme'
    }
  ]
};

export const getIcon = (name) => {
  const icons = {
    SavedLogo: SavedLogo,
    Personalize: Personalize,
    Scout: Scout,
    Notification: Notification,
    Subscription: Subscription,
    Security: Security,
    About: About,
    Profile: Profile,
    Mail: Mail,
    Phone: Phone,
    Website: Website,
    Twitter: Twitter,
    Facebook: Facebook,
    Instagram: Instagram
  };

  return icons[name] || About;
};

export const SocialLinks = [
  {
    route: (data) => {
      return `tell:${data}`;
    },
    icon: Phone,
    name: 'Phone'
  },
  {
    route: (data) => {
      return `mailto:${data}`;
    },
    icon: Mail,
    name: 'Mail'
  },
  {
    route: (data) => {
      return `https://${data}`;
    },
    icon: Website,
    name: 'Website'
  },
  {
    route: (data) => {
      return `https://${data}`;
    },
    icon: Instagram,
    name: 'Instagram'
  },
  {
    route: (data) => {
      return `https://${data}`;
    },
    icon: Facebook,
    name: 'Facebook'
  },
  {
    route: (data) => {
      return `https://${data}`;
    },
    icon: Twitter,
    name: 'Twitter'
  }
];

export const FormComponentData = [
  {
    name: 'title',
    placeholder: 'Event Title',
    type: 'text'
  },
  {
    name: 'category',
    placeholder: 'Event Category',
    type: 'text'
  },
  {
    name: 'event_date',
    placeholder: 'Event Title',
    type: 'date'
  },
  {
    name: 'start_time',
    placeholder: 'Start Time',
    type: 'time'
  },
  {
    name: 'end_time',
    placeholder: 'End Time',
    type: 'time'
  },
  {
    name: 'venue',
    placeholder: 'Venue',
    type: 'text'
  },
  {
    name: 'venue_address',
    placeholder: 'Venue Location',
    type: 'text'
  }
];
