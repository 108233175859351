import { setisEventLoading } from './loaderSlice';
import Api2 from 'middleware/api';
import {
  setEvent,
  setEventCategories,
  setEventDetails,
  setMyEvent,
  setParticipatingEvent,
  setTrendingEvent
} from './selectors/eventSelector';
import Auth from 'middleware/storage';
import { toast } from 'react-toastify';

export const fetchMyEvents = (credentials) => async (dispatch) => {
  try {
    dispatch(setisEventLoading(true));
    const res = await Api2.event.fetchMyEvents(credentials);
    // const { data } = res.data;
    // console.log('My events', res.data.data);
    dispatch(setMyEvent(res.data.data));
    return true;
  } catch (error) {
    console.log('fetch event error', error.response);
    return false;
  } finally {
    dispatch(setisEventLoading(false));
  }
};

export const fetchTrendingEvents = (credentials) => async (dispatch) => {
  try {
    dispatch(setisEventLoading(true));
    const res = await Api2.event.fetchTrendingEvents(credentials);
    // const { data } = res.data;
    // console.log('trending events', res.data.data);
    dispatch(setTrendingEvent(res.data.data));
    return true;
  } catch (e) {
    console.log(e.response);
    return false;
  } finally {
    dispatch(setisEventLoading(false));
  }
};

export const fetchParticipatingEvents = (credentials) => async (dispatch) => {
  try {
    dispatch(setisEventLoading(true));
    const res = await Api2.event.fetchParticipatingEvents(credentials);
    // const { data } = res.data;
    // console.log('participating events', res.data.data);
    dispatch(setParticipatingEvent(res.data.data));
    return true;
  } catch (e) {
    console.log(e.response);
    return false;
  } finally {
    dispatch(setisEventLoading(false));
  }
};

export const fetchEventCategories = (credentials) => async (dispatch) => {
  try {
    dispatch(setisEventLoading(true));
    const res = await Api2.event.fetchEventCategories(credentials);
    // const { data } = res.data;
    // console.log('Events categories', res.data.data);
    dispatch(setEventCategories(res.data?.data));
    return true;
  } catch (e) {
    console.log(e.response);
    return false;
  } finally {
    dispatch(setisEventLoading(false));
  }
};

export const fetchEventDetailsById = (credentials) => async (dispatch) => {
  try {
    dispatch(setisEventLoading(true));
    const res = await Api2.event.eventDetailsById(credentials);
    const res2 = await Api2.event.participants(credentials);
    // const { data } = res.data;
    const data = {
      ...res.data.data,
      participants: res2.data.data || []
    };
    // console.log('Events Details by Id', data);
    dispatch(setEventDetails(data));
    return true;
  } catch (e) {
    console.log(e.response, 'fetch evevnt by id error');
    return false;
  } finally {
    dispatch(setisEventLoading(false));
  }
};

export const createEvent = (credentials) => async (dispatch) => {
  try {
    const token = await Auth.getToken();
    const response = await Api2.event.createEvent(credentials, token);
    const { data } = response.data;
    console.log('success');
    console.log('-------------------Create event response--------------------', response.data);
    return data;
  } catch (error) {
    console.log('error occured');
    console.log(error.response);
    if (error && error?.response?.data) {
      toast.warn(error?.response?.data?.title);
    } else {
      toast.warn('Network error. Try again', 'error');
    }
    return false;
  }
};
