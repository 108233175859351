import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'Assets/icons/Close.svg';
import { BaseText, BlackText } from '../styledComponents';

export const Close = styled(CloseIcon)`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

export const Left = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`;

export const Head = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  text-align: center;
`;

export const ModalTitle = styled(BaseText)`
  font-size: ${({ theme }) => theme.xLarge}px;
  color: ${({ theme }) => theme.light};
`;
