import styled from 'styled-components';

export const CreateEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  height: 100%;
`;

export const EventContainerDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CreateEventButton = styled.button`
  display: flex;
  height: 48px;
  width: 100%;
  color: ${({ theme }) => theme.defaultWhite};
  background: ${({ theme }) => theme.spoxioAltLinear};
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    opacity: 0.85;
  }
`;
// Type Event Container
export const TypeEventContainer = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const InputForm = styled.input`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.borderShade};
  width: 100%;
  height: 48px;
  border-radius: 12px;
  padding: 10px;
`;
export const TypeEventCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.spoxioIcon2};
  padding: 30px 20px 40px;
  border-radius: 10px;
  cursor: pointer;
  width: 48%;
  position: relative;
`;
export const EventTextArea = styled.textarea`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.mediumGray};
  font-size: ${({ theme }) => theme.medium}px;
  color: ${({ theme }) => theme.black};
  width: 100%;
  border-radius: 12px;
  padding: 10px;
`;
export const EventCheckbox = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
`;
export const EventCheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TypeTickets = styled.p`
  display: flex;
  margin: 0;
  justify-content: space-between;
  align-items: center;
`;
export const TicketsForm = styled.p`
  display: flex;
  column-gap: 4px;
  cursor: pointer;
`;

export const SchedulingTag = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
export const SchedulingText = styled.p`
  color: ${({ theme }) => theme.spoxioBlue};
  font-weight: 600;
  margin: 0;
  font-size: 16px;
`;
export const SchedulingContainer = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 15px;
  cursor: pointer;
  justify-content: space-between;
`;

export const EventSuccess = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
`;
export const EventSuccessText = styled.p`
  margin: ${({ m }) => (m ? m : '0px')};
  line-height: 15px;
  color: ${({ color, theme }) => (color ? color : theme.mediumGray)};
  font-size: ${({ size }) => (size ? size : '14px')};
`;

export const EventItemContainer = styled.div`
  height: 280px;
  min-width: 275px;
  max-width: 275px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.borderShade};
  border-radius: 20px;
`;
export const EventBody = styled.div`
  ${({ theme }) => {
    return `
                .trending_event_details {
                    width: 100%;
                    height: 100%;
                    padding-top: 32px; 
                    display: inline-block;

                    .trending_event_date {
                        background: ${theme?.white};
                        width: 90px;
                    
                        border-radius: 10px;
                        display: block;
                        font-size: 16px;
                        color: ${theme?.lightGray};
                        line-height: 45px;
                        text-align: center;
                        font-weight: 500;
                    }

                    .trending_event_title {
                        font-size: 16px;
                        font-weight: 510;
                        color: ${theme?.lightGray3};
                
                    }

                    .trending_event_location {
                        color: ${theme?.lightGray2}
                    }

                    .location_pin {
                        display: inline-block;
                        margin-right: 8px;
                        width: 100%:
                        overflow: hidden;
                        text-wrap: nowrap;

                        img {       
                            width: 16px;
                            height: 16px;
                            margin-right: 8px;
                        }
                    }

                    .trending_event_live {
                        display: inline-block;
                        padding: 8px;
                        border-radius: 8px;
                        background: ${theme?.pink};
                        font-size: 10px;
                        font-weight: 590px;
                        margin-top: 24px;
                    }
                }
            `;
  }}
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  background: ${({ theme }) => theme?.spoxioBg};
  width: 100%;
  height: 80%;
  border-radius: 16px;
  box-sizing: border-box;
  padding-left: 16px;

  .trending_event_image {
    width: 50%;
    height: 100%;
    border-radius: 0px 16px 16px 0px;
    display: inline-block;
  }
`;
