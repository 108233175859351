import styled from 'styled-components';

import { BaseButton } from 'Components/shared/styledComponents';

export const VideoTrimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;

  .input-range__track--active {
    background: ${({ theme }) => theme.primaryBlue};
  }

  .input-range__slider {
    background: ${({ theme }) => theme.primaryBlue};
    border: none;
  }
`;

export const Video = styled.video`
  margin-bottom: 35px;
  width: 100%;
  max-height: 300px;

  ::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }

  ::-webkit-media-controls-timeline {
    display: none;
  }
`;

export const TrimButton = styled(BaseButton)`
  width: 150px;
  margin-top: 50px;
`;
