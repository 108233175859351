import React, { useEffect, useState } from 'react';
import EventsLayout from 'Components/Layouts/EventsLayout/EventsLayout';
import { SubTitle } from 'Components/Layouts/ProfileLayout/LeftBar/components';
import { ContentContainer } from 'Components/shared/styledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { fetchParticipatingEvents, fetchTrendingEvents } from 'Store/eventSlice';
import { getParticipatingEvent, getTrendingEvents } from 'Store/selectors/eventSelector';
import isEmpty from 'Utils/isEmpty';
import { func } from 'prop-types';
import MyEvent from 'Components/Event/MyEvent';
import TrendingEventsBar from 'Components/Event/TrendingBar';
import { useLocation } from 'react-router';
import { Loader } from 'Components/shared/Loader';
import { getIsEventLoading } from 'Store/loaderSlice';

export const AllEvents = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const trendingEvents = useSelector(getTrendingEvents);
  const participatingEvent = useSelector(getParticipatingEvent);
  const isLoading = useSelector(getIsEventLoading);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    if (!query.get('sport')) {
      dispatch(fetchTrendingEvents({ page: 1 }));
      dispatch(fetchParticipatingEvents({ page: 1 }));
      return;
    }
    dispatch(fetchTrendingEvents({ category: query.get('sport'), page: 1 }));
    dispatch(fetchParticipatingEvents({ category: query.get('sport'), page: 1 }));
  }, []);

  const pathname = useSelector((state) => state?.router?.location?.pathname);
  const path = pathname.split('/');
  const eventType = path[2];
  console.log(eventType, 'pathname', pathname, path);

  return (
    <EventsLayout
      showBackButton
      navTitle={eventType === 'trending' ? 'Trending Events' : 'Participating Events'}
      step={step}
      setStep={setStep}
      // border="transparent"
    >
      <ContentContainer gap="20px" column margin="20px auto 10%" maxWidth="700px">
        {isLoading ? (
          <ContentContainer height="85vh" width="100%">
            <Loader />
          </ContentContainer>
        ) : (
          <>
            {eventType === 'trending' ? (
              !isEmpty(trendingEvents) ? (
                <>
                  {trendingEvents?.map((_event, i) => {
                    return <MyEvent key={i} event={_event} />;
                  })}
                </>
              ) : null
            ) : null}

            {eventType === 'participating' ? (
              !isEmpty(participatingEvent) ? (
                <>
                  {participatingEvent?.map((_event, i) => {
                    return <MyEvent key={i} event={_event} />;
                  })}
                </>
              ) : null
            ) : null}
          </>
        )}
      </ContentContainer>
    </EventsLayout>
  );
};
