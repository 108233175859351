import React, { useContext } from 'react';
import { bool, func, string } from 'prop-types';
import { Moon, Sun } from './Component';
import { ContentContainer, IconContainer, SubTitle } from '../../shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';

export const ToggleTheme = ({ showText, color, width }) => {
  const { toggleTheme, themeMode, theme } = useContext(ThemeContext);

  return (
    <div onClick={toggleTheme} style={{ width: width }}>
      {themeMode === 'light' ? (
        <ContentContainer row gap="6px" alignItems="center" width={width}>
          <IconContainer title="Switch theme">
            <Sun
              xmlns="http://www.w3.org/2000/svg"
              // width="27"
              // height="27"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#6D7278"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="5"></circle>
              <line x1="12" y1="1" x2="12" y2="3"></line>
              <line x1="12" y1="21" x2="12" y2="23"></line>
              <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
              <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
              <line x1="1" y1="12" x2="3" y2="12"></line>
              <line x1="21" y1="12" x2="23" y2="12"></line>
              <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
              <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
            </Sun>
          </IconContainer>
          {showText && (
            <SubTitle fontSize="1rem" opacity="1" color={color || theme.spoxioIcon}>
              {' '}
              Switch Theme Mode
            </SubTitle>
          )}
        </ContentContainer>
      ) : (
        <ContentContainer row gap="6px" alignItems="center" width={width}>
          <IconContainer title="Switch theme">
            <Moon
              xmlns="http://www.w3.org/2000/svg"
              // width="27"
              // height="27"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#6D7278"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </Moon>
          </IconContainer>
          {showText && (
            <SubTitle fontSize="1rem" opacity="1" color={color || theme.spoxioIcon}>
              {' '}
              Switch Theme Mode
            </SubTitle>
          )}
        </ContentContainer>
      )}
    </div>
  );
};

ToggleTheme.propTypes = {
  showText: bool,
  color: string,
  width: string
};

ToggleTheme.defaultProps = {
  showText: false
};
