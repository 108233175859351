import ApiHandler, { EventApiHandler } from './ApiHandler';

const Api = {
  auth: {
    signInEmail: (data) => ApiHandler.post(`/auth/login`, data),
    signUpEmail: (data) => ApiHandler.post(`/users`, data),
    refreshToken: (data) => ApiHandler.post(`/auth/refresh-token`, data),
    signInSocial: (token) => ApiHandler.get(`/auth/socials/redirect?idToken=${token}`),
    logout: (data) => ApiHandler.post(`/auth/logout`, data),
    deactivate: () => ApiHandler.post(`/auth/deactivate-account`, {})
  },
  category: {
    countries: () => ApiHandler.get(`/countries`),
    sports: () => ApiHandler.get(`/sports`),
    sportsPopular: () => ApiHandler.get(`/sports/popular`)
  },
  comment: {
    comments: (id) => ApiHandler.get(`/comments/root?postId=${id}`),
    createComment: (data) => ApiHandler.post(`/comments`, data),
    commentReplies: (id) => ApiHandler.get(`/comments/${id}/replies`)
  },
  notification: {
    fetchNotifications: (count, token) =>
      ApiHandler.get(`/notifications?limit=${count || 200}&page=1`, token),
    updateNotification: (id, token) => ApiHandler.put(`/notifications/${id}/read`, {}, token),
    deleteNotification: (id, token) => ApiHandler.delete(`/notifications/${id}`, token),
    updateNotificationPreference: (data) => ApiHandler.put(`/preferences/push-notification`, data),
    turnedOffNotifications: (token) =>
      ApiHandler.get(`/preferences/push-notification/turned-off`, token),
    chatNotifications: (data) => ApiHandler.post(`/chats/notifications`, data),
    liveNotification: (data) => ApiHandler.post(`/users/me/send-notification/to/followers`, data)
  },
  post: {
    timelinePost: (page, limit, token) =>
      ApiHandler.get(`/posts/personalized?limit=${limit || 12}&page=${page || 1}`, token),
    // timelinePost: (page, limit, token) =>
    //   ApiHandler.get(`/posts/personalized?limit=${limit}&page=${page}`, token),
    createPost: (data) => ApiHandler.post(`/posts`, data),
    editPost: (id, data) => ApiHandler.put(`/posts/${id}`, data),
    deletePost: (id) => ApiHandler.delete(`/posts/${id}`),
    singlePost: (id) => ApiHandler.get(`/posts/${id}`),
    singlePostNoAuth: (id) => ApiHandler.get(`/public/posts/${id}`),
    likePostOrComment: (data) => ApiHandler.post(`/likes`, data),
    postInterest: (query, count) =>
      ApiHandler.get(`/posts/personalized?interest=${query}&limit=${count || 300}&page=1`),
    postTrends: (count) => ApiHandler.get(`/posts/trending/topics?limit=${count || 50}&page=1`),
    usersTrends: () => ApiHandler.get(`/users/interests/posts`),
    userPosts: (id) => ApiHandler.get(`/posts/user/${id}?limit=3000&page=1`),
    fetchTopicPost: (id, count) =>
      ApiHandler.get(`/posts/personalized?topics=${id}&limit=${count || 300}&page=1`),
    fetchTopics: () => ApiHandler.get(`/posts/topics`),
    reportPost: (data) => ApiHandler.post(`/report/content`, data),
    trendingUserInterest: () => ApiHandler.get(`/users/interests/posts`)
  },
  story: {
    fetchStories: () => ApiHandler.get(`/stories/me`),
    fetchUserStories: (id) => ApiHandler.get(`/stories/me/${id}`),
    createStory: (data) => ApiHandler.post(`/stories`, data),
    deleteStory: (id) => ApiHandler.delete(`/stories/${id}`)
  },
  user: {
    userDetails: () => ApiHandler.get(`/auth/user`),
    userDetailById: (id) => ApiHandler.get(`/users/${id}`),
    updateUserInfo: (data) => ApiHandler.put(`/users/me`, data),
    registerScout: (data) => ApiHandler.post(`/profiles/scouts`, data),
    updateScout: (data) => ApiHandler.put(`/profiles/scouts`, data),
    registerTalent: (data) => ApiHandler.post(`/profiles/talents`, data),
    updateTalent: (data) => ApiHandler.put(`/profiles/talents`, data),
    registerFan: () => ApiHandler.post(`/profiles/fans`, {}),
    registerClub: (data) => ApiHandler.post(`/profiles/clubs`, data),
    updateClub: (id, data) => ApiHandler.put(`/profiles/clubs/${id}`, data),
    fetchScoutedTalents: () => ApiHandler.get('/v2/profiles/scouts/me/talents'),
    whoToFollow: () => ApiHandler.get(`/users/me/who-to-follow?limit=10&page=1`),
    followers: (id, limit) =>
      ApiHandler.get(`/users/${id || 'me'}/followers?limit=${limit || '1000'}&page=1`),
    following: (id, limit) =>
      ApiHandler.get(`/users/${id || 'me'}/following?limit=${limit || '1000'}&page=1`),
    followUser: (id) => ApiHandler.put(`/users/me/follow/${id}`, {}),
    unFollowUser: (id) => ApiHandler.put(`/users/me/unfollow/${id}`, {}),
    searchUser: (user) => ApiHandler.get(`/users/search?key=${user}`),
    blockUser: (id) => ApiHandler.post(`/users/block/${id}`, {}),
    unBlockUser: (id) => ApiHandler.post(`/users/unblock/${id}`, {}),
    updatePassword: (data) => ApiHandler.put(`/users/me/update-password`, data),
    // userTalents: () => ApiHandler.get(`/users/talents`),
    purchases: () => ApiHandler.get(`/purchases/items`),
    purchasesNew: (data) => ApiHandler.post(`/purchases/new`, data),
    userTalents: () => ApiHandler.get('/users/talents?limit=30&page=1'),
    allTalents: (count, id) =>
      ApiHandler.get(`/v2/profiles/talents?limit=${count || 5}&page=1&sport=${id}`),
    purchasesActive: () => ApiHandler.get('/purchases/me'),
    purchaseIntent: () => ApiHandler.get('/payments/stripe/intent'),
    purchaseConfirmation: (data) => ApiHandler.post('/payments/intent-confirmation', data),
    startTrial: (data) => ApiHandler.post('/purchases/trial/scout', data),
    cancelSub: (id) => ApiHandler.put(`/purchases/unsubscribe/${id}`),
    scoutTalent: (talentId) => ApiHandler.post(`/v2/profiles/scouts/me/talents/${talentId}`),
    deleteScoutTalent: (talentId) =>
      ApiHandler.delete(`/v2/profiles/scouts/me/talents/${talentId}`),
    postTalentGallery: (data) => ApiHandler.post('/v2/profiles/talents/me/gallery', data),
    fetchTalentGallery: (talentId) => ApiHandler.get(`/v2/profiles/talents/${talentId}/gallery`),
    deleteTalentPhoto: (photoId) => ApiHandler.delete(`/v2/profiles/talents/${photoId}/gallery`),
    resendCode: (data) => ApiHandler.post(`/users/activation-code`, data)
  },
  event: {
    createEvent: (data) => EventApiHandler.post('/events', data),
    fetchMyEvents: (data) => {
      let queryString;

      if (data) {
        queryString = Object.keys(data)
          .map((key) => `${key}=${data[key]}`)
          .join('&');

        return EventApiHandler.get(`/user/my-events?${queryString}`);
      }
      return EventApiHandler.get('/user/myevents');
    },
    fetchTrendingEvents: (data) => {
      let queryString;
      if (data) {
        queryString = Object.keys(data)
          .map((key) => `${key}=${data[key]}`)
          .join('&');

        return EventApiHandler.get(`/events/trending?${queryString}`);
      }
      return EventApiHandler.get('/events/trending');
    },
    fetchParticipatingEvents: (data) => {
      let queryString;

      if (data) {
        queryString = Object.keys(data)
          .map((key) => `${key}=${data[key]}`)
          .join('&');

        return EventApiHandler.get(`/events/me/attending?${queryString}`);
      }
      return EventApiHandler.get('/events/me/attending');
    },
    fetchEventCategories: () => EventApiHandler.get('/events/categories'),
    joinEvent: (data) => EventApiHandler.post('/attendees', data),
    participants: (id) => EventApiHandler.get(`/events/${id}/attendees`),
    eventDetailsById: (id) => EventApiHandler.get(`/events/${id}`)
  },
  club: {
    createClubProfile: (data) => ApiHandler.post('/profiles/clubs', data),
    fetchAllClubs: () => ApiHandler.get('/profiles/clubs'),
    acceptClubInvite: (data, token, clubProfileID) =>
      ApiHandler.post(
        `/profiles/club-invitation?inviteToken=${token}&clubProfileId=${clubProfileID}`,
        data
      ),
    fetchClubProfileById: (id) => ApiHandler.get(`/profiles/clubs/${id}`),
    editClubProfile: (data, clubId) => ApiHandler.put(`/profiles/clubs/${clubId}`, data),
    addClubRole: (clubId, data) => ApiHandler.post(`/profiles/clubs/${clubId}/roles`, data),
    fetchClubRoles: (clubId) => ApiHandler.get(`/profiles/clubs/${clubId}/roles`),
    deleteClubRole: (clubId, roleId) =>
      ApiHandler.delete(`/profiles/clubs/${clubId}/roles/${roleId}`),
    inviteClubMember: (clubId, data) =>
      ApiHandler.post(`/profiles/clubs/${clubId}/invite-member`, data),
    editClubMember: (clubId, memberId, data) =>
      ApiHandler.put(`/profiles/clubs/${clubId}/members/${memberId}`, data),
    addClubStatCategory: (clubId, data) =>
      ApiHandler.post(`/profiles/clubs/${clubId}/statistics-category`, data),
    fetchClubStatCategory: (clubId, type) =>
      ApiHandler.get(`/profiles/clubs/${clubId}/statistics-category?type=${type}`),
    deleteClubStatCategory: (clubId, statId) =>
      ApiHandler.delete(`/profiles/clubs/${clubId}/statistics-category/${statId}`),
    addClubStats: (clubId, data) => ApiHandler.put(`/profiles/clubs/${clubId}/statistics`, data),
    deleteClubStats: (clubId, statId) =>
      ApiHandler.delete(`/profiles/clubs/${clubId}/statistics/${statId}`),
    fetchClubMembers: (clubId) => ApiHandler.get(`/profiles/clubs/${clubId}/members`),
    addClubMemberStats: (clubId, memberId, data) =>
      ApiHandler.put(`/profiles/clubs/${clubId}/members/${memberId}/statistics`, data),
    deleteClubMemberStats: (clubId, memberId, statId) =>
      ApiHandler.delete(`/profiles/clubs/${clubId}/members/${memberId}/statistics/${statId}`)
  }
};
export default Api;
