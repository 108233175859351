import { number, string } from 'prop-types';

import { LoaderContainer, LoaderImage } from './components';

export const Loader = ({ size, height, background, padding, position }) => {
  return (
    <LoaderContainer height={height} background={background} position={position} padding={padding}>
      <LoaderImage size={size} />
    </LoaderContainer>
  );
};

Loader.propTypes = {
  size: number,
  height: string,
  background: string,
  position: string,
  padding: string
};

Loader.defaultProps = {
  size: 24,
  height: '100%'
};
