import { useState, useEffect, useRef } from 'react';
import { func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { toast } from 'react-toastify';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

import { formatToMinutes } from 'Utils';
import { TrimButton, Video, VideoTrimmerContainer } from './components';
import { getIsLoading, setLoading } from 'Store/loaderSlice';
import { Loader } from 'Components/shared/Loader';
import { useTranslation } from 'react-i18next';

const ffmpeg = createFFmpeg({ log: false });

export const VideoTrimmer = ({ file, handleTrimVideo }) => {
  const [value, setValue] = useState({ min: 0, max: 1 });
  const [maxTime, setMaxTime] = useState(0);
  const [currentSource, setCurrentSource] = useState(`${file?.base64}#t=0,0`);
  const videoRef = useRef(null);

  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getVideoDuration = () => {
    setValue({ min: 0, max: videoRef.current.duration });
    setMaxTime(Math.floor(videoRef.current.duration));
    setCurrentSource(`${file?.base64}#t=0,${videoRef.current.duration}`);

    document.getElementById('video-preview').removeEventListener('loadeddata', getVideoDuration);
  };

  const load = async () => {
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (videoRef?.current) {
      document.getElementById('video-preview').addEventListener('loadeddata', getVideoDuration);
    }
  }, []);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const onChangeComplete = (newValue) => {
    setCurrentSource(`${file.base64}#t=${newValue.min},${newValue.max}`);
  };

  const onTrimVideo = async () => {
    if (value.max - value.min > 60) {
      toast.warn(`${t('less_than_60sec')}`);
      return;
    }

    dispatch(setLoading(true));

    const duration = (value.max - value.min).toString();
    const startTime = value.min.toString();

    ffmpeg.FS('writeFile', 'input.mp4', await fetchFile(file.blob));
    await ffmpeg.run(
      '-i',
      'input.mp4',
      '-t',
      duration,
      '-ss',
      startTime,
      '-f',
      'mp4',
      'output.mp4'
    );

    const data = ffmpeg.FS('readFile', 'output.mp4');
    const blob = new Blob([data.buffer], { type: 'video/mp4' });

    dispatch(setLoading(false));
    handleTrimVideo(currentSource, blob);
  };

  return (
    <VideoTrimmerContainer>
      {isLoading && <Loader />}
      <Video id="video-preview" ref={videoRef} src={currentSource} controls />
      <InputRange
        maxValue={maxTime}
        minValue={0}
        step={1}
        value={value}
        formatLabel={(val) => formatToMinutes(val)}
        onChange={onChange}
        onChangeComplete={onChangeComplete}
      />
      <TrimButton onClick={onTrimVideo}>{t('trim_video')}</TrimButton>
    </VideoTrimmerContainer>
  );
};

VideoTrimmer.propTypes = {
  file: string,
  handleTrimVideo: func.isRequired
};

VideoTrimmer.defaultProps = {
  file: {}
};
