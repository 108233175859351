import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { genderOptions, paths, userRoles } from 'Utils/constants';
import { requiredRule } from 'Utils/validations';
import { maxDateForDobField } from 'Utils/dates';
import { fanProfile, getCurrentUser, talentProfile } from 'Store/authSlice';
import { AuthContainer } from 'Components/AuthContainer';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
import { BaseButton, ContentContainer, RowContainer } from 'Components/shared/styledComponents';
import { AuthFormContainer, AuthFormDescription, AuthLink } from '../../components';
import { FormTextArea } from 'Components/shared/Inputs/FormTextArea';
import { FormDatePicker } from 'Components/shared/Inputs/FormDatePicker';
import { FormImagePicker } from 'Components/shared/Inputs/FormImagePicker';
import { getCountrySelector, getSportSelector } from 'Store/selectors/dataSelector';
import { fetchCountries, fetchSports } from 'Store/dataSlice';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'Components/Layouts/AuthLayout';
import { SpoxioBlueBtn, SubTitle, Title } from 'Components/Layouts/AuthLayout/components';
import { ThemeContext } from 'Context/themeContext';
import { push } from 'connected-react-router';

export const EnhanceTalentProfilePage = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const user = useSelector(getCurrentUser);
  const country = useSelector(getCountrySelector);
  const sports = useSelector(getSportSelector);

  const dispatch = useDispatch();

  const defaultValues = {
    picture: user?.picture,
    dateOfBirth: user?.dateOfBirth,
    gender: user?.gender,
    country: user?.country,
    weight: user?.weight,
    height: user?.height,
    bio: user?.bio
  };
  const { register, control, errors, watch, handleSubmit } = useForm({ defaultValues });

  useEffect(() => {
    const getData = async () => {
      await dispatch(fetchCountries());
      await dispatch(fetchSports());
    };

    getData();
  }, []);

  const onSubmit = (data) => {
    dispatch(talentProfile(data));
  };

  const onTryLater = async () => {
    const res = await dispatch(fanProfile());
    if (res) {
      localStorage.setItem('type', userRoles.FAN);
      dispatch(push(paths.SIGN_UP_SPORTS));
    }
  };

  const watchValues = watch(['dateOfBirth']);

  const sportsArray = sports?.map((item, i) => {
    return {
      label: item.name,
      value: item._id
    };
  });
  const countryArray = country?.map((item, i) => {
    return {
      label: item.name,
      value: item._id
    };
  });

  return (
    <AuthLayout
      title={
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Title fontSize="1.75rem" fontWeight="bold">
            Enhance your
            <br /> Talent profile
          </Title>
        </div>
      }
      showBackButton
    >
      <AuthFormDescription textAlign="left" className="margin-top_small">
        <SubTitle
          fontSize="1rem"
          fontWeight="400"
          opacity="0.85"
          style={{ color: theme.black, lineHeight: '1.1rem' }}
        >
          Add the information below and
          <br /> increase your chances of being
          <br /> contacted by scouts!
        </SubTitle>
      </AuthFormDescription>
      <AuthFormContainer
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <FormImagePicker
          ref={register(requiredRule)}
          name="picture"
          url={defaultValues.picture}
          compressSize={0.6}
          // helperText={t('add_picture')}
          error={errors?.picture?.message}
          className="margin-bottom_small"
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
        <ContentContainer row gap="20px" className="margin-bottom_small">
          <ContentContainer column width="50%">
            <FormDatePicker
              control={control}
              rules={requiredRule}
              name="dateOfBirth"
              value={watchValues.dateOfBirth}
              placeholder={t('dob')}
              maxDate={maxDateForDobField()}
              error={errors?.dateOfBirth?.message}
            />
          </ContentContainer>

          <ContentContainer column width="50%">
            <FormSelect
              control={control}
              rules={requiredRule}
              name="gender"
              options={genderOptions}
              placeholder={t('gender')}
              error={errors?.gender?.message}
              // className="margin-bottom_medium"
            />
          </ContentContainer>
        </ContentContainer>

        <FormSelect
          control={control}
          rules={requiredRule}
          name="country"
          options={countryArray}
          placeholder={t('country_of_res')}
          isSearchable
          error={errors?.country?.message}
          className="margin-bottom_small"
        />
        <FormSelect
          control={control}
          rules={requiredRule}
          name="sportPlayed"
          options={sportsArray}
          placeholder={t('sport_talent')}
          isSearchable
          error={errors?.country?.message}
          className="margin-bottom_small"
        />

        <ContentContainer
          row
          gap="20px"
          // className="margin-bottom_small"
        >
          <ContentContainer column width="50%">
            <FormInput
              ref={register()}
              name="weight"
              type="number"
              placeholder={t('weight')}
              error={errors?.weight?.message}
              // className="margin-right_medium"
            />
          </ContentContainer>
          <ContentContainer column width="50%">
            <FormInput
              ref={register()}
              name="height"
              type="number"
              placeholder={t('height')}
              error={errors?.height?.message}
            />
          </ContentContainer>
        </ContentContainer>
        <FormTextArea
          ref={register()}
          name="bio"
          placeholder={t('about_bio')}
          error={errors?.bio?.message}
        />
        <SpoxioBlueBtn className="margin-top_medium">{t('enhance_profile')}</SpoxioBlueBtn>
        <ContentContainer
          margin="12px 0"
          width="100%"
          row
          alignItems="center"
          justifyContent="center"
        >
          <Title
            textAlign="center"
            fontSize="1rem"
            onClick={onTryLater}
            style={{ cursor: 'pointer' }}
          >
            I’ll do this later
          </Title>
        </ContentContainer>
      </AuthFormContainer>
    </AuthLayout>
  );
};
