import { arrayOf, func, object, shape, string } from 'prop-types';

import { SportItemButton, SportItemContainer, SportList, SportName } from './components';

import { ReactComponent as IconMedal } from 'Assets/icons/icon-medal.svg';
import isEmpty from 'Utils/isEmpty';

export const ChoseSportsGrid = ({ data, selectedSports, onSelect, flexWrap }) => {
  return (
    <SportList flexWrap={flexWrap}>
      {data?.map((sport) => {
        const isSelected = selectedSports.includes(sport._id);
        return (
          <SportItemContainer key={sport._id}>
            <SportItemButton isSelected={isSelected} onClick={() => onSelect(sport._id)}>
              {!isEmpty(sport.icon) ? (
                <img src={sport.icon} alt={sport.name} height="30px" width="30px" />
              ) : (
                <IconMedal width="30px" height="30px" />
              )}
            </SportItemButton>
            <SportName>{sport.name}</SportName>
          </SportItemContainer>
        );
      })}
    </SportList>
  );
};

ChoseSportsGrid.propTypes = {
  data: arrayOf(
    shape({
      _id: string,
      name: string
    })
  ).isRequired,
  selectedSports: arrayOf(string),
  onSelect: func,
  flexWrap: string,
  theme: object
};

ChoseSportsGrid.defaultProps = {
  selectedSports: [],
  flexWrap: 'wrap',
  onSelect: () => null
};
