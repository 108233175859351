import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bool, node, string } from 'prop-types';

import { getFollowingData } from 'Store/profileSlice';
import { NavBar } from './NavBar';
import { LeftBar } from './LeftBar';
import { RightBar } from './RightBar';
import {
  LeftContainer,
  CenterContainer,
  // RightContainer,
  MainContainer,
  InnerCenterContainer,
  RightContainer
} from './components';

export const MainNavigation = ({ children, showNav, navTitle }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFollowingData());
  }, []);

  return (
    <MainContainer>
      <LeftContainer>
        <LeftBar />
      </LeftContainer>
      <CenterContainer>
        {showNav && <NavBar title={navTitle} />}
        <InnerCenterContainer>{children}</InnerCenterContainer>
      </CenterContainer>
      <RightContainer>
        <RightBar />
      </RightContainer>
    </MainContainer>
  );
};

MainNavigation.propTypes = {
  children: node.isRequired,
  showNav: bool,
  navTitle: string
};

MainNavigation.defaultProps = {
  showNav: true,
  navTitle: ''
};
