import styled from 'styled-components';
import { BlackText } from '../styledComponents';

// export const Tab = styled(BlackText)`
//   display: flex;
//   justify-content: center;
//   flex-basis: 130px;
//   flex-grow: 1;
//   width: 130px;
//   padding: 10px;
//   cursor: pointer;
//   text-align: center;
//   border-bottom: 4px solid
//     ${({ theme, selected }) => (selected ? theme.lightPrimaryBlue : theme.white)};
// `;

// export const TabHead = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   width: 100%;
//   border-bottom: 1px solid ${({ theme }) => theme.lightGray2};
// `;

// export const TabContent = styled.div``;

export const Tab = styled(BlackText)`
  display: flex;
  justify-content: center;
  flex-basis: 100px;
  flex-grow: 1;
  min-width: 100px;
  padding: 10px;
  cursor: pointer;
  opacity: ${({ selected, opacity }) => (selected ? '1' : opacity)};
  text-align: center;
  border-radius: 10px;
  font-size: 16px;
  background: ${({ background }) => background};
  border: 1px solid
    ${({ theme, selected, activeBorderColor, inactiveBorderColor }) =>
      selected
        ? activeBorderColor
          ? activeBorderColor
          : theme.spoxioAltBlue
        : !selected && inactiveBorderColor
        ? inactiveBorderColor
        : theme.borderShade};
`;

export const TabHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ gap }) => gap || '0 10px'};
`;

export const TabContent = styled.div``;
