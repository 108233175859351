import { toast } from 'react-toastify';
import { setLiveStreamsList, setStoriesList } from './selectors/storiesSelector';

import Api2 from 'middleware/api';
import Api from 'Api';
//import Api from 'Api';

// thunks
export const getStories = () => async (dispatch) => {
  try {
    const stories = await Api2.story.fetchStories();
    const livestreams = await Api.stories.getLivestreams();
    console.log('\n\n\r stories and livestreams\n\n\r');
    console.log({ stories: stories.data.data, livestreams });
    dispatch(setStoriesList([...stories.data.data]));
    dispatch(setLiveStreamsList([...livestreams]));
  } catch (error) {
    toast.error(error);
    // toast.error(error?.toString());
    // toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};
