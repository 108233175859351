import { bool, node, number, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';

import { TitleContainer } from './components';

import { paths } from 'Utils/constants';
import Auth from 'middleware/storage';

import { BaseTitle } from 'Components/shared/styledComponents';
import { BackButton } from 'Components/Layouts/AuthLayout/components';

export const NavTitle = ({ title, showBackButton }) => {
  const dispatch = useDispatch();
  const pathname = useSelector((state) => state?.router?.location?.pathname);
  const onGoBack = async () => {
    if (pathname === paths.SIGN_UP_ENHANCE_INFO) {
      await Auth.destroyToken();
    }
    dispatch(goBack());
  };

  return (
    <>
      {showBackButton ? (
        <TitleContainer>
          <BackButton
            height="12px"
            width="12px"
            onClick={() => {
              onGoBack();
            }}
            mb="0"
          />
          <BaseTitle fontSize="20px">{title}</BaseTitle>
        </TitleContainer>
      ) : (
        <BaseTitle fontSize="20px">{title}</BaseTitle>
      )}
    </>
  );
};

NavTitle.propTypes = {
  title: node.isRequired,
  showBackButton: bool,
  currentStep: number,
  numberOfSteps: number,
  children: node
};

NavTitle.defaultProps = {
  showBackButton: false,
  currentStep: null,
  numberOfSteps: null,
  children: null
};
