import React, { useState } from 'react';
import IndexEvents from 'Components/Event/IndexEvents';
import EventsLayout from 'Components/Layouts/EventsLayout/EventsLayout';

export const Events = () => {
  const [step, setStep] = useState(1);
  return (
    <EventsLayout step={step} setStep={setStep}>
      <IndexEvents setStep={setStep} />
    </EventsLayout>
  );
};
