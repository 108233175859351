import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import english from '../Assets/lang/english.json';
import spanish from '../Assets/lang/spanish.json';
import italian from '../Assets/lang/italian.json';
import french from '../Assets/lang/french.json';
import chinese from '../Assets/lang/chinese.json';
import german from '../Assets/lang/german.json';
import japanese from '../Assets/lang/japanese.json';

const resources = {
  en: english,
  sp: spanish,
  it: italian,
  fr: french,
  jp: japanese,
  ge: german,
  ch: chinese
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // true,
    fallbackLng: 'en',
    resources,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
