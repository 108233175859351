import { BaseText } from 'Components/shared/styledComponents';
import styled from 'styled-components';

export const TrendTitle = styled.h3`
  font-family: ${({ fontWeight }) => (fontWeight === 'bold' ? 'SF Pro Bold' : 'SF Pro')};
  font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.xLarge2)}px;
  color: ${({ theme }) => theme.gray};
  text-align: ${({ textAlign }) => textAlign};
`;

export const MansorySubtitle = styled.span`
  font-family: ${({ fontWeight }) => (fontWeight === 'bold' ? 'SF Pro Bold' : 'SF Pro')};
  font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : '0.7rem')};
  color: ${({ theme }) => theme.gray};
`;

export const SportThumbnail = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '100px'};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;

  &:hover {
    opacity: ${({ hover, opacity }) => (hover ? opacity : 1)};
  }
`;

export const PostTitle = styled(BaseText)`
  color: ${({ theme }) => theme.spoxioText};
  font-size: 0.7rem;
`;
