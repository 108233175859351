import { useContext, useEffect, useState } from 'react';
import { shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { UserPic } from 'Components/shared/UserPic';
import { ContentContainer, Title } from 'Components/shared/styledComponents';
import { followOnUser, unfollowOnUser } from 'Store/profileSlice';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';
import { FollowButtonRevamp } from '../FollowButtonRevamp';
import { getUserById } from 'middleware/api/services';
import Api from 'middleware/api';
import { truncate } from 'Utils/truncate';
import { getCurrentUserId } from 'Store/selectors/authSelector';
import UserInfo from 'Components/OtherProfile/UserInfo';

export const FollowUserPage = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(getCurrentUserId);
  const { theme, themeMode } = useContext(ThemeContext);
  const [userData, setUserData] = useState({});
  const [isFollow, setIsFollow] = useState(false);
  const fieldName = user.follower ? 'follower' : 'following';

  const getUserById = () => {
    Api.user
      .userDetailById(user[fieldName]?._id)
      .then((res) => {
        const { isFollowedByMe } = res.data.data;
        setUserData(res.data.data);
        setIsFollow(isFollowedByMe);
        return res.data.data;
      })
      .catch((errors) => {
        return {};
      });
  };

  useEffect(async () => {
    getUserById();
  }, [user]);

  const onUserClick = () => dispatch(push(`${paths.PROFILE}/${user[fieldName]?._id}`));

  const onPlusClick = () => {
    if (isFollow) {
      dispatch(unfollowOnUser(user[fieldName]?._id));
      setIsFollow(!isFollow);
    } else {
      dispatch(followOnUser(user[fieldName]?._id));
      setIsFollow(!isFollow);
    }
  };

  return (
    <ContentContainer
      row
      gap="12px"
      background={themeMode === 'light' ? theme.background : theme.spoxioIcon2}
      borderRadius="10px"
      padding="12px"
      width="100%"
      justifyContent="space-between"
    >
      <ContentContainer row gap="12px" cursor="pointer" onClick={onUserClick}>
        <UserPic src={user[fieldName]?.picture} size={55} />
        <ContentContainer column gap="0">
          <Title fontSize="1rem" maxWidth="250px">{`${user[fieldName]?.firstName} ${
            user[fieldName]?.lastName || ''
          }`}</Title>

          <UserInfo data={userData} maxWidth="16vw" />
        </ContentContainer>
      </ContentContainer>

      {userId !== user[fieldName]?._id && (
        <FollowButtonRevamp isFollowed={isFollow} onClick={onPlusClick} />
      )}
    </ContentContainer>
  );
};

FollowUserPage.propTypes = {
  user: shape({}).isRequired
};
