import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RouteProps } from 'Utils/propTypes';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { CenterContent } from 'Components/shared/styledComponents';

import { getProfileById } from 'Store/profileSlice';
import { ProfileLayout } from 'Components/Layouts/ProfileLayout';
import { Profile } from 'Components/ProfileRevamp';

export const ProfilePage = ({ location }) => {
  const profile = useSelector(getProfileSelector);
  const dispatch = useDispatch();

  const path = location.pathname.split('/');
  const profileId = path[2];
  const tabName = path[3];

  useEffect(() => {
    dispatch(getProfileById(profileId));
  }, [profileId]);

  return (
    <ProfileLayout>
      <CenterContent padding="0">
        <Profile profile={profile} tabName={tabName} />
      </CenterContent>
    </ProfileLayout>
  );
};

ProfilePage.propTypes = RouteProps;
