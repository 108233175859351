import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { setSearch } from 'Store/selectors/discoverySelector';
import { NavContainer } from './components';
import { Search } from './Search';
import { NavTitle } from './NavTitle';
import { bool, func, node, string } from 'prop-types';
import { MainLogo } from 'Components/shared/Logos/MainLogo';
import { ActionsBar } from './ActionsBar';
import { PostModal } from 'Components/PostModal';
import { useState } from 'react';
import { ContentContainer, Divider } from 'Components/shared/styledComponents';
import SpoxioActionBar from './SpoxioActionBar';

export const NavBar = ({ title, showBackButton, showActionNav, handleSearch }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);

  const closeModal = () => setModalIsOpen(false);
  const query = useSelector((state) => state?.router.location.query);
  const dispatch = useDispatch();

  const searchHandle = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      dispatch(setSearch(null));
      dispatch(push(`${paths.DISCOVERY}?search=${e.target.value}`));
    }
  };

  return (
    <>
      {!showActionNav ? (
        <NavContainer id="nav-bar">
          <NavTitle title={title} showBackButton={showBackButton} />
          <Search defaultValue={query?.search} searchHandle={handleSearch || searchHandle} />
        </NavContainer>
      ) : (
        <NavContainer id="nav-bar" background maxWidth="100%" gap="4px">
          <ContentContainer row alignItems="center" gap="8px" padding="0 0 0 10px">
            <MainLogo marginBottom="8px" /> <Divider height="5vh" padding="2px" />
            <NavTitle title={title} showBackButton={showBackButton} />
          </ContentContainer>
          <ContentContainer width="35%">
            <Search
              defaultValue={query?.search}
              searchHandle={handleSearch || searchHandle}
              width="100%"
            />
          </ContentContainer>
          <SpoxioActionBar />
          {/* <ActionsBar openModal={openModal} /> */}
          <PostModal closeModal={closeModal} isOpen={modalIsOpen} />
        </NavContainer>
      )}
    </>
  );
};

NavBar.propTypes = {
  title: node,
  showBackButton: bool,
  showActionNav: bool,
  handleSearch: func
};

NavBar.defaultProps = {
  title: '',
  showActionNav: false,
  handleSearch: null
};
