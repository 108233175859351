import { bool, func, string } from 'prop-types';
import { ActionButton } from 'Components/shared/styledComponents';
import { ReactComponent as CheckedIcon } from 'Assets/icons/Check.svg';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';
import { useContext, useEffect } from 'react';

export const FollowButtonRevamp = ({ isFollowed, className, onClick, background }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  useEffect(() => {}, [isFollowed]);

  return isFollowed ? (
    <ActionButton
      minWidth="6vw"
      color={theme.defaultWhite}
      background={background || theme.spoxioAltLinear}
      className={className}
      borderRadius="8px"
      margin="0"
      onClick={onClick}
    >
      {t('following')}
    </ActionButton>
  ) : (
    <ActionButton
      color={theme.spoxioText}
      background={theme.background}
      borderRadius="8px"
      margin="0"
      minWidth="6vw"
      className={className}
      onClick={onClick}
    >
      {t('follow')}
    </ActionButton>
  );
};

FollowButtonRevamp.propTypes = {
  isFollowed: bool,
  className: string,
  onClick: func,
  background: string
};

FollowButtonRevamp.defaultProps = {
  isFollowed: false,
  className: '',
  onClick: () => {}
};
