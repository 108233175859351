import { arrayOf, object, shape } from 'prop-types';
import { FollowUser } from 'Components/shared/FollowUser';
import { FollowUserPage } from 'Components/shared/FollowUserPageRevamp';
import { ContentContainer } from 'Components/shared/styledComponents';
import Api from 'middleware/api';

export const FollowList = ({ userList }) => {
  if (!userList?.length) {
    return null;
  }

  return (
    <ContentContainer column width="100%" gap="12px" margin="20px 0">
      {userList.map((user) => {
        return user.following || user.follower ? (
          <FollowUserPage key={user._id} user={user} />
        ) : (
          <FollowUser key={user._id} user={user} />
        );
      })}
    </ContentContainer>
  );
};

FollowList.propTypes = {
  userList: arrayOf(shape({}))
};

FollowList.defaultProps = {
  userList: []
};
