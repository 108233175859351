import Players from 'Assets/img/players-two-card.png';
import SlamDunk from 'Assets/img/slam-dunk.png';
import ThirdSlide from 'Assets/img/third-slide.png';
import NewSticker from 'Assets/icons/New_Sticker.svg';
import { any, bool, object, string } from 'prop-types';
import { ImageWrapper, SliderWrapper } from './component';
import { DotGroup } from 'pure-react-carousel';
import { SubTitle } from 'Components/Layouts/AuthLayout/components';
import { ContentContainer, Title } from 'Components/shared/styledComponents';
import { useContext } from 'react';
import { ThemeContext } from 'Context/themeContext';
import roleBg from 'Assets/img/role-bg-overlay2.png';
import styled from 'styled-components';

const CarouselBackground = styled(ContentContainer)`
  background-image: url(${roleBg});
  background-clip: border-box;
  background-repeat: no-repeat;
  background-position: 30%;
  background-size: contain;
  object-fit: cover;
`;

export const SliderContent = ({ players, midSticker, slideText }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <CarouselBackground width="100%" justifyContent="center" column height="65vh">
      <ContentContainer height="80%" width="100%">
        <img
          src={players || Players}
          alt="Super Athlete"
          width="100%"
          height="100%"
          style={{ objectFit: 'contain' }}
        />
      </ContentContainer>

      {midSticker && (
        <div
          style={{
            margin: '0 auto',
            backgroundColor: 'black',
            borderRadius: '50%',
            width: 'max-content'
          }}
        >
          <img
            style={{
              padding: '0px',
              marginTop: '2px'
            }}
            src={NewSticker}
            alt="new"
          />
        </div>
      )}

      <ContentContainer justifyContent="center" alignItems="center" height="15%">
        <Title
          color={theme?.black}
          style={{
            marginTop: '0.875rem',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: '700'
          }}
        >
          {slideText}
        </Title>
        <DotGroup />
      </ContentContainer>
    </CarouselBackground>
  );
};

SliderContent.propTypes = {
  // label: string.isRequired,
  // onClick: func.isRequired,
  slideText: any,
  midSticker: bool,
  players: string.isOptional
};

export const Slider1 = () => {
  return (
    <SliderWrapper>
      {/* Image */}
      <ImageWrapper>
        <img src={Players} alt="Super Geremiah" width={'100%'} />
      </ImageWrapper>
      {/* Sticker if included and carousel text */}

      {/* sticker if index is 0 */}
      <div
        style={{
          margin: '0 auto',
          backgroundColor: 'black',
          borderRadius: '50%',
          // width: 'max-content'
          maxHeight: '40vh',
          width: '50%'
        }}
      >
        <img
          style={{
            padding: '0px',
            marginTop: '2px'
          }}
          src={NewSticker}
          alt="new"
        />
      </div>

      <div style={{ color: '#fff' }}>
        {/* Text for Each Carousel material */}
        <Title
          style={{
            marginTop: '0.875rem',
            color: '#fff',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: '700'
          }}
        >
          Swipe to see talent
          <br /> photo or video
        </Title>
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <DotGroup></DotGroup>
      </div>
    </SliderWrapper>
  );
};

export const Slider2 = () => {
  return (
    <SliderWrapper>
      {/* Image */}
      <ImageWrapper>
        <img src={SlamDunk} alt="people jumping" width={'100%'} />
      </ImageWrapper>
      {/* Sticker if included and carousel text */}
      <div style={{ color: '#fff' }}>
        {/* Text for Each Carousel material */}
        <Title
          style={{
            marginTop: '0.875rem',
            color: '#fff',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: '700'
          }}
        >
          Access exclusive stats and talent rankings
        </Title>
      </div>
    </SliderWrapper>
  );
};

export const Slider3 = () => {
  return (
    <SliderWrapper>
      {/* Image */}
      <ImageWrapper>
        <img src={ThirdSlide} alt="people jumping" width={'100%'} />
      </ImageWrapper>

      <div style={{ color: 'black' }}>
        <SubTitle
          style={{
            marginTop: '0.875rem',
            color: '#fff',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: '700'
          }}
        >
          Contact talent directly or in bulk messages
        </SubTitle>
      </div>
    </SliderWrapper>
  );
};
