import { AboutTags } from 'Components/OtherProfile/components';
import {
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { func } from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormData, setFormData } from 'Store/selectors/dataSelector';
import { ReactComponent as Share } from 'Assets/icons/ShareRevamp.svg';

import EventItem from './EventItem';

const EventSucessPage = ({ setStep }) => {
  const dispatch = useDispatch();
  const formData = useSelector(getFormData);
  const { theme } = useContext(ThemeContext);

  console.log(formData, 'formData sucess');

  return (
    <ContentContainer
      column
      gap="25px"
      alignItems="center"
      height="100%"
      padding="25px"
      // justifyContent="space-between"
    >
      <Title
        fontSize="1.75rem"
        color="#76777E"
        // color={theme?.gray}
        fontWeight="700"
        letterSpacing="0.01em"
        lineHeight="2rem"
        textAlign="center"
        style={{ fontFamily: 'SF Pro' }}
      >
        Event Created!
      </Title>

      <ContentContainer>
        <Title
          fontSize="1.25rem"
          textAlign="center"
          style={{ fontFamily: 'SF Pro' }}
          color={theme?.black}
          margin="30px 0 15px"
        >
          Pending Approval
        </Title>
        <ContentContainer gap="25px 0" alignItems="center">
          <SubTitle
            // color={theme?.gray}
            fontSize="0.875rem"
            lineHeight="1.25rem"
            letterSpacing="-0.52px"
            textAlign="center"
            weight={'400'}
          >
            Just one Last Steps
            <br />
            Your event has been submitted for review, once this event is approved it'll be published
          </SubTitle>
          <ContentContainer
            row
            // flexWrap="wrap"
            justifyContent="center"
            style={{ columnGap: '4px' }}
          >
            <Title fontSize="1rem">Your confirmation may take</Title>
            <Title fontSize="1rem" color={theme.lightGreen}>
              5hrs - 24hrs
            </Title>
          </ContentContainer>
        </ContentContainer>
        <ContentContainer margin="5vh 0" alignItems="center">
          <EventItem event={formData} />
          <ContentContainer row gap="10px" width="100%" margin="25px 0">
            <SpoxioBlueBtn
              border={theme?.spoxioAltBlue}
              background={theme?.spoxioAltBlue}
              marginTop="0"
              padding="0.875rem 2.5rem"
              fontSize="1rem"
              width="60%"
            >
              Edit Event
            </SpoxioBlueBtn>
            <SpoxioBlueBtn
              fontWeight="600"
              border={theme?.spoxioAltBlue}
              color={theme?.spoxioText}
              background="transparent"
              marginTop="0"
              fontSize="1rem"
              width="35%"
              padding="10px"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Title fontSize="1rem"> Share</Title>
              <IconContainer height="20px" width="20px" color={theme?.mediumGray}>
                <Share />
              </IconContainer>
            </SpoxioBlueBtn>
          </ContentContainer>
        </ContentContainer>
      </ContentContainer>
      <SpoxioBlueBtn
        border={theme?.spoxioText}
        background="transparent"
        marginTop="auto"
        fontSize="1rem"
        color={theme?.spoxioText}
        onClick={() => {
          setStep(1);
          dispatch(setFormData({}));
        }}
      >
        Close
      </SpoxioBlueBtn>
    </ContentContainer>
  );
};

EventSucessPage.propTypes = {
  setStep: func
};

export default EventSucessPage;
