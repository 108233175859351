import { useEffect } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  getSearchSelector,
  getSearchDataSelector,
  setSearch,
  setDiscoveryList,
  setSearchData
} from 'Store/selectors/discoverySelector';
import { getIsLoading } from 'Store/loaderSlice';
import { ResultUsersTopics } from './ResultUsersTopics';
import { ResultPosts } from './ResultPosts';
import { Loader } from 'Components/shared/Loader';
import { ResultsContainer } from './components';

export const Results = ({ searchString }) => {
  const isLoading = useSelector(getIsLoading);
  const search = useSelector(getSearchSelector);
  const { users, interests } = useSelector(getSearchDataSelector);
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(setSearch(null));
      dispatch(setDiscoveryList([]));
      dispatch(setSearchData({}));
    },
    []
  );

  return (
    <ResultsContainer>
      {isLoading ? (
        <Loader size={24} />
      ) : searchString ? (
        <ResultUsersTopics
          users={users}
          //topics={topics}
          searchString={searchString}
          //onTopicClick={onTopicClick}
        />
      ) : (
        <ResultPosts postList={interests} search={search} />
      )}
    </ResultsContainer>
  );
};

Results.propTypes = {
  postList: arrayOf(shape({})),
  searchString: string
};

Results.defaultProps = {
  postList: [],
  searchString: ''
};
