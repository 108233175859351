import Modal from 'react-modal';
import { bool, func, node, string } from 'prop-types';

import { Close, Head, Left, ModalTitle } from './components';

import { colors } from 'Theme';
import { useContext } from 'react';
import { ThemeContext } from 'Context/themeContext';

const modalStyle = {
  overlay: {
    backgroundColor: colors.opacityDarkBlue,
    zIndex: 1000
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    // height: 600,
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    padding: 30,
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    border: 'none',
    overflow: 'hidden',
    position: 'relative'
  }
};

export const ModalWindow = ({ isOpen, closeModal, children, leftComponent, title }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Modal isOpen={isOpen} ariaHideApp={false} style={modalStyle}>
      <Head>
        <Left>{leftComponent}</Left>
        <ModalTitle fontWeight="bold" color={theme?.defaultBlack}>
          {title}
        </ModalTitle>
        <Close onClick={closeModal} color={colors.secondaryGray} />
      </Head>
      {children}
    </Modal>
  );
};

ModalWindow.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
  children: node.isRequired,
  leftComponent: node,
  title: string
};
