import { string } from 'prop-types';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';

import { authSocial } from 'Store/authSlice';
import { CenteredContainer, RowContainer } from 'Components/shared/styledComponents';
import { AppleIconContainer, SocialButton, Title } from './components';

import { ReactComponent as AppleIcon } from 'Assets/icons/Apple.svg';
import { ReactComponent as FacebookIcon } from 'Assets/icons/Facebook.svg';
import { ReactComponent as GoogleIcon } from 'Assets/icons/Google.svg';

import { Divider } from 'antd';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';
// import { useTranslation } from 'react-i18next';

export const SocialAuth = ({ title, marginTop }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  // const { t } = useTranslation();

  const authApple = () => {
    const appleProvider = new firebase.auth.OAuthProvider('apple.com');

    appleProvider.addScope('email');
    appleProvider.addScope('name');
    dispatch(authSocial(appleProvider, 'apple'));
  };

  const authFacebook = () => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider();

    dispatch(authSocial(facebookProvider, 'facebook'));
  };

  const authGoogle = () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();

    dispatch(authSocial(googleProvider, 'google'));
  };

  return (
    <CenteredContainer
      style={{ width: '100%' }}
      marginTop={marginTop}
      className=" margin-bottom_small"
    >
      <Title>
        <Divider plain={{ width: '100%' }}>{title} </Divider>
      </Title>

      <RowContainer>
        <SocialButton onClick={authApple}>
          <AppleIconContainer>
            <AppleIcon color={theme.black} fill={theme.black} stroke={theme.spoxioText} />
          </AppleIconContainer>
        </SocialButton>
        <SocialButton onClick={authFacebook}>
          <FacebookIcon color={theme.black} fill={theme.black} stroke={theme.spoxioText} />
        </SocialButton>
        <SocialButton onClick={authGoogle}>
          <GoogleIcon color={theme.black} fill={theme.black} stroke={theme.spoxioText} />
        </SocialButton>
      </RowContainer>
    </CenteredContainer>
  );
};

SocialAuth.propTypes = {
  title: string,
  marginTop: string
};

SocialAuth.defaultProps = {
  title: 'Or continue with',
  marginTop: '50px'
};
