import EventsPics from 'Assets/img/event-calendar.png';
import FallbackSrc from 'Assets/img/event-placeholder4.jpeg';

export const imageData = {
  EventsPics: EventsPics,
  FallbackSrc: FallbackSrc
};

export const paths = {
  HOME: '/',
  ABOUT: '/about',
  PRIVACY: '/privacy',
  TERMS_OF_CONDITIONS: '/terms-conditions',
  USERONBOARDING: '/onboarding',
  SIGN_UP: '/sign-up',
  SIGN_UP_GENERAL_INFO: '/sign-up/general-info',
  SIGN_UP_ROLE: '/sign-up/role',
  SIGN_UP_ENHANCE_INFO: '/sign-up/enhance-info',
  SIGN_UP_SPORTS: '/sign-up/sports',
  SIGN_UP_PAYMENTS: '/sign-up/payments',
  SIGN_IN: '/sign-in',
  TIMELINE: '/timeline',
  POST_DETAIL: '/post-detail',
  REPORT_POST: '/report-post',
  FORGOT_PASSWORD: '/forgot-password',
  CHAT: '/chat',
  DISCOVERY: '/discovery',
  RESULT: '/result',
  PROFILE: '/profile',
  STATS: '/stats',
  STORIES: '/stories',
  NOTIFICATION: '/notification',
  MOBILE_SCREEN: '/mobile-screen',
  EVENTS: '/events',
  LIVEEVENT: '/event/live/:id',
  OTHERROFILE: '/user/:id',
  SCOUT_ACTIVITIES: '/scout-activities',
  SCOUT_PAYMENTS: '/subscription/payments',
  SCOUT_SUBSCRIPTION: '/subscription',
  VIEW_TRENDING_EVENTS: '/events/trending/view-all',
  VIEW_PARTICIPATING_EVENTS: '/events/participating/view-all',
  CLUB_INVITE: '/profiles/club-invitation'
};

export const genderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' }
];

export const userRoles = {
  SCOUT: 'scout',
  TALENT: 'talent',
  FAN: 'fan',
  CLUB: 'club',
  CLUB_MEMBER: 'club_member',
  DEFAULT: 'default'
};

export const notificationTypes = {
  COMMENT: 'comment',
  FOLLOW: 'follow',
  LIKE: 'like',
  LIVE: 'live',
  MESSAGE: 'message'
};

export const SCOUT_MESSAGE =
  'Hi, I saw your profile and I’m interested in knowing more about you. Would you like to have a call and discuss?';

export const TRIM_LIMIT = 35;

export const POSTS_LIMIT = 15;

export const MY_DA_ID = '73abe58e-b40c-1b49-4fa3-0a7f5c90ee18';

export const MY_DA_SECRET_KEY = 'e97356ad00499e586231d098b383d197f91c7e72';

export const googleTranslateKey = 'AIzaSyBDaLqr-TLpFIIlGN39VO1iB0cmSWMrFbM';

export const STRIPE_KEY =
  'pk_live_51I8xY3JDn79qmktLSg9UoqFYQpxy2yoqqvYsGkhjumccoZYmULuDpAId4UieaAcFVK6x9Pn31u7QlyiFnsaIm2XD00kr6ckERH';

// export const EVENT_BASE_URL = 'https://spoxio-event-service.herokuapp.com/v1';
export const EVENT_BASE_URL = 'https://api.spoxio.com/event-service/v1'; //production

// export const BASE_URL = 'https://staging-api-spoxio.herokuapp.com'; //old staging api
// export const BASE_URL = 'https://api.staging.spoxio.com';
export const BASE_URL = 'https://api.spoxio.com';
