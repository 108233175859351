import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { resetPassword } from 'Store/authSlice';
import { AuthContent, AuthFormContainer, AuthLink, LinkDescription } from '../components';
import { AuthContainer } from 'Components/AuthContainer';
import { BaseButton, BlackText } from 'Components/shared/styledComponents';
import { FormInput } from 'Components/shared/Inputs/FormInput';

import { ReactComponent as MailIcon } from 'Assets/icons/Mail.svg';

import { colors } from 'Theme';
import { emailRules } from 'Utils/validations';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'Components/Layouts/AuthLayout';
// import { Title } from 'Components/AuthContainer/components';
import settings from 'Assets/img/reset_setting.png';
import email from 'Assets/img/reset_email.png';
import { SpoxioBlueBtn, Title } from 'Components/Layouts/AuthLayout/components';
import { paths } from 'Utils/constants';

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const [showConfirmed, setShowConfirmed] = useState(false);

  const dispatch = useDispatch();

  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (data) => {
    dispatch(resetPassword(data));
    setShowConfirmed(true);
  };

  const goBack = () => {
    setShowConfirmed(false);
  };

  return (
    <AuthLayout
      title={
        <Title fontSize="1.75rem" fontWeight="bold">
          Reset Your Password
        </Title>
      }
      substep={showConfirmed}
      customGoBack={goBack}
      showBackButton
    >
      {/* {!showConfirmed ? (
        <>
          <BlackText textAlign="center" className="margin-top_medium">
            {t('forgot_text1')} Spoxio {t('forgot_text2')}
          </BlackText>
          <AuthFormContainer className="margin-top_medium" onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              ref={register(emailRules)}
              name="email"
              placeholder={t('email')}
              error={errors?.email?.message}
              className="margin-bottom_medium"
            />
            <BaseButton>{t('reset')}</BaseButton>
          </AuthFormContainer>
        </>
      ) : (
        <AuthContent>
          <MailIcon width={50} height={40} color={colors.gray} className="margin-top_medium" />
          <BlackText textAlign="center" className="margin-top_small">
            {t('check_mail')}
          </BlackText>
        </AuthContent>
      )} */}
      {/*  */}
      {!showConfirmed ? (
        <>
          <BlackText
            textAlign="left"
            className="margin-top_small"
            opacity={0.5}
            width="100%"
            fontSize="14"
          >
            {t('forgot_text1')} <br /> Spoxio {t('forgot_text2')}
          </BlackText>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <img src={settings} alt="" width="200px" height="200px" />
          </div>
          <AuthFormContainer marginTop="1rem" onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              ref={register(emailRules)}
              name="email"
              placeholder={t('email')}
              error={errors?.email?.message}
              className="margin-bottom_small"
            />
            <SpoxioBlueBtn marginTop={'0'}>{t('confirm')}</SpoxioBlueBtn>
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                margin: 0,
                left: 0,
                right: 0,
                textAlign: 'center',
                width: '100%'
              }}
            >
              <span style={{ marginTop: '10px' }}>
                <LinkDescription>Don't have an account?</LinkDescription>{' '}
                <AuthLink textweight={'normal'} to={paths.SIGN_UP} color="#007AFF">
                  SIGN UP
                </AuthLink>
              </span>
            </div>
          </AuthFormContainer>
        </>
      ) : (
        <AuthContent>
          <BlackText textAlign="left" opacity="50%">
            {t('check_mail')}
          </BlackText>
          {/* <MailIcon width={50} height={40} color={theme?.gray} className="margin-top_medium" /> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '270px',
              height: '270px'
            }}
          >
            <img src={email} alt="" width="100%" height="100%" />
          </div>
          <a href="mailto:" style={{ width: '100%' }}>
            <SpoxioBlueBtn marginTop={'0'}>Open email app</SpoxioBlueBtn>
          </a>
        </AuthContent>
      )}
    </AuthLayout>
  );
};
