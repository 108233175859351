import { arrayOf, shape } from 'prop-types';

import { PostsList } from 'Components/PostsList';
import { ResultHeader } from 'Components/ResultHeader';
import { Content } from './components';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { useTranslation } from 'react-i18next';

export const ResultPosts = ({ postList, search }) => {
  const { t } = useTranslation();
  return postList?.length ? (
    <Content>
      <ResultHeader search={search} />
      <PostsList postList={postList} showStories={false} />
    </Content>
  ) : (
    <EmptyContent description={t('no_data_with')} title={search?.data?.name} />
  );
};

ResultPosts.propTypes = {
  postList: arrayOf(shape({})),
  search: shape({})
};

ResultPosts.defaultProps = {
  postList: [],
  search: {}
};
