import { BaseText, RowContainer } from 'Components/shared/styledComponents';
import styled from 'styled-components';

export const MainPost = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const LeftSide = styled.div`
  max-width: 70%;
  width: 70%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.background};
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30%;
  width: 30%;
  padding-bottom: 20px;
  background: ${({ theme }) => theme.background};
`;

export const ActionBarContainer = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.lightGray2};
`;

export const PostData = styled.div`
  padding: 10px;
  overflow-x: scroll;
  max-height: calc(100vh - 140px);
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CommentContainer = styled.div`
  margin-top: auto;
  margin-right: 30px;
  margin-left: 30px;
`;

export const LogoContainer = styled.span`
  position: absolute;
  top: 12px;
  left: 30px;
  z-index: 1;
  min-width: 40px;
  min-height: 40px;
`;

export const CloseContainer = styled.span`
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;

  & svg {
    fill: ${({ fill }) => fill};
    stroke: ${({ stroke }) => stroke};

    path {
      fill: ${({ fill }) => fill};
      stroke: ${({ stroke }) => stroke};
    }
  }
`;

export const LivestreamStatisticContainer = styled(RowContainer)`
  width: 100%;
  margin: 15px 0 5px;
  padding: 0 20px;

  & > span {
    margin-right: 20px;

    :last-child {
      margin: 0;
    }
  }
`;

export const LivestreamStatisticText = styled(BaseText)`
  color: ${({ theme }) => theme.opacityGray};
  font-size: ${({ theme }) => theme.xSmall}px;
`;

export const MainPostMobilr = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LeftSideMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.black};
  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const RightSideMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
  background: ${({ theme }) => theme.black};
`;
