/* eslint-disable eqeqeq */
import React, { useContext, useRef } from 'react';
import {
  BarContainer,
  BarDivider,
  CreateEventIcon,
  CreateEventWrapper,
  NextButton
} from './components';
import { ReactComponent as CreateEventVector } from 'Assets/icons/VectorCreateEvent.svg';
import { ReactComponent as Flag } from 'Assets/icons/Flag.svg';
import { ReactComponent as MoreIconHamburger } from 'Assets/icons/MoreIconHamburger.svg';
import {
  Container,
  ContentContainer,
  IconContainer,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { BackButton } from 'Components/Layouts/AuthLayout/components';
import { array, func } from 'prop-types';
import getSportIcon from 'Utils/getSportIcon';
import isEmpty from 'Utils/isEmpty';

const CreateEventsBar = ({
  handleCategory = () => {
    return null;
  },
  category,
  setStep
}) => {
  const { theme } = useContext(ThemeContext);
  const scrollRef = useRef();
  const scrollRight = (scrollOffset) => {
    if (!scrollOffset.current) {
      return;
    }
    scrollRef.current.scrollRight += scrollOffset;
  };

  return (
    <ContentContainer
      row
      flexWrap="nowrap"
      gap="15px"
      minHeight="120px"
      width="100%"
      alignItems="center"
      background={theme.spoxioBg}
      border={theme.borderShade}
      borderRadius="20px"
      padding="10px 0 10px 20px"
      overflowX="auto"
      hideScroll
    >
      <ContentContainer
        column
        alignItems="center"
        cursor="pointer"
        onClick={() => {
          setStep(2);
        }}
      >
        <CreateEventIcon width="60px" height="60px">
          <CreateEventVector />
        </CreateEventIcon>
        <ContentContainer column minHeight="25px" hover>
          <Title
            color={theme.spoxioText}
            fontSize="0.75rem"
            textAlign="center"
            lineHeight="normal"
            opacity="0.8"
          >
            Create Events
          </Title>
        </ContentContainer>
      </ContentContainer>

      <BarDivider />

      <ContentContainer
        row
        gap="15px"
        overflowX="scroll"
        ref={scrollRef}
        width="100%"
        flexWrap="nowrap"
        hideScroll
      >
        {category?.map((_event) => {
          // console.log(_event, 'the event\n\n\r');
          const iconData = getSportIcon(_event?.name);
          const Icon = iconData?.icon;

          if (_event.event_count == '0') {
            return null;
            // return (
            //   <ContentContainer
            //     column
            //     justifyContent="center"
            //     key={_event.tag}
            //     maxWidth="60px"
            //     alignItems="center"
            //     cursor="not-allowed"
            //     hover
            //     // onClick={() => handleCategory(_event?.tag)}
            //   >
            //     <CreateEventIcon type="sub" background={theme?.body}>
            //       <IconContainer margin="0" width="25px" height="25px" fill={theme?.mediumGray}>
            //         {!isEmpty(iconData?.icon) ? (
            //           <Icon style={{ cursor: 'not-allowed' }} />
            //         ) : (
            //           <Flag
            //             fill={theme?.mediumGray}
            //             stroke={theme?.mediumGray}
            //             style={{ cursor: 'not-allowed' }}
            //           />
            //         )}
            //       </IconContainer>
            //     </CreateEventIcon>

            //     <ContentContainer column minHeight="25px">
            //       <Title
            //         color={theme.spoxioText}
            //         fontSize="0.75rem"
            //         textAlign="center"
            //         lineHeight="normal"
            //         opacity="0.8"
            //       >
            //         {_event.name}
            //       </Title>
            //     </ContentContainer>
            //   </ContentContainer>
            // );
          }

          return (
            <ContentContainer
              column
              justifyContent="center"
              key={_event.tag}
              maxWidth="55px"
              alignItems="center"
              cursor="pointer"
              hover
              onClick={() => handleCategory(_event?.tag)}
            >
              <CreateEventIcon
                type="sub"
                background={iconData?.background}
                width="55px"
                height="55px"
              >
                <IconContainer margin="0" width="25px" height="25px" fill={iconData?.color}>
                  {!isEmpty(iconData?.icon) ? (
                    <Icon />
                  ) : (
                    <Flag fill={iconData?.color} stroke={iconData?.color} />
                  )}
                </IconContainer>
              </CreateEventIcon>

              <ContentContainer column minHeight="25px">
                <Title
                  color={theme.spoxioText}
                  fontSize="0.75rem"
                  textAlign="center"
                  lineHeight="normal"
                  opacity="0.8"
                >
                  {_event.name}
                </Title>
              </ContentContainer>
            </ContentContainer>
          );
        })}
        <ContentContainer
          column
          justifyContent="center"
          maxWidth="45px"
          alignItems="center"
          cursor="pointer"
          hover
          onClick={() => handleCategory('')}
        >
          <CreateEventIcon type="sub" background="#E7EBEF" width="45px" height="45px">
            <IconContainer margin="0" width="20px" height="20px">
              <MoreIconHamburger />
            </IconContainer>
          </CreateEventIcon>
          <ContentContainer column minHeight="25px">
            <Title
              color={theme.spoxioText}
              fontSize="0.75rem"
              textAlign="center"
              lineHeight="normal"
              opacity="0.8"
            >
              All
            </Title>
          </ContentContainer>
        </ContentContainer>
      </ContentContainer>
      {/* {!isEmpty(category) && category?.length > 12 && (
        <IconContainer
          width="18px"
          height="18px"
          style={{ position: 'absolute', top: '40%', right: '0px' }}
          onClick={() => {
            scrollRight(20);
            // console.log(scrollRight(20));
            // console.log('\n\n\r i am here \n');
          }}
        >
          <BackButton right mb="0" />
        </IconContainer>
      )} */}
    </ContentContainer>
  );
};

CreateEventsBar.propTypes = {
  handleCategory: func,
  category: array,
  setStep: func
};

CreateEventsBar.defaultProps = {
  handleCategory: () => {},
  category: [],
  setStep: null
};

export default CreateEventsBar;
