import { bool } from 'prop-types';
import styled from 'styled-components';

import { BaseText } from 'Components/shared/styledComponents';

export const HelperText = styled(BaseText)`
  margin-top: 10px;
  font-size: ${({ theme }) => theme.small}px;
  color: ${({ theme }) => theme.secondaryGray};
`;

export const ErrorText = styled(BaseText)`
  min-height: 13px;
  margin-top: 6px;
  font-size: ${({ theme }) => theme.small}px;
  color: ${({ theme }) => theme.red};
  /* visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')}; */
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
`;

ErrorText.propTypes = {
  isShow: bool
};

ErrorText.defaultProps = {
  isShow: false
};

export const FormSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ background }) => background};
`;

export const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: ${({ mb }) => mb || '20px'};
`;

export const ShowPasswordButton = styled.div`
  width: 20px;
  height: 15px;
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
`;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox'
})`
  border: 0;
  height: 26px;
  width: 26px;
  position: absolute;
  white-space: nowrap;
  visibility: hidden;
`;

export const StyledCheckbox = styled.div`
  transition: all 150ms;
`;

export const CheckboxLabel = styled(BaseText)`
  color: ${({ theme }) => theme.black};
`;

export const CheckboxIconContainer = styled.div`
  margin-right: 10px;
`;

export const InputLabel = styled.div`
  font-size: 14px;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.opacityGray};
`;

export const UploadInputLabel = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.opacityGray};
  margin-left: 10px;
`;

export const UploadInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const UploadContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const UploadInputImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100px;
`;
