import { string } from 'prop-types';
import { useEffect } from 'react';

import { PostMediaContainer } from './components';

export const LivestreamPlayer = ({ resourceId }) => {
  useEffect(() => {
    localStorage.setItem('resourceId', resourceId);
  }, [resourceId]);

  return resourceId ? (
    <PostMediaContainer>
      <iframe
        title="livestream-video"
        style={{ width: '100%', height: '100vh', border: 'none' }}
        src="../livestream/index.html"
      />
    </PostMediaContainer>
  ) : null;
};

LivestreamPlayer.propTypes = {
  resourceId: string
};

LivestreamPlayer.defaultProps = {
  resourceId: ''
};
