import image1 from 'Assets/fixtures/masonry1.png';
import image2 from 'Assets/fixtures/masonry2.png';
import image3 from 'Assets/fixtures/masonry3.png';
import image4 from 'Assets/fixtures/masonry4.png';
import image5 from 'Assets/fixtures/masonry5.png';
import image6 from 'Assets/fixtures/masonry6.png';
import image7 from 'Assets/fixtures/masonry7.png';
import image8 from 'Assets/fixtures/masonry8.png';
import image9 from 'Assets/fixtures/masonry9.png';
import image10 from 'Assets/fixtures/masonry10.png';
import trending1 from 'Assets/fixtures/TrendingFootball1.png';
import trending2 from 'Assets/fixtures/TrendingFootball2.png';
import trending3 from 'Assets/fixtures/TrendingFootball3.png';
import trending4 from 'Assets/fixtures/TrendingFootball4.png';
import trending5 from 'Assets/fixtures/TrendingFootball5.png';
import trending6 from 'Assets/fixtures/TrendingFootball6.png';
import trending7 from 'Assets/fixtures/TrendingFootball7.png';

export const MASONRY_FIXTURES = {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10
};

export const TRENDING_SPORTS = {
  trending1,
  trending2,
  trending3,
  trending4,
  trending5,
  trending6,
  trending7
};
