import { useDispatch } from 'react-redux';
import { shape } from 'prop-types';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { useModal } from 'Hooks/useModal';
import { UserPic } from 'Components/shared/UserPic';
import { MenuContainer, MenuItem, UserPicContainer, UserMenuContent, ArrowUp } from './components';

import { colors } from 'Theme';
import { logout } from 'Store/authSlice';
import { getProfile } from 'Store/profileSlice';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';

export const UserMenu = ({ user }) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();

  const onViewProfileClick = () => {
    dispatch(push(`${paths.PROFILE}/${user.id}`));
    dispatch(getProfile());
    closeModal();
  };

  const onEditProfileClick = () => {
    dispatch(push(`${paths.PROFILE}/${user.id}/edit`));
    closeModal();
  };

  const onSportClick = () => {
    dispatch(push(`${paths.PROFILE}/${user.id}/sports`));
    closeModal();
  };

  const onChangePasswordClick = () => {
    dispatch(push(`${paths.PROFILE}/${user.id}/change_password`));
    closeModal();
  };

  const onLogout = () => {
    //dispatch(logoutUser());
    dispatch(logout());
    closeModal();
  };

  return (
    <>
      <UserPicContainer onClick={openModal}>
        <UserPic src={user?.picture} color={colors.darkGray1} />
      </UserPicContainer>
      <MenuContainer isOpen={isModalOpen} position={{ right: 10 }}>
        <ArrowUp position={{ right: 28 }} />
        <UserMenuContent>
          <MenuItem onClick={onViewProfileClick}>{t('view_profile')}</MenuItem>
          <MenuItem onClick={onEditProfileClick}>{t('edit_profile')}</MenuItem>
          <MenuItem onClick={onSportClick}>{t('sports_i_follow')}</MenuItem>
          <MenuItem onClick={onChangePasswordClick}>{t('change_password')}</MenuItem>
          <MenuItem onClick={onLogout}>{t('logout')}</MenuItem>
        </UserMenuContent>
      </MenuContainer>
    </>
  );
};

UserMenu.propTypes = {
  user: shape({})
};

UserMenu.defaultProps = {
  user: null
};
