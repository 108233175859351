import { AboutTags } from 'Components/OtherProfile/components';
import React, { useContext } from 'react';
import { FormComponentData } from 'Utils/data';
import { EventCheckbox, EventTextArea, InputForm } from './components';
import { useForm } from 'react-hook-form';
import { FormCheckbox } from 'Components/shared/Inputs/FormCheckbox';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { ContentContainer, SpoxioBlueBtn, Title } from 'Components/shared/styledComponents';
import { useTranslation } from 'react-i18next';
import { requiredRule } from 'Utils/validations';
import { any, func, object, string } from 'prop-types';
import { ThemeContext } from 'Context/themeContext';
import { FormContainer } from 'Components/PostModal/components';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
import { useSelector } from 'react-redux';
import { getSportSelector } from 'Store/selectors/dataSelector';
import { formatDate } from 'Utils/dates';

const EventForm = ({
  register,
  watch,
  errors,
  handleSubmit,
  control,
  handleGetData,
  handlePrev
}) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  const sports = useSelector(getSportSelector);

  const sportsArray = sports?.map((item, i) => {
    return {
      label: item.name,
      value: item._id
    };
  });
  const watchValues = watch(['event_date']);

  console.log(watchValues, 'watch');

  return (
    <ContentContainer gap="20px">
      <FormContainer onSubmit={handleSubmit(handleGetData)}>
        <FormInput
          ref={register(requiredRule)}
          name="title"
          type="text"
          placeholder={t('Event Title')}
          error={errors?.title?.message}
          className="margin-bottom_small"
        />
        <FormSelect
          control={control}
          rules={requiredRule}
          name="sportCategory"
          options={sportsArray}
          placeholder={'Event Category'}
          isSearchable
          error={errors?.category?.message}
          className="margin-bottom_small"
        />

        <FormInput
          ref={register(requiredRule)}
          rules={requiredRule}
          name="event_date"
          type="date"
          value={watchValues.event_date}
          placeholder={t('Date')}
          error={errors?.event_date?.message}
          className="margin-bottom_small"
          min={formatDate(new Date(), 'YYYY/MM/DD')}
          // max={formatDate(new Date())}
        />

        <ContentContainer
          row
          flexWrap="wrap"
          width="100%"
          alignItems="center"
          gap="12px"
          justifyContent="space-between"
        >
          <ContentContainer width="45%">
            <FormInput
              ref={register(requiredRule)}
              name="start_time"
              type="time"
              placeholder={t('Start Time')}
              error={errors?.start_time?.message}
              className="margin-bottom_small"
            />
          </ContentContainer>
          <ContentContainer width="45%">
            <FormInput
              ref={register(requiredRule)}
              name="end_time"
              type="time"
              placeholder={t('End Time')}
              error={errors?.end_time?.message}
              className="margin-bottom_small"
            />
          </ContentContainer>
        </ContentContainer>

        <FormInput
          ref={register(requiredRule)}
          name="venue"
          type="text"
          placeholder={t('Venue')}
          error={errors?.venue?.message}
          className="margin-bottom_small"
        />
        <FormInput
          ref={register(requiredRule)}
          name="venue_address"
          type="text"
          placeholder={t('Venue Location')}
          error={errors?.venue_address?.message}
          className="margin-bottom_small"
        />

        <EventTextArea
          rows="3"
          ref={register(requiredRule)}
          name="about"
          type="text"
          placeholder="About Event"
          error={errors?.venue_address?.message}
          className="margin-bottom_small"
        />

        <FormInput
          ref={register(requiredRule)}
          name="event_link"
          type="url"
          // type="text"
          placeholder="Event link"
          className="margin-bottom_small"
        />

        {/* Host on spoxio */}
        {/* <EventCheckbox>
        <EventCheckbox>
          <Checkbox style={{ borderRadius: '50%' }} />
          <Title fontSize="0.75rem">Host on spoxio</Title>
        </EventCheckbox>

        <EventCheckbox>
          <Checkbox style={{ borderRadius: '50%' }} />
          <Title fontSize="0.75rem">External Link</Title>
        </EventCheckbox>
      </EventCheckbox> */}

        {/* Ending Button */}

        <ContentContainer column width="100%" gap="0px" margin="auto 0 0">
          <SpoxioBlueBtn
            onClick={() => {
              // handleSubmit(handleGetData);
            }}
            type="submit"
          >
            Next
          </SpoxioBlueBtn>
          {/* <SpoxioBlueBtn
            border={theme?.spoxioText}
            background="transparent"
            color={theme?.spoxioText}
            onClick={() => {
              // handlePrev();
            }}
          >
            Cancel
          </SpoxioBlueBtn> */}
        </ContentContainer>
      </FormContainer>
    </ContentContainer>
  );
};

EventForm.propTypes = {
  register: func,
  watch: func,
  errors: object,
  handleSubmit: func,
  handleGetData: func,
  handlePrev: func,
  control: any
};

export default EventForm;
