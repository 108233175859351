import {
  ContentContainer,
  IconContainer,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { shape } from 'prop-types';
import React, { useContext } from 'react';
import { getIcon, SocialLinks } from 'Utils/data';

const OverView = ({ clubProfile, about }) => {
  const { theme } = useContext(ThemeContext);
  const { sportCategory } = clubProfile;
  const phone = clubProfile.phoneNumber.substring(1);

  return (
    <ContentContainer column gap="4px" margin="20px 0">
      <ContentContainer row gap="16px" flexWrap="wrap" alignItems="center" width="100%">
        <SubTitle fontSize="1rem">Club Name:</SubTitle>
        <Title fontSize="1rem">{clubProfile?.name}</Title>
      </ContentContainer>
      <ContentContainer row gap="16px" flexWrap="wrap" alignItems="center" width="100%">
        <SubTitle fontSize="1rem">Category:</SubTitle>
        <Title fontSize="1rem">{sportCategory?.name}</Title>
      </ContentContainer>
      {/* <ClubInfoText>
        <ClubText w={'60px'}>League</ClubText>
        <ClubText>Nigeria Professional Football League</ClubText>
      </ClubInfoText> */}
      <ContentContainer row gap="16px" flexWrap="wrap" alignItems="center" width="100%">
        <SubTitle fontSize="1rem">Address:</SubTitle>
        <Title fontSize="1rem">{clubProfile?.address}</Title>
      </ContentContainer>
      {/* Social Links */}
      <ContentContainer row gap="7px" flexWrap="wrap" width="100%" margin="10px 0">
        {SocialLinks.map((data, i) => {
          const Icon = getIcon(data.name);

          if (data.name === 'Website') {
            return (
              <a
                href={data.route(clubProfile?.website)}
                rel="noreferrer"
                target="_blank"
                key={i.toString()}
              >
                <IconContainer
                  padding="12px"
                  margin="0"
                  border={theme.borderShade}
                  width="18px"
                  height="18px"
                  fill={theme.spoxioText}
                  opacity="0.7"
                >
                  <Icon title={data.name} />
                </IconContainer>
              </a>
            );
          }

          if (data.name === 'Phone') {
            return (
              <a href={data.route(phone)} rel="noreferrer" target="_blank" key={i.toString()}>
                <IconContainer
                  padding="12px"
                  margin="0"
                  border={theme.borderShade}
                  width="18px"
                  height="18px"
                  fill={theme.spoxioText}
                  opacity="0.7"
                >
                  <Icon title={data.name} />
                </IconContainer>
              </a>
            );
          }

          if (data.name === 'Mail') {
            return (
              <a
                href={data.route(about?.email)}
                rel="noreferrer"
                target="_blank"
                key={i.toString()}
              >
                <IconContainer
                  padding="12px"
                  margin="0"
                  border={theme.borderShade}
                  width="18px"
                  height="18px"
                  fill={theme.spoxioText}
                  opacity="0.7"
                >
                  <Icon title={data.name} />
                </IconContainer>
              </a>
            );
          }

          if (data.name !== 'Mail' || data.name !== 'Phone' || data.name !== 'Website') {
            return null;
          }

          return (
            <a href={data.route(about?.email)} rel="noreferrer" target="_blank" key={i.toString()}>
              <IconContainer
                padding="12px"
                margin="0"
                border={theme.borderShade}
                width="18px"
                height="18px"
                fill={theme.spoxioText}
                opacity="0.7"
              >
                <Icon title={data.name} />
              </IconContainer>
            </a>
          );
        })}
      </ContentContainer>
    </ContentContainer>
  );
};

OverView.propTypes = {
  clubProfile: shape({}),
  about: shape({})
};

export default OverView;
