/* eslint-disable eqeqeq */
import { ButtonsTag, DetailsTag } from 'Components/OtherProfile/components';
import {
  ContentContainer,
  IconContainer,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import Api2 from 'middleware/api';
import { any, func, object } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSports } from 'Store/dataSlice';
import { getSportSelector } from 'Store/selectors/dataSelector';
import { ReactComponent as Info } from 'Assets/icons/info.svg';
import { BackButton } from 'Components/shared/BackButton';
import { Carousel } from 'react-responsive-carousel';
import { Loader } from 'Components/shared/Loader';
import isEmpty from 'Utils/isEmpty';
import styled from 'styled-components';
import { BottomOverlay } from 'Components/Layouts/AuthLayout/components';
import { getAgeDifference } from 'Utils/dates';

const CarouselWrapper = styled(ContentContainer)`
  .carousel.carousel-slider .slider-wrapper.axis-vertical {
    height: 80vh !important;
  }

  .slide {
    margin: 0 auto 25px !important;
  }

  .slider {
    height: 65vh !important;
  }
`;

const TalentList = ({ setTalentData, showTalentOverview }) => {
  const dispatch = useDispatch();
  const sports = useSelector(getSportSelector);
  const [scoutData, setScoutData] = useState([]);
  const [scoutDataSearch, setScoutDataSearch] = useState([]);
  const [searchString, setsearchString] = useState('');
  const [isRefreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [selectedTalent, setSelectedTalent] = useState({});
  const [currentSport, setCurrentSport] = useState('football');
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(fetchSports());
    fetchTalent(page, sports[0]?._id);
  }, []);

  useEffect(() => {}, [scoutData]);

  // console.log('\n\r ---selectedTalent list ---', selectedTalent);

  const fetchTalent = async (p, s) => {
    try {
      setLoading(true);
      const talent = await Api2.user.allTalents(p, s);
      setScoutData(talent.data.data);
      setScoutDataSearch(talent.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('\n\rfetchTalent error::', error);
    }
  };

  const searchTalents = (value) => {
    setsearchString(value);
    if (searchString == '') {
      setScoutDataSearch(scoutData);
    }
    const newsearch = scoutData.filter((data) =>
      data?.user.firstName.toLowerCase().includes(value.toLowerCase())
    );
    setScoutDataSearch(newsearch);
  };

  const handleSport = async (value) => {
    setCurrentTab(value);
    await fetchTalent(page, value);
  };

  const TalentCard = (data) => {
    const { item, isSelected, containerStyle } = data;
    isSelected && setSelectedTalent(item);
    const date = getAgeDifference(item?.dateOfBirth);
    return (
      <ContentContainer
        hover
        cursor="pointer"
        opacity="0.975"
        width="100%"
        minWidth="25vw"
        maxWidth="25vw"
        margin="auto"
        height="65vh"
        background={`url(${item?.user?.picture})`}
        boxShadow={isSelected ? '0px 4px 40px 4px rgba(0,122,255, 0.225)' : undefined}
        borderRadius="30px"
        style={{
          backgroundClip: 'border-box',
          backgroundrepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          objectFit: 'cover',
          ...containerStyle
        }}
        onClick={() => {
          setTalentData(selectedTalent);
          showTalentOverview();
        }}
      >
        <ContentContainer
          height="100%"
          width="100%"
          padding="25px 15px"
          margin="0"
          justifyContent="flex-end"
          zIndex="99"
        >
          <ContentContainer>
            <Title
              maxWidth="25vw"
              textOverflow="hidden"
              textTransform="ellipsis"
              style={{ overflow: 'hidden' }}
              color={theme?.defaultWhite}
            >
              {item?.user?.firstName}, {date}
            </Title>
            <SubTitle fontSize="0.875rem" color={theme?.defaultWhite} textTransform="capitalize">
              {item?.gender}
            </SubTitle>
            <SubTitle fontSize="0.875rem" color={theme?.defaultWhite} textTransform="capitalize">
              {item?.sportPlayed?.name}
            </SubTitle>
          </ContentContainer>
        </ContentContainer>
        <BottomOverlay maxHeight="25vh" borderRadius="0 0 30px 30px" />
      </ContentContainer>
    );
  };
  const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

  return (
    <ContentContainer width="100%" height="100%" gap="20px">
      <ContentContainer row flexWrap="no-wrap" width="100vw" overflowX="auto" hideScroll>
        <ButtonsTag>
          {(sports || [])?.map((sport, i) => {
            return (
              <DetailsTag
                fontSize="0.75rem"
                minWidth="8vw"
                maxWidth="10vw"
                width="auto"
                key={i?.toString()}
                active={currentTab === sport._id ? true : false}
                onClick={() => handleSport(sport._id)}
              >
                {sport?.name}
              </DetailsTag>
            );
          })}
        </ButtonsTag>
      </ContentContainer>

      <CarouselWrapper
        width="100%"
        height="100%"
        justifyContent="center"
        overflow="hidden"
        style={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: `${theme?.borderShade}`,
          height: '80vh',
          borderBottom: '0',
          borderTopLeftRadius: '30px',
          borderTopRightRadius: '30px',
          paddingTop: '2vh'
        }}
      >
        {!isEmpty(scoutData) ? (
          <ContentContainer
            hover
            cursor="pointer"
            row
            gap="0px"
            position="absolute"
            style={{ right: '2vw', top: '3vh', zIndex: '99' }}
            alignItems="center"
            onClick={showTalentOverview}
          >
            <Title color={theme?.mediumGray} fontSize="0.875rem">
              {' '}
              Talent Info
            </Title>{' '}
            <IconContainer fill={theme.mediumGray} width="18px" height="18px">
              <Info />
            </IconContainer>
          </ContentContainer>
        ) : null}

        {loading ? (
          <ContentContainer alignItems="center" justifyContent="center" height="100%" width="100%">
            <Loader height="100%" background="transparent" />
          </ContentContainer>
        ) : (
          <Carousel
            autoFocus
            emulateTouch
            showIndicators={false}
            axis="vertical"
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            useKeyboardArrows
            renderItem={customRenderItem}
            renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && <BackButton />}
            renderArrowNext={(onClickHandler, hasNext, label) => hasNext && <BackButton right />}
          >
            {scoutData?.map((item, i) => {
              if (i === 0) {
                return (
                  <TalentCard
                    key={i.toString()}
                    item={item}
                    containerStyle={{
                      marginTop: '3vh'
                    }}
                  />
                );
              }

              if (i === scoutData?.length - 1) {
                return (
                  <TalentCard
                    key={i.toString()}
                    item={item}
                    containerStyle={{
                      height: '60vh'
                    }}
                  />
                );
              }

              return <TalentCard key={i.toString()} item={item} />;
            })}
          </Carousel>
        )}
      </CarouselWrapper>
    </ContentContainer>
  );
};

TalentList.propTypes = {
  showTalentOverview: func,
  setTalentData: func
};

export default TalentList;
