import ScoutLayout from 'Components/Layouts/ScoutLayout/ScoutLayout';
import PostsTalent from 'Components/Scout/PostsTalent';
import Api from 'middleware/api';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserId } from 'Store/authSlice';
import { getProfileById } from 'Store/profileSlice';
import { getProfileSelector } from 'Store/selectors/profileSelector';

const ScoutActivities = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getCurrentUserId);

  const profile = useSelector(getProfileSelector);

  useEffect(async () => {
    dispatch(getProfileById(userId));
  }, [userId]);

  // console.log('profile on main scout page', profile);
  return (
    <ScoutLayout profile={profile}>
      <PostsTalent profile={profile} />
    </ScoutLayout>
  );
};

export default ScoutActivities;
