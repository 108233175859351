import styled from 'styled-components';

import { BaseText } from '../shared/styledComponents';

export const SocialButton = styled.div`
  width: 50px;
  height: 50px;
  /* margin-right: 35px; */
  cursor: pointer;

  &:last-child {
    margin: 0;
  }
`;

export const Title = styled(BaseText)`
  margin-bottom: 30px;
  color: ${({ theme }) => theme.black};
  width: 100%;
  text-align: center;
`;

export const AppleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border: 1px solid ${({ theme }) => theme.black};
  border-radius: 50%;
`;
