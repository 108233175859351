import { push } from 'connected-react-router';
import { ThemeContext } from 'Context/themeContext';
import { func, number } from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { paths } from 'Utils/constants';
import { DiscoverButton, DiscoveryButtonsContainer } from './components';

const DiscoverButtons = ({ type, setTypeNumber }) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const buttons = ['For You', 'Sports', 'Topics'];

  return (
    <DiscoveryButtonsContainer>
      {buttons.map((data, index) => (
        <DiscoverButton index={index} type={type} key={index}>
          <button
            style={{
              background: 'transparent',
              borderRadius: '10px',
              border: 'none',
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              color: theme?.spoxioText
            }}
            onClick={() => {
              setTypeNumber(index + 1);
              dispatch(push(`${paths.DISCOVERY}`));
            }}
          >
            {data}
          </button>
        </DiscoverButton>
      ))}
    </DiscoveryButtonsContainer>
  );
};
DiscoverButtons.propTypes = {
  setTypeNumber: func.isRequired,
  type: number
};

export default DiscoverButtons;
