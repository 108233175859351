import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { paths } from 'Utils/constants';
import { RouteProps } from 'Utils/propTypes';
import { updateUser } from 'Store/authSlice';
import { ChoseSports } from 'Components/ChoseSports';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'Components/Layouts/AuthLayout';
import { AuthFormContainer, AuthFormDescription } from '../components';
import { SpoxioBlueBtn, SubTitle, Title } from 'Components/Layouts/AuthLayout/components';
import ChoseTopic from 'Components/ChoseSports/ChoseTopic';
import { ThemeContext } from 'Context/themeContext';
import { ContentContainer } from 'Components/shared/styledComponents';

export const SignUpChoseSports = ({ history }) => {
  const { theme } = useContext(ThemeContext);
  const [sports, setSports] = useState([]);
  const [chosePage, setChosePage] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  localStorage.removeItem('type');

  const onChangeValue = (value) => {
    if (sports.includes(value)) {
      const filteredSports = sports.filter((sport) => sport !== value);

      setSports(filteredSports);
    } else {
      setSports([...sports, value]);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!sports?.length) {
      toast.warn(t('choose_one_sport'));
    } else {
      const formattedSports = new Set(sports);
      // const pathToRedirect = isScout(currentUser?.userType)
      //   ? paths.SIGN_UP_PAYMENTS
      //   : isMobile
      //   ? paths.MOBILE_SCREEN
      //   : paths.TIMELINE;

      const pathToRedirect = paths.TIMELINE;
      dispatch(updateUser({ sports: [...formattedSports] }, pathToRedirect));
    }
  };

  return (
    <>
      {chosePage === 1 && (
        <AuthLayout
          title={
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Title fontSize="1.75rem" fontWeight="bold">
                Follow your <br />
                favorite sports
              </Title>
            </div>
          }
          showBackButton
        >
          <AuthFormDescription textAlign="left" className="margin-top_small">
            <SubTitle
              fontSize="0.875rem"
              fontWeight="400"
              // opacity="0.85"
              // style={{ color: theme.black, lineHeight: '1.1rem' }}
            >
              {t(`Customize your in app experience`)}
            </SubTitle>
          </AuthFormDescription>
          <AuthFormContainer onSubmit={onSubmit} width="calc(100% - 10vw)">
            <ChoseSports selectedSports={sports} onSelect={onChangeValue} />
            <SpoxioBlueBtn
              // onClick={() => {
              //   setChosePage(2);
              // }}
              className="margin-top_small"
            >
              {t('next')}
            </SpoxioBlueBtn>
            <ContentContainer
              margin="12px 0"
              width="100%"
              row
              alignItems="center"
              justifyContent="center"
            >
              <Title
                fontSize="1rem"
                textAlign="center"
                style={{ cursor: 'pointer', opacity: '0.3' }}
              >
                I’ll do this later
              </Title>
            </ContentContainer>
          </AuthFormContainer>
        </AuthLayout>
      )}
      {chosePage === 2 && (
        <AuthLayout
          title={
            <span>
              Follow topic of <br />
              Interest
            </span>
          }
          subtitle={t(`You can choose more than one.`)}
          isPreviousStep={true}
          handleBack={() => {
            setChosePage(1);
          }}
          showBackButton
        >
          <AuthFormContainer onSubmit={onSubmit}>
            <ChoseTopic sportType="clubs" />
            <ChoseTopic sportType="athlete" />
            <ChoseTopic sportType="hashtags" />
            <SpoxioBlueBtn className="margin-top_small">{t('next')}</SpoxioBlueBtn>
          </AuthFormContainer>
        </AuthLayout>
      )}
    </>
  );
};

SignUpChoseSports.propTypes = RouteProps;
