import { BaseText } from 'Components/shared/styledComponents';
import styled from 'styled-components';

export const LivestreamChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 35px;
`;

export const LivestreamMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px 25px;
  margin-left: 15px;
  margin-bottom: 20px;
  width: max-content;
  max-width: 325px;
  background-color: ${({ theme }) => theme.lightGray1};
  border-radius: 0 10px 10px 10px;
`;

export const MessageUserIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: -17px;
`;

export const MessageUserName = styled(BaseText)`
  color: ${({ theme }) => theme.gray};
  font-size: ${({ theme }) => theme.small}px;
`;

export const MessageStarText = styled(BaseText)`
  margin-top: 5px;
  color: ${({ theme }) => theme.gray};
  font-size: ${({ theme }) => theme.xSmall}px;
  cursor: pointer;
`;
