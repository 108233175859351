import LanguageSwitcher from 'Components/LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { paths } from 'Utils/constants';
import { NavigationContainer, ListItem } from './components';

export const Navigation = () => {
  const { t } = useTranslation();
  return (
    <NavigationContainer>
      <Link to={paths.ABOUT}>
        <ListItem> {t('about')}</ListItem>
      </Link>
      <Link to={paths.PRIVACY}>
        <ListItem> {t('privacy_policy')}</ListItem>
      </Link>
      <Link to={paths.TERMS_OF_CONDITIONS}>
        <ListItem> {t('terms_of_use')}</ListItem>
      </Link>
      <span className="email">
        {t('switch_language')}:<br /><br/> <LanguageSwitcher />
      </span>
    </NavigationContainer>
  );
};
