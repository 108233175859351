import { useRef } from 'react';
import { func, string } from 'prop-types';

import { SearchContainer, SearchInput } from './components';

import { ReactComponent as SearchIcon } from 'Assets/icons/Search.svg';
import { useTranslation } from 'react-i18next';

export const Search = ({ searchHandle, width }) => {
  const inputRef = useRef(null);

  const { t } = useTranslation();
  const onSearchContainerClick = () => {
    inputRef?.current?.focus();
  };

  return (
    <SearchContainer onClick={onSearchContainerClick} width={width}>
      <SearchIcon />
      <SearchInput ref={inputRef} placeholder={`${t('search')} Spoxio`} onKeyDown={searchHandle} />
    </SearchContainer>
  );
};

Search.propTypes = {
  searchHandle: func.isRequired,
  width: string
};
