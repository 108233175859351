import firebase from 'firebase/app';
import 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import Api from 'Api';
import { paths, SCOUT_MESSAGE } from 'Utils/constants';
import { removeDuplicates } from 'Utils';
import { getTrialUserData } from 'Utils/formatData';

export const getTimestamp = async () => await firebase.firestore.Timestamp.now();

export const updateUserDoc = (id, userData) => {
  const userDoc = firebase.firestore().collection('users').doc(id);

  return userDoc.get().then((documentSnapshot) => {
    if (!documentSnapshot.exists) {
      userDoc.set({
        ...userData,
        likeCount: 0,
        viewCount: 0,
        commentCount: 0,
        userType: 'fan',
        createdProfile: false
      });

      // return { pathname: paths.SIGN_UP_GENERAL_INFO, state: { userData } };
      return { pathname: paths.SIGN_UP, state: { userData } };
    } else {
      const userDic = documentSnapshot.data();

      if (userDic.createdProfile) {
        return { pathname: paths.TIMELINE };
      } else {
        return { pathname: paths.SIGN_UP_ROLE };
      }
    }
  });
};

export const uploadMedia = async (attachments, userId) => {
  const mediaSources = [];

  await Promise.all(
    attachments.map(async (attachment, index) => {
      const type = attachment.base64Name.includes('image') ? 'image' : 'video';
      const extension = attachment.base64Name.includes('image') ? '.jpg' : '.mp4';
      const filename = `${new Date().getTime()}${extension}`;
      const storageRef = firebase.storage().ref(`posts/${userId}/${filename}`);

      await storageRef.put(attachment.file);
      const dataUrl = await storageRef.getDownloadURL();

      mediaSources[index] = { type, url: dataUrl };
    })
  );

  return mediaSources;
};

export const getCategoriesWithCount = async (categories) => {
  const categoriesWithCount = await Promise.all(
    categories.map((category) =>
      firebase
        .firestore()
        .collection('posts')
        .orderBy('views', 'desc')
        .where('postCategory', '==', `${category.id}`)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return { ...category, totalPosts: 0 };
          }

          return { ...category, totalPosts: querySnapshot.size };
        })
    )
  );

  return categoriesWithCount;
};

export const sendNotification = async (notification) => {
  try {
    await firebase.functions().httpsCallable('sendPushNotification')(notification);

    return true;
  } catch (error) {
    return error;
  }
};

export const getPrices = async () => {
  try {
    const { data } = await firebase.functions().httpsCallable('getPrices')();

    const formattedData = {};

    data.forEach((price) => {
      formattedData[price.recurring.interval] = {
        id: price.id,
        currency: price.currency,
        createdAt: price.created,
        amount: price.unit_amount,
        type: price.recurring.interval
      };
    });

    return formattedData;
  } catch (error) {
    throw error;
  }
};

export const createSession = async (userId, user, price, isTrial = true) => {
  try {
    const trialData = getTrialUserData();
    const trialEnd = new Date(trialData.expiryDate).getTime() / 1000;
    const dataBody = {
      userEmail: user.email,
      price: price.id,
      trialEnd: isTrial ? trialEnd : null,
      oldSubscription: user.subscription
    };

    const { data } = await axios.post(
      'https://us-central1-spoxio-ltd.cloudfunctions.net/createSession',
      dataBody,
      { headers: { 'Content-Type': 'application/json' } }
    );

    await Api.user.updateUser({
      userId,
      user: {
        subscriptionType: price.type,
        subscription: null,
        paymentGateway: 'stripe',
        customer: data.session.customer
      }
    });

    return data.session;
  } catch (error) {
    throw error;
  }
};

export const createGroup = async (members) => {
  const curTime = firebase.firestore.Timestamp.now();
  const document = await firebase.firestore().collection('chat_groups').add({
    members,
    no_of_members: members.length,
    createdAt: curTime,
    lastMessageDate: curTime
  });

  return document.id;
};

export const addContactMessage = (groupId, currentUserId) => {
  const curTime = firebase.firestore.Timestamp.now();

  firebase
    .firestore()
    .collection('chat_groups')
    .doc(groupId)
    .collection('messages')
    .add({
      _id: uuidv4(),
      createdAt: curTime,
      text: SCOUT_MESSAGE,
      user: {
        _id: currentUserId
      }
    });

  firebase.firestore().collection('chat_groups').doc(groupId).update({
    lastMessageDate: curTime
  });
};

export const getMergedPostsData = async (userId, postsFollowing, postsSports) => {
  let finalData = [];
  const ids = new Set(postsFollowing.map((d) => d?.id));

  let mergedData = [...postsSports.filter((d) => !ids.has(d?.id)), ...postsFollowing];
  mergedData = mergedData.sort(
    (a, b) => new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
  );
  finalData = mergedData;

  const posts = [];
  const uniqueV = removeDuplicates(finalData, 'id');

  for (let i = 0; i < uniqueV.length; i++) {
    const userIdOfPost = await Api.user.checkUserWithFirebase(userId, uniqueV[i].uid);

    if (userIdOfPost !== uniqueV[i].uid) {
      posts.push(uniqueV[i]);
    }
  }

  return posts;
};
