import { Footer } from 'Components/Landing/Footer';
import { Header } from 'Components/Landing/Header';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import 'Styles/main.scss';
import 'Styles/privacy_terms.scss';

const Paragraph = styled.p`
  color: ${({ theme }) => theme.spoxioText};
  opacity: 0.8;
`;
const HeaderText = styled.h1`
  color: ${({ theme }) => theme.black};
`;

const Span = styled.span`
  color: ${({ theme }) => theme.black};
`;

export const TermsOfUse = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Header />
      <main
        className="main container nullable-vertical-padding"
        style={{ background: theme.background }}
      >
        <div className="main-content">
          <HeaderText className="main-title-secondary">{t('terms_and_conditions')}</HeaderText>
          <div className="main-body">
            <p className="main-text" style={{ color: theme.spoxioText }}>
              {t('terms_main')}
            </p>
            <Span className="main-text-header">{t('terms_text')}</Span>
            <Paragraph className="main-text">{t('terms_p1')}</Paragraph>
            <Span className="main-text-header">{t('change_of_use')}</Span>
            <Paragraph className="main-text">
              Spoxio {t('terms_t1')}:
              <br />
              1. {t('terms_t1a')}
              <br />
              2. {t('terms_t1b')}
            </Paragraph>
            <Span className="main-text-header">{t('terms_h1')}</Span>
            <Paragraph className="main-text">
              1. {t('terms_p2a')} (i) {t('terms_p2b')} (ii) {t('terms_p2c')}
              <br />
              2. {t('terms_p3')} <br />
              3. {t('terms_p3a')} (i) {t('terms_p3b')} (ii) {t('terms_p3c')} (iii) {t('terms_p3d')}
              <br />
              4. {t('terms_p4')}
              <br />* {t('terms_p4a')}; <br />* {t('terms_p4b')}; <br />* {t('terms_p4c')};
              <br />* {t('terms_p4d')}; <br />* {t('terms_p4e')}; <br />* {t('terms_p4f')}
              <br />* {t('terms_p4g')}
              <br />* {t('terms_p4h')}; <br />* {t('terms_p4i')}; <br />* {t('terms_p4j')}; <br />*{' '}
              {t('terms_p4k')}; <br />* {t('terms_p4l')}; <br />*{t('terms_p4m')}; <br />*{' '}
              {t('terms_p4n')}; <br />* {t('terms_p4o')}; <br />* {t('terms_p4p')}
              <br />* {t('terms_p4q')} <br />
              5. {t('terms_p5')} <br />
              6. {t('terms_p6')}: (i) {t('terms_p6a')}; (ii) {t('terms_p6b')}; (iii){' '}
              {t('terms_p6c')}; (iv) {t('terms_p6d')} (v) {t('terms_p6e')} <br />
              7. {t('terms_p7')}
            </Paragraph>
            <Span className="main-text-header">{t('terms_h2')}</Span>
            <Paragraph className="main-text">
              {t('terms_p8a')}: <br />* 1 {t('terms_p8b')} <br />* 1 {t('terms_p8c')} <br />
              {t('terms_p8d')}
            </Paragraph>
            <Span className="main-text-header">{t('terms_h3')}</Span>
            <Paragraph className="main-text">
              {t('terms_p9a')} Spoxio {t('terms_p9b')}
              Spoxio {t('terms_p9e')} Spoxio {t('terms_p9f')}
            </Paragraph>
            <Span className="main-text-header">{t('copyright')}</Span>
            <Paragraph className="main-text">
              1. {t('terms_p10')} <br />
              2. {t('terms_p10a')}
            </Paragraph>
            <Span className="main-text-header">{t('terms_h4')}</Span>
            <Paragraph className="main-text">
              1. {t('terms_p11')}
              <br />
              2. {t('terms_p11a')}, Spoxio {t('terms_p11b')}. <br />
              3. Spoxio {t('terms_p11c')} <br />
              4. {t('terms_p11d')} Spoxio {t('terms_p11e')} Spoxio {t('terms_p11f')}
            </Paragraph>
            <Span className="main-text-header">{t('terms_h5')}</Span>
            <Paragraph className="main-text">
              1. {t('terms_p12')} <br />
              2. {t('terms_p12a')}: <br />
              {t('terms_p12b')}; <br />
              {t('terms_p12c')}
              <br />
              {t('terms_p12d')}
              <br />
              3. {t('terms_p12e')}
            </Paragraph>
            <Span className="main-text-header">{t('indemnity')}</Span>
            <Paragraph className="main-text">
              {t('terms_p13')} Spoxio {t('terms_p13a')} Spoxio {t('terms_p13b')}
            </Paragraph>
            <Span className="main-text-header">{t('severance')}</Span>
            <Paragraph className="main-text">{t('terms_p14')}</Paragraph>
            <Span className="main-text-header">{t('waiver')}</Span>
            <Paragraph className="main-text">{t('terms_p15')} Info@Spoxio.com.</Paragraph>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
