import { toast } from 'react-toastify';

import Api from 'middleware/api';
import { sortArray } from 'Utils';
import { setLoading } from './loaderSlice';
import { setStatsList } from './selectors/statsSelector';

export const getStats = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const stats = await Api.user.userTalents();
    const sortedStats = sortArray(stats.data.data, 'likeCount', 'desc');

    dispatch(setStatsList(sortedStats));
    dispatch(setLoading(false));
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error?.toString());
    dispatch(setLoading(false));
  }
};
