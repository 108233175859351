import React, { useContext, useEffect, useState } from 'react';
import { SubTitle } from 'Components/Layouts/ProfileLayout/LeftBar/components';
import { AboutTags } from 'Components/OtherProfile/components';
import {
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  Title
} from 'Components/shared/styledComponents';
import CreateEventBar from './CreateEventBar';
import MyEvent from './MyEvent';
import TrendingEventsBar from './TrendingBar';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as MoreIconHamburger } from 'Assets/icons/MoreIconHamburger.svg';
import {
  fetchEventCategories,
  fetchMyEvents,
  fetchParticipatingEvents,
  fetchTrendingEvents
} from 'Store/eventSlice';
import {
  getEventCategories,
  getMyEvent,
  getParticipatingEvent,
  getTrendingEvents
} from 'Store/selectors/eventSelector';
import isEmpty from 'Utils/isEmpty';
import { func } from 'prop-types';
import { paths } from 'Utils/constants';
import { push, replace } from 'connected-react-router';
import { useLocation } from 'react-router';
import { ThemeContext } from 'Context/themeContext';
import { CreateEventIcon } from './components';
import { setSearch } from 'Store/selectors/discoverySelector';
import { BackButton } from 'Components/shared/BackButton';

const IndexEvents = ({ setStep }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const [searchText, handleSearch] = useState({});
  const [searchItems, setSearchItems] = useState([]);
  // // const [trendingEvents, setTrendingEvents] = useState([]);
  // const [participatingEvents, setParticipatingEvents] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [loading, setLoading] = useState(false);

  const trendingEvents = useSelector(getTrendingEvents);
  const eventCategories = useSelector(getEventCategories);
  const participatingEvent = useSelector(getParticipatingEvent);
  const myEvent = useSelector(getMyEvent);

  const filterEvents = (category) => {
    dispatch(fetchTrendingEvents({ ...category, page: 1, limit: 8 }));
    dispatch(fetchParticipatingEvents({ ...category, page: 1, limit: 3 }));
    dispatch(fetchMyEvents({ ...category, page: 1, limit: 5 }));
    handleSearch(category);
  };

  useEffect(() => {
    dispatch(fetchTrendingEvents({ page: 1, limit: 8 }));
    dispatch(fetchParticipatingEvents({ page: 1, limit: 2 }));
    dispatch(fetchMyEvents({ page: 1, limit: 5 }));
    dispatch(fetchEventCategories());
  }, []);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    if (!query.get('search')) {
      dispatch(fetchTrendingEvents({ page: 1, limit: 8 }));
      dispatch(fetchParticipatingEvents({ page: 1, limit: 2 }));
      dispatch(fetchMyEvents({ page: 1, limit: 5 }));
      return;
    }
    handleSearch(query.get('search'));
    setTimeout(() => {
      const searchItem = eventCategories?.filter((item) =>
        item.name.toLowerCase().includes(query.get('search').toLowerCase())
      );
      setSearchItems(searchItem);
      console.log(searchItem, 'searchItems', query.get('search'));
    }, 500);
  }, [query.get('search')]);

  const handleViewTrendingEvents = () => {
    console.log(searchText, 'cetegory');
    isEmpty(searchText)
      ? dispatch(push(`${paths.VIEW_TRENDING_EVENTS}`))
      : dispatch(push(`${paths.VIEW_TRENDING_EVENTS}?sport=${searchText?.category}`));
  };

  const handleViewParticipatingEvents = () => {
    console.log(searchText, 'cetegory');
    isEmpty(searchText)
      ? dispatch(push(`${paths.VIEW_PARTICIPATING_EVENTS}`))
      : dispatch(push(`${paths.VIEW_PARTICIPATING_EVENTS}?sport=${searchText?.category}`));
  };

  const handleSearchItems = (cat) => {
    dispatch(fetchTrendingEvents({ category: cat, page: 1, limit: 8 }));
    dispatch(fetchParticipatingEvents({ category: cat, page: 1, limit: 2 }));
    dispatch(setSearch(null));
    setSearchItems([]);
  };

  return (
    <>
      {!isEmpty(searchItems) ? (
        <ContentContainer gap="20px" column margin="20px 0 10%">
          {!isEmpty(query.get('search')) ? (
            <ContentContainer gap="20px" row margin="20px 0">
              <BackButton
                onClick={() => {
                  setSearchItems([]);
                  handleSearch({});
                  dispatch(setSearch(null));
                  dispatch(push(`${paths.EVENTS}`));
                }}
              />
              <Title width="100%" textAlign="center" fontFamily="SF Pro" fontSize="1.25rem">
                {' '}
                Showing results for '{query.get('search')}'
              </Title>
            </ContentContainer>
          ) : null}
          {searchItems?.map((search, i) => {
            console.log(search, 'sera');
            return (
              <ContentContainer
                row
                key={i}
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                cursor="pointer"
                hover
                onClick={() => {
                  handleSearchItems(search?.tag);
                  handleSearch({ category: search?.tag });
                }}
              >
                <ContentContainer row justifyContent="center" alignItems="center">
                  <ContentContainer row minHeight="25px" alignItems="center" gap="12px">
                    <CreateEventIcon
                      type="sub"
                      background={theme?.spoxioIcon}
                      width="40px"
                      height="40px"
                    >
                      <IconContainer margin="0" width="20px" height="20px">
                        <MoreIconHamburger />
                      </IconContainer>
                    </CreateEventIcon>

                    <Title
                      color={theme.spoxioText}
                      fontSize="1rem"
                      textAlign="center"
                      lineHeight="normal"
                      opacity="0.8"
                      fontFamily="SF Pro"
                    >
                      {search?.name}
                    </Title>
                  </ContentContainer>
                </ContentContainer>

                <Title
                  color={theme.spoxioText}
                  fontSize="1rem"
                  textAlign="center"
                  lineHeight="normal"
                  opacity="0.8"
                  fontFamily="SF Pro"
                >
                  {search?.event_count} Events
                </Title>
              </ContentContainer>
            );
          })}
        </ContentContainer>
      ) : (
        <ContentContainer gap="20px" column margin="20px 0 10%">
          <CreateEventBar
            handleCategory={(data) => {
              filterEvents({ category: data });
              dispatch(setSearch(null));
              dispatch(push(`${paths.EVENTS}`));
            }}
            category={eventCategories}
            setStep={(v) => setStep(v)}
          />

          <ContentContainer row flexWrap="nowrap" width="100%" justifyContent="space-between">
            <SubTitle fontSize="1.25rem" fontWeight="bold">
              Trending
            </SubTitle>

            <SpoxioBlueBtn
              background="transparent"
              color="#007AFF"
              fontSize="0.875rem"
              fontWeight="bold"
              width="auto"
              margin="0"
              padding="0"
              marginTop="0"
              onClick={handleViewTrendingEvents}
            >
              View All
            </SpoxioBlueBtn>
          </ContentContainer>
          <TrendingEventsBar trendingEvents={trendingEvents} />

          {!isEmpty(participatingEvent) ? (
            <>
              <ContentContainer row flexWrap="nowrap" width="100%" justifyContent="space-between">
                <AboutTags size="1rem">Participating Events</AboutTags>

                <SpoxioBlueBtn
                  background="transparent"
                  color="#007AFF"
                  fontSize="0.875rem"
                  fontWeight="bold"
                  width="auto"
                  margin="0"
                  padding="0"
                  marginTop="0"
                  onClick={handleViewParticipatingEvents}
                >
                  View All
                </SpoxioBlueBtn>
              </ContentContainer>

              {
                participatingEvent
                  ?.map((_event, i) => {
                    return <MyEvent key={i} event={_event} />;
                  })
                  .reverse()
                // .splice(0, 3)
              }
            </>
          ) : null}

          {!isEmpty(myEvent) ? (
            <>
              <ContentContainer row flexWrap="nowrap" width="100%" justifyContent="space-between">
                <AboutTags>My Events</AboutTags>

                {/* <SpoxioBlueBtn
                  background="transparent"
                  color="#007AFF"
                  fontSize="1rem"
                  fontWeight="bold"
                  width="auto"
                  margin="0"
                  padding="0"
                  marginTop="0"
                  onClick={handleViewParticipatingEvents}
                >
                  View All
                </SpoxioBlueBtn> */}
              </ContentContainer>

              {
                myEvent
                  ?.map((_event, i) => {
                    return <MyEvent key={i} event={_event} />;
                  })
                  .reverse()
                // .splice(0, 3)
              }
            </>
          ) : null}
        </ContentContainer>
      )}
    </>
  );
};

IndexEvents.propTypes = {
  setStep: func
};

IndexEvents.defaultProps = {
  setStep: null
};

export default IndexEvents;
