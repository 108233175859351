import { connectRouter } from 'connected-react-router';

import { authReducer } from './selectors/authSelector';
import { postReducer } from './postSlice';
import { storiesReducer } from './selectors/storiesSelector';
import { statsReducer } from './selectors/statsSelector';
import { profileReducer } from './selectors/profileSelector';
import { loaderReducer } from './loaderSlice';
import { dataReducer } from './selectors/dataSelector';
import { postsReducer } from './selectors/postSelector';
import { commentsReducer } from './selectors/commentSelector';
import { discoveryReducer } from './selectors/discoverySelector';
import { eventReducer } from './selectors/eventSelector';
import { purchaseReducer } from './selectors/purchaseSelector';
import { clubReducer } from './selectors/clubSelector';

export const createRootReducer = (history) => ({
  router: connectRouter(history),
  loader: loaderReducer,
  auth: authReducer,
  profile: profileReducer,
  post: postReducer,
  stories: storiesReducer,
  discovery: discoveryReducer,
  stats: statsReducer,
  data: dataReducer,
  posts: postsReducer,
  comments: commentsReducer,
  event: eventReducer,
  purchases: purchaseReducer,
  club: clubReducer
});
