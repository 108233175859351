import Api from 'middleware/api';
import { setisNotificationLoading } from './loaderSlice';
import { setCountry, setNotification, setSport, setSportPopular } from './selectors/dataSelector';

export const fetchCountries = () => async (dispatch) => {
  try {
    const res = await Api.category.countries();
    const { data } = res.data;
    dispatch(setCountry(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchSports = () => async (dispatch) => {
  try {
    const res = await Api.category.sports();
    const { data } = res.data;
    dispatch(setSport(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchPopularSports = () => async (dispatch) => {
  try {
    const res = await Api.category.sportsPopular();
    const { data } = res.data;
    dispatch(setSportPopular(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchNotifications = (count) => async (dispatch) => {
  try {
    dispatch(setisNotificationLoading(true));
    const res = await Api.notification.fetchNotifications(count);
    const { data } = res.data;
    dispatch(setNotification(data));
    dispatch(setisNotificationLoading(false));
    return true;
  } catch (error) {
    return false;
  } finally {
    dispatch(setisNotificationLoading(false));
  }
};
