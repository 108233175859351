import styled from 'styled-components';
import { BaseText, BaseTitle, CenteredContainer } from '../../shared/styledComponents';
import { ReactComponent as ArrowLeftIcon } from 'Assets/icons/ArrowLeft.svg';
import AuthImg from 'Assets/img/AuthImg.png';

export const LeftContainer = styled.div`
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 25%;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const StepText = styled(BaseText)`
  margin-top: 10px;
  margin-bottom: 50px;
  color: ${({ theme }) => theme.gray};
`;

export const RightContainer = styled(CenteredContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 25%;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  /*  */
  padding: 20px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const BackButton = styled(ArrowLeftIcon)`
  width: ${({ width }) => width || '16px'};
  height: ${({ height }) => height || '16px'};
  cursor: pointer;
  color: ${({ color, theme }) => color || theme.black};
  padding: 6px;
  background-color: ${({ theme }) => theme.neutralGray};
  border-radius: 50px;
  margin-bottom: ${({ mb }) => mb || '12%'};
  ${({ right }) => right && `transform: rotate(180deg)`};
`;

export const RightBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : '0')};
  overflow-y: scroll;
  overflow-x: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ border }) => (border ? border : 'rgba(196, 196, 196, 0.5)')};
  border-radius: 30px;
  background-color: ${({ background, theme }) => (background ? background : theme.spoxioBg)};

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const BottomOverlay = styled.div`
  max-height: ${({ maxHeight }) => maxHeight || '15vh'};
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(16, 16, 16, 0.5) 35%,
    rgba(16, 16, 16, 0.1) 70%,
    rgba(16, 16, 16, 0) 90%
  );
  background-blend-mode: overlay;
  mix-blend-mode: normal;
  position: absolute;
  bottom: 0;
  border-radius: ${({ borderRadius }) => borderRadius || ' 0 0 24px 24px'};
`;

export const DotsContainer = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const Title = styled(BaseText)`
  width: ${({ width }) => (width ? width : 'auto')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '28px')};
  color: ${({ theme, color }) => color || theme.spoxioText};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.5rem')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '600')};
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
`;

export const SubTitle = styled(BaseText)`
  color: ${({ theme }) => theme.spoxioText};
  font-size: ${({ fontSize }) => fontSize || '1.15rem'};
  opacity: ${({ opacity }) => (opacity ? opacity : '0.6')};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
`;

export const Dots = styled.span`
  height: ${({ height }) => (height ? height : 4)}px;
  width: ${({ selected }) => (selected ? 16 : 10)}px;
  background: ${({ selected, theme }) => (selected ? theme.spoxioText : theme.spoxioGray)};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 15 + '%')};
  opacity: ${({ selected }) => (selected ? 1 : 0.4)};
`;

// export const Title = styled(BaseTitle)`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   font-size: 1.5rem;
//   align-items: center;
//   width: 100%;
//   color: ${({ theme }) => theme.black};

//   @media (max-width: 1024px) {
//     display: none;
//   }
// `;

export const SpoxioBlueBtn = styled.button`
  background: ${({ theme }) => theme.spoxioLinear2};
  color: #fff;
  padding: 0.875rem 2rem;
  margin: 0 auto;
  font-family: SF Pro;
  font-size: 0.875rem;
  width: ${({ width }) => (width ? width : '100%')};
  border-radius: 10px;
  border: none;
  /* margin: 0px 3px; */
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '20px')};
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }
`;

export const AppStoreBtn = styled.img`
  width: 140px;
  height: 45px;
`;

export const GooglePlayBtn = styled.img`
  width: 140px;
  height: 40px;
`;

export const AppstoreBtnContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
