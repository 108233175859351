import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countries: [],
  sports: [],
  sportPopular: [],
  notifications: [],
  chats: [],
  formData: {}
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setCountry: (state, action) => {
      state.countries = action.payload || [];
    },
    setSport: (state, action) => {
      state.sports = action.payload || [];
    },
    setNotification: (state, action) => {
      state.notifications = action.payload || [];
    },
    setSportPopular: (state, action) => {
      state.sportPopular = action.payload || [];
    },
    setChatsData: (state, action) => {
      state.chats = action.payload || [];
    },
    setFormData: (state, action) => {
      state.formData = action.payload || {};
    }
  }
});

// actions
export const {
  setCountry,
  setSport,
  setSportPopular,
  setNotification,
  setChatsData,
  setFormData
} = dataSlice.actions;

// reducer
export const dataReducer = dataSlice.reducer;

// selectors
export const getCountrySelector = (state) => state?.data?.countries;
export const getSportSelector = (state) => state?.data?.sports;
export const getSportPopularSelector = (state) => state?.data?.sportPopular;
export const getNotificationSelector = (state) => state?.data?.notifications;
export const getChatsData = (state) => state?.data?.chats;
export const getFormData = (state) => state?.data?.formData;
