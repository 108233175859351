export const firebaseConfig = {
  apiKey: 'AIzaSyCz-Hy6xy7LXSF-N27-meM9o6q4u7wZJ7Y',
  authDomain: 'spoxio-ltd.firebaseapp.com',
  databaseURL: 'https://spoxio-ltd.firebaseio.com',
  projectId: 'spoxio-ltd',
  storageBucket: 'spoxio-ltd.appspot.com',
  messagingSenderId: '734364452238',
  appId: '1:734364452238:web:b6ea1c5fa21b6012ea50df',
  measurementId: 'G-536L9Y1875'
};
