import React from 'react';
import PropTypes from 'prop-types';
import { ModalWrapper } from './components';
import ModalPortal from './ModalPortal';
import { ReactComponent as CloseIcon } from '../../Assets/icons/circle-back.svg';
import { IconContainer, ModalContent } from 'Components/shared/styledComponents';

const SpoxioModal = ({
  visible,
  children,
  setVisible = () => {
    return null;
  },
  showCloseButton,
  width,
  padding,
  maxWidth,
  minHeight,
  maxHeight,
  background,
  borderRadius,
  minWidth,
  height,
  closeIconStyle
}) => {
  return (
    <>
      {visible && (
        <ModalPortal>
          <ModalWrapper>
            <ModalContent
              height={height}
              padding={padding}
              width={width}
              borderRadius={borderRadius}
              maxWidth={maxWidth}
              minHeight={minHeight}
              minWidth={minWidth}
              background={background}
              maxHeight={maxHeight}
            >
              {showCloseButton && (
                <IconContainer
                  opacity="0.85"
                  onClick={setVisible}
                  style={{
                    zIndex: '9',
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    ...closeIconStyle
                  }}
                >
                  <CloseIcon title="Close" />
                </IconContainer>
              )}
              {children}
            </ModalContent>
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};

SpoxioModal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  setVisible: PropTypes.func,
  width: PropTypes.string,
  padding: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  showCloseButton: PropTypes.bool,
  background: PropTypes.string,
  borderRadius: PropTypes.string,
  minWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  height: PropTypes.string,
  closeIconStyle: PropTypes.object
};

SpoxioModal.DefaultPropTypes = {
  showCloseButton: true
};

export default SpoxioModal;
