import styled from 'styled-components';

import HeaderBackground from 'Assets/img/invitationsHeader.png';
import { BaseTitle } from 'Components/shared/styledComponents';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const InvitationsWrapper = styled.div`
  width: 100%;
  max-width: 716px; //716px;
  min-height: calc(100vh - 8.5rem);
  overflow-y: auto;
  margin: 3vh auto 4px;
  position: relative;
  background: ${({ theme }) => theme?.spoxioBg};
  border-radius: 30px 30px 0 0;
  border: 1px solid ${({ theme }) => theme?.borderShade};
  border-bottom: 0;
`;

export const InvitationsHeader = styled.div`
  width: 100%;
  height: 150px;
  background-image: url(${HeaderBackground});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px 30px 0px 0px;
  box-shadow: 0px 18px 15px rgba(67, 95, 170, 0.1);
  position: relative;
`;

export const InvitationsBody = styled.div`
  position: relative;
  padding: 0 40px 20px;
`;

export const InvitationsModal = styled.div`
  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%);
  width: 100%;
  height: 100%;
  border-radius: 30px 30px 0px 0px;
`;

export const InvitationsTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: -20%;
  left: 2.5rem;
`;
export const ClubTitle = styled(BaseTitle)`
  width: 100%;
  color: ${({ theme }) => theme.defaultWhite};
  font-size: 1.75rem;
  margin-bottom: 10px;
  letter-spacing: -0.746124px;
`;

export const TitleProfile = styled(LazyLoadImage).attrs((props) => ({
  effect: props.effect || 'blur'
}))`
  max-height: 100%;
  max-width: 100%;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: none;
`;
// export const TitleProfile = styled.img`
//   width: 75px;
//   height: 75px;
//   border-radius: 50%;
//   border: none;
// `;

export const TitleDetails = styled.div``;

export const Title = styled.h4`
  color: ${({ theme }) => theme?.black};
`;

export const Subtitle = styled.h6`
  color: ${({ theme }) => theme?.lightGrayShade};
  font-size: 1rem;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: normal;
  letter-spacing: -0.608837px;
  line-height: 1.5rem;
`;

export const InvitationContent = styled.div`
  padding-top: 8vh;
  padding-bottom: 4vh;
`;

export const Paragraph = styled.div`
  max-width: 50%;
  font-size: 1.2rem;
  line-height: 1.55rem;
  color: ${({ theme }) => theme.black};
`;
export const ClubName = styled(Paragraph)`
  text-transform: capitalize;
`;

export const Paragraph2 = styled(Paragraph)`
  opacity: 0.8;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 30px;
`;

export const OtpWrapper = styled.div`
  width: 100%;
  padding: 25px 0;
`;

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'row')};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'nowrap')};
  align-items: center;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  gap: 10px;
`;
