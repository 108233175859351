import styled from 'styled-components';

export const LanguageWrapper = styled.select`
  color: #757575;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: inherit;
  margin: 0px;
  padding: 4px;
  font-weight: 700;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
`;
