import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAllTopics, getWhoToFollowList } from 'Store/discoverySlice';
import { BarTitle, ContentContainer, Divider } from 'Components/shared/styledComponents';
import { FollowUser } from 'Components/shared/FollowUser';
import { v4 as uuidv4 } from 'uuid';
import {
  getTrendingTopicsSelector,
  getWhoToFollowSelector,
  setSearch
} from 'Store/selectors/discoverySelector';
import { useTranslation } from 'react-i18next';
import NavBarUser from 'Components/OtherProfile/NavBarUser';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { DetailsPage } from 'Components/OtherProfile/components';
import { RightBarContainer } from './components';
import { push } from 'connected-react-router';
import { paths } from 'Utils/constants';
import { ListItem } from '../LeftBar/components';

export const RightBar = () => {
  const whoToFollow = useSelector(getWhoToFollowSelector);
  const profile = useSelector(getProfileSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isCurrentUser, data } = profile;
  const trendingTopics = useSelector(getTrendingTopicsSelector);
  const query = useSelector((state) => state?.router.location.query);

  useEffect(() => {
    if (!trendingTopics?.length || query?.topic) {
      dispatch(getAllTopics(query?.topic));
    }
  }, [query?.topic]);

  const onTopicClick = (topic) => {
    const search = { type: 'topic', data: topic };

    dispatch(setSearch(search));
    dispatch(push(`${paths.DISCOVERY}?topic=${topic.topic}`));
  };

  useEffect(() => {
    if (!whoToFollow.length) {
      dispatch(getWhoToFollowList());
    }
  }, []);

  return (
    <RightBarContainer>
      <DetailsPage padding="0" border="transparent" borderRadius="0px" mt={'0'}>
        <NavBarUser isCurrentUser={isCurrentUser} data={data} />
        {trendingTopics?.length ? (
          <>
            <BarTitle fontSize="1.35rem">{t('trending')}</BarTitle>
            <ContentContainer row flexWrap="wrap" gap="10px" width="100%">
              {trendingTopics
                .map((topic, index) => (
                  <ContentContainer column key={uuidv4()}>
                    <ListItem
                      textAlign="center"
                      fontSize="0.875rem"
                      key={`${topic.topic}-${index}`}
                      onClick={() => onTopicClick(topic)}
                    >
                      #{topic.topic}
                    </ListItem>
                  </ContentContainer>
                ))
                .slice(0, 24)}
            </ContentContainer>

            <Divider horizontal margin="10% 0 0" />
          </>
        ) : null}
        {whoToFollow?.length ? (
          <>
            <BarTitle fontSize="1.35rem">{t('who_to_follow')}</BarTitle>
            <ContentContainer column gap="20px" width="100%">
              {whoToFollow
                .map((user) => {
                  return <FollowUser key={user._id} user={user} />;
                })
                .slice(0, 16)}
            </ContentContainer>
          </>
        ) : null}
      </DetailsPage>
    </RightBarContainer>
  );
};
