import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FollowUserButton, IconContainerBg, NavBarUserContainer } from './components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Notification } from 'Assets/icons/NotificationIcon.svg';
import { ReactComponent as ChatSvg } from '../../Assets/icons/chat-with-black.svg';
import { ProfileDetails } from 'Components/ProfileRevamp/components';
import { UserPic } from 'Components/shared/UserPic';
import { UserName } from 'Components/shared/FollowUser/components';
import { paths } from 'Utils/constants';
import { ThemeContext } from 'Context/themeContext';
import { useDispatch } from 'react-redux';
import { FollowUser } from 'Components/shared/FollowUser';
import isEmpty from 'Utils/isEmpty';
import { ContentContainer, Title } from 'Components/shared/styledComponents';
import { truncate } from 'Utils/truncate';
import UserInfo from './UserInfo';

const NavBarUser = ({ isCurrentUser, data }) => {
  const dispatch = useDispatch();
  const History = useHistory();
  const { theme } = useContext(ThemeContext);
  const history = useHistory();

  const Edit = () => {
    history.push(`${paths.PROFILE}/${data?._id}/edit`);
  };
  const pushLink = (link) => {
    History.push(link);
  };

  // console.log(data);
  return (
    <>
      {!isCurrentUser ? (
        <NavBarUserContainer>
          <FollowUser user={data} />
        </NavBarUserContainer>
      ) : (
        <NavBarUserContainer justifyContent>
          <ContentContainer row gap="8px" alignItems="center">
            <UserPic size={50} src={data?.picture} />
            <ContentContainer column gap="0">
              <UserName
                title={`${data?.firstName || ''} ${data?.lastName || ''}`}
                weight={'600'}
                marginLeft="0"
                fontSize="1rem"
              >
                {!isEmpty(data) ? `${data?.firstName} ${data?.lastName}` : ''}
              </UserName>
              <UserInfo data={data} />
            </ContentContainer>
          </ContentContainer>
          <ContentContainer row alignItems="center">
            <IconContainerBg height="18px" width="18px" fill={theme.spoxioIcon}>
              <ChatSvg onClick={() => pushLink(paths.CHAT)} />
            </IconContainerBg>
            <IconContainerBg height="18px" width="18px" fill={theme.spoxioIcon}>
              <Notification onClick={() => pushLink(paths.NOTIFICATION)} />
            </IconContainerBg>
          </ContentContainer>
        </NavBarUserContainer>
      )}
    </>
  );
};

NavBarUser.propTypes = {
  isCurrentUser: PropTypes.bool,
  data: PropTypes.object
};
export default NavBarUser;
