import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { PostProp } from 'Utils/propTypes';
import { paths } from 'Utils/constants';
import { useAuthor } from 'Hooks/useAuthor';
import { fetchSinglePost } from 'Store/postSlice';
import { PostContainer, Description, PostStats } from './components';
import { PostHeader } from './PostHeader';
import { Actions } from './Actions';
import { PostMedia } from './PostMedia';
import { useTranslation } from 'react-i18next';
import { ContentContainer } from '../styledComponents';
import { func } from 'prop-types';
import { useState } from 'react';

export const Post = ({ data, onSelect }) => {
  const { author } = useAuthor();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [likeCount, setLikeCount] = useState(data.likeCount || 0)


  const selectPost = () => {
    //dispatch(viewPost(data, author));
    dispatch(fetchSinglePost(data._id));
    dispatch(push(`${paths.TIMELINE}/${data._id}`, { id: data._id }));
  };


  return (
    <PostContainer onClick={onSelect || selectPost}>
      <PostHeader author={author} data={data} />
      <PostMedia mediaList={data.contents} />
      <ContentContainer column>
        <Actions data={data} handleComment={selectPost} handleLike={setLikeCount} />
        {data?.text ? <Description width="auto">{data.text}</Description> : null}
        <PostStats>
          <ContentContainer row gap="8px" alignItems="center">
            <span>
              {likeCount} {likeCount > 1 ? ` ${t('likes_')} ` : ` ${t('like_')} `}{' '}
            </span>
            <span>
              {data.commentCount}{' '}
              {data.commentCount > 1 ? ` ${t('comments')} ` : ` ${t('comment')} `}
            </span>
            <span>
              {data.viewCount} {data.viewCount > 1 ? ` ${t('views_')} ` : ` ${t('view_')} `}
            </span>
          </ContentContainer>
        </PostStats>
      </ContentContainer>
    </PostContainer>
  );
};

Post.propTypes = {
  data: PostProp.isRequired,
  onSelect: func
};
