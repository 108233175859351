import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { sortArray } from 'Utils';
import { getStats } from 'Store/statsSlice';
import { getIsLoading } from 'Store/loaderSlice';
import { StatsItem } from './StatsItem';
import { StatsHead } from './StatsHead';
import { Messages } from './Messages';
import { StatsContainer, StatsItemsWrapper } from './components';
import { Loader } from 'Components/shared/Loader';
import { getStatsSelector, setStatsList } from 'Store/selectors/statsSelector';
import { useTranslation } from 'react-i18next';

const sorts = [
  {
    label: 'Stars',
    value: 'likeCount'
  },
  {
    label: 'Views',
    value: 'viewCount'
  },
  {
    label: 'Comments',
    value: 'commentCount'
  }
];

export const Stats = () => {
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [sort, setSort] = useState(sorts[0]);
  const [usersForMessage, setUsersForMessage] = useState([]);

  const isLoading = useSelector(getIsLoading);
  const users = useSelector(getStatsSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { register, watch, handleSubmit } = useForm();

  useEffect(() => {
    if (!users?.length) {
      dispatch(getStats());
    }

    return () => {
      const sortedUsers = sortArray([...users], 'likeCount', 'desc');

      dispatch(setStatsList(sortedUsers));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSort = (sortField) => {
    const sortedUsers = sortArray([...users], sortField.value, 'desc');

    setSort(sortField);
    dispatch(setStatsList(sortedUsers));
  };

  const onNextClick = (data, isShowMessages) => {
    const userIds = Object.keys(data).filter((key) => data[key] && data[key]);

    if (!userIds?.length) {
      toast.warn(t('please_select_users'));
      return;
    }

    const userObjects = users.filter((user) => userIds.includes(user._id));

    setUsersForMessage(userObjects);
    setShowMessage(isShowMessages);
    setShowCheckbox(false);
  };

  const checkboxes = watch(users?.map((item) => item._id));

  return (
    <StatsContainer showMessage={showMessage}>
      {isLoading && <Loader size={24} />}
      {showMessage ? (
        <Messages users={usersForMessage} setShowMessage={setShowMessage} />
      ) : (
        <>
          <StatsHead
            sorts={sorts}
            currentSort={sort}
            onSort={onSort}
            setShowCheckbox={setShowCheckbox}
            onNextClick={handleSubmit(onNextClick)}
          />
          <StatsItemsWrapper>
            {users?.map((item) => (
              <StatsItem
                key={item._id}
                ref={register}
                item={item}
                name={item._id}
                checked={checkboxes[item._id]}
                showCheckbox={showCheckbox}
              />
            ))}
          </StatsItemsWrapper>
        </>
      )}
    </StatsContainer>
  );
};
