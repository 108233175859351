// import { useTranslation } from 'react-i18next';
import { MASONRY_FIXTURES } from 'Utils/fixtures';
import {
  OnboardingContainer,
  Title,
  SubTitle,
  ImageContainer,
  DotsContainer,
  Dots,
  BottomOverlay
} from './components';
import Masonry from 'react-responsive-masonry';
import styled from 'styled-components';
import { hideScrollbar } from 'Components/shared/styledComponents';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const WrapperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const MasonryContainer = styled(Masonry)`
  ${hideScrollbar}
  border-radius: 0 0 24px 24px;
`;

export const SportMasonry = () => {
  //   const { t } = useTranslation();

  return (
    <WrapperContainer>
      <Title fontSize="1.4rem" fontWeight="bold" lineHeight="auto">
        Connect with sport <br /> professionals
      </Title>

      <SubTitle>
        The best way to see your favourite <br /> sport athlete live in action with
        <br /> photos and videos.
      </SubTitle>

      <DotsContainer>
        <Dots selected /> <Dots /> <Dots />
      </DotsContainer>

      <ImageContainer>
        <MasonryContainer
          columnsCount={2}
          gutter="10px"
          style={{ height: '100%', overflowY: 'auto' }}
        >
          <LazyLoadImage
            src={MASONRY_FIXTURES.image1}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image6}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image2}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image7}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image3}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />
          <LazyLoadImage
            src={MASONRY_FIXTURES.image8}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image4}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image9}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />
          <LazyLoadImage
            src={MASONRY_FIXTURES.image5}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />

          <LazyLoadImage
            src={MASONRY_FIXTURES.image10}
            alt=""
            effect="blur"
            style={{ width: '100%', display: 'block' }}
          />
        </MasonryContainer>
        <BottomOverlay />
      </ImageContainer>
    </WrapperContainer>
  );
};
