import { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { RouteProps } from 'Utils/propTypes';
import { paths } from 'Utils/constants';
import { getNumberWithText } from 'Utils/strings';
import { fetchSinglePost, getPostById, sendCommentPost } from 'Store/postSlice';
import { getCurrentUserId } from 'Store/authSlice';
import { useLivestream } from 'Hooks/useLivestream';
import {
  MainPost,
  LeftSide,
  RightSide,
  ActionBarContainer,
  PostData,
  CommentContainer,
  CloseContainer,
  LogoContainer,
  LivestreamStatisticContainer,
  LivestreamStatisticText,
  LeftSideMobile,
  RightSideMobile
} from './components';
import { Description } from 'Components/shared/Post/components';
import { PostHeader } from 'Components/shared/Post/PostHeader';
import { Actions } from 'Components/shared/Post/Actions';
import { PostComment } from 'Components/shared/PostComment';
import { CommentInput } from 'Components/shared/PostComment/CommentInput';
import { ActionsBar } from 'Components/Navigation/NavBar/ActionsBar';
import { PostMedia } from 'Components/shared/Post/PostMedia';
import { CommentsWrapper } from 'Components/shared/PostComment/components';
import { LivestreamPlayer } from 'Components/shared/Post/LivestreamPlayer';
import { LivestreamChat } from 'Components/LivestreamChat';

import { ReactComponent as Close } from 'Assets/icons/Close.svg';
import LogoSecondary from 'Assets/img/Logo.png';

import { getPostSelector, setPost } from 'Store/selectors/postSelector';
import {
  getCommentsSelector,
  setCommentReplies,
  setComments
} from 'Store/selectors/commentSelector';
import { fetchPostCommentReplies, fetchPostComments } from 'Store/commentSlice';
import { getIsAuthorizedSelector } from 'Store/selectors/authSelector';
import { ThemeContext } from 'Context/themeContext';
import { blockUser, unBlockUser } from 'Store/profileSlice';
import { MainLogo } from 'Components/shared/Logos/MainLogo';
import { FormContainer } from 'Components/PostModal/components';
import { SpoxioBlueBtn } from 'Components/shared/styledComponents';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Api from 'middleware/api';
import { useTranslation } from 'react-i18next';

export const PostDetail = ({ location, history }) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [parentCommentId, setParentCommentId] = useState(null);
  const inputRef = useRef(null);
  const [editPost, setEditPost] = useState(false);

  const post = useSelector(getPostSelector);
  const comments = useSelector(getCommentsSelector);
  const currentUserId = useSelector(getCurrentUserId);
  const isAuthorized = useSelector(getIsAuthorizedSelector);
  const query = useSelector((state) => state?.router?.location?.query);
  const dispatch = useDispatch();

  const { livestream, viewers, sendMessage, likeLivestream, likeMessage } = useLivestream(location);
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    const path = location.pathname.split('/');
    const dataId = path[path.length - 1];

    if (!post && !query?.isLivestream) {
      dispatch(getPostById(dataId));
    }
    dispatch(fetchSinglePost(dataId));
    dispatch(fetchPostComments(dataId));
    dispatch(fetchPostCommentReplies(dataId));
    return () => {
      dispatch(setPost({}));
      dispatch(setComments([]));
      dispatch(setCommentReplies([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem('resourceId');
    localStorage.removeItem('livestreamViewers');
  }, []);

  const onClose = () => {
    // if (location?.state?.id) {
    //   history.goBack();
    // } else {
    //   history.push(paths.TIMELINE);
    // }
    history.goBack();
  };

  const onReport = () => {
    if (query?.isLivestream) {
      history.push(`${location.pathname}/report?livestreamId=${livestream.id}`);
    } else {
      history.push(`${location.pathname}/report`);
    }
  };

  const onBlock = () => {
    dispatch(blockUser(post?.author?._id));
    console.log('fired');
  };

  const unBlock = () => {
    dispatch(unBlockUser(post?.author?._id));
  };

  const onReply = (parentCommentId) => {
    inputRef?.current.focus();
    setParentCommentId(parentCommentId);
  };

  const onComment = () => {
    inputRef?.current.focus();
    setParentCommentId(0);
  };

  const onSendComment = (text) => {
    if (query?.isLivestream) {
      sendMessage(text);
    } else {
      dispatch(sendCommentPost(text, post._id, parentCommentId));
      setParentCommentId(null);
    }
  };

  const onLike = (comment) => {
    //dispatch(likeComment(comment));
  };

  const onSubmit = async (data) => {
    console.log(data, 'submit edit data');
    try {
      await Api.post.editPost(post._id, { text: data?.post });
      setEditPost(!editPost);
      toast.success('Updated');
      dispatch(fetchSinglePost(post._id));
    } catch (error) {
      console.log('onSaveEditPost::', error);
      setEditPost(!editPost);
    }
  };

  return isMobile ? (
    <>
      <ActionBarContainer>
        <ActionsBar size="100%" />
      </ActionBarContainer>
      <LeftSideMobile>
        <LogoContainer>
          <img src={LogoSecondary} alt="logo" />
        </LogoContainer>
        {query?.isLivestream ? (
          <LivestreamPlayer resourceId={livestream.resourceId} />
        ) : (
          <PostMedia
            mediaList={post?.contents || []}
            sliderProps={{ classNameTray: 'post-detail-slide' }}
          />
        )}
        <CloseContainer onClick={onClose}>
          <Close color={theme.darkGray} />
        </CloseContainer>
      </LeftSideMobile>
      <RightSideMobile>
        <PostData>
          {livestream?.id ? (
            <>
              <PostHeader
                author={{
                  id: livestream.uid,
                  name: livestream.username,
                  picture: livestream.profile
                }}
                data={{
                  sport: livestream.sport,
                  address: livestream.location,
                  createdAt: livestream.createdAt
                }}
              />
              <Description>{livestream.description}</Description>
              <LivestreamStatisticContainer>
                <LivestreamStatisticText>
                  {getNumberWithText(viewers, 'view')}
                </LivestreamStatisticText>
                <LivestreamStatisticText>
                  {getNumberWithText(livestream.likes.length, 'star')}
                </LivestreamStatisticText>
                <LivestreamStatisticText>
                  {getNumberWithText(livestream.messages.length, 'comment')}
                </LivestreamStatisticText>
              </LivestreamStatisticContainer>
              <Actions
                data={livestream}
                isLivestream
                handleComment={onComment}
                handleLike={likeLivestream}
                onReport={livestream.uid !== currentUserId ? onReport : null}
              />
              {livestream.messages ? (
                <LivestreamChat
                  messages={livestream.messages}
                  onStarMessage={(id) => likeMessage(id, currentUserId)}
                />
              ) : null}
            </>
          ) : (
            <>
              <PostHeader author={post} data={post} />
              {editPost ? (
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                  <FormInput ref={register} name="post" placeholder={post.text} />
                  <SpoxioBlueBtn>{t('save')}</SpoxioBlueBtn>

                  <Description>{post.text}</Description>
                </FormContainer>
              ) : (
                <Description>{post.text}</Description>
              )}
              {isAuthorized && (
                <Actions
                  data={post}
                  handleComment={onComment}
                  onReport={post._id !== currentUserId ? onReport : null}
                  onBlock={post?.author?._id !== currentUserId ? onBlock : null}
                />
              )}
              {isAuthorized && (
                <CommentsWrapper>
                  {comments.length > 0
                    ? comments.map((comment) => (
                        <PostComment
                          key={comment._id}
                          comments={comments}
                          comment={comment}
                          onReply={onReply}
                          onLike={onLike}
                          onReport={post?.author?._id !== currentUserId ? onReport : null}
                        />
                      ))
                    : null}
                </CommentsWrapper>
              )}
            </>
          )}
        </PostData>
        {isAuthorized && (
          <CommentContainer>
            <CommentInput ref={inputRef} onSendComment={onSendComment} />
          </CommentContainer>
        )}
      </RightSideMobile>
    </>
  ) : (
    <MainPost>
      <LeftSide>
        <LogoContainer>
          <img src={LogoSecondary} alt="logo" />
        </LogoContainer>
        {query?.isLivestream ? (
          <LivestreamPlayer resourceId={livestream.resourceId} />
        ) : (
          <PostMedia
            slideStyle={{ height: '100vh' }}
            isIntrinsicHeight={true}
            mediaList={post?.contents || []}
            sliderProps={{ classNameTray: 'post-detail-slide' }}
          />
        )}
        <CloseContainer onClick={onClose} fill={theme.spoxioIcon}>
          <Close color={theme.mediumGray} />
        </CloseContainer>
      </LeftSide>
      <RightSide>
        <ActionBarContainer>
          <ActionsBar size="100%" />
        </ActionBarContainer>
        <PostData>
          {livestream?.id ? (
            <>
              <PostHeader
                author={{
                  id: livestream.uid,
                  name: livestream.username,
                  picture: livestream.profile
                }}
                data={{
                  sport: livestream.sport,
                  address: livestream.location,
                  createdAt: livestream.createdAt
                }}
              />
              <Description>{livestream.description}</Description>
              <LivestreamStatisticContainer>
                <LivestreamStatisticText>
                  {getNumberWithText(viewers, 'view')}
                </LivestreamStatisticText>
                <LivestreamStatisticText>
                  {getNumberWithText(livestream.likes.length, 'star')}
                </LivestreamStatisticText>
                <LivestreamStatisticText>
                  {getNumberWithText(livestream.messages.length, 'comment')}
                </LivestreamStatisticText>
              </LivestreamStatisticContainer>
              <Actions
                data={livestream}
                isLivestream
                handleComment={onComment}
                handleLike={likeLivestream}
                onReport={livestream.uid !== currentUserId ? onReport : null}
                onBlock={livestream.uid !== currentUserId ? onBlock : null}
              />
              {livestream.messages ? (
                <LivestreamChat
                  messages={livestream.messages}
                  onStarMessage={(id) => likeMessage(id, currentUserId)}
                />
              ) : null}
            </>
          ) : (
            <>
              <PostHeader author={post} data={post} flexWrap="wrap" />

              {editPost ? (
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                  <FormInput
                    ref={register}
                    name="post"
                    placeholder={post.text}
                    defaultValue={post.text}
                  />
                  <SpoxioBlueBtn>{t('Save')}</SpoxioBlueBtn>
                </FormContainer>
              ) : (
                <Description>{post.text}</Description>
              )}

              {isAuthorized && (
                <Actions
                  data={post}
                  edit={editPost}
                  setEditPost={(v) => setEditPost(v)}
                  handleComment={onComment}
                  onReport={post._id !== currentUserId ? onReport : null}
                  onBlock={post?.author?._id !== currentUserId ? onBlock : null}
                />
              )}

              {isAuthorized && (
                <CommentsWrapper>
                  {comments.length > 0
                    ? comments.map((comment) => (
                        <PostComment
                          key={comment._id}
                          comments={comments}
                          comment={comment}
                          onReply={onReply}
                          onLike={onLike}
                          onReport={post?.author?._id !== currentUserId ? onReport : null}
                        />
                      ))
                    : null}
                </CommentsWrapper>
              )}
            </>
          )}
        </PostData>
        {isAuthorized && (
          <CommentContainer>
            <CommentInput ref={inputRef} onSendComment={onSendComment} />
          </CommentContainer>
        )}
      </RightSide>
    </MainPost>
  );
};

PostDetail.propTypes = {
  ...RouteProps
};
