import { useState, useEffect } from 'react';

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
      document.getElementById('custom-modal-overlay').style.display = 'block';
      document.getElementById('custom-modal-overlay').addEventListener('click', closeModal);
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.getElementById('custom-modal-overlay').removeEventListener('click', closeModal);
      document.getElementById('custom-modal-overlay').style.display = 'none';
    };
  }, [isModalOpen]);

  return { isModalOpen, openModal, closeModal };
};
