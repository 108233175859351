import { Title } from 'Components/Layouts/AuthLayout/components';
import { ContentContainer, IconContainer } from 'Components/shared/styledComponents';
import { bool } from 'prop-types';
import styled from 'styled-components';

export const NavBarUserContainer = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => (justifyContent ? 'space-between' : 'flex-end')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  width: 100%;
  align-items: center;
  gap: 4px;
  flex-wrap: ${({ flexWrap }) => flexWrap};
`;

NavBarUserContainer.propTypes = {
  justifyContent: bool
};

export const NavBarProfileContainer = styled.div`
  display: flex;
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ justifyContent }) => (justifyContent ? 'space-between' : 'flex-end')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  width: 100%;
  align-items: center;
  gap: ${({ cg }) => cg || '5px'};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ border, theme }) => (border ? border : theme.borderShade)};
  padding: ${({ padding }) => (padding ? padding : '20px')};
  background: ${({ background, theme }) => background || theme.background};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '20px')};
`;

export const IconContainerBg = styled(IconContainer)`
  border-radius: 50%;
  padding: 10px;
  margin: 0;
  &:hover {
    background: rgba(60, 86, 95, 0.4);
    backdrop-filter: blur(10px);

    path {
      fill: ${({ theme }) => theme.defaultWhite};
    }
  }
`;

export const FollowUserButton = styled.button`
  background: ${({ theme, alt }) =>
    alt === 'true'
      ? `linear-gradient(to bottom right, #00a5c9, #0076de)`
      : theme.spoxioAltBlue}; //;
  color: #fff;
  border: none;
  width: ${({ width }) => (width ? width : 'auto')};
  min-width: ${({ minWidth }) => minWidth};
  border-radius: ${({ borderRadius }) => borderRadius || '12px'};
  cursor: pointer;
  display: flex;
  margin: ${({ margin }) => margin};
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 700;
  padding: ${({ padding }) => padding || '12px'};
`;

export const DetailsPage = styled.div`
  min-width: ${({ mw }) => (mw ? mw : '100%')};
  /* width: 100%; */
  max-width: ${({ maxWidth }) => maxWidth || '25vw'};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ border, theme }) => (border ? border : theme.borderShade)};
  padding: ${({ padding }) => (padding ? padding : '20px')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '20px')};
  min-height: ${({ mh }) => (mh ? mh : '60vh')};
  margin-top: ${({ mt }) => (mt ? mt : '10px')};
  overflow-y: scroll;
  background: ${({ background }) => background};
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

// export const DetailsTag = styled.div`
export const DetailsTag = styled.button`
  background: ${({ background }) => background || 'transparent'};
  width: ${({ width }) => width || '110px'};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  padding: ${({ padding }) => padding};
  height: 39px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  border-radius: 10px;
  color: ${({ color, theme }) => (color ? color : theme.spoxioText)};
  border: 1.76px solid ${({ theme, active }) => (active ? theme.spoxioAltBlue : theme.borderShade)};
`;

export const AboutDetails = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AboutTags = styled(Title)`
  color: ${({ theme, color }) => (color ? color : theme.gray)};
  font-weight: ${({ weight }) => (weight ? weight : 600)};
  display: flex;
  font-family: ${({ fontFamily }) => fontFamily};
  flex-direction: row;
  column-gap: 5px;
  flex-wrap: wrap;
  text-align: ${({ center }) => center};
  text-transform: capitalize;
  font-size: ${({ size }) => (size ? size : '1.2rem')};
  margin-top: ${({ m }) => (m ? m : '0px')};
  margin: ${({ ma }) => ma};
`;
export const AboutText = styled(Title)`
  color: ${({ theme }) => theme.lightGray3};
  display: block;
  font-size: ${({ textSize }) => (textSize ? textSize : '22px')};
  line-height: ${({ line }) => (line ? line : '25px')};
`;

export const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RowPostContainer = styled.div`
  display: flex;
  column-gap: 8px;
  width: 100%;
`;
export const RowImageStack = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const VideoWrapper = styled.div``;

// export const ButtonsTag = styled.div`
export const ButtonsTag = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  column-gap: ${({ gap }) => gap || '12px'};
  margin-top: 10px;
  margin-bottom: ${({ m }) => (m ? m : '0px')};
  color: ${({ color }) => color};
  overflow-x: auto;
  width: 100%;
`;

export const ParagraphText = styled.p`
  font-size: 14px;
  display: inline-block;
  margin-top: 5px;
`;

export const ModalContent = styled.div`
  margin: auto auto;
  backdrop-filter: blur(4px);
  border-radius: 30px;
  padding: 33px 23px;
  width: ${({ w }) => (w ? w : '572px')};
  min-height: 360px;
  background: ${({ theme }) => theme.background};
  position: relative;
`;

export const ModalLinks = styled.button`
  display: flex;
  margin: 0 auto;
  border-radius: 10px;
  min-height: 48px;
  cursor: pointer;
  color: ${({ color, theme }) => color || theme.spoxioIcon};
  font-size: 16px;
  background: rgba(35, 34, 38, 1);
  min-width: 483px;
  border: none;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`;
export const ModalLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: ${({ mt }) => (mt ? mt : '4px')};
  align-items: center;
  justify-content: center;
`;
export const ModalAdminLinks = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ color, theme }) => color || theme.spoxioIcon};
  background: rgba(35, 34, 38, 1);
  min-width: 430px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const ModalAdminItem = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ color, theme }) => color || theme.spoxioIcon};
  background: ${({ background }) => background || 'rgba(35, 34, 38, 1)'};
  min-width: 430px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
`;

export const LeftSideModal = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 56px;
  padding: 18px 23px;
  width: ${({ width }) => width};
`;
export const LeftSideText = styled.p`
  font-size: 16px;
  display: block;
  margin: auto 0;
`;
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: ${({ mt }) => (mt ? mt : '4px')};
  padding: 5px 45px;
`;
