import styled from 'styled-components';

export const StoriesContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.black};

  & > :nth-child(3) {
    & > :nth-child(2) {
      & > :first-child {
        width: 100%;
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  position: absolute;
  top: 10px;
  z-index: 1;
`;

export const CloseStoriesButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const LogoImage = styled.img`
  width: 38px;
`;

export const UserHeader = styled.div`
  position: absolute;
  left: 35%;
  top: 20px;
  z-index: 999999;
  width: 180px;
  height: 44px;
  cursor: pointer;
`;

export const NavigationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95vw;
  height: 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
`;

export const PrevButtonContainer = styled.div`
  cursor: pointer;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const NextButtonContainer = styled.div`
  cursor: pointer;
  /* margin-left: 10vw; */
`;
