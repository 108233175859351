import styled from 'styled-components';

export const SliderWrapper = styled.div`
  color: ${({ color }) => (color ? color : 'column')};
  display: flex;
  justify-content: center;
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'column')};
  height: 50vh;
  max-height: 50vh;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  display: 'flex';
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'column')};
  height: '100%';
`;
