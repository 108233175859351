import Api from 'middleware/api';
import { toast } from 'react-toastify';
import { setLoading } from './loaderSlice';
import {
  setDiscoveryList,
  setSearchData,
  setTopics,
  setUserInterestTrending,
  setWhoToFollow
} from './selectors/discoverySelector';

// thunks
export const getWhoToFollowList = () => async (dispatch) => {
  try {
    const res = await Api.user.whoToFollow();

    dispatch(setWhoToFollow(res.data.data));
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

export const getPostsBySearch = (search) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const posts =
      search.type === 'sport'
        ? await Api.posts.getBySports(search.data.name)
        : await Api.posts.getByTopic(search.data.name);

    dispatch(setDiscoveryList(posts));
    dispatch(setLoading(false));
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
  }
};

export const getAllTopics = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await Api.post.fetchTopics();
    const { data } = res.data;
    dispatch(setTopics(data));
    dispatch(setLoading(false));
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
  }
};

export const getSearchUsers = (value) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const users = await Api.user.searchUser(value);
    dispatch(setSearchData({ users: users.data.data, topics: [], interests: [] }));
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    return false;
  }
};

export const getSearchInterests = (value) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const interests = await Api.post.postInterest(value);
    dispatch(setSearchData({ users: [], topics: [], interests: interests.data.data }));
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    return false;
  }
};

export const getSearchTopics = (value) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const topics = await Api.post.fetchTopicPost(value);
    dispatch(setSearchData({ users: [], topics: [], interests: topics.data.data }));
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    return false;
  }
};

export const getUserInterestTrending = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const interestTrending = await Api.post.trendingUserInterest();
    dispatch(setUserInterestTrending(interestTrending.data.data));
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    return false;
  }
};
