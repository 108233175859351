import { useRef, useContext } from 'react';
import { func, shape, string } from 'prop-types';
import { PostItemListProps } from 'Utils/propTypes';
import {
  PostSlideContainer,
  PostImage,
  PostVideo,
  PostPlayContainerSmall,
  PostMediaContainerSmall
} from './components';
import { ReactComponent as PlayIcon } from 'Assets/icons/PlayFilled.svg';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';

export const PostMediaThumbnail = ({ mediaList, onSelect, height, width }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const videoRef = useRef(null);

  return (
    <PostMediaContainerSmall height={height} width={width} onClick={onSelect}>
      <PostSlideContainer borderRadius="8px" background={mediaList[0].url}>
        {mediaList[0].type === 'video' ? (
          <>
            <PostVideo ref={videoRef} src={mediaList[0].url} borderRadius="8px" />
            <PostPlayContainerSmall>
              <PlayIcon width={12} height={12} color={theme.spoxioIcon} stroke={theme.mediumGray} />
            </PostPlayContainerSmall>
          </>
        ) : (
          <PostImage
            src={mediaList[0].url}
            alt={t('post_item')}
            borderRadius="8px"
            // height={'100px'}
            width={'100%'}
            style={{ objectFit: 'cover' }}
          />
        )}
      </PostSlideContainer>
    </PostMediaContainerSmall>
  );
};

PostMediaThumbnail.propTypes = {
  mediaList: PostItemListProps,
  sliderProps: shape({}),
  height: string,
  width: string,
  onSelect: func
};

PostMediaThumbnail.defaultProps = {
  mediaList: [],
  onSelect: () => {}
};
