import { arrayOf, func, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { getCurrentUserId } from 'Store/authSlice';
import { BlackText } from 'Components/shared/styledComponents';
import { UserPic } from 'Components/shared/UserPic';
import {
  LivestreamChatContainer,
  LivestreamMessageContainer,
  MessageUserIconContainer,
  MessageUserName,
  MessageStarText
} from './components';

import { ReactComponent as Star } from 'Assets/icons/AuthFanRole.svg';
import { ReactComponent as RedStar } from 'Assets/icons/RedStar.svg';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';

export const LivestreamChat = ({ messages, onStarMessage }) => {
  const currentUserId = useSelector(getCurrentUserId);
  const { theme } = useContext(ThemeContext);

  return (
    <LivestreamChatContainer>
      {messages.map((message) => (
        <LivestreamMessageContainer key={message.id}>
          <MessageUserIconContainer>
            <UserPic src={message.avatar} size={32} />
          </MessageUserIconContainer>
          <MessageUserName>{message.name}</MessageUserName>
          <BlackText>{message.text}</BlackText>
          <MessageStarText onClick={() => onStarMessage(message.id)}>
            {message.likes.includes(currentUserId) ? (
              <RedStar width={12} height={12} />
            ) : (
              <Star width={12} height={12} color={theme?.darkGray1} />
            )}
            {message.likes.length ? ` ${message.likes.length}` : null}
          </MessageStarText>
        </LivestreamMessageContainer>
      ))}
    </LivestreamChatContainer>
  );
};

LivestreamChat.propTypes = {
  messages: arrayOf(
    shape({
      id: string,
      uid: string,
      avatar: string,
      name: string,
      text: string,
      likes: arrayOf(string)
    })
  ),
  onStarMessage: func
};

LivestreamChat.defaultProps = {
  messages: [],
  onStarMessage: () => {}
};
