import moment from 'moment';

export const formatDate = (date, formatString = 'DD-MMM-YYYY') =>
  date ? moment(date).format(formatString) : '';

export const dateMeridiem = (hours) => {
  if (hours > 11) {
    return 'pm';
  }
  return 'am';
};

export const maxDateForDobField = (i) =>
  moment(new Date())
    .subtract(i ? i : 13, 'years')
    .toDate();

export const getDateDifference = (date) => (date ? moment(date).fromNow() : '');

export const getAgeDifference = (date) =>
  date ? moment(new Date()).diff(moment(date), 'years') : '';

export const getYears = (from, to) => {
  const years = [];

  for (let i = from; i <= to; i += 1) {
    years.push(i);
  }

  return years;
};

export const getDateAfterPeriod = (date, periodType, period, formatString = 'MM/DD/YYYY') =>
  moment(date).add(period, periodType).format(formatString);

export const getDuration = (start, end) => {
  const noww = moment(start);
  const endd = moment(end);

  return endd.diff(noww, 'minutes');
};
