import styled from 'styled-components';
import { Line, Circle } from 'rc-progress';

export const ClubDetailsContainer = styled.div`
  display: flex;
  column-gap: 15px;
  background: ${({ theme }) => theme.spoxioBg};
  padding: 15px 8px;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.darkGray2};
`;
export const ImageHolder = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
`;

export const NameHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;
export const NameHead = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  width: 100%;
`;

export const NameBottom = styled.h6``;

export const ProfileButton = styled.button`
  width: 94px;
  height: 44px;
  background: ${({ theme }) => theme.spoxioAltBlue};
  border-radius: 12px;
  border: none;
`;
export const ScoutContents = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScoutStatsContainer = styled.div`
  display: flex;
  column-gap: 12px;
  margin-top: 10px;
  justify-content: space-between;
  overflow-x: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;
// suggested talents
export const SuggestedTalentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
export const SuggestedTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;
export const SuggestedTalentsText = styled.p``;
export const TalentImages = styled.div`
  display: flex;
  column-gap: 6px;
  overflow-x: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;
// Lastest struck includes a followers component card

export const FollowersContainer = styled.div`
  display: flex;
  column-gap: 10px;
  display: flex;
  column-gap: 15px;
  background: ${({ theme }) => theme.spoxioBg};
  padding: 10px 8px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.darkGray2};
  justify-content: space-between;
`;
export const FollowersDetails = styled.div`
  display: flex;
  justify-content: between;
  column-gap: 3px;
`;

export const FollowersName = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FollowersStack = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 10px;
`;

export const MainPostContainer = styled.div`
  border: 0.85px solid ${({ theme }) => theme.borderShade};
  border-radius: 30px;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
`;
export const TalentInfo = styled.div`
  display: flex;
  gap: 4px;
  justify-content: end;
`;
export const TalentMedia = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  border-radius: 30px;
  padding: 10px 12% 10px;
`;
export const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4%;
`;
export const OverviewHead = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ViewProfile = styled.button`
  background: ${({ theme }) => theme.opacityBlue};
  color: ${({ theme }) => theme.spoxioAltBlue};
  font-weight: 600;
  min-width: 100px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-size: 13px;
  padding: 8px;

  &:hover {
    opacity: 0.85;
  }
`;
export const InfoTeam = styled.div`
  display: flex;
  column-gap: 100px;
`;
export const TeamsHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextInfo = styled.p`
  margin: 0;
  color: ${({ color, theme }) => (color ? color : theme.spoxioIcon2)};
  font-size: 15px;
`;

export const StatsProgress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const LinearStats = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 70%;
`;
export const ProgressStats = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ProgressBar = styled(Line)`
  color: ${({ theme }) => theme.gray};
`;
export const ProgressBarCircle = styled(Circle)`
  color: ${({ theme }) => theme.gray};
`;
