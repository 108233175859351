import { colors, theme } from '../../../../Theme';
const themeMode = window.localStorage.getItem('themeMode');

export const styles = {
  container: () => ({
    width: '100%',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: `${colors.neutralGray}`
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 'auto',
    border: 'none',
    fontSize: '1rem',
    padding: '8px',
    width: '100%',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme?.colors?.neutralGray,
    color: theme?.colors?.mediumGray,
    backgroundColor: 'transparent', // theme?.colors?.cardSubc, // 'transparent',
    boxShadow: 'none',
    ':hover': {
      borderColor: colors.mediumGray
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 0'
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    fontFamily: 'SF Pro',
    color: colors.mediumGray //themeMode === 'light' ? colors.defaultBlack : colors.defaultWhite
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    // backgroundColor: isFocused ? colors.opacityPrimaryBlue : 'transparent',
    // color: isSelected ? colors.gray : colors.black
    backgroundColor: isFocused ? theme?.colors?.opacityLightGray1 : 'transparent',
    color: isSelected ? `${({ theme }) => theme.spoxioText}` : `${({ theme }) => theme.background}`,
   
  }),
  placeholder: () => ({
    fontFamily: 'SF Pro',
    // color: colors.opacityGray
    color: theme?.colors?.spoxioBodyText,
    fontSize: '0.85rem'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '2px 0',
    color: colors.secondaryGray
  }),
  indicatorSeparator: () => ({
    display: 'none'
  })
};
