import { Col, Grid, Row } from 'antd';
import { AboutTags } from 'Components/OtherProfile/components';
import { ThemeContext } from 'Context/themeContext';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  ChooseTopicContainer,
  TagsContainer
} from './components';
import TopicCard from './TopicCard';

const ChoseTopic = ({ sportType }) => {
  const { theme } = useContext(ThemeContext);
  const [topic, setTopic] = useState([]);

  const club = [
    'Shooting Stars Jr',
    'Barca Fc',
    'MFM',
    'Manchester town fc',
    'Dominion men',
    'Stars club'
  ];

  const athletes = [
    'Lionel messi',
    'Usain Bolt',
    'Bamiji Olayinka',
    'Emm. Ekong',
    'Kelechi Iyenacho',
    'Jayjay'
  ];

  const hashtags = ['#AFCON', 'NBA', 'EPL', 'FIFA', 'WWE', 'NPFL', 'Kelechi Iyenacho', 'Jayjay'];

  useEffect(() => {
    sportType === 'clubs'
      ? setTopic(club)
      : sportType === 'athlete'
      ? setTopic(athletes)
      : sportType === 'hashtags'
      ? setTopic(hashtags)
      : setTopic(club);
  }, [sportType]);
  return (
    <>
      <ChooseTopicContainer>
        <AboutTags size={'0.875rem'}>{sportType}</AboutTags>
        {/* The Tags in between design */}
        <TagsContainer>
          <Row gutter={[8, 8]}>
            {topic?.map((data) => (
              <Col span={12}>
                <TopicCard data={data} />
              </Col>
            ))}
          </Row>
        </TagsContainer>
      </ChooseTopicContainer>
    </>
  );
};

ChoseTopic.propTypes = {
  sportType: PropTypes.string
};

export default ChoseTopic;
