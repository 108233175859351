import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as AddIconFilled } from '../../../Assets/icons/AddIconFilled.svg';
import { ReactComponent as BlueCalendar } from '../../../Assets/icons/calender-blue.svg';
import { CircleContainer } from '../EventDetails/components';
import ModalPortal from 'Components/Modals/ModalPortal';
import { ModalProfile } from 'Components/Modals/components';
import { SchedulingContainer, SchedulingTag, SchedulingText } from './components';
import { t } from 'i18next';
import { ModalContent } from 'Components/OtherProfile/components';
import SchedueModalDetails from './SchedueModal';
import { ThemeContext } from 'Context/themeContext';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { dateMeridiem, formatDate } from 'Utils/dates';
import isEmpty from 'Utils/isEmpty';
import { useForm } from 'react-hook-form';
import { any, arrayOf, func, object, string } from 'prop-types';

const EventScheduling = ({ setSchedule, schedule, formData }) => {
  const { theme } = useContext(ThemeContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {}, [schedule]);

  return (
    <>
      <SchedulingContainer onClick={showModal}>
        <SchedulingTag>
          <CircleContainer size={'24px'} color={theme?.spoxioBlue}>
            <AddIconFilled />
          </CircleContainer>
          <SchedulingText>Add a Schedule</SchedulingText>
        </SchedulingTag>
        <BlueCalendar />
      </SchedulingContainer>
      <ContentContainer column margin="20px 0" hideScroll gap="25px" width="100%">
        {!isEmpty(schedule) && schedule[0]
          ? schedule?.map((scheduleItem, i) => {
              const startTime =
                scheduleItem?.start_time +
                dateMeridiem(+scheduleItem?.start_time[0] + scheduleItem?.start_time[1]);
              const endTime =
                scheduleItem?.start_time +
                dateMeridiem(+scheduleItem?.end_time[0] + scheduleItem?.end_time[1]);

              return (
                <ContentContainer
                  key={i}
                  row
                  flexWrap="wrap"
                  gap="6px"
                  width="100%"
                  minHeight="7vh"
                >
                  <ContentContainer width="10%" padding="12px 5px">
                    <SubTitle width="100%" fontSize="0.875rem">
                      {formatDate(scheduleItem?.date, 'DD MMM')}
                    </SubTitle>
                  </ContentContainer>
                  <ContentContainer
                    borderRadius="14px"
                    width="90%"
                    gap="8px"
                    padding="12px 10px"
                    background={theme.spoxioLinear2}
                  >
                    <Title fontSize="1.25rem" color={theme.defaultWhite}>
                      {scheduleItem?.title}
                    </Title>
                    <Title fontSize="0.875rem" opacity="0.75" color={theme.defaultWhite}>
                      {startTime} - {endTime}
                    </Title>
                  </ContentContainer>
                </ContentContainer>
              );
            })
          : null}
      </ContentContainer>

      {isModalVisible && (
        <ModalPortal>
          <ModalProfile>
            <ModalContent>
              <SchedueModalDetails
                formData={formData}
                setActive={setIsModalVisible}
                setSchedule={setSchedule}
                schedule={schedule}
              />
            </ModalContent>
          </ModalProfile>
        </ModalPortal>
      )}
    </>
  );
};
EventScheduling.propTypes = {
  setSchedule: func,
  schedule: arrayOf(object),
  formData: any
};

export default EventScheduling;
