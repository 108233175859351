import { shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import Api from 'Api';
import { paths } from 'Utils/constants';
// import { getTextByCount } from 'Utils/strings';
import { getCurrentUserId } from 'Store/authSlice';
import { blockUser, followOnUser, unBlockUser, unfollowOnUser } from 'Store/profileSlice';
import {
  ProfileHeadContainer,
  UserImage,
  UserName,
  Statistic,
  StatisticItem,
  Description,
  EditBtn,
  BtnContainer,
  Button,
  EditIcon,
  CommentIcon
} from '../ProfileRevamp/components';
import { UserPic } from 'Components/shared/UserPic';
import { ReadMore } from 'Components/shared/ReadMore';
import { FollowButton } from 'Components/shared/FollowButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ProfileHead = ({ profile }) => {
  const { data, posts, isBlocked, isCurrentUser } = profile;
  const { t } = useTranslation();
  const [isFollow, setIsFollow] = useState(data?.isFollowedByMe);

  const currentUserId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();

  const openEdit = () => dispatch(push(`${paths.PROFILE}/${data._id}/edit`));

  const openFollowers = () => dispatch(push(`${paths.PROFILE}/${data._id}/followers`));

  const openFollowing = () => dispatch(push(`${paths.PROFILE}/${data._id}/following`));

  const onFollow = () => {
    if (isFollow) {
      dispatch(unfollowOnUser(data._id));
      setIsFollow(!isFollow);
    } else {
      dispatch(followOnUser(data._id));
      setIsFollow(!isFollow);
    }
  };

  const goToChat = (groupId) => dispatch(push(`${paths.CHAT}?chat_id=${groupId}`));

  const onClickChat = () => {
    Api.messages.create([currentUserId, data._id], goToChat);
  };

  const onBlockUserAction = () => {
    dispatch(blockUser(data._id));
  };

  const unBlockUserAction = () => {
    dispatch(unBlockUser(data._id));
  };

  return (
    <ProfileHeadContainer>
      <UserImage>
        <UserPic src={data?.picture} size={140} />
      </UserImage>
      <UserName>{`${data?.firstName || ''} ${data?.lastName || ''}`}</UserName>
      <Statistic>
        <StatisticItem>
          <span>{posts?.length || 0}</span>
          {/* {getTextByCount(posts?.length, `${t('posts')}`)} */}
          {t('posts')}
        </StatisticItem>
        <StatisticItem onClick={openFollowers}>
          <span>{data?.followerCount || 0}</span>
          {t('followers')}
        </StatisticItem>
        <StatisticItem onClick={openFollowing}>
          <span>{data?.followingCount || 0}</span>
          {t('following')}
        </StatisticItem>
      </Statistic>
      <ReadMore text={data?.about || ''} TextComponent={Description} />
      {isCurrentUser ? (
        <EditBtn onClick={openEdit}>
          <EditIcon />
          {t('edit_profile')}
        </EditBtn>
      ) : (
        <BtnContainer>
          {isBlocked ? (
            <Button onClick={unBlockUserAction}>{t('unblock')}</Button>
          ) : (
            <>
              <FollowButton
                isFollowed={isFollow}
                className="margin-horizontal_small"
                onClick={onFollow}
              />
              <Button onClick={onClickChat}>
                <CommentIcon />
                {t('chat')}
              </Button>
              <Button onClick={onBlockUserAction}>{t('block')}</Button>
            </>
          )}
        </BtnContainer>
      )}
    </ProfileHeadContainer>
  );
};

ProfileHead.propTypes = {
  profile: shape({})
};

ProfileHead.defaultProps = {
  profile: {}
};
