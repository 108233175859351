/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'Store/authSlice';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { getIsLoading } from 'Store/loaderSlice';
import { getActiveStripePurchase } from 'Store/selectors/purchaseSelector';
import { ThemeContext } from 'Context/themeContext';
import { cancelSubscription, fetchActivePurchases } from 'Store/purchasesSlice';
import Auth from 'middleware/storage';
import ScoutLayout from 'Components/Layouts/ScoutLayout/ScoutLayout';
import { AuthLink } from 'Routes/Auth/components';
// import Stripe from 'Assets/icons/Stripe.png';
import Stripe from 'Assets/img/stripeLogo.png';
import moment from 'moment';

const SubscriptionDetails = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const profile = useSelector(getProfileSelector);
  const loading = useSelector(getIsLoading);
  const activeSub = useSelector(getActiveStripePurchase);
  const { theme, themeMode } = useContext(ThemeContext);

  useEffect(() => {
    fetchUser();
    dispatch(fetchActivePurchases());
  }, []);

  const fetchUser = async () => {
    await Auth.fetchUser();
    const user = await Auth.getUser();
    // setUser(user);
  };

  return (
    <ScoutLayout profile={profile} navTitle="Subscription">
      {activeSub[0]?.isActive ? (
        <ContentContainer
          flex="1"
          height={`calc(100% - 5vh)`}
          padding="4vh 0"
          justifyContent="space-around"
        >
          <ContentContainer gap="25px">
            <SubTitle fontWeight="700" opacity="0.85" fontSize="1rem">
              Subscription details
            </SubTitle>

            <ContentContainer
              padding="2.5%"
              minHeight="15vh"
              gap="10px"
              background={theme?.background}
              borderRadius="15px"
            >
              <Title fontSize="1rem"> {activeSub[0]?.item?.name}</Title>
              <Title fontSize="1rem">
                {' '}
                {`${activeSub[0]?.item?.subType.toUpperCase()}`}{' '}
                {`$${activeSub[0]?.item?.amount}  /  ${
                  activeSub[0]?.item?.subType === 'yearly' ? 'year' : 'month'
                }`}
              </Title>
              <SubTitle fontSize="0.875rem">
                {' '}
                Renews on {moment(activeSub[0]?.endingPeriod).format('MMMM d, YYYY')}
              </SubTitle>
            </ContentContainer>

            <SubTitle fontWeight="700" opacity="0.85" fontSize="1rem">
              Payment info
            </SubTitle>

            <ContentContainer
              padding="2.5%"
              minHeight="15vh"
              gap="10px"
              background={theme?.background}
              borderRadius="15px"
            >
              <ContentContainer gap="0px">
                <Title fontSize="1rem"> Payment with</Title>
                <ContentContainer width="18%">
                  <img
                    src={Stripe}
                    alt="apple"
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'contain' }}
                  />
                </ContentContainer>
              </ContentContainer>
              <hr style={{ width: '100%' }} />
              <SubTitle
                cursor="pointer"
                textAlign="center"
                textTransform="uppercase"
                color={theme?.spoxioRed}
                fontSize="0.875rem"
                onClick={() => {
                  cancelSubscription(activeSub[0]?.item?._id);
                }}
              >
                Cancel Subscription
              </SubTitle>
            </ContentContainer>

            <SubTitle fontWeight="700" opacity="0.85" fontSize="1rem">
              Support
            </SubTitle>

            <ContentContainer
              minHeight="10vh"
              padding="2.5%"
              background={theme?.background}
              borderRadius="15px"
            >
              <Title fontSize="1rem" fontWeight="400" fontFamily="SF Pro">
                For help and support with your subscription, contact our suport team and they will
                be happy to help
              </Title>

              <hr style={{ width: '100%' }} />

              <AuthLink to="#" color={theme?.spoxioText}>
                CONTACT SUPPORT
              </AuthLink>
            </ContentContainer>
          </ContentContainer>
        </ContentContainer>
      ) : (
        <ContentContainer
          flex="1"
          height={`calc(100% - 5vh)`}
          padding="4vh 0"
          justifyContent="space-around"
        >
          <ContentContainer height="auto" width="100%" alignItems="center" justifyContent="center">
            <Title textAlign="center" fontSize="1rem">
              No Active Subscription
            </Title>
          </ContentContainer>

          <ContentContainer width="100%">
            <Title fontSize="1rem">Support</Title>

            <ContentContainer
              minHeight="10vh"
              padding="2.5%"
              background={theme?.background}
              borderRadius="15px"
            >
              <Title fontSize="1rem" fontWeight="400" fontFamily="SF Pro">
                For help and support with your subscription, contact our suport team and they will
                be happy to help
              </Title>

              <hr style={{ width: '100%' }} />

              <AuthLink to="#" color={theme?.spoxioText}>
                CONTACT SUPPORT
              </AuthLink>
            </ContentContainer>
          </ContentContainer>
        </ContentContainer>
      )}

      <ContentContainer margin="auto 0 0 ">
        <AuthLink to="#" color={theme?.spoxioText}>
          See Spoxio Purchase Terms
        </AuthLink>
      </ContentContainer>
    </ScoutLayout>
  );
};

SubscriptionDetails.propTypes = {};

export default SubscriptionDetails;
