import { createSlice } from '@reduxjs/toolkit';
import { push, replace } from 'connected-react-router';
import firebase from 'firebase/app';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';

import Api from 'Api';
import Api2 from 'middleware/api';
import { paths, userRoles } from 'Utils/constants';
import { formatGeneralUserInfo } from 'Utils/formatData';
import { setLoading } from './loaderSlice';
import Auth from 'middleware/storage';
import { getProfile, getProfileById } from './profileSlice';

const initialState = {
  userId: '',
  user: {},
  isAuthorized: false,
  following: []
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
    setFollowing: (state, action) => {
      state.following = action.payload;
    },
    clearState: (state) => {
      state.user = {};
      state.userId = '';
      state.isAuthorized = false;
    }
  }
});

// actions
export const { setUser, setUserId, setIsAuthorized, setFollowing, clearState } = authSlice.actions;

// reducer
export const authReducer = authSlice.reducer;

// selectors
export const getIsAuthorizedSelector = (state) => state?.auth?.isAuthorized;
export const getCurrentUser = (state) => state?.auth?.user;
export const getCurrentUserSports = (state) => state?.auth?.user?.sports;
export const getCurrentUserId = (state) => state?.auth?.userId;
export const getCurrentUserRole = (state) => state?.auth?.user?.userType;
export const getCurrentUserFollowingSelector = (state) => state?.auth?.following;

// thunks
export const signUpUser = (userData) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const formattedUserData = formatGeneralUserInfo(userData);
    const { user } = await Api.auth.createUser(userData);
    await Api.user.addUser({ userId: user.uid, user: formattedUserData });

    dispatch(setUser(formattedUserData));
    dispatch(setUserId(user.uid));
    dispatch(push(paths.SIGN_UP_ROLE));
    dispatch(setLoading(false));
  } catch (error) {
    // toast.error(error.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
  }
};

export const signUpChoseRole = (userType) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));

    const { auth } = getState();

    await Api.user.updateUser({
      userId: auth.userId,
      user: { userType, createdProfile: true }
    });
    const data = await Api.user.getUser(auth.userId);

    dispatch(setUser(data));
    dispatch(push(paths.SIGN_UP_ENHANCE_INFO));
    dispatch(setLoading(false));
  } catch (error) {
    // toast.error(error.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
  }
};

export const signInUser = (credentials) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const { user } = await Api.auth.login(credentials);
    const data = await Api.user.getUser(user.uid);

    dispatch(setUser(data));
    dispatch(setUserId(user.uid));

    if (isMobile) {
      dispatch(push(paths.MOBILE_SCREEN));
    } else {
      dispatch(push(paths.TIMELINE));
    }

    dispatch(setLoading(false));
  } catch (error) {
    // toast.error(error.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
  }
};

export const authSocial = (authProvider, socialType = '') => async (dispatch, getState) => {
  try {
    await firebase
      .auth()
      .signInWithPopup(authProvider)
      .then(async (userCredential) => {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then(async (resToken) => {
            await Api2.auth
              .signInSocial(resToken)
              .then(async (res) => {
                const { data } = res.data;
                Auth.setToken(data.access_token, data.refresh_token);
                await dispatch(getProfile());
                const { role } = getState().auth;
                if (role === userRoles.DEFAULT) {
                  dispatch(push(paths.SIGN_UP_ROLE));
                  return;
                }
                dispatch(push(paths.TIMELINE));
              })
              .catch();
          })
          .catch();
      });
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await Api.auth.logout();

    dispatch(clearState());
    dispatch(replace(paths.HOME));
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

export const resetPassword = ({ email }) => async () => {
  try {
    await Api.auth.resetPassword(email, `${window.location.origin}${paths.SIGN_IN}`);
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

export const changePassword = (credentials) => async () => {
  try {
    await Api2.user.updatePassword(credentials);
    toast.success('password changed.');
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

export const updateUser = (user, routeName) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await Api2.user.updateUserInfo(user);
    await dispatch(getProfile());

    if (routeName) {
      dispatch(push(routeName));
    }
    toast.success('Profile updated.');
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const followOnSport = (sportId) => async (dispatch, getState) => {
  try {
    await Api2.user.updateUserInfo({ sports: sportId });
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

export const signIn = (credentials) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await Api2.auth.signInEmail(credentials);
    const { data } = res.data;
    Auth.setToken(data.access_token, data.refresh_token);

    await dispatch(getProfile());
    
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    toast.error(error?.response?.data?.detail);
    return false;
  }
};

export const resendEmail = async (user) => {
  console.log(user, ' resend email user\n\n\r', { email: user?.email });
  try {
    if (user.email && !user.isActive) {
    const res = await Api2.user.resendCode({ email: user?.email });
    // console.log(res, 'the resend email res \n\n\r');
    toast.success(res?.data?.message);
    return res?.data?.message
    }
  } catch (error) {
    console.log(error?.response, 'resend email error');
  }
};

export const signUp = (userData) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await Api2.auth.signUpEmail(userData);
    await dispatch(signIn({ username: userData.email, password: userData.password }));

    dispatch(push(paths.SIGN_UP_ROLE));
    dispatch(setLoading(false));
  } catch (error) {
    toast.error(error?.response?.data?.detail);
    dispatch(setLoading(false));
  }
};

export const logout = () => async (dispatch) => {
  try {
    await Api2.auth.logout();
    await Auth.destroyToken();

    dispatch(clearState());
    dispatch(replace(paths.HOME));
  } catch (error) {
    toast.error(error?.toString());
  }
};

export const fanProfile = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await Api2.user.registerFan();
    await dispatch(getProfile());

    dispatch(setLoading(false));
    return true;
  } catch (error) {
    // toast.error(error.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
    return false;
  }
};

export const talentProfile = (credentials) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await Api2.user.registerTalent({
      ...credentials,
      dateOfBirth: new Date(credentials.dateOfBirth).toISOString(),
      weight: parseInt(credentials.weight),
      height: parseInt(credentials.height),
      country: credentials.country.value,
      gender: credentials.gender.value,
      sportPlayed: credentials.sportPlayed.value
    });

    dispatch(getProfile());
    dispatch(setLoading(false));
    dispatch(push(paths.SIGN_UP_SPORTS));
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    return false;
  }
};

export const scoutProfile = (credentials) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await Api2.user.registerScout({
      ...credentials,
      country: credentials.country.value
    });

    dispatch(getProfile());
    dispatch(setLoading(false));
    dispatch(push(paths.SIGN_UP_SPORTS));
    return true;
  } catch (error) {
    return false;
  }
};

export const clubProfile = (credentials) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await Api2.user.registerClub({
      ...credentials,
      country: credentials.country.value
    });

    dispatch(getProfile());
    dispatch(setLoading(false));
    dispatch(push(paths.SIGN_UP_SPORTS));
    return true;
  } catch (error) {
    return false;
  }
};

export const updateTalentProfile = (credentials, id) => async (dispatch) => {
  console.log('-----------------------i got herrrrrreeeeeeee finally--------------------------');
  try {
    dispatch(setLoading(true));
    await Api2.user.updateTalent(credentials);

    dispatch(getProfileById(id));
    dispatch(setLoading(false));

    return true;
  } catch (error) {
    console.log(error, error.response, 'update talent profile');
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
    return false;
  }
};

export const updateScoutProfile = (credentials, id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await Api2.user.updateScout(credentials);

    dispatch(getProfileById(id));
    dispatch(setLoading(false));

    return true;
  } catch (error) {
    dispatch(setLoading(false));
    return false;
  }
};

export const updateFanProfile = (credentials, id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await Api2.user.updateUserInfo(credentials);

    dispatch(getProfileById(id));
    dispatch(setLoading(false));

    return true;
  } catch (error) {
    dispatch(setLoading(false));
    return false;
  }
};

export const editClubProfile = (credentials, id) => async (dispatch) => {
  console.log(credentials, id, 'editClubProfile credentials');
  try {
    const response = await Api2.club.editClubProfile(credentials, id);

    console.log(response, 'editClubProfile response');
    if (response.data) {
      toast.success(response?.data?.message);
    }
    await Auth.fetchUser();
    return true;
  } catch (error) {
    console.log(error, error.response, 'editClubProfile error.response');
    if (error && error?.response?.data) {
      toast.error(error?.response?.data?.title);
    } else {
      toast.error('Network error. Try again', 'error');
    }
    return false;
  }
};

export const accountDeactivate = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await Api2.auth.deactivate();
    await Api2.auth.logout();
    await Auth.destroyToken();
    dispatch(clearState());
    setLoading(false);
    toast.success('Deactivated successfully');
    dispatch(replace(paths.HOME));
  } catch (error) {
    setLoading(false);
    console.error('****** Account deactivate error ******\n', error);
  }
};
