import { useState, useRef, useContext } from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { bool, func, shape } from 'prop-types';

import { PostItemListProps } from 'Utils/propTypes';
import { useSlider } from 'Hooks/useSlider';
import {
  PostDot,
  PostMediaContainer,
  PostMediaDots,
  PostNext,
  PostPrevious,
  PostSlideContainer,
  PostImage,
  PostVideo,
  PostPlayContainer,
  PostMuteContainer
} from './components';

import { ReactComponent as PreviousIcon } from 'Assets/icons/CircleArrowLeft.svg';
import { ReactComponent as NextIcon } from 'Assets/icons/CircleArrowRight.svg';
import { ReactComponent as PlayIcon } from 'Assets/icons/PlayFilled.svg';
import { ReactComponent as MuteIcon } from 'Assets/icons/VolumeMute.svg';
import { ReactComponent as VolumeIcon } from 'Assets/icons/VolumeUp.svg';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';

export const PostMedia = ({ mediaList, sliderProps, slideStyle, onSelect, isIntrinsicHeight }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);

  const { currentSlide, showPrevious, showNext, handleNext, handlePrevious } = useSlider(
    mediaList?.length,
    1
  );

  const toggleVideoPlay = (e) => {
    e?.stopPropagation();

    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsVideoPlayed(true);
    } else {
      videoRef.current.pause();
      setIsVideoPlayed(false);
    }
  };

  const toggleVideoSound = (e) => {
    e.stopPropagation();

    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(videoRef.current.muted);
  };

  const onEndedVideo = () => {
    videoRef.current.currentTime = 0;
    setIsMuted(false);
    setIsVideoPlayed(false);
  };

  const onNext = (e) => {
    if (isVideoPlayed) {
      toggleVideoPlay();
    }

    handleNext(e);
  };

  const onPrevious = (e) => {
    if (isVideoPlayed) {
      toggleVideoPlay();
    }

    handlePrevious(e);
  };

  const renderDots = () => {
    if (mediaList.length <= 1) {
      return null;
    }

    return (
      <PostMediaDots>
        {mediaList.map((media, index) => (
          <PostDot key={`${media?.url}-${index}`} isActive={currentSlide === index} />
        ))}
      </PostMediaDots>
    );
  };

  return (
    <PostMediaContainer onClick={onSelect}>
      <CarouselProvider
        naturalSlideWidth={1}
        naturalSlideHeight={1}
        totalSlides={mediaList?.length}
        visibleSlides={1}
        currentSlide={currentSlide}
        dragEnabled={false}
        step={0}
        isIntrinsicHeight={isIntrinsicHeight}>
        <Slider {...sliderProps}>
          {mediaList.map((media, index) => (
            <Slide key={`${index}-${media?.url}`} style={slideStyle}>
              <PostSlideContainer
                onClick={media?.type === 'video' ? toggleVideoPlay : () => {}}
                isClickable={media?.type === 'video'}
                background={media?.url}>
                {media?.type === 'video' ? (
                  <>
                    <PostVideo key={index} ref={videoRef} src={media?.url} onEnded={onEndedVideo} />
                    {!isVideoPlayed ? (
                      <PostPlayContainer>
                        <PlayIcon
                          width={50}
                          height={50}
                          color={theme.spoxioIcon}
                          stroke={theme.mediumGray}
                        />
                      </PostPlayContainer>
                    ) : null}
                    <PostMuteContainer onClick={toggleVideoSound}>
                      {isMuted ? (
                        <MuteIcon
                          width={20}
                          height={20}
                          color={theme.spoxioIcon}
                          stroke={theme.mediumGray}
                        />
                      ) : (
                        <VolumeIcon
                          width={20}
                          height={20}
                          color={theme.spoxioIcon}
                          stroke={theme.mediumGray}
                        />
                      )}
                    </PostMuteContainer>
                  </>
                ) : (
                  <PostImage
                    key={index}
                    src={media?.url}
                    alt={t('post_item')}
                    effect="blur"
                    wrapperClassName="post-image-wrapper"
                    style={{ maxHeight: '100vh' }}
                  />
                )}
              </PostSlideContainer>
            </Slide>
          ))}
        </Slider>
        {renderDots()}
        {showPrevious ? (
          <PostPrevious onClick={onPrevious}>
            <PreviousIcon
              width={22}
              height={22}
              // color={theme.gray}
              color={theme.mediumGray}
            />
          </PostPrevious>
        ) : null}
        {showNext ? (
          <PostNext onClick={onNext}>
            <NextIcon width={22} height={22} color={theme.mediumGray} />
          </PostNext>
        ) : null}
      </CarouselProvider>
    </PostMediaContainer>
  );
};

PostMedia.propTypes = {
  mediaList: PostItemListProps,
  sliderProps: shape({}),
  slideStyle: shape({}),
  onSelect: func,
  isIntrinsicHeight: bool
};

PostMedia.defaultProps = {
  mediaList: [],
  sliderProps: {},
  slideStyle: undefined,
  onSelect: () => {},
  isIntrinsicHeight: false
};
