/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import BackArrow from 'Assets/icons/arrow-back-black.svg';
import Close from 'Assets/icons/Close.svg';
import ApplePay from 'Assets/icons/applepay.svg';
import GPay from 'Assets/icons/gpay.svg';
import LiveStream from 'Assets/icons/live-stream.svg';
import { ReactComponent as CircleBack } from 'Assets/icons/circle-back.svg';
import { ReactComponent as Chat } from 'Assets/icons/Chat.svg';
import { ReactComponent as Shape } from 'Assets/icons/shape.svg';
import { ReactComponent as Scout } from 'Assets/icons/Scout.svg';
import background2 from 'Assets/img/background-2.png';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { SliderContent } from './sliders';
import Players from 'Assets/img/players-two-card.png';
import SlamDunk from 'Assets/img/slam-dunk.png';
import ThirdSlide from 'Assets/img/third-slide.png';
// import Stripe from 'Assets/img/stripeLogo.png';
import Stripe from 'Assets/icons/Stripe.png';
import { AuthContainer } from 'Components/AuthContainer';
import { AuthFormDescription, AuthLink } from 'Routes/Auth/components';
import ScoutLayout from 'Components/Layouts/ScoutLayout/ScoutLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserId } from 'Store/authSlice';
import ModalPortal from 'Components/Modals/ModalPortal';
import { getProfileById } from 'Store/profileSlice';
// import { getProfileSelector } from 'Store/selectors/profileSelector';
import {
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ModalProfile } from 'Components/Modals/components';
import { BackButton } from 'Components/Layouts/AuthLayout/components';
import { ThemeContext } from 'Context/themeContext';
import { bool } from 'prop-types';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { Content } from 'Components/Results/components';
import isEmpty from 'Utils/isEmpty';
import { getIsLoading, setLoading } from 'Store/loaderSlice';
import { fetchActivePurchases, fetchPurchases, fetchPurchasesIntent } from 'Store/purchasesSlice';
import Auth from 'middleware/storage';
import Api from 'middleware/api';
import { toast } from 'react-toastify';
import { getActiveStripePurchase, getStripePurchase } from 'Store/selectors/purchaseSelector';
import { getCurrentUser } from 'Store/selectors/authSelector';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components';
import { Loader } from 'Components/shared/Loader';

const StripElementContainer = styled(ContentContainer)`
  background: ${({ theme }) => 'rgba(35, 34, 38, 1)' || theme?.spoxioGray1};
  height: ${({ height }) => height || '5vh'};
  justify-content: center;
  padding: 2%;
  border-radius: 12px;
  border: 2px inset ${({ theme }) => theme?.spoxioAltBlue};
`;

const ScoutSubscription = (props) => {
  const [stripePromise] = useState(async () => {
    const loadStrip = await loadStripe(
      'pk_test_51I8xY3JDn79qmktLZxIYRgCvTRnggASzPL4Rz9BZ8KlzJyAVhtWkoUr4mbhQUjpL6vD0N3ChdA95lUJTAO8tOt4c00x24znR6s'
    );
    return loadStrip;
  });

  return (
    <Elements stripe={stripePromise}>
      <SubscriptionPayment {...props} />
    </Elements>
  );
};

const SubscriptionPayment = ({ hideLayout }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getCurrentUserId);
  const user = useSelector(getCurrentUser);
  const profile = useSelector(getProfileSelector);
  const loading = useSelector(getIsLoading);
  const activeSub = useSelector(getActiveStripePurchase);
  const { theme, themeMode } = useContext(ThemeContext);

  const [modalState, setModalState] = useState(false);
  const [part, setPart] = useState(1);
  const [plan, setPlan] = useState('basic');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [userData, setUserData] = useState({});

  // Stripe

  const stripe = useStripe();
  const elements = useElements();
  const [planId, setPlanId] = useState(null);
  const [checkPlan, setCheckPlan] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const stripePurchases = useSelector(getStripePurchase);

  const openModal = () => {
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };

  const updatePart = () => {
    setPart(2);
  };

  const decreasePart = () => {
    setPart(1);
  };

  useEffect(async () => {
    // dispatch(getProfileById(userId));
  }, [userId]);

  const fetchUser = async () => {
    const user = await Auth.getUser();
    setUserData(user);
  };

  useEffect(() => {
    dispatch(fetchPurchases());
    dispatch(fetchActivePurchases());
    fetchUser();
  }, []);

  const uniquePurchases = useMemo(() => {
    return Array.from(new Set(stripePurchases.map((a) => a.planType))).map((id) => {
      return stripePurchases.find((a) => a.planType === id);
    });
  }, [stripePurchases]);

  useEffect(() => {
    console.log(
      '🚀 ~ file: ScoutSubscription.jsx:153 ~ useEffect ~ uniquePurchases:',
      uniquePurchases
    );
    if (isEmpty(uniquePurchases)) return;
    setPlan(uniquePurchases[0]?.planType);
    return () => {
      setCheckPlan(null);
      setPlanId(null);
    };
  }, [stripePurchases]);

  const startTrial = async () => {
    setLoading(true);
    try {
      const response = await Api.user.startTrial({});
      if (response) {
        await Auth.fetchUser();
        setIsConfirmed(true);
        dispatch(fetchActivePurchases());
      }
    } catch (error) {
      console.error(error);
      console.log('\n\n\rstart trial error \n\r', error.response);
      toast.error(error.response ? error.response?.data?.detail : error.toString());
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSub = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // what we had
    const cardElement = elements.getElement(CardNumberElement);
    setLoading(true);

    try {
      //create payment method
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          email: user?.email
        }
      });

      console.log('\n\n\r--------------created payment method result------------- \n\n\r', result);

      if (result.error) {
        setLoading(false);
        toast.error(`Error code: ${result.error.message}`);

        console.log(result.error, result.error.code, '-------created payment method error-----');
        // setPaymentModal(false);
      } else {
        const data = {
          item: planId,
          tokenId: result?.paymentMethod?.id
        };

        const res = await Api.user.purchasesNew(data);
        console.log('\n\n\r ------------------Response fom purchasesNew:------------ ' + res);
        if (res.data.data?.clientSecret) {
          setLoading(false);
          setPaymentModal(false);
          const response = await stripe.confirmPayment(res.data.data.clientSecret, {
            type: 'card'
          });
          if (response.error) {
            console.log(response.error, 'error from confirm payment');
            toast.error(response.error ? response?.error?.message : 'Payment Unsuccessful');
            setLoading(false);
            setPaymentModal(false);
            return;
          }
          dispatch(fetchActivePurchases());
          toast.success('Your payment method is successfully set up for future payments!');
          setPaymentModal(false);
          setPart(3);
          setIsConfirmed(true);
        }
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response, 'stripe payment error');
      toast.error(error.response ? error.response?.data?.title : error?.toString());
      setLoading(false);
      // setPaymentModal(false);
    }
  };

  const SubscribeButton = () => {
    return (
      <ContentContainer width="70%" margin="0 auto" column justifyContent="center">
        <Title
          color={theme?.spoxioBodyText}
          fontSize="1rem"
          letterSpacing="-0.3px"
          textAlign="center"
          opacity="0.9"
        >
          Starting at £19.90 / month
        </Title>
        <SpoxioBlueBtn marginTop="0" padding="1.2rem 2.4rem" margin="0 auto" onClick={updatePart}>
          Choose your Plan
        </SpoxioBlueBtn>
        {!userData?.scoutProfile?.trialEnabled && (
          <SubTitle
            onClick={startTrial}
            color={theme?.spoxioBodyText}
            fontSize="1rem"
            padding="10px 0"
            lineHeight="25.21px"
            letterSpacing="-0.3px"
            textAlign="center"
            textDecoration="none"
            opacity="0.9"
            cursor="pointer"
          >
            Start free 3 months trial
          </SubTitle>
        )}
      </ContentContainer>
    );
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: '#e0e0e0',
        color: '#b5ffc0',
        fontWeight: 700,
        fontSize: '20px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883'
        },
        '::placeholder': {
          color: '#e0e0e08e',
          fontWeight: '600',
          fontSize: '16px'
        }
      },
      invalid: {
        iconColor: '#ff3939',
        color: '#ff3939bd'
      }
    }
  };

  const CheckoutForm = (props) => {
    return (
      <form>
        <ContentContainer width="100%" height="100%" gap="25px" justifyContent="center">
          {/* <ContentContainer width="100%">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </ContentContainer> */}
          <StripElementContainer width="100%">
            <CardNumberElement
              options={{
                ...CARD_ELEMENT_OPTIONS,
                placeholder: '1234 1234 1234 1234',
                showIcon: true
              }}
            />
          </StripElementContainer>

          <ContentContainer
            row
            gap="2%"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <StripElementContainer width="70%">
              <CardExpiryElement
                options={{ ...CARD_ELEMENT_OPTIONS, placeholder: 'Expiry date' }}
              />
            </StripElementContainer>
            <StripElementContainer width="28%">
              <CardCvcElement options={{ ...CARD_ELEMENT_OPTIONS, placeholder: 'CVV' }} />
            </StripElementContainer>
          </ContentContainer>

          <SpoxioBlueBtn
            background="transparent"
            border={themeMode === 'light' ? theme?.spoxioAltBlue : theme?.black}
            hover
            fontFamily="SF Pro"
            borderWidth="1.5px"
            padding="1.5rem 1rem"
            borderRadius="12px"
            marginTop="0.5%"
            disabled={!stripe || !elements || loading}
            onClick={handlePaymentSub}
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '5%'
            }}
          >
            {loading ? (
              <Loader size={24} background="transparent" />
            ) : (
              <>
                <Title color={theme?.black} fontWeight="700" fontSize="1rem">
                  Pay now with
                </Title>
                <img src={Stripe} alt="apple" height="30vh" style={{ objectFit: 'contain' }} />
              </>
            )}
          </SpoxioBlueBtn>
        </ContentContainer>
      </form>
    );
  };

  return (
    <>
      {!hideLayout ? (
        <>
          <ScoutLayout profile={profile} navTitle="Subscription">
            <>
              {part === 1 && (
                <>
                  <CarouselProvider
                    naturalSlideHeight={'200'}
                    naturalSlideWidth={'125'}
                    totalSlides={3}
                  >
                    <Slider style={{ height: '75vh' }}>
                      <Slide>
                        <SliderContent
                          players={Players}
                          midSticker
                          slideText={
                            <>
                              Swipe to see talent
                              <br /> photo or video
                            </>
                          }
                        />
                      </Slide>
                      <Slide>
                        <SliderContent
                          players={SlamDunk}
                          slideText="Access exclusive stats and talent rankings"
                        />
                      </Slide>
                      <Slide>
                        <SliderContent
                          players={ThirdSlide}
                          slideText="  Contact talent directly or in bulk messages"
                        />
                      </Slide>
                    </Slider>
                  </CarouselProvider>

                  <SubscribeButton />
                  {/* <AuthLink>Subscribe Already?</AuthLink> */}
                </>
              )}

              {/* Second part of the components */}
              {part === 2 && (
                <ContentContainer
                  borderRadius="1.5rem 1.5rem 0 0"
                  padding="20px 40px"
                  style={{
                    backgroundImage: `url(${background2})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: `calc(90vh - 20px)`,
                    backgroundClip: 'border-box',
                    backgroundPosition: '30%',
                    objectFit: 'contain'
                  }}
                >
                  <ContentContainer width="100%">
                    <ContentContainer row width="100%" justifyContent="center" alignItems="center">
                      <BackButton color="#fff" onClick={() => decreasePart()} mb="0" />
                      <ContentContainer row width="100%" justifyContent="center" margin="0">
                        <AuthLink
                          style={{
                            cursor: 'pointer',
                            fontSize: '1rem',
                            color: '#fff',
                            width: '100%',
                            textAlign: 'center',
                            textDecoration: 'underline'
                          }}
                        >
                          Subscribe Already?
                        </AuthLink>
                      </ContentContainer>
                    </ContentContainer>

                    <ContentContainer column gap="20px" style={{ marginTop: '5vh' }}>
                      <Title
                        color={theme?.defaultWhite}
                        fontSize="2.25rem"
                        fontWeight="700"
                        lineHeight="2.875rem"
                      >
                        Start scouting, <br />
                        start your 3-month <br />
                        FREE trial!
                      </Title>

                      {/* Subtitle2 below */}
                      <SubTitle
                        opacity="0.85"
                        color={theme?.defaultWhite}
                        style={{ marginTop: '5px' }}
                      >
                        No commitment, cancel anytime.
                      </SubTitle>
                    </ContentContainer>
                  </ContentContainer>

                  <ContentContainer margin="0 0 20px">
                    <ContentContainer row alignItems="center" gap="0 20px">
                      <SpoxioBlueBtn
                        style={{ display: 'none' }}
                        fontSize="0.875rem"
                        background="rgb(0, 123, 255)"
                        width="auto"
                        borderRadius="8px"
                        padding="0.5rem 1.25rem"
                        margin="0"
                      >
                        + Virtual Plan
                      </SpoxioBlueBtn>
                      <SpoxioBlueBtn
                        fontSize="0.875rem"
                        background={theme?.spoxioAltBlue}
                        width="auto"
                        borderRadius="8px"
                        padding="0.5rem 1.25rem"
                        margin="0"
                      >
                        Basic
                      </SpoxioBlueBtn>
                    </ContentContainer>

                    <ContentContainer>
                      {(stripePurchases || [])
                        .filter((item) => item?.planType === plan)
                        .map((item, index) => {
                          return (
                            <SpoxioBlueBtn
                              key={index}
                              background="rgba(80, 85, 92, 0.64)"
                              padding="0.75rem"
                              borderWidth="2px"
                              border={
                                checkPlan === item?.subType ? theme?.spoxioAltBlue : 'transparent'
                              }
                              borderRadius="22px"
                              alignItems="center"
                              width="100%"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '2%',
                                alignItems: 'center'
                              }}
                              onClick={() => {
                                setCheckPlan(item?.subType);
                                setPlanId(item?._id);
                              }}
                            >
                              <ContentContainer>
                                <input
                                  readOnly
                                  type="radio"
                                  name="plan"
                                  style={{
                                    height: '21px',
                                    width: '21px'
                                  }}
                                  checked={checkPlan === item?.subType ? true : false}
                                />
                              </ContentContainer>

                              <ContentContainer>
                                <ContentContainer gap="8px" padding="0 1rem">
                                  <ContentContainer row alignItems="center">
                                    <Title
                                      fontSize="1rem"
                                      textTransform="uppercase"
                                      color={theme?.defaultWhite}
                                      fontWeight="700"
                                      style={{ fontFamily: 'SF Pro' }}
                                    >
                                      {item.subType} {item.subType == 'yearly' ? '(20% OFF)' : ''}
                                    </Title>
                                  </ContentContainer>

                                  <SubTitle
                                    fontSize="1rem"
                                    color={theme?.defaultWhite}
                                    opacity="0.85"
                                    fontWeight="300"
                                  >
                                    ${item.amount} / {item.subType}
                                  </SubTitle>
                                </ContentContainer>
                              </ContentContainer>
                            </SpoxioBlueBtn>
                          );
                        })}
                    </ContentContainer>
                  </ContentContainer>

                  {!isEmpty(planId) ? (
                    <SpoxioBlueBtn
                      background="transparent"
                      border={theme?.defaultWhite}
                      borderWidth="1.5px"
                      borderRadius="12px"
                      marginTop="auto"
                      onClick={() => setPaymentModal(!paymentModal)}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Title
                        color={theme?.defaultWhite}
                        style={{
                          fontSize: '18px',
                          fontWeight: '600',
                          textTransform: 'initial'
                          // margin: '0 10px'
                        }}
                      >
                        Continue with
                      </Title>
                      <img
                        src={Stripe}
                        alt="apple"
                        height="32vh"
                        style={{ objectFit: 'contain' }}
                      />
                    </SpoxioBlueBtn>
                  ) : null}

                  <ContentContainer
                    hover
                    row
                    width="100%"
                    justifyContent="center"
                    margin="auto 0 0"
                    style={{ bottom: '0px' }}
                    onClick={openModal}
                  >
                    <AuthLink
                      style={{
                        cursor: 'pointer',
                        fontSize: '1rem',
                        color: '#fff',
                        width: '100%',
                        textAlign: 'center',
                        textDecoration: 'underline'
                      }}
                    >
                      Billed as £199, See what you'll get
                    </AuthLink>
                  </ContentContainer>
                </ContentContainer>
              )}
            </>
          </ScoutLayout>
        </>
      ) : (
        <>
          {part === 1 && (
            <>
              <CarouselProvider
                naturalSlideHeight={'200'}
                naturalSlideWidth={'125'}
                totalSlides={3}
              >
                <Slider style={{ height: '75vh' }}>
                  <Slide>
                    <SliderContent
                      players={Players}
                      midSticker
                      slideText={
                        <>
                          Swipe to see talent
                          <br /> photo or video
                        </>
                      }
                    />
                  </Slide>
                  <Slide>
                    <SliderContent
                      players={SlamDunk}
                      slideText="Access exclusive stats and talent rankings"
                    />
                  </Slide>
                  <Slide>
                    <SliderContent
                      players={ThirdSlide}
                      slideText="  Contact talent directly or in bulk messages"
                    />
                  </Slide>
                </Slider>
              </CarouselProvider>

              <SubscribeButton />
              {/* <AuthLink>Subscribe Already?</AuthLink> */}
            </>
          )}

          {/* Second part of the components */}
          {part === 2 && (
            <ContentContainer
              borderRadius="1.5rem 1.5rem 0 0"
              padding="20px 40px"
              style={{
                backgroundImage: `url(${background2})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: `calc(90vh - 20px)`,
                backgroundClip: 'border-box',
                backgroundPosition: '30%',
                objectFit: 'contain'
              }}
            >
              <ContentContainer width="100%">
                <ContentContainer row width="100%" justifyContent="center" alignItems="center">
                  <BackButton color="#fff" onClick={() => decreasePart()} mb="0" />
                  <ContentContainer row width="100%" justifyContent="center" margin="0">
                    <AuthLink
                      style={{
                        cursor: 'pointer',
                        fontSize: '1rem',
                        color: '#fff',
                        width: '100%',
                        textAlign: 'center',
                        textDecoration: 'underline'
                      }}
                    >
                      Subscribe Already?
                    </AuthLink>
                  </ContentContainer>
                </ContentContainer>

                <ContentContainer column gap="20px" style={{ marginTop: '5vh' }}>
                  <Title
                    color={theme?.defaultWhite}
                    fontSize="2.25rem"
                    fontWeight="700"
                    lineHeight="2.875rem"
                  >
                    Start scouting, <br />
                    start your 3-month <br />
                    FREE trial!
                  </Title>

                  <SubTitle opacity="0.85" color={theme?.defaultWhite} style={{ marginTop: '5px' }}>
                    No commitment, cancel anytime.
                  </SubTitle>
                </ContentContainer>
              </ContentContainer>

              <ContentContainer margin="0 0 20px">
                <ContentContainer row alignItems="center" gap="0 20px">
                  <SpoxioBlueBtn
                    style={{ display: 'none' }}
                    fontSize="0.875rem"
                    background="rgb(0, 123, 255)"
                    width="auto"
                    borderRadius="8px"
                    padding="0.5rem 1.25rem"
                    margin="0"
                  >
                    + Virtual Plan
                  </SpoxioBlueBtn>
                  <SpoxioBlueBtn
                    fontSize="0.875rem"
                    background={theme?.spoxioAltBlue}
                    width="auto"
                    borderRadius="8px"
                    padding="0.5rem 1.25rem"
                    margin="0"
                  >
                    Basic
                  </SpoxioBlueBtn>
                </ContentContainer>

                <ContentContainer>
                  {(stripePurchases || [])
                    .filter((item) => item?.planType === plan)
                    .map((item, index) => {
                      return (
                        <SpoxioBlueBtn
                          key={index}
                          background="rgba(80, 85, 92, 0.64)"
                          padding="0.75rem"
                          borderWidth="2px"
                          border={
                            checkPlan === item?.subType ? theme?.spoxioAltBlue : 'transparent'
                          }
                          borderRadius="22px"
                          alignItems="center"
                          width="100%"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '2%',
                            alignItems: 'center'
                          }}
                          onClick={() => {
                            setCheckPlan(item?.subType);
                            setPlanId(item?._id);
                          }}
                        >
                          <ContentContainer>
                            <input
                              readOnly
                              type="radio"
                              name="plan"
                              style={{
                                height: '21px',
                                width: '21px'
                              }}
                              checked={checkPlan === item?.subType ? true : false}
                            />
                          </ContentContainer>

                          <ContentContainer>
                            <ContentContainer gap="8px" padding="0 1rem">
                              <ContentContainer row alignItems="center">
                                <Title
                                  fontSize="1rem"
                                  textTransform="uppercase"
                                  color={theme?.defaultWhite}
                                  fontWeight="700"
                                  style={{ fontFamily: 'SF Pro' }}
                                >
                                  {item.subType} {item.subType == 'yearly' ? '(20% OFF)' : ''}
                                </Title>
                              </ContentContainer>

                              <SubTitle
                                fontSize="1rem"
                                color={theme?.defaultWhite}
                                opacity="0.85"
                                fontWeight="300"
                              >
                                ${item.amount} / {item.subType}
                              </SubTitle>
                            </ContentContainer>
                          </ContentContainer>
                        </SpoxioBlueBtn>
                      );
                    })}
                </ContentContainer>
              </ContentContainer>

              {!isEmpty(planId) ? (
                <SpoxioBlueBtn
                  background="transparent"
                  border={theme?.defaultWhite}
                  borderWidth="1.5px"
                  borderRadius="12px"
                  marginTop="auto"
                  onClick={() => setPaymentModal(!paymentModal)}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Title
                    color={theme?.defaultWhite}
                    style={{
                      fontSize: '18px',
                      fontWeight: '600',
                      textTransform: 'initial'
                      // margin: '0 10px'
                    }}
                  >
                    Continue with
                  </Title>
                  <img src={Stripe} alt="apple" height="32vh" style={{ objectFit: 'contain' }} />
                </SpoxioBlueBtn>
              ) : null}

              <ContentContainer
                hover
                row
                width="100%"
                justifyContent="center"
                margin="auto 0 0"
                style={{ bottom: '0px' }}
                onClick={openModal}
              >
                <AuthLink
                  style={{
                    cursor: 'pointer',
                    fontSize: '1rem',
                    color: '#fff',
                    width: '100%',
                    textAlign: 'center',
                    textDecoration: 'underline'
                  }}
                >
                  Billed as £199, See what you'll get
                </AuthLink>
              </ContentContainer>
            </ContentContainer>
          )}
        </>
      )}

      {/*Payment Modals */}
      {paymentModal && (
        <ModalPortal>
          <ModalProfile>
            <ContentContainer
              margin="10vh auto"
              background={theme?.background}
              minHeight="50vh"
              height="50vh"
              maxHeight="60vh"
              minWidth="40vw"
              maxWidth="40vw"
              padding="20px"
              borderRadius="30px"
              style={{ backdropFilter: `blur(4px)` }}
            >
              <ContentContainer position="absolute">
                <IconContainer
                  zIndex="10"
                  height="2rem"
                  width="2rem"
                  onClick={() => setPaymentModal(false)}
                >
                  <CircleBack alt="close modal" />
                </IconContainer>
              </ContentContainer>

              <ContentContainer height="100%" style={{ padding: '0 2vw' }}>
                <ContentContainer width="100%" column alignItems="center">
                  <img src={Stripe} alt="apple" height="60px" style={{ objectFit: 'contain' }} />
                </ContentContainer>
                <ContentContainer margin="10px 0 0" gap="20px">
                  <Title fontSize="1rem">Enter Card details</Title>
                  <CheckoutForm />
                </ContentContainer>
              </ContentContainer>
            </ContentContainer>
          </ModalProfile>
        </ModalPortal>
      )}

      {/* Plan Details Modal */}
      {modalState && (
        <ModalPortal>
          <ModalProfile>
            <ContentContainer
              margin="10vh auto"
              background={theme?.background}
              color={theme?.defaultWhite}
              minHeight="65vh"
              maxWidth="50vw"
              padding="20px"
              borderRadius="30px"
            >
              <IconContainer height="2.5rem" width="2.5rem">
                <CircleBack onClick={closeModal} alt="close modal" />
              </IconContainer>

              <ContentContainer height="100%" gap="4vh" style={{ padding: '2vh 2vw' }}>
                <ContentContainer column>
                  <Title
                    width="260px"
                    fontFamily="SF Pro"
                    lineHeight="1.875rem"
                    fontSize="1.875rem"
                    letterSpacing="-0.4px"
                    fontWeight="800"
                  >
                    {plan === 'basic'
                      ? `Scout basic plan £199 / year`
                      : `Scout + virtual event £391 / year`}
                  </Title>
                  <SubTitle color={theme?.spoxioText} style={{ marginTop: '12px' }}>
                    No commitment, cancel anytime.
                  </SubTitle>
                </ContentContainer>

                <ContentContainer gap="20px" margin="10px 0 0">
                  <ContentContainer
                    row
                    padding="0 15px"
                    background="rgba(80, 85, 92, 1)"
                    borderRadius="11px"
                    alignItems="center"
                    gap="10px"
                    minHeight="70px"
                    maxHeight="90px"
                    minWidth="381px"
                    maxWidth="382px"
                  >
                    <IconContainer fill={theme?.spoxioIcon}>
                      <Scout />
                    </IconContainer>
                    <SubTitle color={theme?.defaultWhite}>
                      Swipe to see videos and photos posted by talents
                    </SubTitle>
                  </ContentContainer>

                  {/* Second */}
                  <ContentContainer
                    row
                    padding="0 15px"
                    background="rgba(80, 85, 92, 1)"
                    borderRadius="11px"
                    alignItems="center"
                    gap="10px"
                    minHeight="70px"
                    maxHeight="90px"
                    minWidth="381px"
                    maxWidth="382px"
                  >
                    <IconContainer fill={theme?.spoxioIcon}>
                      <Shape />
                    </IconContainer>
                    <SubTitle color={theme?.defaultWhite}>
                      Access exclusive stats and rankings
                    </SubTitle>
                  </ContentContainer>

                  {/* Third */}
                  <ContentContainer
                    row
                    padding="0 15px"
                    background="rgba(80, 85, 92, 1)"
                    borderRadius="11px"
                    alignItems="center"
                    gap="10px"
                    minHeight="70px"
                    maxHeight="90px"
                    minWidth="381px"
                    maxWidth="382px"
                  >
                    <IconContainer fill={theme?.spoxioIcon}>
                      <Chat />
                    </IconContainer>
                    <SubTitle color={theme?.defaultWhite}>
                      Contact talents directly or in bulk messages
                    </SubTitle>
                  </ContentContainer>

                  {/* Fourth */}
                  {/* {!plan.includes('basic') && (
                    <div
                      style={{
                        padding: '0 15px',
                        backgroundColor: 'rgba(80, 85, 92, 1)',
                        borderRadius: '11px',
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '10px',
                        minHeight: '70px',
                        maxHeight: '90px',
                        minWidth: '381px',
                        maxWidth: '382px'
                      }}
                    >
                      <img src={LiveStream} alt={'Shape'} width={'29px'} />
                      <div
                        style={{
                          maxWidth: '287px',
                          fontSize: '22px'
                        }}
                      >
           
                        <SubTitle>2 hours of live video streaming</SubTitle>
                      </div>
                    </div>
                  )} */}
                </ContentContainer>

                {/* <SpoxioBlueBtn
                  style={{
                    backgroundColor: 'rgba(0,148,201,0.8)',
                    // height:'70px',
                    padding: '10px',
                    height: '50px',
                    maxWidth: '381px',
                    marginTop: '44px',
                    borderRadius: '12px'
                  }}
                >
                  <SubTitle
                    style={{
                      fontSize: '17px',
                      fontWeight: '600',
                      textTransform: 'initial',
                      margin: '0 10px'
                    }}
                  >
                    Continue with
                  </SubTitle>
                  <img src={ApplePay} alt="apple" />
                </SpoxioBlueBtn>

                <SpoxioBlueBtn
                  style={{
                    border: '1px solid white',
                    height: '50px',
                    maxWidth: '381px',
                    marginTop: '10px'
                  }}
                >
                  <SubTitle
                    style={{
                      fontSize: '17px',
                      fontWeight: '600',
                      textTransform: 'initial',
                      margin: '0 10px'
                    }}
                  >
                    Continue with
                  </SubTitle>
                  <img src={GPay} alt="Google pay" />
                </SpoxioBlueBtn> */}
              </ContentContainer>
            </ContentContainer>
          </ModalProfile>
        </ModalPortal>
      )}
    </>
  );
};

SubscriptionPayment.propTypes = {
  hideLayout: bool
};

export default ScoutSubscription;
