import { object, shape, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Api from 'Api';
import { paths, userRoles } from 'Utils/constants';
// import { getTextByCount } from 'Utils/strings';
import { getCurrentUserId } from 'Store/authSlice';
import { fetchTalentGallery, postTalentGallery } from 'Store/profileSlice';
import {
  ProfileHeadContainer,
  UserImage,
  UserName,
  Statistic,
  StatisticsContainer,
  HamburgerHolder
} from './components';
import { CameraIcon } from 'Components/shared/Inputs/DragDropFilesInput/components';
import { UserPic } from 'Components/shared/UserPic';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Hamburger } from '../../Assets/icons/MoreIcon.svg';
import { ThemeContext } from 'Context/themeContext';
import {
  ContentContainer,
  IconContainer,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import isEmpty from 'Utils/isEmpty';
import UserInfo from 'Components/OtherProfile/UserInfo';
import { DragDropFileInput } from 'Components/shared/Inputs/DragDropFilesInput';
import firebase from 'firebase/app';
import 'firebase/storage';
import { Loader } from 'Components/shared/Loader';
import { getTalentPhotoList } from 'Store/selectors/profileSelector';

export const ProfileHead = ({ profile, setVisible }) => {
  const { theme, themeMode } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { data, posts } = profile;
  const { t } = useTranslation();
  const currentUserId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();
  const talentPhotoList = useSelector(getTalentPhotoList);

  // const openEdit = () => dispatch(push(`${paths.PROFILE}/${data._id}/edit`));
  const openFollowers = () => dispatch(push(`${paths.PROFILE}/${data._id}/followers`));
  const openFollowing = () => dispatch(push(`${paths.PROFILE}/${data._id}/following`));

  const goToChat = (groupId) => dispatch(push(`${paths.CHAT}?chat_id=${groupId}`));

  // const onClickChat = () => {
  //   Api.messages.create([currentUserId, data._id], goToChat);
  // };

  // const userRole = localStorage.getItem('type');
  // if (userRole === userRoles.FAN) {

  const selectFile = (base64Name, file) => {
    uploadTalentPhoto(base64Name, file);
  };

  const postTalentsUrls = async (data) => {
    let resp = await dispatch(postTalentGallery({ urls: data }));
    if (resp) {
      dispatch(fetchTalentPhotos(data?._id));
    }
  };

  const uploadTalentPhoto = async (base64Name, file) => {
    setLoading(true);
    const filename = `${data._id}.jpg`;
    const storageRef = firebase.storage().ref(`users/${filename}`);
    storageRef
      .put(file)
      .then(() => storageRef.getDownloadURL())
      .then(async (pictureUrl) => {
        await postTalentsUrls([pictureUrl]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Upload Error: ', error);
      });
  };

  const fetchTalentPhotos = () => async (dispatch) => {
    if (data?.userType === userRoles.TALENT && data?._id === data?.talentProfile?.user) {
      await dispatch(fetchTalentGallery(data?.talentProfile?.user));
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch(fetchTalentPhotos());
  }, []);

  useEffect(() => {}, [talentPhotoList]);

  return (
    <>
      <ProfileHeadContainer>
        <HamburgerHolder fill={theme?.defaultWhite}>
          <Hamburger
            width="18px"
            height="18px"
            onClick={() => {
              setVisible((prev) => !prev);
            }}
            stroke={theme?.spoxioALtBlue}
          />
        </HamburgerHolder>

        <div
          style={{
            position: 'absolute',
            bottom: data?.userType === userRoles.CLUB_MEMBER ? '-55px' : '-48px',
            left: '15px',
            width: 'calc(100% - 15px)'
          }}
        >
          <UserImage mode={themeMode}>
            {data?.userType === userRoles.TALENT && data?._id === currentUserId ? (
              <>
                <ContentContainer maxWidth="85px" width="100%">
                  <DragDropFileInput
                    disabled={loading}
                    acceptedFormat="image/*"
                    border="transparent"
                    onChange={selectFile}
                    customIcon={
                      <ContentContainer cursor="pointer">
                        {loading ? (
                          <ContentContainer position="absolute" style={{ top: '40%', left: '55%' }}>
                            <Loader size={24} background="transparent" />
                          </ContentContainer>
                        ) : null}

                        <UserPic src={data?.picture} size={85} />
                        <IconContainer
                          fill={theme?.defaultWhite}
                          stroke={theme?.defaultBlack}
                          style={{
                            position: 'absolute',
                            bottom: '2px',
                            right: '-14px',
                            zIndex: 99
                          }}
                          width="18px"
                          height="18px"
                        >
                          <CameraIcon />
                        </IconContainer>
                      </ContentContainer>
                    }
                  />
                </ContentContainer>
              </>
            ) : (
              <UserPic src={data?.picture} size={85} />
            )}
            <ContentContainer column gap="5px 0" margin="4px 0 0">
              <UserName
                title={`${data?.firstName || ''} ${data?.lastName || ''}`}
                weight="600"
                marginLeft="0"
                fontSize="1.875rem"
                textAlign="left"
              >
                {!isEmpty(data) ? `${data?.firstName} ${data?.lastName}` : ''}
              </UserName>

              <UserInfo data={data} maxWidth="24vw" fontSize="1rem" />
            </ContentContainer>
          </UserImage>
        </div>
      </ProfileHeadContainer>

      {/* Other statistics to be wrapped so it can be centered */}
      <StatisticsContainer>
        <Statistic>
          <ContentContainer column justifyContent="center">
            <SubTitle
              fontSize="1.85rem"
              fontWeight="bold"
              textAlign="center"
              style={{ letterSpacing: '0.30px' }}
              opacity="0.9"
            >
              {posts?.length || 0}
            </SubTitle>
            <SubTitle fontSize="0.75rem" textAlign="center" style={{ letterSpacing: '0.30px' }}>
              {/* {getTextByCount(posts?.length, `${t('posts')}`)} */}
              {t('Posts')}
            </SubTitle>
          </ContentContainer>
          <ContentContainer column justifyContent="center" onClick={openFollowers} cursor="pointer">
            <SubTitle
              fontSize="1.85rem"
              fontWeight="bold"
              textAlign="center"
              style={{ letterSpacing: '0.30px' }}
              opacity="0.9"
            >
              {data?.followerCount || 0}
            </SubTitle>
            <SubTitle fontSize="0.75rem" textAlign="center" style={{ letterSpacing: '0.30px' }}>
              {/* {getTextByCount(posts?.length, `${t('posts')}`)} */}
              {t('followers')}
            </SubTitle>
          </ContentContainer>
          <ContentContainer column justifyContent="center" onClick={openFollowing} cursor="pointer">
            <SubTitle
              fontSize="1.85rem"
              fontWeight="bold"
              textAlign="center"
              style={{ letterSpacing: '0.30px' }}
              opacity="0.9"
            >
              {data?.followingCount || 0}
            </SubTitle>
            <SubTitle fontSize="0.75rem" textAlign="center" style={{ letterSpacing: '0.30px' }}>
              {/* {getTextByCount(posts?.length, `${t('posts')}`)} */}
              {t('following')}
            </SubTitle>
          </ContentContainer>
        </Statistic>
      </StatisticsContainer>
    </>
  );
};

ProfileHead.propTypes = {
  profile: shape({}),
  theme: object,
  setVisible: func
};

ProfileHead.defaultProps = {
  profile: {}
};
