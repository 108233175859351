import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isCurrentUser: false,
  isFollowed: false,
  isBlocked: false,
  followers: [],
  following: [],
  posts: [],
  scoutedTalent: [],
  talentPhotoList: []
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.data = action.payload.data || {};
      state.isCurrentUser = action.payload.isCurrentUser || false;
      state.isBlocked = action.payload.isBlocked || false;
    },
    setProfileData: (state, action) => {
      state.data = action.payload;
    },
    setIsBlocked: (state, action) => {
      state.isBlocked = action.payload;
    },
    setUserPosts: (state, action) => {
      state.posts = action.payload;
    },
    setFollowing: (state, action) => {
      state.following = action.payload;
    },
    setFollower: (state, action) => {
      state.followers = action.payload;
    },
    setFollowingData: (state, action) => {
      state.isFollowed = action.payload.isFollowed || false;
    },
    setScoutedTalent: (state, action) => {
      state.scoutedTalent = action.payload;
    },
    setTalentPhotoList: (state, action) => {
      state.talentPhotoList = action.payload;
    },
    clearProfile: (state) => {
      state.data = {};
      state.followers = [];
      state.following = [];
      state.posts = [];
      state.talentPhotoList = [];
      state.scoutedTalent = [];
      state.isCurrentUser = false;
      state.isFollowed = false;
      state.isBlocked = false;
    }
  }
});

// actions
export const {
  setProfile,
  setUserPosts,
  setProfileData,
  setFollowingData,
  setFollower,
  setFollowing,
  setIsBlocked,
  setScoutedTalent,
  setTalentPhotoList,
  clearProfile
} = profileSlice.actions;

// reducer
export const profileReducer = profileSlice.reducer;

// selectors
export const getProfileSelector = (state) => state?.profile || {};
export const getFollowingDataSelector = (state) => ({
  following: state?.profile?.following || [],
  followers: state?.profile?.followers || []
});
export const getFetchedScoutedTalents = (state) => state?.profile?.scoutedTalents;
export const getTalentPhotoList = (state) => state?.profile?.talentPhotoList;
