import { LeftBarContainer } from './components';
import { MainLogo } from 'Components/shared/Logos/MainLogo';
import { Onboarding } from './Onboarding';
import Auth from 'middleware/storage';
import SideNav from './SideNav';

export const LeftBar = () => {
  return (
    <LeftBarContainer>
      <MainLogo />
      {!Auth?.isAuthenticated() ? <Onboarding /> : <SideNav />}
    </LeftBarContainer>
  );
};
