import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import Api2 from 'middleware/api';

import { arrayCompare, getResourceUriByBroadcastID, sortArray } from 'Utils';
import { notificationTypes, paths, POSTS_LIMIT } from 'Utils/constants';
import { addContactMessage, createGroup, getMergedPostsData, sendNotification } from './services';
import Auth from 'middleware/storage';

const concertPostsFromQuery = (querySnapshot) => {
  let posts = [];
  querySnapshot.forEach((doc) => {
    posts = doc.data().isLiveStreamingPost ? posts : [...posts, { id: doc.id, ...doc.data() }];
  });

  return posts;
};

const addAndSort = (arr, value) => {
  arr.push(value);

  return arr.sort((a, b) => {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }

    return 0;
  });
};

const reauthenticate = (currentPassword) => {
  const currentUser = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, currentPassword);

  return currentUser.reauthenticateWithCredential(credential);
};

const Api = {
  auth: {
    checkEmail: (email) =>
      firebase
        .auth()
        .fetchSignInMethodsForEmail(email)
        .then((providers) => !providers?.length),
    login: ({ email, password }) => firebase.auth().signInWithEmailAndPassword(email, password),
    logout: () => firebase.auth().signOut(),
    createUser: ({ email, password }) =>
      firebase.auth().createUserWithEmailAndPassword(email, password),
    resetPassword: (email, succeededRedirectUrl) =>
      firebase.auth().sendPasswordResetEmail(email, { url: succeededRedirectUrl }),
    changePassword: (currentPassword, newPassword) =>
      reauthenticate(currentPassword).then(() => {
        const currentUser = firebase.auth().currentUser;
        return currentUser.updatePassword(newPassword).then(() => true);
      })
  },
  user: {
    getUser: (userId) =>
      firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .get()
        .then((doc) => ({ id: doc.id, ...doc.data() })),
    addUser: ({ userId, user }) => firebase.firestore().collection('users').doc(userId).set(user),
    updateUser: ({ userId, user }) =>
      firebase.firestore().collection('users').doc(userId).update(user),
    getWhoToFollow: (sports, currentUserId) => {
      const query = firebase
        .firestore()
        .collection('users')
        .where(firebase.firestore.FieldPath.documentId(), '!=', currentUserId);
      const filteredQuery = sports?.length
        ? query.where('sports', 'array-contains-any', sports.slice(0, 10))
        : query;

      return filteredQuery
        .limit(6)
        .get()
        .then((snapshot) => {
          const users = [];

          snapshot.forEach((doc) => users.push({ ...doc.data(), id: doc.id }));

          return users;
        });
    },
    getUsersByName: (searchName, currentUserId) =>
      firebase
        .firestore()
        .collection('users')
        .where(firebase.firestore.FieldPath.documentId(), '!=', currentUserId)
        .get()
        .then((snapshot) => {
          const users = [];

          snapshot.forEach((doc) => {
            const user = doc.data();

            if (user?.name?.toLowerCase().search(searchName?.toLowerCase()) >= 0) {
              users.push({ ...user, id: doc.id });
            }
          });

          return users;
        }),
    getFollowedUsers: (userId) =>
      firebase
        .firestore()
        .collection('relationships')
        .where('followedId', '==', userId)
        .get()
        .then(async (snapshot) => {
          const followed = [];
          if (snapshot.docs.length >= 1) {
            await Promise.all(
              snapshot.docs.map(
                async (data) =>
                  await firebase
                    .firestore()
                    .collection('users')
                    .doc(data.data().followerId)
                    .get()
                    .then((userSnapshot) =>
                      followed.push({
                        id: data.data().followerId,
                        ...userSnapshot.data()
                      })
                    )
              )
            );
          }

          return followed;
        }),
    getFollowingIds: (userId) =>
      firebase
        .firestore()
        .collection('relationships')
        .where('followedId', '==', userId)
        .get()
        .then((snapshot) => snapshot.docs.map((doc) => doc.data().followerId)),
    getFollowers: (userId) =>
      firebase
        .firestore()
        .collection('relationships')
        .where('followerId', '==', userId)
        .get()
        .then(async (snapshot) => {
          const followers = [];

          if (snapshot.docs.length >= 1) {
            await Promise.all(
              snapshot.docs.map(
                async (data) =>
                  await firebase
                    .firestore()
                    .collection('users')
                    .doc(data.data().followedId)
                    .get()
                    .then((userSnapshot) =>
                      followers.push({
                        id: data.data().followedId,
                        ...userSnapshot.data()
                      })
                    )
              )
            );
          }

          return followers;
        }),
    getStats: () =>
      firebase
        .firestore()
        .collection('users')
        .where('userType', '==', 'talent')
        .get()
        .then((snapshot) => {
          const users = [];

          if (!snapshot.empty) {
            snapshot.forEach((doc) => users.push({ ...doc.data(), id: doc.id }));
          }

          return users;
        }),
    follow: async (profileId, currentUserId, isFollowed) => {
      const query = firebase
        .firestore()
        .collection('relationships')
        .doc(`${profileId}_${currentUserId}`);

      if (isFollowed) {
        query.delete();
      } else {
        query.set({
          followerId: profileId,
          followedId: currentUserId
        });
        const turnedOffNotifications = await Api.notifications.getTurnedOff(profileId);
        const notification = {
          type: notificationTypes.FOLLOW,
          createdAt: firebase.firestore.Timestamp.now(),
          authorId: profileId,
          userId: currentUserId
        };

        Api.notifications.add(notification);

        if (!turnedOffNotifications.includes(notificationTypes.FOLLOW)) {
          sendNotification(notification);
        }
      }
    },
    getIsBlocked: (profileId, currentUserId) =>
      firebase
        .firestore()
        .collection('blocked_users')
        .doc(currentUserId)
        .collection('blocked_list')
        .doc(profileId)
        .get()
        .then((snapshot) => snapshot.exists),
    block: async (profileId, currentUserId, isBlocked) => {
      if (!isBlocked) {
        await firebase
          .firestore()
          .collection('blocked_users')
          .doc(currentUserId)
          .collection('blocked_list')
          .doc(profileId)
          .set({
            isBlocked: true
          });

        return true;
      } else {
        await firebase
          .firestore()
          .collection('blocked_users')
          .doc(currentUserId)
          .collection('blocked_list')
          .doc(profileId)
          .delete();

        return false;
      }
    },
    checkUserWithFirebase: async (currentUserId, postUserId) => {
      const userDoc = await firebase
        .firestore()
        .collection('blocked_users')
        .doc(currentUserId)
        .collection('blocked_list')
        .doc(postUserId)
        .get();

      if (userDoc.exists) {
        return postUserId;
      }
    }
  },
  posts: {
    get: async (currentUser) => {
      const dataUid = [];
      const dataSport = [];
      let uidQuerySnapshot = {};
      let catQuerySnapshot = {};
      const { sports = [] } = currentUser;
      const followingIds = (await Api.user.getFollowingIds(currentUser.id)) || [];

      const userQuery = await firebase
        .firestore()
        .collection('posts')
        .where('uid', 'in', [...followingIds.slice(0, 9), currentUser.id])
        .orderBy('createdAt', 'desc')
        .limit(POSTS_LIMIT);

      await userQuery.get().then((querySnapshot) => {
        querySnapshot.docs.forEach((element) =>
          dataUid.push({ ...element.data(), id: element.ref.id })
        );

        uidQuerySnapshot = querySnapshot.docs[querySnapshot.docs.length - 1];
      });

      if (sports?.length) {
        await Promise.all(
          sports.map(async (sport) => {
            const catQuery = await firebase
              .firestore()
              .collection('posts')
              .where('postCategory', '==', sport)
              .limit(POSTS_LIMIT);

            await catQuery.get().then((querySnapshot) => {
              querySnapshot.docs.forEach((element) =>
                dataSport.push({ ...element.data(), id: element.ref.id })
              );

              catQuerySnapshot = querySnapshot.docs[querySnapshot.docs.length - 1];
            });
          })
        );
      }

      const posts = await getMergedPostsData(currentUser.id, dataUid, dataSport);

      return {
        posts,
        pagination: {
          callNextRequest: true,
          uidLastVisible: uidQuerySnapshot,
          catLastVisible: catQuerySnapshot
        }
      };
    },
    getNextPosts: async (currentUser, { uidLastVisible, catLastVisible }, currentPosts) => {
      const dataUid = [];
      const dataSport = [];
      let uidQuerySnapshot = {};
      let catQuerySnapshot = {};
      const { sports = [] } = currentUser;
      const followingIds = (await Api.user.getFollowingIds(currentUser.id)) || [];

      if (uidLastVisible !== undefined && uidLastVisible?.id) {
        const userQuery = await firebase
          .firestore()
          .collection('posts')
          .where('uid', 'in', [...followingIds.slice(0, 9), currentUser.id])
          .orderBy('createdAt', 'desc')
          .startAfter(uidLastVisible)
          .limit(POSTS_LIMIT);

        await userQuery.get().then((querySnapshot) => {
          querySnapshot.docs.forEach((element) =>
            dataUid.push({ ...element.data(), id: element.ref.id })
          );

          uidQuerySnapshot = querySnapshot.docs[querySnapshot.docs.length - 1];
        });
      }

      if (catLastVisible !== undefined && catLastVisible?.id && sports.length > 0) {
        await Promise.all(
          sports.map(async (sport) => {
            const catQuery = await firebase
              .firestore()
              .collection('posts')
              .where('postCategory', '==', sport)
              .startAfter(catLastVisible)
              .limit(POSTS_LIMIT);

            await catQuery.get().then((querySnapshot) => {
              querySnapshot.docs.forEach((element) =>
                dataSport.push({ ...element.data(), id: element.ref.id })
              );

              catQuerySnapshot = querySnapshot.docs[querySnapshot.docs.length - 1];
            });
          })
        );
      }

      const posts = await getMergedPostsData(currentUser.id, dataUid, dataSport);

      if (posts?.length) {
        return {
          posts,
          pagination: {
            callNextRequest: true,
            uidLastVisible: uidQuerySnapshot,
            catLastVisible: catQuerySnapshot
          }
        };
      } else {
        return {
          posts: [],
          pagination: {
            callNextRequest: false,
            uidLastVisible: null,
            catLastVisible: null
          }
        };
      }
    },
    getBySports: (sportName) =>
      firebase
        .firestore()
        .collection('posts')
        .where('sport', '==', sportName)
        .get()
        .then(concertPostsFromQuery),
    getByTopic: (topic) =>
      firebase
        .firestore()
        .collection('posts')
        .where('hashtags', 'array-contains', topic)
        .get()
        .then(concertPostsFromQuery),
    getTopicsBySearch: (searchValue) =>
      firebase
        .firestore()
        .collection('posts')
        .where('containsHashtags', '==', true)
        .get()
        .then((snapshot) => {
          const topics = [];

          snapshot.forEach((doc) => {
            const post = doc.data();

            post.hashtags.forEach((hashtag) => {
              if (hashtag.toLowerCase().search(searchValue.toLowerCase()) >= 0) {
                const index = topics.findIndex((topic) => topic.name === hashtag);

                if (index !== -1) {
                  topics[index].totalPosts += 1;
                } else {
                  topics.push({
                    name: hashtag,
                    totalPosts: 1
                  });
                }
              }
            });
          });

          return topics;
        }),
    getByUserId: (id, emitter) =>
      firebase
        .firestore()
        .collection('posts')
        .where('uid', '==', id)
        .orderBy('createdAt', 'desc')
        .onSnapshot((snapshot) => {
          const posts = [];

          snapshot.forEach((doc) => posts.push({ id: doc.id, ...doc.data() }));

          emitter(posts);
        }),
    upload: (post) => firebase.firestore().collection('posts').add(post),
    report: (storyId, userId, { isOffensive, isFalse, other, details }) => {
      const mainContent = [];

      if (isOffensive) {
        mainContent.push({
          userId,
          storyId,
          content: "it's offensive"
        });
      }

      if (isFalse) {
        mainContent.push({
          userId,
          storyId,
          content: "it's false"
        });
      }

      if (other) {
        mainContent.push({
          userId,
          storyId,
          content: details
        });
      }

      mainContent.forEach((item) => {
        firebase.firestore().collection('report_content').add(item);
      });
    },
    like: async (post) => {
      const curUser = firebase.auth().currentUser;

      if (post.uid !== curUser.uid) {
        let likes = !post?.likes?.length ? [] : [...post.likes];
        let likeCountDifference = 0;

        if (!likes.includes(curUser.uid)) {
          likes.push(curUser.uid);
          likeCountDifference = 1;

          const turnedOffNotifications = await Api.notifications.getTurnedOff(post.uid);
          const notification = {
            type: notificationTypes.LIKE,
            createdAt: firebase.firestore.Timestamp.now(),
            postId: post.id,
            authorId: post.uid,
            userId: curUser.uid
          };

          Api.notifications.add(notification);

          if (!turnedOffNotifications.includes(notificationTypes.LIKE)) {
            sendNotification(notification);
          }
        } else {
          likes = likes.filter((value) => value !== curUser.uid);
          likeCountDifference = -1;
        }

        await firebase.firestore().collection('posts').doc(post.id).update({ likes });

        firebase
          .firestore()
          .collection('users')
          .doc(post.uid)
          .get()
          .then((documentSnapshot) => {
            const user = documentSnapshot.data();

            firebase
              .firestore()
              .collection('users')
              .doc(post.uid)
              .update({
                likeCount: (user.likeCount || 0) + likeCountDifference
              });
          });

        return likes;
      }
    },
    commentLike: (comment, post) => {
      const curUser = firebase.auth().currentUser;

      if (comment.author !== curUser.uid) {
        const likes = [...comment.likes];
        const copiedComments = [...post.comments];

        if (!likes.includes(curUser.uid)) {
          likes.push(curUser.uid);

          const comments = copiedComments.map((item) => {
            if (item.id === comment.id) {
              return { ...item, likes };
            }

            return item;
          });

          firebase.firestore().collection('posts').doc(post.id).update({
            comments
          });
        } else {
          const comments = copiedComments.map((item) => {
            if (item.id === comment.id) {
              return { ...item, likes: likes.filter((like) => like !== curUser.uid) };
            }

            return item;
          });

          firebase.firestore().collection('posts').doc(post.id).update({
            comments
          });
        }
      }
    },
    getPostById: (id, emitter) =>
      firebase
        .firestore()
        .collection('posts')
        .doc(id)
        .onSnapshot(async (doc) => {
          const data = doc.data();
          const author = await Api.user.getUser(data.uid);

          emitter({ data: { ...data, id }, author });
        }),
    viewPost: (id, viewCount, author) => {
      firebase
        .firestore()
        .collection('posts')
        .doc(id)
        .update({ views: (viewCount || 0) + 1 });

      if (author) {
        firebase
          .firestore()
          .collection('users')
          .doc(author.id)
          .update({
            viewCount: (author?.viewCount || 0) + 1
          });
      }
    },
    sendComment: (userId, { comment, parent, postId, postUid, prevComments }) => {
      const query = firebase.firestore().collection('posts').doc(postId);

      const newData = {
        id: firebase.firestore.Timestamp.now().seconds,
        text: comment,
        author: userId,
        parent,
        likes: [],
        unlikes: []
      };
      const newComments = addAndSort([...prevComments], newData);

      query.update({
        comments: newComments
      });

      Api.user.getUser(userId).then((curUser) => {
        Api.user.updateUser({
          userId,
          user: { commentCount: (curUser.commentCount || 0) + 1 }
        });
      });

      if (postUid !== userId) {
        Api.notifications.add({
          type: 'comment',
          createdAt: firebase.firestore.Timestamp.now(),
          postId,
          comment,
          authorId: postUid,
          userId
        });
      }

      return newComments;
    },
    getCategories: () =>
      firebase
        .firestore()
        .collection('post_category')
        .orderBy('name', 'asc')
        .get()
        .then((querySnapshot) => {
          const categories = [];

          querySnapshot.forEach((doc) => categories.push(doc.data()));

          return categories;
        }),
    getTopics: () =>
      firebase
        .firestore()
        .collection('posts')
        .where('containsHashtags', '==', true)
        .get()
        .then((querySnapshot) => {
          const topics = [];

          querySnapshot.forEach((post) => {
            const postData = post.data();

            postData.hashtags.forEach((hashtag) => {
              const index = topics.findIndex((topic) => topic.name === hashtag);

              if (index !== -1) {
                topics[index].totalPosts += 1;
              } else {
                topics.push({
                  name: hashtag,
                  totalPosts: 1
                });
              }
            });
          });

          topics.sort((a, b) => b.totalPosts - a.totalPosts);

          return topics;
        })
  },
  relationship: {
    getRelationships: ({ userId, relationshipType }) =>
      firebase
        .firestore()
        .collection('relationships')
        .where(relationshipType, '==', userId)
        .get()
        .then((collection) => collection.docs.length),
    hasRelationship: (relationshipId) =>
      firebase
        .firestore()
        .collection('relationships')
        .doc(relationshipId)
        .get()
        .then((doc) => doc.exists),
    addRelationship: ({ followerId, followedId }) =>
      firebase
        .firestore()
        .collection('relationships')
        .doc(`${followerId}_${followedId}`)
        .set({ followerId, followedId }),
    deleteRelationship: (relationshipId) =>
      firebase.firestore().collection('relationships').doc(relationshipId).delete(),
    getFollowingUsers: (userId) =>
      firebase
        .firestore()
        .collection('relationships')
        .where('followedId', '==', userId)
        .get()
        .then((collection) => {
          const followerIds = [];
          collection.forEach((doc) => followerIds.push(doc.data().followerId));
          return followerIds;
        })
  },
  notifications: {
    get: async (userId, emitter) => {
      const commonNotifications = Object.values(notificationTypes).filter(
        (type) => type !== notificationTypes.LIVE
      );

      firebase
        .firestore()
        .collection('notifications')
        .where('type', 'in', commonNotifications)
        .where('authorId', '==', userId)
        .onSnapshot(async (snapshot) => {
          const commonNotifications = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
          }));

          firebase
            .firestore()
            .collection('notifications')
            .where('type', '==', notificationTypes.LIVE)
            .where('userId', '==', userId)
            .onSnapshot(async (liveSnapshot) => {
              const liveNotifications = liveSnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
                route: `${paths.TIMELINE}/${doc.data().broadcast.uid}?isLivestream=true`
              }));

              const sortedNotifications = sortArray(
                [...commonNotifications, ...liveNotifications],
                'createdAt',
                'desc'
              );

              const resultNotifications = await Promise.all(
                sortedNotifications.map(async (notification) => {
                  const authorData = await Api.user.getUser(
                    notification.type === notificationTypes.LIVE
                      ? notification.authorId
                      : notification.userId
                  );

                  return {
                    ...notification,
                    user: {
                      id: authorData.id,
                      name: authorData.name,
                      picture: authorData?.picture
                    }
                  };
                })
              );

              emitter(resultNotifications);
            });
        });
    },
    getTurnedOff: (profileId) =>
      firebase
        .firestore()
        .collection('users')
        .doc(profileId)
        .get()
        .then(async (doc) => {
          const data = await doc.data();

          return data?.turnedOffNotifications || [];
        }),
    add: (notification) => firebase.firestore().collection('notifications').add(notification),
    delete: (notificationId) =>
      firebase.firestore().collection('notifications').doc(notificationId).delete(),
    read: (notificationId) =>
      firebase.firestore().collection('notifications').doc(notificationId).update({ isRead: true }),
    turnOff: (notificationType, userId, turnedOffNotifications = []) => {
      const newNotifications = turnedOffNotifications?.includes(notificationType)
        ? turnedOffNotifications.filter((notification) => notification !== notificationType)
        : [...turnedOffNotifications, notificationType];

      firebase.firestore().collection('users').doc(userId).update({
        turnedOffNotifications: newNotifications
      });
    }
  },
  messages: {
    getGroups: (userId, emitter) =>
      firebase
        .firestore()
        .collection('chat_groups')
        .where('members', 'array-contains-any', [userId])
        .orderBy('lastMessageDate', 'desc')
        .onSnapshot((querySnapshot) => {
          const groups = querySnapshot.docs.map((value) => ({
            id: value.id,
            ...value.data()
          }));

          groups.map((m, index) =>
            firebase
              .firestore()
              .collection('chat_groups')
              .doc(m.id)
              .collection('messages')
              .orderBy('createdAt', 'desc')
              .get()
              .then((querySnapshot) => {
                const messages = [];

                querySnapshot.docs.forEach((doc) => {
                  const messageData = doc.data();
                  messages.push({
                    id: doc.id,
                    userId: messageData.user._id,
                    text: messageData.text,
                    createdAt: messageData.createdAt,
                    isRead: messageData.isRead
                  });
                });
                messages.sort((a, b) => (a.createdAt.toMillis() < b.createdAt.toMillis() ? -1 : 1));

                //const lastMessage = messages[messages.length - 1] || m.lastMessageDate;
                //const isRead = lastMessage?.userId === userId ? true : messages[0]?.isRead || false;

                m.members.forEach(async (member) => {
                  if (member !== userId) {
                    // firebase
                    //   .firestore()
                    //   .collection('users')
                    //   .doc(member)
                    //   .get()
                    //   .then((doc) => {
                    //     if (doc.exists) {
                    //       emitter((prev) => [
                    //         ...prev.filter((obj) => obj.userId !== member),
                    //         {
                    //           userId: member,
                    //           name: doc.data().name,
                    //           avatar: doc.data().picture,
                    //           role: doc.data().userType,
                    //           isRead,
                    //           messages,
                    //           previewMessage: lastMessage?.text || '',
                    //           id: m.id,
                    //           lastMessageDate: m.lastMessageDate
                    //         }
                    //       ]);
                    //     }
                    //   });

                    // store user info in the chat data
                    try {
                      const chatUser = await Api2.user.userDetailById(member);

                      emitter((prev) =>
                        [
                          ...prev.filter((obj) => obj.userId !== member),
                          {
                            userId: member,
                            name: chatUser?.data?.data?.firstName,
                            avatar: chatUser?.data?.data?.picture,
                            isRead: m.isRead,
                            previewMessage: m.previewMessage,
                            MessageGroupID: m.id,
                            id: m.id,
                            previewMessageID: m.previewMessageID,
                            messages,
                            lastMessageDate: m.lastMessageDate,
                            role: chatUser?.data?.data?.userType,
                            index
                          }
                        ].sort((a, b) => a.index - b.index)
                      );
                    } catch (error) {}
                  }
                });
              })
          );
        }),
    read: (groupId, messageId) =>
      firebase
        .firestore()
        .collection('chat_groups')
        .doc(groupId)
        .collection('messages')
        .doc(messageId)
        .update({
          isRead: true
        }),
    send: async (groupId, currentUserId, userId, text) => {
      const message = {
        id: uuidv4(),
        text,
        createdAt: firebase.firestore.Timestamp.now(),
        isRead: false,
        user: {
          _id: currentUserId
        }
      };

      const groupQuery = firebase.firestore().collection('chat_groups').doc(groupId);
      const messagesQuery = groupQuery.collection('messages');
      const messageRef = await messagesQuery.add(message);

      groupQuery.update({
        lastMessageDate: message.createdAt
      });

      sendNotification({
        type: notificationTypes.MESSAGE,
        authorId: currentUserId,
        userId,
        createdAt: message.createdAt,
        message: { ...message, messageId: messageRef.id, docId: groupId }
      });
    },
    create: (members, emitter) => {
      firebase
        .firestore()
        .collection('chat_groups')
        .where('members', 'array-contains-any', [members[0]])
        .where('no_of_members', '==', members.length)
        .get()
        .then(async (querySnapshot) => {
          if (!querySnapshot.empty) {
            const matchGroups = querySnapshot.docs.filter((doc) =>
              arrayCompare(doc.data().members, members)
            );

            if (matchGroups.length === 0) {
              const documentId = await createGroup(members);

              emitter(documentId);
            } else {
              emitter(matchGroups[0].id);
            }
          } else {
            const documentId = await createGroup(members);

            emitter(documentId);
          }
        });
    },
    bulkCreate: (members, currentUserId) => {
      members.forEach((member) =>
        firebase
          .firestore()
          .collection('chat_groups')
          .where('no_of_members', '==', 2)
          .where('members', 'array-contains', member._id)
          .get()
          .then(async (querySnapshot) => {
            const currentMembers = [member._id, currentUserId];

            if (!querySnapshot.empty) {
              const matchGroup = querySnapshot.docs.find((doc) =>
                arrayCompare(doc.data().members, currentMembers)
              );

              if (!matchGroup) {
                const documentId = await createGroup(currentMembers);

                addContactMessage(documentId, currentUserId);
              } else {
                addContactMessage(matchGroup.id, currentUserId);
              }
            } else {
              const documentId = await createGroup(currentMembers);

              addContactMessage(documentId, currentUserId);
            }
          })
      );
    },
    messageUpdate: async (setChatData = () => {}) => {
      const user = await Auth.getUser();
      console.log(setChatData, 'setChatData setChatData setChatData');
      firebase
        .firestore()
        .collection('chat_groups')
        .where('members', 'array-contains-any', [user._id])
        .orderBy('lastMessageDate', 'desc')
        .onSnapshot({
          error: (error) => {},
          next: (querySnapshot) => {
            const groups = [];
            querySnapshot.docs.forEach((value) => {
              const group = value.data();
              group.id = value.id;
              groups.push(group);
            });
            groups.sort((a, b) => a.lastMessageDate.toMillis() < b.lastMessageDate.toMillis());

            groups.map((m, index) => {
              return firebase
                .firestore()
                .collection('chat_groups')
                .doc(m.id)
                .collection('messages')
                .orderBy('createdAt', 'desc')
                .onSnapshot({
                  next: (querySnapshot) => {
                    const messages = [];
                    querySnapshot.docs.forEach((doc) => {
                      const message = doc.data();
                      message.id = doc.id;
                      messages.push(message);
                    });
                    if (messages.length > 0) {
                      m.previewMessage = messages[0].text;
                      m.isRead =
                        messages[0].user._id === user._id ? true : messages[0].isRead || false;
                      m.previewMessageID = messages[0].id;

                      m.members.forEach(async (member) => {
                        if (member === user._id) {
                          return '';
                        }

                        // firestore()
                        //   .collection('users')
                        //   .doc(member)
                        //   .get()
                        //   .then((doc) => {
                        //     if (doc.exists) {
                        //       setChatData((prev) => (
                        //         [
                        //           ...prev.filter((obj) => obj.userId !== member),
                        //           {
                        //             userId: member,
                        //             name: doc.data().name,
                        //             avatar: doc.data().picture,
                        //             isRead: m.isRead,
                        //             previewMessage: m.previewMessage,
                        //             MessageGroupID: m.id,
                        //             previewMessageID: m.previewMessageID,
                        //             index,
                        //           },
                        //         ].sort((a, b) => a.index - b.index)
                        //       ));
                        //     } else {

                        //     }
                        //   })
                        //   .catch((error) => {

                        //   });

                        try {
                          const chatUser = await Api2.user.userDetailById(member);
                          setChatData((prev) =>
                            [
                              ...prev.filter((obj) => obj.userId !== member),
                              {
                                userId: member,
                                name: chatUser.data.data.firstName,
                                avatar: chatUser.data.data.picture,
                                isRead: m.isRead,
                                previewMessage: m.previewMessage,
                                MessageGroupID: m.id,
                                previewMessageID: m.previewMessageID,
                                index
                              }
                            ].sort((a, b) => a.index - b.index)
                          );
                        } catch (error) {
                          console.log(error, 'messge update');
                        }
                      });
                    }
                  }
                });
            });
          }
        });
    }
  },
  stories: {
    get: () => {
      const beginningDate = Date.now() - 24 * 60 * 60 * 1000;
      const beginningDateObject = new Date(beginningDate);

      return firebase
        .firestore()
        .collection('stories')
        .orderBy('createdAt', 'desc')
        .where('createdAt', '>', beginningDateObject)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const tmpStoriesObj = {};

            querySnapshot.docs.forEach((story) => {
              const storyData = story.data();

              if (!(storyData.uid in tmpStoriesObj)) {
                tmpStoriesObj[storyData.uid] = {
                  id: storyData.uid,
                  username: storyData.uname,
                  title: storyData.title,
                  profile: storyData.uprofile,
                  stories: []
                };
              }

              tmpStoriesObj[storyData.uid].stories.push({
                id: story.id,
                url: storyData.url,
                type: storyData.type,
                duration: 15000
              });
            });

            const finalArray = [];
            Object.keys(tmpStoriesObj).forEach((tmp) => {
              finalArray.push(tmpStoriesObj[tmp]);
            });
            return finalArray;
          } else {
            return [];
          }
        });
    },
    getLivestreams: () =>
      firebase
        .firestore()
        .collection('online_streaming')
        .orderBy('createdAt', 'desc')
        .get()
        .then((snapshot) => {
          const livestreams = [];

          if (!snapshot.empty) {
            snapshot.docs.forEach((doc) => {
              const livestream = doc.data()?.posts[0];
              livestreams.push({
                id: doc.id,
                createdAt: doc.data().createdAt,
                ...livestream,
                resourceId: getResourceUriByBroadcastID(livestream.url)
              });
            });
          }

          return livestreams;
        }),
    getLivestreamById: (id) =>
      firebase
        .firestore()
        .collection('online_streaming')
        .doc(id)
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          const livestreamData = data.posts[0];

          return {
            id: snapshot.id,
            createdAt: data.createdAt,
            ...livestreamData,
            resourceId: getResourceUriByBroadcastID(livestreamData.url)
          };
        }),
    updateLivestream: async (id, data) => {
      const livestream = firebase.firestore().collection('online_streaming').doc(id);
      const livestreamData = await livestream.get().then((doc) => doc.data().posts[0]);

      livestream.update({ posts: [{ ...livestreamData, ...data }] });
    },
    likeLivestream: async (livestream, userId) => {
      let newLikes = [...livestream.likes];

      if (!livestream.likes.includes(userId)) {
        newLikes.push(userId);
      } else {
        newLikes = newLikes.filter((likeId) => likeId !== userId);
      }

      await firebase
        .firestore()
        .collection('online_streaming')
        .doc(livestream.id)
        .update({ posts: [{ ...livestream, likes: newLikes }] });

      return newLikes;
    }
  },
  countries: {
    get: () =>
      firebase
        .firestore()
        .collection('country')
        .orderBy('name', 'asc')
        .get()
        .then((querySnapshot) => {
          let countries = [];

          querySnapshot.forEach((doc) => {
            countries = [...countries, { label: doc.data().name, value: doc.data().name }];
          });

          return countries;
        })
  },
  transaction: {
    create: (transaction) => firebase.firestore().collection('transactions').add(transaction)
  }
};

export default Api;
