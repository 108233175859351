import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { paths } from 'Utils/constants';
import { BaseButton, BlackText } from 'Components/shared/styledComponents';
import { SuccessContainer, SecondHeader, Margin } from './components';

import { ReactComponent as Flag } from 'Assets/icons/Flag.svg';

import { fontSizes } from 'Theme';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ThemeContext } from 'Context/themeContext';
import { SpoxioBlueBtn } from 'Components/Layouts/AuthLayout/components';

export const Success = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const openTimeline = () => dispatch(push(paths.TIMELINE));

  return (
    <SuccessContainer>
      <Flag color={theme.spoxioBlue} stroke={theme.spoxioBlue} />
      <SecondHeader textAlign="center" fontWeight="bold" fontSize={fontSizes.xLarge}>
        {t('report_success')}
      </SecondHeader>
      <Margin>
        <BlackText>{t('report_feedback')}</BlackText>
      </Margin>
      <Margin>
        <SpoxioBlueBtn onClick={openTimeline}>{t('go_back_to_timeline')}</SpoxioBlueBtn>
      </Margin>
    </SuccessContainer>
  );
};
