import styled from 'styled-components';

import { BaseText, BlackText, CenteredContainer } from 'Components/shared/styledComponents';

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* gap: 20px; */
  width: 100%;
  height: 630px;
  margin-top: 30px;
  border: 1px solid ${({ theme }) => theme.lightGray2};
  border-radius: 20px;
`;

export const UserList = styled.div`
  width: 300px;
  border-right: 1px solid ${({ theme }) => theme.lightGray2};

  @media (max-width: 1024px) {
    width: auto;
  }
`;

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

export const NotSelectedChatContainer = styled(CenteredContainer)`
  justify-content: center;
  margin: auto;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

export const UserItem = styled.div`
  padding: 24px 10px 24px 24px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.lightGray2};
  cursor: pointer;
  border-top-left-radius: ${({ index }) => (index === 0 ? '20px' : 'none')};
  border-left: 4px solid ${({ theme, selected }) => (selected ? theme.primaryBlue : theme.white)};
  background: ${({ theme, selected }) => (selected ? theme.opacityPrimaryBlue : 'transparent')};

  @media (max-width: 1024px) {
    padding: 24px 10px;
  }
`;

export const CenterListItem = styled.div`
  padding: 0 10px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const DateLabel = styled(BaseText)`
  min-width: 50px;
  margin-left: auto;
  font-size: ${({ theme }) => theme.small}px;
  text-align: right;
  color: ${({ theme }) => theme.opacityGray};

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ListName = styled(BlackText)`
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 130px;
`;

export const ListMessage = styled(BlackText)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: ${({ theme }) => theme.small}px;
  line-height: 18px;
  max-width: 130px;
`;

export const TextArea = styled.textarea`
  width: 100% !important;
  min-height: 75px;
  padding: 15px 27px 15px 15px;
  resize: none;
  outline: none !important;
  border-color: ${({ theme }) => theme.lightGray};
  border-style: solid;
  border-radius: 20px;
  font-family: SF Pro;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.gray};
  }

  :disabled {
    background-color: rgba(222, 222, 222, 0.42);
    border-color: transparent;
    color: ${({ theme }) => theme.black};
  }
`;

export const TextareaContainer = styled.div`
  position: relative;
  width: 100%;

  & svg {
    position: absolute;
    bottom: 15px;
    right: 10px;
    cursor: pointer;
  }
`;

export const UserInfo = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MessagesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px;
  height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MessageInputContainer = styled.div`
  margin-top: auto;
  padding: 0 15px 15px;
`;

export const Message = styled(BlackText)`
  display: inline-block;
  border-radius: 20px;
  padding: 8px 15px;
  margin-bottom: 5px;
  margin-right: 25%;
  position: relative;
  align-self: flex-start;
  background-color: ${({ theme }) => theme.lightGray1};
  line-height: 22px;

  &:before {
    display: ${({ isLast }) => (isLast ? 'block' : 'none')};
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: ${({ theme }) => theme.lightGray1};
    border-bottom-right-radius: 15px;
  }

  &:after {
    display: ${({ isLast }) => (isLast ? 'block' : 'none')};
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
  }
`;

export const SentMessage = styled.div`
  border-radius: 20px;
  padding: 8px 15px;
  margin-bottom: 5px;
  display: inline-block;
  color: white;
  margin-left: 25%;
  background: ${({ theme }) => theme.blue};
  background-attachment: fixed;
  position: relative;
  align-items: flex-end;
  align-self: flex-end;

  &:before {
    display: ${({ isLast }) => (isLast ? 'block' : 'none')};
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: ${({ theme }) => theme.blue};
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }

  &:after {
    display: ${({ isLast }) => (isLast ? 'block' : 'none')};
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-left-radius: 10px;
  }
`;

export const TitleName = styled(BlackText)`
  font-size: ${({ theme }) => theme.xLarge}px;
`;

export const TitleRole = styled(BaseText)`
  font-size: ${({ theme }) => theme.small}px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.opacityGray};
`;

export const MessageTime = styled(BaseText)`
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.small}px;
  color: ${({ theme }) => theme.opacityGray};
`;

export const SentMessageTime = styled(MessageTime)`
  display: flex;
  justify-content: flex-end;
`;
