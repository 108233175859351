import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { getCategoriesWithCount, uploadMedia } from 'Api/services';
import { detectLanguage, hashtagsToArray } from 'Utils';
import { getPostsBySearch } from './discoverySlice';
import { setLoading } from './loaderSlice';
import Api2 from 'middleware/api';
import { setPost, setPosts, setTimelineMetaData } from './selectors/postSelector';
import Api from 'Api';
import { fetchPostCommentReplies, fetchPostComments } from './commentSlice';
import { setSearchData } from './selectors/discoverySelector';
import Auth from 'middleware/storage';

const initialState = {
  categories: [],
  selectedPost: null
};

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setSelectedPost: (state, action) => {
      state.selectedPost = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setLikes: (state, action) => {
      state.selectedPost = {
        ...state.selectedPost,
        data: {
          ...state.selectedPost?.data,
          id: action.payload.id,
          likes: action.payload.likes
        }
      };
    }
  }
});

// actions
export const { setSelectedPost, setCategories, setLikes } = postSlice.actions;

// reducer
export const postReducer = postSlice.reducer;

// selectors
export const getSelectedPostSelector = (state) => state?.post?.selectedPost;
export const getCategoriesSelector = (state) => state?.post?.categories;
export const getPopularCategoriesSelector = (state) =>
  state?.post?.categories.filter((category) => category?.isMostPopular);

// thunks
export const getPostById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await Api.posts.getPostById(id, (data) => dispatch(setSelectedPost(data)));
    dispatch(setLoading(false));
  } catch (error) {
    // toast.error(error);
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
  }
};

export const createPost = ({ text, files, sport = null, address = null }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(setLoading(true));

    const { userId } = getState().auth;

    const postTextLanguage = await detectLanguage(text);
    const hashtags = hashtagsToArray(text);
    const posts = await uploadMedia(files, userId);

    const data = {
      text,
      hashtags: Array.from(new Set(hashtags)),
      //containsHashtags: Boolean(hashtagsArray.length),
      address: address?.text || null,
      //sport: fullInfoSport.name,
      sport: sport?._id || null, // "6086ad3877b4753073ce5f48"
      postTextLanguage: postTextLanguage,
      contents: posts,
      location: {
        lat: address?.lat || null,
        lng: address?.lng || null
      }
    };

    await Api2.post.createPost(data);

    toast.success('✔ Post created');

    await dispatch(fetchAllPosts());

    dispatch(setLoading(false));
  } catch (error) {
    // toast.error(error.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
  }
};

//new endpoint
export const toggleLikePostOrComment = (id, type, action) => async () => {
  try {
    const data = {
      contentId: id,
      contentType: type,
      action: action
    };

    await Api2.post.likePostOrComment(data);
  } catch (error) {
    // console.log('like post/comment err', error, error.response);
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

export const sendComment = (comment, parent = 0) => async (dispatch, getState) => {
  try {
    const {
      auth: { userId },
      post: { selectedPost }
    } = getState();

    const comments = await Api.posts.sendComment(userId, {
      comment,
      parent,
      postId: selectedPost?.data?.id,
      postUid: selectedPost?.data?.uid,
      prevComments: selectedPost?.data?.comments || []
    });

    dispatch(setSelectedPost({ ...selectedPost, data: { ...selectedPost.data, comments } }));
  } catch (error) {
    // toast.error(error.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

export const likeComment = (comment) => async (dispatch, getState) => {
  try {
    const {
      post: { selectedPost },
      auth: { userId }
    } = getState();

    if (userId !== comment.author) {
      await Api.posts.commentLike(comment, { ...selectedPost.data });

      dispatch(getPostById(selectedPost.data.id));
    } else {
      toast.warn('This is your own comment');
    }
  } catch (error) {
    // toast.error(error.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

export const reportPost = (reportData, reportDataId = null) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    await Api2.post.reportPost(reportData);

    dispatch(setLoading(false));
  } catch (error) {
    // toast.error(error.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
  }
};

export const getPostCategories = (sport) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const categories = await Api.posts.getCategories();
    const categoriesWithCount = await getCategoriesWithCount(categories);

    dispatch(setCategories(categoriesWithCount));

    if (sport) {
      const sportData = categoriesWithCount.find((category) => category.name === sport);
      const search = { type: 'sport', data: sportData };

      dispatch(getPostsBySearch(search));
      dispatch(setSearchData(search));
    }
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
    dispatch(setLoading(false));
  }
};

export const viewPost = (data, author) => async (dispatch, getState) => {
  try {
    const { userId } = getState().auth;
    const authorData = author?.id !== userId ? author : null;

    await Api.posts.viewPost(data?.id, data?.views, authorData);

    dispatch(
      setSelectedPost({
        author: { ...author, viewCount: (author?.viewCount || 0) + 1 },
        data: { ...data, views: (data?.views || 0) + 1 }
      })
    );
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error.toString());
  }
};

//new endpoint

export const fetchAllPosts = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const token = Auth.getToken();
    const response = await Api2.post.timelinePost(page, limit, token);
    // console.log(response, 'the resp all post');
    const { data, _metadata } = response.data;
    dispatch(setPosts(data));
    dispatch(setTimelineMetaData(_metadata));
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    return false;
  }
};

export const fetchSinglePost = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const token = await Auth.getToken();
    const response = token ? await Api2.post.singlePost(id) : await Api2.post.singlePostNoAuth(id);

    const { data } = response.data;

    dispatch(setPost(data));
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    return false;
  }
};

export const sendCommentPost = (comment, postId, commentId = null) => async (dispatch) => {
  try {
    let data = {
      postId: postId,
      content: comment
    };

    if (commentId) {
      data = {
        ...data,
        parentCommentId: commentId
      };
    }
    await Api2.comment.createComment(data);
    await dispatch(fetchPostComments(postId));
    if (commentId) await dispatch(fetchPostCommentReplies(commentId));
    return true;
  } catch (error) {
    return false;
  }
};
