import { toast } from 'react-toastify';
import { setLoading } from './loaderSlice';
import Auth from 'middleware/storage';
import Api from 'middleware/api';
import {
  setFollower,
  setFollowing,
  setUserPosts,
  setProfile,
  setProfileData,
  setScoutedTalent,
  setTalentPhotoList
} from './selectors/profileSelector';
import { setIsAuthorized, setRole, setUser, setUserId } from './selectors/authSelector';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/storage';

import { updateTalentProfile } from './authSlice';

// thunks
export const getProfilePosts = (id) => async (dispatch) => {
  try {
    const res = await Api.post.userPosts(id);
    dispatch(setUserPosts(res.data.data));
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error?.toString());
  }
};

export const getFollowingData = (id = null) => async (dispatch) => {
  try {
    const resFollower = await Api.user.followers(id);
    const resFollowing = await Api.user.following(id);

    dispatch(setFollower(resFollower.data.data));
    dispatch(setFollowing(resFollowing.data.data));

    return true;
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error?.toString());
  }
};

// export const getProfile = (profileId = null) => async (dispatch, getState) => {
//     try {
//         dispatch(setLoading(true));

//         const { userId, user } = getState().auth;

//         if (!profileId || userId === profileId) {
//             await dispatch(getProfilePosts(userId));
//             await dispatch(getFollowingData(userId));
//             dispatch(setProfile({ data: { ...user, id: userId }, isCurrentUser: true }));
//         } else {
//             const profile = await Api.user.getUser(profileId);
//             const isBlocked = await Api.user.getIsBlocked(profileId, userId);

//             await dispatch(getProfilePosts(profileId));
//             await dispatch(getFollowingData(profileId));
//             dispatch(setProfile({ data: profile, isCurrentUser: false, isBlocked }));
//         }

//         dispatch(setLoading(false));
//     } catch (error) {
//         toast.error(error?.toString());
//         dispatch(setLoading(false));
//     }
// };

export const followOnUser = (id) => async (dispatch) => {
  try {
    await Api.user.followUser(id);
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error?.toString());
  }
};

export const unfollowOnUser = (id) => async (dispatch) => {
  try {
    await Api.user.unFollowUser(id);
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error?.toString());
  }
};

export const blockUser = (profileId) => async (dispatch, getState) => {
  try {
    const response = await Api.user.blockUser(profileId);
    await dispatch(getProfileById(profileId));
    toast.success(response?.data.message?.toString());
    console.log(response?.data, 'response', response);
    return response;
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error?.toString());
  }
};

export const unBlockUser = (profileId) => async (dispatch, getState) => {
  try {
    await Api.user.unBlockUser(profileId);
    await dispatch(getProfileById(profileId));
  } catch (error) {
    // toast.error(error?.toString());
    toast.error(error.response ? error.response?.data?.detail : error?.toString());
  }
};

export const getProfile = (profileId = null) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    await Auth.getToken();

    if (!profileId) {
      const res = await Api.user.userDetails();
      const { data } = res.data;
      dispatch(setProfile({ data: data, isCurrentUser: true }));
      dispatch(setIsAuthorized(true));
      dispatch(setUser(data));
      dispatch(setProfileData(data));
      dispatch(setUserId(data?._id));
      dispatch(setRole(data.userType));
      dispatch(fetchScoutedTalents());
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('type', data.userType);
    } else {
      const res = await Api.user.userDetailById(profileId);
      const { data } = res.data;
      dispatch(setProfile({ data, isCurrentUser: false }));
      dispatch(setProfileData(data));
    }

    dispatch(setLoading(false));
  } catch (error) {
    //toast.error(error?.toString());
    dispatch(setLoading(false));
  }
};

export const getProfileById = (profileId = null) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));

    const { userId } = getState().auth;

    const res = await Api.user.userDetailById(profileId);
    const { data } = res.data;

    dispatch(
      setProfile({
        data: data,
        isCurrentUser: userId === data._id,
        isBlocked: data.isBlockedByMe
      })
    );
    dispatch(setProfileData(data));
    dispatch(getProfilePosts(profileId));
    dispatch(getFollowingData(profileId));

    dispatch(setLoading(false));
  } catch (error) {
    //toast.error(error?.toString());
    dispatch(setLoading(false));
  }
};

export const uploadToFirebase = (path, file, currentUserId) => async (dispatch) => {
  console.log(
    'path details ---------------------->',
    // path,
    '\n file data ---------------------->',
    file,
    'current user ---------------------->',
    currentUserId
  );
  const filename = `${file.name}`;
  const storageRef = firebase.storage().ref(`users/${filename}`);
  //    const uploadTask = uploadBytesResumable(storageRef, file);

  //     uploadTask.on(
  //         "state_changed",
  //         (snapshot) => {
  //             const percent = Math.round(
  //                 (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //             );

  //             // update progress
  //             setPercent(percent);
  //         },
  //         (err) => console.log(err),
  //         () => {
  //             // download url
  //             getDownloadURL(uploadTask.snapshot.ref).then((url) => {
  //                 console.log(url);
  //             });
  //         }
  //     );
  storageRef
    .putString(path, 'base64', { contentType: file.type })
    .then(() => storageRef.getDownloadURL())
    .then(async (result) => {
      console.log('\n\n from firebase--------------->', result);
      const data = {
        cv: result
      };
      let response = await dispatch(updateTalentProfile(data, currentUserId));
      console.log('response uploadToFirebase', response);
      if (response) {
        toast.success('CV Updated Successfully');
        return;
      }
    })
    .catch((error) => console.log('the upload cv to firebase error', error, error?.response))
    .finally(() => setLoading(false));
};

export const fetchScoutedTalents = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await Api.user.fetchScoutedTalents();
    const { data } = response.data;
    dispatch(setScoutedTalent(data));
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    // response?.invalid_params
    // 								? response?.invalid_params[0]
    // 								: response?.detail
    if (error?.response?.data) {
      toast.warn(error.response.data.detail);
    } else {
      toast.warn('Network error', 'error');
    }
    return false;
  }
};

export const scoutTalent = (talentId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await Api.user.scoutTalent(talentId);
    const { data } = response.data;
    dispatch(setLoading(false));
    toast.success('Scouted');
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.data) {
      toast.error(error.response.data.title, 'warning');
    } else {
      toast.error('Network error', 'error');
    }
    return false;
  }
};

export const postTalentGallery = (data) => async (dispatch) => {
  console.log('🚀 ~ file: profileSlice.js:242 ~ postTalentGallery ~ data:', data);

  try {
    // dispatch(setLoading(true));
    let resp = await Api.user.postTalentGallery(data);
    toast.success('Photo uploaded successfully');
    return true;
  } catch (error) {
    // dispatch(setLoading(false));
    if (error?.response?.data) {
      toast.error(error.response?.data?.title);
    } else {
      toast.error('Network error', 'error');
    }

    return false;
  }
};

export const fetchTalentGallery = (talentId) => async (dispatch) => {
  try {
    const response = await Api.user.fetchTalentGallery(talentId);
    // console.log('\nresponse fetch talent gallery\n', response);
    const { data } = response.data;
    console.log('🚀 ~ file: profileSlice.js:266 ~ fetchTalentGallery ~ data:', data);
    dispatch(setTalentPhotoList(data));
    return data;
  } catch (error) {
    // console.log(error.response)
    if (error?.response?.data) {
      toast.error(error.response.data.title);
    } else {
      toast.error('Network error');
    }
    return [];
  }
};

export const deleteTalentPhoto = (photoId) => async (dispatch) => {
  try {
    await Api.user.deleteTalentPhoto(photoId);
    toast.success('Remove successfully');
    return true;
  } catch (error) {
    if (error?.response?.data) {
      toast.error(error.response.data.title);
    } else {
      toast.error('Network error');
    }
    return false;
  }
};
