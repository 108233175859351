import { SocialAuth } from 'Components/SocialAuth';
import React from 'react';
// import { useTranslation } from 'react-i18next';
import appstorelogo from 'Assets/img/appstorelogo.png';
import googleplay from 'Assets/img/googleplaybtn.png';
import { paths } from 'Utils/constants';
import { AuthLink, LinkDescription } from '../components';
import {
  AppStoreBtn,
  AppstoreBtnContainer,
  GooglePlayBtn,
  OnboardingPageContainer,
  SpoxioBlueBtn,
  Title
} from './component';
import { AppLink } from 'Routes/MobileDetectRoute/components';
import { AuthLayout } from 'Components/Layouts/AuthLayout';

const OnboardingPage = (props) => {
  //   const { t } = useTranslation();
  return (
    <AuthLayout>
      <OnboardingPageContainer>
        <Title fontSize="2.2rem">Start using Spoxio</Title>
        <AuthLink to={paths.SIGN_UP} width={'100%'}>
          {' '}
          <SpoxioBlueBtn padding="1.15rem 2rem" width="100%" fontSize="1rem">
            Start with your email
          </SpoxioBlueBtn>
        </AuthLink>

        <SocialAuth title={'Or continue with'} />

        <AuthLink to={paths.SIGN_IN} color="#A5D1E3" style={{ marginTop: '30px' }}>
          <LinkDescription>I already have an account</LinkDescription>
        </AuthLink>

        <AppstoreBtnContainer>
          <AppLink href="https://apps.apple.com/ng/app/spoxio/id1525715823">
            <AppStoreBtn src={appstorelogo} alt="App Store Link" />
          </AppLink>
          <AppLink href="https://play.google.com/store/apps/details?id=com.spoxio.app">
            <GooglePlayBtn src={googleplay} alt="Play Market Link" />
          </AppLink>
        </AppstoreBtnContainer>
      </OnboardingPageContainer>
    </AuthLayout>
  );
};

OnboardingPage.propTypes = {};

export default OnboardingPage;
