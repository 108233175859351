import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, node, string } from 'prop-types';

import { getFollowingData } from 'Store/profileSlice';
// import { NavBar } from '../MainNavigation/NavBar';
// import { LeftBar } from '../MainNavigation/LeftBar';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { CenterContent } from 'Components/shared/styledComponents';
import { Profile } from 'Components/Profile';
import { getProfileById } from 'Store/profileSlice';

// import { RightBarContainer, RightContainer } from 'Components/OnboardNavigation/components';
import { LeftBar } from '../MainNavigation/LeftBar';
import { NavBar } from '../MainNavigation/NavBar';
import { CenterContainer, InnerCenterContainer, LeftContainer, MainContainer, RightContainer } from '../MainNavigation/components';
import { RightBarContainer } from '../MainNavigation/RightBar/components';
// import NavBarUser from 'Components/OtherProfile/NavBarUser';
// import Details from 'Components/OtherProfile/Details';

const ProfileRight = ({ children, showSuggestion, showSports, showNav, navTitle, showFooter }) => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfileSelector);
  // eslint-disable-next-line no-restricted-globals
  const path = location.pathname.split('/');
  const profileId = path[2];
  const tabName = path[3];

  useEffect(() => {
    dispatch(getProfileById(profileId));
    dispatch(getFollowingData());
  }, [profileId]);
  return (
    <MainContainer>
      <LeftContainer>
        <LeftBar showSuggestion={showSuggestion} showSports={showSports} showFooter={showFooter} />
      </LeftContainer>
      <CenterContainer>
        {showNav && <NavBar title={navTitle} />}
        <InnerCenterContainer>
          <CenterContent>
            <Profile profile={profile} tabName={tabName} />
          </CenterContent>
        </InnerCenterContainer>
      </CenterContainer>
      <RightContainer>
        <RightBarContainer border="transparent">{children}</RightBarContainer>
      </RightContainer>
    </MainContainer>
  );
};

ProfileRight.propTypes = {
  children: node.isRequired,
  showSports: bool,
  showSuggestion: bool,
  showNav: bool,
  navTitle: string,
  showFooter: bool
};

ProfileRight.defaultProps = {
  showSports: true,
  showSuggestion: true,
  showNav: true,
  navTitle: '',
  showFooter: true
};

export default ProfileRight;
