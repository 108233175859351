import React, { Fragment, useContext, useEffect } from 'react';
import { MansorySubtitle, PostTitle, SportThumbnail, TrendTitle } from './component';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUserInterestTrending } from 'Store/discoverySlice';
import { DiscoveryContainer, PopularSportContainer } from 'Components/Discovery/components';
// import { Results } from 'Components/Results';
import { getInterestTrendingSelector } from 'Store/selectors/discoverySelector';
import { v4 as uuidv4 } from 'uuid';
import { truncate } from 'Utils/truncate';
import { PostMediaThumbnail } from 'Components/shared/Post/PostMediaThumbnail';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { getIsLoading } from 'Store/loaderSlice';
// import { Loader } from 'Components/shared/Loader';
import { Title } from 'Components/Layouts/AuthLayout/components';
import { fetchSinglePost } from 'Store/postSlice';
import { push } from 'connected-react-router';
import { paths } from 'Utils/constants';
import { ThemeContext } from 'Context/themeContext';
import { ContentContainer, SubTitle } from 'Components/shared/styledComponents';
import isEmpty from 'Utils/isEmpty';
import { Loader } from 'Components/shared/Loader';

const SportTrending = () => {
  const { theme } = useContext(ThemeContext);
  const isLoading = useSelector(getIsLoading);
  const interestTrending = useSelector(getInterestTrendingSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getUserInterestTrending());
  }, []);

  const selectPost = (data) => {
    dispatch(fetchSinglePost(data._id));
    dispatch(push(`${paths.TIMELINE}/${data._id}`, { id: data._id }));
  };

  return (
    <DiscoveryContainer>
      {isLoading ? (
        <ContentContainer height="100%" width="100%">
          <Loader size={24} background="transparent" />
        </ContentContainer>
      ) : null}

      {!isLoading && interestTrending < 1 ? (
        <EmptyContent
          maxWidth="auto"
          description={
            <Title fontSize="1rem">
              {t('Follow a sport or topic to see interesting contents tailored for you...')}
            </Title>
          }
        />
      ) : null}

      {!isEmpty(interestTrending)
        ? interestTrending?.map((interest) => {
            if (interest.posts.contents < 1) {
              return <></>;
            }
            return (
              <Fragment key={uuidv4()}>
                <TrendTitle fontWeight="bold">Trending in {interest.interest}</TrendTitle>
                <PopularSportContainer>
                  {isEmpty(interest.posts) ? (
                    <ContentContainer
                      minHeight="10vh"
                      width="100%"
                      // justifyContent="center"
                      // alignItems="center"
                    >
                      <SubTitle
                        fontSize="0.875rem"
                        color={theme?.dark}
                        opacity="1"
                        textAlign="center"
                        width="100%">
                        Nothing to see here...
                      </SubTitle>
                    </ContentContainer>
                  ) : (
                    interest.posts.map((post) => {
                      return (
                        <SportThumbnail key={uuidv4()} onClick={() => selectPost(post)}>
                          <PostMediaThumbnail mediaList={post.contents} />
                          <PostTitle>{truncate(post.text, 30)}</PostTitle>
                          <MansorySubtitle>
                            {post.viewCount} {post.viewCount > 1 ? 'views' : 'view'}
                          </MansorySubtitle>
                        </SportThumbnail>
                      );
                    })
                  )}
                </PopularSportContainer>
              </Fragment>
            );
          })
        : null}
    </DiscoveryContainer>
  );
};

export default SportTrending;
