import styled from 'styled-components';

import { BaseButton, BaseText, BaseTitle, BlackText } from '../shared/styledComponents';
import { FormCheckbox } from '../shared/Inputs/FormCheckbox';

import { ReactComponent as ArrowLeft } from 'Assets/icons/ArrowLeft.svg';
import { ReactComponent as GroupMessage } from 'Assets/icons/GroupMessage.svg';

export const StatsContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
  max-width: 700px;
  padding-bottom: ${({ showMessage }) => (!showMessage ? 40 : 0)}px;
  border: 1px solid ${({ theme }) => theme.lightGray2};
  border-radius: 20px;
`;

export const Sort = styled(BaseText)`
  padding: 10px 10px 2px 10px;
  width: 50px;
  flex-basis: 50px;
  flex-grow: 1;
  border-bottom: ${({ theme, selected }) =>
    selected ? `4px solid ${theme.lightPrimaryBlue}` : 'none'};
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.large}px;
  text-align: center;
  cursor: pointer;
`;

export const SortList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 77%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const StatsHeadContainer = styled.div`
  position: relative;
  padding: 40px 20px 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.lightGray2};
  margin-bottom: 30px;

  @media (max-width: 930px) {
    padding: 40px 10px 0 10px;
  }
`;

export const GroupMessageIcon = styled(GroupMessage)`
  position: relative;
  top: 6px;
  width: 30px;
  height: 23px;
`;

export const RightBar = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
`;

export const BulkButton = styled(BaseText)`
  color: ${({ theme }) => theme.spoxioAltBlue};
  font-size: ${({ theme }) => theme.large}px;
  cursor: pointer;
`;

export const NextBtn = styled(BaseButton)`
  padding: 7px 30px;
  font-size: ${({ theme }) => theme.large}px;
`;

export const Cancel = styled(BaseButton)`
  padding: 7px;
  margin-right: 10px;
  font-size: ${({ theme }) => theme.large}px;
`;

export const BtnContainer = styled.div`
  display: flex;
`;

export const StatsItemsWrapper = styled.div`
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
`;

export const StatsItemContainer = styled.div`
  position: relative;
  display: flex;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.lightGray2};
`;

export const Statistic = styled.div`
  display: flex;
  margin-top: 5px;
  width: 100%;
  justify-content: space-between;
`;

export const UserName = styled(BlackText)`
  font-size: ${({ theme }) => theme.medium}px;
  cursor: pointer;
  text-transform: capitalize;
`;

export const UserData = styled.div`
  padding: 5px;
  /* width: 200px; */
  width: 100%;
  max-width: 25vw;
`;

export const Stat = styled(BlackText)`
  font-size: ${({ theme }) => theme.small}px;

  & span {
    color: ${({ theme }) => theme.opacityGray};
    font-size: ${({ theme }) => theme.small}px;
    font-family: SF Pro;
    text-transform: capitalize;
  }
`;

export const Checkbox = styled(FormCheckbox)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const MessagesContainer = styled.div`
  padding: 25px 15px 20px 20px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  display: flex;
  margin-top: auto;
`;

export const MessageHead = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(BaseTitle)`
  margin: 0;
  font-size: ${({ theme }) => theme.large}px;
`;

export const BackIcon = styled(ArrowLeft)`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;

  & path {
    fill: ${({ theme }) => theme.opacityGray};
  }
`;

export const UsersContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 200px;
  margin: 20px 0;
`;

export const UserIcon = styled.div`
  max-width: 25px;
  height: 30px;
`;

export const MessageIcon = styled.div`
  margin-right: 10px;
`;

export const Info = styled(BaseText)`
  margin: auto;
  text-align: center;
  color: ${({ theme }) => theme.opacityGray};
`;
