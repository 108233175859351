import { useState, useEffect, useContext } from 'react';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { PostCommentProp } from 'Utils/propTypes';
import { getNumberWithText } from 'Utils/strings';
import { getCurrentUserId } from 'Store/authSlice';
import { PostSubInfoText, PostUserName } from '../Post/components';
import { ClickableContainer } from 'Components/shared/styledComponents';
import {
  CommentBody,
  UserImg,
  CommentText,
  StarIcon,
  CommentContainer,
  CommentAction,
  CommentActionText,
  ViewReplyContainer
} from './components';
import { UserPic } from 'Components/shared/UserPic';

import { ReactComponent as Star } from 'Assets/icons/AuthFanRole.svg';
import { ReactComponent as RedStar } from 'Assets/icons/RedStar.svg';
import { toggleLikePostOrComment } from 'Store/postSlice';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';

export const Comment = ({ comment, onReply, onLike, onReport, onViewReply }) => {
  const [isLiked, setIsLiked] = useState(false);
  const { t } = useTranslation();
  const currentUserId = useSelector(getCurrentUserId);
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLiked(comment?.isLikedByMe);
  }, [comment?.isLikedByMe, currentUserId]);

  const onReplyClick = () => {
    onReply(comment._id);
  };

  const onLikeClick = () => {
    onLike(comment);
    dispatch(toggleLikePostOrComment(comment._id, 'comment', isLiked ? 'unlike' : 'like'));
    setIsLiked(!isLiked);
  };

  return (
    <CommentContainer>
      <CommentBody>
        <UserImg>
          <UserPic src={comment?.commenter?.picture} size={32} />
        </UserImg>
        <CommentText>
          <PostUserName fontWeight="bold">{comment?.commenter?.name}</PostUserName>
          {comment?.content}
        </CommentText>
        <StarIcon onClick={onLikeClick}>
          {isLiked ? (
            <RedStar title={t('unlike_comment')} width={13} height={13} />
          ) : (
            <Star title={t('like_comment')} width={13} height={13} color={theme.spoxioIcon} />
          )}
        </StarIcon>
      </CommentBody>
      <CommentAction>
        <CommentActionText>{getNumberWithText(comment?.likeCount, 'star')}</CommentActionText>
        <ClickableContainer onClick={onReplyClick}>
          <CommentActionText>{t('reply')}</CommentActionText>
        </ClickableContainer>
        {onReport ? (
          <ClickableContainer onClick={onReport}>
            <CommentActionText>{t('report')}</CommentActionText>
          </ClickableContainer>
        ) : null}
      </CommentAction>
      {comment.repliesCount > 0 && (
        <ViewReplyContainer>
          <PostSubInfoText onClick={onViewReply}>
            {t('view_replies')} ({comment.repliesCount})
          </PostSubInfoText>
        </ViewReplyContainer>
      )}
    </CommentContainer>
  );
};

Comment.propTypes = {
  comment: PostCommentProp.isRequired,
  onReply: func,
  onLike: func,
  onReport: func,
  onViewReply: func
};

Comment.defaultProps = {
  onReply: () => {},
  onLike: () => {},
  onReport: null
};
