import { isScout } from 'Utils';
import { ChoseSports } from 'Components/ChoseSports';
import { PostsList } from 'Components/PostsList';
import { ChangePassword } from './ChangePassword';
import { FollowSportsContainer } from '../ProfileRevamp/components';

export const getProfileTabs = (data, posts, onChoseSport) => {
  const tabs = [
    {
      id: 'sports',
      label: 'Sports I Follow',
      component: (
        <FollowSportsContainer>
          <ChoseSports selectedSports={data?.sports} onSelect={onChoseSport} />
        </FollowSportsContainer>
      )
    },
    {
      id: 'change_password',
      label: 'Change Password',
      component: <ChangePassword />
    }
  ];

  if (!isScout(data?.userType)) {
    tabs.unshift({
      id: 'posts',
      label: `Posts (${posts?.length})`,
      component: <PostsList postList={posts} className="margin-top_small-v2" showStories={false} />
    });
  }

  return tabs;
};
