import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, node, string } from 'prop-types';

import { getFollowingData } from 'Store/profileSlice';
import { NavBar } from './NavBar';
import { LeftBar } from './LeftBar';
import { RightBar } from './RightBar';
import {
  LeftContainer,
  CenterContainer,
  // RightContainer,
  MainContainer,
  InnerCenterContainer,
  RightContainer
} from './components';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import isEmpty from 'Utils/isEmpty';
import { truncate } from 'Utils/truncate';
import { SubTitle, Title } from 'Components/shared/styledComponents';

export const ProfileLayout = ({ children, showNav, navTitle }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFollowingData());
  }, []);

  const profile = useSelector(getProfileSelector);
  const { data } = profile;

  return (
    <MainContainer>
      <LeftContainer>
        <LeftBar />
      </LeftContainer>
      <CenterContainer>
        {showNav && (
          <NavBar
            title={
              navTitle || (
                <SubTitle fontWeight="bold" fontSize="1rem">
                  {!isEmpty(data) ? truncate(`${data?.firstName} ${data?.lastName}`, 18) : ''}
                </SubTitle>
              )
            }
          />
        )}
        <InnerCenterContainer>{children}</InnerCenterContainer>
      </CenterContainer>
      <RightContainer>
        <RightBar />
      </RightContainer>
    </MainContainer>
  );
};

ProfileLayout.propTypes = {
  children: node.isRequired,
  navTitle: string,
  showNav: bool
};

ProfileLayout.defaultProps = {
  showNav: true
};
