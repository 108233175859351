import styled from 'styled-components';
import { BaseText, BlackText } from '../shared/styledComponents';

export const SportList = styled.div`
  display: flex;
  flex-wrap: ${({ flexWrap }) => (flexWrap === 'nowrap' ? 'nowrap' : 'wrap')};
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-bottom: 30px;
  min-height: 6vh;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SportItemContainer = styled.div`
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 120px;
  /* width: 80px; */
  width: 25%;
`;

export const SportItemButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 5px 15px; */
  height: 65px;
  width: 65px;
  margin: 0 7px;
  background-color: ${({ theme }) => theme.spoxioIcon2};
  border-radius: 50%;
  cursor: pointer;

  ${({ theme, isSelected }) =>
    isSelected
      ? {
          // backgroundColor: theme.orange,
          // backgroundColor: theme.spoxioAltBlue,
          borderColor: theme.spoxioAltBlue,
          borderWidth: 1,
          borderStyle: 'solid',
          paddingBottom: 6,
          paddingTop: 6,
          paddingRight: 0,
          border: `1px solid ${theme.spoxioLinear2}`
        }
      : {}};
`;

export const SportName = styled(BaseText)`
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  height: 35px;
  color: ${({ theme, isSelected }) => (isSelected ? theme.lightestGray : theme.black)};
`;

export const CancelButtonContainer = styled.div`
  margin: 0 10px;
  margin-top: -2px;
`;

export const ChoseSportsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: calc(100% - 10vw); */
`;

export const SportsGroupTitle = styled(BlackText)`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.gray};
`;

// const SportIcons = [
//   {
//       event: 'Football',
//       icon: CreateFootballVector,
//       background: 'linear-gradient(144.52deg, #2B1C12 22.1%, #4D280F 92.24%)'
//   },
//   {
//       event: 'American Soccer',
//       icon: CreateASVector,
//       background: 'linear-gradient(140.32deg, #12202B 28.88%, #1D3761 97.69%)'
//   },
//   {
//       event: 'Basketball',
//       icon: CreateBasketballVector,
//       background: 'linear-gradient(141.26deg, #2B2412 26.28%, #423D17 96.6%)'
//   },
//   {
//       event: 'Golf',
//       icon: CreateGolfVector,
//       background: 'linear-gradient(140.32deg, #122B22 28.88%, #1D614D 97.69%)'
//   },
//   {
//       event: 'Badminton',
//       icon: CreateBadmintonVector,
//       background: 'linear-gradient(140.32deg, #12202B 28.88%, #1D3761 97.69%)'
//   }
// ]

export const ChooseTopicContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  justify-content: start;
  align-items: start;
  margin: 10px 0;
`;

export const TagsContainer = styled.div`
  background: ${({ theme }) => theme.tagsContainer};
  width: 100%;
  border-radius: 10px;
  color: ${({ theme }) => theme.white};
  padding: 5px;
`;

export const TagDetailsWrapper = styled.div`
  background: ${({ theme }) => theme.spoxioCardBg};
  width: 'auto';
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 12px;
  height: 45px;
  color: ${({ theme }) => theme.gray};
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ borderColor }) => (borderColor ? borderColor : 'transparent')};

  ${({ theme, isSelected }) =>
    isSelected
      ? {
          // backgroundColor: theme.orange,
          // backgroundColor: theme.spoxioAltBlue,
          borderColor: theme.spoxioAltBlue,
          borderWidth: 1,
          borderStyle: 'solid',
          paddingBottom: 6,
          paddingTop: 6,
          whiteSpace: 'pre-line',
          paddingRight: 0,
          border: `1px solid ${theme.spoxioLinear2}`
        }
      : {}};
`;
export const TagDetailsInput = styled.input``;
