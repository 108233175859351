import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { getAllTopics } from 'Store/discoverySlice';
import { LeftBarContainer, ListItem, StoreContainer, StoreImage } from './components';
import { BarTitle } from 'Components/shared/styledComponents';
import { CategoryList } from './CategoryList';
import { Navigation } from './Navigation';

import AppleStoreIcon from 'Assets/img/AppleStoreIcon.jpg';
import GooglePlayIcon from 'Assets/img/GooglePlayIcon.jpg';
import { getTrendingTopicsSelector, setSearch } from 'Store/selectors/discoverySelector';
import { useTranslation } from 'react-i18next';

export const LeftBar = ({ showSuggestion, showSports }) => {
  const trendingTopics = useSelector(getTrendingTopicsSelector);
  const query = useSelector((state) => state?.router.location.query);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!trendingTopics?.length || query?.topic) {
      dispatch(getAllTopics(query?.topic));
    }
  }, [query?.topic]);

  const onTopicClick = (topic) => {
    const search = { type: 'topic', data: topic };

    dispatch(setSearch(search));
    dispatch(push(`${paths.DISCOVERY}?topic=${topic.topic}`));
  };

  return (
    <LeftBarContainer>
      {showSuggestion ? (
        <>
          {trendingTopics?.length ? (
            <>
              <BarTitle>{t('trending')}</BarTitle>
              {trendingTopics.map((topic, index) => (
                <ListItem key={`${topic.topic}-${index}`} onClick={() => onTopicClick(topic)}>
                  #{topic.topic}
                </ListItem>
              ))}
            </>
          ) : null}
          {showSports ? (
            <>
              <BarTitle>{t('sports')}</BarTitle>
              <CategoryList />
            </>
          ) : null}
        </>
      ) : null}
      <StoreContainer>
        <a
          href="https://apps.apple.com/ng/app/spoxio/id1525715823"
          target="_blank"
          rel="noreferrer"
        >
          <StoreImage src={AppleStoreIcon} alt={t('download_on_appstore')} />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.spoxio.app"
          target="_blank"
          rel="noreferrer"
        >
          <StoreImage src={GooglePlayIcon} alt={t('download_on_playstore')} />
        </a>
      </StoreContainer>
      <Navigation />
    </LeftBarContainer>
  );
};

LeftBar.propTypes = {
  showSuggestion: bool,
  showSports: bool
};

LeftBar.defaultProps = {
  showSuggestion: true,
  showSports: true
};
