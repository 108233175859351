import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RouteProps } from 'Utils/propTypes';
import { paths } from 'Utils/constants';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { getIsLoading } from 'Store/loaderSlice';
import { CenterContent } from 'Components/shared/styledComponents';
import { Tabs } from 'Components/shared/Tabs';
import { Loader } from 'Components/shared/Loader';
import { getProfileById } from 'Store/profileSlice';
import { useTranslation } from 'react-i18next';
import { ProfileLayout } from 'Components/Layouts/ProfileLayout';
import { FollowList } from 'Components/ProfileRevamp/FollowList';
import { getCurrentUserId } from 'Store/selectors/authSelector';

export const FollowPage = ({ history, location }) => {
  const { data, followers, following } = useSelector(getProfileSelector);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const path = location?.pathname.split('/');
  const userId = useSelector(getCurrentUserId);
  const profileId = path[2];
  const tabName = path[3];

  useEffect(() => {
    dispatch(getProfileById(profileId));
  }, [profileId]);

  const onBack = () => history.push(`${paths.PROFILE}/${data._id}`);

  const onTabClick = (tabId) => {
    history.push(`${paths.PROFILE}/${data._id}/${tabId}`);
  };
  return (
    <ProfileLayout>
      <CenterContent maxWidth={600} m="0 auto">
        {isLoading ? (
          <Loader size={24} />
        ) : (
          <>
            {/* <Head>
              <HeadUserName>{data?.firstName}</HeadUserName>
              <BackButton label={t('back')} onClick={onBack} />
            </Head> */}
            <Tabs
              defaultTab={tabName}
              handleChangeTab={onTabClick}
              tabs={
                userId !== profileId
                  ? [
                      {
                        id: 'followers',
                        label: `${t('followers')} (${followers?.length || 0})`,
                        component: <FollowList userList={followers} />
                      },
                      {
                        id: 'following',
                        label: `${t('following')} (${following?.length || 0})`,
                        component: <FollowList userList={following} />
                      }
                      // {
                      //   id: 'mutual',
                      //   label: `${t('Mutual Followers')} (${followers?.length || 0})`,
                      //   component: <FollowList userList={followers} />
                      // }
                    ]
                  : [
                      {
                        id: 'followers',
                        label: `${t('followers')} (${followers?.length || 0})`,
                        component: <FollowList userList={followers} />
                      },
                      {
                        id: 'following',
                        label: `${t('following')} (${following?.length || 0})`,
                        component: <FollowList userList={following} />
                      }
                    ]
              }
            />
          </>
        )}
      </CenterContent>
    </ProfileLayout>
  );
};

FollowPage.propTypes = RouteProps;
