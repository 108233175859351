import React from 'react';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { shape } from 'prop-types';
import { formatDate, getAgeDifference } from 'Utils/dates';
import { PlayerInfoTagContainer } from './components';
import { truncate } from 'Utils/truncate';
import isEmpty from 'Utils/isEmpty';

const PlayerInfo = ({ about }) => {
  // console.log(about, 'player info');
  const { talentProfile } = about;
  const date = getAgeDifference(talentProfile?.dateOfBirth);
  return (
    <>
      <ContentContainer column margin="20px 0" gap="20px">
        <ContentContainer row justifyContent="space-between" flexWrap="wrap" gap="10px">
          <PlayerInfoTagContainer>
            <SubTitle fontSize="1rem" opacity="0.5">
              AGE
            </SubTitle>
            <Title fontSize="0.875rem" fontWeight="bold">
              {about?.clubMemberProfile?.age || date}
            </Title>
          </PlayerInfoTagContainer>
          <PlayerInfoTagContainer>
            <SubTitle fontSize="1rem" opacity="0.5">
              DATE SIGNED
            </SubTitle>
            <Title fontSize="0.875rem" fontWeight="bold">
              {formatDate(about?.clubMemberProfile?.dateSigned, 'DD MMM, YYYY') || '-'}
            </Title>
          </PlayerInfoTagContainer>
          <PlayerInfoTagContainer>
            <SubTitle fontSize="1rem" opacity="0.5">
              COUNTRY
            </SubTitle>
            <Title fontSize="0.875rem" fontWeight="bold">
              {truncate(about?.country?.name || '-', 11)}
            </Title>
          </PlayerInfoTagContainer>
        </ContentContainer>
        <ContentContainer row justifyContent="space-between" flexWrap="wrap" gap="10px">
          <PlayerInfoTagContainer>
            <SubTitle fontSize="1rem" opacity="0.5">
              POSITION
            </SubTitle>
            <Title fontSize="0.875rem" fontWeight="bold" textTransform="capitalize">
              {about?.clubMemberProfile?.role?.name || '-'}
            </Title>
          </PlayerInfoTagContainer>
          <PlayerInfoTagContainer>
            <SubTitle fontSize="1rem" opacity="0.5">
              HEIGHT
            </SubTitle>
            <Title fontSize="0.875rem" fontWeight="bold">
              {!isEmpty(about?.clubMemberProfile?.height)
                ? about?.clubMemberProfile?.height + ' cm'
                : talentProfile?.height + ' cm' || '-'}
            </Title>
          </PlayerInfoTagContainer>
          <PlayerInfoTagContainer>
            <SubTitle fontSize="1rem" opacity="0.5">
              WEIGHT
            </SubTitle>
            <Title fontSize="0.875rem" fontWeight="bold">
              {!isEmpty(about?.clubMemberProfile?.weight)
                ? about?.clubMemberProfile?.weight + ' kg'
                : talentProfile?.weight + ' kg' || '-'}
            </Title>
          </PlayerInfoTagContainer>
        </ContentContainer>
        <ContentContainer row justifyContent="space-between" flexWrap="wrap" gap="10px">
          {!isEmpty(about?.clubMemberProfile) ? (
            <PlayerInfoTagContainer>
              <SubTitle fontSize="1rem" opacity="0.5">
                MEDALS
              </SubTitle>
              <Title fontSize="0.875rem" fontWeight="bold">
                {about?.clubMemberProfile?.medals || '-'}
              </Title>
            </PlayerInfoTagContainer>
          ) : null}
        </ContentContainer>
      </ContentContainer>

      {/* Player Info includes a text about player */}
      <ContentContainer column gap="10px">
        <SubTitle fontWeight="600">Bio</SubTitle>
        {/* Players about answer */}
        <Title fontSize="0.875rem" lineHeight="normal">
          {about?.clubMemberProfile?.bio || talentProfile?.bio}
        </Title>
      </ContentContainer>
    </>
  );
};
PlayerInfo.propTypes = {
  about: shape({})
};

export default PlayerInfo;
