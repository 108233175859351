import { elementType, number, string } from 'prop-types';
import { useState } from 'react';

import { trimText } from 'Utils/strings';
import { ReadMoreText } from 'Components/shared/styledComponents';
import { useTranslation } from 'react-i18next';

export const ReadMore = ({ text, TextComponent, countVisibleCharacters }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  return text?.length > countVisibleCharacters ? (
    <TextComponent>
      {isExpanded ? text : trimText(text, countVisibleCharacters)}
      <ReadMoreText onClick={toggleReadMore}>
        {isExpanded ? ` ${t('read_less')}` : ` ${t('read_more')}`}
      </ReadMoreText>
    </TextComponent>
  ) : (
    <TextComponent>{text}</TextComponent>
  );
};

ReadMore.propTypes = {
  text: string.isRequired,
  TextComponent: elementType.isRequired,
  countVisibleCharacters: number
};

ReadMore.defaultProps = {
  countVisibleCharacters: 150
};
