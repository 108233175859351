import { any, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { getAddressForPost } from 'Utils/strings';
import { getDateDifference } from 'Utils/dates';
import { UserPic } from 'Components/shared/UserPic';
import {
  ClickableContainer,
  ContentContainer,
  RowContainer
} from 'Components/shared/styledComponents';
import {
  PostHeaderContainer,
  PostHeaderLeft,
  PostUserData,
  PostUserName,
  PostTime,
  PostSubInfoContainer,
  PostSubInfoText
} from './components';

import { ReactComponent as FootballIcon } from 'Assets/icons/Football.svg';
import { ReactComponent as LocationIcon } from 'Assets/icons/Location.svg';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';

export const PostHeader = ({ author, data, flexWrap }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const goToAuthorPage = (e) => {
    e.stopPropagation();
    dispatch(push(`${paths.PROFILE}/${data?.author?._id}`));
  };

  return (
    <PostHeaderContainer flexWrap={flexWrap}>
      <PostHeaderLeft>
        <ClickableContainer onClick={goToAuthorPage}>
          <UserPic src={data?.author?.picture} size={48} />
        </ClickableContainer>
        <PostUserData>
          <ClickableContainer onClick={goToAuthorPage}>
            <PostUserName fontWeight="bold">{data?.author?.firstName}</PostUserName>
          </ClickableContainer>
          <PostSubInfoContainer fill={theme.mediumGray}>
            {data?.sport ? (
              <ContentContainer row gap="4px" alignItems="center">
                <FootballIcon width={15} height={15} color={theme.darkGray} />
                <PostSubInfoText>{data.sport?.name}</PostSubInfoText>
              </ContentContainer>
            ) : null}
            {data?.address ? (
              <ContentContainer row gap="4px" width="100%" alignItems="center">
                <LocationIcon width={15} height={15} color={theme.mediumGray} />
                <PostSubInfoText color={theme.spoxioText}>
                  {getAddressForPost(data.address)}
                </PostSubInfoText>
              </ContentContainer>
            ) : null}
          </PostSubInfoContainer>
        </PostUserData>
      </PostHeaderLeft>
      <PostTime>{getDateDifference(data?.createdAt)}</PostTime>
    </PostHeaderContainer>
  );
};

PostHeader.propTypes = {
  author: shape({
    id: string,
    name: string,
    picture: string
  }),
  data: shape({
    sport: shape({
      _id: string,
      name: string
    }),
    address: string,
    createdAt: any
  }),
  flexWrap: string
};

PostHeader.defaultProps = {
  author: null,
  data: null,
  flexWrap: 'nowrap'
};
