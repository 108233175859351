import styled from 'styled-components';

import { BaseTitle } from 'Components/shared/styledComponents';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 40px;
  position: relative;

  & span {
    display: flex;
    justify-content: center;
  }
`;

export const PostsCount = styled.span`
  margin: 10px 0;
  font-size: ${({ theme }) => theme.small}px;
  text-transform: lowercase;
  color: ${({ theme }) => theme.opacityGray};
`;

export const SearchName = styled(BaseTitle)`
  font-size: ${({ theme }) => theme.xLarge}px;
`;

export const ResultTitle = styled(BaseTitle)`
  width: 60%;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.xLarge2}px;
`;
