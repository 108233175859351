import { useState } from 'react';
import { any, bool, func, string } from 'prop-types';

import { compressFile, isVideo } from 'Utils';
import { VideoTrimmer } from 'Components/VideoTrimmer';
import { InputContainer, FileInput, Info, ImageIcon } from './components';
import { ModalWindow } from 'Components/shared/Modal';
import { useTranslation } from 'react-i18next';
import isEmpty from 'Utils/isEmpty';
import { Loader } from 'Components/shared/Loader';

export const DragDropFileInput = ({
  onChange,
  customIcon,
  acceptedFormat,
  border,
  isDocument,
  disabled
}) => {
  const [videoFile, setVideoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation();

  const selectFile = async (e) => {
    const reader = new FileReader();
    setUploading(true);

    if (!isDocument) {
      const file = isVideo(e.target?.files[0] && e.target?.files[0]?.type)
        ? e.target.files[0]
        : await compressFile(e.target?.files[0]);

      reader.readAsDataURL(file);
      reader.onload = () => {
        if (isVideo(e.target?.files[0] && e.target?.files[0]?.type)) {
          setVideoFile({ base64: reader.result, blob: file });
          return;
        }

        onChange(reader.result, file);
      };
    } else {
      const file = e.target?.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result.replace('data:', '').replace(/^.+,/, '');
        // console.log(result, 'result file read');
        onChange(result, file);
      };
    }
    setUploading(false);
  };

  const closeTrimVideo = () => {
    setVideoFile(null);
  };

  const handleTrimVideo = (result, file) => {
    onChange(result, file);
    closeTrimVideo();
  };

  return (
    <>
      <ModalWindow isOpen={!!videoFile} title={t('trim_video')} closeModal={closeTrimVideo}>
        <VideoTrimmer file={videoFile} handleTrimVideo={handleTrimVideo} />
      </ModalWindow>
      <InputContainer border={border} loading={uploading}>
        {customIcon ? (
          customIcon
        ) : (
          <Info>
            <ImageIcon />
            {t('drag_click_to_upload')}
          </Info>
        )}
        {!uploading ? (
          <>
            {isEmpty(acceptedFormat) ? (
              <FileInput
                disabled={uploading || disabled}
                onChange={selectFile}
                onClick={(e) => {
                  e.target.value = null;
                }}
                type="file"
              />
            ) : (
              <FileInput
                disabled={uploading || disabled}
                onChange={selectFile}
                onClick={(e) => {
                  e.target.value = null;
                }}
                type="file"
                accept={acceptedFormat}
              />
            )}
          </>
        ) : (
          <Loader background="transparent" size={20} height="100px" />
        )}
      </InputContainer>
    </>
  );
};

DragDropFileInput.propTypes = {
  onChange: func.isRequired,
  acceptedFormat: string,
  customIcon: any,
  isDocument: bool,
  border: string,
  disabled: bool,
  setFileLoading: bool
};
