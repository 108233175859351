export const requiredRule = {
  required: 'This field is required'
};

export const emailRules = {
  ...requiredRule,
  pattern: {
    // eslint-disable-next-line no-useless-escape
    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    message: 'Email is not valid'
  }
};

export const passwordRules = {
  ...requiredRule,
  pattern: {
    value: /^(?=.*[a-z]).{4,}$/,
    message: 'Password is not valid'
  }
};
//    value: /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{4,}$/,
