import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { DetailsPage } from 'Components/OtherProfile/components';
import { RightBarContainer } from './components';
import Details from 'Components/OtherProfile/Player/Details';
import { getFollowingData } from 'Store/profileSlice';
import NavBarProfile from 'Components/OtherProfile/NavBarProfile';
import { ThemeContext } from 'Context/themeContext';

export const RightBar = () => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFollowingData());
  }, []);
  const profile = useSelector(getProfileSelector);
  let { data, isCurrentUser } = profile;
  const { userType } = data;

  return (
    <RightBarContainer borderRadius="0" padding="0" border="transparent" background="transparent">
      <DetailsPage padding="0" border="transparent" borderRadius="0px" mt={'0'}>
        <NavBarProfile isCurrentUser={isCurrentUser} data={data} />
        <Details role={userType} profileData={profile} />
      </DetailsPage>
    </RightBarContainer>
  );
};
