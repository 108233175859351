import { useState, forwardRef } from 'react';
import { any, number, object, string } from 'prop-types';
import firebase from 'firebase/app';

import { compressFile } from 'Utils';
import { UserPic } from 'Components/shared/UserPic';
import { CenteredRowContainer, ContentContainer } from 'Components/shared/styledComponents';
import { ErrorText } from 'Components/shared/Inputs/components';
import { HelperText, ImageInput, HiddenInput, Container, EditIconContainer } from './components';
import { getCurrentUser } from 'Store/selectors/authSelector';
import { ReactComponent as EditIcon } from 'Assets/icons/EditPen.svg';
import { useSelector } from 'react-redux';
import isEmpty from 'Utils/isEmpty';

export const FormImagePicker = forwardRef(
  ({ name, error, url, compressSize, className, helperText, style, customIcon }, ref) => {
    const [value, setValue] = useState('');
    const [image, setImage] = useState('');
    const user = useSelector(getCurrentUser);

    const selectImage = async (e) => {
      const reader = new FileReader();
      const file = await compressFile(e.target.files[0], compressSize);

      const filename = `${user.email}.jpg`;
      const storageRef = firebase.storage().ref(`users/${filename}`);
      await storageRef.put(file);
      const dataUrl = await storageRef.getDownloadURL();

      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
        setValue(dataUrl);
      };
    };

    return (
      <ContentContainer column className={className} style={style}>
        <ContentContainer column width="100px">
          <HiddenInput ref={ref} name={name} className={className} value={value || url} />
          <ImageInput onChange={selectImage} />
          <UserPic src={image || url} size={100} />
          {customIcon ? (
            customIcon
          ) : (
            <EditIconContainer>
              <EditIcon />
            </EditIconContainer>
          )}
        </ContentContainer>
        {helperText ? <HelperText>{helperText}</HelperText> : null}
        <ErrorText isShow={!!error}>{error}</ErrorText>
      </ContentContainer>
    );
  }
);

FormImagePicker.propTypes = {
  name: string.isRequired,
  control: any,
  url: string,
  compressSize: number,
  helperText: string,
  error: string,
  className: string,
  style: object,
  customIcon: any
};

FormImagePicker.defaultProps = {
  control: {},
  url: '',
  compressSize: null,
  helperText: '',
  error: '',
  className: ''
};
