import styled from 'styled-components';
import { number } from 'prop-types';

import LoaderGif from 'Assets/img/Loader.gif';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || '10px 0'};
  width: 100%;
  height: ${({ height }) => height || '100%'};
  position: ${({ position }) => position || 'absolute'};
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${({ theme, background }) =>
    background || theme.background}; //rgba(255, 255, 255, 0.5);
`;

export const LoaderImage = styled.img.attrs(() => ({
  src: LoaderGif
}))`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

LoaderImage.propTypes = {
  size: number
};

LoaderImage.defaultProps = {
  size: 30
};
