import styled from 'styled-components';

export const EventDetailsContainer = styled.div`
  margin-top: 80px;
`;

export const EventDetailsImage = styled.div`
  background-image: url(${({ cover }) => cover}), url(${({ fallBack }) => fallBack});
  height: 250px;
  min-height: ${({ minHeight }) => minHeight};
  width: 100%;
  position: relative;
  display: flex;
  background-color: rgba(220, 220, 235, 0.6);
  background-size: cover;
  background-position: center;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
  background-blend-mode: 'multiply';
`;

export const EventShare = styled.div`
  display: flex;
  position: absolute;
  bottom: -25px;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
`;

export const EventShareText = styled.p`
  font-size: 30px;
  color: #fff;
`;
export const CircleContainer = styled.p`
  width: ${({ size }) => (size ? size : '50px')};
  height: ${({ size }) => (size ? size : '50px')};
  border-radius: 50%;
  margin: 0;
  background: ${({ theme, color }) => (color ? color : theme.spoxioBg)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EventTiming = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  column-gap: 10px;
`;

export const EventTimingBox = styled.div`
  background: ${({ theme }) => theme.spoxioIcon2};
  border: 1px solid ${({ theme }) => theme.borderShade};
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 6px 10px;
  border-radius: 10px;
`;
export const EventProfile = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EventProfileDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const EventText = styled.p`
  margin: 0;
  color: ${({ color, theme }) => color || theme.spoxioText};
  opacity: ${({ opacity }) => opacity || '0.85'};
`;

export const EventDetailsProfile = styled.div`
  display: flex;
  column-gap: 15px;
  padding: 15px 8px;
  border-radius: 15px;
`;
export const ImageHolder = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
`;

export const NameHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const NameHead = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  width: 100%;
`;

export const NameBottom = styled.h6``;

export const ProfileButton = styled.button`
  width: 94px;
  height: 44px;
  background: ${({ theme }) => theme.spoxioAltBlue};
  border-radius: 12px;
  border: none;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;
export const DetailsText = styled.p`
  margin: 10px 0;
  font-size: 16px;
  color: ${({ color, theme }) => (color ? color : theme.gray)};
`;

export const EventButton = styled.button`
  background: ${({ theme, color }) => (color ? color : theme.spoxioAltLinear)};
  width: ${({ width }) => (width ? width : '98%')};
  margin: ${({ margin }) => (margin ? margin : '5px auto')};
  column-gap: 15px;
  height: ${({ h }) => (h ? h : '48px')};
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: ${({ size }) => (size ? size : '20px')};
  border: ${({ border }) => (border ? border : 'none')};
  border-radius: 10px;
`;
