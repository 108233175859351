import React, { useContext, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import {
  FollowUserButton,
  IconContainerBg,
  NavBarProfileContainer,
  NavBarUserContainer
} from './components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ChatSvg } from '../../Assets/icons/chat-with-black.svg';
import { ProfileDetails } from 'Components/ProfileRevamp/components';
import { UserPic } from 'Components/shared/UserPic';
import { UserName } from 'Components/shared/FollowUser/components';
import { paths } from 'Utils/constants';
import { ThemeContext } from 'Context/themeContext';
import { useDispatch, useSelector } from 'react-redux';
import { FollowUser } from 'Components/shared/FollowUser';
import isEmpty from 'Utils/isEmpty';
import { followOnUser, unfollowOnUser } from 'Store/profileSlice';
import { FollowButton } from 'Components/shared/FollowButton';
import { FollowButtonRevamp } from 'Components/shared/FollowButtonRevamp';
import { truncate } from 'Utils/truncate';
import { ContentContainer, Title } from 'Components/shared/styledComponents';
import UserInfo from './UserInfo';
import Api from 'Api';
import { push } from 'connected-react-router';
import { getCurrentUserId } from 'Store/authSlice';

const NavBarProfile = ({ isCurrentUser, data, showProfileButton }) => {
  const dispatch = useDispatch();
  const History = useHistory();
  const currentUserId = useSelector(getCurrentUserId);
  const { theme } = useContext(ThemeContext);
  const [isFollow, setIsFollow] = useState(data.isFollowedByMe);
  const history = useHistory();
  const Edit = () => {
    history.push(`${paths.PROFILE}/${data._id}/edit`);
  };
  const openProfile = () => {
    history.push(`${paths.PROFILE}/${data._id}`);
  };

  const pushLink = (link) => {
    History.push(link);
  };

  const onPlusClick = () => {
    if (isFollow) {
      dispatch(unfollowOnUser(data._id));
      setIsFollow(!isFollow);
    } else {
      dispatch(followOnUser(data._id));
      setIsFollow(!isFollow);
    }
  };

  const goToChat = (groupId) => dispatch(push(`${paths.CHAT}?chat_id=${groupId}`));
  const onClickChat = () => {
    if (isEmpty(data)) return;
    Api.messages.create([currentUserId, data._id], goToChat);
  };
  return (
    <>
      {!isCurrentUser ? (
        <NavBarUserContainer>
          <IconContainerBg height="22px" width="22px" fill={theme.spoxioIcon}>
            <ChatSvg onClick={onClickChat} />
          </IconContainerBg>

          {/* <IconContainerBg height="22px" width="22px" fill={theme.defaultWhite}>
            <Notification onClick={() => pushLink(paths.NOTIFICATION)} />
          </IconContainerBg> */}
          <FollowButtonRevamp isFollowed={isFollow} onClick={onPlusClick} />
        </NavBarUserContainer>
      ) : (
        <NavBarProfileContainer justifyContent cg="4px" flexWrap="wrap">
          <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <UserPic src={data?.picture} />
            <ContentContainer column flexWrap="wrap" minWidth="75px" gap="0">
              <UserName
                title={`${data?.firstName || ''} ${data?.lastName || ''}`}
                weight={'600'}
                fontSize="1rem"
                marginLeft="0"
              >
                {!isEmpty(data) ? truncate(`${data?.firstName} ${data?.lastName}`, 16) : ''}
              </UserName>

              <UserInfo data={data} maxWidth="100px" />
            </ContentContainer>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            {showProfileButton ? (
              <FollowUserButton minWidth="70px" onClick={openProfile} padding="10px 18px">
                Profile
              </FollowUserButton>
            ) : (
              <FollowUserButton borderRadius="10px" padding="10px" minWidth="70px" onClick={Edit}>
                Edit Profile
              </FollowUserButton>
            )}
          </div>
        </NavBarProfileContainer>
      )}
    </>
  );
};

NavBarProfile.propTypes = {
  isCurrentUser: PropTypes.bool,
  showProfileButton: bool,
  data: PropTypes.object
};
export default NavBarProfile;
