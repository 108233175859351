import styled from 'styled-components';

import { BaseText, BlackText } from '../styledComponents';

export const CommentsWrapper = styled.div`
  margin-top: 35px;
`;

export const CommentContainer = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
`;

export const ReplyContainer = styled.div`
  padding-left: 30px;
`;

export const CommentBody = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

export const UserImg = styled.div`
  margin-right: 10px;
`;

export const CommentText = styled(BlackText)`
  width: 100%;
  margin-left: 5px;
  font-size: ${({ theme }) => theme.small}px;
  word-break: break-all;
`;

export const StarIcon = styled.div`
  margin-left: 10px;
  /* padding-top: 5px; */
  cursor: pointer;

 
`;

export const CommentAction = styled.div`
  display: flex;
  padding-left: 50px;
  margin-top: 5px;

  & > * {
    margin-right: 30px;
  }
`;

export const CommentActionText = styled(BaseText)`
  font-size: ${({ theme }) => theme.small}px;
  color: ${({ theme }) => theme.opacityGray};
`;

export const Input = styled.input`
  background-color: initial;
  border: none;
  margin-left: 10px;
  width: 100%;
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.small}px;

  ::placeholder {
    color: ${({ theme }) => theme.darkGray1};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  align-self: center;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
`;

export const CommentUserImg = styled.div`
  position: relative;
  left: 2px;
`;

export const ViewReplyContainer = styled.div`
  display: flex;
  padding-left: 45px;
  margin-top: 15px;
  cursor: pointer;
  & > * {
    margin-right: 30px;
  }
`;
