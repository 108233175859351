import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { RouteProps } from 'Utils/propTypes';
import { paths } from 'Utils/constants';
import { signUp, updateUser } from 'Store/authSlice';
import { emailRules, passwordRules, requiredRule } from 'Utils/validations';
import { FormCheckbox } from 'Components/shared/Inputs/FormCheckbox';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { BaseButton, BlackText } from 'Components/shared/styledComponents';
import {
  AuthFormContainer,
  AuthLink,
  DocumentationLink,
  LinkDescription,
  SocialInfoContainer,
  SocialInfoUser
} from '../components';
import { CheckboxLabel, ErrorText } from 'Components/shared/Inputs/components';
import { UserPic } from 'Components/shared/UserPic';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'Components/Layouts/AuthLayout';
import { SpoxioBlueBtn, Title } from 'Components/Layouts/AuthLayout/components';
import { theme } from 'Theme';
import isEmpty from 'Utils/isEmpty';

export const SignUpGeneralInfoPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, watch, errors, handleSubmit } = useForm();

  const [hideFrom, setHideForm] = useState(false);
  const [form, setForm] = useState({ email: '', name: '' });

  const preSubmit = (data) => {
    if (isEmpty(data?.email)) {
      return;
    }
    setForm({ email: data?.email, name: `${data?.firstName} ${data?.lastName}` });
    setTimeout(() => {
      setHideForm(true);
    }, 500);
  };

  const onSubmit = async (data) => {
    await preSubmit(data);
    dispatch(signUp({ ...data }));
  };

  const checkboxes = watch(['isAllowedAge', 'terms', 'privacy']);
  const { state } = location;

  return (
    <AuthLayout
      title={
        <Title fontSize="1.75rem" fontWeight="bold">
          Create your
          <br /> account
        </Title>
      }
      substep={hideFrom}
      customGoBack={() => {
        setHideForm(false);
      }}
      showBackButton
    >
      <AuthFormContainer onSubmit={handleSubmit(onSubmit)}>
        {/* {state?.userData */}
        {hideFrom ? (
          <SocialInfoContainer justifyContent="flex-start" gap="10px">
            <UserPic src={state?.userData?.picture} size={100} />
            <SocialInfoUser>
              <BlackText>{state?.userData?.name || form.name}</BlackText>
              <BlackText>{state?.userData?.email || form.email}</BlackText>
            </SocialInfoUser>
          </SocialInfoContainer>
        ) : (
          <SocialInfoContainer marginBottom="0">
            {/* <BlackText className="margin-bottom_medium">{location?.state?.email}</BlackText> */}
            <FormInput
              ref={register(requiredRule)}
              name="firstName"
              placeholder={t('first_name')}
              error={errors?.firstName?.message}
              // className="margin-bottom_medium"
            />
            <FormInput
              ref={register}
              name="lastName"
              placeholder={`${t('last_name')} (Optional)`}
              // className="margin-bottom_medium"
            />

            <FormInput
              ref={register(emailRules)}
              name="email"
              placeholder={t('email')}
              error={errors?.email?.message}
              // className="margin-bottom_medium"
            />
            <FormInput
              ref={register(passwordRules)}
              name="password"
              type="password"
              placeholder={t('password')}
              // helperText={t('minimum_password')}
              helperText={t('Minimum of 6 letters and/or numbers')}
              error={errors?.password?.message}
              // className="margin-bottom_medium"
              isPassword
            />
          </SocialInfoContainer>
        )}
        <div style={{ marginTop: '0' }}>
          <FormCheckbox
            ref={register(requiredRule)}
            name="isAllowedAge"
            label={t('13_years_older')}
            checked={checkboxes.isAllowedAge}
          />
          <FormCheckbox
            ref={register(requiredRule)}
            name="terms"
            CustomLabel={() => (
              <Title fontSize='inherit'>
                {t('i_agree_with_the')}
                <DocumentationLink color={theme.spoxioText} to={paths.TERMS_OF_CONDITIONS}>
                  {t('terms_condition')}
                </DocumentationLink>
              </Title>
            )}
            checked={checkboxes.terms}
          />
          <FormCheckbox
            ref={register(requiredRule)}
            name="privacy"
            CustomLabel={() => (
              <CheckboxLabel>
                {t('i_agree_with_the')}
                <DocumentationLink to={paths.PRIVACY}> {t('privacy_policy')}</DocumentationLink>
              </CheckboxLabel>
            )}
            checked={checkboxes.privacy}
          />
          <ErrorText isShow={errors?.isAllowedAge || errors?.terms || errors?.privacy}>
            {errors?.isAllowedAge || errors?.terms || errors?.privacy ? (
              <>{t('please_accept_all')}</>
            ) : null}
          </ErrorText>
        </div>

        <SpoxioBlueBtn className="margin-top_medium">{t('create_account')}</SpoxioBlueBtn>

        <span style={{ marginTop: '45px', textAlign: 'center' }}>
          {' '}
          <LinkDescription>Already have an account?</LinkDescription>{' '}
          <AuthLink textweight={'normal'} to={paths.SIGN_IN} color="#007AFF">
            SIGN IN
          </AuthLink>
        </span>
      </AuthFormContainer>
    </AuthLayout>
  );
};

SignUpGeneralInfoPage.propTypes = RouteProps;
