import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { getStories } from 'Store/storiesSlice';
import { useSlider } from 'Hooks/useSlider';
import { UserPic } from 'Components/shared/UserPic';
import { SliderContainer, SlideContainer, UserName, NextBtn, BeforeBtn } from './components';

// import { ReactComponent as NextIcon } from 'Assets/icons/Next.svg';
// import { ReactComponent as BeforeIcon } from 'Assets/icons/Before.svg';
import { ReactComponent as BeforeIcon } from 'Assets/icons/ArrowLeftWhite.svg';
import { ReactComponent as NextIcon } from 'Assets/icons/ArrowRightWhite.svg';
import { ReactComponent as Camera } from 'Assets/icons/camera.svg';
import {
  getLiveStreamSelector,
  getStoriesSelector,
  setLiveStreamsList,
  setStoriesList
} from 'Store/selectors/storiesSelector';
import { ContentContainer, Divider } from 'Components/shared/styledComponents';
import { Title } from 'Components/Layouts/MainNavigation/LeftBar/components';
import { ThemeContext } from 'Context/themeContext';
import { toast } from 'react-toastify';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import { getCurrentUser } from 'Store/authSlice';
import { setLoading } from 'Store/loaderSlice';
import Api from 'middleware/api';
import { DragDropFileInput } from 'Components/shared/Inputs/DragDropFilesInput';

export const Stories = () => {
  const dispatch = useDispatch();
  const allStories = useSelector(getStoriesSelector);
  const liveStreams = useSelector(getLiveStreamSelector);
  const currentUserId = useSelector(getCurrentUser);
  const { theme } = useContext(ThemeContext);

  const feed = useMemo(() => [...liveStreams, ...allStories], [liveStreams, allStories]);

  const { currentSlide, showPrevious, showNext, handleNext, handlePrevious } = useSlider(
    allStories?.length,
    6
  );

  const goToStories = (userStory, storyIndex) => {
    if (userStory.type === 'live_streaming') {
      dispatch(push(`${paths.TIMELINE}/${userStory.id}?isLivestream=true`));
    } else {
      const state = {
        prev: storyIndex > 0 ? storyIndex - 1 : undefined,
        userStoryId: userStory._id,
        next: storyIndex < allStories.length - 1 ? storyIndex + 1 : undefined
      };

      dispatch(push(`${paths.STORIES}/${userStory._id}`, state));
    }
  };

  useEffect(() => {
    dispatch(getStories());
    return () => {
      getStories();
    };
  }, [dispatch]);

  useEffect(() => {
    console.log('\n\rallStories====> ', allStories);
    console.log('\n\r feed stream and story combined====> \n\r', feed);
  }, [allStories, feed]);

  const onUploadStory = async (base64Name, file) => {
    const uploadPromises = [];
    let mediaSource = '';
    const type = file.type.includes('image') ? 'image' : 'video';

    uploadPromises.push(
      new Promise((resolve, reject) => {
        let filename = new Date().getTime();
        const extension = base64Name.includes('image') ? '.jpg' : '.mp4';
        filename += extension;

        const storageRef = firebase.storage().ref(`stories/${currentUserId}/${filename}`);
        storageRef
          .put(file)
          .then(() => storageRef.getDownloadURL())
          .then((dataUrl) => {
            mediaSource = dataUrl;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      })
    );

    Promise.all(uploadPromises)
      .then(async () => {
        console.log('the media sources', mediaSource);
        await saveStory({
          type,
          title: '',
          url: mediaSource,
          channel: 'story'
        });

        dispatch(getStories());
      })
      .catch((reason) => {
        console.log('upload story:::', reason);
        toast.warn(reason.message);
      });
  };

  const saveStory = async (payload) => {
    console.log(payload, '\n\r----------------- the payload save story-----------\n\r');
    try {
      dispatch(setLoading(true));
      const story = await Api.story.createStory(payload);
      console.log(story, '\n\r----------------- the res create story-----------\n\r');
      toast.success(story?.data?.message.charAt(0).toUpperCase() + story?.data?.message.slice(1));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.log('save story error::', error, '\n\r', error?.response);
    }
  };

  const prepareStoryData = async () => {
    const beginningDate = Date.now() - 24 * 60 * 60 * 1000;
    const beginningDateObject = new Date(beginningDate);

    try {
      const livestreams = firebase.firestore()
        .collection('online_streaming')
        .orderBy('createdAt', 'desc')
        .where('createdAt', '>', beginningDateObject);

      livestreams.onSnapshot({
        next: (snapshot) => {
          if (!snapshot.empty) {
            const newLivestreams = snapshot.docs.map((livestream) => {
              const livestreamData = livestream.data();
              return livestreamData.posts[0];
            });

            dispatch(setLiveStreamsList(newLivestreams));
          } else {
            dispatch(setLiveStreamsList([]));
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      prepareStoryData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <SliderContainer>
      <ContentContainer
        hover
        column
        gap="8px"
        padding="5px"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: 'pointer' }}
      >
        <DragDropFileInput
          isDocument
          onChange={onUploadStory}
          border="transparent"
          acceptedFormat={'image/*, video/*'}
          customIcon={
            <ContentContainer
              hover
              column
              gap="8px"
              padding="5px"
              justifyContent="center"
              alignItems="center"
              style={{ cursor: 'pointer' }}
            >
              <Camera width={35} height={35} />
              <Title fontSize="0.75rem" textAlign="center" style={{ lineHeight: '14px' }}>
                Add Your Story
              </Title>
            </ContentContainer>
          }
        />
      </ContentContainer>

      <Divider vertical />
      <ContentContainer width="100%">
        <CarouselProvider
          naturalSlideWidth={180}
          naturalSlideHeight={160}
          totalSlides={feed.length}
          visibleSlides={6}
          currentSlide={currentSlide}
          step={0}
        >
          <Slider>
            {feed?.map((story, index) => {
              // console.log('\n\r the story ', story);
              return (
                <Slide key={`${story._id || story.uid}-${index}`} index={index}>
                  <SlideContainer onClick={() => goToStories(story, index)}>
                    <UserPic
                      src={story?.createdBy?.picture || story?.profile}
                      active={story?.active}
                      live={story.type === 'live_streaming'}
                      size={47}
                    />
                    <Title fontSize="0.7rem" textAlign="center" style={{ lineHeight: '14px' }}>
                      {story?.createdBy?.firstName || story?.username}
                    </Title>
                  </SlideContainer>
                </Slide>
              );
            })}
          </Slider>
        </CarouselProvider>
        {showPrevious ? (
          <BeforeBtn>
            <BeforeIcon onClick={handlePrevious} />
          </BeforeBtn>
        ) : null}
        {showNext && feed?.length > 6 ? (
          <NextBtn>
            <NextIcon onClick={handleNext} stroke={theme.black} />
          </NextBtn>
        ) : null}
      </ContentContainer>
    </SliderContainer>
  );
};
