import styled from 'styled-components';
import { bool } from 'prop-types';

import { FormTextArea } from 'Components/shared/Inputs/FormTextArea';
import { BaseButton, BaseText, BlackText, SpoxioBlueBtn } from 'Components/shared/styledComponents';

import { ReactComponent as CloseIcon } from 'Assets/icons/Close.svg';
import { ReactComponent as CloseXIcon } from 'Assets/icons/CloseXIcon.svg';
import { ReactComponent as MoreXIcon } from 'Assets/icons/threeDots.svg';
import { ReactComponent as Search } from 'Assets/icons/Search.svg';
import { ReactComponent as Football } from 'Assets/icons/Football.svg';
import { ReactComponent as Location } from 'Assets/icons/Location.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 90px;
  background-color: ${({ theme }) => theme.white};
`;

export const TextInput = styled(FormTextArea)`
  border: none;
  font-size: ${({ theme }) => theme.xLarge}px;

  ::-webkit-resizer {
    display: none;
  }
`;

export const SelectItems = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export const FootballIcon = styled(Football)`
  position: relative;
  top: 1px;
  width: 20px;
  & path {
    fill: ${({ theme, selected }) => (selected ? theme.orange : theme.opacityGray)};
  }
`;

export const CloseIconX = styled(CloseXIcon)`
  position: relative;
  & path {
    fill: ${({ theme, color }) => color || theme.orange};
  }
`;

export const ThreeDotsIcon = styled(MoreXIcon)`
  position: relative;
  & path {
    fill: ${({ theme, color }) => color || theme.orange};
  }
`;

export const LocationIcon = styled(Location)`
  width: 20px;
  & path {
    fill: ${({ theme, selected }) => (selected ? theme.orange : theme.opacityGray)};
  }
`;

export const Select = styled.div`
  font-size: 14px;
  display: flex;
  padding-right: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.opacityGray};
`;

export const SelectLabel = styled(BaseText)`
  align-self: center;
  margin-left: 5px;
  font-size: ${({ theme }) => theme.small}px;
  color: ${({ theme }) => theme.darkGray1};
`;

export const SearchIcon = styled(Search)`
  margin-right: 10px;
  position: relative;
  top: 3px;

  & path {
    fill: ${({ theme }) => theme.opacityGray};
  }
`;

export const SearchInputContainer = styled.div`
  margin-bottom: 25px;
`;

export const SearchContainer = styled.div`
  position: relative;
  padding: 0 60px;
  height: 100%;
  overflow: hidden;
`;

export const SearchItemsContainer = styled.div`
  height: 400px;
  overflow-y: auto;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(216, 216, 216, 1) 100%);
  background-size: 100% 225px;
  background-repeat: no-repeat;
  background-position: bottom;
`;

export const SearchItem = styled(BlackText)`
  display: block;
  padding: 10px 5px;
  cursor: pointer;
  margin-left: 35px;

  :hover {
    background: ${({ theme }) => theme.opacityBlue};
  }
`;

export const SearchInput = styled.input`
  width: calc(100% - 35px);
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.opacityGray};
`;

// export const Image = styled.img`
export const Image = styled(LazyLoadImage).attrs((props) => ({
  effect:  props.effect || 'blur'
}))`
  max-height: 100%;
  max-width: 100%;
  border-radius: 14px;
`;

export const ImageSelectContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  padding-bottom: 24px;
  overflow-x: auto;
  column-gap: 10px;
`;

export const ImageContainer = styled.div`
  height: ${({ height }) => height || '200px'};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  position: relative;
  min-width: ${({ minWidth }) => minWidth || '65%'};
  display: flex;
  justify-content: center;
`;

export const Close = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const Button = styled(SpoxioBlueBtn)`
  padding: 15px;
  margin-top: auto;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

Button.propTypes = {
  isVisible: bool
};

Button.defaultProps = {
  isVisible: true
};

export const File = styled.div`
  width: 100%;
  min-width: 225px;
  height: 200px;
`;

export const Back = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 200;
  color: ${({ theme }) => theme.opacityGray};
  & svg {
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
`;
