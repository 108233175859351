const globalColors = {
  white: '#fff',
  black: '#000',
  lightestGray: '#f9f9f9',
  lightGray: '#dedede',
  lightGray1: '#e6e5eb',
  lightGray2: '#d8d8d8',
  gray: '#8d8d91',
  secondaryGray: '#979797',
  darkGray: '#757575',
  darkGray1: '#6D7278',
  opacityGray: 'rgba(60, 60, 67, 0.6)',
  opacityLightGray: 'rgba(141, 141, 145, 0.3)',
  opacityLightGray1: 'rgba(141, 141, 145, 0.5)',
  primaryBlue: '#016F96',
  opacityPrimaryBlue: 'rgba(1, 111, 150, 0.1)',
  lightPrimaryBlue: '#0094C9',
  blue: '#0091ff',
  bluePurple: '#3B77F2',
  lightBlue: '#32c5ff',
  opacityBlue: 'rgba(50, 197, 255, 0.3)',
  opacityDarkBlue: 'rgb(0, 35, 48, 0.5)',
  orange: '#fa6400',
  yellow: '#f7b500',
  purple: '#6236ff',
  red: '#e94235',
  primaryRed: '#ff2d55',
  pink: '#ce0881',
  green: '#578e1c',
  lightGreen: '#6dd400',
  cardSubc: '#50555CA1'
};

export const fontSizes = {
  xSmall: 11,
  small: 12,
  medium: 14,
  large: 16,
  large2: 18,
  xLarge: 20,
  xLarge2: 22,
  xLarge3: 34
};

export const lightTheme = {
  body: '#fff',
  background: '#F4F8FA',
  white: '#fff',
  black: '#101010',
  lightestGray: '#f9f9f9',
  lightGray: '#dedede',
  lightGray1: '#e6e5eb',
  lightGray2: '#d8d8d8',
  lightGray3: '#E6E5EB',
  gray: '#8d8d91',
  secondaryGray: '#979797',
  mediumGray: 'rgba(127, 127, 127)',
  darkGray: '#757575',
  darkGray1: '#6D7278',
  darkGray2: '#E4E6EB',
  neutralGray: 'rgba(141, 141, 145, 0.3)',
  neutralGray2: '#76777E',
  opacityGray: 'rgba(60, 60, 67, 0.6)',
  opacityLightGray: 'rgba(141, 141, 145, 0.3)',
  opacityLightGray1: 'rgba(141, 141, 145, 0.5)',
  primaryBlue: '#016F96',
  opacityPrimaryBlue: 'rgba(1, 111, 150, 0.2)',
  lightPrimaryBlue: '#0094C9',
  blue: '#0091ff',
  bluePurple: '#3B77F2',
  lightBlue: '#32c5ff',
  opacityBlue: 'rgba(50, 197, 255, 0.3)',
  opacityDarkBlue: 'rgb(0, 35, 48, 0.5)',
  orange: '#fa6400',
  yellow: '#f7b500',
  purple: '#6236ff',
  red: '#e94235',
  primaryRed: '#ff2d55',
  pink: '#ce0881',
  green: '#578e1c',
  lightGreen: '#6dd400',
  defaultWhite: '#fff',
  defaultBlack: '#101010',
  defaultLightGray: '#dedede',
  borderShade: '#DCDCE1',
  borderShade2: 'transparent',
  lightGrayShade: '#454545', //'#2C2C2C',
  dividerGray: '#D8D8D8',
  tabActiveBg: '#E7EBEF',
  spoxioLinear1: 'linear-gradient(137.49deg, #00A5C9 0%, #FA6400 96.01%)',
  spoxioLinear2: 'linear-gradient(98.25deg, #00A5C9 5.01%, #0076DE 90.43%)',
  spoxioAltLinear: 'linear-gradient(98.25deg, #00A5C9 5.01%, #007AFF 90.43%)',
  spoxioBlackOpacity: 'rgba(0, 0, 0, 0.6)',
  spoxioBlue: '#0094C9',
  spoxioAltBlue: '#007AFF',
  spoxioText: '#29292E',
  spoxioBodyText: '#6A6E85',
  spoxioBodyText2: '#9396a7',
  spoxioBg: '#FDFFFF',
  spoxioDarkBg: '#141414',
  spoxioBg2: '#323232',
  spoxioFaintWhite: '#E4E6EB',
  spoxioBgLayer1: '#F4F8FA',
  spoxioBgLayer2: '#E7EBEF',
  spoxioLightBlue: '#D5EBEF',
  spoxioCardBg: '#f3f4f6',
  spoxioRed: '#ff2d55',
  spoxioGray: '#C7CEDE',
  spoxioGray1: '#C7CEDE',
  spoxioIcon: '#A9B0C5',
  spoxioIcon2: '#e6e5eb',
  spoxioIcon3: '#76777E',
  tagsContainer: '#e6e5eb',
  spoxioPurple: '#A389FF',
  cardSubc: '#dcdce14a',
  xxSmall: 10,
  xSmall: 11,
  small: 12,
  medium: 14,
  large: 16,
  large2: 18,
  xLarge: 20,
  xLarge2: 22,
  xLarge3: 34
};

export const darkTheme = {
  body: '#101010',
  background: '#141414',
  white: '#000',
  black: '#fff',
  lightestGray: '#f9f9f9',
  lightGray: '#dedede',
  lightGray1: '#e6e5eb',
  lightGray2: '#757575',
  gray: '#76777E',
  secondaryGray: '#979797',
  mediumGray: 'rgba(127, 127, 127)',
  darkGray: '#FBFBFC',
  darkGray1: '#E6E5EB',
  darkGray2: '#212121',
  neutralGray: '#6D7278',
  neutralGray2: '#76777E',
  opacityGray: '#c4c4d2',
  opacityLightGray: 'rgba(215, 215, 215)',
  opacityLightGray1: 'rgba(215, 215, 215, 0.7)',
  primaryBlue: '#016F96',
  opacityPrimaryBlue: 'rgba(1, 111, 150, 0.2)',
  lightPrimaryBlue: '#0094C9',
  blue: '#0091ff',
  lightGray3: '#E4E6EB',
  bluePurple: '#3B77F2',
  lightBlue: '#32c5ff',
  opacityBlue: 'rgba(50, 197, 255, 0.3)',
  opacityDarkBlue: 'rgb(0, 35, 48, 0.5)',
  orange: '#fa6400',
  yellow: '#f7b500',
  purple: '#6236ff',
  red: '#e94235',
  primaryRed: '#ff2d55',
  pink: '#ce0881',
  green: '#578e1c',
  lightGreen: '#6dd400',
  defaultWhite: '#fff',
  spoxioLightBlue: '#003148',
  defaultBlack: '#101010',
  defaultLightGray: '#dedede',
  borderShade: '#29292E',
  borderShade2: '#29292E',
  lightGrayShade: '#dedede',
  dividerGray: '#D8D8D8',
  tabActiveBg: '#1B1A1C',
  spoxioLinear1: 'linear-gradient(137.49deg, #00A5C9 0%, #FA6400 96.01%)',
  spoxioLinear2: 'linear-gradient(98.25deg, #00A5C9 5.01%, #0076DE 90.43%)',
  spoxioAltLinear: 'linear-gradient(98.25deg, #00A5C9 5.01%, #007AFF 90.43%)',
  spoxioBlue: '#0094C9',
  spoxioAltBlue: '#007AFF',
  spoxioBlackOpacity: 'rgba(255, 255, 255, 0.6)',
  spoxioText: lightTheme.spoxioFaintWhite, //'#29292E',
  spoxioBodyText: '#6A6E85',
  spoxioBodyText2: '#9396a7',
  spoxioBg: '#141414', //light
  spoxioDarkBg: '#FDFFFF',
  spoxioBg2: '#323232',
  spoxioFaintWhite: lightTheme.spoxioText, //'#E4E6EB',
  spoxioPurple: '#A389FF',
  spoxioBgLayer1: '#F4F8FA',
  spoxioBgLayer2: '#E7EBEF',
  spoxioCardBg: '#202122',
  spoxioRed: '#ff2d55',
  spoxioGray: '#C7CEDE',
  spoxioGray1: '#898A8D',
  spoxioIcon: '#A9B0C5',
  spoxioIcon2: '#232226',
  spoxioIcon3: '#3A3B3C',
  tagsContainer: '#1A1B1C',
  cardSubc: '#dcdce14a',
  xxSmall: 10,
  xSmall: 11,
  small: 12,
  medium: 14,
  large: 16,
  large2: 18,
  xLarge: 20,
  xLarge2: 22,
  xLarge3: 34
};

export const light = {
  background: '#F4F8FA',
  mainBg: '#FDFFFF',
  postBackground: '#FDFFFF',
  text: '#000',
  socialsIcon: '#999999',
  headerbg: '#FDFFFF',
  tabBg: 'rgba(235, 235, 235, 0.5)',
  tabActiveBg: '#E7EBEF',
  tabTextColor: '#50555C',
  tabBd: 'transparent',
  tabBdActive: '#007AFF',
  eventContainerBg: '#F4F8FA',
  eventContainerBd: 'rgba(0, 0, 0, 0.04)',
  roleTagsBg: '#fff',
  blueBtnBg: '#D5EBEF',
  // contentContainerBd: '#ffffff',
  CameraSvgColor: '#979797',
  CameraSvgContainer: '#F5F5F5',
  contentContainerBd: 'rgba(0, 0, 0, 0.05)',
  dateContainerBg: '#E7EBEF',
  borderColor: '#F4F8FA',
  redColor: '#fdf2f4',
  subText: '#6A6E85',
  current: '#007AFF',
  menu: '#E7EBEF',
  backBtnBG: '#E4E6EB',
  tabActiveText: '#007AFF',
  statContainerBg: '#E7EBEF',
  storyIcon: '#E7EBEF',
  football: '#FFF0E6',
  golf: '#E6E0FF',
  afootbal: '#E6F4FF',
  basketball: '#F3EEDE',
  searchbg: '#F4F8FA',
  searchTxt: '#6A6E85',
  chatBorder: '#C7CEDE',
  statusBarColor: 'light-content',
  spinner: '#0073b7',
  notify: '#E7EBEF',
  cardSubc: '#FDFFFF'
};

export const dark = {
  background: '#141414',
  text: '#29292E',
  mainBg: '#141414',
  postBackground: '#141414',
  // text: '#E4E6EB',
  socialsIcon: '#E4E6EB',
  headerbg: '#141414',
  tabBg: '#1B1A1C',
  tabActiveBg: '#1B1A1C',
  tabTextColor: '#E4E6EB',
  tabBd: '#29292E',
  tabBdActive: '#0094C9',
  eventContainerBg: '#232226',
  eventContainerBd: '#29292E',
  roleTagsBg: '#202122',
  blueBtnBg: '#003148',
  contentContainerBd: '#29292E',
  CameraSvgColor: 'rgba(255, 255, 255, 0.6)',
  CameraSvgContainer: '#1B1A1C',
  // contentContainerBd: '#29292E',
  dateContainerBg: '#141414',
  borderColor: '#29292E',
  redColor: '#29292E',
  subText: '#6D7278',
  current: '#E4E6EB',
  menu: '#6D7278',
  backBtnBG: '#323232',
  tabActiveText: '#E4E6EB',
  statContainerBg: '#232226',
  storyIcon: '#1A1A1A',
  football: '#372111',
  golf: '#261937',
  afootbal: '#16273B',
  basketball: '#322C13',
  searchbg: '#29292E',
  searchTxt: '#757575',
  chatBorder: '#46464B',
  statusBarColor: 'dark-content',
  spinner: '#fff',
  notify: '#232226',
  cardSubc: '#50555CA1'
};
const themeMode = window.localStorage.getItem('themeMode');
const darkMode = themeMode === 'light' ? true : false;

const colors = themeMode === 'dark' ? darkTheme : lightTheme;

export { colors, darkMode, globalColors };

export const theme = {
  colors: { ...colors },
  fontSizes: { ...fontSizes }
};
