import styled from 'styled-components';

import { ReactComponent as Verified } from 'Assets/icons/Verified.svg';

export const UserPicContainer = styled.div`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

export const AvatarContainer = styled.div`
  border-radius: ${({ size }) => `${size}px`};
  border-width: 1px;
  border-style: solid;
  background-color: ${({ theme }) => theme?.spoxioIcon2};
  border-color: ${({ active, live, theme }) =>
    active || live ? theme.spoxioAltBlue : 'transparent'};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  overflow: hidden;
  & img {
    width: 100%;
    object-fit: cover;
  }
`;

export const LiveIconContainer = styled.div`
  position: absolute;
  bottom: -16px;
  margin: 0 auto;
`;

export const VerifiedIcon = styled(Verified)`
  position: absolute;
  bottom: -6px;
  right: -6px;
  & path {
    /* fill: ${({ theme }) => theme.orange}; */
  }
`;
