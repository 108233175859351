import { forwardRef } from 'react';
import { string } from 'prop-types';

import { BaseTextArea } from '../styledComponents';
import { ErrorText, InputLabel } from './components';

export const FormTextArea = forwardRef(({ name, placeholder, error, className, label }, ref) => {
  return (
    <>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <BaseTextArea ref={ref} name={name} placeholder={placeholder} className={className} />
      <ErrorText isShow={!!error}>{error}</ErrorText>
    </>
  );
});

FormTextArea.propTypes = {
  name: string.isRequired,
  placeholder: string,
  error: string,
  className: string,
  label: string
};

FormTextArea.defaultProps = {
  placeholder: 'Placeholder',
  error: '',
  className: '',
  label: ''
};
