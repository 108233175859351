import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import { ConnectedRouter } from 'connected-react-router';
import { firebaseConfig } from './firebase';
import { history, store } from 'Store';
import { Router } from 'Routes';
import { NotificationContainer } from 'Components/shared/styledComponents';
import ThemeContextProvider from 'Context/themeContext';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'Styles/index.scss';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const themeMode = window.localStorage.getItem('themeMode');
  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      setIsLoading(false);
    } else {
      firebase.app(); // if already initialized, use that one
      setIsLoading(false);
    }
    firebase.auth().signInAnonymously();
  }, []);

  useEffect(() => {}, [themeMode]);

  return (
    <ThemeContextProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>{!isLoading ? <Router /> : null}</ConnectedRouter>
        <NotificationContainer
          position="top-right"
          autoClose={5000}
          limit={3}
          hideProgressBar
          pauseOnHover
          draggable
        />
      </Provider>
    </ThemeContextProvider>
  );
};

export default App;
