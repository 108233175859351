import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  whoToFollow: [],
  topics: [],
  search: null,
  searchData: {
    users: [],
    topics: [],
    interests: []
  },
  interestTrending: []
};

export const discoverySlice = createSlice({
  name: 'discovery',
  initialState,
  reducers: {
    setDiscoveryList: (state, action) => {
      state.list = action.payload;
    },
    setWhoToFollow: (state, action) => {
      state.whoToFollow = action.payload;
    },
    setTopics: (state, action) => {
      state.topics = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setUserInterestTrending: (state, action) => {
      state.interestTrending = action.payload;
    },
    setSearchData: (state, action) => {
      const { users, topics, interests } = action.payload;

      state.searchData = {
        users: users?.length ? users : [],
        topics: topics?.length ? topics : [],
        interests: interests?.length ? interests : []
      };
    }
  }
});

// actions
export const {
  setWhoToFollow,
  setDiscoveryList,
  setTopics,
  setSearch,
  setSearchData,
  setUserInterestTrending
} = discoverySlice.actions;

// reducer
export const discoveryReducer = discoverySlice.reducer;

// selectors
export const getSearchSelector = (state) => state?.discovery?.search;
export const getSearchDataSelector = (state) => state?.discovery?.searchData;
export const getDiscoveryListSelector = (state) => state?.discovery?.list;
export const getWhoToFollowSelector = (state) => state?.discovery?.whoToFollow;
export const getAllTopicsSelector = (state) => state?.discovery?.topics;
export const getTrendingTopicsSelector = (state) => state?.discovery?.topics;
export const getInterestTrendingSelector = (state) => state?.discovery?.interestTrending;
