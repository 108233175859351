import { Link } from 'react-router-dom';

import { GetStartedButton } from './GetStartedButton';

import 'Styles/mobile_menu.scss';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'Components/LanguageSwitcher/LanguageSwitcher';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';

export const MobileMenu = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  return (
    <nav className="mobile-menu">
      <input type="checkbox" />
      <span className="menu-burger"></span>
      <span className="menu-burger"></span>
      <span className="menu-burger"></span>
      <div className="mobile-menu-content">
        <GetStartedButton />
        <ul className="menu-list">
          <li className="menu-item">
            <Link to="/about" className="header-link">
              {t('about')}
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/privacy" className="header-link">
              {t('privacy_policy')}
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/terms-conditions" className="header-link">
              {t('terms_of_use')}
            </Link>
          </li>
        </ul>
        <div className="contact">
          <span className="label">
            {t('contact_us')}
          </span>
          <span className="email">support@spoxio.com</span>
          <br />
          <span className="label">{t('for_more_info')}</span>
          <a href=" https://blog.spoxio.com/" rel="noreferrer" target="_blank">
            Spoxio Blog
          </a>
          <span className="email">
            {t('switch_language')}: <LanguageSwitcher />
          </span>
        </div>
      </div>
    </nav>
  );
};
