import React from 'react';
import PropTypes from 'prop-types';
import { AboutTags, AboutText } from './components';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';

const AboutProfile = ({ about }) => {
  // console.log('about profile details\n', about);

  return (
    <ContentContainer column gap="20px" width="100%">
      <AboutTags m={'20px'} size={'0.875rem'}>
        <SubTitle opacity="0.5" fontSize="0.875rem" style={{ textTransform: 'none' }}>
          Interested in
        </SubTitle>
        <Title fontSize="0.875rem">
          {about?.sports?.map((sport, i) => {
            if (i === about.sports.length - 1) {
              return <span key={i.toString()}>{sport.name}.</span>;
            }
            return <span key={i.toString()}> {sport.name}, </span>;
          })}
        </Title>
      </AboutTags>

      {/* About the person self */}
      {about?.bio && <SubTitle fontWeight="600">About</SubTitle>}

      {about?.bio && (
        <AboutText textSize={'16px'}>
          {about.bio}
        </AboutText>
      )}
    </ContentContainer>
  );
};

AboutProfile.propTypes = {
  about: PropTypes.object
};

export default AboutProfile;
