import React, { useContext } from 'react';
import ScoutPic from '../../Assets/img/scoutprofile.png';
import { object } from 'prop-types';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { ContentContainer, SubTitle, Title } from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { UserPic } from 'Components/shared/UserPicCustom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { paths } from 'Utils/constants';

const ScoutInfo = ({ about, profile }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const { scoutProfile } = about;
  const onUserClick = (id) => dispatch(push(`${paths.PROFILE}/${id}`));
  console.log(profile, 'scoutinfo profile');
  return scoutProfile?.talentsOfInterest.length < 1 ? (
    <EmptyContent
      showIcon={false}
      maxWidth="auto"
      description={
        <ContentContainer column width="100%" alignItems="center" justifyContent="center">
          <Title fontWeight="bold" color={theme.black} textAlign="center" fontSize="1.25rem">
            No Scouted Talent
          </Title>
          <SubTitle width="100%" textAlign="center" fontSize="0.875rem" lineHeight="normal">
            You don't have any liked talent. Scout for talents.
          </SubTitle>
        </ContentContainer>
      }
    />
  ) : (
    <ContentContainer row gap="16px" flexWrap="wrap" margin="20px 0" width="100%">
      {profile?.scoutedTalent?.map((data, i) => {
        console.log(data, 'talent scouted');
        return (
          <ContentContainer
            column
            key={i.toString()}
            cursor="pointer"
            gap="4px"
            width="40%"
            onClick={() => onUserClick(data?.user?._id)}
          >
            <UserPic containerStyle={{ minHeight: '8vh' }} src={data?.user?.picture} size="100%" />
            <Title
              fontSize="0.875rem"
              lineHeight="normal"
              textOverflow="ellipsis"
              maxWidth="10vw"
              style={{ overflow: 'hidden' }}
              fontFamily="SF Pro"
            >
              {data?.user?.firstName} {data?.user?.lastName}
            </Title>
          </ContentContainer>
        );
      })}
    </ContentContainer>
  );
};

ScoutInfo.propTypes = {
  about: object,
  profile: object
};

export default ScoutInfo;
