import { useState, useRef } from 'react';
import { func, instanceOf } from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { formatDate } from 'Utils/dates';
import { YearMonthForm, fromMonth, toMonth } from './YearMonthForm';

export const DatePicker = ({ maxDate, onChange, ...props }) => {
  const [month, setMonth] = useState(fromMonth);
  const inputRef = useRef(null);

  const handleYearMonthChange = (month) => {
    setMonth(month);
    inputRef?.current?.input?.focus();
  };

  return (
    <DayPickerInput
      ref={inputRef}
      id="date-picker"
      format="MM/DD/YYYY"
      formatDate={formatDate}
      keepFocus={false}
      dayPickerProps={{
        month,
        fromMonth,
        toMonth: maxDate || toMonth,
        // eslint-disable-next-line react/prop-types
        captionElement: ({ date, localeUtils }) => (
          <YearMonthForm
            date={date}
            maxDate={maxDate}
            localeUtils={localeUtils}
            onChange={handleYearMonthChange}
          />
        )
      }}
      onDayChange={onChange}
      {...props}
    />
  );
};

DatePicker.propTypes = {
  maxDate: instanceOf(Date),
  onChange: func.isRequired
};

DatePicker.defaultProps = {
  maxDate: null
};
