import axios from 'axios';
import imageCompression from 'browser-image-compression';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Swal from 'sweetalert2';

import { googleTranslateKey, MY_DA_ID, MY_DA_SECRET_KEY, userRoles } from './constants';

export const getParentComments = (comments) => comments.filter((comment) => comment.parent === 0);

export const getChildComments = (comments, idParentComment) =>
  comments.filter((comment) => comment.parent === idParentComment);

export const detectLanguage = async (text = '') => {
  let request = '';

  try {
    request = await axios.post(
      'https://translation.googleapis.com/language/translate/v2/detect',
      null,
      {
        params: {
          q: text,
          key: googleTranslateKey
        }
      }
    );
    request = request.data.data.detections[0][0].language;
  } catch (error) {
    request = 'en';
  }

  return request;
};

export const hashtagsToArray = (str) => {
  const result = str.match(/\.*#\w\w+\b/g);

  return result ? Array.from(new Set(result)) : [];
};

export const compressFile = async (file, maxSizeMB = 4) => {
  const options = {
    maxSizeMB,
    initialQuality: 0.9
  };

  const compressedFile = await imageCompression(file, options);

  return compressedFile;
};

export const isVideo = (file) => file.includes('video');

export const getStateForStoriesRouting = (allStories, next, prev) => {
  if (next > 0) {
    return {
      prev: next - 1,
      userStoryId: allStories[next]._id,
      next: next + 1 < allStories.length ? next + 1 : undefined
    };
  }

  if (prev >= 0) {
    return {
      prev: prev - 1 >= 0 ? prev - 1 : undefined,
      userStoryId: allStories[prev]._id,
      next: prev + 1
    };
  }
};

export const getResourceUriByBroadcastID = (broadcastId) => {
  const uri = `https://cdn.bambuser.net/broadcasts/${broadcastId}?da_id=${MY_DA_ID}&da_timestamp=${Math.floor(
    Date.now() / 1000
  )}&da_static=1&da_signature_method=HMAC-SHA256`;

  const stringToSign = `GET ${uri}`;
  const signature = hmacSHA256(stringToSign, MY_DA_SECRET_KEY);
  const signedUri = `${uri}&da_signature=${signature}`;

  return signedUri;
};

export const getLivestreamLink = (broadcastId) => {
  const resourceUri = getResourceUriByBroadcastID(broadcastId);

  return `https://dist.bambuser.net/player/?resourceUri=${encodeURIComponent(resourceUri)}`;
};

export const sortArray = (items, field, sortType = 'asc') => {
  if (sortType === 'asc') {
    items.sort((firstItem, secondItem) => (firstItem[field] > secondItem[field] ? 1 : -1));
  } else {
    items.sort((firstItem, secondItem) => (firstItem[field] < secondItem[field] ? 1 : -1));
  }

  return items;
};

export const arrayCompare = (arr1, arr2) => {
  const arr1Copy = [...arr1];
  const arr2Copy = [...arr2];

  arr1Copy.sort((a, b) => (a > b ? 1 : -1));
  arr2Copy.sort((a, b) => (a > b ? 1 : -1));

  return JSON.stringify(arr1Copy) === JSON.stringify(arr2Copy);
};

export const getTurnedOffNotifications = (notificationType, turnedOffNotifications = []) =>
  turnedOffNotifications?.includes(notificationType)
    ? turnedOffNotifications.filter((notification) => notification !== notificationType)
    : [...turnedOffNotifications, notificationType];

export const removeDuplicates = (originalArray, prop) => {
  const newArray = [];
  const lookupObject = {};

  for (const i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (const i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

export const isScout = (role) => role === userRoles.SCOUT;

export const formatToMinutes = (time) => {
  const mins = Math.floor((time % 3600) / 60);
  const secs = Math.floor(time % 60);

  return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

export const stringToPrice = (value, currency = 'usd') => {
  const stringValue = value.toString();
  const money = stringValue.slice(0, -2);
  const coins = stringValue.slice(-2);
  const symbol = currency === 'usd' ? '$' : '£';

  return `${symbol}${money}.${coins}`;
};

export const getScoutAccess = (data) => {
  if (
    isScout(data?.userType) &&
    (!data?.scoutProfile?.expiryDate || new Date() > new Date(data?.scoutProfile?.expiryDate))
  ) {
    return false;
  } else {
    return true;
  }
};

export const transformArr = (arr) => {
  const hash = {};

  // Use forEach to iterate over arr
  arr?.forEach((item) => {
    const id = item.role._id;

    // If this ID hasn't been seen before, create a new entry in the hash
    if (!hash[id]) {
      hash[id] = { id, data: [], name: item.role.name };
    }

    // Push the current item into the 'data' object for this ID
    hash[id].data.push(item);
  });

  // Use Object.values to get all the values from hash as an array
  const transformedArr = Object.values(hash);
  console.log('🚀 ~ file: EditClubSquad.js:117 ~ transformArr ~ transformedArr:', transformedArr);
  // transformedArr.sort((a, b) => b.name - a.name);
  return transformedArr;
};

export const concertPostsFromQuery = (querySnapshot) => {
  let posts = [];
  querySnapshot.forEach((doc) => {
    posts = doc.data().isLiveStreamingPost ? posts : [...posts, { id: doc.id, ...doc.data() }];
  });

  return posts;
};

export const addAndSort = (arr, value) => {
  arr.push(value);

  return arr.sort((a, b) => {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }

    return 0;
  });
};

export const getCountryFlag = (c) =>
  c.replace(/./g, (ch) => String.fromCodePoint(0x1f1a5 + ch.toUpperCase().charCodeAt()));

export const meridiem = (hours) => {
  if (hours > 11) {
    return 'pm';
  }
  return 'am';
};

export const formatScheduleTime = (start_time, end_time) => {
  if (!start_time) return;
  if (!end_time) return;
  return `${start_time} ${meridiem(+start_time[0] + start_time[1])} - ${end_time} ${meridiem(
    +start_time[0] + start_time[1]
  )}`;
};

export const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  timer: 3000
  // timerProgressBar: true
});
