import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { any, arrayOf, bool, shape, string } from 'prop-types';
import Api from 'Api';
import { getDateDifference } from 'Utils/dates';
import { getCurrentUserId } from 'Store/authSlice';
import { UserPic } from 'Components/shared/UserPic';
import { MessageInput } from './MessageInput';
import { ReactComponent as ChatSvg } from 'Assets/icons/chat-msg.svg';
import {
  ContentContainer,
  IconContainer,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import {
  ChatContainer,
  Messages,
  MessagesList,
  Message,
  TitleRole,
  MessageInputContainer,
  SentMessage,
  SentMessageTime,
  MessageTime,
  MessageContainer
} from './components';
import { ReactComponent as More } from 'Assets/icons/More.svg';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';
import { IconContainerBg, NavBarUserContainer } from 'Components/OtherProfile/components';
import { UserName } from 'Components/shared/FollowUser/components';
import isEmpty from 'Utils/isEmpty';

export const Chat = ({ chats }) => {
  const { theme } = useContext(ThemeContext);
  const [selectedChat, setSelectedChat] = useState({});
  const messageEl = useRef(null);
  const { t } = useTranslation();
  const selectedChatId = useSelector((state) => state?.router?.location?.query?.chat_id);
  const currentUserId = useSelector(getCurrentUserId);

  useEffect(() => {
    messageEl?.current?.scroll({ top: messageEl?.current?.scrollHeight });
  }, [messageEl, selectedChat]);

  useEffect(() => {
    if (selectedChatId && chats?.length) {
      const currentChat = chats.find((chats) => chats.id === selectedChatId) || {};
      setSelectedChat(currentChat);
    }
  }, [selectedChatId, chats]);

  const onMessageSend = async (text) => {
    const { userId, id } = selectedChat;
    await Api.messages.send(id, currentUserId, userId, text);
  };

  return (
    <>
      {!isEmpty(selectedChat) && (
        <ContentContainer
          border={theme.borderShade}
          padding="10px 25px"
          borderRadius="25px"
          margin="10px 0 0"
          background={theme.background}
        >
          <NavBarUserContainer justifyContent flexWrap="wrap">
            <ContentContainer row gap="8px" alignItems="center">
              <UserPic size={50} src={selectedChat?.avatar} />
              <ContentContainer column gap="4px">
                <UserName title={selectedChat.name} weight={'600'} marginLeft="0" fontSize="1rem">
                  {selectedChat.name}
                </UserName>
                <TitleRole>{selectedChat?.role || ''}</TitleRole>
              </ContentContainer>
            </ContentContainer>
            <IconContainerBg width="12px" height="12px" background={theme.spoxioIcon2}>
              <More />
            </IconContainerBg>
          </NavBarUserContainer>
        </ContentContainer>
      )}
      <ChatContainer>
        <Messages>
          {selectedChat?.id ? (
            <ContentContainer gap="10px" padding="10px" height="100%">
              <MessagesList ref={messageEl}>
                {selectedChat?.messages?.map((message, index) => (
                  <MessageContainer key={message.id}>
                    {message.userId === currentUserId ? (
                      <ContentContainer gap="0">
                        <SentMessage isLast={message.id !== selectedChat?.messages[index + 1]?.id}>
                          {message.text}
                        </SentMessage>
                        <SentMessageTime>
                          {getDateDifference(message?.createdAt?.toDate())}
                        </SentMessageTime>
                      </ContentContainer>
                    ) : (
                      <ContentContainer gap="0">
                        <Message
                          last={index === 0}
                          isLast={message.id !== selectedChat?.messages[index + 1]?.id}
                        >
                          {message.text}
                        </Message>
                        <MessageTime>{getDateDifference(message?.createdAt?.toDate())}</MessageTime>
                      </ContentContainer>
                    )}
                  </MessageContainer>
                ))}
              </MessagesList>
              <MessageInputContainer>
                <MessageInput onSendClick={onMessageSend} />
              </MessageInputContainer>
            </ContentContainer>
          ) : (
            <ContentContainer
              column
              gap="8%"
              height="100%"
              width="100%"
              alignItems="center"
              justifyContent="center"
              background={theme.background}
              borderRadius="20px"
            >
              <IconContainer height="100%" width="100%">
                <ChatSvg />
              </IconContainer>

              <ContentContainer
                column
                gap="10px"
                width="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Title fontWeight="bold" fontSize="1.875rem">
                  Your messages
                </Title>
                <SubTitle
                  fontSize="1rem"
                  opacity="1"
                  textAlign="center"
                  color={theme.spoxioBodyText}
                >
                  Send private photos and messages to a friend.
                </SubTitle>
              </ContentContainer>
            </ContentContainer>
          )}
        </Messages>
      </ChatContainer>
    </>
  );
};

Chat.propTypes = {
  chats: arrayOf(
    shape({
      id: string,
      userId: string,
      name: string,
      avatar: string,
      role: string,
      previewMessage: string,
      messages: arrayOf(shape({})),
      isRead: bool,
      lastMessageDate: any
    })
  ),
  selectedChatId: string
};

Chat.defaultProps = {
  chats: [],
  selectedChatId: ''
};
