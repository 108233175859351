import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Api from 'Api';
import { getCurrentUser, getCurrentUserId } from 'Store/authSlice';
import { MainNavigation } from 'Components/Layouts/MainNavigation';
import { Chat } from 'Components/ChatRevamp';
import ChatLayout from 'Components/Layouts/ChatLayout';
import { array } from 'prop-types';
import { getChatsData, setChatsData } from 'Store/selectors/dataSelector';
import { Redirect } from 'react-router';
import { paths } from 'Utils/constants';
import { getScoutAccess } from 'Utils';
import { push } from 'connected-react-router';
import Auth from 'middleware/storage';

export const ChatPage = () => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(getCurrentUserId);
  const chatsData = useSelector(getChatsData);
  const currentUser = useSelector(getCurrentUser);
  const [chats, setChats] = useState([]);
  useEffect(() => {
    const scoutAccess = () => {
      if (getScoutAccess(currentUser)) return;
      dispatch(push(`${paths.SCOUT_SUBSCRIPTION}`));
      return;
    };
    return scoutAccess();
  }, []);

  useEffect(() => {
    Api.messages.getGroups(currentUserId, setChats);
  }, [currentUserId]);

  useEffect(() => {
    dispatch(setChatsData(chats));
  }, [chats]);

  useEffect(() => {
    // console.log(getScoutAccess(currentUser), currentUser, 'getScoutAccess ===> (currentUser)');
  }, [currentUser]);

  useEffect(() => {}, [chatsData]);

  return (
    <ChatLayout navTitle="Chat">
      <Chat chats={chatsData} />
    </ChatLayout>
  );
};
