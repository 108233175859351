import ProfileRight from 'Components/Layouts/ProfileLayout/ProfileRight';
import React from 'react';
import { AboutTags, DetailsPage, DetailsTag } from './components';
import NavBarUser from './NavBarUser';
import SectionTags from './SectionTags';
import { useSelector } from 'react-redux';
import { getProfileSelector } from 'Store/selectors/profileSelector';

const Scout = () => {
  const profile = useSelector(getProfileSelector);
  const { isCurrentUser, data } = profile;
  const { userType } = data;
  return (
    //   Scouting Plan
    <ProfileRight>
      {/* If not admin */}
      <NavBarUser isCurrentUser={isCurrentUser} data={data} />
      {/* if Admin */}

      {/* About Secions */}
      <DetailsPage>
        <SectionTags role={userType} />
      </DetailsPage>
    </ProfileRight>
  );
};

export default Scout;
