import React, { useContext, useEffect, useState } from 'react';
import { TalentMedia } from './components';
import Overview from './Overview';
import { ReactComponent as Info } from 'Assets/icons/info.svg';
import {
  BaseTitle,
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { shape } from 'prop-types';
import { LabelPosts } from 'Components/ProfileRevamp/components';
import { SportThumbnail } from 'Components/SportTrending/component';
import { PostMediaThumbnail } from 'Components/shared/Post/PostMediaThumbnail';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { fetchSinglePost } from 'Store/postSlice';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { paths } from 'Utils/constants';
import { UserPic } from 'Components/shared/UserPicCustom';
import { EmptyContent } from 'Components/shared/EmptyContent';
import Api2 from 'middleware/api';
import { Stats } from 'Components/Stats';
import { getIsLoading } from 'Store/loaderSlice';
import { Loader } from 'Components/shared/Loader';
import { BackButton, BottomOverlay } from 'Components/Layouts/AuthLayout/components';
import ScoutSubscription from './ScoutSubscription/ScoutSubscription';
import isEmpty from 'Utils/isEmpty';
import moment from 'moment';
import TalentList from './TalentList';
import { EventDetailsImage, EventShare } from 'Components/Event/EventDetails/components';
import { getAgeDifference } from 'Utils/dates';
import TalentStatOverview from './TalentStatOverview';
import { scoutTalent } from 'Store/profileSlice';
import { getCurrentUserId } from 'Store/authSlice';
import Api from 'Api';
import { toast } from 'react-toastify';

const PostsTalent = ({ profile }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const currentUserId = useSelector(getCurrentUserId);
  const { data } = profile;

  // console.log('Profile on profile page\n', profile);

  const isLoading = useSelector(getIsLoading);
  const [step, setStep] = useState('talent_list');
  const [talentData, setTalentData] = useState({});
  const [clubData, setClubData] = useState({});

  const getClubData = async () => {
    try {
      const res = await Api2.user.userDetailById(data?.clubMemberProfile?.clubProfile);
      console.log(res, 'clubdata');
      setClubData(res.data.data);
    } catch (e) {
      // console.log(e, e?.response, 'club data error');
    }
  };

  const goToChat = (groupId) => dispatch(push(`${paths.CHAT}?chat_id=${groupId}`));
  const sendChatMessage = () => {
    Api.messages.create([currentUserId, talentData?.user?._id], goToChat);
  };

  // console.log('data ---->', data);

  useEffect(() => {
    getClubData();
  }, [data]);

  const handleScoutTalent = () => {

    dispatch(scoutTalent(talentData?._id));
  };

  return (
    <ContentContainer column gap="8px" margin="5px auto" maxWidth="700px">
      {/* {isLoading && <Loader height="100%" background="transparent" />} */}

      {data?.userType === 'talent' || data?.userType === 'club_member' ? (
        <>
          <ContentContainer
            column
            border={theme?.borderShade}
            borderRadius="30px"
            width="100%"
            padding="20px"
          >
            <ContentContainer row alignItems="center" width="100%">
              {data?.userType === 'scout' ? (
                <BackButton mb="0" onClick={() => setStep('talent_list')} />
              ) : null}
            </ContentContainer>

            <ContentContainer column width="100%">
              <DetailHead talentData={talentData} theme={theme} profile={profile} />
            </ContentContainer>

            <Overview profile={profile} clubData={clubData} />
          </ContentContainer>
        </>
      ) : null}

      {data?.userType === 'scout' && (
        <>
          {step === 'talent_list' && (
            <>
              {(data?.scoutProfile?.subscriptionType === 'trial' &&
                data?.scoutProfile?.trialEnabled) ||
              (!isEmpty(data?.scoutProfile?.expiryDate) &&
                moment().isSameOrBefore(data?.scoutProfile?.expiryDate, 'day')) ? (
                <TalentList
                  setTalentData={(data) => setTalentData(data)}
                  showTalentOverview={() => setStep('overview')}
                />
              ) : (
                <ScoutSubscription hideLayout={true} />
              )}
            </>
          )}

          {step === 'overview' && (
            <>
              <Title width="100%" textAlign="center" fontSize="1.25rem" margin="10px 0 0">
                Manage your scout activities all in one place
              </Title>
              <ContentContainer row gap="0px" justifyContent="flex-end" alignItems="center">
                <ContentContainer row alignItems="center" width="100%">
                  {data?.userType === 'scout' ? (
                    <BackButton mb="0" onClick={() => setStep('talent_list')} />
                  ) : null}
                </ContentContainer>
              </ContentContainer>
              <ContentContainer column width="100%">
                <DetailHead talentData={talentData} profile={profile} theme={theme} />
              </ContentContainer>
              <TalentStatOverview profile={talentData} clubData={clubData} />
              {talentData?.scoutByMe ? (
                <SpoxioBlueBtn onClick={sendChatMessage}>Message</SpoxioBlueBtn>
              ) : (
                <SpoxioBlueBtn onClick={handleScoutTalent}>Scout</SpoxioBlueBtn>
              )}
            </>
          )}
        </>
      )}

      {/* Tags */}
      {/* <ButtonsTag>
        <DetailsTag>Soccer</DetailsTag>
        <DetailsTag>BasketBall</DetailsTag>
      </ButtonsTag> */}
    </ContentContainer>
  );
};

const DetailHead = (props) => {
  const { talentData, theme, profile } = props;
  const { data } = profile;
  const isScout = data?.userType === 'scout';
  // console.log(talentData, 'detail', data);
  const date = getAgeDifference(
    !isScout ? data?.talentProfile?.dateOfBirth : talentData?.dateOfBirth
  );
  return (
    <EventDetailsImage
      minHeight="40vh"
      cover={!isScout ? data?.picture : talentData?.user?.picture}
    >
      <ContentContainer
        height="100%"
        width="100%"
        padding="4px 15px"
        margin="0"
        justifyContent="flex-end"
        zIndex="99"
      >
        <ContentContainer>
          <Title
            // maxWidth="25vw"
            textOverflow="hidden"
            textTransform="ellipsis"
            style={{ overflow: 'hidden' }}
            color={theme?.defaultWhite}
          >
            {!isScout ? data?.firstName : talentData?.user?.firstName}, {date}
          </Title>
          <SubTitle fontSize="0.875rem" color={theme?.defaultWhite} textTransform="capitalize">
            {!isScout ? data?.talentProfile?.gender : talentData?.gender}
          </SubTitle>
          <SubTitle fontSize="0.875rem" color={theme?.defaultWhite} textTransform="capitalize">
            {!isScout ? data?.talentProfile?.sportPlayed?.name : talentData?.sportPlayed?.name}
          </SubTitle>
        </ContentContainer>
      </ContentContainer>
      <BottomOverlay borderRadius="0" />
    </EventDetailsImage>
  );
};

PostsTalent.propTypes = {
  profile: shape({}),
  clubData: shape({})
};

DetailHead.propTypes = {
  talentData: shape({}),
  profile: shape({}),
  theme: shape({})
};

export default PostsTalent;
