import { useEffect, useRef, useState } from 'react';
import { any, arrayOf, bool, func, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import Api2 from 'middleware/api';
import { getNotificationText } from 'Utils/strings';
import { getDateDifference } from 'Utils/dates';
import { paths } from 'Utils/constants';
import {
  NotificationContainer,
  More,
  MoreContainer,
  NotificationText,
  Actions,
  Bin,
  Silent,
  Action,
  Bell
} from './components';
import { UserPic } from 'Components/shared/UserPic';
import { NewNotificationBadge, SubTitle } from 'Components/shared/styledComponents';

export const NotificationItem = ({ notification, currentUserId, turnedOffList }) => {
  console.log(
    '\n 🚀 ~ file: NotificationItem.jsx:25 ~ NotificationItem ~ notification:',
    notification
  );
  const [showAction, setShowAction] = useState(false);
  const ref = useRef(null);

  const dispatch = useDispatch();

  const handleClickOutside = (e) => {
    e.stopPropagation();

    if (ref.current && !ref.current.contains(e.target)) {
      setShowAction(false);
      document.getElementById('notifications-overlay').style.display = 'none';
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document
      .getElementById('notifications-overlay')
      .addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const onClick = async (e) => {
    await Api2.notification.updateNotification(notification._id);

    if (notification.actionType === 'follow') {
      dispatch(push(`${paths.PROFILE}/${notification.typeRef}`));
    } else if (notification.actionType === 'create') {
      dispatch(push(`${paths.TIMELINE}/${notification.typeRef}`));
    } else {
      dispatch(push(`${paths.TIMELINE}/${notification.typeRef}`));
    }
  };

  const onMore = (e) => {
    e.stopPropagation();
    document.getElementById('notifications-overlay').style.display = 'block';

    setShowAction(true);
  };

  const onTurnOffClick = async (e) => {
    e.stopPropagation();
    // await Api.notifications.turnOff(notification.type, currentUserId, turnedOffList);

    // const turnedOffNotifications = getTurnedOffNotifications(notification.type, turnedOffList);

    // dispatch(updateUser({ turnedOffNotifications }));
    setShowAction(false);
  };

  const onDeleteClick = (e) => {
    e.stopPropagation();
    //Api.notifications.delete(notification._id);
    setShowAction(false);
  };

  return (
    <NotificationContainer onClick={onClick}>
      <NewNotificationBadge isVisible={!notification?.isRead} />
      <UserPic src={notification.from?.picture} size={32} />
      <NotificationText
        fontWeight={!notification?.isRead ? 'bold' : 'normal'}
        fontSize="1rem"
        maxWidth="auto">
        {notification?.from?.firstName}{' '}
        <SubTitle fontSize="inherit">{getNotificationText(notification?.actionType)}</SubTitle>
      </NotificationText>
      <MoreContainer onClick={onMore}>
        <More active={showAction?.toString()} />
        {getDateDifference(notification?.createdAt)}
      </MoreContainer>
      <Actions show={showAction} ref={ref}>
        <Action onClick={onDeleteClick}>
          <Bin />
          Delete Notification
        </Action>
        <Action onClick={onTurnOffClick}>
          {turnedOffList?.includes(notification?.actionType) ? (
            <>
              <Bell /> Turn-on this type of notification
            </>
          ) : (
            <>
              <Silent /> Turn-off this type of notification{' '}
            </>
          )}
        </Action>
      </Actions>
      <div id="notifications-overlay"></div>
    </NotificationContainer>
  );
};

NotificationItem.propTypes = {
  notification: shape({
    _id: string,
    type: string,
    postId: string,
    route: string,
    createdAt: any,
    isRead: bool,
    from: shape({
      id: string,
      firstName: string,
      picture: string
    })
  }).isRequired,
  currentUserId: string,
  turnedOffList: arrayOf(string),
  getNotifications: func
};

NotificationItem.defaultProps = {
  turnedOffList: [],
  getNotifications: () => {}
};
