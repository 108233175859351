import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { setSearch } from 'Store/selectors/discoverySelector';
import { NavContainer } from './components';
import { Search } from './Search';
import { NavTitle } from './NavTitle';
import { bool, node, string } from 'prop-types';
import { BackButton } from 'Components/Layouts/AuthLayout/components';
import { ContentContainer } from 'Components/shared/styledComponents';

export const NavBar = ({ title }) => {
  const query = useSelector((state) => state?.router.location.query);
  const dispatch = useDispatch();

  const searchHandle = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      dispatch(setSearch(null));
      dispatch(push(`${paths.DISCOVERY}?search=${e.target.value}`));
    }
  };

  const onGoBack = async () => {
    dispatch(goBack());
  };

  return (
    <NavContainer id="nav-bar">
      <ContentContainer row flexWrap="nowrap" gap="8px" alignItems="center">
        <NavTitle title={title} showBackButton />
      </ContentContainer>
      <Search defaultValue={query?.search} searchHandle={searchHandle} />
    </NavContainer>
  );
};

NavBar.propTypes = {
  title: node,
  arrow: bool
};

NavBar.defaultProps = {
};
