import styled from 'styled-components';

export const Sun = styled.svg`
  /* transition: all 0.5s linear; */
  transition: background 0.5s linear, color 0.5s linear;
  cursor: pointer;
`;

export const Moon = styled.svg`
  /* transition: all 0.5s linear; */
  transition: background 0.5s linear, color 0.5s linear;
  cursor: pointer;
`;
