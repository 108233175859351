import { useState } from 'react';
import { array, bool, func, oneOfType, string } from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import {
  SearchInputContainer,
  SearchInput,
  SearchIcon,
  SearchContainer,
  SearchItem,
  SearchItemsContainer
} from './components';
import { useTranslation } from 'react-i18next';

export const Search = ({ data, placeholder, isAddress, onSelect }) => {
  const [searched, setSearched] = useState(data);
  const { t } = useTranslation();
  const onChange = (e) => {
    setSearched(
      data.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()))
    );
  };

  const handleChangeAddress = (address) => {
    setSearched(address);
  };

  const handleSelectAddress = (address) => {
    geocodeByAddress(address).then(async (results) => {
      const { lat, lng } = await getLatLng(results[0]);
      onSelect({ text: address, lat, lng });
    });
  };

  return isAddress ? (
    <PlacesAutocomplete
      value={searched}
      onSelect={handleSelectAddress}
      onChange={handleChangeAddress}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <SearchContainer>
          <SearchInputContainer>
            <SearchIcon />
            <SearchInput {...getInputProps({ placeholder })} />
          </SearchInputContainer>
          {loading && <div>{t('loading')}</div>}
          {suggestions.map((suggestion) => (
            <SearchItem key={suggestion.description} {...getSuggestionItemProps(suggestion)}>
              {suggestion.description}
            </SearchItem>
          ))}
        </SearchContainer>
      )}
    </PlacesAutocomplete>
  ) : (
    <SearchContainer>
      <SearchInputContainer>
        <SearchIcon />
        <SearchInput placeholder={placeholder} onChange={onChange} />
      </SearchInputContainer>
      <SearchItemsContainer>
        {searched.map((item) => (
          <SearchItem key={item._id} onClick={() => onSelect(item)}>
            {item.name}
          </SearchItem>
        ))}
      </SearchItemsContainer>
    </SearchContainer>
  );
};

Search.propTypes = {
  data: oneOfType([array, string]),
  placeholder: string,
  isAddress: bool,
  onSelect: func
};

Search.defaultProps = {
  data: '',
  placeholder: '',
  isAddress: false,
  onSelect: () => {}
};
