import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { getIsAuthorizedSelector } from '../../Store/authSlice';
import { LandingButton } from '../shared/styledComponents';
import { useTranslation } from 'react-i18next';

export const GetStartedButton = () => {
  const isAuthorized = useSelector(getIsAuthorizedSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClick = () => {
    if (isAuthorized) {
      dispatch(push(paths.TIMELINE));
    } else {
      dispatch(push(paths.USERONBOARDING));
    }
  };

  return (
    <LandingButton className="button-get-started" onClick={onClick}>
      {t('get_started')}
    </LandingButton>
  );
};
