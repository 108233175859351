import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { paths } from 'Utils/constants';
import { getIsAuthorizedSelector } from 'Store/authSlice';
import { MobileMenu } from 'Components/Landing/MobileMenu';
import { Footer } from 'Components/Landing/Footer';
import { GetStartedButton } from 'Components/Landing/GetStartedButton';
import { AuthLink, LinkDescription } from 'Routes/Auth/components';

import HeaderLogo from 'Assets/img/HeaderLogo.png';
import AppStoreLogo from 'Assets/img/AppStore.png';
import GooglePlayLogo from 'Assets/img/GooglePlay.png';
import FirstScreenshot from 'Assets/img/Screen1.png';
import SecondScreenshot from 'Assets/img/Screen2.png';
import ThirdScreenshot from 'Assets/img/Screen3.png';
import ForthScreenshot from 'Assets/img/Screen4.png';
import { ReactComponent as PostIcon } from 'Assets/icons/Posts.svg';
import { ReactComponent as LivestreamIcon } from 'Assets/icons/Livestream.svg';
import { ReactComponent as AddStoryIcon } from 'Assets/icons/AddStory.svg';
import { ReactComponent as MessageIcon } from 'Assets/icons/Message.svg';
import { ReactComponent as SearchIcon } from 'Assets/icons/Search.svg';
import { ReactComponent as StatsIcon } from 'Assets/icons/Stats.svg';
import { ReactComponent as BadgeVerifiedIcon } from 'Assets/icons/BadgeVerified.svg';

// import { colors } from 'Theme';
import 'Styles/home.scss';
import 'Styles/main.scss';
import { AppLink } from 'Routes/MobileDetectRoute/components';
import { LandingButton } from 'Components/shared/styledComponents';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';
import { useContext } from 'react';

export const Home = () => {
  const isAuthorized = useSelector(getIsAuthorizedSelector);
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  return isAuthorized ? (
    <Redirect to={paths.TIMELINE} />
  ) : (
    <>
      <header className="header container">
        <div className="header-mobile">
          <Link to="/">
            <img className="mobile-logo" src={HeaderLogo} alt="Spoxio" />
          </Link>
          <MobileMenu />
        </div>
        <div className="header-content">
          <div className="header-left">
            <Link to="/">
              <img className="header-logo" src={HeaderLogo} alt="Spoxio" />
            </Link>
            <h1 className="header-title"> {t('connecting_sport_professionals')} </h1>
            <span className="header-text">{t('header_text')}</span>

            <div>
              <GetStartedButton />{' '}
              <a href=" https://blog.spoxio.com/" rel="noreferrer" target="_blank">
                <LandingButton>{t('visit_our_blog')} </LandingButton>
              </a>
            </div>

            <div className="header-sign-in">
              <LinkDescription style={{ color: 'white', marginRight: '7px' }}>
                {t('already_have_acct')}
              </LinkDescription>
              <AuthLink to={paths.SIGN_IN} color="#A5D1E3">
                {t('sign_in')}
              </AuthLink>
            </div>
            <div className="header-links">
              <AppLink href="https://apps.apple.com/ng/app/spoxio/id1525715823">
                <img src={AppStoreLogo} width={200} height={60} alt="App Store Link" />
              </AppLink>
              <AppLink href="https://play.google.com/store/apps/details?id=com.spoxio.app">
                <img src={GooglePlayLogo} width={200} height={60} alt="Play Market Link" />
              </AppLink>
            </div>
          </div>
          <div className="header-right">
            <img className="first-screenshot" src={FirstScreenshot} alt="Screenshot" />
            <img className="second-screenshot" src={ForthScreenshot} alt="Screenshot" />
          </div>
        </div>
      </header>
      <main className="main container" style={{ background: theme.background }}>
        <div className="main-section">
          <div className="talent-screen">
            <h2 className="main-title talent-title" style={{ color: theme.black }}>
              {t('for_sport_talents')}
            </h2>
            <div className="main-image-set">
              <img className="talent-screenshot" src={SecondScreenshot} alt="Screenshot" />
              <div className="main-blue-block blue-block-right"></div>
            </div>
          </div>
          <div className="main-description">
            <span className="main-text" style={{ color: theme.spoxioText }}>
              Spoxio {t('main_text_home')}
            </span>
            <ul className="main-list">
              <li className="main-list-item">
                <PostIcon className="list-icon" color={theme.spoxioBlue} />
                <span className="main-list-text" style={{ color: theme.spoxioText }}>
                  <b>{t('post')}</b> {t('photo_and_video_updates')}
                </span>
              </li>
              <li className="main-list-item">
                <LivestreamIcon
                  className="list-icon"
                  color={theme.spoxioBlue}
                  stroke={theme.spoxioBlue}
                />
                <span className="main-list-text" style={{ color: theme.spoxioText }}>
                  <b>{t('livestream')}</b> {t('your_trainings_and_events')}
                </span>
              </li>
              <li className="main-list-item" style={{ color: theme.spoxioText }}>
                <AddStoryIcon
                  className="list-icon"
                  color={theme.spoxioBlue}
                  stroke={theme.spoxioBlue}
                />
                <span className="main-list-text">
                  <b>{t('Stories')}</b> {t('for_quick_updates')}
                </span>
              </li>
              <li className="main-list-item" style={{ color: theme.spoxioText }}>
                <MessageIcon
                  className="list-icon"
                  color={theme.spoxioBlue}
                  // stroke={theme.spoxioBlue}
                />
                <span className="main-list-text" style={{ color: theme.spoxioText }}>
                  <b>{t('chat')}</b> {t('with_verified_scouts_and_other_talents')}
                </span>
              </li>
            </ul>
            <GetStartedButton />
          </div>
        </div>
        <div className="main-section scout-section">
          <div className="main-description">
            <span className="main-text" style={{ color: theme.spoxioText }}>
              {t('main_text_home2')}
            </span>
            <ul className="main-list">
              <li className="main-list-item" style={{ color: theme.spoxioText }}>
                <SearchIcon className="list-icon" color={theme.spoxioBlue} />
                <span className="main-list-text">
                  <b> {t('discover')} </b> {t('talent')}
                </span>
              </li>
              <li className="main-list-item" style={{ color: theme.spoxioText }}>
                <MessageIcon className="list-icon" color={theme.spoxioBlue} />
                <span className="main-list-text">
                  <b>{t('direct')}</b> {t('or')} <b>{t('bulk')}</b> {t('message_talents')}
                </span>
              </li>
              <li className="main-list-item" style={{ color: theme.spoxioText }}>
                <StatsIcon className="list-icon" color={theme.spoxioBlue} />
                <span className="main-list-text">
                  {t('access')} {t('to')} <b>{t('exclusive_stats')}</b>
                </span>
              </li>
              <li className="main-list-item" style={{ color: theme.spoxioText }}>
                <BadgeVerifiedIcon className="list-icon" color={theme.spoxioBlue} />
                <span className="main-list-text">
                  {t('get')} {t('a')} <b>{t('verifile_profile')}</b>
                </span>
              </li>
            </ul>
            <GetStartedButton />
          </div>
          <div className="scout-screen">
            <h2 className="main-title" style={{ color: theme.black }}>
              {t('for_scouts')}
            </h2>
            <div className="main-image-set">
              <div className="main-blue-block blue-block-left"></div>
              <img className="scout-screenshot" src={ThirdScreenshot} alt="Screenshot" />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
