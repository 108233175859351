import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';
import { isMobile } from 'react-device-detect';

import { paths, userRoles } from 'Utils/constants';
import { emailRules, passwordRules } from 'Utils/validations';
import { signIn } from 'Store/authSlice';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { BaseButton, ContentContainer, SpoxioBlueBtn } from 'Components/shared/styledComponents';
import {
  AuthFormContainer,
  AuthLink,
  BottomLinkContainer,
  ForgotPasswordLink,
  LinkDescription
} from '../components';
import { SocialAuth } from 'Components/SocialAuth';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from 'Components/Layouts/AuthLayout';
import { Title } from 'Components/Layouts/AuthLayout/components';

export const SignInPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const res = await dispatch(signIn(data));
    if (res) {
      const role = localStorage.getItem('type');
      if (isMobile) {
        dispatch(push(paths.MOBILE_SCREEN));
        return;
      }
      if (role === userRoles.DEFAULT) {
        dispatch(push(paths.SIGN_UP_ROLE));
        return;
      }
      role === userRoles.SCOUT && localStorage.setItem('checkScoutStatus', 'false');
      dispatch(push(paths.TIMELINE));
    }
  };

  return (
    <AuthLayout
      title={
        <Title fontSize="1.75rem" fontWeight="bold">
          Hi,
          <br /> Welcome Back!
        </Title>
      }
      showBackButton
    >
      <div className="margin-top_medium" style={{ width: '100%' }}>
        <Title fontSize="1.2rem" fontWeight="bold">
          Login to continue
        </Title>
      </div>

      <AuthFormContainer onSubmit={handleSubmit(onSubmit)} marginTop={'1rem'}>
        {/* <Col lg={20} md={20} sm={24}> */}
        <FormInput
          ref={register(emailRules)}
          name="username"
          placeholder={t('Your email')}
          error={errors?.email?.message}
        />
        <FormInput
          ref={register(passwordRules)}
          name="password"
          type="password"
          placeholder={t('Your password')}
          error={errors?.password?.message}
          isPassword
        />
        <ContentContainer row width="100%" justifyContent="flex-end">
          <ForgotPasswordLink to={paths.FORGOT_PASSWORD}>
            {t('forgot_my_password')}
          </ForgotPasswordLink>
        </ContentContainer>
        <SpoxioBlueBtn className="margin-top_small">{t('login')}</SpoxioBlueBtn>

        <div
          style={{
            position: 'absolute',
            bottom: '0',
            margin: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
            width: '100%'
          }}
        >
          <SocialAuth marginTop="0" title={t('Or continue using')} />
          <span
          // style={{ marginTop: '10px' }}
          >
            <LinkDescription>Don't have an account?</LinkDescription>{' '}
            <AuthLink textweight={'normal'} to={paths.SIGN_UP} color="#007AFF">
              SIGN UP
            </AuthLink>
          </span>
        </div>
        {/* </Col> */}
      </AuthFormContainer>

      {/* <BottomLinkContainer>
          <LinkDescription>{t('doesnt_have_account')}</LinkDescription>
          <AuthLink to={paths.SIGN_UP}>{t('signup')}</AuthLink>
        </BottomLinkContainer> */}
    </AuthLayout>
  );
};
