import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  list: []
};

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setStatsList: (state, action) => {
      state.list = action.payload || [];
    }
  }
});

// actions
export const { setStatsList } = statsSlice.actions;

// reducer
export const statsReducer = statsSlice.reducer;

// selectors
export const getStatsSelector = (state) => state?.stats?.list;
