import { ContentContainer, IconContainer, Title } from 'Components/shared/styledComponents';
import { bool, object, string } from 'prop-types';
import React, { Fragment } from 'react';
import { getCountryFlag } from 'Utils';
import { getAgeDifference } from 'Utils/dates';
import isEmpty from 'Utils/isEmpty';
import { truncate } from 'Utils/truncate';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Verified } from 'Assets/icons/Verified.svg';

const UserInfo = ({ data, maxWidth, fontSize }) => {


  // console.log(data, 'data on userinfo')
  return (
    <>
      {data?.userType === 'fan' && (
        <ContentContainer row alignItems="center" flexWrap="wrap" minWidth="100px">
          <Title
            fontSize={fontSize}
            opacity="0.5"
            lineHeight="normal"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: maxWidth
            }}
          >
            Loves{' '}
            {data?.sports?.map((sport, i) => {
              if (i === data.sports.length - 1) {
                return <Fragment key={uuidv4()}>{sport?.name}... </Fragment>;
              }
              return <Fragment key={uuidv4()}>{sport?.name}, </Fragment>;
            })}
          </Title>
        </ContentContainer>
      )}

      {data?.userType === 'talent' && (
        <ContentContainer row alignItems="center" flexWrap="wrap" minWidth="120px">
          <Title fontSize={fontSize} opacity="0.5" lineHeight="normal">
            {getAgeDifference(data?.talentProfile?.dateOfBirth)} | Origin:
          </Title>
          <Title
            fontSize={fontSize}
            opacity="0.8"
            lineHeight="normal"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: maxWidth
            }}
          >
            {data?.country?.name}
          </Title>
          <span>{!isEmpty(data?.country?.iso2) && getCountryFlag(data?.country?.iso2)}</span>
        </ContentContainer>
      )}

      {data?.userType === 'club' && (
        <ContentContainer row alignItems="center" flexWrap="wrap" minWidth="120px">
          <Title
            fontSize={fontSize}
            opacity="0.5"
            lineHeight="normal"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: maxWidth
            }}
          >
            {data?.clubProfile?.name}, {data?.clubProfile?.address}.{' '}
            <span>{!isEmpty(data?.country?.iso2) && getCountryFlag(data?.country?.iso2)}</span>
          </Title>
        </ContentContainer>
      )}

      {data?.userType === 'scout' && (
        <ContentContainer row alignItems="center" flexWrap="wrap" minWidth="120px">
          {data?.isVerified && (
            <>
              <IconContainer margin="0" width="16px" height="16px">
                <Verified />
              </IconContainer>
              <Title
                fontWeight="700"
                opacity="0.5"
                fontSize={fontSize}
                lineHeight="normal"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: maxWidth
                }}
              >
                Verified
              </Title>
            </>
          )}
          <Title
            fontSize={fontSize}
            opacity="0.5"
            lineHeight="normal"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: maxWidth
            }}
          >
            {data?.scoutProfile?.club} Scout - {data?.scoutProfile?.organisation}
          </Title>
        </ContentContainer>
      )}

      {data?.userType === 'club_member' && (
        <ContentContainer column minWidth="120px" gap="0">
          <ContentContainer row alignItems="center" flexWrap="nowrap">
            <Title fontSize={fontSize} opacity="0.5" lineHeight="normal">
              {data?.clubMemberProfile?.age} | Origin:
            </Title>
            <Title
              fontSize={fontSize}
              opacity="0.8"
              lineHeight="normal"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: maxWidth
              }}
            >
              {data?.country?.name}
            </Title>
            <span>{!isEmpty(data?.country?.iso2) && getCountryFlag(data?.country?.iso2)}</span>
          </ContentContainer>
          <ContentContainer row alignItems="center">
            <span
              style={{
                background: '#19181B',
                color: '#fff',
                padding: '5px 8px',
                borderRadius: '5px',
                fontSize: '0.65rem'
              }}
            >
              {data?.clubMemberProfile?.role?.name}
            </span>
            <Title
              fontSize={fontSize}
              opacity="0.5"
              lineHeight="normal"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: maxWidth
              }}
            >
              at Sport Club
            </Title>
          </ContentContainer>
        </ContentContainer>
      )}
    </>
  );
};

UserInfo.propTypes = {
  isCurrentUser: bool,
  maxWidth: string,
  data: object,
  fontSize: string
};
UserInfo.defaultProps = {
  maxWidth: '150px',
  fontSize: '0.75rem'
};
export default UserInfo;
