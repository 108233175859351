import { Fragment, useEffect, useState } from 'react';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';

import { Tab, TabHead, TabContent } from './components';
import { ContentContainer } from '../styledComponents';

export const Tabs = ({
  tabs,
  defaultTab,
  handleChangeTab,
  activeBorderColor,
  inactiveBorderColor,
  tabColorOpacity,
  tabGap,
  background,
  containerMaxHeight,
  height,
  overflowY,
  reverse
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);

  const onTabClick = (id) => {
    setSelectedTab(id);
    handleChangeTab(id);
  };

  return (
    <ContentContainer margin="0" padding="0" flexDirection={reverse ? 'column-reverse' : 'column'}>
      <TabHead gap={tabGap}>
        {tabs.map((item, index) => (
          <Tab
            background={background}
            opacity={tabColorOpacity}
            activeBorderColor={activeBorderColor}
            inactiveBorderColor={inactiveBorderColor}
            key={`${item.id}-${index}`}
            selected={item.id === selectedTab}
            onClick={() => onTabClick(item.id)}
          >
            {item.label}
          </Tab>
        ))}
      </TabHead>
      {tabs.map((item) =>
        item.id === selectedTab ? (
          <ContentContainer
            hideScroll
            height={height}
            overflowY={overflowY}
            maxHeight={containerMaxHeight}
            key={item.id}
          >
            {item?.component}
          </ContentContainer>
        ) : null
      )}
    </ContentContainer>
  );
};

Tabs.propTypes = {
  tabs: arrayOf(
    shape({
      id: string,
      label: string,
      component: node
    })
  ).isRequired,
  defaultTab: string,
  handleChangeTab: func,
  activeBorderColor: string,
  inactiveBorderColor: string,
  tabGap: string,
  tabColorOpacity: string,
  background: string,
  containerMaxHeight: string,
  height: string,
  overflowY: string,
  reverse: bool
};

Tabs.defaultProps = {
  defaultTab: '',
  reverse: false,
  handleChangeTab: () => {}
};
