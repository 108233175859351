import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isEventLoading: false,
  isNotificationLoading: false
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setisEventLoading: (state, action) => {
      state.isEventLoading = action.payload;
    },
    setisNotificationLoading: (state, action) => {
      state.isNotificationLoading = action.payload;
    }
  }
});

// actions
export const { setLoading, setisEventLoading, setisNotificationLoading } = loaderSlice.actions;

// reducer
export const loaderReducer = loaderSlice.reducer;

// selectors
export const getIsLoading = (state) => state?.loader?.isLoading;
export const getIsEventLoading = (state) => state?.loader?.isEventLoading;
export const getIsNotificationLoading = (state) => state?.loader?.isNotificationLoading;
