import { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

/* eslint-disable react/prop-types */
const ScrollToTopComponent = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};

export const ScrollToTop = withRouter(ScrollToTopComponent);
