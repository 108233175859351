import styled from 'styled-components';

export const ResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  min-height: calc(100vh - 110px);
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 540px;
`;

export const ResultUsersContainer = styled.div`
  max-height: 500px;
  max-width: 300px;
  width: 100%;
  overflow-y: auto;
`;

export const ResultTopicsContainer = styled.div`
  width: 100%;
  max-height: 500px;
  padding-bottom: 30px;
  overflow-y: auto;
`;
