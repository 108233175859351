/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react';
import Auth from 'middleware/storage';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
import {
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { ThemeContext } from 'Context/themeContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'Store/authSlice';
import {
  addClubRole,
  deleteClubRole,
  fetchClubMembers,
  fetchAllClubs,
  fetchClubRoles
} from 'Store/clubSlice';
import { requiredRule } from 'Utils/validations';
import { ReactComponent as PlusIcon } from 'Assets/icons/AddIcon.svg';
import { Loader } from 'Components/shared/Loader';
import { getClub } from 'Store/selectors/clubSelector';
import { CloseIconX } from 'Components/PostModal/components';
import Swal from 'sweetalert2';
import SpoxioModal from 'Components/Modals/SpoxioModal';
import AddTeamMember from './AddTeamMember';
import isEmpty from 'Utils/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { UserPic } from 'Components/shared/UserPicCustom';
import EditTeamMember from './EditTeamMember';
import { More } from 'Components/Navigation/NavBar/components';
import { EditIcon } from 'Components/ProfileRevamp/components';

const EditClubSquad = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const profile = useSelector(getCurrentUser);
  const [roleLoading, setRoleLoading] = useState(false);
  const [clubRoleLoading, setClubRoleLoading] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [editMemberModal, setEditMemberModal] = useState(false);
  const [delRoleLoading, setDelRoleLoading] = useState(false);
  const [selectedRoleId, setRoleId] = useState('');
  const [selectedClubMember, setSelectedClubMember] = useState({});
  const [selectedMemberRole, setSelectedMemberRole] = useState({});
  const [membersLoading, setMemberLoading] = useState(false);
  const [members, setMembers] = useState([]);

  const club = useSelector(getClub);
  const clubInfo = profile?.clubProfile;

  const handleEditClubPlayer = (data) => {
    setSelectedClubMember(data);
    setEditMemberModal(true);
  };

  const fetchRoles = async () => {
    setClubRoleLoading(true);
    await dispatch(fetchClubRoles(clubInfo._id));
    setClubRoleLoading(false);
  };

  useEffect(() => {
    Auth.fetchUser();
    dispatch(fetchAllClubs());
    fetchRoles();
    fetchAndSaveMembers();
    return () => {
      Auth.fetchUser();
      fetchAllClubs();
      fetchRoles();
      fetchAndSaveMembers();
    };
  }, [clubInfo?._id, dispatch]);

  const transformArr = (arr) => {
    const hash = {};

    // Use forEach to iterate over arr
    arr?.forEach((item) => {
      const id = item.role._id;

      // If this ID hasn't been seen before, create a new entry in the hash
      if (!hash[id]) {
        hash[id] = { id, data: [], name: item.role.name };
      }

      // Push the current item into the 'data' object for this ID
      hash[id].data.push(item);
    });

    // Use Object.values to get all the values from hash as an array
    const transformedArr = Object.values(hash);
    console.log('🚀 ~ file: EditClubSquad.js:117 ~ transformArr ~ transformedArr:', transformedArr);
    // transformedArr.sort((a, b) => b.name - a.name);
    return transformedArr;
  };

  const fetchAndSaveMembers = async () => {
    setMemberLoading(true);
    const result = await dispatch(fetchClubMembers(clubInfo._id));
    let members = transformArr(result);
    setMembers(members);
    setMemberLoading(false);
  };

  const { register, reset, errors, handleSubmit } = useForm();

  const { register: register2, control, errors: errors2, handleSubmit: handleSubmit2 } = useForm();

  const submitRole = async (values) => {
    setRoleLoading(true);
    const data = { name: values?.name, tag: '' };
    console.log(data, 'submit role values');
    const response = await dispatch(addClubRole(clubInfo?._id, data));
    if (response) {
      dispatch(fetchClubRoles(clubInfo._id));
      reset({ name: '' });
    }
    setRoleLoading(false);
  };

  const handleSubmitRole = (event) => {
    event.preventDefault();
    handleSubmit(async (data) => {
      submitRole(data);
    })();
  };

  const onClickSelectRole = (event) => {
    event.preventDefault();
    handleSubmit2(async (data) => {
      if (isEmpty(data.role)) {
        return;
      }
      setSelectedMemberRole(data.role);
      setAddMemberModal(true);
    })();
  };

  const removeRole = async (roleId) => {
    setDelRoleLoading(true);
    setRoleId(roleId);
    const response = await dispatch(deleteClubRole(clubInfo._id, roleId));
    if (response) {
      dispatch(fetchClubRoles(clubInfo._id));
    }
    setDelRoleLoading(false);
    setRoleId('');
  };

  const confirmDelete = (data) => {
    Swal.fire({
      title: `Delete Role / Position ?`,
      text: `You are about to delete ${data?.name}`,
      showCancelButton: true,
      confirmButtonColor: theme?.spoxioAltBlue,
      cancelButtonColor: theme?.spoxioRed,
      confirmButtonText: 'Proceed',
      reverseButtons: true,
      imageUrl: data?.url,
      imageWidth: 200,
      imageHeight: 150,
      imageAlt: ''
    }).then((result) => {
      if (result.isConfirmed) {
        removeRole(data?._id);
      }
    });
  };

  const clubRolesData = club?.clubRoles?.map((item) => {
    return {
      label: item.name,
      value: item._id
    };
  });

  return (
    <ContentContainer width="100%" gap="1rem">
      <ContentContainer background={theme?.background} padding="0.875rem" borderRadius="12px">
        <ContentContainer row gap="20px" width="100%" alignItems="center" margin="0 0 0.875rem">
          <FormInput
            ref={register(requiredRule)}
            name="name"
            rules={{
              required: 'Add role / position'
            }}
            placeholder={t('Add role / position')}
            error={errors?.name?.message}
            marginBottom="0"
          />
          <SpoxioBlueBtn
            disabled={roleLoading}
            hover
            width="10%"
            marginTop="0"
            fontSize="1.2rem"
            background={theme?.spoxioLightBlue}
            padding="14px"
            height="100%"
            onClick={handleSubmitRole}>
            {roleLoading ? (
              <Loader size={20} background="transparent" padding="0" position="relative" />
            ) : (
              <IconContainer
                fill={theme?.spoxioBlue}
                height="16px"
                width="16px"
                margin="0"
                style={{ justifyContent: 'center' }}>
                <PlusIcon />
              </IconContainer>
            )}
          </SpoxioBlueBtn>
        </ContentContainer>
        <ContentContainer row gap="20px" flexWrap="wrap" alignItems="center">
          {clubRoleLoading ? (
            <ContentContainer row justifyContent="center" width="100%" margin="2% auto">
              <Loader size={20} background="transparent" padding="0" position="relative" />
            </ContentContainer>
          ) : (
            club.clubRoles &&
            club.clubRoles.map((role) => (
              <ContentContainer
                hover
                key={role._id}
                row
                gap="12px"
                padding="10px 8px"
                borderRadius="8px"
                background={theme?.white}
                alignItems="center"
                cursor="pointer"
                minWidth="30px">
                {delRoleLoading && selectedRoleId === role._id ? (
                  <Loader size={16} background="transparent" padding="0" position="relative" />
                ) : (
                  <>
                    <IconContainer
                      width="12px"
                      height="12px"
                      margin="0"
                      style={{ justifyContent: 'center' }}
                      onClick={() => confirmDelete(role)}>
                      <CloseIconX name="close" color="#E4E6EB" size={18} />
                    </IconContainer>
                    <SubTitle fontSize="1rem" textTransform="uppercase">
                      {role.name}
                    </SubTitle>
                  </>
                )}
              </ContentContainer>
            ))
          )}
        </ContentContainer>
      </ContentContainer>

      <ContentContainer
        // background={theme?.background}
        // padding="0.875rem"
        borderRadius="8px">
        <ContentContainer row gap="20px" width="100%" margin="0 0 0.875rem">
          <FormSelect
            control={control}
            rules={{
              required: 'Select a role'
            }}
            ref={register2(requiredRule)}
            name="role"
            options={clubRolesData || []}
            placeholder={'Role'}
            isSearchable
            error={errors2?.role?.message}
          />

          <SpoxioBlueBtn
            hover
            width="45%"
            marginTop="0"
            background={theme?.spoxioLightBlue}
            padding="10px"
            height="100%"
            maxHeight="50px"
            onClick={onClickSelectRole}>
            <Title fontSize="0.75rem" color={theme?.spoxioBlue}>
              Add new team member
            </Title>
          </SpoxioBlueBtn>
        </ContentContainer>

        <ContentContainer row gap="20px" flexWrap="wrap" alignItems="center">
          {membersLoading ? (
            <ContentContainer row justifyContent="center" width="100%" margin="2% auto">
              <Loader size={20} background="transparent" padding="0" position="relative" />
            </ContentContainer>
          ) : (
            <>
              {!isEmpty(members)
                ? members?.map((category) => {
                    return (
                      <ContentContainer
                        key={uuidv4()}
                        column
                        cursor="pointer"
                        gap="4px"
                        width="100%"
                        maxWidth="650px"
                        // onClick={() => onUserClick(data?.id)}
                      >
                        <Title
                          textTransform="uppercase"
                          fontSize="1.25rem"
                          lineHeight="normal"
                          opacity="0.5">
                          {category.name}
                        </Title>

                        <ContentContainer
                          row
                          width="100%"
                          alignItems="center"
                          flexWrap="nowrap"
                          style={{ overflowX: 'auto' }}>
                          {category?.data?.map((player) => {
                            console.log("\n 🚀 ~ file: EditClubSquad.js:333 ~ {category?.data?.map ~ player:", player)
                            return (
                              <ContentContainer
                                key={uuidv4()}
                                column
                                cursor="pointer"
                                gap="4px"
                                width="100%"
                                height="200px"
                                minWidth="180px"
                                maxWidth="180px"
                                closeModal

                                // onClick={() => onUserClick(data?.id)}
                              >
                                <ContentContainer
                                  height="100%"
                                  width="100%"
                                  style={{ position: 'relative' }}>
                                  <IconContainer
                                    hover
                                    opacity="0.85"
                                    width="12px"
                                    height="16px"
                                    margin="0"
                                    padding="6px"
                                    background="#fff"
                                    border={theme?.spoxioRed}
                                    borderRadius="6px"
                                    fill={theme?.spoxioRed}
                                    zIndex="99"
                                    style={{
                                      justifyContent: 'flex-end',
                                      position: 'absolute',
                                      top: '10px',
                                      right: '10px'
                                    }}
                                    onClick={() => handleEditClubPlayer(player)}>
                                    <EditIcon name="Edit" size={24} style={{ marginRight: '0' }} />
                                  </IconContainer>
                                  <UserPic src={player?.picture} size="100%" />
                                  <Title fontSize="0.875rem" lineHeight="normal" textAlign="center">
                                    {player?.user.firstName} {player?.user.lastName}
                                  </Title>
                                  <Title fontSize="0.75rem" lineHeight="normal" textAlign="center">
                                  <SubTitle fontSize="inherit" lineHeight="normal">
                                  Origin:{' '}
                                  </SubTitle>{' '}
                                  {player?.user?.country}
                                </Title>
                                </ContentContainer>
                              </ContentContainer>
                            );
                          })}
                        </ContentContainer>
                      </ContentContainer>
                    );
                  })
                : null}
            </>
          )}
        </ContentContainer>
      </ContentContainer>

      <SpoxioModal
        showCloseButton
        visible={addMemberModal}
        width="100%"
        height="100%"
        padding="25px"
        minWidth="50vw"
        maxWidth="50vw"
        borderRadius="12px"
        setVisible={() => setAddMemberModal(false)}
        minHeight="90vh"
        maxHeight="90vh"
        closeIconStyle={{ left: '10px', right: '0' }}>
        <AddTeamMember
          clubRoles={clubRolesData}
          selectedRole={selectedMemberRole}
          clubInfo={clubInfo}
          closeModal={() => setAddMemberModal(false)}
        />
      </SpoxioModal>
      <SpoxioModal
        showCloseButton
        visible={editMemberModal}
        width="100%"
        height="100%"
        padding="25px"
        minWidth="50vw"
        maxWidth="50vw"
        borderRadius="12px"
        setVisible={() => setEditMemberModal(false)}
        minHeight="90vh"
        maxHeight="90vh"
        closeIconStyle={{ left: '10px', right: '0' }}>
        <EditTeamMember
          clubRoles={clubRolesData}
          selectedClubMember={selectedClubMember}
          clubInfo={clubInfo}
          closeModal={() => setEditMemberModal(false)}
        />
      </SpoxioModal>
    </ContentContainer>
  );
};

export default EditClubSquad;
