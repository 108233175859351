import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bool, func, node, number, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { getFollowingData } from 'Store/profileSlice';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import { DetailsPage } from 'Components/OtherProfile/components';
import EventsRight from 'Components/Event/CreateEvent/EventsRight';
import CreatEventStep from 'Components/Event/CreateEvent/CreatEventStep';
import EventSucessPage from 'Components/Event/CreateEvent/EventSucessPage';
import {
  CenterContainer,
  InnerCenterContainer,
  LeftContainer,
  MainContainer,
  RightContainer
} from '../MainNavigation/components';
import { LeftBar } from '../MainNavigation/LeftBar';
import { NavBar } from '../MainNavigation/NavBar';
import { RightBarContainer } from '../MainNavigation/RightBar/components';
import { setSearch } from 'Store/selectors/discoverySelector';
import { push } from 'connected-react-router';
import { paths } from 'Utils/constants';

const EventsLayout = ({
  children,
  navTitle,
  showBackButton,
  showNav,
  eventsRightContent,
  setStep,
  border,
  hideCreateImage,
  step
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFollowingData());
  }, []);
  const profile = useSelector(getProfileSelector);

  const searchHandle = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      dispatch(setSearch(null));
      dispatch(push(`${paths.EVENTS}?search=${e.target.value}`));
    }
  };

  return (
    <MainContainer>
      <LeftContainer>
        <LeftBar />
      </LeftContainer>
      <CenterContainer>
        {showNav && (
          <NavBar
            title={navTitle || 'Sport Event'}
            showBackButton={showBackButton}
            handleSearch={searchHandle}
          />
        )}
        <InnerCenterContainer>{children}</InnerCenterContainer>
      </CenterContainer>
      <RightContainer>
        <RightBarContainer border="transparent" padding="0" b>
          <DetailsPage mt="0" mh="100%" border={border}>
            {eventsRightContent || (
              <>
                {step === 1 && <EventsRight setStep={setStep} hideCreateImage={hideCreateImage} />}
                {step === 2 && <CreatEventStep setStep={setStep} />}
                {step === 3 && <EventSucessPage setStep={setStep} />}
              </>
            )}
          </DetailsPage>
        </RightBarContainer>
      </RightContainer>
    </MainContainer>
  );
};

EventsLayout.propTypes = {
  children: node.isRequired,
  navTitle: node,
  showBackButton: bool,
  showNav: bool,
  eventsRightContent: node,
  setStep: func,
  step: number,
  border: string,
  hideCreateImage: bool
};

EventsLayout.defaultProps = {
  showNav: true,
  navTitle: '',
  setStep: null,
  step: 1,
  hideCreateImage: false
};

export default EventsLayout;
