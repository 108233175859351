import { BlackText } from 'Components/shared/styledComponents';
import { func, number, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SearchItemContainer, PostsCount } from './components';

export const SearchItem = ({ name, count, onClick }) => {
  const { t } = useTranslation();
  return (
    <SearchItemContainer onClick={onClick}>
      <BlackText>{name}</BlackText>
      <PostsCount>
        <span>{count}</span>
        <span>{count > 1 ? t('posts') : 'post'}</span>
      </PostsCount>
    </SearchItemContainer>
  );
};

SearchItem.propTypes = {
  name: string.isRequired,
  count: number.isRequired,
  onClick: func.isRequired
};
