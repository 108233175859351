import { func, number, shape, string } from 'prop-types';

import { MostPopularContainer } from './components';

import { ReactComponent as Football } from 'Assets/icons/FootballIcon.svg';
import { ReactComponent as Golf } from 'Assets/icons/Golf.svg';
import { ReactComponent as AmericanFootball } from 'Assets/icons/AmericanFootball.svg';
import { ReactComponent as Baseball } from 'Assets/icons/Baseball.svg';
import { ReactComponent as Basketball } from 'Assets/icons/Basketball.svg';
import { ReactComponent as Boxing } from 'Assets/icons/boxing-icon.svg';
import { ReactComponent as Flag } from 'Assets/icons/Flag.svg';
import { Title } from 'Components/Layouts/AuthLayout/components';
import { IconContainer } from 'Components/shared/styledComponents';

const colors = ['orange', 'yellow', 'blue', 'purple'];

const getIcon = (name) => {
  const icons = {
    Football: Football,
    Basketball: Basketball,
    Boxing: Boxing,
    Golf: Golf,
    'American Football': AmericanFootball,
    Baseball: Baseball
  };

  return icons[name] || Flag;
};

export const MostPopular = ({ sport, index, onClick, color }) => {
  const Icon = getIcon(sport.name);
  // console.log('the sport', sport);
  return (
    <div
      style={{
        backgroundColor: color.color,
        width: `170px`,
        border: `1px solid ${({ theme }) => theme.lightBlue}`,
        padding: `20px`,
        marginBottom: `10px`,
        borderRadius: `20px`,
        flexShrink: 0
      }}
    >
      <MostPopularContainer onClick={onClick}>
        {/* Background color */}
        <IconContainer color={colors[index]} margin="0">
          <Icon />
        </IconContainer>
        <Title color="#fff" fontSize='0.875rem'>{sport.name}</Title>
      </MostPopularContainer>
    </div>
  );
};

MostPopular.propTypes = {
  sport: shape({
    id: string,
    name: string,
    icon: string
  }).isRequired,
  index: number.isRequired,
  onClick: func.isRequired,
  color: shape({ color: string })
};
