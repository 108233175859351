import React, { useContext, useRef, useState } from 'react';
import { TagDetailsInput, TagDetailsWrapper } from './components';
import CloseTopic from 'Assets/icons/close-topic.svg';
import PropTypes from 'prop-types';
import { ThemeContext } from 'Context/themeContext';

const TopicCard = ({ data }) => {
  const [checked, setChecked] = useState(false);
  const [showTag, setShowTag] = useState(true);
  const [showSport, setShowSport] = useState(true);
  const imgRef = useRef();
  const hideElement = () => {
    setShowSport(false);
  };
  const checkHandler = () => {
    setChecked((prev) => !prev);
    setShowTag(checked);
  };
  const { theme } = useContext(ThemeContext);
  return showSport ? (
    <TagDetailsWrapper ref={imgRef} isSelected={checked}>
      {showTag && (
        <img
          style={{ cursor: 'pointer' }}
          onClick={hideElement}
          src={CloseTopic}
          alt="Close-Topic"
        />
      )}
      <div style={{ display: 'flex', alignItems: 'center', color: theme?.gray, columnGap: '5px' }}>
        <TagDetailsInput checked={checked} onClick={checkHandler} type={'radio'} />
        {data}
      </div>
    </TagDetailsWrapper>
  ) : null;
};

TopicCard.propTypes = {
  data: PropTypes.string
};

export default TopicCard;
