import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  liveStreamsList: []
};

export const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setStoriesList: (state, action) => {
      state.list = action.payload || [];
    },
    setLiveStreamsList: (state, action) => {
      state.liveStreamsList = action.payload || [];
    }
  }
});

// actions
export const { setStoriesList, setLiveStreamsList } = storiesSlice.actions;

// reducer
export const storiesReducer = storiesSlice.reducer;

// selectors
export const getStoriesSelector = (state) => state?.stories?.list;
export const getLiveStreamSelector = (state) => state?.stories?.liveStreamsList;
