import { ButtonsTag, DetailsTag } from 'Components/OtherProfile/components';
import {
  RecordsContainer,
  RecordsTextAns,
  RecordsTextTag
} from 'Components/OtherProfile/Player/components';
import React, { useContext, useEffect, useState } from 'react';
import { ContentContainer } from 'Components/shared/styledComponents';
import { shape } from 'prop-types';
import { ThemeContext } from 'Context/themeContext';
import { useDispatch, useSelector } from 'react-redux';
import { getFetchedScoutedTalents } from 'Store/selectors/profileSelector';
import { fetchScoutedTalents } from 'Store/profileSlice';
import { ScoutedTalents } from './ScoutedTalents';
import { ScoutStatsContainer } from './components';

const ScoutDetails = ({ profile, clubData }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const scoutedTalents = useSelector(getFetchedScoutedTalents);
  // console.log(
  //   '\n\n\r Scouted Talents',
  //   scoutedTalents,
  // '\n\n\rscout details',
  // profile,
  // '\n Club data',
  // clubData
  // );
  const { data } = profile;
  const [tag, setTag] = useState(1);

  useEffect(() => {
    dispatch(fetchScoutedTalents());
  }, [dispatch]);
  return (
    <ContentContainer maxWidth="20vw" column gap="12px">
      <ButtonsTag>
        <DetailsTag active={tag === 1} onClick={() => setTag(1)}>
          Summary
        </DetailsTag>
        <DetailsTag active={tag === 2} onClick={() => setTag(2)}>
          Scouted
        </DetailsTag>
      </ButtonsTag>

      {/* Container for scout */}
      {tag === 1 && (
        <>
          <ScoutStatsContainer>
            <RecordsContainer h={'10px'} w="150px">
              <RecordsTextTag fontSize="1rem">Scouted</RecordsTextTag>
              <RecordsTextAns>{data?.scoutProfile?.talentsOfInterest?.length || 0}</RecordsTextAns>
            </RecordsContainer>
            <RecordsContainer h={'80px'} w="150px">
              <RecordsTextTag fontSize="1rem">My Struck</RecordsTextTag>
              <RecordsTextAns>{profile?.posts?.length || 0}</RecordsTextAns>
            </RecordsContainer>
            <RecordsContainer h={'10px'} w="150px">
              <RecordsTextTag fontSize="1rem">Watching</RecordsTextTag>
              <RecordsTextAns>{profile?.following?.length || 0}</RecordsTextAns>
            </RecordsContainer>
          </ScoutStatsContainer>
        </>
      )}
      {tag === 2 && (
        <ContentContainer column gap="10px 0" margin="20px 0" minWidth="20vw">
          {/* <SubTitle>Watching</SubTitle> */}

          {profile?.scoutedTalent?.map((data, i) => {
            return <ScoutedTalents user={data} key={i.toString()} />;
          })}
        </ContentContainer>
      )}
    </ContentContainer>
  );
};

ScoutDetails.propTypes = {
  profile: shape({}),
  clubData: shape({})
};

ScoutDetails.defaultProps = {
  profile: {}
};

export default ScoutDetails;
