import { any, bool, func, node, number, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';

import {
  BackButton,
  LeftContainer,
  RightBarContainer,
  RightContainer,
  StepText,
  Title,
  TitleContainer
} from './components';
import { BaseSubtitle, BaseTitle, Container } from '../../shared/styledComponents';
import { ColumnLogo } from '../../shared/Logos/ColumnLogo';
import { Loader } from 'Components/shared/Loader';
import { getIsLoading } from 'Store/loaderSlice';
import { paths } from 'Utils/constants';
import Auth from 'middleware/storage';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'Components/LanguageSwitcher/LanguageSwitcher';
import { LanguageContainer } from 'Components/Navigation/NavBar/components';
import { SportMasonry } from './SportMasonry';
import { MainLogo } from 'Components/shared/Logos/MainLogo';
import { MainContainer } from 'Components/Navigation/components';
import { useContext } from 'react';
import { ThemeContext } from 'Context/themeContext';
import { ToggleTheme } from 'Components/shared/ToggleTheme';
import { LeftBarContainer } from 'Components/Navigation/LeftBar/components';

export const AuthLayout = ({
  title,
  titleWidth,
  subtitle,
  showBackButton,
  currentStep,
  numberOfSteps,
  children,
  customGoBack = () => {
    return null;
  },
  substep
}) => {
  const dispatch = useDispatch();
  const { themeMode, theme, toggleTheme } = useContext(ThemeContext);
  const isLoading = useSelector(getIsLoading);
  const { t } = useTranslation();
  const pathname = useSelector((state) => state?.router?.location?.pathname);
  const onGoBack = async () => {
    if (pathname === paths.SIGN_UP_ENHANCE_INFO) {
      await Auth.destroyToken();
    }
    dispatch(goBack());
  };

  // console.log('theme on aiuth layout', theme?.spoxioBg);

  return (
    <MainContainer backgroundColor={theme?.body}>
      {isLoading && <Loader background="rgba(255, 255, 255, 0.39)" size={40} />}
      <LeftContainer>
        <LeftBarContainer border={theme.borderShade}>
          <MainLogo />
          <SportMasonry />
               
        </LeftBarContainer>
      </LeftContainer>
      <RightContainer>
        <RightBarContainer padding="20px" border={theme.borderShade2} background={theme.spoxioBg}>
          <LanguageContainer>
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center', padding: '4px 2px' }}>
              <LanguageSwitcher />
              <ToggleTheme />
            </div>
          </LanguageContainer>
          {/* <ColumnLogo /> */}
          {showBackButton ? (
            <TitleContainer>
              <BackButton
                onClick={() => {
                  substep ? customGoBack() : onGoBack();
                }}
              />
              <Title width={titleWidth} fontSize="1.2rem" fontWeight="bold">
                {title}
              </Title>
              <BaseSubtitle fontSize={12}>{subtitle}</BaseSubtitle>
            </TitleContainer>
          ) : (
            <TitleContainer>
              <BaseTitle fontSize={28}>{title}</BaseTitle>
              <BaseSubtitle fontSize={14}>{subtitle}</BaseSubtitle>
            </TitleContainer>
          )}
          {currentStep ? (
            <StepText>{`${t('step')} ${currentStep} / ${numberOfSteps}`}</StepText>
          ) : null}
          {children}
        </RightBarContainer>
      </RightContainer>
    </MainContainer>
  );
};

AuthLayout.propTypes = {
  title: node,
  showBackButton: bool,
  currentStep: number,
  numberOfSteps: number,
  children: node,
  subtitle: any,
  titleWidth: string,
  customGoBack: func,
  substep: bool
};

AuthLayout.defaultProps = {
  showBackButton: false,
  currentStep: null,
  numberOfSteps: null,
  children: null,
  titleWidth: '100%',
  substep: false
};
