// import styled from 'styled-components';

// import { BaseButton } from 'Components/shared/styledComponents';
// import { FormImagePicker } from 'Components/shared/Inputs/FormImagePicker';

// export const EditProfileContainer = styled.div`
//   width: 100%;
//   max-width: 700px;
// `;

// export const Head = styled.div`
//   font-size: 18px;
//   font-weight: 600;
//   position: relative;
//   margin: 40px 0 60px;
//   text-align: center;
// `;

// export const InputContainer = styled.div`
//   margin: 10px 0;
//   .margin-bottom_medium-v2 {
//     margin-bottom: 0px;
//   }
// `;

// export const TwoRow = styled.div`
//   margin: 20px 0;
//   display: flex;
//   width: 70%;
//   justify-content: space-between;
// `;

// export const Row = styled.div`
//   width: 45%;
//   .margin-bottom_medium-v2 {
//     margin-bottom: 0px;
//   }
//   .css-ozi9ov-ValueContainer {
//     padding: 0;
//   }
// `;

// export const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   position: relative;
// `;

// export const UploadAvatar = styled.div`
//   width: 30%;
//   margin: 20px 0;
// `;

// export const SubmitButton = styled(BaseButton)`
//   margin-top: 40px;
//   width: 200px;
//   margin-left: auto;
//   margin-right: auto;
// `;

// export const UploadFile = styled(FormImagePicker)`
//   & > :first-child {
//     justify-content: flex-start;
//   }

//   & span {
//     left: 120px;
//   }
// `;

import styled from 'styled-components';

import { BaseButton } from 'Components/shared/styledComponents';
import { FormImagePicker } from 'Components/shared/Inputs/FormImagePicker';

export const EditProfileContainer = styled.div`
  width: 100%;
  max-width: 700px;
`;

export const Head = styled.div`
  font-size: 18px;
  font-weight: 600;
  position: relative;
  margin: 40px 0 60px;
  text-align: center;
`;

export const InputContainer = styled.div`
  margin: 10px 0;
  .margin-bottom_medium-v2 {
    margin-bottom: 0px;
  }
`;

export const TwoRow = styled.div`
  margin: ${({ margin }) => margin || '20px 0'};
  display: flex;
  width: ${({ width }) => (width ? width : '70%')};
  justify-content: space-between;
`;

export const Row = styled.div`
  width: ${({ width }) => (width ? width : '45%')};
  .margin-bottom_medium-v2 {
    margin-bottom: 0px;
  }
  .css-ozi9ov-ValueContainer {
    padding: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const UploadAvatar = styled.div`
  /* width: 30%; */
  margin: 20px 0 0;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'space-between')};
  align-items: center;
`;

export const SubmitButton = styled(BaseButton)`
  margin-top: 40px;
  width: ${({ width }) => (width ? width : '200px')};
  margin-left: auto;
  margin-right: auto;
  background: ${({ theme, linear }) => (linear ? theme.spoxioLinear2 : '')};
`;

export const SaveButton = styled.button`
  margin-top: 40px;
  width: ${({ width }) => (width ? width : '200px')};
  margin-left: auto;
  margin-right: auto;
  background: ${({ theme, linear }) => (linear ? theme.spoxioLinear2 : '')};
`;

export const UploadFile = styled(FormImagePicker)`
  & > :first-child {
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
  }

  & span {
    /* left: 120px; */
  }
`;
