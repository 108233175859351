import styled from 'styled-components';

import { BaseText, BaseTitle, CenteredContainer } from '../shared/styledComponents';

import { ReactComponent as ArrowLeftIcon } from 'Assets/icons/ArrowLeft.svg';
import AuthImg from 'Assets/img/AuthImg.png';

export const LeftContainer = styled.div`
  width: 50%;
  min-width: 500px;
  background-image: url(${AuthImg});
  background-size: cover;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Title = styled(BaseTitle)`
  width: 100%;
`;

export const StepText = styled(BaseText)`
  margin-top: 10px;
  margin-bottom: 50px;
  color: ${({ theme }) => theme.gray};
`;

export const RightContainer = styled(CenteredContainer)`
  padding: 40px 60px;
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const BackButton = styled(ArrowLeftIcon)`
  width: 27px;
  height: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.gray};
`;
