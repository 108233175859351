import React, { useContext, useEffect, useState } from 'react';
import PropTypes, { bool, shape, string } from 'prop-types';
import {
  LeftSideModal,
  LeftSideText,
  ModalAdminItem,
  ModalAdminLinks,
  ModalContent,
  ModalFooter,
  ModalLinks,
  ModalLinksContainer
} from './components';
import { ReactComponent as CloseIcon } from '../../Assets/icons/circle-back.svg';
// import { ReactComponent as Add } from 'Assets/icons/AddIcon.svg';
import { ReactComponent as LogOut } from 'Assets/icons/logout-nav.svg';
import { modalData } from 'Utils/data';
import { getCurrentUserId, logout } from 'Store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ContentContainer, IconContainer } from 'Components/shared/styledComponents';
import { LogoutText } from 'Components/Layouts/ProfileLayout/LeftBar/components';
import { ThemeContext } from 'Context/themeContext';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { paths } from 'Utils/constants';
import { blockUser, followOnUser, unBlockUser, unfollowOnUser } from 'Store/profileSlice';
import Api from 'Api';
import isEmpty from 'Utils/isEmpty';
import { ToggleTheme } from 'Components/shared/ToggleTheme';
import { v4 as uuidv4 } from 'uuid';
import { Loader } from 'Components/shared/Loader';
import Skeleton from 'react-loading-skeleton';

const ProfileModalContent = ({ setVisible, profile }) => {
  const { theme } = useContext(ThemeContext);
  const { fan } = modalData;
  const [navListData, setNavListData] = useState(fan);
  const dispatch = useDispatch();
  const onLogoutClick = () => {
    dispatch(logout());
  };

  const { isBlocked, data } = profile;

  const { t } = useTranslation();
  const [isFollow, setIsFollow] = useState(data?.isFollowedByMe);

  const currentUserId = useSelector(getCurrentUserId);

  const openFollowers = () => dispatch(push(`${paths.PROFILE}/${data._id}/followers`));

  const openFollowing = () => dispatch(push(`${paths.PROFILE}/${data._id}/following`));

  const openEdit = () => dispatch(push(`${paths.PROFILE}/${data._id}/edit`));

  const openPersonalize = () => dispatch(push(`${paths.PROFILE}/${data._id}/sports`));

  const openChangePassword = () => dispatch(push(`${paths.PROFILE}/${data._id}/change_password`));

  const openAboutSpoxio = () => dispatch(push(`${paths.PROFILE}/${data._id}/about`));

  const openScoutActivites = () => dispatch(push(`${paths.SCOUT_ACTIVITIES}`));

  const openScoutSubscription = () => dispatch(push(`${paths.SCOUT_PAYMENTS}`));

  const onFollow = () => {
    if (isFollow) {
      dispatch(unfollowOnUser(data._id));
      setIsFollow(!isFollow);
    } else {
      dispatch(followOnUser(data._id));
      setIsFollow(!isFollow);
    }
  };
  const goToChat = (groupId) => dispatch(push(`${paths.CHAT}?chat_id=${groupId}`));
  const onClickChat = () => {
    Api.messages.create([currentUserId, data._id], goToChat);
  };

  const onBlockUserAction = () => {
    dispatch(blockUser(data._id));
  };

  const unBlockUserAction = () => {
    dispatch(unBlockUser(data._id));
  };

  useEffect(() => {
    setNavListData(modalData[data.userType || 'fan']);
  }, [data.userType]);

  console.log('\n 🚀 ~ file: ProfileModalContent.js:93 ~ ProfileModalContent ~ profile:', profile);

  return (
    <>
      {/* Modal content for other profile view */}
      <ModalContent>
        <IconContainer margin="0 10px 20px">
          <CloseIcon onClick={() => setVisible((prev) => !prev)} title="Close" />
        </IconContainer>

        {isEmpty(data) ? (
          <ContentContainer height="100%">
            {/* <Loader height="16vh" size={32} /> */}
            <Skeleton
              height={45}
              borderRadius={10}
              style={{ marginBottom: '10px' }}
              baseColor={theme?.darkGray2}
              count={2}
              enableAnimation={false}
            />
          </ContentContainer>
        ) : currentUserId !== data._id ? (
          <ModalLinksContainer mt="10px">
            <ModalLinks onClick={onFollow}>{isFollow ? 'Unfollow' : 'Follow'}</ModalLinks>
            <ModalLinks onClick={openFollowers}>View Followers</ModalLinks>
            <ModalLinks onClick={openFollowing}>View Following</ModalLinks>

            {isBlocked ? (
              <ModalLinks color={theme.spoxioRed} onClick={unBlockUserAction}>
                {t('unblock')} {data?.firstName} {data?.lastName}
              </ModalLinks>
            ) : (
              <>
                <ModalLinks onClick={onClickChat}>Send Direct Message</ModalLinks>
                <ModalLinks color={theme.spoxioRed} onClick={onBlockUserAction}>
                  {t('block')} {data?.firstName} {data?.lastName}
                </ModalLinks>
              </>
            )}
          </ModalLinksContainer>
        ) : null}

        {isEmpty(data) ? (
          <ContentContainer height="100%">
            {/* <Loader size={24} /> */}
            <Skeleton
              height={45}
              borderRadius={10}
              style={{ marginBottom: '10px' }}
              baseColor={theme?.darkGray2}
              count={2}
              enableAnimation={false}
            />
          </ContentContainer>
        ) : currentUserId === data._id ? (
          <>
            <ModalLinksContainer>
              {navListData?.map((item, i) => {
                const Icon = item?.icon;

                if (item?.name === 'editProfile') {
                  return (
                    <ModalAdminItem onClick={openEdit} key={uuidv4()}>
                      <LeftSideModal>
                        <IconContainer width="22px" height="22px">
                          <Icon />
                        </IconContainer>
                        <LeftSideText>{item.text}</LeftSideText>
                      </LeftSideModal>
                    </ModalAdminItem>
                  );
                }

                if (item?.name === 'switchTheme') {
                  return (
                    <ModalAdminItem key={uuidv4()}>
                      <LeftSideModal width="100%">
                        <ToggleTheme showText color={theme.spoxioIcon} width="100%" />
                      </LeftSideModal>
                    </ModalAdminItem>
                  );
                }

                if (item?.name === 'security') {
                  return (
                    <ModalAdminItem onClick={openChangePassword} key={uuidv4()}>
                      <LeftSideModal>
                        <IconContainer width="22px" height="22px">
                          <Icon />
                        </IconContainer>
                        <LeftSideText>{item.text}</LeftSideText>
                      </LeftSideModal>
                    </ModalAdminItem>
                  );
                }

                if (item?.name === 'personalize') {
                  return (
                    <ModalAdminItem onClick={openPersonalize} key={uuidv4()}>
                      <LeftSideModal>
                        <IconContainer width="22px" height="22px">
                          <Icon />
                        </IconContainer>
                        <LeftSideText>{item.text}</LeftSideText>
                      </LeftSideModal>
                    </ModalAdminItem>
                  );
                }

                if (item?.name === 'about') {
                  return (
                    <ModalAdminItem onClick={openAboutSpoxio} key={uuidv4()}>
                      <LeftSideModal>
                        <IconContainer width="22px" height="22px">
                          <Icon />
                        </IconContainer>
                        <LeftSideText>{item.text}</LeftSideText>
                      </LeftSideModal>
                    </ModalAdminItem>
                  );
                }

                if (item?.name === 'scoutActivities') {
                  return (
                    <ModalAdminItem
                      onClick={() => {
                        data?.userType === 'talent' || data?.userType === 'club_member'
                          ? openScoutActivites()
                          : data?.userType === 'scout' && data?.scoutProfile?.trialEnabled
                          ? openScoutActivites()
                          : openScoutSubscription();
                      }}
                      key={uuidv4()}>
                      <LeftSideModal>
                        <IconContainer width="22px" height="22px">
                          <Icon />
                        </IconContainer>
                        <LeftSideText>{item.text}</LeftSideText>
                      </LeftSideModal>
                    </ModalAdminItem>
                  );
                }

                if (item?.name === 'subscription') {
                  return (
                    <ModalAdminItem onClick={openScoutSubscription} key={uuidv4()}>
                      <LeftSideModal>
                        <IconContainer width="22px" height="22px">
                          <Icon />
                        </IconContainer>
                        <LeftSideText>{item.text}</LeftSideText>
                      </LeftSideModal>
                    </ModalAdminItem>
                  );
                }

                return (
                  <ModalAdminItem key={uuidv4()}>
                    <LeftSideModal>
                      <IconContainer width="22px" height="22px">
                        <Icon />
                      </IconContainer>
                      <LeftSideText>{item.text}</LeftSideText>
                    </LeftSideModal>
                  </ModalAdminItem>
                );
              })}
            </ModalLinksContainer>
            {/* Add Account logout and SPoxio version */}
            <ModalFooter mt={'40px'}>
              {/* <SpoxioMenuItem>
              <Add />
              <SpoxioMenuText f={'16px'} c={true}>
                Add Account
              </SpoxioMenuText>
            </SpoxioMenuItem> */}

              <ContentContainer row justifyContent="space-between" width="100%">
                <div
                  style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                  onClick={onLogoutClick}>
                  <LogOut width="16px" height="16px" />
                  <LogoutText>Log Out</LogoutText>
                </div>{' '}
                <div style={{ color: '#828490' }}>Spoxio v2.0</div>
              </ContentContainer>
            </ModalFooter>
          </>
        ) : null}
      </ModalContent>
    </>
  );
};

ProfileModalContent.propTypes = {
  role: PropTypes.string,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  profile: PropTypes.shape({
    isCurrentUser: PropTypes.bool,
    isBlocked: PropTypes.bool,
    data: PropTypes.shape({
      scoutProfile: shape({}),
      firstName: string,
      lastName: string,
      _id: string,
      userType: string,
      isFollowedByMe: bool
    })
  })
};
export default ProfileModalContent;
