import { setLoading } from './loaderSlice';
import Api2 from 'middleware/api';
import { setCommentReplies, setComments } from './selectors/commentSelector';

export const fetchPostComments = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await Api2.comment.comments(id);
    const { data } = response.data;

    dispatch(setComments(data));
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    return false;
  }
};

export const fetchPostCommentReplies = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await Api2.comment.commentReplies(id);
    const { data } = response.data;

    dispatch(setCommentReplies(data));
    return true;
  } catch (error) {
    dispatch(setLoading(false));
    return false;
  }
};
