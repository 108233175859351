import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allClubsList: {},
  clubProfileByID: {},
  clubRoles: [],
  clubStatCategories: [],
  clubStatistics: []
};

// reducer
export const clubSlice = createSlice({
  name: 'club',
  initialState,
  reducers: {
    setAllClubsList: (state, action) => {
      state.allClubsList = action.payload;
    },
    setClubProfileByID: (state, action) => {
      state.clubProfileByID = action.payload;
    },
    setClubRoles: (state, action) => {
      state.clubRoles = action.payload;
    },
    setClubStatCategory: (state, action) => {
      state.clubStatCategories = action.payload;
    },
    setClubStatistics: (state, action) => {
      state.clubStatistics = action.payload;
    }
  }
});

export const {
  setAllClubsList,
  setClubProfileByID,
  setClubRoles,
  setClubStatCategory,
  setClubStatistics
} = clubSlice.actions;
export const clubReducer = clubSlice.reducer;

// selectors
export const getClub = (state) => state.club;
export const getClubProfileByID = (state) => state.club.clubProfileByID;
export const getClubInfo = (state) => state.club.clubInfo;
export const getClubRoles = (state) => state.club.clubRoles;
export const getClubStatCategories = (state) => state.club.clubStatCategories;
export const getClubStatistics = (state) => state.club.clubStatistics;
