import { t } from 'i18next';
import { func } from 'prop-types';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUser } from '../../../Store/authSlice';
import { UserPic } from '../UserPic';

import { Input, InputContainer, CommentUserImg } from './components';

export const CommentInput = forwardRef(({ onSendComment }, ref) => {
  const currentUser = useSelector(getCurrentUser);

  const onSend = (e) => {
    if (e.key === 'Enter') {
      onSendComment(e.target.value);
      e.target.value = '';
    }
  };

  return (
    <InputContainer>
      <CommentUserImg>
        <UserPic src={currentUser?.picture} size={32} />
      </CommentUserImg>
      <Input ref={ref} placeholder={t('your_comments_here')} onKeyDown={onSend} />
    </InputContainer>
  );
});

CommentInput.propTypes = {
  onSendComment: func
};

CommentInput.defaultProps = {
  onSendComment: () => {}
};
