import { shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { isScout } from 'Utils';
import { getProfileTabs } from './helpers';
import { followOnSport } from 'Store/authSlice';
import { getIsLoading } from 'Store/loaderSlice';
import { PostsList } from 'Components/PostsList';
import { Tabs } from 'Components/shared/Tabs';
import { ProfileHead } from './ProfileHead';
import { TabContainer, LabelPosts, ProfileContainer } from '../ProfileRevamp/components';
import { EmptyPage } from './EmptyPage';
import { Loader } from 'Components/shared/Loader';
import { useTranslation } from 'react-i18next';

export const Profile = ({ profile, tabName }) => {
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { posts, data, isCurrentUser, isBlocked } = profile;

  const onChoseSport = (sportId) => {
    let sports = [];
    sports.push(sportId);
    dispatch(followOnSport(sports));
  };

  const onTabClick = (tabId) => {
    if (tabId === 'posts') {
      dispatch(push(`${paths.PROFILE}/${data._id}`));
    } else {
      dispatch(push(`${paths.PROFILE}/${data._id}/${tabId}`));
    }
  };

  const defaultTab = !isScout(data?.userType) && !tabName ? t('posts') : tabName || t('sports');

  return (
    <ProfileContainer>
      {isLoading ? (
        <Loader size={60} />
      ) : (
        <>
          <ProfileHead profile={profile} />
          <TabContainer>
            {isCurrentUser ? (
              <Tabs
                defaultTab={defaultTab}
                handleChangeTab={onTabClick}
                tabs={getProfileTabs(data, posts, onChoseSport)}
              />
            ) : !isBlocked && !isScout(data?.userType) ? (
              <>
                <LabelPosts>{`${t('posts')} (${posts?.length})`}</LabelPosts>
                {posts?.length ? (
                  <PostsList postList={posts} showStories={false} />
                ) : (
                  <EmptyPage userName={data?.name} />
                )}
              </>
            ) : null}
          </TabContainer>
        </>
      )}
    </ProfileContainer>
  );
};

Profile.propTypes = {
  profile: shape({}),
  tabName: string
};

Profile.defaultProps = {
  profile: {},
  tabName: ''
};
