import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { getIsLoading } from 'Store/loaderSlice';
import { passwordRules, requiredRule } from 'Utils/validations';
import { FollowSportsContainer, SubmitBtn, Input } from '../ProfileRevamp/components';
import { Loader } from 'Components/shared/Loader';
import { accountDeactivate, changePassword } from 'Store/authSlice';
import { useTranslation } from 'react-i18next';
import { ProfileLayout } from 'Components/Layouts/ProfileLayout';
import {
  ContentContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import SpoxioModal from 'Components/Modals/SpoxioModal';
import { ThemeContext } from 'Context/themeContext';

export const ChangePassword = () => {
  const { theme } = useContext(ThemeContext);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, errors, watch, handleSubmit } = useForm();

  const onSubmit = async ({ password, newPassword }) => {
    dispatch(changePassword({ oldPassword: password, newPassword }));
  };
  const newPassword = watch('newPassword');
  const [isVisible, setIsVisible] = useState(false);

  const deactivate = () => {
    dispatch(accountDeactivate());
  };

  return (
    <ProfileLayout navTitle="Change Password">
      <ContentContainer width="100%" column gap="20px" maxWidth="700px" margin="20px auto">
        {isLoading && <Loader size={24} height="100%" />}
        <ContentContainer column gap="8px" width="100%">
          <SubTitle margin="0 0 -10px" fontWeight="bold">
            Change Password
          </SubTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              ref={register(requiredRule)}
              name="password"
              type="password"
              error={errors?.password?.message}
              placeholder={t('old_pass')}
            />
            <Input
              ref={register(passwordRules)}
              name="newPassword"
              type="password"
              error={errors?.newPassword?.message}
              placeholder={t('new_pass')}
            />
            <Input
              ref={register({
                validate: (value) => value === newPassword || `${t('password_dont_match')}`
              })}
              name="confirmPassword"
              type="password"
              error={errors?.confirmPassword?.message}
              placeholder={t('confirm_pass')}
            />
            <SpoxioBlueBtn hover marginTop="-20px">
              {t('save_changes')}
            </SpoxioBlueBtn>
          </form>
        </ContentContainer>

        <ContentContainer column gap="8px" width="100%" margin="20px 0">
          <SubTitle margin="0 0 -10px" fontWeight="bold">
            Deactivate Account
          </SubTitle>

          <SpoxioBlueBtn
            hover
            margin="0"
            onClick={() => {
              setIsVisible(true);
            }}
          >
            Deactivate Account
          </SpoxioBlueBtn>

          <SpoxioModal
            showCloseButton={false}
            visible={isVisible}
            setVisible={() => setIsVisible(false)}
            minHeight="20vh"
          >
            <ContentContainer column gap="30px" width="100%">
              <Title
                width="100%"
                textAlign="center"
                fontSize="1.35rem"
                fontWeight="bold"
                textTransform="uppercase"
                opacity="0.65"
              >
                Account Deactivation
              </Title>

              <Title width="100%" textAlign="center" fontSize="1.15rem">
                Are you sure you want to{' '}
                <span
                  style={{ color: theme.spoxioRed, fontWeight: '700', fontFamily: 'SF Pro Bold' }}
                >
                  deactivate
                </span>{' '}
                your account?
              </Title>

              <ContentContainer row width="100%" gap="20px" justifyContent="center" flexWrap="wrap">
                <SpoxioBlueBtn
                  hover
                  width="auto"
                  marginTop="0"
                  margin="0"
                  onClick={() => setIsVisible(false)}
                >
                  Cancel
                </SpoxioBlueBtn>
                <SpoxioBlueBtn
                  background={theme.defaultBlack}
                  color={theme.spoxioRed}
                  hover
                  width="auto"
                  marginTop="0"
                  margin="0"
                  border={theme.spoxioBg}
                  onClick={deactivate}
                >
                  Deactivate
                </SpoxioBlueBtn>
              </ContentContainer>
            </ContentContainer>
          </SpoxioModal>
        </ContentContainer>
      </ContentContainer>
    </ProfileLayout>
  );
};
