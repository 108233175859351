import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { genderOptions, userRoles } from 'Utils/constants';
import { RouteProps } from 'Utils/propTypes';
import { emailRules, requiredRule } from 'Utils/validations';
import { maxDateForDobField } from 'Utils/dates';
import {
  formatClubEnhanceInfo,
  formatFanInfo,
  formatScoutEnhanceInfo,
  formatTalentEnhanceInfo
} from 'Utils/formatData';
import { getProfileDefaultValues } from './helpers';
import { getProfileSelector } from 'Store/selectors/profileSelector';
import {
  editClubProfile,
  updateFanProfile,
  updateScoutProfile,
  updateTalentProfile
} from 'Store/authSlice';
import { getIsLoading } from 'Store/loaderSlice';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { FormDatePicker } from 'Components/shared/Inputs/FormDatePicker';
import { FormTextArea } from 'Components/shared/Inputs/FormTextArea';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
import { PageNavigation } from 'Components/Navigation';
import {
  CenterContent,
  ContentContainer,
  RowContainer,
  SpoxioBlueBtn
} from 'Components/shared/styledComponents';
import { BackButton } from 'Components/shared/BackButton';
import {
  Head,
  InputContainer,
  TwoRow,
  Row,
  SubmitButton,
  Form,
  UploadAvatar,
  UploadFile
} from './components';
import { Loader } from 'Components/shared/Loader';
import { getProfileById } from 'Store/profileSlice';
import { getCountrySelector, getSportSelector } from 'Store/selectors/dataSelector';
import { fetchCountries } from 'Store/dataSlice';
import { useTranslation } from 'react-i18next';
import { ProfileLayout } from 'Components/Layouts/ProfileLayout';
import { FollowUserButton } from 'Components/OtherProfile/components';
import { Tabs } from 'Components/shared/Tabs';
import { ThemeContext } from 'Context/themeContext';
import EditClubSquad from 'Components/OtherProfile/Club/ClubComponents/EditClubSquad';
import EditClubStats from 'Components/OtherProfile/Club/ClubComponents/EditClubStats';

export const EditProfile = ({ history, location }) => {
  const dispatch = useDispatch();

  const { register, reset, control, errors, handleSubmit } = useForm();
  const isLoading = useSelector(getIsLoading);
  const { theme } = useContext(ThemeContext);
  const { data } = useSelector(getProfileSelector);
  console.log('🚀 ~ file: index.jsx:65 ~ EditProfile ~ data:', data);
  const { t } = useTranslation();
  const countries = useSelector(getCountrySelector);
  const sports = useSelector(getSportSelector);
  const [tabName, setTabName] = useState('overview');

  const countryArray = countries?.map((item, i) => {
    return {
      label: item.name,
      value: item._id
    };
  });

  const sportsArray = sports?.map((item, i) => {
    return {
      label: item.name,
      value: item._id
    };
  });

  const profileId = location.pathname.split('/')[2];
  const defaultValues = getProfileDefaultValues(data);

  useEffect(() => {
    dispatch(getProfileById(profileId));
    dispatch(fetchCountries());
    return () => {
      getProfileById(profileId);
      fetchCountries();
    };
  }, [dispatch, profileId]);

  // useEffect(() => reset(defaultValues), [reset, data]);

  const onSubmit = (values) => {
    if (data?.userType === userRoles.SCOUT) {
      dispatch(updateScoutProfile(formatScoutEnhanceInfo(values), profileId));
    } else if (data?.userType === userRoles.TALENT) {
      dispatch(updateTalentProfile(formatTalentEnhanceInfo(values), profileId));
    } else if (data?.userType === userRoles.CLUB) {
      dispatch(editClubProfile(formatClubEnhanceInfo(values), data?.clubProfile?._id));
    } else {
      dispatch(updateFanProfile(formatFanInfo(values), profileId));
    }
  };

  const onTabClick = (tabId) => {
    setTabName(tabId);
  };

  // const FormField = () => {
  //   return (
  //     <ContentContainer margin="8px 0">
  //       {data?.userType !== userRoles.CLUB && (
  //         <>
  //           <InputContainer>
  //             <FormInput
  //               ref={register(requiredRule)}
  //               name="firstName"
  //               placeholder={t('first_name')}
  //               label={t('first_name')}
  //               error={errors?.firstName?.message}
  //             />
  //           </InputContainer>

  //           <InputContainer>
  //             <FormInput
  //               ref={register(requiredRule)}
  //               name="lastName"
  //               placeholder={t('last_name')}
  //               label={t('last_name')}
  //               error={errors?.lastName?.message}
  //             />
  //           </InputContainer>
  //         </>
  //       )}

  //       {data?.userType !== userRoles.TALENT &&
  //         data?.userType !== userRoles.SCOUT &&
  //         data?.userType !== userRoles.CLUB && (
  //           <InputContainer>
  //             <FormTextArea
  //               ref={register(requiredRule)}
  //               name="bio"
  //               placeholder={t('about')}
  //               label={t('about_bio_1')}
  //               error={errors?.bio?.message}
  //             />
  //           </InputContainer>
  //         )}

  //       {data?.userType === userRoles.SCOUT && (
  //         <>
  //           {/* <FormInput
  //           ref={register(requiredRule)}
  //           name="club"
  //           label={t('company_or_clubname')}
  //           placeholder={t('company_or_clubname')}
  //           error={errors?.club?.message}
  //           className="margin-bottom_medium"
  //         />
  //         <FormInput
  //           ref={register(requiredRule)}
  //           name="organisation"
  //           label={t('organisation_of_reg')}
  //           placeholder={t('organisation_of_reg')}
  //           error={errors?.organization?.message}
  //           className="margin-bottom_medium"
  //         />
  //         <FormInput
  //           ref={register(requiredRule)}
  //           name="scoutId"
  //           label={t('reg_no')}
  //           placeholder={t('reg_no')}
  //           error={errors?.scoutId?.message}
  //           className="margin-bottom_medium"
  //         />
  //         <InputContainer>
  //           <FormSelect
  //             label={t('country_of_res')}
  //             control={control}
  //             name="country"
  //             options={countryArray}
  //             placeholder={t('country_of_reg')}
  //             isSearchable
  //             className="margin-bottom_medium-v2"
  //             rules={requiredRule}
  //             error={errors?.country?.message}
  //           />
  //         </InputContainer> */}
  //         </>
  //       )}

  //       {data?.userType === userRoles.TALENT && (
  //         <>
  //           <TwoRow>
  //             <Row>
  //               <FormDatePicker
  //                 control={control}
  //                 //value={defaultValues.dateOfBirth}
  //                 name="dateOfBirth"
  //                 label={t('dob')}
  //                 placeholder={t('dob')}
  //                 maxDate={maxDateForDobField()}
  //                 rules={requiredRule}
  //                 error={errors?.dateOfBirth?.message}
  //               />
  //             </Row>
  //             <Row>
  //               <FormSelect
  //                 label={t('gender')}
  //                 control={control}
  //                 name="gender"
  //                 options={genderOptions}
  //                 placeholder={t('gender')}
  //                 isSearchable
  //                 className="margin-bottom_medium-v2"
  //                 rules={requiredRule}
  //                 error={errors?.gender?.message}
  //               />
  //             </Row>
  //           </TwoRow>
  //           <InputContainer>
  //             <FormSelect
  //               label={t('country_of_res')}
  //               control={control}
  //               name="country"
  //               options={countryArray}
  //               placeholder={t('country_of_reg')}
  //               isSearchable
  //               className="margin-bottom_medium-v2"
  //               rules={requiredRule}
  //               error={errors?.country?.message}
  //             />
  //           </InputContainer>
  //           <TwoRow>
  //             <Row>
  //               <FormInput
  //                 ref={register(requiredRule)}
  //                 name="weight"
  //                 label={t('weight')}
  //                 type="number"
  //                 placeholder={t('weight')}
  //                 error={errors?.weight?.message}
  //               />
  //             </Row>
  //             <Row>
  //               <FormInput
  //                 ref={register(requiredRule)}
  //                 name="height"
  //                 label={t('height')}
  //                 type="number"
  //                 placeholder={t('height')}
  //                 error={errors?.height?.message}
  //               />
  //             </Row>
  //           </TwoRow>
  //           <FormTextArea
  //             ref={register(requiredRule)}
  //             name="bio"
  //             placeholder={t('about')}
  //             label={t('about_bio_1')}
  //             error={errors?.bio?.message}
  //           />
  //         </>
  //       )}

  //       {data?.userType === userRoles.CLUB && (
  //         <>
  //           <FormInput
  //             ref={register(requiredRule)}
  //             name="name"
  //             placeholder={t('Club Name')}
  //             error={errors?.name?.message}
  //           />
  //           <FormSelect
  //             control={control}
  //             rules={requiredRule}
  //             name="sportCategory"
  //             options={sportsArray}
  //             placeholder={'Sport Category'}
  //             isSearchable
  //             error={errors?.sportCategory?.message}
  //             className="margin-bottom_small"
  //           />
  //           <FormInput
  //             ref={register(requiredRule)}
  //             name="address"
  //             placeholder={'Club Address'}
  //             error={errors?.address?.message}
  //           />

  //           <FormInput
  //             ref={register(requiredRule)}
  //             name="phoneNumber"
  //             placeholder={'Phone number (+123)4567890'}
  //             error={errors?.phoneNumber?.message}
  //           />
  //           <FormInput
  //             ref={register(requiredRule)}
  //             name="website"
  //             placeholder={'Website'}
  //             error={errors?.website?.message}
  //           />
  //         </>
  //       )}
  //     </ContentContainer>
  //   );
  // };

  return (
    <ProfileLayout navTitle="Edit Profile">
      <CenterContent
        mh={'90%'}
        border
        m="20px auto 4px"
        mw="700px"
        borderRadius="30px 30px 0px 0px"
        style={{ borderBottom: '0px' }}
        padding="20px 20px 0">
        {isLoading ? (
          <Loader size={24} height="100%" />
        ) : (
          <>
            <ContentContainer column alignItems="center" gap="0" width="100%">
              <UploadAvatar>
                <UploadFile
                  ref={register(requiredRule)}
                  name="picture"
                  url={defaultValues.picture}
                  compressSize={0.6}
                  helperText={t('update_picture')}
                  error={errors?.picture?.message}
                />
              </UploadAvatar>

              <FollowUserButton
                width="33%"
                margin="12px 0"
                padding="10px"
                alt="true"
                onClick={handleSubmit(onSubmit)}>
                {t('Save Changes')}
              </FollowUserButton>
            </ContentContainer>

            {data?.userType === userRoles.CLUB ? (
              <ContentContainer width="100%" margin="10px 0">
                <Tabs
                  activeBorderColor="#007AFF"
                  inactiveBorderColor="transparent"
                  defaultTab={tabName}
                  tabGap="20px"
                  tabColorOpacity="0.55"
                  background={theme?.darkGray2}
                  handleChangeTab={onTabClick}
                  tabs={[
                    {
                      id: 'overview',
                      label: `Overview`,
                      component: (
                        <>
                          <ContentContainer margin="8px 0">
                            {data?.userType !== userRoles.CLUB && (
                              <>
                                <InputContainer>
                                  <FormInput
                                    ref={register(requiredRule)}
                                    name="firstName"
                                    placeholder={t('first_name')}
                                    label={t('first_name')}
                                    error={errors?.firstName?.message}
                                  />
                                </InputContainer>

                                <InputContainer>
                                  <FormInput
                                    ref={register(requiredRule)}
                                    name="lastName"
                                    placeholder={t('last_name')}
                                    label={t('last_name')}
                                    error={errors?.lastName?.message}
                                  />
                                </InputContainer>
                              </>
                            )}

                            {data?.userType !== userRoles.TALENT &&
                              data?.userType !== userRoles.SCOUT &&
                              data?.userType !== userRoles.CLUB && (
                                <InputContainer>
                                  <FormTextArea
                                    ref={register(requiredRule)}
                                    name="bio"
                                    placeholder={t('about')}
                                    label={t('about_bio_1')}
                                    error={errors?.bio?.message}
                                  />
                                </InputContainer>
                              )}

                            {data?.userType === userRoles.SCOUT && (
                              <>
                                {/* <FormInput
                                ref={register(requiredRule)}
                                name="club"
                                label={t('company_or_clubname')}
                                placeholder={t('company_or_clubname')}
                                error={errors?.club?.message}
                                className="margin-bottom_medium"
                              />
                              <FormInput
                                ref={register(requiredRule)}
                                name="organisation"
                                label={t('organisation_of_reg')}
                                placeholder={t('organisation_of_reg')}
                                error={errors?.organization?.message}
                                className="margin-bottom_medium"
                              />
                              <FormInput
                                ref={register(requiredRule)}
                                name="scoutId"
                                label={t('reg_no')}
                                placeholder={t('reg_no')}
                                error={errors?.scoutId?.message}
                                className="margin-bottom_medium"
                              />
                              <InputContainer>
                                <FormSelect
                                  label={t('country_of_res')}
                                  control={control}
                                  name="country"
                                  options={countryArray}
                                  placeholder={t('country_of_reg')}
                                  isSearchable
                                  className="margin-bottom_medium-v2"
                                  rules={requiredRule}
                                  error={errors?.country?.message}
                                />
                              </InputContainer> */}
                              </>
                            )}

                            {data?.userType === userRoles.TALENT && (
                              <>
                                <TwoRow>
                                  <Row>
                                    <FormDatePicker
                                      control={control}
                                      //value={defaultValues.dateOfBirth}
                                      name="dateOfBirth"
                                      label={t('dob')}
                                      placeholder={t('dob')}
                                      maxDate={maxDateForDobField()}
                                      rules={requiredRule}
                                      error={errors?.dateOfBirth?.message}
                                    />
                                  </Row>
                                  <Row>
                                    <FormSelect
                                      label={t('gender')}
                                      control={control}
                                      name="gender"
                                      options={genderOptions}
                                      placeholder={t('gender')}
                                      isSearchable
                                      className="margin-bottom_medium-v2"
                                      rules={requiredRule}
                                      error={errors?.gender?.message}
                                    />
                                  </Row>
                                </TwoRow>
                                <InputContainer>
                                  <FormSelect
                                    label={t('country_of_res')}
                                    control={control}
                                    name="country"
                                    options={countryArray}
                                    placeholder={t('country_of_reg')}
                                    isSearchable
                                    className="margin-bottom_medium-v2"
                                    rules={requiredRule}
                                    error={errors?.country?.message}
                                  />
                                </InputContainer>
                                <TwoRow>
                                  <Row>
                                    <FormInput
                                      ref={register(requiredRule)}
                                      name="weight"
                                      label={t('weight')}
                                      type="number"
                                      placeholder={t('weight')}
                                      error={errors?.weight?.message}
                                    />
                                  </Row>
                                  <Row>
                                    <FormInput
                                      ref={register(requiredRule)}
                                      name="height"
                                      label={t('height')}
                                      type="number"
                                      placeholder={t('height')}
                                      error={errors?.height?.message}
                                    />
                                  </Row>
                                </TwoRow>
                                <FormTextArea
                                  ref={register(requiredRule)}
                                  name="bio"
                                  placeholder={t('about')}
                                  label={t('about_bio_1')}
                                  error={errors?.bio?.message}
                                />
                              </>
                            )}

                            {data?.userType === userRoles.CLUB && (
                              <>
                                <FormInput
                                  ref={register(requiredRule)}
                                  name="name"
                                  defaultValue={defaultValues.name}
                                  placeholder={t('Club Name')}
                                  error={errors?.name?.message}
                                />
                                <FormSelect
                                  control={control}
                                  rules={requiredRule}
                                  name="sportCategory"
                                  defaultValue={defaultValues.sportCategory.value}
                                  options={sportsArray}
                                  placeholder={'Sport Category'}
                                  isSearchable
                                  error={errors?.sportCategory?.message}
                                  className="margin-bottom_small"
                                />
                                <FormInput
                                  ref={register(requiredRule)}
                                  name="address"
                                  defaultValue={defaultValues.address}
                                  placeholder={'Club Address'}
                                  error={errors?.address?.message}
                                />

                                <FormInput
                                  ref={register(requiredRule)}
                                  name="phoneNumber"
                                  defaultValue={defaultValues.phoneNumber}
                                  placeholder={'Phone number (+123)4567890'}
                                  error={errors?.phoneNumber?.message}
                                />
                                <FormInput
                                  ref={register(requiredRule)}
                                  defaultValue={defaultValues.website}
                                  name="website"
                                  placeholder={'Website'}
                                  error={errors?.website?.message}
                                />
                              </>
                            )}
                          </ContentContainer>
                        </>
                      )
                    },
                    {
                      id: 'squad',
                      label: `Squad`,
                      component: (
                        <ContentContainer row margin="20px 0" gap="20px">
                          <EditClubSquad />
                        </ContentContainer>
                      )
                    },
                    {
                      id: 'stat',
                      label: `Stats`,
                      component: (
                        <ContentContainer row margin="20px 0" hideScroll gap="20px">
                          <EditClubStats />
                        </ContentContainer>
                      )
                    }
                  ]}
                />
              </ContentContainer>
            ) : (
              <>
                {/* <FormField /> */}
                <ContentContainer margin="8px 0">
                  {data?.userType !== userRoles.CLUB && (
                    <>
                      <InputContainer>
                        <FormInput
                          ref={register(requiredRule)}
                          name="firstName"
                          placeholder={t('first_name')}
                          label={t('first_name')}
                          error={errors?.firstName?.message}
                        />
                      </InputContainer>

                      <InputContainer>
                        <FormInput
                          ref={register(requiredRule)}
                          name="lastName"
                          placeholder={t('last_name')}
                          label={t('last_name')}
                          error={errors?.lastName?.message}
                        />
                      </InputContainer>
                    </>
                  )}

                  {data?.userType !== userRoles.TALENT &&
                    data?.userType !== userRoles.SCOUT &&
                    data?.userType !== userRoles.CLUB && (
                      <InputContainer>
                        <FormTextArea
                          ref={register(requiredRule)}
                          name="bio"
                          placeholder={t('about')}
                          label={t('about_bio_1')}
                          error={errors?.bio?.message}
                        />
                      </InputContainer>
                    )}

                  {data?.userType === userRoles.SCOUT && (
                    <>
                      {/* <FormInput
                        ref={register(requiredRule)}
                        name="club"
                        label={t('company_or_clubname')}
                        placeholder={t('company_or_clubname')}
                        error={errors?.club?.message}
                        className="margin-bottom_medium"
                      />
                      <FormInput
                        ref={register(requiredRule)}
                        name="organisation"
                        label={t('organisation_of_reg')}
                        placeholder={t('organisation_of_reg')}
                        error={errors?.organization?.message}
                        className="margin-bottom_medium"
                      />
                      <FormInput
                        ref={register(requiredRule)}
                        name="scoutId"
                        label={t('reg_no')}
                        placeholder={t('reg_no')}
                        error={errors?.scoutId?.message}
                        className="margin-bottom_medium"
                      />
                      <InputContainer>
                        <FormSelect
                          label={t('country_of_res')}
                          control={control}
                          name="country"
                          options={countryArray}
                          placeholder={t('country_of_reg')}
                          isSearchable
                          className="margin-bottom_medium-v2"
                          rules={requiredRule}
                          error={errors?.country?.message}
                        />
                      </InputContainer> */}
                    </>
                  )}

                  {data?.userType === userRoles.TALENT && (
                    <>
                      <TwoRow>
                        <Row>
                          <FormDatePicker
                            control={control}
                            //value={defaultValues.dateOfBirth}
                            name="dateOfBirth"
                            label={t('dob')}
                            placeholder={t('dob')}
                            maxDate={maxDateForDobField()}
                            rules={requiredRule}
                            error={errors?.dateOfBirth?.message}
                          />
                        </Row>
                        <Row>
                          <FormSelect
                            label={t('gender')}
                            control={control}
                            name="gender"
                            options={genderOptions}
                            placeholder={t('gender')}
                            isSearchable
                            className="margin-bottom_medium-v2"
                            rules={requiredRule}
                            error={errors?.gender?.message}
                          />
                        </Row>
                      </TwoRow>
                      <InputContainer>
                        <FormSelect
                          label={t('country_of_res')}
                          control={control}
                          name="country"
                          options={countryArray}
                          placeholder={t('country_of_reg')}
                          isSearchable
                          className="margin-bottom_medium-v2"
                          rules={requiredRule}
                          error={errors?.country?.message}
                        />
                      </InputContainer>
                      <TwoRow>
                        <Row>
                          <FormInput
                            ref={register(requiredRule)}
                            name="weight"
                            label={t('weight')}
                            type="number"
                            placeholder={t('weight')}
                            error={errors?.weight?.message}
                          />
                        </Row>
                        <Row>
                          <FormInput
                            ref={register(requiredRule)}
                            name="height"
                            label={t('height')}
                            type="number"
                            placeholder={t('height')}
                            error={errors?.height?.message}
                          />
                        </Row>
                      </TwoRow>
                      <FormTextArea
                        ref={register(requiredRule)}
                        name="bio"
                        placeholder={t('about')}
                        label={t('about_bio_1')}
                        error={errors?.bio?.message}
                      />
                    </>
                  )}

                  {data?.userType === userRoles.CLUB && (
                    <>
                      <FormInput
                        ref={register(requiredRule)}
                        name="name"
                        placeholder={t('Club Name')}
                        error={errors?.name?.message}
                      />
                      <FormSelect
                        control={control}
                        rules={requiredRule}
                        name="sportCategory"
                        options={sportsArray}
                        placeholder={'Sport Category'}
                        isSearchable
                        error={errors?.sportCategory?.message}
                        className="margin-bottom_small"
                      />
                      <FormInput
                        ref={register(requiredRule)}
                        name="address"
                        placeholder={'Club Address'}
                        error={errors?.address?.message}
                      />

                      <FormInput
                        ref={register(requiredRule)}
                        name="phoneNumber"
                        placeholder={'Phone number (+123)4567890'}
                        error={errors?.phoneNumber?.message}
                      />
                      <FormInput
                        ref={register(requiredRule)}
                        name="website"
                        placeholder={'Website'}
                        error={errors?.website?.message}
                      />
                    </>
                  )}
                </ContentContainer>
              </>
            )}
          </>
        )}
      </CenterContent>
    </ProfileLayout>
  );
};

EditProfile.propTypes = RouteProps;
