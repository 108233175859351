import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  /* padding: 50px 0 30px; */
  gap: 10px;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.spoxioBg} !important;
  overflow: hidden;
  height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
`;

export const RelativeContainer = styled.div`
  height: 100vh;
  min-width: ${({ width }) => width}px;
`;

RelativeContainer.defaultProps = {
  width: 234
};

export const LeftContainer = styled.div`
  height: calc(100vh - 80px);
  position: fixed;
  left: 0;
  top: 80px;
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 300px;
`;

export const RightContainer = styled.div`
  position: fixed;
  right: 0;
  top: 80px;
`;
