import styled from 'styled-components';
import { string } from 'prop-types';

import { BaseButton, BaseText, BaseTitle, BlackText } from 'Components/shared/styledComponents';

import { ReactComponent as CloseIcon } from 'Assets/icons/Close.svg';
import { ReactComponent as MoreIcon } from 'Assets/icons/More.svg';
import { ReactComponent as BinIcon } from 'Assets/icons/Bin.svg';
import { ReactComponent as SilentIcon } from 'Assets/icons/Silent.svg';
import { ReactComponent as BellIcon } from 'Assets/icons/Bell.svg';

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* position: fixed; */
  background-color: transparent; //${({ theme }) => theme.background};
  backdrop-filter: blur(5px);
  align-items: center;
  gap: 4px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  /* padding-top: 10px; */
  height: 80px;
  top: 0;
  z-index: 1000;
  /* @media (max-width: 870px) {
    width: 36vw;
  } */

  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
`;

NavContainer.propTypes = {
  border: string
};

NavContainer.defaultProps = {
  border: null
};

export const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  /* min-width: 200px; */
  width: 60%;
  height: 50px;
  background: ${({ theme }) => theme.spoxioBg}; //rgba(165, 209, 227, 0.1);
  border-width: 0.75px;
  border-style: solid;
  border-color: ${({ border, theme }) => (border ? border : theme.borderShade)};
  border-radius: 16px;
  padding: 0px 16px;
  cursor: pointer;

  & svg {
    width: 16px;
    height: 16px;
    position: relative;
  }

  & svg path {
    fill: ${({ theme }) => theme.spoxioIcon};
  }
`;

export const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  font-weight: 100;
  width: '50%';
  margin-left: 8px;
  color: ${({ theme }) => theme.black};
  transition: all 0.2s ease-out;

  ::placeholder {
    color: ${({ theme }) => theme.spoxioBodyText};
  }

  :focus {
    width: '100%';
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
  width: ${({ size }) => size};
  position: relative;
`;

export const StatsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
`;

export const PostBtn = styled(BaseButton)`
  padding: 5px 30px;
  margin-right: 10px;
  width: auto;
  position: relative;
  background: transparent;
  color: ${({ theme }) => theme.primaryBlue};
  cursor: pointer;
  transition: all 0.2s ease-out;

  :hover {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.primaryBlue};
  }
`;

export const NotAuthorizedButton = styled(BaseButton)`
  max-width: 130px;
  padding: 5px 0;
  font-weight: 500;
`;

export const IconContainer = styled.span`
  display: flex;
  cursor: pointer;
  margin: 0 10px;

  & svg {
    width: 27px;
    height: 27px;
    stroke: ${({ stroke }) => stroke};
    fill: ${({ fill }) => fill};

    path {
      fill: ${({ fill }) => fill};
      stroke: ${({ stroke }) => stroke};
    }
  }
`;

export const UserPicContainer = styled.span`
  margin-left: 10px;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  min-width: 250px;
  min-height: 250px;
  position: absolute;
  z-index: 9999;
  top: 75px;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.opacityLightGray};
  border-radius: 20px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);

  ${({ position }) => (position ? position : '')};
`;

export const LanguageContainer = styled.div`
  position: absolute;
  z-index: 9999;
  top: 10px;
  right: 10px;
  border-radius: 20px;
`;

export const UserMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
`;

export const MenuItem = styled(BaseText)`
  padding: 10px 30px;
  cursor: pointer;
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
  position: absolute;
  top: -15px;
  right: 63px;

  ${({ position }) => (position ? position : '')};
`;

export const NotificationsHead = styled(BaseTitle)`
  position: relative;
  text-align: center;
  padding: 25px 0 30px;
  font-size: ${({ theme }) => theme.xLarge2}px;
  border-bottom: 1px solid ${({ theme }) => theme.lightGray2};
`;

export const Close = styled(CloseIcon)`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 20px;
  cursor: pointer;
`;

export const NotificationItemsContainer = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const NotificationContainer = styled.div`
  display: flex;
  padding: 12px 10px;
  border-radius: 10px;
  min-width: 380px;
  background-color: ${({ theme }) => theme.spoxioIcon2};
  gap: 4px;
  position: relative;
  cursor: pointer;
  align-items: center;

  &:hover {
    opacity: 0.875;
    z-index: 99;
  }

  :last-child {
    margin-bottom: 30px;
  }
`;

export const More = styled(MoreIcon)`
  width: 15px;

  & g {
    fill: ${({ theme, active }) => (active ? theme.orange : theme.opacityGray)};
  }
`;

export const Bin = styled(BinIcon)`
  position: relative;
  top: 2px;
  margin-right: 10px;
`;

export const Silent = styled(SilentIcon)`
  position: relative;
  top: 2px;
  margin-right: 8px;
`;

export const Bell = styled(BellIcon)`
  position: relative;
  top: 2px;
  margin-right: 8px;
  width: 13px;
  height: 14px;
`;

export const MoreContainer = styled(BaseText)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 110px;
  position: absolute;
  right: 10px;
  font-size: ${({ theme }) => theme.small}px;
  cursor: pointer;
`;

export const NotificationText = styled(BlackText)`
  max-width: 160px;
  margin-left: 10px;
  font-size: ${({ theme }) => theme.medium}px;
`;

export const Actions = styled.div`
  position: absolute;
  width: 250px;
  height: 100px;
  background: white;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
  top: 35px;
  right: -5px;
  padding: 10px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 100000;
`;

export const Action = styled(BaseText)`
  display: block;
  padding: 10px 0;
  font-size: ${({ theme }) => theme.medium}px;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  width: 100%;
`;
