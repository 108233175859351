import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool, func } from 'prop-types';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import { PostProp } from 'Utils/propTypes';
import { paths } from 'Utils/constants';
import { toggleLikePostOrComment } from 'Store/postSlice';
import { getCurrentUserId } from 'Store/authSlice';
import { ActionsContainer, FlagIcon, MainActions } from './components';
import { ClickableContainer, ContentContainer, SpoxioBlueBtn } from '../styledComponents';
import LogoSecondary from 'Assets/img/LogoSecondary.png';
import darklogo from 'Assets/img/darklogo.png';
import { ReactComponent as Info } from 'Assets/icons/More.svg';

import { ReactComponent as Star } from 'Assets/icons/AuthFanRole.svg';
// import { ReactComponent as StarFilled } from 'Assets/icons/likeFilled.svg';
import { ReactComponent as RedStar } from 'Assets/icons/RedStar.svg';
// import { ReactComponent as Comment } from 'Assets/icons/Comment.svg';
import { ReactComponent as CommentFilled } from 'Assets/icons/ChatRevamp.svg';
// import { ReactComponent as Share } from 'Assets/icons/Share.svg';
import { ReactComponent as Share } from 'Assets/icons/ShareRevamp.svg';

// import { colors } from 'Theme';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'Context/themeContext';
import SpoxioModal from 'Components/Modals/SpoxioModal';
import { MainLogo } from '../Logos/MainLogo';
import Auth from 'middleware/storage';
import Api from 'middleware/api';
import { push } from 'connected-react-router';

export const Actions = ({
  data,
  isLivestream,
  onReport,
  handleComment,
  handleLike,
  onBlock,
  editPost,
  setEditPost
}) => {
  const [isLiked, setIsLiked] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const { theme, themeMode } = useContext(ThemeContext);
  const { t } = useTranslation();
  const currentUserId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();

  const onDeletePost = async () => {
    console.log('Post will be deleted');
    try {
      const token = await Auth.getToken();
      await Api.post.deletePost(data._id, token);
      toast.warn('Deleted', 'warning');
    } catch (error) {
      console.log('onDeletePost::', error);
    }
  };

  const onDelete = () => setShowDeleteDialog(true);

  useEffect(() => {
    setIsLiked(data?.isLikedByMe);
  }, [currentUserId, data?.isLikedByMe]);

  // console.log(currentUserId, 'currentUserId', data);

  const onLike = (e) => {
    e.stopPropagation();

    if (isLivestream) {
      handleLike(data?.likeCount + 1);
    } else {
      isLiked && data?.isLikedByMe
        ? handleLike(data?.likeCount - 1)
        : !isLiked && !data?.isLikedByMe
        ? handleLike(data?.likeCount + 1)
        : handleLike(data?.likeCount);
      dispatch(toggleLikePostOrComment(data._id, 'post', isLiked ? 'unlike' : 'like'));
      setIsLiked(!isLiked);
    }
  };

  const onShareClick = (e) => {
    e.stopPropagation();
    if (isLivestream) {
      copy(window.location.href);
    } else {
      copy(`${window.location.origin}${paths.TIMELINE}/${data._id}`);
    }
    toast.success(`${t('link_copied')}`);
  };

  const onCommentClick = (e) => {
    e.stopPropagation();
    handleComment();
  };

  return (
    <>
      <ActionsContainer>
        <MainActions>
          <ClickableContainer onClick={onLike}>
            {isLiked ? (
              <RedStar title={t('unlike')} />
            ) : (
              <Star title={t('like')} color={theme?.mediumGray} />
            )}
          </ClickableContainer>
          <ClickableContainer onClick={onCommentClick} fill={theme?.mediumGray}>
            <CommentFilled title={t('write_comment')} />
          </ClickableContainer>
          <ClickableContainer onClick={onShareClick} fill={theme?.mediumGray}>
            <Share title={t('share')} />
          </ClickableContainer>
        </MainActions>
        {onReport ? (
          currentUserId === data?.author?._id ? (
            <ClickableContainer
              style={{
                background: theme.spoxioIcon2,
                borderRadius: '50%',
                padding: '5px'
              }}>
              <Info
                title={t('report')}
                color={theme?.spoxioIcon}
                onClick={() => setModalVisible2(!modalVisible2)}
              />
            </ClickableContainer>
          ) : (
            <ClickableContainer>
              <FlagIcon
                title={t('report')}
                color={theme?.mediumGray}
                onClick={() => setModalVisible(!modalVisible)}
              />
            </ClickableContainer>
          )
        ) : null}
      </ActionsContainer>
      <SpoxioModal
        showCloseButton={true}
        visible={modalVisible}
        setVisible={() => setModalVisible(!modalVisible)}
        background={theme.defaultWhite}
        minHeight="240px"
        maxWidth="24vw"
        minWidth="240px">
        <ClickableContainer>
          <img className="mobile-logo" src={LogoSecondary} alt="Spoxio" />
        </ClickableContainer>
        <ContentContainer gap="0px" justifyContent="center" alignItems="center" padding="20px">
          <SpoxioBlueBtn
            hover
            border={theme.mediumGray}
            background="transparent"
            color={theme.mediumGray}
            fontSize="1.1rem"
            onClick={onReport}>
            {' '}
            Report Content
          </SpoxioBlueBtn>
          <SpoxioBlueBtn
            hover
            border={theme.spoxioRed}
            background="transparent"
            color={theme.spoxioRed}
            fontSize="1.1rem"
            onClick={() => {
              onBlock();
              setTimeout(() => {
                setModalVisible(!modalVisible);
              }, 1500);
            }}>
            {t('block_user')}
          </SpoxioBlueBtn>
        </ContentContainer>
      </SpoxioModal>

      <SpoxioModal
        maxWidth="25vw"
        minWidth="240px"
        showCloseButton={true}
        visible={showDeleteDialog}
        setVisible={() => setShowDeleteDialog(!showDeleteDialog)}
        background={theme.defaultWhite}
        minHeight="240px">
        <ClickableContainer>
          <img className="mobile-logo" src={LogoSecondary} alt="Spoxio" />
        </ClickableContainer>
        <ContentContainer gap="0px" justifyContent="center" alignItems="center" padding="20px">
          <SpoxioBlueBtn
            hover
            border={theme.mediumGray}
            background="transparent"
            color={theme.mediumGray}
            fontSize="1.1rem"
            onClick={() => {
              setShowDeleteDialog(!showDeleteDialog);
              setTimeout(() => {
                setModalVisible2(!modalVisible2);
              }, 250);
            }}>
            Cancel
          </SpoxioBlueBtn>
          <SpoxioBlueBtn
            hover
            border={theme.defaultWhite}
            background={theme.spoxioRed}
            color={theme.defaultWhite}
            fontSize="1.1rem"
            onClick={() => {
              onDeletePost();
              setTimeout(() => {
                setShowDeleteDialog(!showDeleteDialog);
                dispatch(push(`${paths.TIMELINE}`));
              }, 1500);
            }}>
            {t('Confirm Delete')}
          </SpoxioBlueBtn>
        </ContentContainer>
      </SpoxioModal>

      <SpoxioModal
        showCloseButton={true}
        visible={modalVisible2}
        setVisible={() => setModalVisible2(!modalVisible2)}
        background={theme.defaultWhite}
        minHeight="240px"
        maxWidth="24vw"
        minWidth="240px">
        <ClickableContainer>
          <img className="mobile-logo" src={LogoSecondary} alt="Spoxio" />
        </ClickableContainer>
        <ContentContainer gap="0px" justifyContent="center" alignItems="center" padding="20px">
          <SpoxioBlueBtn
            hover
            border={theme.mediumGray}
            background="transparent"
            color={theme.mediumGray}
            fontSize="1rem"
            onClick={() => {
              setModalVisible2(!modalVisible2);
              setEditPost(!editPost);
            }}>
            Edit Post
          </SpoxioBlueBtn>
          <SpoxioBlueBtn
            hover
            border={theme.spoxioRed}
            background="transparent"
            color={theme.spoxioRed}
            fontSize="1rem"
            onClick={() => {
              setModalVisible2(!modalVisible2);
              setTimeout(() => {
                setShowDeleteDialog(!showDeleteDialog);
              }, 500);
            }}>
            {t('Delete Post')}
          </SpoxioBlueBtn>
        </ContentContainer>
      </SpoxioModal>
    </>
  );
};

Actions.propTypes = {
  data: PostProp,
  isLivestream: bool,
  onReport: func,
  handleComment: func,
  handleLike: func,
  onBlock: func,
  setEditPost: func,
  editPost: bool
};

Actions.defaultProps = {
  data: null,
  isLivestream: false,
  onReport: null,
  handleComment: () => {},
  handleLike: () => {},
  onBlock: null,
  setEditPost: null
};
