import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { emailRules, requiredRule } from 'Utils/validations';
import { clubProfile, fanProfile, getCurrentUser } from 'Store/authSlice';
import { FormInput } from 'Components/shared/Inputs/FormInput';
import { FormSelect } from 'Components/shared/Inputs/FormSelect';
// import { BlackText } from 'Components/shared/styledComponents';
import {
  AuthFormContainer,
  AuthFormDescription
  // VerificationBadgeContainer,
  // VerificationContainer,
  // VerificationGrayCircle,
  // VerificationIcon
} from '../../components';
import { FormImagePicker } from 'Components/shared/Inputs/FormImagePicker';
import { fetchCountries, fetchSports } from 'Store/dataSlice';
import { getCountrySelector, getSportSelector } from 'Store/selectors/dataSelector';
import { useTranslation } from 'react-i18next';
import { SpoxioBlueBtn } from 'Routes/Auth/OnboardingPage/component';
import { AuthLayout } from 'Components/Layouts/AuthLayout';
import { SubTitle, Title } from 'Components/Layouts/AuthLayout/components';
import { ThemeContext } from 'Context/themeContext';
import { paths, userRoles } from 'Utils/constants';
import { push } from 'connected-react-router';
import { ContentContainer } from 'Components/shared/styledComponents';

export const EnhanceClubProfilePage = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const user = useSelector(getCurrentUser);
  const country = useSelector(getCountrySelector);
  const sports = useSelector(getSportSelector);

  const dispatch = useDispatch();

  const onTryLater = async () => {
    const res = await dispatch(fanProfile());
    if (res) {
      localStorage.setItem('type', userRoles.FAN);
      dispatch(push(paths.SIGN_UP_SPORTS));
    }
  };

  const defaultValues = {
    picture: user?.picture,
    club: user?.club,
    country: user?.country,
    organisation: user?.organisation,
    scoutId: user?.scoutId,
    name: user?.name,
    address: user?.address,
    phoneNumber: user?.phoneNumber,
    website: user?.website
  };
  const { register, control, errors, handleSubmit } = useForm({ defaultValues });

  useEffect(() => {
    const getData = async () => {
      await dispatch(fetchCountries());
      await dispatch(fetchSports());
    };

    getData();
  }, []);

  const onSubmit = (data) => {
    dispatch(clubProfile(data));
  };

  const countryArray = country?.map((item, i) => {
    return {
      label: item.name,
      value: item._id
    };
  });
  const sportsArray = sports?.map((item, i) => {
    return {
      label: item.name,
      value: item._id
    };
  });

  return (
    <AuthLayout
      title={
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Title fontSize="1.75rem" fontWeight="bold">
            Setup your
            <br /> club account
          </Title>
        </div>
      }
      showBackButton
    >
      <AuthFormDescription className="margin-top_small">
        <SubTitle
          fontSize="1rem"
          fontWeight="400"
          opacity="0.85"
          style={{ color: theme.black, lineHeight: '1.1rem' }}
        >
          Create your team profile and define
          <br /> an organized team structure{' '}
        </SubTitle>
      </AuthFormDescription>
      <AuthFormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormImagePicker
          ref={register(requiredRule)}
          name="picture"
          url={defaultValues.picture}
          compressSize={0.6}
          justifyContent={'center'}
          error={errors?.picture?.message}
          className="margin-bottom_small"
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
        <FormInput
          ref={register(requiredRule)}
          name="name"
          placeholder={t('Club Name')}
          error={errors?.name?.message}
        />
        <FormInput
          ref={register(emailRules)}
          name="email"
          placeholder={'Club Email'}
          error={errors?.email?.message}
        />
        <FormSelect
          control={control}
          rules={requiredRule}
          name="country"
          options={countryArray}
          placeholder={'Country of Residence'}
          isSearchable
          error={errors?.country?.message}
        />
        <FormSelect
          control={control}
          rules={requiredRule}
          name="sportCategory"
          options={sportsArray}
          placeholder={'Sport Category'}
          isSearchable
          error={errors?.sportCategory?.message}
        />
        <FormInput
          ref={register(requiredRule)}
          name="address"
          placeholder={'Club Address'}
          error={errors?.address?.message}
        />
        <FormInput
          ref={register(requiredRule)}
          name="phoneNumber"
          placeholder={'Phone number (+123)4567890'}
          error={errors?.phoneNumber?.message}
        />
        <FormInput
          ref={register(requiredRule)}
          name="website"
          placeholder={'Website'}
          error={errors?.website?.message}
        />
        <SpoxioBlueBtn>{'Complete club profile'}</SpoxioBlueBtn>
        <ContentContainer
          margin="12px 0"
          width="100%"
          row
          alignItems="center"
          justifyContent="center"
        >
          <Title
            textAlign="center"
            fontSize="1rem"
            onClick={onTryLater}
            style={{ cursor: 'pointer' }}
          >
            I’ll do this later
          </Title>
        </ContentContainer>
      </AuthFormContainer>
    </AuthLayout>
  );
};
