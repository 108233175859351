import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { paths } from 'Utils/constants';
import { getTrendingTopicsSelector, setSearch } from 'Store/selectors/discoverySelector';
import { DiscoveryContainer, SecondTitle } from './components';
import { Results } from 'Components/Results';
import { getAllTopics, getSearchTopics } from 'Store/discoverySlice';
import { useTranslation } from 'react-i18next';
import { BarTitle, ContentContainer } from 'Components/shared/styledComponents';
import { TopicItem } from './TopicItem';

export const Topics = () => {
  const trendingTopics = useSelector(getTrendingTopicsSelector);
  const { query, search } = useSelector((state) => state?.router.location);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!trendingTopics?.length || query?.topic) {
      dispatch(getAllTopics(query?.topic));
    }
  }, [query?.topic]);

  const onTopicClick = (topic) => {
    const search = { type: 'topic', data: topic };

    dispatch(setSearch(search));
    dispatch(push(`${paths.DISCOVERY}?topic=${topic.topic}`));
  };

  useEffect(() => {
    dispatch(getSearchTopics(query.topic));
  }, [search]);

  return query?.sport || query?.topic || query?.search ? (
    <Results searchString={query?.search} />
  ) : (
    <DiscoveryContainer>
      <ContentContainer column width="100%" minHeight="15vh">
        <SecondTitle>{t('trending')}</SecondTitle>
        {trendingTopics
          .map((topic, index) => (
            <TopicItem
              key={`${topic.topic}-${index}`}
              name={topic.topic}
              count={topic.postCount}
              onClick={() => onTopicClick(topic)}
            />
          ))
          ?.slice(0, 3)}
      </ContentContainer>
      <ContentContainer column width="100%" minHeight="15vh">
        <SecondTitle>{t('All Topics')}</SecondTitle>
        {trendingTopics.map((topic, index) => (
          <TopicItem
            key={`${topic.topic}-${index}`}
            name={topic.topic}
            count={topic.postCount}
            onClick={() => onTopicClick(topic)}
          />
        ))}
      </ContentContainer>
    </DiscoveryContainer>
  );
};
