import styled from 'styled-components';

import { BaseTitle } from '../../../Components/shared/styledComponents';

export const OnboardingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 25px;
  gap: 20px;
  position: relative;
`;

export const Title = styled(BaseTitle)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.5rem')};
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.black};

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SpoxioBlueBtn = styled.button`
  background: ${({ theme }) => theme.spoxioLinear2};
  color: #fff;
  padding: ${({ padding }) => (padding ? padding : '0.875rem 2rem')};
  margin: 0 auto;
  font-family: SF Pro;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.875rem')};
  width: ${({ width }) => (width ? width : '100%')};
  border-radius: 10px;
  border: none;
  margin: 0px 3px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '20px')};
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }
`;

export const AppStoreBtn = styled.img`
  width: 140px;
  height: 45px;
`;

export const GooglePlayBtn = styled.img`
  width: 140px;
  height: 40px;
`;

export const AppstoreBtnContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
