import { useState, useEffect } from 'react';

export const useSlider = (allItems, visibleSlides) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showNext, setShowNext] = useState(false);
  const [showPrevious, setShowPrevious] = useState(false);

  useEffect(() => {
    if (allItems > visibleSlides) {
      setShowNext(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentSlide > 0) {
      setShowPrevious(true);
    } else {
      setShowPrevious(false);
    }

    if (currentSlide < allItems - visibleSlides && allItems > visibleSlides) {
      setShowNext(true);
    } else {
      setShowNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide, allItems]);

  const handleNext = (e) => {
    e.stopPropagation();

    if (currentSlide < allItems - visibleSlides) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handlePrevious = (e) => {
    e.stopPropagation();

    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  return { currentSlide, showPrevious, showNext, handleNext, handlePrevious };
};
