import { formatDate, getDateAfterPeriod } from './dates';

export const formatGeneralUserInfo = (userData) => ({
  email: userData.email,
  name: `${userData.firstName} ${userData.lastName}`.trim(),
  isAllowedAge: userData.isAllowedAge,
  commentCount: 0,
  likeCount: 0,
  viewCount: 0,
  country: null
});

export const formatTalentEnhanceInfo = (userData) => ({
  ...userData,
  gender: userData?.gender?.value,
  dob: userData.dob ? formatDate(userData.dob) : null,
  country: userData?.country.value
});

export const formatClubEnhanceInfo = (userData) => ({
  address: userData?.address,
  category: userData?.sportCategory?.value,
  name: userData?.name,
  phoneNumber: userData?.phoneNumber,
  picture: userData?.picture,
  website: userData?.website
});

export const formatScoutEnhanceInfo = (userData) => ({
  ...userData,
  country: userData?.country.value
});

export const formatFanInfo = (userData) => ({
  ...userData,
  firstName: userData.firstName,
  lastName: userData.lastName,
  picture: userData.picture
});

export const getTrialUserData = () => {
  const dateSubscribed = formatDate(new Date(), 'MM/DD/YYYY');
  const expiryDate = getDateAfterPeriod(dateSubscribed, 'days', 91);

  return {
    dateSubscribed,
    expiryDate
  };
};
