import {
  RecordsContainer,
  RecordsTextAns,
  RecordsTextTag
} from 'Components/OtherProfile/Player/components';
import React, { useContext, useState } from 'react';
import { ScoutStatsContainer } from './components';
import { v4 as uuidv4 } from 'uuid';
import {
  ContentContainer,
  IconContainer,
  SpoxioBlueBtn,
  SubTitle,
  Title
} from 'Components/shared/styledComponents';
import { shape } from 'prop-types';
import { ThemeContext } from 'Context/themeContext';
import { EmptyContent } from 'Components/shared/EmptyContent';
import { SportThumbnail } from 'Components/SportTrending/component';
import { PostMediaThumbnail } from 'Components/shared/Post/PostMediaThumbnail';
import { push } from 'connected-react-router';
import { paths } from 'Utils/constants';
import { fetchSinglePost } from 'Store/postSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Cv } from 'Assets/icons/cv.svg';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrow-right.svg';
import { DragDropFileInput } from 'Components/shared/Inputs/DragDropFilesInput';
import isEmpty from 'Utils/isEmpty';
import { uploadToFirebase } from 'Store/profileSlice';
import { getCurrentUser, getCurrentUserId } from 'Store/authSlice';
import { Loader } from 'Components/shared/Loader';
import { getIsLoading } from 'Store/loaderSlice';
import { toast } from 'react-toastify';
import SpoxioModal from 'Components/Modals/SpoxioModal';

const TalentDetails = ({ profile, clubData }) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const { posts, data } = profile;

  const [showCv, setShowCv] = useState(false);

  const currentUserId = useSelector(getCurrentUserId);
  const isLoading = useSelector(getIsLoading);
  const selectPost = (data) => {
    dispatch(fetchSinglePost(data?._id));
    dispatch(push(`${paths.TIMELINE}/${data?._id}`, { id: data?._id }));
  };

  const selectFile = (base64Name, file) => {
    console.log(base64Name, file);

    dispatch(uploadToFirebase(base64Name, file, currentUserId));
  };

  return (
    <ContentContainer column gap="20px">
      <ScoutStatsContainer>
        <RecordsContainer h={'10px'}>
          <RecordsTextTag fontSize="1rem">Account views</RecordsTextTag>
          <RecordsTextAns>{data?.viewCount}</RecordsTextAns>
        </RecordsContainer>
        <RecordsContainer h={'80px'} w="100px">
          <RecordsTextTag fontSize="1rem">Post</RecordsTextTag>
          <RecordsTextAns>{profile?.posts?.length}</RecordsTextAns>
        </RecordsContainer>
      </ScoutStatsContainer>

      {currentUserId === data?._id && data?.userType === 'talent' ? (
        <SpoxioBlueBtn background={theme.spoxioIcon2} marginTop="0" padding="10px">
          {isLoading ? (
            <ContentContainer width="100%" height="20px">
              <IconContainer height="18px" width="18px">
                <Loader size={18} background="transparent" />
              </IconContainer>
            </ContentContainer>
          ) : (
            <>
              {!isEmpty(data?.talentProfile?.cv) ? (
                <SpoxioBlueBtn
                  background={theme.spoxioIcon2}
                  marginTop="0"
                  padding="5px"
                  onClick={() => setShowCv(!showCv)}
                >
                  <ContentContainer
                    row
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <IconContainer height="18px" width="18px">
                      <Cv />
                    </IconContainer>{' '}
                    <Title fontSize="0.875rem" lineHeight="normal" width="100%" fontFamily="SF Pro">
                      View CV
                    </Title>
                    <IconContainer height="14px" width="14px" fill={theme.spoxioText}>
                      <ArrowRight />
                    </IconContainer>
                  </ContentContainer>
                </SpoxioBlueBtn>
              ) : (
                <DragDropFileInput
                  isDocument
                  onChange={selectFile}
                  border="transparent"
                  acceptedFormat="application/pdf"
                  customIcon={
                    <ContentContainer row width="100%" justifyContent="space-between">
                      <IconContainer height="18px" width="18px">
                        <Cv />
                      </IconContainer>
                      <Title fontSize="1rem" lineHeight="normal" width="100%" fontFamily="SF Pro">
                        Upload CV
                      </Title>
                      <IconContainer height="14px" width="14px" fill={theme.spoxioText}>
                        <ArrowRight />
                      </IconContainer>
                    </ContentContainer>
                  }
                />
              )}
            </>
          )}
        </SpoxioBlueBtn>
      ) : (
        <>
          {!isEmpty(data?.talentProfile?.cv) ? (
            <SpoxioBlueBtn
              background={theme.spoxioIcon2}
              marginTop="0"
              padding="4px"
              onClick={() => setShowCv(!showCv)}
            >
              <ContentContainer row width="100%" justifyContent="space-between" alignItems="center">
                <IconContainer height="18px" width="18px">
                  <Cv />
                </IconContainer>{' '}
                <Title fontSize="1rem" fontFamily="SF Pro" lineHeight="normal" width="100%">
                  View CV
                </Title>
                <IconContainer height="14px" width="14px" fill={theme.spoxioText}>
                  <ArrowRight />
                </IconContainer>
              </ContentContainer>
            </SpoxioBlueBtn>
          ) : null}
        </>
      )}

      <SpoxioModal
        showCloseButton
        visible={showCv}
        width="100%"
        height="100%"
        minWidth="85vw"
        maxWidth="85vw"
        borderRadius="0px"
        setVisible={() => setShowCv(false)}
        minHeight="100vh"
        maxHeight="100vh"
      >
        <ContentContainer column width="100%" height="100%" margin="auto" padding="10px">
          <object
            data={data?.talentProfile?.cv}
            type="application/pdf"
            width="100%"
            height="100%"
            aria-label="Talent CV"
          ></object>
        </ContentContainer>
      </SpoxioModal>

      <>
        {posts < 1 ? (
          <EmptyContent
            showIcon={false}
            maxWidth="auto"
            description={
              <ContentContainer column width="100%" alignItems="center" justifyContent="center">
                <Title fontWeight="bold" color={theme.black} textAlign="center">
                  Not feeling sporty
                </Title>
                <SubTitle textAlign="center" fontSize="1rem">
                  Upload photos and videos to get profile up and sporty
                </SubTitle>
              </ContentContainer>
            }
          />
        ) : (
          <ContentContainer
            hideScroll
            row
            flexWrap="nowrap"
            width="100%"
            gap="10px"
            style={{ overflowX: 'scroll' }}
          >
            {posts?.map((post) => {
              return (
                <SportThumbnail
                  maxWidth="180px"
                  key={uuidv4()}
                  onClick={() => selectPost(post)}
                  minWidth="180px"
                >
                  <PostMediaThumbnail  height="180px" mediaList={post.contents} />
                </SportThumbnail>
              );
            })}
          </ContentContainer>
        )}
      </>
    </ContentContainer>
  );
};

TalentDetails.propTypes = {
  profile: shape({}),
  clubData: shape({})
};

TalentDetails.defaultProps = {
  profile: {}
};

export default TalentDetails;
